/* eslint-disable prefer-rest-params */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable no-underscore-dangle */
import { useEffect, useState } from 'react';
import { LIBRARY_LOADING_TIMEOUT } from '@/util/constants';

const useReferral = () => {
    const [loading, setLoading] = useState(true);
    const [referralId, setReferralId] = useState('');

    useEffect(() => {
        (function (w, r) {
            w._rwq = r;
            w[r] =
                w[r] ||
                function () {
                    (w[r].q = w[r].q || []).push(arguments);
                };
            const timer = setTimeout(() => setLoading(false), LIBRARY_LOADING_TIMEOUT);
            w.rewardful('ready', function () {
                if (w.Rewardful.referral) {
                    setReferralId(w.Rewardful.referral);
                }
                setLoading(false);
                clearTimeout(timer);
            });
        })(window, 'rewardful');
    }, []);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://r.wdfl.co/rw.js';
        script.setAttribute('data-rewardful', process.env.REACT_APP_REWARDFUL_API_KEY);
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return { loading, referralId };
};

export default useReferral;
