export default function ArrowUpOnSquareStackIcon({ className = '', style = {} }) {
    return (
        <svg
            width="22"
            height="22"
            viewBox="0 0 24 25"
            fill="none"
            stroke="currentColor"
            strokeWidth="1.7"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            className={className}
            style={style}>
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M7.5 7.5h-.75A2.25 2.25 0 004.5 9.75v7.5a2.25 2.25 0 002.25 2.25h7.5a2.25 2.25 0 002.25-2.25v-7.5a2.25 2.25 0 00-2.25-2.25h-.75m0-3l-3-3m0 0l-3 3m3-3v11.25m6-2.25h.75a2.25 2.25 0 012.25 2.25v7.5a2.25 2.25 0 01-2.25 2.25h-7.5a2.25 2.25 0 01-2.25-2.25v-.75"
            />
        </svg>
    );
}
