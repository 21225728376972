const APP_STYLES = {
    BOX_SHADOW: {
        SM: '0px 1px 2px rgba(0, 0, 0, 0.05)',
        DEFAULT: '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)',
        MD: '0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.06)',
        LG: '0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)',
        XL: '0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04)',
        WRAP: '0px 4px 4px 1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.06), 0px 0px 4px -1px rgba(0, 0, 0, 0.12)',
        NONE: 'none',
    },
    SCREENS: {
        LG_MIN_WIDTH: 1024, // matches the default Tailwind lg screen breakpoint
        CREATOR_MIN_WIDTH: '1194px',
        WORKBOOK_VIEWER_MIN_WIDTH: '900px',
        WORKBOOK_VIEWER_EMBED_MIN_WIDTH: '1564px',
        WORKBOOK_VIEWER_EMBED_SHOW_PADDED_CONTENT_MIN_WIDTH: 1505, // px suffix needs to be added manually for this property
    },
    FONT_FAMILY: {
        WOBO: 'Inter',
    },
    SPACING: {
        1.75: '0.4375rem /* 7px */',
        2.25: '0.5625rem /* 9px */',
        3.25: '0.8125rem /* 13px */',
        4.5: '1.125rem /* 18px */',
        8.5: '2.125rem /* 34px */',
    },
    BORDER_WIDTH: {
        0.5: '0.5px',
    },
    COLORS: {
        STANDARD: {
            BLACK: 'rgba(0,0,0,1)',
            WHITE: 'rgba(255,255,255,1)',
        },
        PRIMARY: {
            50: 'rgba(243,247,255,1)', // #F3F7FF
            100: 'rgba(223,231,251,1)', // #DFE7FB
            200: 'rgba(184,203,248,1)', // #B8CBF8
            300: 'rgba(121,159,245,1)', // #799FF5
            400: 'rgba(92,139,244,1)', // #5C8BF4
            500: 'rgba(53,112,244,1)', // #3570F4
            600: 'rgba(19,81,222,1)', // #1351DE
        },
        NEUTRAL: {
            25: 'rgba(249,250,251,1)', // #F9FAFB
            50: 'rgba(243,243,243,1)', // #F3F3F3
            100: 'rgba(217,217,217,1)', // #D9D9D9
            200: 'rgba(184,184,184,1)', // #B8B8B8
            300: 'rgba(154,151,151,1)', // #9A9797
            400: 'rgba(87,87,87,1)', // #575757
            500: 'rgba(39,39,39,1)', // #272727
        },
        ERROR: {
            50: 'rgba(255,222,214,1)', // #FFDED6
            100: 'rgba(255,192,176,1)', // #FFC0B0
            200: 'rgba(246,132,104,1)', // #F68468
            300: 'rgba(244,94,57,1)', // #F45E39
            400: 'rgba(232,68,28,1)', // #E8441C
            500: 'rgba(216,58,20,1)', // #D83A14
        },
        WARNING: {
            50: 'rgba(255,241,228,1)', // #FFF1E4
            100: 'rgba(255,226,200,1)', // #FFC0B0
            200: 'rgba(254,209,168,1)', // #FED1A8
            300: 'rgba(255,180,112,1)', // #FFB470
            400: 'rgba(250,160,78,1)', // #FAA04E
            500: 'rgba(244,140,44,1)', // #F48C2C
        },
        SUCCESS: {
            50: 'rgba(236,253,245,1)', // #ECFDF5
            100: 'rgba(188,255,234,1)', // #BCFFEA
            200: 'rgba(157,250,221,1)', // #9DFADD
            300: 'rgba(66,229,179,1)', // #42E5B3
            400: 'rgba(26,195,143,1)', // #1AC38F
            500: 'rgba(5,150,105,1)', // #059669
        },
    },
    HEIGHT: {
        EM: '1em',
        EM_2: '1.2em',
    },
    WIDTH: {
        EM: '1em',
        EM_2: '1.2em',
    },
    MIN_WIDTH: {
        BADGE: '61px',
    },
    MAX_WIDTH: {
        BADGE: '220px',
    },
    TRANSITION_TIMING_FUNCTION: {
        IN_OUT_QUAD: 'cubic-bezier(0.45, 0, 0.55, 1)',
    },
    CURSOR: {
        GRAB: 'grab',
    },
    Z_INDEX: {
        60: 60,
        70: 70,
        80: 80,
        90: 90,
        100: 100,
        110: 110,
    },
    NAVBAR_HEIGHT: {
        MOBILE: '48px',
        DESKTOP: '64px',
        EMBED_MOBILE: '48px',
        EMBED_DESKTOP: '42px',
    },
    EMBED_VIEWER_TOP_PADDING_LG: '24px',
    NAV_BUTTON_CONTAINER_HEIGHT: '68px',
};

const DEFAULT_SCHEMA_STYLES = {
    PAGE_COLOR: 'rgba(255,255,255,1)',
    TEXT_STYLING: {
        TITLE: {
            COLOR: APP_STYLES.COLORS.STANDARD.BLACK,
        },
        SUBTITLE: {
            COLOR: APP_STYLES.COLORS.STANDARD.BLACK,
        },
        BODY: {
            COLOR: APP_STYLES.COLORS.STANDARD.BLACK,
        },
    },
    BUTTON_STYLING: {
        FILL_COLOR: 'rgba(0,125,255,1)',
        TEXT_COLOR: APP_STYLES.COLORS.STANDARD.WHITE,
        HOVER_COLOR: 'rgba(154,201,255,1)',
        HOVER_TEXT_COLOR: APP_STYLES.COLORS.STANDARD.BLACK,
    },
    LINK_STYLING: {
        TEXT_COLOR: APP_STYLES.COLORS.STANDARD.BLACK,
    },
    TABLE_STYLING: {
        HEADER_BACKGROUND_COLOR: 'rgba(229,229,229,1)',
        LINE_COLOR: 'rgb(134,134,134,1)',
    },
};

module.exports = {
    APP_STYLES,
    DEFAULT_SCHEMA_STYLES,
};
