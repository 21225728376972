import classNames from 'classnames';

export default function ErrorIcon({ className = '', style = {} }) {
    return (
        <svg
            width="62"
            height="61"
            fill="none"
            viewBox="0 0 62 61"
            xmlns="http://www.w3.org/2000/svg"
            className={classNames('w-em', className)}
            style={style}>
            <path fill="#FFB470" d="m50.906 6.704 8.208 8.208-33.421 33.421-8.208-8.208z" />
            <path
                d="m25.872 43.69-8.465 3.422-4.435-4.434 4.031-7.857m8.869 8.869 29.655-28.876M25.872 43.69l-4.434-4.435m-4.435-4.434L46.658 5.946M17.003 34.82l4.435 4.434m25.22-33.31 3.556-3.555 8.869 8.868-3.556 3.556m-8.869-8.868 4.434 4.434m4.435 4.434-4.434-4.434m0 0L21.438 39.255"
                stroke="#272727"
                strokeWidth="2.728"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="m7.383 50.228-.911-3.65-4.968 3.385 5.879.265Z"
                fill="#272727"
                stroke="#272727"
                strokeWidth="2.728"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="m5.605 40.126-3.632-2.46"
                stroke="#3570F4"
                strokeWidth="2.728"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="m16.182 53.968 3.633 2.46"
                stroke="#F45E39"
                strokeWidth="2.728"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.535 39.19v-4.688"
                stroke="#3570F4"
                strokeWidth="2.728"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.252 54.904v4.687"
                stroke="#F45E39"
                strokeWidth="2.728"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
