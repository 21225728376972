import { useReducer } from 'react';
import { getUserStatusLabel } from '@/auth/helpers';

export const ACTIONS = {
    SET: 'set',
    UPDATE_STUDENTS: 'update_students',
    UPDATE_COHORT: 'update_cohort',
};

export const defaultDataQueryOptions = () => ({
    $skip: 0,
    $top: 10,
    $orderby: 'first_name asc',
});

export const getDefaultState = () => {
    return {
        initialized: false,
        id: '',
        name: '',
        evergreen: false,
        startDate: null,
        endDate: null,
        workbook: {},
        students: [],
        studentEmailsDictionary: {},
        dataQueryOptions: defaultDataQueryOptions(),
    };
};

const getStudentWithDisplayData = (student, moduleIds) => {
    let openedModules = 0;
    moduleIds.forEach((id) => {
        if (student.modulesProgress[id] && student.modulesProgress[id].opened) openedModules += 1;
    });
    return {
        ...student,
        statusLabel: getUserStatusLabel(student.status),
        progress: moduleIds.length === 0 ? 0 : openedModules / moduleIds.length,
    };
};

const getStudentsData = (arr, moduleIds) => {
    const students = [];
    const emailsDictionary = {};
    arr.forEach((student, i) => {
        students.push(getStudentWithDisplayData(student, moduleIds));
        emailsDictionary[student.email] = i;
    });
    return [students, emailsDictionary];
};

export const studentsReducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.SET: {
            const { payload } = action;
            const [students, studentEmailsDictionary] = getStudentsData(
                payload.students.data,
                payload.workbook.moduleIds,
            );
            return {
                initialized: true,
                id: payload.id,
                name: payload.name,
                evergreen: payload.evergreen,
                startDate: payload.startDate,
                endDate: payload.endDate,
                workbook: payload.workbook,
                students: {
                    total: payload.students.total,
                    data: students,
                },
                studentEmailsDictionary,
                dataQueryOptions: defaultDataQueryOptions(),
            };
        }
        case ACTIONS.UPDATE_STUDENTS: {
            const { students: newStudents, dataQueryOptions } = action.payload;
            const { data, total } = newStudents;
            const [students, studentEmailsDictionary] = getStudentsData(data, state.workbook.moduleIds);
            return {
                ...state,
                students: {
                    total,
                    data: students,
                },
                dataQueryOptions,
                studentEmailsDictionary,
            };
        }
        case ACTIONS.UPDATE_COHORT: {
            const { name, startDate, endDate } = action.payload;
            return {
                ...state,
                name,
                startDate,
                endDate,
            };
        }
        default:
            throw new Error('Action not supported ');
    }
};

const useCohortReducer = () => {
    return useReducer(studentsReducer, getDefaultState());
};

export default useCohortReducer;
