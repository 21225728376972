import CreatorSelectorButton from '@/navigation/CreatorSelectorButton';
import DashboardBackground from '@/system/DashboardBackground';
import HeadingWithOutline, { OUTLINE_SIZES } from '@/system/HeadingWithOutline';

export default function CreatorSelector({ creatorOptions, onSelect }) {
    return (
        <div className="relative">
            <DashboardBackground />
            <div className="relative z-10 mx-auto w-11/12 pt-10 md:w-1/2 md:pt-18 xl:w-1/3">
                <div className="flex w-full flex-col justify-center">
                    <HeadingWithOutline
                        label="Welcome Back!"
                        size={OUTLINE_SIZES.LG}
                        className="mb-5"
                        outlineClassName="-top-4 -left-2 md:-left-4"
                    />
                    <p className="paragraph__md mb-10">Choose an instructor to view their workbooks.</p>

                    <div className="mb-5 space-y-5">
                        {creatorOptions.map((creatorOption) => (
                            <CreatorSelectorButton
                                key={creatorOption.id}
                                label={creatorOption.label}
                                onClick={() => onSelect(creatorOption.id)}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
