/* eslint-disable react/jsx-props-no-spreading */
import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Transition } from '@headlessui/react';
import { SecondaryButton, BUTTON_SIZE } from '@/system/Buttons';
import CreatorEmailSignup from '@/auth/CreatorEmailSignup';
import { ThinkificButton, GoogleButton } from '@/system/BrandedButtons';
import ThinkificLogin from '@/auth/ThinkificLogin';
import { getAnimationProps, getThinkificCreatorSignupSsoParams, getGoogleCreatorSignupSsoParams } from '@/util/helpers';
import { AUTHENTICATION_TYPES, ROUTES, GOOGLE_AUTHORIZE_URL } from '@/util/constants';
import useReferral from '@/referral/useReferral';
import useQueryParameter from '@/util/useQueryParameter';
import LoadingSpinner from '@/system/LoadingSpinner';
import { getSearch } from '@/api/helpers';

export default function Signup() {
    const isMounted = useRef(true);
    const [loading, setLoading] = useState(true);
    const [redirecting, setRedirecting] = useState(false);
    const [signupType, setSignupType] = useState(null);
    const { loading: loadingReferral, referralId } = useReferral();
    const type = useQueryParameter('type');
    const referredFromThinkific = useQueryParameter('referred_from_thinkific') === 'true';

    const handleEmailSignupClick = () => {
        setSignupType(AUTHENTICATION_TYPES.EMAIL);
    };

    const handleThinkificSignupClick = () => {
        setSignupType(AUTHENTICATION_TYPES.THINKIFIC);
    };

    const handleGoogleSignupClick = async () => {
        setRedirecting(true);
        window.location.href = `${GOOGLE_AUTHORIZE_URL}${getSearch(
            getGoogleCreatorSignupSsoParams(ROUTES.CREATOR.GOOGLE_SIGNUP_SUCCESS, referralId),
        )}`;
    };

    const getSsoParams = () => {
        return getThinkificCreatorSignupSsoParams(
            ROUTES.CREATOR.THINKIFIC_SIGNUP_SUCCESS,
            null,
            referralId,
            referredFromThinkific,
        );
    };

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (isMounted.current) {
            setSignupType(type);
            setLoading(false);
        }
    }, [type]);

    if (loading || loadingReferral) return <LoadingSpinner />;

    return (
        <>
            {redirecting && <LoadingSpinner />}
            {!signupType && (
                <div className="absolute top-1/2 left-1/2 flex min-w-[80%] -translate-x-1/2 -translate-y-1/2 flex-col items-center justify-center lg:min-w-full">
                    <img src="/images/logo.svg" className="mb-10 w-32" alt="Logo" />
                    <div className="flex flex-col items-center justify-center">
                        <p className="heading__md--medium mb-8.5">Create your Wobo account</p>

                        <div className="mb-8.5 flex flex-col items-center justify-center space-y-3">
                            <ThinkificButton
                                label="Sign up with Thinkific"
                                onClick={handleThinkificSignupClick}
                                size={BUTTON_SIZE.LG}
                                className="w-[360px]"
                            />
                            <p className="caption">or</p>
                            <div className="grid grid-cols-2 gap-4">
                                <GoogleButton
                                    label="Google"
                                    onClick={handleGoogleSignupClick}
                                    size={BUTTON_SIZE.LG}
                                    className="w-[172px]"
                                />
                                <SecondaryButton
                                    label="Sign up with Email"
                                    onClick={handleEmailSignupClick}
                                    size={BUTTON_SIZE.LG}
                                    className="w-[172px]"
                                />
                            </div>
                        </div>

                        <div className="paragraph__sm text-center">
                            <span className="mr-1">Already have an account? Return to</span>
                            <Link className="paragraph__sm--semibold" to={ROUTES.CREATOR.LOGIN}>
                                Log In
                            </Link>
                        </div>
                    </div>
                </div>
            )}

            <Transition
                show={signupType === AUTHENTICATION_TYPES.EMAIL}
                {...getAnimationProps(
                    { transition: 'duration-300 ease-in', from: 'opacity-0', to: 'opacity-100' },
                    { transition: 'duration-300 ease-out', from: 'opacity-100', to: 'opacity-0' },
                )}>
                <CreatorEmailSignup />
            </Transition>
            <Transition
                show={signupType === AUTHENTICATION_TYPES.THINKIFIC}
                {...getAnimationProps(
                    { transition: 'duration-300 ease-in', from: 'opacity-0', to: 'opacity-100' },
                    { transition: 'duration-300 ease-out', from: 'opacity-100', to: 'opacity-0' },
                )}>
                <ThinkificLogin signup getSsoParams={getSsoParams} />
            </Transition>
        </>
    );
}
