import MobileModuleNav from '@/workbook/student/sidebar/MobileModuleNav';
import DesktopModuleNav from '@/workbook/student/sidebar/DesktopModuleNav';

export default function Sidebar({ workbook, selectModule, selectSection, preview, addOns, showAddOns }) {
    return (
        <>
            <DesktopModuleNav
                workbook={workbook}
                onModuleSelect={selectModule}
                onSectionSelect={selectSection}
                addOns={addOns}
                showAddOns={showAddOns}
            />
            <MobileModuleNav workbook={workbook} onModuleSelect={selectModule} preview={preview} addOns={addOns} />
        </>
    );
}
