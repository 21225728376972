import RichTextEditorDebounce from '@/system/RichTextEditorDebounce';
import TextInputDebounce from '@/system/TextInputDebounce';
import ToggleRow from '@/workbook/builder/sections/ToggleRow';
import SectionTitle from '@/system/SectionTitle';
import SectionDescription from '@/system/SectionDescription';

export const TextFieldEditor = ({ schema, setSectionSchemaProp, saveSectionSchemaProp }) => {
    const { title, description } = schema;

    const handleTitleToggleChange = () => {
        saveSectionSchemaProp('title.show', !title.show);
    };

    const handleTitleChange = (e) => {
        const { value: text } = e.target;
        setSectionSchemaProp('title.text', text);
    };

    const handleTitleTypingEnd = (text) => {
        saveSectionSchemaProp('title.text', text);
    };

    const handleDescriptionToggleChange = () => {
        saveSectionSchemaProp('description.show', !description.show);
    };

    const handleDescriptionChange = (text) => {
        setSectionSchemaProp('description.text', text);
    };

    const handleDescriptionTypingEnd = (text) => {
        saveSectionSchemaProp('description.text', text);
    };

    return (
        <>
            <ToggleRow label="Title" enabled={title.show} onChange={handleTitleToggleChange} />
            <TextInputDebounce value={title.text} onChange={handleTitleChange} onTypingEnd={handleTitleTypingEnd} />
            <ToggleRow label="Description" enabled={description.show} onChange={handleDescriptionToggleChange} />
            <RichTextEditorDebounce
                value={description.text}
                onChange={handleDescriptionChange}
                onTypingEnd={handleDescriptionTypingEnd}
            />
        </>
    );
};

export const TextField = ({ workbookSchema, schema }) => {
    const { title, description } = schema;

    return (
        <>
            {title.show && (
                <SectionTitle
                    text={title.text}
                    style={{
                        fontFamily: workbookSchema.textStyling.subtitle.fontFamily,
                        color: workbookSchema.textStyling.subtitle.color,
                    }}
                />
            )}
            {description.show && (
                <SectionDescription
                    text={description.text}
                    style={{
                        fontFamily: workbookSchema.textStyling.body.fontFamily,
                        color: workbookSchema.textStyling.body.color,
                    }}
                />
            )}
        </>
    );
};

export const TextFieldArtifact = ({ workbookSchema, schema }) => {
    const { title, description } = schema;

    return (
        <>
            {title.show && (
                <SectionTitle
                    text={title.text}
                    style={{
                        fontFamily: workbookSchema.textStyling.subtitle.fontFamily,
                        color: workbookSchema.textStyling.subtitle.color,
                    }}
                />
            )}
            {description.show && (
                <SectionDescription
                    text={description.text}
                    style={{
                        fontFamily: workbookSchema.textStyling.body.fontFamily,
                        color: workbookSchema.textStyling.body.color,
                    }}
                />
            )}
        </>
    );
};
