/* eslint-disable import/prefer-default-export */
import { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';
import { debounce } from 'lodash';
import {
    LOGO_SIZES,
    POSITIONS_X,
    POSITIONS_Y,
    TEXT_ALIGNMENTS,
    COVER_PAGE_SECTION_ROOT_FONT_SIZE,
    COVER_PAGE_SECTION_MOBILE_ROOT_FONT_SIZE,
    COVER_PAGE_SECTION_EDITOR,
    ARTIFACT_PAGE_FORMATS,
    ROUTES,
    SETTINGS_SECTIONS,
} from '@/util/constants';
import { getStorageUrl, getUniqueComponentClassName, getRelativeFontSize } from '@/util/helpers';
import ImageUploader from '@/system/ImageUploader';
import Divider from '@/system/Divider';
import RadioGroup from '@/system/RadioGroup';
import GroupHeading from '@/system/GroupHeading';
import TextareaDebounce from '@/system/TextareaDebounce';
import SelectList from '@/system/SelectList';
import ToggleRow from '@/workbook/builder/sections/ToggleRow';
import TextAlignPicker from '@/system/TextAlignPicker';
import RangeSlider from '@/system/RangeSlider';
import { useToast } from '@/system/ToastProvider';
import Label from '@/system/Label';
import PoweredByWobo from '@/workbook/builder/sections/PoweredByWobo';

const radioGroupSizes = [
    { id: LOGO_SIZES.SMALL.ID, label: LOGO_SIZES.SMALL.LABEL },
    { id: LOGO_SIZES.MEDIUM.ID, label: LOGO_SIZES.MEDIUM.LABEL },
    { id: LOGO_SIZES.LARGE.ID, label: LOGO_SIZES.LARGE.LABEL },
];

const selectPositionXOptions = [
    { id: POSITIONS_X.LEFT, value: POSITIONS_X.LEFT, label: POSITIONS_X.LEFT },
    { id: POSITIONS_X.MIDDLE, value: POSITIONS_X.MIDDLE, label: POSITIONS_X.MIDDLE },
    { id: POSITIONS_X.RIGHT, value: POSITIONS_X.RIGHT, label: POSITIONS_X.RIGHT },
];

const selectPositionYOptionsLogo = [
    { id: POSITIONS_Y.TOP, value: POSITIONS_Y.TOP, label: POSITIONS_Y.TOP },
    { id: POSITIONS_Y.MIDDLE, value: POSITIONS_Y.MIDDLE, label: POSITIONS_Y.MIDDLE },
    { id: POSITIONS_Y.BOTTOM, value: POSITIONS_Y.BOTTOM, label: POSITIONS_Y.BOTTOM },
];

const selectPositionYOptionsTitle = [
    { id: POSITIONS_Y.TOP, value: POSITIONS_Y.TOP, label: POSITIONS_Y.TOP },
    { id: POSITIONS_Y.ONE_THIRD, value: POSITIONS_Y.ONE_THIRD, label: POSITIONS_Y.ONE_THIRD },
    { id: POSITIONS_Y.MIDDLE, value: POSITIONS_Y.MIDDLE, label: POSITIONS_Y.MIDDLE },
    { id: POSITIONS_Y.TWO_THIRDS, value: POSITIONS_Y.TWO_THIRDS, label: POSITIONS_Y.TWO_THIRDS },
    { id: POSITIONS_Y.BOTTOM, value: POSITIONS_Y.BOTTOM, label: POSITIONS_Y.BOTTOM },
];

const debounceTimeout = 100;

export const WorkbookCoverPageEditor = ({
    schema,
    creatorSchema,
    setSectionSchemaProp,
    saveSectionSchemaProp,
    saveSectionFile,
    resetSectionFile,
    updateWorkbookThumbnail,
}) => {
    const { hidden, title, subtitle, image, logo } = schema;
    const { pushError } = useToast();
    const isA4PageFormat = creatorSchema.artifact.pageFormat === ARTIFACT_PAGE_FORMATS.A4;

    const handleLogoSizeChange = (value) => {
        saveSectionSchemaProp('logo.size', value);
        updateWorkbookThumbnail();
    };

    const handleLogoPositionXChange = (value) => {
        saveSectionSchemaProp('logo.position.x', value);
        updateWorkbookThumbnail();
    };

    const handleLogoPositionYChange = (value) => {
        saveSectionSchemaProp('logo.position.y', value);
        updateWorkbookThumbnail();
    };

    const handleTitleToggleChange = () => {
        saveSectionSchemaProp('title.show', !title.show);
        updateWorkbookThumbnail();
    };

    const handleTitleChange = (e) => {
        const { value: text } = e.target;
        setSectionSchemaProp('title.text', text);
    };

    const handleTitleTypingEnd = (text) => {
        saveSectionSchemaProp('title.text', text);
        updateWorkbookThumbnail();
    };

    const handleDescriptionToggleChange = () => {
        saveSectionSchemaProp('subtitle.show', !subtitle.show);
    };

    const handleDescriptionChange = (e) => {
        const { value: text } = e.target;
        setSectionSchemaProp('subtitle.text', text);
    };

    const handleDescriptionTypingEnd = (text) => {
        saveSectionSchemaProp('subtitle.text', text);
        updateWorkbookThumbnail();
    };

    const handleImageSelect = async (fileData) => {
        updateWorkbookThumbnail();
        return saveSectionFile('image', fileData);
    };

    const handleImageRemove = () => {
        resetSectionFile('image');
        updateWorkbookThumbnail();
    };

    const handleFileLoaderError = (error) => {
        pushError(error, null);
    };

    const handleTextAlignmentClick = (alignment) => {
        saveSectionSchemaProp('title.align', alignment);
        updateWorkbookThumbnail();
    };

    const handleTitleFontSizeChange = (e) => {
        setSectionSchemaProp('title.styling.fontSize', e.target.value);
    };

    const handleTitleFontSizeBlur = (e) => {
        saveSectionSchemaProp('title.styling.fontSize', e.target.value);
        updateWorkbookThumbnail();
    };

    const handleTitleLetterSpacingChange = (e) => {
        setSectionSchemaProp('title.styling.letterSpacing', e.target.value);
    };

    const handleTitleLetterSpacingBlur = (e) => {
        saveSectionSchemaProp('title.styling.letterSpacing', e.target.value);
        updateWorkbookThumbnail();
    };

    const handleTitleLineHeightChange = (e) => {
        setSectionSchemaProp('title.styling.lineHeight', e.target.value);
    };

    const handleTitleLineHeightBlur = (e) => {
        saveSectionSchemaProp('title.styling.lineHeight', e.target.value);
        updateWorkbookThumbnail();
    };

    const handleSubtitleFontSizeChange = (e) => {
        setSectionSchemaProp('subtitle.styling.fontSize', e.target.value);
    };

    const handleSubtitleFontSizeBlur = (e) => {
        saveSectionSchemaProp('subtitle.styling.fontSize', e.target.value);
        updateWorkbookThumbnail();
    };

    const handleSubtitleLetterSpacingChange = (e) => {
        setSectionSchemaProp('subtitle.styling.letterSpacing', e.target.value);
    };

    const handleSubtitleLetterSpacingBlur = (e) => {
        saveSectionSchemaProp('subtitle.styling.letterSpacing', e.target.value);
        updateWorkbookThumbnail();
    };

    const handleSubtitleLineHeightChange = (e) => {
        setSectionSchemaProp('subtitle.styling.lineHeight', e.target.value);
    };

    const handleSubtitleLineHeightBlur = (e) => {
        saveSectionSchemaProp('subtitle.styling.lineHeight', e.target.value);
        updateWorkbookThumbnail();
    };

    const handleTitlePositionYChange = (value) => {
        saveSectionSchemaProp('title.position.y', value);
        updateWorkbookThumbnail();
    };

    const handleDisplayCoverPage = () => {
        saveSectionSchemaProp('hidden', !hidden);
        updateWorkbookThumbnail();
    };

    return (
        <>
            <ToggleRow label="Hide Cover Page" enabled={hidden} onChange={handleDisplayCoverPage} />
            <Divider />
            <ImageUploader
                title="Cover Editor"
                imageName={image.filename}
                buttonLabel="Upload cover image"
                onSelect={handleImageSelect}
                onRemove={handleImageRemove}
                onFileLoaderError={handleFileLoaderError}
            />
            <p className="caption mb-3.5">
                Accepted file types: png and jpg.
                <br />
                Suggested dimensions: {isA4PageFormat ? '595 x 842' : '612 x 792'} pixels
            </p>
            <p className="caption text-neutral-300">
                Page dimensions are set to {isA4PageFormat ? 'A4' : 'US Letter'}.{' '}
                <Link
                    to={`${ROUTES.CREATOR.SETTINGS}?section=${SETTINGS_SECTIONS.WORKBOOK_SETTINGS}`}
                    className="underline">
                    Change in settings
                </Link>
            </p>
            <Divider />
            <GroupHeading heading="Logo" />
            <p className="caption mb-3.5">Upload your logo in the Global Styles tab.</p>
            <Label desc="Size" className="mb-2" />
            <RadioGroup value={logo.size} onChange={handleLogoSizeChange} options={radioGroupSizes} className="mb-2" />
            <Label desc="Positioning" className="mb-2" />
            <div className="mb-3.5">
                <SelectList
                    value={logo.position.x}
                    options={selectPositionXOptions}
                    onChange={handleLogoPositionXChange}
                />
            </div>
            <SelectList
                value={logo.position.y}
                options={selectPositionYOptionsLogo}
                onChange={handleLogoPositionYChange}
            />
            <Divider />
            <GroupHeading heading="Content" />
            <div className="space-y-3.5">
                <div className="space-y-3.5">
                    <div>
                        <ToggleRow label="Title" enabled={title.show} onChange={handleTitleToggleChange} />
                        <TextareaDebounce
                            value={title.text}
                            onChange={handleTitleChange}
                            onTypingEnd={handleTitleTypingEnd}
                            resizeToFit
                        />
                    </div>
                    <div>
                        <Label desc={`Size: ${title.styling.fontSize}px`} className="mb-2" />
                        <RangeSlider
                            value={title.styling.fontSize}
                            step={COVER_PAGE_SECTION_EDITOR.TITLE.FONT_SIZE.STEP}
                            min={COVER_PAGE_SECTION_EDITOR.TITLE.FONT_SIZE.MIN}
                            max={COVER_PAGE_SECTION_EDITOR.TITLE.FONT_SIZE.MAX}
                            onChange={handleTitleFontSizeChange}
                            onBlur={handleTitleFontSizeBlur}
                        />
                    </div>
                    <div>
                        <Label desc={`Letter Spacing: ${title.styling.letterSpacing}em`} className="mb-2" />
                        <RangeSlider
                            value={title.styling.letterSpacing}
                            step={COVER_PAGE_SECTION_EDITOR.TITLE.LETTER_SPACING.STEP}
                            min={COVER_PAGE_SECTION_EDITOR.TITLE.LETTER_SPACING.MIN}
                            max={COVER_PAGE_SECTION_EDITOR.TITLE.LETTER_SPACING.MAX}
                            onChange={handleTitleLetterSpacingChange}
                            onBlur={handleTitleLetterSpacingBlur}
                        />
                    </div>
                    <div>
                        <Label desc={`Line Height: ${title.styling.lineHeight}px`} className="mb-2" />
                        <RangeSlider
                            value={title.styling.lineHeight}
                            step={COVER_PAGE_SECTION_EDITOR.TITLE.LINE_HEIGHT.STEP}
                            min={COVER_PAGE_SECTION_EDITOR.TITLE.LINE_HEIGHT.MIN}
                            max={COVER_PAGE_SECTION_EDITOR.TITLE.LINE_HEIGHT.MAX}
                            onChange={handleTitleLineHeightChange}
                            onBlur={handleTitleLineHeightBlur}
                        />
                    </div>
                </div>
                <div className="space-y-3.5">
                    <div>
                        <ToggleRow
                            label="Description"
                            enabled={subtitle.show}
                            onChange={handleDescriptionToggleChange}
                        />
                        <TextareaDebounce
                            value={subtitle.text}
                            onChange={handleDescriptionChange}
                            onTypingEnd={handleDescriptionTypingEnd}
                            resizeToFit
                        />
                    </div>
                    <div>
                        <Label desc={`Size: ${subtitle.styling.fontSize}px`} className="mb-2" />
                        <RangeSlider
                            value={subtitle.styling.fontSize}
                            step={COVER_PAGE_SECTION_EDITOR.SUBTITLE.FONT_SIZE.STEP}
                            min={COVER_PAGE_SECTION_EDITOR.SUBTITLE.FONT_SIZE.MIN}
                            max={COVER_PAGE_SECTION_EDITOR.SUBTITLE.FONT_SIZE.MAX}
                            onChange={handleSubtitleFontSizeChange}
                            onBlur={handleSubtitleFontSizeBlur}
                        />
                    </div>
                    <div>
                        <Label desc={`Letter Spacing: ${subtitle.styling.letterSpacing}em`} className="mb-2" />
                        <RangeSlider
                            value={subtitle.styling.letterSpacing}
                            step={COVER_PAGE_SECTION_EDITOR.SUBTITLE.LETTER_SPACING.STEP}
                            min={COVER_PAGE_SECTION_EDITOR.SUBTITLE.LETTER_SPACING.MIN}
                            max={COVER_PAGE_SECTION_EDITOR.SUBTITLE.LETTER_SPACING.MAX}
                            onChange={handleSubtitleLetterSpacingChange}
                            onBlur={handleSubtitleLetterSpacingBlur}
                        />
                    </div>
                    <div>
                        <Label desc={`Line Height: ${subtitle.styling.lineHeight}px`} className="mb-2" />
                        <RangeSlider
                            value={subtitle.styling.lineHeight}
                            step={COVER_PAGE_SECTION_EDITOR.SUBTITLE.LINE_HEIGHT.STEP}
                            min={COVER_PAGE_SECTION_EDITOR.SUBTITLE.LINE_HEIGHT.MIN}
                            max={COVER_PAGE_SECTION_EDITOR.SUBTITLE.LINE_HEIGHT.MAX}
                            onChange={handleSubtitleLineHeightChange}
                            onBlur={handleSubtitleLineHeightBlur}
                        />
                    </div>
                </div>
                <Label desc="Alignment" />
                <TextAlignPicker value={title.align} onClick={handleTextAlignmentClick} />
                <Label desc="Positioning" className="mb-2" />
                <SelectList
                    value={title.position.y}
                    options={selectPositionYOptionsTitle}
                    onChange={handleTitlePositionYChange}
                />
            </div>
        </>
    );
};

const getHeightByLogoSize = (size) => {
    switch (size) {
        case LOGO_SIZES.SMALL.ID:
            return '25px';
        case LOGO_SIZES.LARGE.ID:
            return '75px';
        default:
            return '50px';
    }
};

const getLogoPositionXClasses = (positionX) => {
    switch (positionX) {
        case POSITIONS_X.MIDDLE:
            return 'left-1/2 -translate-x-1/2';
        case POSITIONS_X.RIGHT:
            return 'right-8';
        default:
            return 'left-8';
    }
};

const getLogoPositionYClasses = (positionY) => {
    switch (positionY) {
        case POSITIONS_Y.MIDDLE:
            return 'top-1/2 -translate-y-1/2';
        case POSITIONS_Y.BOTTOM:
            return 'bottom-8';
        default:
            return 'top-8';
    }
};

const getTitlePositionYClasses = (positionY) => {
    switch (positionY) {
        case POSITIONS_Y.TWO_THIRDS:
            return 'top-2/3 -translate-y-1/2';
        case POSITIONS_Y.MIDDLE:
            return 'top-1/2 -translate-y-1/2';
        case POSITIONS_Y.ONE_THIRD:
            return 'top-1/3 -translate-y-1/2';
        case POSITIONS_Y.BOTTOM:
            return 'top-3/4 -translate-y-1/2';
        default:
            return 'top-1/4 -translate-y-1/2';
    }
};

const getTitleTextAlign = (align) => {
    switch (align) {
        case TEXT_ALIGNMENTS.CENTER:
            return 'text-center';
        case TEXT_ALIGNMENTS.RIGHT:
            return 'text-right';
        default:
            return 'text-left';
    }
};

const getTitleStyles = (styling) => {
    return {
        fontSize: `${styling.fontSize || 36}px`,
        letterSpacing: `${styling.letterSpacing || 0.01}em`,
        lineHeight: `${styling.lineHeight || 41}px`,
    };
};

const getSubtitleStyles = (styling) => {
    return {
        fontSize: `${styling.fontSize || 20}px`,
        letterSpacing: `${styling.letterSpacing || 0.01}em`,
        lineHeight: `${styling.lineHeight || 24}px`,
    };
};

export function WorkbookCoverPage({ schema, workbookSchema, showPoweredByWobo }) {
    const imageContainer = useRef(null);
    const componentClassName = useRef(getUniqueComponentClassName());
    const logoSizeStyles = {
        height: getHeightByLogoSize(schema.logo.size),
    };
    const logoPositionXClasses = getLogoPositionXClasses(schema.logo.position.x);
    const logoPositionYClasses = getLogoPositionYClasses(schema.logo.position.y);
    const logoContainerClasses = classNames('absolute hidden sm:block', logoPositionXClasses, logoPositionYClasses);
    const titlePositionYClasses = getTitlePositionYClasses(schema.title.position.y);
    const titleContainerClasses = classNames('absolute w-full px-8 left-1/2 -translate-x-1/2', titlePositionYClasses);
    const titleTextAlign = getTitleTextAlign(schema.title.align);

    const titleStyles = getTitleStyles(schema.title.styling);
    titleStyles.mobileFontSize = `${getRelativeFontSize(
        parseInt(schema.title.styling.fontSize, 10) || 36,
        COVER_PAGE_SECTION_ROOT_FONT_SIZE,
        COVER_PAGE_SECTION_MOBILE_ROOT_FONT_SIZE,
    )}px`;
    titleStyles.mobileLineHeight = `${getRelativeFontSize(
        parseInt(schema.title.styling.lineHeight, 10) || 41,
        COVER_PAGE_SECTION_ROOT_FONT_SIZE,
        COVER_PAGE_SECTION_MOBILE_ROOT_FONT_SIZE,
    )}px`;

    const subtitleStyles = getSubtitleStyles(schema.subtitle.styling);
    subtitleStyles.mobileFontSize = `${getRelativeFontSize(
        parseInt(schema.subtitle.styling.fontSize, 10) || 20,
        COVER_PAGE_SECTION_ROOT_FONT_SIZE,
        COVER_PAGE_SECTION_MOBILE_ROOT_FONT_SIZE,
    )}px`;
    subtitleStyles.mobileLineHeight = `${getRelativeFontSize(
        parseInt(schema.subtitle.styling.lineHeight, 10) || 24,
        COVER_PAGE_SECTION_ROOT_FONT_SIZE,
        COVER_PAGE_SECTION_MOBILE_ROOT_FONT_SIZE,
    )}px`;

    // Make sure the cover page image has a minimum height to maintain a 8.5 x 11 ratio
    const resizeImageHeight = () => {
        const computedStyles = getComputedStyle(imageContainer.current);

        if (!isMobile) {
            const newHeight = (parseInt(computedStyles.width.replace('px', ''), 10) / 8.5) * 11;
            imageContainer.current.style.minHeight = `${newHeight}px`;
        }
    };

    useEffect(() => {
        if (imageContainer.current) {
            resizeImageHeight();
            window.onresize = debounce(resizeImageHeight, debounceTimeout);
        }
        return () => {
            window.onresize = null;
        };
    }, []);

    return (
        <>
            <style type="text/css">
                {`
                .${componentClassName.current} .workbook-cover-page__title {
                    font-size: ${titleStyles.mobileFontSize};
                    letter-spacing: ${titleStyles.letterSpacing};
                    line-height: ${titleStyles.mobileLineHeight};
                }

                .${componentClassName.current} .workbook-cover-page__subtitle {
                    font-size: ${subtitleStyles.mobileFontSize};
                    letter-spacing: ${subtitleStyles.letterSpacing};
                    line-height: ${subtitleStyles.mobileLineHeight};
                }

                @media (min-width: 640px) {
                    .${componentClassName.current} .workbook-cover-page__title {
                        font-size: ${titleStyles.fontSize};
                        line-height: ${titleStyles.lineHeight};
                    }

                    .${componentClassName.current} .workbook-cover-page__subtitle {
                        font-size: ${subtitleStyles.fontSize};
                        line-height: ${subtitleStyles.lineHeight};
                    }
                } 
                `}
            </style>
            <div
                ref={imageContainer}
                className={`${componentClassName.current} relative h-full w-full rounded-md bg-cover bg-center shadow-md`}
                style={
                    schema.image.storage_filename
                        ? {
                              backgroundImage: `url("${getStorageUrl(
                                  `${workbookSchema.storageObjectBasePath}${schema.image.storage_filename}`,
                              )}")`,
                              backgroundColor: workbookSchema.pageColor,
                          }
                        : {
                              backgroundColor: workbookSchema.pageColor,
                          }
                }>
                <PoweredByWobo enable={showPoweredByWobo} overlay />
                {workbookSchema.logo.storage_filename && (
                    <div className={logoContainerClasses}>
                        <img
                            className="object-contain"
                            src={getStorageUrl(
                                `${workbookSchema.storageObjectBasePath}${workbookSchema.logo.storage_filename}`,
                            )}
                            alt="Workbook Logo"
                            style={logoSizeStyles}
                        />
                    </div>
                )}
                {(schema.title.show || schema.subtitle.show) && (
                    <div className={titleContainerClasses}>
                        {schema.title.show && (
                            <pre
                                className={classNames(
                                    'workbook-cover-page__title mb-3 w-full whitespace-pre-wrap break-words font-bold',
                                    titleTextAlign,
                                )}
                                style={{
                                    fontFamily: workbookSchema.textStyling.title.fontFamily,
                                    color: workbookSchema.textStyling.title.color,
                                }}>
                                {schema.title.text}
                            </pre>
                        )}
                        {schema.subtitle.show && (
                            <pre
                                className={classNames(
                                    'workbook-cover-page__subtitle subheading w-full whitespace-pre-wrap break-words',
                                    titleTextAlign,
                                )}
                                style={{
                                    fontFamily: workbookSchema.textStyling.subtitle.fontFamily,
                                    color: workbookSchema.textStyling.subtitle.color,
                                }}>
                                {schema.subtitle.text}
                            </pre>
                        )}
                    </div>
                )}
            </div>
        </>
    );
}

export function WorkbookCoverPageArtifact({ schema, workbookSchema, showPoweredByWobo }) {
    const logoSizeStyles = {
        height: getHeightByLogoSize(schema.logo.size),
    };
    const logoPositionXClasses = getLogoPositionXClasses(schema.logo.position.x);
    const logoPositionYClasses = getLogoPositionYClasses(schema.logo.position.y);
    const logoContainerClasses = classNames('absolute', logoPositionXClasses, logoPositionYClasses);
    const titlePositionYClasses = getTitlePositionYClasses(schema.title.position.y);
    const titleContainerClasses = classNames('absolute w-full px-8 left-1/2 -translate-x-1/2', titlePositionYClasses);
    const titleTextAlign = getTitleTextAlign(schema.title.align);
    const titleStyles = getTitleStyles(schema.title.styling);
    const subtitleStyles = getSubtitleStyles(schema.subtitle.styling);

    return (
        <div className="relative h-full w-full overflow-hidden" style={{ backgroundColor: workbookSchema.pageColor }}>
            {schema.image.storage_filename && (
                <img
                    src={getStorageUrl(`${workbookSchema.storageObjectBasePath}${schema.image.storage_filename}`)}
                    alt="Workbook cover page background"
                    className="absolute w-full h-auto left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 border-0"
                />
            )}
            <PoweredByWobo enable={showPoweredByWobo} overlay />
            {workbookSchema.logo.storage_filename && (
                <div className={logoContainerClasses}>
                    <img
                        src={getStorageUrl(
                            `${workbookSchema.storageObjectBasePath}${workbookSchema.logo.storage_filename}`,
                        )}
                        alt="Workbook Logo"
                        style={logoSizeStyles}
                    />
                </div>
            )}
            {(schema.title.show || schema.subtitle.show) && (
                <div className={titleContainerClasses}>
                    {schema.title.show && (
                        <pre
                            className={classNames(
                                'mb-3 w-full whitespace-pre-wrap break-words font-bold',
                                titleTextAlign,
                            )}
                            style={{
                                ...titleStyles,
                                fontFamily: workbookSchema.textStyling.title.fontFamily,
                                color: workbookSchema.textStyling.title.color,
                            }}>
                            {schema.title.text}
                        </pre>
                    )}
                    {schema.subtitle.show && (
                        <pre
                            className={classNames('subheading w-full whitespace-pre-wrap break-words', titleTextAlign)}
                            style={{
                                ...subtitleStyles,
                                fontFamily: workbookSchema.textStyling.subtitle.fontFamily,
                                color: workbookSchema.textStyling.subtitle.color,
                            }}>
                            {schema.subtitle.text}
                        </pre>
                    )}
                </div>
            )}
        </div>
    );
}
