import { useEffect } from 'react';
import { getTeachableLoginUrl } from '@/util/helpers';
import useQueryParameter from '@/util/useQueryParameter';

export default function TeachableAuthStart() {
    const fromUrlBase64 = useQueryParameter('from_url');
    const fromUrl = fromUrlBase64 ? atob(fromUrlBase64) : null;
    const workbookId = useQueryParameter('workbook_id');

    useEffect(() => {
        function getDomainByUrl(url) {
            return url.split('/')[2];
        }
        if (fromUrl) {
            const { referrer } = window.document;
            if (!referrer) {
                console.log('no referrer, nothing to do here!');
                return;
            }
            const domain = getDomainByUrl(referrer);
            getTeachableLoginUrl(domain, fromUrl, 'true', workbookId)
                .then((url) => {
                    window.location.href = url;
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [fromUrl, workbookId]);

    return <div />;
}
