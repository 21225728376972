import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthApi from '@/api/useAuthApi';
import { isEmailValid } from '@/auth/helpers';
import {
    ROLES,
    WOBO_SUPPORT_DOCS_COOKIE_POLICY,
    WOBO_SUPPORT_DOCS_PRIVACY_POLICY,
    WOBO_SUPPORT_DOCS_TERMS,
    UNVERIFIED_USER_PLACEHOLDER,
} from '@/util/constants';
import { CardWithFooter } from '@/auth/Cards';
import TextInput from '@/system/TextInput';
import useGTM from '@/util/useGTM';
import { getStudentEnrollInPubliclySharedWorkbook } from '@/util/helpers';
import { userFlagsUtil } from '@/util/useUserFlags';
import { useAuth } from '@/auth/AuthProvider';
import ForgotPassword from '@/auth/ForgotPassword';

export default function StudentEmailSignup({ onLoginClick, publiclySharedWorkbookId }) {
    const isMounted = useRef(true);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [checkEmail, setCheckEmail] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState('');
    const [apiErrorMessage, setApiErrorMessage] = useState('');
    const { getUserFlags } = useAuthApi();
    const [validUser, setValidUser] = useState();
    const [needsPasswordReset, setNeedsPasswordReset] = useState(false);
    const [passwordResetSent, setPasswordResetSent] = useState(false);
    const { studentSignupAndEnrollInPubliclySharedWorkbookAndAutoLogin, sendPasswordResetEmail } = useAuth();
    const gtm = useGTM();
    const navigate = useNavigate();

    const validateEmail = (value, checkFormat = true) => {
        let passed = true;
        if (apiErrorMessage) setApiErrorMessage('');
        if (!checkEmail) setCheckEmail(true);
        if (emailErrorMessage) passed = false;
        if (!value) {
            setEmailErrorMessage('email is required');
            passed = false;
        } else {
            setEmailErrorMessage('');
        }
        if (checkFormat && value && !isEmailValid(value)) {
            setEmailErrorMessage('email is not valid');
            passed = false;
        }
        return passed;
    };

    const checkUserFlags = (emailAddress) => {
        getUserFlags(emailAddress)
            .then((data) => {
                if (data?.flags > 0) {
                    const { flags } = data;
                    const flagUtil = userFlagsUtil(flags);
                    if (flagUtil.isEmailNotVerified || flagUtil.isPasswordNotSet) {
                        setNeedsPasswordReset(true);
                        setPasswordResetSent(false);
                    }
                    if (!flagUtil.isEmailNotVerified && !flagUtil.isPasswordNotSet) {
                        setValidUser(true);
                    }
                }
            })
            .catch(() => {});
    };

    const handleEmailChange = (value) => {
        setEmail(value);
        validateEmail(value, false);
        setNeedsPasswordReset(false);
        checkUserFlags(value);
    };

    const handleEmailBlur = (value) => {
        setEmail(value);
        validateEmail(value);
        checkUserFlags(value);
    };

    const checkFields = () => {
        let passed = true;
        if (!validateEmail(email)) passed = false;
        return passed;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!needsPasswordReset) {
            if (!checkFields()) return;
            setLoading(true);
            studentSignupAndEnrollInPubliclySharedWorkbookAndAutoLogin(
                publiclySharedWorkbookId,
                UNVERIFIED_USER_PLACEHOLDER,
                UNVERIFIED_USER_PLACEHOLDER,
                email,
            )
                .then((data) => {
                    gtm.pushSignup('student', 'email-short', data.student.id);
                    setLoading(false);
                    const path = getStudentEnrollInPubliclySharedWorkbook(publiclySharedWorkbookId);
                    navigate(path, { replace: true });
                })
                .catch((error) => {
                    if (isMounted.current) {
                        setApiErrorMessage(error.message);
                        setLoading(false);
                    }
                });
        } else {
            setLoading(true);
            sendPasswordResetEmail(email, ROLES.STUDENT)
                .then(() => {
                    setLoading(false);
                    setPasswordResetSent(true);
                })
                .catch((err) => {
                    setApiErrorMessage(err.message);
                    setPasswordResetSent(false);
                    setLoading(false);
                });
        }
    };

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);

    if (passwordResetSent) {
        return <ForgotPassword className="md:pt-0 md:w-full" emailSentProp title="Email sent!" />;
    }
    return (
        <CardWithFooter
            className="md:pt-0 md:w-full"
            loading={loading}
            onSubmit={handleSubmit}
            buttonLabel={needsPasswordReset ? 'Set Password' : 'Sign up'}
            apiErrorMessage={apiErrorMessage}
            footer={
                <>
                    <div className="caption mb-4">
                        <span className="mr-1">Already have an account?</span>
                        <button
                            type="button"
                            onClick={() => {
                                setTimeout(() => {
                                    onLoginClick();
                                }, 1);
                            }}
                            className="caption--semibold">
                            Log In.
                        </button>
                    </div>
                    <div className="caption">
                        By signing up, you agree to our{' '}
                        <span>
                            <a
                                className="caption--underline"
                                href={WOBO_SUPPORT_DOCS_TERMS}
                                target="_blank"
                                rel="noreferrer">
                                Terms
                            </a>
                            ,{' '}
                            <a
                                className="caption--underline"
                                href={WOBO_SUPPORT_DOCS_PRIVACY_POLICY}
                                target="_blank"
                                rel="noreferrer">
                                Data Policy
                            </a>{' '}
                        </span>
                        and{' '}
                        <span>
                            <a
                                className="caption--underline"
                                href={WOBO_SUPPORT_DOCS_COOKIE_POLICY}
                                target="_blank"
                                rel="noreferrer">
                                Cookies Policy
                            </a>
                            .
                        </span>
                    </div>
                </>
            }>
            <div className="heading__xs--medium mb-1">Email</div>
            <TextInput
                value={email}
                placeholder="email"
                onChange={(e) => handleEmailChange(e.target.value)}
                onBlur={(e) => handleEmailBlur(e.target.value.trim())}
                className="mb-0"
                required
                error={checkEmail && !!emailErrorMessage}
                errorMsg={emailErrorMessage}
            />
            {!validUser && needsPasswordReset && (
                <p className="caption mt-4 text-red-500">
                    An account with this email already exists please set a password.
                </p>
            )}
            {validUser && needsPasswordReset && (
                <p className="caption mt-4 text-red-500">An account with this email already exists please log in.</p>
            )}
        </CardWithFooter>
    );
}
