import superagent from 'superagent';
import { getCookieValue } from './helpers';

const ContactLifecycleStage = {
    FREE_TRIAL: 'FREE_TRIAL',
    FREE_TRIAL_EXPIRED: 'FREE_TRIAL_EXPIRED',
    PAID: 'PAID',
    CHURNED: 'CHURNED',
};

const hubspotForms = {
    signin: 'https://api.hsforms.com/submissions/v3/integration/submit/24111407/a279a42c-3e2d-4257-a6b0-e4cba85a5a27',
    customerLifecycle: {
        [ContactLifecycleStage.FREE_TRIAL]:
            'https://api.hsforms.com/submissions/v3/integration/submit/24111407/7fda531f-d0bd-48f5-9b0a-5773d5f5a980',
        [ContactLifecycleStage.FREE_TRIAL_EXPIRED]:
            'https://api.hsforms.com/submissions/v3/integration/submit/24111407/ef61a816-3bab-4844-af24-6e054390fe9f',
        [ContactLifecycleStage.PAID]:
            'https://api.hsforms.com/submissions/v3/integration/submit/24111407/fc76851c-73f9-420a-a0d8-29609b1f57d8',
        [ContactLifecycleStage.CHURNED]:
            'https://api.hsforms.com/submissions/v3/integration/submit/24111407/5fdbc531-62d2-4aab-9cc9-5cfdaec1463f',
    },
};

const hsp = {
    LIFECYCLE_STAGES: ContactLifecycleStage,
    submitSignin: async ({ email, firstname, lastname, companyName, signupOrigin, role }) => {
        const hutkCookie = getCookieValue('hubspotutk');
        if (hutkCookie === 'Cookie not found') {
            console.warn('WARNING: Hubspot cookie not found');
            return;
        }
        superagent
            .post(hubspotForms.signin)
            .set('Content-Type', 'application/json')
            .send({
                fields: [
                    {
                        objectTypeId: '0-1',
                        name: 'email',
                        value: email,
                    },
                    {
                        objectTypeId: '0-1',
                        name: 'firstname',
                        value: firstname,
                    },
                    {
                        objectTypeId: '0-1',
                        name: 'lastname',
                        value: lastname,
                    },
                    {
                        objectTypeId: '0-1',
                        name: 'signup_origin',
                        value: signupOrigin,
                    },
                    {
                        objectTypeId: '0-1',
                        name: 'company',
                        value: companyName,
                    },
                    {
                        objectTypeId: '0-1',
                        name: 'wobo_user_type',
                        value: role,
                    },
                ],
                context: {
                    hutk: hutkCookie,
                },
                legalConsentOptions: {
                    consent: {
                        // Include this object when GDPR options are enabled
                        consentToProcess: true,
                        text: 'I agree to allow Example Company to store and process my personal data.',
                        communications: [
                            {
                                value: true,
                                subscriptionTypeId: 999,
                                text: 'I agree to receive marketing communications from Example Company.',
                            },
                        ],
                    },
                },
            })
            .then((res) => res.body)
            .catch((res) => console.error(res));
    },
    submitLifecycle: async (email, lifecycleStage) => {
        const hutkCookie = getCookieValue('hubspotutk');
        if (hutkCookie === 'Cookie not found') {
            console.warn('WARNING: Hubspot cookie not found');
            return;
        }
        superagent
            .post(hubspotForms.customerLifecycle[lifecycleStage])
            .set('Content-Type', 'application/json')
            .send({
                fields: [
                    {
                        objectTypeId: '0-1',
                        name: 'email',
                        value: email,
                    },
                ],
                context: {
                    hutk: hutkCookie,
                },
            })
            .then((res) => res.body)
            .catch((res) => console.error(res));
    },
};

export default hsp;
