import { MenuIcon, MenuAlt2Icon, MenuAlt3Icon } from '@heroicons/react/outline';
import classNames from 'classnames';
import { TEXT_ALIGNMENTS } from '@/util/constants';

export default function TextAlignPicker({ value, onClick }) {
    const handleTextAlignLeftClick = () => {
        onClick(TEXT_ALIGNMENTS.LEFT);
    };

    const handleTextAlignCenterClick = () => {
        onClick(TEXT_ALIGNMENTS.CENTER);
    };

    const handleTextAlignRightClick = () => {
        onClick(TEXT_ALIGNMENTS.RIGHT);
    };

    return (
        <div className="flex items-center space-x-2 text-lg">
            <button
                type="button"
                onClick={handleTextAlignLeftClick}
                className={classNames('rounded-sm border-0 p-1', {
                    'bg-neutral-100': value === TEXT_ALIGNMENTS.LEFT,
                })}>
                <MenuAlt2Icon className="h-em-2 w-em-2" />
            </button>
            <button
                type="button"
                onClick={handleTextAlignCenterClick}
                className={classNames('rounded-sm border-0 p-1', {
                    'bg-neutral-100': value === TEXT_ALIGNMENTS.CENTER,
                })}>
                <MenuIcon className="h-em-2 w-em-2" />
            </button>
            <button
                type="button"
                onClick={handleTextAlignRightClick}
                className={classNames('rounded-sm border-0 p-1', {
                    'bg-neutral-100': value === TEXT_ALIGNMENTS.RIGHT,
                })}>
                <MenuAlt3Icon className="h-em-2 w-em-2" />
            </button>
        </div>
    );
}
