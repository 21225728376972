import CoverPageLabel from '@/workbook/student/sidebar/modules/WorkbookCoverPageButton';
import ModuleButton from '@/workbook/student/sidebar/modules/ModuleButton';
import SectionButton from '@/workbook/student/sidebar/modules/SectionButton';
import { getSectionIcon, getSectionLabel, isSectionDisabledAddOn } from '@/workbook/builder/sections/helpers';
import { SECTIONS } from '@/workbook/builder/sections/sections';

export default function ModuleExplorer({
    workbook,
    onModuleSelect,
    onSectionSelect,
    addOns,
    hideSections = false,
    showAddOns,
}) {
    const selectedIsCoverPage = workbook?.selectedModule?.sections?.[0]?.type === SECTIONS.WORKBOOK_COVER_PAGE.TYPE_ID;
    const modulesWithoutCoverPage = workbook?.modules.slice(1);
    const coverPageSchema = workbook?.modules[0]?.sections[0]?.schema;
    const hideCoverPageLabel = coverPageSchema?.hidden ?? false;

    const handleSectionSelect = (e, sectionId) => {
        e.preventDefault();
        e.stopPropagation();
        if (typeof onSectionSelect === 'function') onSectionSelect(sectionId);
    };

    if (hideSections) {
        return (
            <div className="space-y-2">
                {!hideCoverPageLabel && (
                    <CoverPageLabel
                        label="Workbook cover page"
                        selected={selectedIsCoverPage}
                        onClick={() => onModuleSelect(workbook.modules[0].id)}
                    />
                )}
                {modulesWithoutCoverPage?.map((module) => (
                    <div key={`module-${module.id}`}>
                        <ModuleButton
                            label={module.title}
                            selected={module.id === workbook.selectedModule.id}
                            onClick={() => onModuleSelect(module.id)}
                            hideSections={hideSections}
                        />
                    </div>
                ))}
            </div>
        );
    }

    return (
        <div className="space-y-2">
            {!hideCoverPageLabel && (
                <CoverPageLabel
                    label="Workbook cover page"
                    selected={selectedIsCoverPage}
                    onClick={() => onModuleSelect(workbook.modules[0].id)}
                />
            )}
            {modulesWithoutCoverPage?.map((module) => (
                <div key={`module-${module.id}`}>
                    <ModuleButton
                        label={module.title}
                        selected={module.id === workbook.selectedModule.id}
                        onClick={() => onModuleSelect(module.id)}
                        hideSections={hideSections}>
                        <div className="space-y-1">
                            {workbook?.selectedModule?.sections?.map((section) => {
                                if (showAddOns && isSectionDisabledAddOn(section.type, addOns)) {
                                    return null;
                                }
                                return (
                                    <SectionButton
                                        key={`section-button-${module.id}-${section.id}`}
                                        sectionId={section.id}
                                        label={getSectionLabel(section)}
                                        icon={getSectionIcon(section)}
                                        onClick={(e) => handleSectionSelect(e, section.id)}
                                        style={workbook.schema.buttonStyling}
                                        selected={section.id === workbook.selectedSection.id}
                                    />
                                );
                            })}
                        </div>
                    </ModuleButton>
                </div>
            ))}
        </div>
    );
}
