import { SSO_TYPE_QUERY_PARAM, SSO_ID_QUERY_PARAM, SSO_TYPES } from '@/util/constants';
import useQueryParameter from '@/util/useQueryParameter';

export default function useGetSelectedCreatorIdFromSso() {
    const ssoType = useQueryParameter(SSO_TYPE_QUERY_PARAM);
    const ssoId = useQueryParameter(SSO_ID_QUERY_PARAM);
    const getSelectedCreatorFromSso = (member) => {
        if (ssoType === SSO_TYPES.THINKIFIC && ssoId) {
            const creatorIds = Object.keys(member.creators);
            for (let i = 0; i < creatorIds.length; i += 1) {
                const creatorId = creatorIds[i];
                if (member.creators[creatorId].creatorThinkificDomain === ssoId) {
                    return creatorId;
                }
            }
        } else if (ssoType === SSO_TYPES.GOOGLE) {
            const creatorIds = Object.keys(member.creators);
            for (let i = 0; i < creatorIds.length; i += 1) {
                const creatorId = creatorIds[i];
                if (!member.creators[creatorId].creatorThinkificDomain) {
                    return creatorId;
                }
            }
        }
        return null;
    };
    return getSelectedCreatorFromSso;
}
