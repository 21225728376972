import TextareaDebounce from '@/system/TextareaDebounce';
import TextInputDebounce from '@/system/TextInputDebounce';
import ToggleRow from '@/workbook/builder/sections/ToggleRow';
import RichTextEditorDebounce from '@/system/RichTextEditorDebounce';
import SectionTitle from '@/system/SectionTitle';
import SectionDescription from '@/system/SectionDescription';
import PreviewSuggestionTooltip from '@/workbook/builder/sections/PreviewSuggestionTooltip';
import { WORKBOOK_SECTION_VIEWER_MODES } from '@/util/constants';
import { isSectionReadOnly } from '@/util/helpers';

const responsePath = 'answer';

export const QuestionAndAnswerEditor = ({ schema, setSectionSchemaProp, saveSectionSchemaProp }) => {
    const { title, description } = schema;

    const handleTitleToggleChange = () => {
        saveSectionSchemaProp('title.show', !title.show);
    };

    const handleTitleChange = (e) => {
        const { value: text } = e.target;
        setSectionSchemaProp('title.text', text);
    };

    const handleTitleTypingEnd = (text) => {
        saveSectionSchemaProp('title.text', text);
    };

    const handleDescriptionToggleChange = () => {
        saveSectionSchemaProp('description.show', !description.show);
    };

    const handleDescriptionChange = (text) => {
        setSectionSchemaProp('description.text', text);
    };

    const handleDescriptionTypingEnd = (text) => {
        saveSectionSchemaProp('description.text', text);
    };

    return (
        <>
            <ToggleRow
                label="Title"
                enabled={title.show}
                onChange={handleTitleToggleChange}
                data-test="section-editor-qa-title-toggle"
            />
            <TextInputDebounce
                value={title.text}
                onChange={handleTitleChange}
                onTypingEnd={handleTitleTypingEnd}
                data-test="section-editor-qa-title"
            />
            <ToggleRow
                label="Description"
                enabled={description.show}
                onChange={handleDescriptionToggleChange}
                data-test="section-editor-qa-desc-toggle"
            />

            <RichTextEditorDebounce
                value={description.text}
                onChange={handleDescriptionChange}
                onTypingEnd={handleDescriptionTypingEnd}
                data-test="section-editor-qa-desc"
            />
        </>
    );
};

export const QuestionAndAnswer = ({
    workbookSchema,
    schema,
    mode = WORKBOOK_SECTION_VIEWER_MODES.DEFAULT,
    sectionResponses,
    setSectionResponseProp,
    saveSectionResponseProp,
}) => {
    const { title, description } = schema;

    const handleAnswerChange = (e) => {
        const { value: text } = e.target;
        if (typeof setSectionResponseProp === 'function') setSectionResponseProp(responsePath, text);
    };

    const handleAnswerTypingEnd = async (text) => {
        if (typeof saveSectionResponseProp === 'function') return saveSectionResponseProp(responsePath, text);
        return null;
    };

    const textArea = () => (
        <TextareaDebounce
            value={sectionResponses?.[responsePath]}
            onChange={handleAnswerChange}
            onTypingEnd={handleAnswerTypingEnd}
            className="custom-focus-outline min-h-[125px]"
            readOnly={isSectionReadOnly(mode)}
            style={{ fontFamily: workbookSchema.textStyling.body.fontFamily }}
            showStatusMessage
            resizeToFit
        />
    );

    return (
        <>
            {title.show && (
                <SectionTitle
                    text={title.text}
                    style={{
                        fontFamily: workbookSchema.textStyling.subtitle.fontFamily,
                        color: workbookSchema.textStyling.subtitle.color,
                    }}
                />
            )}
            {description.show && (
                <SectionDescription
                    text={description.text}
                    style={{
                        fontFamily: workbookSchema.textStyling.body.fontFamily,
                        color: workbookSchema.textStyling.body.color,
                    }}
                    data-test="section-qa-desc"
                />
            )}
            {mode === WORKBOOK_SECTION_VIEWER_MODES.BUILDER ? (
                <PreviewSuggestionTooltip>{textArea()}</PreviewSuggestionTooltip>
            ) : (
                textArea()
            )}
        </>
    );
};

export const QuestionAndAnswerArtifact = ({ workbookSchema, schema, sectionResponses }) => {
    const { title, description } = schema;

    return (
        <>
            {title.show && (
                <SectionTitle
                    text={title.text}
                    className="block"
                    style={{
                        fontFamily: workbookSchema.textStyling.subtitle.fontFamily,
                        color: workbookSchema.textStyling.subtitle.color,
                    }}
                />
            )}
            {description.show && (
                <SectionDescription
                    text={description.text}
                    style={{
                        fontFamily: workbookSchema.textStyling.body.fontFamily,
                        color: workbookSchema.textStyling.body.color,
                    }}
                />
            )}
            <pre
                className="paragraph__md w-full block rounded-md border px-3 pt-2 pb-2 border-neutral-200 whitespace-pre-wrap break-words min-h-[125px] bg-white"
                style={{ fontFamily: workbookSchema.textStyling.body.fontFamily }}>
                {sectionResponses?.[responsePath]}
            </pre>
        </>
    );
};
