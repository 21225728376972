import { useLayoutEffect } from 'react';

// This hook overwrites the main page css properties to make it printable.
export default function usePrintable() {
    useLayoutEffect(() => {
        document.documentElement.style.height = 'auto';
        document.documentElement.style.width = 'auto';
        document.documentElement.style.overflow = 'auto';
        document.body.style.height = 'auto';
        document.body.style.width = 'auto';
        document.body.style.overflow = 'auto';
        document.getElementById('root').style.top = 'auto';
        document.getElementById('root').style.right = 'auto';
        document.getElementById('root').style.bottom = 'auto';
        document.getElementById('root').style.left = 'auto';
        document.getElementById('root').style.position = 'static';
    }, []);
}
