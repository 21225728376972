/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';
import { useEffect, useRef, useState } from 'react';
import ZoomInIcon from '@/system/icons/ZoomInIcon';
import ZoomOutIcon from '@/system/icons/ZoomOutIcon';
import RotateLeftIcon from '@/system/icons/RotateLeftIcon';
import RotateRightIcon from '@/system/icons/RotateRightIcon';
import FlipHorizontalIcon from '@/system/icons/FlipHorizontalIcon';
import FlipVerticalIcon from '@/system/icons/FlipVerticalIcon';

const Editor = ({ imageData, onImageChange }) => {
    const [cropper, setCropper] = useState(null);
    const imageRef = useRef(null);

    const createCropper = () => {
        if (cropper) return;
        const newCropper = new Cropper(imageRef.current, {
            autoCropArea: 1,
        });
        setCropper(newCropper);
    };

    const destroyCropper = () => {
        if (cropper) {
            cropper.destroy();
            setCropper(null);
        }
    };

    const getCroppedCanvas = () => {
        if (cropper) {
            const canvas = cropper.getCroppedCanvas(
                imageData.type === 'image/png'
                    ? {}
                    : {
                          fillColor: '#fff',
                      },
            );
            onImageChange(canvas);
        }
    };

    const handleDragEnd = () => {
        getCroppedCanvas();
    };

    const handleZoom = (ratio) => {
        cropper.zoom(ratio);
        getCroppedCanvas();
    };

    const handleRotate = (degree) => {
        cropper.rotate(degree);
        getCroppedCanvas();
    };

    const handleFlip = (type) => {
        if (type === 'horizontal') {
            cropper.scaleX(-cropper.getData().scaleX || -1);
        } else {
            cropper.scaleY(-cropper.getData().scaleY || -1);
        }
        getCroppedCanvas();
    };

    const handleReset = () => {
        cropper.reset();
        getCroppedCanvas();
    };

    useEffect(() => {
        imageRef.current.addEventListener('cropend', () => getCroppedCanvas());
        return () => destroyCropper();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cropper]);

    return (
        <>
            <div className="h-full w-full">
                <div className="h-4/5 w-full">
                    <img
                        ref={imageRef}
                        alt={imageData.name}
                        src={imageData.url}
                        onLoad={createCropper}
                        onDragEnd={handleDragEnd}
                        className="max-h-full max-w-full"
                    />
                </div>
                <div className="flex w-full items-center justify-center py-8">
                    <div className="z-20 flex w-auto justify-center overflow-hidden rounded-lg">
                        <button
                            type="button"
                            className="flex h-9 w-9 items-center justify-center bg-neutral-500 text-white hover:bg-neutral-100 hover:text-neutral-500"
                            title="Zoom In"
                            onClick={() => handleZoom(0.1)}>
                            <ZoomInIcon />
                        </button>
                        <button
                            type="button"
                            className="flex h-9 w-9 items-center justify-center bg-neutral-500 text-white hover:bg-neutral-100 hover:text-neutral-500"
                            title="Zoom Out"
                            onClick={() => handleZoom(-0.1)}>
                            <ZoomOutIcon />
                        </button>
                        <button
                            type="button"
                            className="flex h-9 w-9 items-center justify-center bg-neutral-500 text-white hover:bg-neutral-100 hover:text-neutral-500"
                            title="Rotate Left"
                            onClick={() => handleRotate(-90)}>
                            <RotateLeftIcon />
                        </button>
                        <button
                            type="button"
                            className="flex h-9 w-9 items-center justify-center bg-neutral-500 text-white hover:bg-neutral-100 hover:text-neutral-500"
                            title="Rotate Right"
                            onClick={() => handleRotate(90)}>
                            <RotateRightIcon />
                        </button>
                        <button
                            type="button"
                            className="flex h-9 w-9 items-center justify-center bg-neutral-500 text-white hover:bg-neutral-100 hover:text-neutral-500"
                            title="Flip Horizontal"
                            onClick={() => handleFlip('horizontal')}>
                            <FlipHorizontalIcon />
                        </button>
                        <button
                            type="button"
                            className="flex h-9 w-9 items-center justify-center bg-neutral-500 text-white hover:bg-neutral-100 hover:text-neutral-500"
                            title="Flip Vertical"
                            onClick={() => handleFlip('vertical')}>
                            <FlipVerticalIcon />
                        </button>
                        <button
                            type="button"
                            className="flex items-center justify-center bg-neutral-500 px-3 text-white hover:bg-neutral-100 hover:text-neutral-500"
                            title="Reset"
                            onClick={handleReset}>
                            Reset
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Editor;
