import { CheckIcon } from '@heroicons/react/outline';

export default function ValidationListItem({ content, validate = false, passed = true }) {
    if (!validate)
        return (
            <div className="flex items-center">
                <div className="block h-5 min-w-6" />
                <ul className="list-disc">
                    <li>{content}</li>
                </ul>
            </div>
        );

    if (passed)
        return (
            <div className="flex items-center text-green-400">
                <CheckIcon className="block h-5 min-w-6" />
                <ul className="list-none">
                    <li>{content}</li>
                </ul>
            </div>
        );

    return (
        <div className="flex items-center text-error-200">
            <div className="block h-5 min-w-6" />
            <ul className="list-disc">
                <li>{content}</li>
            </ul>
        </div>
    );
}
