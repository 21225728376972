import { RadioGroup as HeadlessRadioGroup } from '@headlessui/react';
import classNames from 'classnames';

export const ORIENTATION = {
    HORIZONTAL: 'horizontal',
    VERTICAL: 'vertical',
};

export default function RadioGroup({
    value,
    options,
    onChange,
    orientation = ORIENTATION.HORIZONTAL,
    className = '',
    style = {},
    labelStyle = {},
}) {
    const orientationClasses = orientation === ORIENTATION.HORIZONTAL ? 'flex-row space-x-4' : 'flex-col space-y-1';
    const radioGroupClasses = classNames('flex', orientationClasses, className);
    return (
        <HeadlessRadioGroup
            value={value}
            onChange={typeof onChange === 'function' ? onChange : () => {}}
            className={radioGroupClasses}
            style={style}>
            {options.map((option) => (
                <HeadlessRadioGroup.Option key={option.id} value={option.id}>
                    {({ checked }) => {
                        return (
                            <div className="paragraph__sm inline-flex items-center" style={labelStyle}>
                                <input type="radio" checked={checked} readOnly tabIndex="-1" />{' '}
                                <span className="ml-1.5">{option.label}</span>
                            </div>
                        );
                    }}
                </HeadlessRadioGroup.Option>
            ))}
        </HeadlessRadioGroup>
    );
}
