import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingSpinner from '@/system/LoadingSpinner';
import useQueryParameter from '@/util/useQueryParameter';
import { FROM_URL_QUERY_PARAM } from '@/util/constants';
import useGTM from '@/util/useGTM';
import { useAuth } from './AuthProvider';

export default function TeachableAuthCompleteFromModal() {
    const navigate = useNavigate();
    const location = useLocation();
    const fromUrl = useQueryParameter(FROM_URL_QUERY_PARAM);
    const { setOriginalSignin } = useAuth();
    const gtm = useGTM();

    useEffect(() => {
        gtm.pushSignin('creator', 'teachable');
        setOriginalSignin('teachable');
        navigate(`${fromUrl}${location.search}`, { replace: true });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <LoadingSpinner />;
}
