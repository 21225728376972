import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Disclosure, Transition } from '@headlessui/react';
import { XIcon, ChevronLeftIcon, BookOpenIcon } from '@heroicons/react/outline';
import ModuleExplorer from '@/workbook/student/sidebar/ModuleExplorer';
import { getStorageUrl, getAnimationProps } from '@/util/helpers';
import { ROUTES } from '@/util/constants';

export default function MobileModuleNav({ workbook, onModuleSelect, preview, addOns }) {
    const { schema } = workbook;

    return (
        <Disclosure as="nav" className="relative lg:hidden">
            {({ open, close }) => {
                const handleModuleSelect = (moduleId) => {
                    onModuleSelect(moduleId);
                    close();
                };

                return (
                    <>
                        <div className="relative z-20 flex h-12 w-full items-center justify-between bg-white px-6 shadow-lg">
                            <button type="button">
                                {!preview && (
                                    <Link to={ROUTES.STUDENT.DASHBOARD}>
                                        <ChevronLeftIcon className="w-em-2" />
                                    </Link>
                                )}
                            </button>
                            {schema.logo.storage_filename && (
                                <img
                                    src={getStorageUrl(
                                        `${schema.storageObjectBasePath}${schema.logo.storage_filename}`,
                                    )}
                                    alt="Company Logo"
                                    className="max-w-1/3 h-6"
                                />
                            )}
                            <Disclosure.Button>
                                <span className="sr-only">Open module explorer</span>
                                {open ? (
                                    <XIcon className="block w-em-2" aria-hidden="true" />
                                ) : (
                                    <BookOpenIcon className="w-em-2" aria-hidden="true" />
                                )}
                            </Disclosure.Button>
                        </div>
                        <Transition
                            appear
                            show={open}
                            as={Fragment}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...getAnimationProps(
                                {
                                    transition: 'duration-200 ease-out',
                                    from: '-translate-y-full opacity-0',
                                    to: 'translate-y-0 opacity-100',
                                },
                                {
                                    transition: 'duration-300 ease-out',
                                    from: 'translate-y-0 opacity-100',
                                    to: '-translate-y-full opacity-0',
                                },
                            )}>
                            <Disclosure.Panel className="absolute z-10 max-h-screen-minus-mobile-navbar min-h-screen-minus-mobile-navbar w-full overflow-y-auto bg-white p-4">
                                <ModuleExplorer
                                    workbook={workbook}
                                    onModuleSelect={handleModuleSelect}
                                    addOns={addOns}
                                    hideSections
                                />
                            </Disclosure.Panel>
                        </Transition>
                    </>
                );
            }}
        </Disclosure>
    );
}
