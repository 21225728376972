import { Switch } from '@headlessui/react';
import classNames from 'classnames';

export default function Toggle({ enabled, onChange, className = '', style = {}, screenReaderLabel = 'Use toggle' }) {
    const toggleClasses = classNames(
        'relative inline-flex items-center h-6 rounded-full w-11 transition ease-in-out duration-200',
        { 'bg-primary-500': enabled, 'bg-neutral-100': !enabled },
        className,
    );
    return (
        <Switch
            checked={enabled}
            onChange={typeof onChange === 'function' ? onChange : () => {}}
            className={toggleClasses}
            style={style}>
            <span className="sr-only">{screenReaderLabel}</span>
            <span
                className={`${
                    enabled ? 'translate-x-[22px]' : 'translate-x-0.5'
                } translate-x inline-block h-5 w-5 transform rounded-full bg-white transition duration-200 ease-in-out`}
            />
        </Switch>
    );
}
