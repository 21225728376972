import { APP_STYLES } from '@/system/styles';

export default function WorkbookStyles({ schema, embedded }) {
    return (
        <style type="text/css">
            {`
                html {
                    background-color: ${schema.pageColor};
                }

                .custom-focus-outline:focus, .custom-focus-outline:hover {
                    --tw-ring-color: ${schema.buttonStyling.fillColor};
                    border-color: ${schema.buttonStyling.fillColor};
                }

                .custom-focus-outline:checked {
                    background-color: ${schema.buttonStyling.fillColor} !important;
                }

                .custom-outline-color {
                    outline-color: ${schema.buttonStyling.fillColor};
                }

                @media (max-width: ${
                    embedded
                        ? APP_STYLES.SCREENS.WORKBOOK_VIEWER_EMBED_SHOW_PADDED_CONTENT_MIN_WIDTH - 1
                        : APP_STYLES.SCREENS.LG_MIN_WIDTH - 1
                }px) {
                    .viewer-container-background-color {
                        background-color: ${schema.pageColor};
                    }
                }
                
                .nav-button {
                    background-color: ${schema.buttonStyling.fillColor};
                    color: ${schema.buttonStyling.textColor};
                }

                .nav-button:hover {
                    background-color: ${schema.buttonStyling.hoverColor};
                    color: ${schema.buttonStyling.hoverTextColor};
                }

                .nav-button-container {
                    background-color: ${schema.pageColor};
                }

                @media (min-width: 1024px) {
                    .nav-button-container {
                        background-color: transparent;
                    }
                }
            `}
        </style>
    );
}
