import { Portal } from 'react-portal';
import { useNavigate } from 'react-router-dom';
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import { ROUTES } from '@/util/constants';
import { DefaultButton, BUTTON_SIZE } from '@/system/Buttons';

export default function MinimumWidthRoute({ children }) {
    const navigate = useNavigate();
    const handleMinimumWidthModalClick = () => {
        navigate(ROUTES.CREATOR.WORKBOOKS_DASHBOARD);
    };

    return (
        <>
            <div className="hidden cmw:block">{children}</div>
            <Portal>
                <div className="cmw:hidden relative z-110">
                    <div className="fixed inset-0 bg-neutral-200" />
                    <div
                        className="fixed top-1/2 left-1/2 w-11/12 max-w-lg -translate-y-1/2 -translate-x-1/2 overflow-hidden rounded-lg bg-white p-0 shadow-xl"
                        data-test="min-screen-modal">
                        <div className="px-8 pt-6 pb-9 text-center">
                            <div className="mb-5 flex justify-center">
                                <div className="flex h-12 w-12 items-center justify-center rounded-full bg-error-100">
                                    <ExclamationCircleIcon className="w-5 text-error-400" />
                                </div>
                            </div>
                            <div className="heading__sm--medium mb-2 text-center" data-test="min-screen-modal-title">
                                Screen is too small
                            </div>
                            <div className="paragraph__sm text-center space-y-2.5" data-test="min-screen-modal-desc">
                                <p>
                                    You&apos;ll need a larger screen to create and manage your workbooks; please switch
                                    to a desktop device.
                                </p>
                                <p>If you&apos;re using a desktop, try making the window larger or zooming out.</p>
                            </div>
                        </div>
                        <div className="bg-neutral-50 p-3 text-center">
                            <DefaultButton
                                label="Go to dashboard"
                                size={BUTTON_SIZE.LG}
                                className="px-11"
                                data-test="min-screen-modal-button"
                                onClick={handleMinimumWidthModalClick}
                            />
                        </div>
                    </div>
                </div>
            </Portal>
        </>
    );
}
