import { useReducer } from 'react';
import { formatISO } from 'date-fns';
import { dateWithoutTime, deepCopy } from '@/util/helpers';

export const ACTIONS = {
    UPDATE_CREATORS: 'update_creators',
    SET_CURRENT_CREATOR: 'set_current_creator',
    RESET_CURRENT_CREATOR: 'reset_current_creator',
    UPDATE_CURRENT_CREATOR: 'update_current_creator',
};

export const EVENTS = {
    TRIAL_END_CHANGE: 'Trial end date change',
    ENABLE_POWERED_BY_WOBO: 'Enable powered by Wobo',
    DISABLE_POWERED_BY_WOBO: 'Disable powered by Wobo',
    THINKIFIC_DOMAIN_CHANGE: 'Thinkific domain change',
};

export const defaultDataQueryOptions = () => ({
    $skip: 0,
    $top: 10,
    $orderby: 'first_name asc',
});

export const getDefaultAdminLogSchema = () => ({
    generalNotes: '',
});

export const getDefaultAdminLogEvent = () => ({
    date: '',
    responsible: '',
    type: '',
    before: '',
    after: '',
});

export const getDefaultState = () => {
    return {
        total: 0,
        data: [],
        currentCreator: {
            adminLogSchema: getDefaultAdminLogSchema(),
            adminLogEvents: [],
        },
        dataQueryOptions: defaultDataQueryOptions(),
    };
};

export const getNewEvent = (type, user, before, after) => ({
    date: formatISO(dateWithoutTime(new Date())),
    responsible: user.email,
    type,
    before,
    after,
});

export const creatorsReducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.UPDATE_CREATORS: {
            return {
                ...state,
                total: action.payload.total,
                data: action.payload.data,
                dataQueryOptions: action.payload.dataQueryOptions || defaultDataQueryOptions(),
            };
        }
        case ACTIONS.SET_CURRENT_CREATOR: {
            return {
                ...state,
                currentCreator: action.payload,
            };
        }
        case ACTIONS.RESET_CURRENT_CREATOR: {
            return {
                ...state,
                currentCreator: {
                    adminLogSchema: getDefaultAdminLogSchema(),
                    adminLogEvents: [],
                },
            };
        }
        case ACTIONS.UPDATE_CURRENT_CREATOR: {
            const { event, updatedFields } = action.payload;
            return {
                ...state,
                data: state.data.map((cre) => {
                    if (cre.id === state.currentCreator.id) {
                        const adminLogEvents = deepCopy(state.currentCreator.adminLogEvents);
                        if (event) {
                            adminLogEvents.push(event);
                        }
                        return { ...cre, ...updatedFields, adminLogEvents };
                    }
                    return cre;
                }),
            };
        }
        default:
            throw new Error('Action not supported ');
    }
};

const useCreatorsReducer = () => {
    return useReducer(creatorsReducer, getDefaultState());
};

export default useCreatorsReducer;
