import { useState } from 'react';
import { DefaultButton, SecondaryButton } from '@/system/Buttons';

export default function CommentEditor({ workbookSchema, initialComment, onAdd, setAddingComment, isEditing }) {
    const [comment, setComment] = useState(initialComment);

    const isEmpty = comment.trim() === '';
    const ENTER_KEYCODE = 13;

    const onSave = async () => {
        await onAdd(comment);
        setComment('');
    };

    const handleEnter = (e) => {
        if (e.keyCode === ENTER_KEYCODE && !e.shiftKey && !isEmpty) {
            setComment(e.target.value);
            onSave();
            e.preventDefault();
            e.stopPropagation();
            return false;
        }

        return true;
    };

    return (
        <div style={{ fontFamily: workbookSchema.textStyling.body.fontFamily }} className="flex flex-col mt-2 w-full">
            <textarea
                placeholder="Write a comment..."
                value={comment}
                onKeyDown={(e) => handleEnter(e)}
                onChange={(e) => setComment(e.target.value)}
                className="w-full rounded-md border px-3 pt-2 pb-2 border-neutral-200 resize-none focus:rounded-md focus:border focus:border-neutral-200"
            />
            <div className="flex flex-row w-full mt-4 justify-end">
                <SecondaryButton label="Cancel" onClick={() => setAddingComment(false)} />
                <DefaultButton
                    label={isEditing ? 'Save' : 'Comment'}
                    className={`ml-3 transition-opacity ${isEmpty ? 'pointer-events-none opacity-50' : ''}`}
                    onClick={onSave}
                />
            </div>
        </div>
    );
}
