import { useEffect, useRef } from 'react';
import classnames from 'classnames';
import { polyfill } from 'seamless-scroll-polyfill';
import { useWorkbookVersion } from '@/workbook/public/WorkbookVersionProvider';
import LoadingSpinner from '@/system/LoadingSpinner';
import TechnicalError from '@/system/TechnicalError';
import Sidebar from '@/workbook/student/sidebar/Sidebar';
import Viewer from '@/workbook/student/Viewer';
import WorkbookStyles from '@/workbook/WorkbookStyles';
import { WORKBOOK_SECTION_VIEWER_MODES, PLANS } from '@/util/constants';

export default function Workbook() {
    const viewerContainerRef = useRef(null);
    const {
        workbookVersion,
        loading,
        loadingError,
        selectModule,
        selectPrevModule,
        selectNextModule,
        selectSection,
        setSectionResponseProp,
        generateWorkbookArtifact,
        generateWorkbookModuleArtifact,
        saveResponseFile,
        resetResponseFile,
        workbookArtifactDownloadUrl,
        creator,
    } = useWorkbookVersion();
    const { schema } = workbookVersion;
    const { schema: creatorSchema } = creator;
    let { addOns } = creator;
    const showAddOns = creator.plan === PLANS.PRO;

    if (showAddOns) {
        addOns = { studentImageUpload: true };
    }

    useEffect(() => {
        // Ensure that Safari can scrollTo, scrollIntoView smoothly
        polyfill();
    }, []);

    if (loading) return <LoadingSpinner />;
    if (loadingError) return <TechnicalError title="Error loading workbook" desc={`Details: ${loadingError}`} />;
    return (
        <>
            <WorkbookStyles schema={schema} />
            <div
                className={classnames('h-screen lg:flex lg:overflow-hidden', {
                    'prevent-copy-on-mobile': creatorSchema.protectingIp.disableTextCopying,
                })}>
                <div className="lg:h-full lg:w-96 lg:flex-shrink-0 lg:flex-grow-0 lg:bg-white">
                    <Sidebar
                        workbook={workbookVersion}
                        selectModule={selectModule}
                        selectSection={selectSection}
                        addOns={addOns}
                        showAddOns={showAddOns}
                    />
                </div>
                <div
                    ref={viewerContainerRef}
                    className="lg:h-full lg:w-[calc(100vw-24rem)] lg:flex-shrink lg:flex-grow lg:bg-neutral-50 viewer-container-background-color h-screen-minus-mobile-navbar overflow-auto lg:pt-6">
                    <Viewer
                        workbookVersion={workbookVersion}
                        selectPrevModule={selectPrevModule}
                        selectNextModule={selectNextModule}
                        setSectionResponseProp={setSectionResponseProp}
                        saveSectionResponseProp={setSectionResponseProp}
                        generateWorkbookArtifact={generateWorkbookArtifact}
                        generateWorkbookModuleArtifact={generateWorkbookModuleArtifact}
                        workbookArtifactDownloadUrl={workbookArtifactDownloadUrl}
                        embedded={false}
                        viewerContainerRef={viewerContainerRef}
                        saveResponseFile={saveResponseFile}
                        resetResponseFile={resetResponseFile}
                        mode={WORKBOOK_SECTION_VIEWER_MODES.PREVIEW}
                        addOns={addOns}
                        showAddOns={showAddOns}
                    />
                </div>
            </div>
        </>
    );
}
