/* eslint-disable react/jsx-props-no-spreading */
import { Fragment, useState, useEffect } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { SwitchVerticalIcon, XIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import { Droppable, Draggable, DragDropContext } from 'react-beautiful-dnd';
import { getUniqueId, reorderOptions, addOption, removeOption } from '@/util/helpers';
import TextInputDebounce from '@/system/TextInputDebounce';
import RichTextEditorDebounce from '@/system/RichTextEditorDebounce';
import ToggleRow from '@/workbook/builder/sections/ToggleRow';
import SectionTitle from '@/system/SectionTitle';
import SectionDescription from '@/system/SectionDescription';
import { DefaultButton } from '@/system/Buttons';
import Label from '@/system/Label';
import { WORKBOOK_SECTION_VIEWER_MODES } from '@/util/constants';

const responsePath = 'answer';
const maxOptions = 50;

export const DropDownListEditor = ({ schema, setSectionSchemaProp, saveSectionSchemaProp }) => {
    const { title, description, options } = schema;
    const hideRemove = options.length === 1;
    const limitReached = options.length === maxOptions;

    const handleTitleChange = (e) => {
        const { value: text } = e.target;
        setSectionSchemaProp('title.text', text);
    };

    const handleTitleTypingEnd = (text) => {
        saveSectionSchemaProp('title.text', text);
    };

    const handleDescriptionToggleChange = () => {
        saveSectionSchemaProp('description.show', !description.show);
    };

    const handleDescriptionChange = (text) => {
        setSectionSchemaProp('description.text', text);
    };

    const handleDescriptionTypingEnd = (text) => {
        saveSectionSchemaProp('description.text', text);
    };

    const handleOptionChange = (label, index) => {
        const updatedOptions = [...options];
        updatedOptions[index] = { id: updatedOptions[index].id, value: updatedOptions[index].id, label };
        setSectionSchemaProp('options', updatedOptions);
    };

    const handleOptionTypingEnd = (label, index) => {
        const updatedOptions = [...options];
        updatedOptions[index] = { id: updatedOptions[index].id, value: updatedOptions[index].id, label };
        saveSectionSchemaProp('options', updatedOptions);
    };

    const handleDragEnd = (result) => {
        const fromIndex = result.source.index;
        const toIndex = result.destination.index;
        if (fromIndex === toIndex) return;
        // Ignore when the user drags outside of the droppable container
        if (!result.destination || options.length <= 1) return;
        saveSectionSchemaProp('options', reorderOptions(fromIndex, toIndex, options));
    };

    const handleAddOption = () => {
        if (!limitReached) {
            const id = getUniqueId(5);
            const label = `Option ${options.length + 1}`;
            saveSectionSchemaProp('options', addOption(options, { id, value: id, label }));
        }
    };

    const handleRemoveOption = (removeIndex) => {
        saveSectionSchemaProp('options', removeOption(options, removeIndex));
    };

    return (
        <div className="space-y-3.5">
            <Label desc="Question" />
            <TextInputDebounce value={title.text} onChange={handleTitleChange} onTypingEnd={handleTitleTypingEnd} />
            <div>
                <ToggleRow label="Description" enabled={description.show} onChange={handleDescriptionToggleChange} />
                <RichTextEditorDebounce
                    value={description.text}
                    onChange={handleDescriptionChange}
                    onTypingEnd={handleDescriptionTypingEnd}
                />
            </div>
            <Label desc="Options" />
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="droppable-radio-buttons-option">
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef} className="space-y-3.5">
                            {options.map((option, index) => (
                                <Draggable
                                    key={`draggable-${option.id}`}
                                    draggableId={`draggable-${option.id}`}
                                    index={index}>
                                    {(providedDraggable) => (
                                        <div ref={providedDraggable.innerRef} {...providedDraggable.draggableProps}>
                                            <div className="relative" key={`draggable-container-${option.id}`}>
                                                <div
                                                    className="absolute left-1.5 z-[1] top-1/2 -translate-y-1/2"
                                                    {...providedDraggable.dragHandleProps}>
                                                    <SwitchVerticalIcon className="h-em w-em cursor-grab text-neutral-200" />
                                                </div>
                                                {!hideRemove && (
                                                    <div className="absolute right-1.5 z-[1] top-1/2 -translate-y-1/2">
                                                        <button type="button" onClick={() => handleRemoveOption(index)}>
                                                            <XIcon className="h-em w-em text-neutral-200" />
                                                        </button>
                                                    </div>
                                                )}
                                                <TextInputDebounce
                                                    value={option.label}
                                                    onChange={(e) => handleOptionChange(e.target.value, index)}
                                                    onTypingEnd={(text) => handleOptionTypingEnd(text, index)}
                                                    inputClassName="px-6"
                                                />
                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <DefaultButton label="Add option" onClick={handleAddOption} disabled={limitReached} />
        </div>
    );
};

export const DropDownList = ({
    workbookSchema,
    schema,
    mode = WORKBOOK_SECTION_VIEWER_MODES.DEFAULT,
    sectionResponses,
    saveSectionResponseProp,
}) => {
    const { title, description, options } = schema;

    const handleOptionChange = (value) => {
        if (value !== sectionResponses?.[responsePath] && typeof saveSectionResponseProp === 'function') {
            saveSectionResponseProp(responsePath, value);
        }
    };

    return (
        <>
            <SectionTitle
                text={title.text}
                style={{
                    fontFamily: workbookSchema.textStyling.subtitle.fontFamily,
                    color: workbookSchema.textStyling.subtitle.color,
                }}
            />
            {description.show && (
                <SectionDescription
                    text={description.text}
                    style={{
                        fontFamily: workbookSchema.textStyling.body.fontFamily,
                        color: workbookSchema.textStyling.body.color,
                    }}
                />
            )}
            <SelectList
                value={sectionResponses?.[responsePath]}
                options={options}
                onChange={handleOptionChange}
                mode={mode}
                fontFamily={workbookSchema.textStyling.body.fontFamily}
            />
        </>
    );
};

export const DropDownListArtifact = ({ workbookSchema, schema, sectionResponses }) => {
    const { title, description, options } = schema;

    return (
        <>
            <SectionTitle
                text={title.text}
                style={{
                    fontFamily: workbookSchema.textStyling.subtitle.fontFamily,
                    color: workbookSchema.textStyling.subtitle.color,
                }}
            />
            {description.show && (
                <SectionDescription
                    text={description.text}
                    style={{
                        fontFamily: workbookSchema.textStyling.body.fontFamily,
                        color: workbookSchema.textStyling.body.color,
                    }}
                />
            )}
            <SelectListArtifact
                value={sectionResponses?.[responsePath]}
                options={options}
                fontFamily={workbookSchema.textStyling.body.fontFamily}
            />
        </>
    );
};

function SelectList({ value, options, onChange, mode, fontFamily }) {
    const [optionsDictionary, setOptionsDictionary] = useState({});

    useEffect(() => {
        const dictionary = {};
        if (Array.isArray(options)) {
            options.forEach((option) => {
                dictionary[option.value] = option.label;
            });
        }
        setOptionsDictionary(dictionary);
    }, [options]);

    if (!Array.isArray(options) || options.length === 0) return 'At least 1 option required for SelectList';

    return (
        <div className="relative w-full sm:max-w-[75%] h-8">
            <Listbox value={value} onChange={onChange} disabled={mode === WORKBOOK_SECTION_VIEWER_MODES.READ_ONLY}>
                <Listbox.Button
                    className="paragraph__md relative w-full cursor-default rounded-md border border-neutral-100 bg-white py-[2px] pl-3 pr-10 text-left text-neutral-500 focus:outline-none focus-visible:border-2 focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 h-full"
                    style={{ fontFamily }}>
                    {({ open }) => {
                        return (
                            <>
                                <span className="block truncate">
                                    {Object.keys(optionsDictionary) === 0
                                        ? ''
                                        : optionsDictionary[value] || 'Choose an option'}
                                </span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 text-neutral-500">
                                    {open ? (
                                        <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
                                    ) : (
                                        <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                                    )}
                                </span>
                            </>
                        );
                    }}
                </Listbox.Button>
                <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <Listbox.Options className="absolute select-list-show-scrollbars z-10 mt-2.25 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {options.map((option) => (
                            <Listbox.Option
                                key={option.id}
                                value={option.value}
                                disabled={option.disabled}
                                style={{ fontFamily }}
                                className={({ active }) =>
                                    classNames(
                                        'paragraph__md relative cursor-default select-none p-3.25 text-neutral-500',
                                        { 'bg-neutral-50': active },
                                    )
                                }>
                                {({ selected }) => {
                                    return (
                                        <div
                                            className={classNames({
                                                'font-semibold': selected,
                                            })}>
                                            {option.label}
                                        </div>
                                    );
                                }}
                            </Listbox.Option>
                        ))}
                    </Listbox.Options>
                </Transition>
            </Listbox>
        </div>
    );
}

function SelectListArtifact({ value, options, fontFamily }) {
    const [optionsDictionary, setOptionsDictionary] = useState({});

    useEffect(() => {
        const dictionary = {};
        if (Array.isArray(options)) {
            options.forEach((option) => {
                dictionary[option.value] = option.label;
            });
        }
        setOptionsDictionary(dictionary);
    }, [options]);

    if (!Array.isArray(options) || options.length === 0) return 'At least 1 option required for SelectList';

    return (
        <div className="relative w-full sm:max-w-[75%]">
            <Listbox value={value} disabled>
                <Listbox.Button
                    className="paragraph__md relative w-full cursor-default rounded-md border border-neutral-100 bg-white py-2 pl-3 pr-10 text-left text-neutral-500 focus:outline-none focus-visible:border-2 focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300"
                    style={{ fontFamily }}>
                    {() => {
                        return (
                            <>
                                <span className="block truncate">
                                    {Object.keys(optionsDictionary) === 0
                                        ? ''
                                        : optionsDictionary[value] || 'Choose an option'}
                                </span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 text-neutral-500">
                                    <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                            </>
                        );
                    }}
                </Listbox.Button>
            </Listbox>
        </div>
    );
}
