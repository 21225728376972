import { updateSectionResponsePropDto } from '@/api/dtos';
import useRequest from '@/api/useRequest';

export default function useStudentApi() {
    const { getRequest, patchRequest, putRequest, postRequest } = useRequest();

    const getStudentWorkbookVersion = async (studentId, workbookVersionId) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/students/${studentId}/workbook-versions/${workbookVersionId}`;
        return getRequest(url);
    };

    const getStudentWorkbookVersionModules = async (studentId, workbookVersionId) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/students/${studentId}/workbook-versions/${workbookVersionId}/modules`;
        return getRequest(url);
    };

    const getStudentWorkbookVersionModuleResponses = async (studentId, workbookVersionId, cohortId) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/students/${studentId}/workbook-versions/${workbookVersionId}/cohorts/${cohortId}/module-responses`;
        return getRequest(url);
    };

    const updateStudentModuleResponseToOpened = async (studentId, workbookVersionId, cohortId, moduleResponseId) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/students/${studentId}/workbook-versions/${workbookVersionId}/cohorts/${cohortId}/module-responses/${moduleResponseId}`;
        const queryParams = {
            action: 'set_opened',
        };
        return patchRequest(url, null, queryParams);
    };

    const upsertStudentSectionResponseProp = async (
        studentId,
        workbookVersionId,
        cohortId,
        moduleResponseId,
        sectionId,
        path,
        value,
    ) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/students/${studentId}/workbook-versions/${workbookVersionId}/cohorts/${cohortId}/module-responses/${moduleResponseId}/section-responses/${sectionId}`;
        const payload = updateSectionResponsePropDto(path, value);
        return putRequest(url, payload);
    };

    const createStudentWorkbookArtifactSignedUrl = async (studentId, workbookVersionId, cohortId, optionalInfo) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/students/${studentId}/workbook-versions/${workbookVersionId}/cohorts/${cohortId}/artifact`;
        const payload = {};
        if (optionalInfo) {
            if (optionalInfo.pageFormat) {
                payload.page_format = optionalInfo.pageFormat;
            }
            if (optionalInfo.headerHtml) {
                payload.header_html = optionalInfo.headerHtml;
            }
            if (optionalInfo.footerHtml) {
                payload.footer_html = optionalInfo.footerHtml;
            }
        }
        return postRequest(url, payload);
    };

    const createStudentWorkbookModuleArtifactSignedUrl = async (
        studentId,
        workbookVersionId,
        moduleId,
        cohortId,
        optionalInfo,
    ) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/students/${studentId}/workbook-versions/${workbookVersionId}/modules/${moduleId}/cohorts/${cohortId}/artifact`;
        const payload = {};
        if (optionalInfo) {
            if (optionalInfo.pageFormat) {
                payload.page_format = optionalInfo.pageFormat;
            }
            if (optionalInfo.headerHtml) {
                payload.header_html = optionalInfo.headerHtml;
            }
            if (optionalInfo.footerHtml) {
                payload.footer_html = optionalInfo.footerHtml;
            }
        }
        return postRequest(url, payload);
    };

    const logStudentAccess = async (studentId, workbookVersionId) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/students/${studentId}/workbook-versions/${workbookVersionId}/creator`;
        const queryParams = { action: 'log_access' };
        return patchRequest(url, null, queryParams);
    };

    return {
        getStudentWorkbookVersion,
        getStudentWorkbookVersionModules,
        getStudentWorkbookVersionModuleResponses,
        updateStudentModuleResponseToOpened,
        upsertStudentSectionResponseProp,
        createStudentWorkbookArtifactSignedUrl,
        createStudentWorkbookModuleArtifactSignedUrl,
        logStudentAccess,
    };
}
