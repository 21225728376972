/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-boolean-value */
import { useEffect, useRef, useState } from 'react';
import { Transition } from '@headlessui/react';
import { DefaultButton, BUTTON_SIZE } from '@/system/Buttons';
import { ThinkificButton, GoogleButton } from '@/system/BrandedButtons';
import EmailLogin from '@/auth/EmailLogin';
import ThinkificLogin from '@/auth/ThinkificLogin';
import { getAnimationProps, getThinkificLoginSsoParams, getGoogleLoginSsoParams } from '@/util/helpers';
import useQueryParameter from '@/util/useQueryParameter';
import LoadingSpinner from '@/system/LoadingSpinner';
import {
    AUTHENTICATION_TYPES,
    ROLES,
    ROUTES,
    SSO_ID_QUERY_PARAM,
    SSO_TYPES,
    SSO_TYPE_QUERY_PARAM,
    GOOGLE_AUTHORIZE_URL,
} from '@/util/constants';
import { getSearch } from '@/api/helpers';

export default function StudentLogin({
    showThinkific = true,
    showGoogle = true,
    autoEnrollPubliclySharedWorkbookId = undefined,
}) {
    const isMounted = useRef(true);
    const [loading, setLoading] = useState(true);
    const defaultSigninType = !showThinkific && !showGoogle ? AUTHENTICATION_TYPES.EMAIL : null;
    const [signinType, setSigninType] = useState(defaultSigninType);
    const loginType = useQueryParameter('login_type');
    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (isMounted.current) {
            setSigninType(loginType);
            setLoading(false);
        }
    }, [loginType]);

    const handleEmailLoginClick = () => {
        setSigninType(AUTHENTICATION_TYPES.EMAIL);
    };

    const handleThinkificLoginClick = () => {
        setSigninType(AUTHENTICATION_TYPES.THINKIFIC);
    };

    const handleGoogleLoginClick = async () => {
        const search = getSearch({
            [SSO_TYPE_QUERY_PARAM]: SSO_TYPES.GOOGLE,
        });

        window.location.href = `${GOOGLE_AUTHORIZE_URL}${getSearch(
            getGoogleLoginSsoParams(`${ROUTES.STUDENT.DASHBOARD}${search}`, ROLES.STUDENT),
        )}`;
    };

    const getSsoParams = (domain) => {
        const search = getSearch({
            [SSO_TYPE_QUERY_PARAM]: SSO_TYPES.THINKIFIC,
            [SSO_ID_QUERY_PARAM]: domain,
        });
        return getThinkificLoginSsoParams(`${ROUTES.STUDENT.DASHBOARD}${search}`, ROLES.STUDENT);
    };

    if (loading) return <LoadingSpinner />;
    return (
        <>
            {!signinType && (
                <div className="absolute top-1/2 left-1/2 flex min-w-[80%] -translate-x-1/2 -translate-y-1/2 flex-col items-center justify-center lg:min-w-full">
                    <img src="/images/logo.svg" className="mb-10 w-32" alt="Logo" />
                    <div className="flex flex-col items-center justify-center space-y-6">
                        <p className="heading__md--medium">Log in to your account</p>
                        <DefaultButton
                            label="Sign in with email"
                            onClick={handleEmailLoginClick}
                            size={BUTTON_SIZE.LG}
                            className="w-72"
                            data-test="email-sign-in-button"
                        />
                        {showThinkific && (
                            <ThinkificButton
                                label="Continue with Thinkific"
                                onClick={handleThinkificLoginClick}
                                size={BUTTON_SIZE.LG}
                                className="w-72"
                            />
                        )}
                        {showGoogle && (
                            <GoogleButton
                                label="Continue with Google"
                                onClick={handleGoogleLoginClick}
                                size={BUTTON_SIZE.LG}
                                className="w-72"
                            />
                        )}
                    </div>
                </div>
            )}

            <Transition
                show={signinType === AUTHENTICATION_TYPES.EMAIL}
                {...getAnimationProps(
                    { transition: 'duration-300 ease-in', from: 'opacity-0', to: 'opacity-100' },
                    { transition: 'duration-300 ease-out', from: 'opacity-100', to: 'opacity-0' },
                )}>
                <EmailLogin
                    fromUrl={ROUTES.STUDENT.DASHBOARD}
                    autoEnrollPubliclySharedWorkbookId={autoEnrollPubliclySharedWorkbookId}
                />
            </Transition>
            <Transition
                show={signinType === AUTHENTICATION_TYPES.THINKIFIC}
                {...getAnimationProps(
                    { transition: 'duration-300 ease-in', from: 'opacity-0', to: 'opacity-100' },
                    { transition: 'duration-300 ease-out', from: 'opacity-100', to: 'opacity-0' },
                )}>
                <ThinkificLogin getSsoParams={getSsoParams} student />
            </Transition>
        </>
    );
}
