import {
    DocumentIcon,
    PhotographIcon,
    QuestionMarkCircleIcon,
    MenuAlt2Icon,
    LinkIcon,
    VideoCameraIcon,
    TableIcon,
    PencilIcon,
    DownloadIcon,
    SelectorIcon,
    ServerIcon,
    ExclamationIcon,
} from '@heroicons/react/outline';
import PageBreakIcon from '@/system/icons/PageBreakIcon';
import ArrowUpOnSquareStackIcon from '@/system/icons/ArrowUpOnSquareStackIcon';
import SliderIcon from '@/system/icons/SliderIcon';
import RadioButtonIcon from '@/system/icons/RadioButtonIcon';
import CheckboxIcon from '@/system/icons/CheckboxIcon';
import {
    QuestionAndAnswer,
    QuestionAndAnswerEditor,
    QuestionAndAnswerArtifact,
} from '@/workbook/builder/sections/QuestionAndAnswer';
import { Slider, SliderEditor, SliderArtifact } from '@/workbook/builder/sections/Slider';
import { Link, LinkEditor, LinkArtifact } from '@/workbook/builder/sections/Link';
import { Video, VideoEditor, VideoArtifact } from '@/workbook/builder/sections/Video';
import { TextField, TextFieldEditor, TextFieldArtifact } from '@/workbook/builder/sections/TextField';
import { NotesArea, NotesAreaEditor, NotesAreaArtifact } from '@/workbook/builder/sections/NotesArea';
import {
    WorkbookCoverPage,
    WorkbookCoverPageEditor,
    WorkbookCoverPageArtifact,
} from '@/workbook/builder/sections/WorkbookCoverPage';
import {
    ModuleCoverPage,
    ModuleCoverPageEditor,
    ModuleCoverPageArtifact,
} from '@/workbook/builder/sections/ModuleCoverPage';
import { Image, ImageEditor, ImageArtifact } from '@/workbook/builder/sections/Image';
import { Table, TableEditor, TableArtifact } from '@/workbook/builder/sections/Table';
import {
    WorkbookDownloadLink,
    WorkbookDownloadLinkEditor,
    WorkbookDownloadLinkArtifact,
} from '@/workbook/builder/sections/WorkbookDownloadLink';
import {
    ModuleDownloadLink,
    ModuleDownloadLinkEditor,
    ModuleDownloadLinkArtifact,
} from '@/workbook/builder/sections/ModuleDownloadLink';
import { RadioButtons, RadioButtonsEditor, RadioButtonsArtifact } from '@/workbook/builder/sections/RadioButtons';
import { Checkboxes, CheckboxesEditor, CheckboxesArtifact } from '@/workbook/builder/sections/Checkboxes';
import { DropDownList, DropDownListEditor, DropDownListArtifact } from '@/workbook/builder/sections/DropDownList';
import {
    StudentImageUpload,
    StudentImageUploadEditor,
    StudentImageUploadArtifact,
} from '@/workbook/builder/sections/StudentImageUpload';
import { FileUpload, FileUploadEditor, FileUploadArtifact } from '@/workbook/builder/sections/FileUpload';
import {
    TEXT_ALIGNMENTS,
    POSITIONS_X,
    POSITIONS_Y,
    LOGO_SIZES,
    IMAGE_SIZES,
    TABLE_LAYOUTS,
    FILL_IN_THE_BLANKS_SECTION,
    FILE_MAX_SIZES_DESC,
    ROUTES,
    SETTINGS_SECTIONS,
} from '@/util/constants';
import { ANALYTICS_EVENTS } from '@/auth/AuthProvider';
import {
    FillInTheBlanks,
    FillInTheBlanksEditor,
    FillInTheBlanksArtifact,
} from '@/workbook/builder/sections/FillInTheBlanks';
import { PageBreak, PageBreakArtifact, PageBreakEditor } from '@/workbook/builder/sections/PageBreak';

export const SECTIONS = {
    WORKBOOK_COVER_PAGE: {
        TYPE_ID: 1,
        EDITOR: WorkbookCoverPageEditor,
        VIEWER: WorkbookCoverPage,
        ARTIFACT: WorkbookCoverPageArtifact,
        TITLE: 'Cover Page',
        DEFAULT_SCHEMA: {
            hidden: false,
            title: {
                text: 'Title',
                show: true,
                align: TEXT_ALIGNMENTS.LEFT,
                position: {
                    y: POSITIONS_Y.TOP,
                },
                styling: {
                    fontSize: 36,
                    letterSpacing: 0.01,
                    lineHeight: 38,
                },
            },
            subtitle: {
                text: 'Subtitle',
                show: true,
                styling: {
                    fontSize: 20,
                    letterSpacing: 0.01,
                    lineHeight: 24,
                },
            },
            image: {
                filename: '',
                storage_filename: '',
            },
            logo: {
                size: LOGO_SIZES.MEDIUM.ID,
                position: {
                    x: POSITIONS_X.LEFT,
                    y: POSITIONS_Y.TOP,
                },
            },
        },
    },
    MODULE_COVER_PAGE: {
        TYPE_ID: 2,
        ICON: DocumentIcon,
        EDITOR: ModuleCoverPageEditor,
        VIEWER: ModuleCoverPage,
        ARTIFACT: ModuleCoverPageArtifact,
        TITLE: 'Module Cover Page',
        DESCRIPTION: 'Update or design your own.',
        DEFAULT_SCHEMA: {
            title: {
                text: 'Module Title',
                show: true,
                align: TEXT_ALIGNMENTS.LEFT,
                position: {
                    y: POSITIONS_Y.TOP,
                },
                styling: {
                    fontSize: 36,
                    letterSpacing: 0.01,
                    lineHeight: 38,
                },
            },
            subtitle: {
                text: 'Subtitle',
                show: true,
                styling: {
                    fontSize: 20,
                    letterSpacing: 0.01,
                    lineHeight: 24,
                },
            },
            image: {
                filename: '',
                storage_filename: '',
            },
        },
        EVENT_NAME: ANALYTICS_EVENTS.ADD_WORKBOOK_SECTION_CATEGORIES.MODULE_COVER_PAGE,
        PREVIEW: 'module-cover-page.gif',
    },
    IMAGE: {
        TYPE_ID: 3,
        ICON: PhotographIcon,
        EDITOR: ImageEditor,
        VIEWER: Image,
        ARTIFACT: ImageArtifact,
        TITLE: 'Image',
        DESCRIPTION: 'Insert an image or diagram.',
        DEFAULT_SCHEMA: {
            title: {
                text: 'Image',
            },
            image: {
                filename: '',
                storage_filename: '',
                size: IMAGE_SIZES.FULL,
                alt: '',
            },
        },
        EVENT_NAME: ANALYTICS_EVENTS.ADD_WORKBOOK_SECTION_CATEGORIES.IMAGE,
        PREVIEW: 'image.gif',
    },
    QUESTION_AND_ANSWER: {
        TYPE_ID: 4,
        ICON: QuestionMarkCircleIcon,
        EDITOR: QuestionAndAnswerEditor,
        VIEWER: QuestionAndAnswer,
        ARTIFACT: QuestionAndAnswerArtifact,
        TITLE: 'Question & Answer',
        DESCRIPTION: 'Create a prompt and allow a student response.',
        DEFAULT_SCHEMA: {
            title: {
                text: 'Question & answer',
                show: true,
            },
            description: {
                text: '',
                show: true,
            },
        },
        EVENT_NAME: ANALYTICS_EVENTS.ADD_WORKBOOK_SECTION_CATEGORIES.QUESTION_AND_ANSWER,
        PREVIEW: 'question-and-answer.gif',
    },
    TEXT_FIELD: {
        TYPE_ID: 5,
        ICON: MenuAlt2Icon,
        EDITOR: TextFieldEditor,
        VIEWER: TextField,
        ARTIFACT: TextFieldArtifact,
        TITLE: 'Text Field',
        DESCRIPTION: 'Input text without a student response area.',
        DEFAULT_SCHEMA: {
            title: {
                text: 'Text Field',
                show: true,
            },
            description: {
                text: '',
                show: true,
            },
        },
        EVENT_NAME: ANALYTICS_EVENTS.ADD_WORKBOOK_SECTION_CATEGORIES.TEXT_FIELD,
        PREVIEW: 'text-field.gif',
    },
    LINK: {
        TYPE_ID: 6,
        ICON: LinkIcon,
        EDITOR: LinkEditor,
        VIEWER: Link,
        ARTIFACT: LinkArtifact,
        TITLE: 'Link',
        DESCRIPTION: 'Insert a link to external material.',
        DEFAULT_SCHEMA: {
            text: 'Link',
            url: '',
        },
        EVENT_NAME: ANALYTICS_EVENTS.ADD_WORKBOOK_SECTION_CATEGORIES.LINK,
        PREVIEW: 'link.gif',
    },
    TABLE: {
        TYPE_ID: 7,
        ICON: TableIcon,
        EDITOR: TableEditor,
        VIEWER: Table,
        ARTIFACT: TableArtifact,
        TITLE: 'Table',
        DESCRIPTION: 'Build a table with or without student response areas.',
        DEFAULT_SCHEMA: {
            title: {
                text: '',
                show: false,
            },
            description: {
                text: '',
                show: false,
            },
            layout: TABLE_LAYOUTS.TOP_ROW,
            columns: 2,
            rows: 2,
            cellText: {},
        },
        NEW_SCHEMA: {
            title: {
                text: 'Table',
                show: true,
            },
            description: {
                text: 'Enter your responses in the table below.',
                show: true,
            },
            layout: TABLE_LAYOUTS.TOP_ROW,
            columns: 2,
            rows: 2,
            cellText: {},
        },
        EVENT_NAME: ANALYTICS_EVENTS.ADD_WORKBOOK_SECTION_CATEGORIES.TABLE,
        PREVIEW: 'table.gif',
    },
    NOTES_AREA: {
        TYPE_ID: 8,
        ICON: PencilIcon,
        EDITOR: NotesAreaEditor,
        VIEWER: NotesArea,
        ARTIFACT: NotesAreaArtifact,
        TITLE: 'Notes Area',
        DESCRIPTION: 'Insert a field for student input.',
        DEFAULT_SCHEMA: {
            text: 'Notes Area',
        },
        EVENT_NAME: ANALYTICS_EVENTS.ADD_WORKBOOK_SECTION_CATEGORIES.NOTES_AREA,
        PREVIEW: 'notes-area.gif',
    },
    WORKBOOK_DOWNLOAD_LINK: {
        TYPE_ID: 9,
        ICON: DownloadIcon,
        EDITOR: WorkbookDownloadLinkEditor,
        VIEWER: WorkbookDownloadLink,
        ARTIFACT: WorkbookDownloadLinkArtifact,
        TITLE: 'Workbook Download Link',
        DESCRIPTION: 'Provide a link that allows students to download their completed workbook.',
        DEFAULT_SCHEMA: {
            title: {
                text: "You've reached the end!",
                show: true,
            },
            description: {
                text: 'Download your completed workbook to keep your notes and responses.',
                show: true,
            },
        },
        EVENT_NAME: ANALYTICS_EVENTS.ADD_WORKBOOK_SECTION_CATEGORIES.WORKBOOK_DOWNLOAD_LINK,
        PREVIEW: 'workbook-download-link.gif',
    },
    RADIO_BUTTONS: {
        TYPE_ID: 10,
        ICON: RadioButtonIcon,
        EDITOR: RadioButtonsEditor,
        VIEWER: RadioButtons,
        ARTIFACT: RadioButtonsArtifact,
        TITLE: 'Radio Buttons',
        DESCRIPTION: 'Allow students to select only one option from a list you provide and show unselected options.',
        DEFAULT_SCHEMA: {
            title: {
                text: 'Radio buttons',
            },
            description: {
                text: '',
                show: true,
            },
            options: [
                {
                    id: 'option1', // New options will have a 5-character nanoID
                    label: 'Option 1',
                },
            ],
            showOther: false,
        },
        EVENT_NAME: ANALYTICS_EVENTS.ADD_WORKBOOK_SECTION_CATEGORIES.RADIO_BUTTONS,
        PREVIEW: 'radio-buttons.gif',
    },
    CHECKBOXES: {
        TYPE_ID: 11,
        ICON: CheckboxIcon,
        EDITOR: CheckboxesEditor,
        VIEWER: Checkboxes,
        ARTIFACT: CheckboxesArtifact,
        TITLE: 'Checkboxes',
        DESCRIPTION: 'Allow students to select one or more options from a list you provide.',
        DEFAULT_SCHEMA: {
            title: {
                text: 'Checkboxes',
            },
            description: {
                text: '',
                show: true,
            },
            options: [
                {
                    id: 'option1', // New options will have a 5-character nanoID
                    label: 'Option 1',
                },
            ],
            showOther: false,
        },
        EVENT_NAME: ANALYTICS_EVENTS.ADD_WORKBOOK_SECTION_CATEGORIES.CHECKBOXES,
        PREVIEW: 'checkboxes.gif',
    },
    DROP_DOWN_LIST: {
        TYPE_ID: 12,
        ICON: SelectorIcon,
        EDITOR: DropDownListEditor,
        VIEWER: DropDownList,
        ARTIFACT: DropDownListArtifact,
        TITLE: 'Drop-down List',
        DESCRIPTION:
            'Allow students to select only one option from a list that you provide and hide unselected options.',
        DEFAULT_SCHEMA: {
            title: {
                text: 'Drop-down list',
            },
            description: {
                text: '',
                show: true,
            },
            options: [
                {
                    id: 'option1', // New options will have a 5-character nanoID
                    value: 'option1',
                    label: 'Option 1',
                },
            ],
        },
        EVENT_NAME: ANALYTICS_EVENTS.ADD_WORKBOOK_SECTION_CATEGORIES.DROP_DOWN_LIST,
        PREVIEW: 'drop-down-list.gif',
    },
    FILL_IN_THE_BLANKS: {
        TYPE_ID: 13,
        ICON: ServerIcon,
        EDITOR: FillInTheBlanksEditor,
        VIEWER: FillInTheBlanks,
        ARTIFACT: FillInTheBlanksArtifact,
        TITLE: 'Fill in the Blanks',
        DESCRIPTION: 'Create an interactive exercise where students enter short responses to complete a phrase.',
        DEFAULT_SCHEMA: {
            title: {
                text: 'Fill in the blanks',
            },
            description: {
                text: '',
                show: true,
            },
            options: [
                {
                    id: 'text1', // New texts will have a 5-character nanoID
                    type: FILL_IN_THE_BLANKS_SECTION.TEXT_TYPE,
                    label: 'Text 1',
                },
                {
                    id: 'input1', // New inputs will have a 5-character nanoID
                    type: FILL_IN_THE_BLANKS_SECTION.INPUT_TYPE,
                    label: 'Input Field 1',
                },
            ],
        },
        EVENT_NAME: ANALYTICS_EVENTS.ADD_WORKBOOK_SECTION_CATEGORIES.FILL_IN_THE_BLANKS,
        PREVIEW: 'fill-in-the-blanks.gif',
    },
    MODULE_DOWNLOAD_LINK: {
        TYPE_ID: 14,
        ICON: DownloadIcon,
        EDITOR: ModuleDownloadLinkEditor,
        VIEWER: ModuleDownloadLink,
        ARTIFACT: ModuleDownloadLinkArtifact,
        TITLE: 'Module Download Link',
        DESCRIPTION: 'Provide a link that allows students to download only the current module.',
        DEFAULT_SCHEMA: {
            title: {
                text: 'End of Module',
                show: true,
            },
            description: {
                text: 'Download this module to keep your notes and responses.',
                show: true,
            },
        },
        EVENT_NAME: ANALYTICS_EVENTS.ADD_WORKBOOK_SECTION_CATEGORIES.MODULE_DOWNLOAD_LINK,
        PREVIEW: 'module-download-link.gif',
    },
    SLIDER: {
        TYPE_ID: 15,
        ICON: SliderIcon,
        EDITOR: SliderEditor,
        VIEWER: Slider,
        ARTIFACT: SliderArtifact,
        TITLE: 'Slider',
        DESCRIPTION: 'Allow students to select a value on a sliding scale.',
        DEFAULT_SCHEMA: {
            title: {
                text: 'Slider',
                show: true,
            },
            description: {
                text: 'Please choose a value on the scale.',
                show: true,
            },
            label: 'Percentage',
            range: {
                min: 0,
                max: 100,
            },
        },
        EVENT_NAME: ANALYTICS_EVENTS.ADD_WORKBOOK_SECTION_CATEGORIES.SLIDER,
        PREVIEW: 'slider.gif',
        NEW: false,
    },
    STUDENT_IMAGE_UPLOAD: {
        TYPE_ID: 16,
        ICON: PhotographIcon,
        EDITOR: StudentImageUploadEditor,
        VIEWER: StudentImageUpload,
        ARTIFACT: StudentImageUploadArtifact,
        TITLE: 'Student Image Upload',
        DESCRIPTION: 'Prompt students to upload an image that will be displayed in the workbook.',
        ADD_ON_DESCRIPTION: (
            <>
                <p>Prompt students to upload an image that will be displayed in the workbook.</p>
                <ul className="mt-3 ml-2 list-disc list-inside">
                    <li>Access to Student Upload Files feature</li>
                    <li>20GB of data storage to enable student uploads</li>
                </ul>
            </>
        ),
        ADD_ON_CTA: {
            label: 'Update in the Billing and Plans Page',
            href: `${ROUTES.CREATOR.SETTINGS}?section=${SETTINGS_SECTIONS.BILLING_AND_PLANS}`,
        },
        ADD_ON_MEMBER_CTA: {
            label: (
                <>
                    <div className="px-4 py-2 font-semibold rounded-md bg-warning-50 text-warning-500 paragraph__sm w-fit">
                        <ExclamationIcon className="inline-block mr-1 w-em" /> Speak to your account admin to add this
                        feature
                    </div>
                </>
            ),
        },
        DEFAULT_SCHEMA: {
            title: {
                text: 'Image Title',
                show: true,
            },
            image: {
                size: IMAGE_SIZES.FULL,
            },
            description: {
                text: '',
                show: true,
            },
            file: {
                max_size: FILE_MAX_SIZES_DESC['5MB'],
            },
        },
        EVENT_NAME: ANALYTICS_EVENTS.ADD_WORKBOOK_SECTION_CATEGORIES.STUDENT_IMAGE_UPLOAD,
        PREVIEW: 'student-image-upload.gif',
        NEW: false,
    },
    PAGE_BREAK: {
        TYPE_ID: 17,
        ICON: PageBreakIcon,
        EDITOR: PageBreakEditor,
        VIEWER: PageBreak,
        ARTIFACT: PageBreakArtifact,
        TITLE: 'PDF Page Break',
        DESCRIPTION: 'This section indicates a page break in the PDF version of the workbook.',
        DEFAULT_SCHEMA: {
            title: {
                text: 'PDF Page Break',
                show: true,
            },
            description: {
                text: '',
                show: false,
            },
        },
        EVENT_NAME: ANALYTICS_EVENTS.ADD_WORKBOOK_SECTION_CATEGORIES.PAGE_BREAK,
        PREVIEW: 'pdf-page-break.gif',
        NEW: false,
    },
    VIDEO: {
        TYPE_ID: 18,
        ICON: VideoCameraIcon,
        EDITOR: VideoEditor,
        VIEWER: Video,
        ARTIFACT: VideoArtifact,
        TITLE: 'Embed Video',
        DESCRIPTION: 'Embed YouTube, Vimeo or Loom videos.',
        DEFAULT_SCHEMA: {
            title: {
                text: 'Embed Video',
                show: true,
            },
            description: {
                text: '',
                show: true,
            },
            url: '',
        },
        EVENT_NAME: ANALYTICS_EVENTS.ADD_WORKBOOK_SECTION_CATEGORIES.VIDEO,
        PREVIEW: 'embed-video.gif',
        NEW: false,
    },
    FILE_UPLOAD: {
        TYPE_ID: 19,
        ICON: ArrowUpOnSquareStackIcon,
        EDITOR: FileUploadEditor,
        VIEWER: FileUpload,
        ARTIFACT: FileUploadArtifact,
        TITLE: 'Student File Upload',
        DESCRIPTION: 'Prompt students to upload a file to their workbook.',
        ADD_ON_DESCRIPTION: (
            <>
                <p>Prompt students to upload a file to their workbook.</p>
                <ul className="mt-3 ml-2 list-disc list-inside">
                    <li>Access to Student Upload Files feature</li>
                    <li>20GB of data storage to enable student uploads</li>
                </ul>
            </>
        ),
        ADD_ON_CTA: {
            label: 'Update in the Billing and Plans Page',
            href: `${ROUTES.CREATOR.SETTINGS}?section=${SETTINGS_SECTIONS.BILLING_AND_PLANS}`,
        },
        ADD_ON_MEMBER_CTA: {
            label: (
                <>
                    <div className="px-4 py-2 font-semibold rounded-md bg-warning-50 text-warning-500 paragraph__sm w-fit">
                        <ExclamationIcon className="inline-block mr-1 w-em" /> Speak to your account admin to add this
                        feature
                    </div>
                </>
            ),
        },
        DEFAULT_SCHEMA: {
            title: {
                text: 'File Upload',
                show: true,
            },
            description: {
                text: '',
                show: true,
            },
            file: {
                max_size: FILE_MAX_SIZES_DESC['5MB'],
            },
        },
        EVENT_NAME: ANALYTICS_EVENTS.ADD_WORKBOOK_SECTION_CATEGORIES.FILE_UPLOAD,
        PREVIEW: 'student-file-upload.gif',
        NEW: false,
    },
};

export const SECTIONS_DICTIONARY = {
    1: 'WORKBOOK_COVER_PAGE',
    2: 'MODULE_COVER_PAGE',
    3: 'IMAGE',
    4: 'QUESTION_AND_ANSWER',
    5: 'TEXT_FIELD',
    6: 'LINK',
    7: 'TABLE',
    8: 'NOTES_AREA',
    9: 'WORKBOOK_DOWNLOAD_LINK',
    10: 'RADIO_BUTTONS',
    11: 'CHECKBOXES',
    12: 'DROP_DOWN_LIST',
    13: 'FILL_IN_THE_BLANKS',
    14: 'MODULE_DOWNLOAD_LINK',
    15: 'SLIDER',
    16: 'STUDENT_IMAGE_UPLOAD',
    17: 'PAGE_BREAK',
    18: 'VIDEO',
    19: 'FILE_UPLOAD',
};

export const SECTIONS_EVENTS_DICTIONARY = {
    [ANALYTICS_EVENTS.ADD_WORKBOOK_SECTION_CATEGORIES.MODULE_COVER_PAGE]: 'MODULE_COVER_PAGE',
    [ANALYTICS_EVENTS.ADD_WORKBOOK_SECTION_CATEGORIES.IMAGE]: 'IMAGE',
    [ANALYTICS_EVENTS.ADD_WORKBOOK_SECTION_CATEGORIES.QUESTION_AND_ANSWER]: 'QUESTION_AND_ANSWER',
    [ANALYTICS_EVENTS.ADD_WORKBOOK_SECTION_CATEGORIES.TEXT_FIELD]: 'TEXT_FIELD',
    [ANALYTICS_EVENTS.ADD_WORKBOOK_SECTION_CATEGORIES.LINK]: 'LINK',
    [ANALYTICS_EVENTS.ADD_WORKBOOK_SECTION_CATEGORIES.TABLE]: 'TABLE',
    [ANALYTICS_EVENTS.ADD_WORKBOOK_SECTION_CATEGORIES.NOTES_AREA]: 'NOTES_AREA',
    [ANALYTICS_EVENTS.ADD_WORKBOOK_SECTION_CATEGORIES.WORKBOOK_DOWNLOAD_LINK]: 'WORKBOOK_DOWNLOAD_LINK',
    [ANALYTICS_EVENTS.ADD_WORKBOOK_SECTION_CATEGORIES.RADIO_BUTTONS]: 'RADIO_BUTTONS',
    [ANALYTICS_EVENTS.ADD_WORKBOOK_SECTION_CATEGORIES.CHECKBOXES]: 'CHECKBOXES',
    [ANALYTICS_EVENTS.ADD_WORKBOOK_SECTION_CATEGORIES.DROP_DOWN_LIST]: 'DROP_DOWN_LIST',
    [ANALYTICS_EVENTS.ADD_WORKBOOK_SECTION_CATEGORIES.FILL_IN_THE_BLANKS]: 'FILL_IN_THE_BLANKS',
    [ANALYTICS_EVENTS.ADD_WORKBOOK_SECTION_CATEGORIES.MODULE_DOWNLOAD_LINK]: 'MODULE_DOWNLOAD_LINK',
    [ANALYTICS_EVENTS.ADD_WORKBOOK_SECTION_CATEGORIES.SLIDER]: 'SLIDER',
    [ANALYTICS_EVENTS.ADD_WORKBOOK_SECTION_CATEGORIES.STUDENT_IMAGE_UPLOAD]: 'STUDENT_IMAGE_UPLOAD',
    [ANALYTICS_EVENTS.ADD_WORKBOOK_SECTION_CATEGORIES.PAGE_BREAK]: 'PAGE_BREAK',
    [ANALYTICS_EVENTS.ADD_WORKBOOK_SECTION_CATEGORIES.VIDEO]: 'VIDEO',
    [ANALYTICS_EVENTS.ADD_WORKBOOK_SECTION_CATEGORIES.FILE_UPLOAD]: 'FILE_UPLOAD',
};
