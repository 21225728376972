export default function SliderIcon({ className = '', style = {} }) {
    return (
        <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            style={style}>
            <path
                d="M12.3244 12.9828L20.6113 12.9828M12.3244 12.9828C12.3244 11.7938 11.3605 10.83 10.1715 10.83C8.98255 10.83 8.0187 11.7938 8.0187 12.9828M12.3244 12.9828C12.3244 14.1718 11.3605 15.1356 10.1715 15.1356C8.98255 15.1356 8.0187 14.1718 8.0187 12.9828M8.0187 12.9828L3.38866 12.9828"
                stroke="currentColor"
                strokeWidth="1.87399"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
