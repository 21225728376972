import { useWorkbookVersion } from '@/workbook/studentArtifact/WorkbookVersionJsonLoaderProvider';
import LoadingSpinner from '@/system/LoadingSpinner';
import TechnicalError from '@/system/TechnicalError';
import Viewer from '@/workbook/studentArtifact/Viewer';
import WorkbookStyles from '@/workbook/WorkbookStyles';
import usePrintable from '@/workbook/usePrintable';

export default function Workbook() {
    const { workbookVersion, loading, loadingError, creatorSchema, student, addOns } = useWorkbookVersion();
    const { schema } = workbookVersion;
    usePrintable();

    if (loading) return <LoadingSpinner />;
    if (loadingError) return <TechnicalError title="Error loading workbook" desc={`Details: ${loadingError}`} />;
    return (
        <>
            <WorkbookStyles schema={schema} />
            <Viewer workbook={workbookVersion} creatorSchema={creatorSchema} student={student} addOns={addOns} />
        </>
    );
}
