export default function ThinkificIcon({ className = '', style = {} }) {
    return (
        <svg
            width="22"
            height="26"
            viewBox="0 0 22 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            style={style}>
            <path
                d="M18.9945 0.5H3.00546C1.34559 0.5 0 1.83249 0 3.47619V22.5238C0 24.1675 1.34559 25.5 3.00546 25.5H18.9945C20.6544 25.5 22 24.1675 22 22.5238V3.47619C22 1.83249 20.6544 0.5 18.9945 0.5Z"
                fill="#25244A"
            />
            <path
                d="M10.9395 17.6798C11.7027 17.6798 12.3214 17.0671 12.3214 16.3113C12.3214 15.5555 11.7027 14.9429 10.9395 14.9429C10.1763 14.9429 9.55762 15.5555 9.55762 16.3113C9.55762 17.0671 10.1763 17.6798 10.9395 17.6798Z"
                fill="white"
            />
            <path
                d="M15.6109 6.72607H6.27092C5.79553 6.72607 5.41016 7.1077 5.41016 7.57846V8.61262C5.41016 9.08338 5.79553 9.465 6.27092 9.465H15.6109C16.0863 9.465 16.4717 9.08338 16.4717 8.61262V7.57846C16.4717 7.1077 16.0863 6.72607 15.6109 6.72607Z"
                fill="white"
            />
        </svg>
    );
}
