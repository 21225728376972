import { useEffect, useRef } from 'react';
import { ArrowNarrowLeftIcon, ArrowNarrowRightIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import { getSectionViewer } from '@/workbook/builder/sections/helpers';
import ModuleTitle from '@/workbook/builder/viewer/ModuleTitle';
import { SECTIONS } from '@/workbook/builder/sections/sections';
import PoweredByWobo from '@/workbook/builder/sections/PoweredByWobo';
import { APP_STYLES } from '@/system/styles';
import { EMBED_CONTEXTS } from '@/util/constants';
import { ICON_POSITION, ViewerButton } from '@/system/Buttons';

export default function Viewer({
    setSignupModalVisible,
    workbook,
    creatorSchema,
    student,
    embedded,
    viewerContainerRef,
    embedContext,
    mode,
    selectPrevModule,
    selectNextModule,
}) {
    const moduleContainerRef = useRef(null);
    const { schema, selectedModule } = workbook;
    const { poweredByWobo } = creatorSchema;
    const currentModuleIndex = workbook.moduleIdsDictionary[selectedModule.id];
    let showPrevModuleButton = currentModuleIndex > 0;
    const showNextModuleButton = currentModuleIndex < workbook.modules.length - 1;
    const selectedIsCoverPage = selectedModule?.id && workbook.moduleIdsDictionary[selectedModule.id] === 0;
    const isFirstSectionModuleCoverPage = selectedModule?.sections?.[0]?.type === SECTIONS.MODULE_COVER_PAGE.TYPE_ID;
    const sectionCount = selectedModule?.sections?.length || 0;
    const coverPageSchema = workbook?.modules[0]?.sections[0]?.schema;
    const hideCoverPageModule = coverPageSchema?.hidden ?? false;

    const scrollToTopOfModule = () => {
        const maxInnerWidth = embedded
            ? APP_STYLES.SCREENS.WORKBOOK_VIEWER_EMBED_SHOW_PADDED_CONTENT_MIN_WIDTH
            : APP_STYLES.SCREENS.LG_MIN_WIDTH;
        // The module container div on mobile width doesn't have a fixed height,
        // so we need to scroll to the top of the window.
        if (window.innerWidth < maxInnerWidth) {
            viewerContainerRef?.current?.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        } else {
            moduleContainerRef.current.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }
    };

    useEffect(() => {
        scrollToTopOfModule();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedModule]);

    // If cover page is set to hidden, don't show the back button on the first module
    if (hideCoverPageModule) {
        showPrevModuleButton = currentModuleIndex > 1;
    }

    return (
        <>
            <div
                role="presentation"
                key={selectedModule.id}
                ref={moduleContainerRef}
                onMouseDown={() => setSignupModalVisible(true)}
                onKeyDown={() => setSignupModalVisible(true)}
                className={classNames('relative mx-auto w-full space-y-8 overflow-y-auto', {
                    'min-h-full-minus-nav-button-container p-4 lg:p-10': !selectedIsCoverPage,
                    'pt-0 pr-4 pl-4 pb-4 lg:pt-0 lg:pr-10 lg:pl-10': isFirstSectionModuleCoverPage,
                    'lg:pb-10': isFirstSectionModuleCoverPage && sectionCount > 1,
                    'lg:pb-0': isFirstSectionModuleCoverPage && sectionCount === 1,
                    'h-full-minus-nav-button-container p-4 pb-0': selectedIsCoverPage,
                    'lg:p-0': selectedIsCoverPage && !embedded,
                    'emw:p-0': selectedIsCoverPage && embedded,
                    'emw:w-workbook-viewer-embed-desktop emw:shadow-md': embedded,
                    'emw:h-workbook-viewer-embed-desktop emw:min-h-workbook-viewer-embed-desktop':
                        embedded && embedContext !== EMBED_CONTEXTS.WORKBOOK_MODULE,
                    'emw:h-module-viewer-embed-desktop emw:min-h-module-viewer-embed-desktop':
                        embedded && embedContext === EMBED_CONTEXTS.WORKBOOK_MODULE,
                    'lg:h-full-minus-nav-button-container lg:min-h-full-minus-nav-button-container lg:w-workbook-viewer-desktop lg:shadow-md':
                        !embedded,
                })}
                style={{ backgroundColor: selectedIsCoverPage ? 'transparent' : schema.pageColor }}>
                <PoweredByWobo
                    enable={embedded && !selectedIsCoverPage && poweredByWobo && !isFirstSectionModuleCoverPage}
                />
                {!selectedIsCoverPage && !isFirstSectionModuleCoverPage && !selectedModule.show_title && (
                    <ModuleTitle title={selectedModule.title} workbookSchema={schema} />
                )}
                {selectedModule?.sections?.map((section, index) => {
                    // Hide the sections which are dependent on creator's paid add-ons or being on trial

                    // Hide page break sections from student view
                    if (section.type === SECTIONS.PAGE_BREAK.TYPE_ID) {
                        return null;
                    }

                    const sectionId = section.id;
                    const Section = getSectionViewer(section);

                    return (
                        <div
                            key={sectionId}
                            id={`section-${sectionId}`}
                            className={classNames('relative', {
                                'h-full w-full': section.type === SECTIONS.WORKBOOK_COVER_PAGE.TYPE_ID,
                            })}>
                            {Section && (
                                <div>
                                    <Section
                                        id={sectionId}
                                        schema={section.schema}
                                        workbook={workbook}
                                        module={selectedModule}
                                        workbookSchema={schema}
                                        student={student}
                                        mode={mode}
                                        showPoweredByWobo={
                                            embedded &&
                                            poweredByWobo &&
                                            (section.type === SECTIONS.WORKBOOK_COVER_PAGE.TYPE_ID ||
                                                section.type === SECTIONS.MODULE_COVER_PAGE.TYPE_ID) &&
                                            ((isFirstSectionModuleCoverPage && index === 0) || selectedIsCoverPage)
                                        }
                                    />
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
            {embedContext !== EMBED_CONTEXTS.WORKBOOK_MODULE && (
                <div
                    className={classNames(
                        'nav-button-container mx-auto flex w-full justify-between p-4 emw:py-4 emw:px-0',
                        { 'emw:w-workbook-viewer-desktop': !embedded, 'emw:w-workbook-viewer-embed-desktop': embedded },
                    )}
                    data-test="viewer-nav-container">
                    {showPrevModuleButton && (
                        <ViewerButton
                            label="Back"
                            icon={ArrowNarrowLeftIcon}
                            iconPosition={ICON_POSITION.LEFT}
                            onClick={selectPrevModule}
                            className="nav-button custom-focus-outline"
                        />
                    )}

                    {showNextModuleButton && (
                        <ViewerButton
                            label="Next"
                            icon={ArrowNarrowRightIcon}
                            onClick={selectNextModule}
                            className="ml-auto nav-button custom-focus-outline"
                        />
                    )}
                </div>
            )}
        </>
    );
}
