import { useEffect, useRef } from 'react';
import { polyfill } from 'seamless-scroll-polyfill';
import { useWorkbookVersion } from '@/workbook/demo/WorkbookVersionProvider';
import LoadingSpinner from '@/system/LoadingSpinner';
import TechnicalError from '@/system/TechnicalError';
import Sidebar from '@/workbook/student/sidebar/Sidebar';
import Viewer from '@/workbook/student/Viewer';
import WorkbookStyles from '@/workbook/WorkbookStyles';
import { WORKBOOK_SECTION_VIEWER_MODES } from '@/util/constants';

export default function Workbook() {
    const viewerContainerRef = useRef(null);
    const {
        workbookVersion,
        loading,
        loadingError,
        selectModule,
        selectPrevModule,
        selectNextModule,
        selectSection,
        setSectionResponseProp,
        saveResponseFile,
        resetResponseFile,
    } = useWorkbookVersion();
    const { schema } = workbookVersion;

    useEffect(() => {
        // Ensure that Safari can scrollTo, scrollIntoView smoothly
        polyfill();
    }, []);

    if (loading) return <LoadingSpinner />;
    if (loadingError) return <TechnicalError title="Error loading workbook" desc={`Details: ${loadingError}`} />;
    return (
        <>
            <WorkbookStyles schema={schema} />
            <div className="h-screen lg:flex lg:overflow-hidden">
                <div className="lg:h-full lg:w-96 lg:flex-shrink-0 lg:flex-grow-0 lg:bg-white">
                    <Sidebar workbook={workbookVersion} selectModule={selectModule} selectSection={selectSection} />
                </div>
                <div
                    ref={viewerContainerRef}
                    className="viewer-container-background-color h-screen-minus-mobile-navbar overflow-auto lg:h-full lg:w-[calc(100vw-24rem)] lg:flex-shrink lg:flex-grow lg:bg-neutral-50 lg:pt-6">
                    <Viewer
                        workbook={workbookVersion}
                        workbookVersion={workbookVersion}
                        selectPrevModule={selectPrevModule}
                        selectNextModule={selectNextModule}
                        setSectionResponseProp={setSectionResponseProp}
                        saveSectionResponseProp={setSectionResponseProp}
                        embedded={false}
                        viewerContainerRef={viewerContainerRef}
                        saveResponseFile={saveResponseFile}
                        resetResponseFile={resetResponseFile}
                        mode={WORKBOOK_SECTION_VIEWER_MODES.PREVIEW}
                    />
                </div>
            </div>
        </>
    );
}
