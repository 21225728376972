/* eslint-disable react/jsx-boolean-value */
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '@/system/LoadingSpinner';
import TechnicalError from '@/system/TechnicalError';
import { useAuth } from '@/auth/AuthProvider';
import { useEmbed } from '@/embed/EmbedProvider';
import useQueryParameter from '@/util/useQueryParameter';
import { ROLE_QUERY_PARAM, ROUTES, ERROR_QUERY_PARAM, FROM_URL_QUERY_PARAM } from '@/util/constants';
import { SSO_ERROR_ACTION } from '@/embed/ThinkificSso';
import { appendQueryParams, getSsoErrorState } from '@/util/helpers';
import useGTM from '@/util/useGTM';

export default function GoogleAuthComplete() {
    const isMounted = useRef(true);
    const navigate = useNavigate();
    const { completeEmbedLogin, user, setOriginalSignin } = useAuth();
    const { embedded } = useEmbed();
    const ssoError = useQueryParameter(ERROR_QUERY_PARAM);
    const fromUrl = useQueryParameter(FROM_URL_QUERY_PARAM);
    const role = useQueryParameter(ROLE_QUERY_PARAM);
    const errorState = getSsoErrorState(ssoError, role);
    const fromUrlWithRole = appendQueryParams(fromUrl, { role });
    const gtm = useGTM();

    const handleSsoErrorClick = () => {
        navigate(errorState.navigateUrl);
    };

    const reportError = (errorMessage) => {
        window.opener.postMessage({ action: SSO_ERROR_ACTION, payload: errorMessage }, '*');
        window.opener.console.error(errorMessage);
        window.close();
    };

    useEffect(() => {
        isMounted.current = true;
        if (embedded) {
            if (!ssoError) {
                const defaultUrl = `${ROUTES.COMMON.BASE}?embed=true`;
                completeEmbedLogin()
                    .then(() => {
                        gtm.pushSignin('creator', 'google', user.id);
                        setOriginalSignin('google');
                        window.opener.location.href = fromUrlWithRole || appendQueryParams(defaultUrl, { role });
                        window.close();
                    })
                    .catch((error) => {
                        if (isMounted.current) {
                            reportError(error.message);
                        }
                    });
            } else {
                reportError(ssoError);
            }
        } else if (!ssoError) {
            gtm.pushSignin('creator', 'google', user.id);
            setOriginalSignin('google');
            navigate(fromUrlWithRole || appendQueryParams(ROUTES.COMMON.BASE, { role }));
        }
        return () => {
            isMounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (ssoError)
        return (
            <TechnicalError
                title="SSO error"
                desc={`Error completing SSO: ${ssoError}`}
                buttonLabel={embedded ? null : errorState.buttonLabel}
                onButtonClick={handleSsoErrorClick}
            />
        );
    return <LoadingSpinner />;
}
