import { SplitFactory } from '@splitsoftware/splitio-react';

const config = {
    core: {
        authorizationKey: process.env.REACT_APP_SPLITIO_CLIENT_KEY || '',
        key: 'CUSTOMER_ID',
    },
    startup: {
        readyTimeout: 1.5,
    },
};

const FeatureFlagProvider = ({ children }) => {
    return (
        <SplitFactory config={config} updateOnSdkTimedout>
            {() => {
                return children;
            }}
        </SplitFactory>
    );
};

export default FeatureFlagProvider;
