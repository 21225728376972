import { useEffect } from 'react';
import { AUTHENTICATION_TYPES, ROUTES } from '@/util/constants';
import LoadingSpinner from '@/system/LoadingSpinner';
import useQueryParameter from '@/util/useQueryParameter';

export default function ThinkificInstall() {
    const subdomain = useQueryParameter('subdomain');

    useEffect(() => {
        window.location.href = `${ROUTES.CREATOR.SIGNUP}?type=${AUTHENTICATION_TYPES.THINKIFIC}&referred_from_thinkific=true&subdomain=${subdomain}`;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <LoadingSpinner />;
}
