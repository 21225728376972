import { useWorkbook } from '@/workbook/artifact/WorkbookJsonLoaderProvider';
import LoadingSpinner from '@/system/LoadingSpinner';
import TechnicalError from '@/system/TechnicalError';
import Viewer from '@/workbook/artifact/Viewer';
import WorkbookStyles from '@/workbook/WorkbookStyles';
import usePrintable from '@/workbook/usePrintable';

export default function Workbook() {
    const { workbook, student, loading, loadingError, creatorSchema, addOns } = useWorkbook();
    const { schema } = workbook;
    usePrintable();

    if (loading) return <LoadingSpinner />;
    if (loadingError) return <TechnicalError title="Error loading workbook" desc={`Details: ${loadingError}`} />;
    return (
        <>
            <WorkbookStyles schema={schema} />
            <Viewer workbook={workbook} creatorSchema={creatorSchema} student={student} addOns={addOns} />
        </>
    );
}
