import classNames from 'classnames';
import { ExclamationIcon, ExclamationCircleIcon, XIcon } from '@heroicons/react/outline';

export const NOTIFICATION_VARIANTS = {
    ERROR: 'error',
    WARNING: 'warning',
    INFO: 'info',
};

function BaseNotification({ component, onRemove, variant = NOTIFICATION_VARIANTS.INFO }) {
    if (!component) return null;

    const Body = component;
    let iconContainerClasses;
    let Logo;

    switch (variant) {
        case NOTIFICATION_VARIANTS.ERROR:
            iconContainerClasses = 'bg-error-100 text-error-400';
            Logo = ExclamationIcon;
            break;
        case NOTIFICATION_VARIANTS.WARNING:
            iconContainerClasses = 'bg-warning-100 text-warning-500';
            Logo = ExclamationCircleIcon;
            break;
        default:
            iconContainerClasses = 'bg-success-100 text-success-500';
            Logo = ExclamationCircleIcon;
    }

    const handleRemoveClick = () => {
        if (typeof onRemove === 'function') onRemove();
    };

    return (
        <div className="relative flex  w-full rounded-lg bg-neutral-25 px-4.5 py-6 shadow-md">
            <div className="absolute right-2 top-2">
                <button type="button" onClick={handleRemoveClick}>
                    <XIcon className="h-em w-em" />
                </button>
            </div>
            <div className="mr-4">
                <div
                    className={classNames(
                        'flex h-12 w-12 items-center justify-center rounded-full',
                        iconContainerClasses,
                    )}>
                    <Logo className="h-6 w-6" />
                </div>
            </div>
            <Body />
        </div>
    );
}

export function InfoNotification({ component, onRemove }) {
    return <BaseNotification component={component} onRemove={onRemove} variant={NOTIFICATION_VARIANTS.INFO} />;
}

export function WarningNotification({ component, onRemove }) {
    return <BaseNotification component={component} onRemove={onRemove} variant={NOTIFICATION_VARIANTS.WARNING} />;
}

export function ErrorNotification({ component, onRemove }) {
    return <BaseNotification component={component} onRemove={onRemove} variant={NOTIFICATION_VARIANTS.ERROR} />;
}
