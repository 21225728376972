import { useState, useEffect, useRef } from 'react';
import { XIcon } from '@heroicons/react/outline';
import { DefaultButton } from '@/system/Buttons';
import ImageUploaderModal from '@/system/imageUploader/ImageUploaderModal';

const initialState = () => ({ show: false, mode: 'upload', data: null });

export default function ImageUploader({
    title,
    imageName,
    buttonLabel,
    onSelect,
    onRemove,
    onFileLoaderError,
    constraintText,
    validMimeTypes,
    filenameMaxWidth = '127px',
}) {
    const isMounted = useRef(true);
    const [imageUploader, setImageUploader] = useState(initialState);

    const handleButtonClick = () => {
        setImageUploader({ mode: 'upload', data: null, show: true });
    };

    const handleImageUse = (data) => {
        if (typeof onSelect === 'function') {
            setImageUploader({ mode: 'uploading', data, show: true });
            onSelect(data).then(() => {
                if (isMounted.current) setImageUploader({ mode: 'uploading', data: null, show: false });
            });
        } else {
            setImageUploader({ mode: 'edit', data, show: false });
        }
    };

    const handleImageReset = () => {
        setImageUploader(initialState);
        if (typeof onRemove === 'function') onRemove();
    };

    const handleFileLoaded = (data) => {
        setImageUploader({ ...imageUploader, mode: 'edit', data });
    };

    const handleModalClose = () => {
        setImageUploader(initialState);
    };

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);

    return (
        <>
            <div className="mb-3.5 align-middle">
                <DefaultButton label={buttonLabel} onClick={handleButtonClick} />
                {imageName && (
                    <div className="caption ml-2.5 inline-flex items-center">
                        <span className="truncate" style={{ maxWidth: filenameMaxWidth || '127px' }}>
                            {imageName}
                        </span>{' '}
                        <button type="button" className="ml-2" onClick={handleImageReset}>
                            <XIcon className="h-em w-em" />
                        </button>
                    </div>
                )}
            </div>
            <ImageUploaderModal
                show={imageUploader.show}
                mode={imageUploader.mode}
                title={title}
                constraintText={constraintText}
                validMimeTypes={validMimeTypes}
                imageData={imageUploader.data}
                onUse={handleImageUse}
                onFileLoaded={handleFileLoaded}
                onClose={handleModalClose}
                onFileLoaderError={onFileLoaderError}
            />
        </>
    );
}
