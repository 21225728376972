/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { Fragment, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import debounce from 'lodash/debounce';
import { Disclosure, Transition } from '@headlessui/react';
import {
    BookOpenIcon,
    ChevronDownIcon,
    CreditCardIcon,
    InformationCircleIcon,
    LogoutIcon,
    MenuIcon,
    QuestionMarkCircleIcon,
    UserGroupIcon,
    UserIcon,
    ViewGridIcon,
    XIcon,
} from '@heroicons/react/outline';
import classNames from 'classnames';
import { Link, matchPath, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '@/auth/AuthProvider';
import { useEmbed } from '@/embed/EmbedProvider';
import { ROLES, ROUTES, SETTINGS_SECTIONS, WOBO_SUPPORT_DOCS_BASE_URL } from '@/util/constants';
import { isCreatorPreviewRoute, isMemberPreviewRoute, isStudentWorkbookRoute } from '@/util/helpers';
import MobileNavButton from '@/system/NavButton';
import { useRoles } from '@/navigation/RolesProvider';
import AccountSelector from './AccountSelector';
import { APP_STYLES } from '@/system/styles';
import UserFlagBar from '@/navigation/UserFlagBar';

const navbarConfig = {
    [ROLES.CREATOR]: {
        mobileMenuOptions: [
            { name: 'Workbooks', route: `${ROUTES.CREATOR.WORKBOOKS_DASHBOARD}`, icon: BookOpenIcon },
            {
                name: 'Profile',
                route: `${ROUTES.CREATOR.SETTINGS}?section=${SETTINGS_SECTIONS.PROFILE}`,
                icon: UserIcon,
            },
            {
                name: 'Workbook Settings',
                route: `${ROUTES.CREATOR.SETTINGS}?section=${SETTINGS_SECTIONS.WORKBOOK_SETTINGS}`,
                icon: BookOpenIcon,
            },
            { name: 'Apps', route: `${ROUTES.CREATOR.SETTINGS}?section=${SETTINGS_SECTIONS.APPS}`, icon: ViewGridIcon },
            {
                name: 'Billing & Plans',
                route: `${ROUTES.CREATOR.SETTINGS}?section=${SETTINGS_SECTIONS.BILLING_AND_PLANS}`,
                icon: CreditCardIcon,
            },
            {
                name: 'Members',
                route: `${ROUTES.CREATOR.SETTINGS}?section=${SETTINGS_SECTIONS.MEMBERS}`,
                icon: UserGroupIcon,
            },
            {
                name: 'Support Docs',
                href: WOBO_SUPPORT_DOCS_BASE_URL,
                icon: QuestionMarkCircleIcon,
            },
            {
                name: 'Support',
                route: `${ROUTES.CREATOR.SETTINGS}?section=${SETTINGS_SECTIONS.SUPPORT}`,
                icon: InformationCircleIcon,
            },
            {
                name: 'Logout',
                route: ROUTES.CREATOR.LOGIN,
                icon: LogoutIcon,
            },
        ],
        routes: {
            login: ROUTES.CREATOR.LOGIN,
            dashboard: ROUTES.CREATOR.WORKBOOKS_DASHBOARD,
            settings: ROUTES.CREATOR.SETTINGS,
            admin: ROUTES.CREATOR.ADMIN,
        },
    },
    [ROLES.STUDENT]: {
        mobileMenuOptions: [
            { name: 'Workbooks', route: ROUTES.STUDENT.DASHBOARD, icon: BookOpenIcon },
            {
                name: 'Profile',
                route: `${ROUTES.STUDENT.SETTINGS}?section=${SETTINGS_SECTIONS.PROFILE}`,
                icon: UserIcon,
            },
            {
                name: 'Support',
                href: WOBO_SUPPORT_DOCS_BASE_URL,
                icon: QuestionMarkCircleIcon,
            },
            {
                name: 'Logout',
                route: ROUTES.STUDENT.LOGIN,
                icon: LogoutIcon,
            },
        ],
        routes: {
            login: ROUTES.STUDENT.LOGIN,
            dashboard: ROUTES.STUDENT.DASHBOARD,
            settings: ROUTES.STUDENT.SETTINGS,
        },
    },
    [ROLES.MEMBER]: {
        mobileMenuOptions: [
            { name: 'Workbooks', route: `${ROUTES.MEMBER.DASHBOARD}`, icon: BookOpenIcon },
            {
                name: 'Profile',
                route: `${ROUTES.MEMBER.SETTINGS}?section=${SETTINGS_SECTIONS.PROFILE}`,
                icon: UserIcon,
            },
            {
                name: 'Support',
                href: WOBO_SUPPORT_DOCS_BASE_URL,
                icon: QuestionMarkCircleIcon,
            },
            {
                name: 'Logout',
                route: ROUTES.MEMBER.LOGIN,
                icon: LogoutIcon,
            },
        ],
        routes: {
            login: ROUTES.CREATOR.LOGIN,
            dashboard: ROUTES.MEMBER.DASHBOARD,
            settings: ROUTES.MEMBER.SETTINGS,
        },
    },
};

const hideNavbar = (pathname) => {
    const routes = [
        ROUTES.CREATOR.PROCESS_PAYMENT,
        ROUTES.CREATOR.PAYMENT,
        ROUTES.CREATOR.TRIAL,
        ROUTES.CREATOR.THINKIFIC_SIGNUP_SUCCESS,
        ROUTES.CREATOR.GOOGLE_SIGNUP_SUCCESS,
        ROUTES.CREATOR.STUDENT_RESPONSES,
        ROUTES.MEMBER.STUDENT_RESPONSES,
    ];
    return (
        isCreatorPreviewRoute(pathname) ||
        isMemberPreviewRoute(pathname) ||
        ((isMobile || (!isMobile && window.innerWidth < APP_STYLES.SCREENS.LG_MIN_WIDTH)) &&
            isStudentWorkbookRoute(pathname)) ||
        routes.some((route) => !!matchPath(route, pathname))
    );
};

export default function AppNavbar() {
    const [show, setShow] = useState(false);
    const { user, signout } = useAuth();
    const { currentRole, isAdmin } = useRoles();
    const { embedded } = useEmbed();
    const location = useLocation();
    const navigate = useNavigate();
    const currentUrl = location.pathname + location.search;
    const handleLogout = () => {
        setShow(false);
        signout();
    };

    const handleSupportLinkClick = () => {
        window.open(WOBO_SUPPORT_DOCS_BASE_URL, '_blank');
    };

    const handleMobileButtonClick = (option, close) => {
        if (option.href) {
            window.open(option.href, '_blank');
        } else {
            navigate(option.route);
            close();
        }
    };

    useEffect(() => {
        if (!embedded && !isMobile) {
            const listener = debounce(() => {
                if (hideNavbar(location.pathname)) {
                    setShow(false);
                } else {
                    setShow(true);
                }
            }, 150);

            window.addEventListener('resize', listener);

            return () => {
                window.removeEventListener('resize', listener);
            };
        }
        return () => {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    useEffect(() => {
        if (hideNavbar(location.pathname)) setShow(false);
        else if (!embedded) setShow(true);
    }, [embedded, location.pathname]);

    if (!show) return null;

    const closeUserMenu = (closeMenu) => {
        // setTimeout has to be here, because panel transitions in over 100ms
        const timeout = setTimeout(() => {
            const menu = document.querySelector(`#headlessui-disclosure-panel-2`);
            const handler = (evt) => {
                if (evt.target !== menu) {
                    closeMenu();
                    window.removeEventListener('click', handler);
                    clearTimeout(timeout);
                }
            };
            window.addEventListener('click', handler);
        }, 101);
    };

    const loginRoute = navbarConfig[currentRole].routes.login;
    const dashboardRoute = navbarConfig[currentRole].routes.dashboard;
    const settingsRoute = navbarConfig[currentRole].routes.settings;
    const adminRoute = navbarConfig[currentRole].routes.admin;
    const { mobileMenuOptions } = navbarConfig[currentRole];
    return (
        <Disclosure as="nav" className="relative bg-white">
            {({ open, close }) => (
                <>
                    <div className="relative z-20 flex h-12 items-center justify-between bg-white px-2 shadow sm:px-4 lg:h-16 lg:px-16">
                        <Link className="flex flex-shrink-0 items-center ml-2 sm:ml-0" to={dashboardRoute}>
                            <img src="/images/logo.svg" alt="Wobo" className="block w-20" />
                        </Link>
                        <AccountSelector />
                        <div className="lg:hidden">
                            {/* Mobile menu button */}
                            <Disclosure.Button className="inline-flex items-center justify-center p-2 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                <span className="sr-only">Open mobile main menu</span>
                                <MenuIcon className="block w-em-2" aria-hidden="true" />
                            </Disclosure.Button>
                        </div>
                        <div className="hidden lg:flex lg:items-center">
                            {/* Profile dropdown */}
                            <Disclosure as="div" className="relative">
                                {({ close: closeMenu }) => (
                                    <>
                                        <Disclosure.Button
                                            className="flex items-center text-sm"
                                            onMouseUp={() => closeUserMenu(closeMenu)}>
                                            <span className="sr-only">Open user menu</span>
                                            <img
                                                className="w-8 [clip-path:circle(47%_at_50%_50%)]"
                                                src="/images/profile-picture-placeholder.svg"
                                                alt=""
                                            />
                                            <ChevronDownIcon className="ml-3 inline-block h-5 w-5" />
                                        </Disclosure.Button>

                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95">
                                            <Disclosure.Panel className="absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                {/* TODO move this to the navbarConfig object */}
                                                <Link
                                                    className={classNames(
                                                        'block px-3.5 py-2 text-sm text-neutral-400',
                                                        {
                                                            'bg-neutral-50': settingsRoute === currentUrl,
                                                        },
                                                    )}
                                                    to={settingsRoute}
                                                    onClick={closeMenu}>
                                                    Settings
                                                </Link>
                                                {isAdmin && (
                                                    <Link
                                                        className={classNames(
                                                            'block px-3.5 py-2 text-sm text-neutral-400',
                                                            {
                                                                'bg-neutral-50': adminRoute === currentUrl,
                                                            },
                                                        )}
                                                        to={adminRoute}
                                                        onClick={closeMenu}>
                                                        Admin
                                                    </Link>
                                                )}
                                                <button
                                                    type="button"
                                                    className="block px-3.5 py-2 text-sm text-neutral-400"
                                                    onClick={() => {
                                                        handleSupportLinkClick();
                                                        closeMenu();
                                                    }}>
                                                    Support Docs
                                                </button>

                                                <Link
                                                    className={classNames(
                                                        'block px-3.5 py-2 text-sm text-neutral-400',
                                                        {
                                                            'bg-neutral-50': loginRoute === currentUrl,
                                                        },
                                                    )}
                                                    onClick={handleLogout}
                                                    to={loginRoute}>
                                                    Logout
                                                </Link>
                                            </Disclosure.Panel>
                                        </Transition>
                                    </>
                                )}
                            </Disclosure>
                        </div>
                    </div>
                    <UserFlagBar settingsRoute={settingsRoute} />
                    <Transition show={open} as={Fragment}>
                        <Disclosure.Panel className="transparent absolute top-0 z-50 h-screen w-screen lg:hidden">
                            <Transition.Child
                                enter="transition-opacity ease-linear duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="transition-opacity ease-linear duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0">
                                <div
                                    className="absolute h-full w-full bg-neutral-400 opacity-50"
                                    role="button"
                                    onClick={close}
                                    tabIndex={-1}
                                />
                            </Transition.Child>
                            <Transition.Child
                                enter="transition ease-in-out duration-300 transform"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transition ease-in-out duration-300 transform"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full">
                                <div className="relative top-0 left-1/3 h-screen w-2/3 bg-white px-4 py-9 sm:left-2/3 sm:w-1/3">
                                    <Disclosure.Button className="absolute top-3.5 right-3.5 sm:right-8.5">
                                        <span className="sr-only">Close mobile main menu</span>
                                        <XIcon className="w-6" aria-hidden="true" />
                                    </Disclosure.Button>
                                    <div className="heading__sm--semibold mb-8 flex items-start">
                                        <img
                                            className="mr-2 w-8 [clip-path:circle(47%_at_50%_50%)]"
                                            src="/images/profile-picture-placeholder.svg"
                                            alt=""
                                        />
                                        <div className="w-4/5 break-words">{user.firstName}</div>
                                    </div>
                                    <div className="space-y-2">
                                        {mobileMenuOptions.map((option) => {
                                            return (
                                                <MobileNavButton
                                                    key={option.name}
                                                    label={option.name}
                                                    icon={option.icon}
                                                    onClick={() => handleMobileButtonClick(option, close)}
                                                    current={option.route === currentUrl}
                                                />
                                            );
                                        })}
                                    </div>
                                </div>
                            </Transition.Child>
                        </Disclosure.Panel>
                    </Transition>
                </>
            )}
        </Disclosure>
    );
}
