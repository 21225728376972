/* eslint-disable react/jsx-boolean-value */
import { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '@/auth/AuthProvider';
import useAuthApi from '@/api/useAuthApi';
import { useEmbed } from '@/embed/EmbedProvider';
import { CardWithFooter } from '@/auth/Cards';
import TextInput, {
    TEXT_INPUT_AUTO_CAPITALIZE,
    TEXT_INPUT_AUTO_CORRECT,
    TEXT_INPUT_SPELL_CHECK,
    TEXT_INPUT_TYPE,
} from '@/system/TextInput';
import { isEmailValid } from '@/auth/helpers';
import { ROLE_QUERY_PARAM, ROLES, ROUTES } from '@/util/constants';
import useGTM from '@/util/useGTM';
import { getStudentEnrollInPubliclySharedWorkbook } from '@/util/helpers';
import { userFlagsUtil } from '@/util/useUserFlags';
import ForgotPassword from '@/auth/ForgotPassword';

export default function EmailLogin({
    creator,
    fromUrl,
    cardProps = {},
    autoEnrollPubliclySharedWorkbookId = undefined,
}) {
    const isMounted = useRef(true);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [checkEmail, setCheckEmail] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState('');
    const [password, setPassword] = useState('');
    const [checkPassword, setCheckPassword] = useState(false);
    const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
    const [apiErrorMessage, setApiErrorMessage] = useState('');
    const [validUser, setValidUser] = useState();
    const [needsPasswordReset, setNeedsPasswordReset] = useState(false);
    const [passwordResetSent, setPasswordResetSent] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { signin, setOriginalSignin, sendPasswordResetEmail } = useAuth();
    const { getUserFlags } = useAuthApi();
    const { embedded } = useEmbed();
    const gtm = useGTM();

    const { from } = location.state || {
        from: { pathname: fromUrl || ROUTES.COMMON.BASE },
    };

    const validateEmail = (value, checkFormat = true) => {
        let passed = true;
        if (apiErrorMessage) setApiErrorMessage('');
        if (!checkEmail) setCheckEmail(true);
        if (emailErrorMessage) passed = false;
        if (!value) {
            setEmailErrorMessage('email is required');
            passed = false;
        } else {
            setEmailErrorMessage('');
        }
        if (checkFormat && value && !isEmailValid(value)) {
            setEmailErrorMessage('email is not valid');
            passed = false;
        }
        return passed;
    };

    const checkUserFlags = (emailAddress) => {
        getUserFlags(emailAddress)
            .then((data) => {
                if (data?.flags > 0) {
                    const { flags } = data;
                    const flagUtil = userFlagsUtil(flags);
                    if (flagUtil.isEmailNotVerified || flagUtil.isPasswordNotSet) {
                        setNeedsPasswordReset(true);
                        setPasswordResetSent(false);
                    }
                    if (!flagUtil.isEmailNotVerified && !flagUtil.isPasswordNotSet) {
                        setValidUser(true);
                    }
                }
            })
            .catch(() => {});
    };

    const handleEmailChange = (value) => {
        setEmail(value);
        validateEmail(value, false);
        setNeedsPasswordReset(false);
        checkUserFlags(value);
    };

    const handleEmailBlur = (value) => {
        setEmail(value);
        validateEmail(value);
        checkUserFlags(value);
    };

    const validatePassword = (value) => {
        let passed = true;
        if (apiErrorMessage) setApiErrorMessage('');
        if (!checkPassword) setCheckPassword(true);
        if (passwordErrorMessage) passed = false;
        if (!value) {
            setPasswordErrorMessage('password is required');
            passed = false;
        } else {
            setPasswordErrorMessage('');
        }
        return passed;
    };

    const handlePasswordChange = (e) => {
        const { value: newPassword } = e.target;
        setPassword(newPassword);
        validatePassword(newPassword);
    };

    const checkFields = () => {
        let passed = true;
        if (!validateEmail(email)) passed = false;
        if (!validatePassword(password)) passed = false;
        return passed;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!needsPasswordReset) {
            if (!checkFields()) return;
            setLoading(true);
            signin(email, password)
                .then((user) => {
                    if (user !== null) {
                        gtm.pushSignin('creator', 'email', user.id);
                        setOriginalSignin('email');
                        if (autoEnrollPubliclySharedWorkbookId) {
                            const path = getStudentEnrollInPubliclySharedWorkbook(autoEnrollPubliclySharedWorkbookId);
                            navigate(path, { replace: true });
                        } else {
                            navigate(from, { replace: true });
                        }
                    }
                    if (isMounted.current) setLoading(false);
                })
                .catch((error) => {
                    if (isMounted.current) {
                        setApiErrorMessage(error.message);
                        setLoading(false);
                    }
                });
        } else {
            setLoading(true);
            sendPasswordResetEmail(email, ROLES.STUDENT)
                .then(() => {
                    setLoading(false);
                    setPasswordResetSent(true);
                })
                .catch((err) => {
                    setApiErrorMessage(err.message);
                    setPasswordResetSent(false);
                    setLoading(false);
                });
        }
    };

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);

    if (passwordResetSent) {
        return <ForgotPassword className="md:pt-0 md:w-full" emailSentProp title="Email sent!" />;
    }
    return (
        <CardWithFooter
            loading={loading}
            onSubmit={handleSubmit}
            buttonLabel={needsPasswordReset ? 'Set Password' : 'Log in'}
            apiErrorMessage={apiErrorMessage}
            hideLogo={cardProps?.hideLogo}
            postFormSection={cardProps?.postFormSection}
            secondarySubmitButton={cardProps?.secondarySubmitButton}
            className={`md:pt-0 md:w-full ${cardProps?.className}`}
            footer={
                <div className="caption">
                    <div className="flex flex-row">
                        <span className="mr-1">Forgot your password?</span>
                        <Link
                            className="caption--semibold"
                            to={`${ROUTES.COMMON.FORGOT_PASSWORD}?${ROLE_QUERY_PARAM}=${
                                creator ? ROLES.CREATOR : ROLES.STUDENT
                            }`}>
                            Reset it here.
                        </Link>
                    </div>
                    {creator && (
                        <div className="flex flex-row">
                            <span className="mr-1">Don&apos;t have an account?</span>
                            <Link className="caption--semibold" to={ROUTES.CREATOR.SIGNUP}>
                                Sign up!
                            </Link>
                        </div>
                    )}
                    {!creator && embedded && (
                        <div>
                            If you don&apos;t have a Google account, your instructor can send you an invite via email to
                            sign up for Wobo. If you haven&apos;t received the invitation, please contact your
                            instructor.
                        </div>
                    )}
                </div>
            }>
            {cardProps?.header}
            <TextInput
                value={email}
                placeholder="email"
                data-test="email"
                onChange={(e) => handleEmailChange(e.target.value)}
                onBlur={(e) => handleEmailBlur(e.target.value.trim())}
                required
                error={checkEmail && !!emailErrorMessage}
                errorMsg={emailErrorMessage}
                autoCorrect={TEXT_INPUT_AUTO_CORRECT.OFF}
                autoCapitalize={TEXT_INPUT_AUTO_CAPITALIZE.NONE}
                spellCheck={TEXT_INPUT_SPELL_CHECK.FALSE}
            />
            {!needsPasswordReset && (
                <TextInput
                    value={password}
                    placeholder="password"
                    data-test="password"
                    type={TEXT_INPUT_TYPE.PASSWORD}
                    onChange={handlePasswordChange}
                    required
                    error={checkPassword && !!passwordErrorMessage}
                    errorMsg={passwordErrorMessage}
                    autoCorrect={TEXT_INPUT_AUTO_CORRECT.OFF}
                    autoCapitalize={TEXT_INPUT_AUTO_CAPITALIZE.NONE}
                    spellCheck={TEXT_INPUT_SPELL_CHECK.FALSE}
                />
            )}
            {!validUser && needsPasswordReset && (
                <p className="caption mt-2 text-red-500">
                    An account with this email already exists please set a password.
                </p>
            )}
            {validUser && needsPasswordReset && (
                <p className="caption mt-4 text-red-500">An account with this email already exists please log in.</p>
            )}
        </CardWithFooter>
    );
}
