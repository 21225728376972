import { Fragment, useState, useEffect } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon, OfficeBuildingIcon, UserCircleIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import { ROLES } from '@/util/constants';
import { useRoles } from '@/navigation/RolesProvider';
import { getCreatorStorageObjectBasePath, getStorageUrl } from '@/util/helpers';
import { useAuth } from '@/auth/AuthProvider';

const separator = '|';

const getDictionaryId = (role, creatorId) => role + separator + creatorId;
const getRoleAndCreatorId = (dictionaryId) => dictionaryId.split(separator);

export default function AccountSelector() {
    const { user } = useAuth();
    const { creator, member, student, roles, selectedCreator, currentRole, setCurrentRole } = useRoles();
    const [optionsDictionary, setOptionsDictionary] = useState({});
    const [options, setOptions] = useState([]);

    const getCompanyNamesAndLogoStorageFilenames = () => {
        let names = '';
        let storageFilenames = '';
        if (user.roles.includes(ROLES.CREATOR)) {
            names += creator.companyName;
            storageFilenames += creator.schema.companyLogo.storage_filename;
        }
        if (user.roles.includes(ROLES.MEMBER)) {
            Object.keys(member.creators).forEach((creatorId) => {
                names += member.creators[creatorId].creatorCompanyName;
                storageFilenames += member.creators[creatorId].creatorCompanyLogoStorageFilename;
            });
        }
        return [names, storageFilenames];
    };

    const [companyNames, companyLogoStorageFilenames] = getCompanyNamesAndLogoStorageFilenames();

    const getCreatorIcon = (creatorId, creatorLogoStorageFilename) => {
        if (!creatorLogoStorageFilename) return <OfficeBuildingIcon className="h-[28px] w-[28px]" aria-hidden="true" />;
        const creatorStorageBaseObjectBasePath = getCreatorStorageObjectBasePath(creatorId);
        return (
            <img
                src={getStorageUrl(`${creatorStorageBaseObjectBasePath}${creatorLogoStorageFilename}`)}
                alt="Company Logo"
                className="h-[28px] w-[28px] [clip-path:circle(50%_at_50%_50%)]"
            />
        );
    };

    const getItem = (dictionaryId, truncate = false) => {
        const option = optionsDictionary[dictionaryId];
        return (
            <span className="flex items-center text-neutral-500">
                <span className="mr-3">{option.icon}</span>
                <span className={classNames('max-w-[147px]', { 'block truncate': truncate })}>{option.label}</span>
            </span>
        );
    };

    const handleAccountChange = (value) => {
        const [role, creatorId] = getRoleAndCreatorId(value);
        setCurrentRole(role, creatorId);
    };

    useEffect(() => {
        const dictionary = {};
        const creators = [];
        const students = [];
        const memberCreators = [];
        roles.forEach((role) => {
            if (role === ROLES.CREATOR) {
                const id = getDictionaryId(role, creator.id);
                const label = creator.companyName;
                creators.push({
                    id,
                    value: id,
                    label,
                });
                dictionary[id] = {
                    icon: getCreatorIcon(creator.id, creator.schema.companyLogo?.storage_filename),
                    label,
                };
            } else if (role === ROLES.MEMBER) {
                Object.keys(member.creators).forEach((creatorId) => {
                    const id = getDictionaryId(role, creatorId);
                    const label = member.creators[creatorId].creatorCompanyName;
                    memberCreators.push({
                        id,
                        value: id,
                        label,
                    });
                    dictionary[id] = {
                        icon: getCreatorIcon(creatorId, member.creators[creatorId].creatorCompanyLogoStorageFilename),
                        label,
                    };
                });
            } else if (role === ROLES.STUDENT && student.totalCreators > 0) {
                const id = getDictionaryId(role, selectedCreator.id);
                const label = 'Student Dashboard';
                students.push({
                    id,
                    value: id,
                    label,
                });
                dictionary[id] = { icon: <UserCircleIcon className="h-[28px] w-[28px]" aria-hidden="true" />, label };
            }
        });
        setOptions(creators.concat(memberCreators, students));
        setOptionsDictionary(dictionary);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyNames, companyLogoStorageFilenames]);

    if (options.length <= 1) return null;
    const dictionaryId = getDictionaryId(currentRole, selectedCreator.id);
    return (
        <div className="relative w-full sm:max-w-[220px]">
            <Listbox value={dictionaryId} onChange={handleAccountChange}>
                <Listbox.Button className="paragraph__sm relative w-full cursor-default rounded-md border-0 bg-white py-2 pl-3 pr-5 text-left text-neutral-500 focus:outline-none focus-visible:border-2 focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300">
                    {({ open }) => {
                        return (
                            <>
                                {getItem(dictionaryId, true)}
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center text-neutral-500">
                                    {open ? (
                                        <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
                                    ) : (
                                        <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                                    )}
                                </span>
                            </>
                        );
                    }}
                </Listbox.Button>
                <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <Listbox.Options className="select-list-show-scrollbars z-10 mt-2.25 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none absolute">
                        {options.map((option) => (
                            <Listbox.Option
                                key={option.id}
                                value={option.value}
                                disabled={option.disabled}
                                className={({ active }) =>
                                    classNames(
                                        'paragraph__sm relative cursor-default select-none p-3.25 text-neutral-500',
                                        { 'bg-neutral-50': active },
                                    )
                                }>
                                {({ selected }) => {
                                    return (
                                        <div
                                            className={classNames({
                                                'font-semibold text-primary-500': selected,
                                            })}>
                                            {getItem(option.value)}
                                        </div>
                                    );
                                }}
                            </Listbox.Option>
                        ))}
                    </Listbox.Options>
                </Transition>
            </Listbox>
        </div>
    );
}
