import {
    getAccessTokenRequest,
    getXRequestedWithRequest,
    postAccessTokenRequest,
    postSimpleRequest,
    postXRequestedWithRequest,
    postApiKeyRequest,
    patchAccessTokenRequest,
    patchXRequestedWithRequest,
} from '@/api/helpers';
import { getGoogleAnalyticsId } from '@/auth/helpers';
import { useEmbed } from '@/embed/EmbedProvider';
import { Tokens, useQueryParamTokens } from '@/auth/tokens';

export default function useAuthApi() {
    const { embedded, tokens, updateTokens, updateTokensV2, updateOpenerTokens } = useEmbed();
    const queryParamTokens = useQueryParamTokens();

    const signup = async (companyName, firstName, lastName, email, password, referralId = null) => {
        const clientId = getGoogleAnalyticsId();
        const url = `${process.env.REACT_APP_API_BASE_URL}/creator-signup`;
        const payload = {
            company_name: companyName,
            first_name: firstName,
            last_name: lastName,
            email,
            password,
        };
        if (referralId) payload.referral_id = referralId;
        if (clientId) payload.client_id = clientId;
        return postApiKeyRequest(url, payload);
    };

    const confirmCreatorSignup = async (email, otp) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/confirm-creator-signup`;
        const payload = { email, otp };
        return postApiKeyRequest(url, payload);
    };

    const confirmSignup = async (email, newPassword, tmpPassword) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/confirm-signup`;
        const payload = { email, new_password: newPassword, tmp_password: tmpPassword };
        return postApiKeyRequest(url, payload);
    };

    const login = async (email, password) => {
        const clientId = getGoogleAnalyticsId();
        const loginUrl = `${process.env.REACT_APP_API_BASE_URL}/login`;
        const payload = { email, password };
        if (clientId) payload.client_id = clientId;
        let userDto;
        try {
            const usersUrl = `${process.env.REACT_APP_API_BASE_URL}/users`;
            if (embedded) {
                const updatedTokens = new Tokens(await postSimpleRequest(loginUrl, payload));
                updateTokens(updatedTokens);
                userDto = await getAccessTokenRequest(usersUrl, updatedTokens.accessToken);
            } else {
                userDto = await postXRequestedWithRequest(loginUrl, payload); // Security cookies are set automatically during this step
            }
            return userDto;
        } catch (error) {
            throw new Error(error.message);
        }
    };

    const logout = async () => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/users/logout`;
        return embedded ? postAccessTokenRequest(url, null, tokens.accessToken) : postXRequestedWithRequest(url);
    };

    const sendForgotPassword = async (email, role, embed) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/forgot-password`;
        const payload = { email, role: parseInt(role, 10), embed };
        return postApiKeyRequest(url, payload);
    };

    const confirmForgotPassword = async (email, newPassword, otp) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/confirm-forgot-password`;
        const payload = { email, new_password: newPassword, otp };
        return postApiKeyRequest(url, payload);
    };

    const changePassword = async (oldPassword, newPassword) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/users/change-password`;
        const payload = { old_password: oldPassword, new_password: newPassword };
        return postXRequestedWithRequest(url, payload);
    };

    const currentAuthenticatedUser = async () => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/users`;
        return embedded ? getAccessTokenRequest(url, tokens.accessToken) : getXRequestedWithRequest(url);
    };

    const completeEmbedLogin = async () => {
        if (!queryParamTokens || !queryParamTokens.accessToken) {
            throw new Error('Invalid tokens');
        }
        try {
            const usersUrl = `${process.env.REACT_APP_API_BASE_URL}/users`;
            updateOpenerTokens(queryParamTokens);
            return await getAccessTokenRequest(usersUrl, queryParamTokens.accessToken);
        } catch (error) {
            throw new Error(error.message);
        }
    };

    // This is used for embed links where we directly kick-off the SSO process
    // We're going to generate a new access token for each embed request and
    // store the access token in memory.
    const completeEmbedLoginV2 = async () => {
        if (!queryParamTokens || !queryParamTokens.accessToken) {
            throw new Error('Invalid tokens');
        }
        try {
            const usersUrl = `${process.env.REACT_APP_API_BASE_URL}/users`;
            updateTokensV2(queryParamTokens);
            return await getAccessTokenRequest(usersUrl, queryParamTokens.accessToken);
        } catch (error) {
            throw new Error(error.message);
        }
    };

    const updateUserPersonalData = async (firstName, lastName) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/users`;
        const payload = {
            first_name: firstName,
            last_name: lastName,
        };
        return embedded
            ? patchAccessTokenRequest(url, payload, tokens.accessToken)
            : patchXRequestedWithRequest(url, payload);
    };

    const updateUserEmail = async (email) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/users`;
        const payload = { email };
        return embedded
            ? patchAccessTokenRequest(url, payload, tokens.accessToken)
            : patchXRequestedWithRequest(url, payload);
    };

    const resendConfirmation = async (email, role, creatorId) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/resend-confirmation`;
        const payload = { email, role: parseInt(role, 10) };
        if (creatorId) {
            payload.creator_id = creatorId;
        }
        return postApiKeyRequest(url, payload);
    };

    const verifyUserEmail = async (code) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/users/verify-email`;
        const payload = { code };
        return embedded
            ? postAccessTokenRequest(url, payload, tokens.accessToken)
            : postXRequestedWithRequest(url, payload);
    };

    const resendEmailVerificationCode = async () => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/users/resend-email-verification-code`;
        return embedded ? postAccessTokenRequest(url, null, tokens.accessToken) : postXRequestedWithRequest(url, null);
    };

    const sendEmail = async (subject, emailTo, htmlBody, plainTextBody) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/users/send-email`;
        const payload = {
            subject,
            email_to: emailTo,
            html_body: htmlBody,
            plain_text_body: plainTextBody,
        };
        return embedded
            ? postAccessTokenRequest(url, payload, tokens.accessToken)
            : postXRequestedWithRequest(url, payload);
    };

    const studentSignupAndEnrollInPubliclySharedWorkbook = async (publicSharedId, firstName, lastName, email) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/share/${publicSharedId}/signup-and-enroll`;
        const payload = {
            first_name: firstName,
            last_name: lastName,
            email,
        };
        return embedded
            ? postAccessTokenRequest(url, payload, tokens.accessToken)
            : postXRequestedWithRequest(url, payload);
    };

    const getUserFlags = async (email) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/users/flags`;
        const payload = { email };
        return embedded
            ? postAccessTokenRequest(url, payload, tokens.accessToken)
            : postXRequestedWithRequest(url, payload);
    };

    return {
        signup,
        confirmCreatorSignup,
        confirmSignup,
        login,
        logout,
        sendForgotPassword,
        confirmForgotPassword,
        changePassword,
        currentAuthenticatedUser,
        completeEmbedLogin,
        completeEmbedLoginV2,
        resendConfirmation,
        updateUserPersonalData,
        updateUserEmail,
        verifyUserEmail,
        resendEmailVerificationCode,
        sendEmail,
        studentSignupAndEnrollInPubliclySharedWorkbook,
        getUserFlags,
    };
}
