import React, { createContext, useContext, useState, useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import useAuthApi from '@/api/useAuthApi';
import useUserReducer, { ACTIONS, fromDto } from '@/auth/useUserReducer';
import { useEmbed } from '@/embed/EmbedProvider';
import useHubspot from '@/util/useHubspot';

export const ANALYTICS_EVENTS = {
    LOGIN: 'Email login',
    THINKIFIC_INSTALLED: 'Thinkific installed',
    NEW_WORKBOOK_BLANK: 'New Workbook - Blank',
    NEW_WORKBOOK_TEMPLATE: 'New Workbook - Template',
    ADD_WORKBOOK_MODULE: 'Add Module',
    ADD_WORKBOOK_SECTION: 'Add Section',
    ADD_WORKBOOK_SECTION_CATEGORIES: {
        MODULE_COVER_PAGE: 'Add Cover Page',
        QUESTION_AND_ANSWER: 'Add Q+A',
        LINK: 'Add Link',
        NOTES_AREA: 'Add Notes Area',
        IMAGE: 'Add Image',
        TEXT_FIELD: 'Add Text Field',
        TABLE: 'Add Table',
        WORKBOOK_DOWNLOAD_LINK: 'Add DL Link',
        RADIO_BUTTONS: 'Add Radio Buttons',
        CHECKBOXES: 'Add Checkboxes',
        DROP_DOWN_LIST: 'Add Drop-down List',
        FILL_IN_THE_BLANKS: 'Add Fill in the Blanks',
        MODULE_DOWNLOAD_LINK: 'Add Module DL Link',
        SLIDER: 'Add Slider',
        STUDENT_IMAGE_UPLOAD: 'Student Image Upload',
        PAGE_BREAK: 'Page Break',
        VIDEO: 'Add Video',
        FILE_UPLOAD: 'File Upload',
    },
    BUTTON_CLICK: 'Button Click',
    PUBLISH_WORKBOOK: 'Publish Workbook',
    PREVIEW_WORKBOOK: 'Preview Workbook',
    SHARE_EVERGREEN_WORKBOOK: 'Share Evergreen Workbook',
    SHARE_WORKBOOK_WITH_DATES: 'Share Workbook With Dates',
    COPY_EMBED_URL: 'Copy Embed URL',
    ADD_STUDENT_BY_EMAIL: 'Add Student',
    VIEW_STUDENT_WORKBOOK: 'View Student Workbook',
    ADD_MEMBER: 'Add Member',
    DELETE_MEMBER: 'Delete Member',
};

const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export default function AuthProvider({ children }) {
    const [user, setUser] = useUserReducer();
    const [authenticated, setAuthenticated] = useState(false);
    const [originalSignin, setOriginalSignin] = useState(null);

    const {
        login,
        logout,
        currentAuthenticatedUser,
        signup,
        confirmCreatorSignup: confirmCreatorSignupApi,
        confirmSignup: confirmSignupApi,
        sendForgotPassword,
        confirmForgotPassword,
        changePassword,
        completeEmbedLogin: completeEmbedLoginApi,
        completeEmbedLoginV2: completeEmbedLoginV2Api,
        updateUserPersonalData: updateUserPersonalDataApi,
        updateUserEmail: updateUserEmailApi,
        verifyUserEmail: verifyUserEmailApi,
        resendEmailVerificationCode: resendEmailVerificationCodeApi,
        resendConfirmation: resendConfirmationApi,
        sendEmail: sendEmailApi,
        studentSignupAndEnrollInPubliclySharedWorkbook,
    } = useAuthApi();
    const { updateContactLifecycleStage, lifecycleStages } = useHubspot();

    const { embedded, updateTokensV2 } = useEmbed();

    const trackEvent = async (eventName, properties = {}, userId = user.id) => {
        try {
            mixpanel.track(eventName, { ...properties, distinct_id: userId });
        } catch (err) {
            console.error(`Cannot track event in Mixpanel: ${err.message}`);
        }
    };

    const updateUserProperty = async (property, value, userId = user.id) => {
        try {
            mixpanel.identify(userId);
            mixpanel.people.set({ [property]: value });
        } catch (err) {
            console.error(`Cannot update user property in Mixpanel: ${err.message}`);
        }
    };

    const updateAuthenticatedUser = (updatedUser) => {
        setUser({ type: ACTIONS.SET, payload: updatedUser });
        setAuthenticated(true);
    };

    const cleanUser = () => {
        setUser({ type: ACTIONS.RESET });
        setAuthenticated(false);
    };

    const signin = async (email, password) => {
        const userDto = await login(email, password);
        const signedUser = fromDto(userDto);
        updateAuthenticatedUser(signedUser);
        trackEvent(ANALYTICS_EVENTS.LOGIN, { UserId: signedUser.id });
        return signedUser;
    };

    const signout = async () => {
        cleanUser();
        logout().catch(() => {});
    };

    const checkAuthentication = async () => {
        const userDto = await currentAuthenticatedUser().catch((error) => {
            if (error.statusCode === 401) signout();
            return null;
        });
        if (!userDto) {
            signout();
            return false;
        }
        updateAuthenticatedUser(fromDto(userDto));
        return true;
    };

    const creatorSignup = async (companyName, firstName, lastName, email, password, referralId) => {
        return signup(companyName, firstName, lastName, email, password, referralId).then((userDto) => {
            updateContactLifecycleStage(userDto?.email, lifecycleStages.FREE_TRIAL);
            const newUser = fromDto(userDto);
            return newUser;
        });
    };

    const confirmCreatorSignup = (email, otp) => {
        return confirmCreatorSignupApi(email, otp);
    };

    const confirmSignup = (email, newPassword, tmpPassword) => {
        return confirmSignupApi(email, newPassword, tmpPassword);
    };

    const sendPasswordResetEmail = (email, role, embed = false) => {
        return sendForgotPassword(email, role, embed);
    };

    const confirmPasswordReset = (email, newPassword, otp) => {
        return confirmForgotPassword(email, newPassword, otp);
    };

    const updatePassword = (oldPassword, newPassword) => {
        return changePassword(oldPassword, newPassword);
    };

    const updateUserPersonalData = async (firstName, lastName) => {
        return updateUserPersonalDataApi(firstName, lastName).then(() => {
            setUser({
                type: ACTIONS.SET,
                payload: { firstName, lastName },
            });
        });
    };

    const updateUserEmail = async (email) => {
        return updateUserEmailApi(email).then(() => {
            setUser({
                type: ACTIONS.SET,
                payload: { email, emailVerified: false },
            });
        });
    };

    const verifyUserEmail = async (code) => {
        return verifyUserEmailApi(code).then(() => {
            setUser({
                type: ACTIONS.SET,
                payload: { emailVerified: true },
            });
        });
    };

    const resendEmailVerificationCode = async () => {
        return resendEmailVerificationCodeApi();
    };

    const resendConfirmation = async (email, role, creatorId) => {
        return resendConfirmationApi(email, role, creatorId);
    };

    const completeEmbedLogin = async () => {
        const userDto = await completeEmbedLoginApi();
        const signedUser = fromDto(userDto);
        updateAuthenticatedUser(signedUser);
        return signedUser;
    };

    const completeEmbedLoginV2 = async () => {
        const userDto = await completeEmbedLoginV2Api();
        const signedUser = fromDto(userDto);
        updateAuthenticatedUser(signedUser);
        return signedUser;
    };

    const studentSignupAndEnrollInPubliclySharedWorkbookAndAutoLogin = async (
        publicSharedId,
        firstName,
        lastName,
        email,
    ) => {
        const response = await studentSignupAndEnrollInPubliclySharedWorkbook(
            publicSharedId,
            firstName,
            lastName,
            email,
        );
        if (embedded) {
            const token = response.access_token;
            updateTokensV2({ accessToken: token });
        }
        return response;
    };

    const sendEmail = async (subject, emailTo, htmlBody, plainTextBody) => {
        return sendEmailApi(subject, emailTo, htmlBody, plainTextBody);
    };

    useEffect(() => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN);

        // Set to true to test mixpanel events on localhost and remove any ad blockers
        const testMixpanelLocal = true;
        if (testMixpanelLocal) {
            mixpanel.set_config({ secure_cookie: false });
        }
    }, []);

    const auth = {
        user,
        authenticated,
        signin,
        signout,
        originalSignin,
        setOriginalSignin,
        checkAuthentication,
        creatorSignup,
        confirmCreatorSignup,
        confirmSignup,
        sendPasswordResetEmail,
        confirmPasswordReset,
        updatePassword,
        completeEmbedLogin,
        completeEmbedLoginV2,
        updateUserPersonalData,
        updateUserEmail,
        trackEvent,
        updateUserProperty,
        verifyUserEmail,
        resendEmailVerificationCode,
        resendConfirmation,
        sendEmail,
        studentSignupAndEnrollInPubliclySharedWorkbookAndAutoLogin,
    };

    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}
