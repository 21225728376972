import ErrorIcon from '@/system/icons/ErrorIcon';
import { DefaultButton, BUTTON_SIZE } from '@/system/Buttons';

export default function TechnicalError({ title, desc, buttonLabel, onButtonClick }) {
    return (
        <div className="absolute top-1/2 left-1/2 flex -translate-y-1/2 -translate-x-1/2 flex-col items-center justify-center">
            <ErrorIcon className="mb-1 !w-12" />
            <div className="w-80 space-y-3 lg:w-[640px]">
                {title && <div className="heading__md--semibold text-center">{title}</div>}
                {desc && <p className="paragraph__md text-center">{desc}</p>}
            </div>
            {buttonLabel && typeof onButtonClick === 'function' && (
                <DefaultButton label={buttonLabel} onClick={onButtonClick} className="mt-3.5" size={BUTTON_SIZE.LG} />
            )}
        </div>
    );
}
