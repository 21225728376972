export default function RadioButtonIcon({ className }) {
    return (
        <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.3651 8.18233C13.3651 10.8267 11.2215 12.9704 8.57711 12.9704C5.93276 12.9704 3.78909 10.8267 3.78909 8.18233C3.78909 5.53798 5.93276 3.39431 8.57711 3.39431C11.2215 3.39431 13.3651 5.53798 13.3651 8.18233ZM15.3512 8.18233C15.3512 11.9236 12.3184 14.9565 8.57711 14.9565C4.83586 14.9565 1.80298 11.9236 1.80298 8.18233C1.80298 4.44109 4.83586 1.4082 8.57711 1.4082C12.3184 1.4082 15.3512 4.44109 15.3512 8.18233ZM8.57657 10.5051C9.85939 10.5051 10.8993 9.46522 10.8993 8.1824C10.8993 6.89959 9.85939 5.85966 8.57657 5.85966C7.29375 5.85966 6.25382 6.89959 6.25382 8.1824C6.25382 9.46522 7.29375 10.5051 8.57657 10.5051Z"
                fill="currentColor"
            />
        </svg>
    );
}
