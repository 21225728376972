import classNames from 'classnames';

export default function SectionTitle({ text, className, style }) {
    return (
        <div
            className={classNames('text-[1.3rem] leading-8 font-semibold break-words mb-4.5', className)}
            style={style}>
            {text}
        </div>
    );
}
