import { forwardRef } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { uppercaseFirstLetter } from '@/util/helpers';

export const TEXT_INPUT_TYPE = {
    TEXT: 'text',
    PASSWORD: 'password',
    NUMBER: 'number',
    // Email type is not supported due to this bug: https://github.com/facebook/react/issues/14897
};

export const TEXT_INPUT_AUTO_CORRECT = {
    ON: 'on',
    OFF: 'off',
};

export const TEXT_INPUT_AUTO_CAPITALIZE = {
    NONE: 'none',
    SENTENCES: 'sentences',
};

export const TEXT_INPUT_SPELL_CHECK = {
    TRUE: 'true',
    FALSE: 'false',
};

const TextInput = forwardRef(
    (
        {
            type = TEXT_INPUT_TYPE.TEXT,
            value,
            onChange,
            onBlur = null,
            onFocus = null,
            label = null,
            icon = null,
            id = null,
            placeholder = '',
            required = false,
            error = false,
            errorMsg = null,
            style = {},
            className = '',
            inputClassName = '',
            readOnly = false,
            prefixLabel = '',
            suffixLabel = '',
            autoCorrect = TEXT_INPUT_AUTO_CORRECT.ON,
            autoCapitalize = TEXT_INPUT_AUTO_CAPITALIZE.SENTENCES,
            spellCheck = TEXT_INPUT_SPELL_CHECK.TRUE,
            maxLength = null,
            'data-test': dataTest,
            placeholderUppercaseFirstLetter = true,
        },
        ref,
    ) => {
        const Icon = icon;
        const inputClasses = classNames(
            'paragraph__md text-left text-neutral-500 placeholder-neutral-300 border-neutral-100 px-3 py-2 w-full',
            {
                'text-error-500 border-error-200 pr-8': error,
                'border-t border-b': true,
                'rounded-r-md': !suffixLabel,
                'rounded-l-md': !prefixLabel,
            },
            inputClassName,
        );

        const requiredText = !placeholder ? '* Required' : '* ';

        const findPlaceholder = () => {
            if (placeholderUppercaseFirstLetter) {
                if (placeholder !== 'https://') {
                    return uppercaseFirstLetter(placeholder);
                }
                return placeholder;
            }
            return placeholder;
        };

        return (
            <>
                {label && (
                    <label className="heading__xs--medium font-normal block mb-2">{uppercaseFirstLetter(label)}</label>
                )}
                <div className={classNames('mb-4', className)}>
                    <div className="relative flex flex-row items-center">
                        {error && (
                            <div className="absolute right-3 top-1/2 -translate-y-1/2 transform text-error-500">
                                {Icon ? (
                                    <Icon className="h-em w-em" />
                                ) : (
                                    <ExclamationCircleIcon className="h-em w-em" />
                                )}
                            </div>
                        )}
                        {prefixLabel && (
                            <div className="paragraph__md rounded-l-md border-t border-l border-b border-neutral-100 bg-neutral-25 px-3 py-2.5 text-neutral-300 lg:py-2">
                                {prefixLabel}
                            </div>
                        )}
                        <input
                            id={id}
                            type={type}
                            ref={ref}
                            className={inputClasses}
                            value={value}
                            placeholder={`${required ? requiredText : ''}${findPlaceholder()}`}
                            required={required}
                            style={style}
                            onChange={typeof onChange === 'function' ? onChange : () => {}}
                            onBlur={typeof onBlur === 'function' ? onBlur : () => {}}
                            onFocus={typeof onFocus === 'function' ? onFocus : () => {}}
                            readOnly={readOnly}
                            autoCorrect={autoCorrect}
                            autoCapitalize={autoCapitalize}
                            spellCheck={spellCheck}
                            maxLength={maxLength}
                            data-test={dataTest}
                        />
                        {suffixLabel && (
                            <div className="paragraph__md rounded-r-md border-t border-r border-b border-neutral-100 bg-neutral-25 px-3 py-2.5 text-neutral-300 lg:py-2">
                                {suffixLabel}
                            </div>
                        )}
                    </div>
                    {error && errorMsg && <div className="caption mt-1 text-error-200">{errorMsg}</div>}
                </div>
            </>
        );
    },
);

export default TextInput;
