import {
    getAccessTokenRequest,
    getXRequestedWithRequest,
    postAccessTokenRequest,
    postXRequestedWithRequest,
    patchAccessTokenRequest,
    patchXRequestedWithRequest,
    putAccessTokenRequest,
    putXRequestedWithRequest,
    deleteAccessTokenRequest,
    deleteXRequestedWithRequest,
} from '@/api/helpers';
import { useEmbed } from '@/embed/EmbedProvider';
import { useAuth } from '@/auth/AuthProvider';
import { BACKEND_ERRORS } from '@/util/constants';

export default function useRequest() {
    const { embedded, tokens } = useEmbed();
    const { signout } = useAuth();

    const runRequest = (promise) =>
        promise.catch((error) => {
            if (
                // The user logged out manually from another tab
                error.statusCode === 401 ||
                // Auth cookies expired
                (error.statusCode === 400 && error.message === BACKEND_ERRORS.JWT_EXPIRED)
            ) {
                signout();
            }
            if (error.statusCode === 403) throw new Error('You cannot access this resource');
            throw error;
        });

    const getRequest = async (url, queryParams, omitEncoding) => {
        return runRequest(
            embedded
                ? getAccessTokenRequest(url, tokens.accessToken, queryParams, omitEncoding)
                : getXRequestedWithRequest(url, queryParams, omitEncoding),
        );
    };

    const postRequest = async (url, payload, queryParams) => {
        return runRequest(
            embedded
                ? postAccessTokenRequest(url, payload, tokens.accessToken, queryParams)
                : postXRequestedWithRequest(url, payload, queryParams),
        );
    };

    const patchRequest = async (url, payload, queryParams) => {
        return runRequest(
            embedded
                ? patchAccessTokenRequest(url, payload, tokens.accessToken, queryParams)
                : patchXRequestedWithRequest(url, payload, queryParams),
        );
    };

    const putRequest = async (url, payload, queryParams) => {
        return runRequest(
            embedded
                ? putAccessTokenRequest(url, payload, tokens.accessToken, queryParams)
                : putXRequestedWithRequest(url, payload, queryParams),
        );
    };

    const deleteRequest = async (url, queryParams, payload) => {
        const dto = await runRequest(
            embedded
                ? deleteAccessTokenRequest(url, tokens.accessToken, queryParams, payload)
                : deleteXRequestedWithRequest(url, queryParams, payload),
        );
        return dto;
    };

    return {
        getRequest,
        postRequest,
        patchRequest,
        putRequest,
        deleteRequest,
    };
}
