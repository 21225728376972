import { Range } from 'konsta/react';

export default function RangeSlider({
    value,
    className,
    step,
    min,
    max,
    onBlur,
    onChange,
    onMouseOut,
    style,
    colors = { valueBg: 'bg-primary-500' },
}) {
    return (
        // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
        <div className={className} style={style} onMouseOut={onMouseOut}>
            <Range colors={colors} value={value} step={step} min={min} max={max} onBlur={onBlur} onChange={onChange} />
        </div>
    );
}
