/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import { SwitchVerticalIcon, XIcon } from '@heroicons/react/outline';
import { Droppable, Draggable, DragDropContext } from 'react-beautiful-dnd';
import { getUniqueId, reorderOptions, addOption, removeOption, isSectionReadOnly } from '@/util/helpers';
import TextInputDebounce from '@/system/TextInputDebounce';
import RichTextEditorDebounce from '@/system/RichTextEditorDebounce';
import ToggleRow from '@/workbook/builder/sections/ToggleRow';
import SectionTitle from '@/system/SectionTitle';
import SectionDescription from '@/system/SectionDescription';
import { DefaultButton } from '@/system/Buttons';
import { OTHER_OPTION_KEY, MAX_OPTIONS, WORKBOOK_SECTION_VIEWER_MODES } from '@/util/constants';
import Label from '@/system/Label';
import PreviewSuggestionTooltip from '@/workbook/builder/sections/PreviewSuggestionTooltip';

const responsePath = 'answer';
const responsePathOther = 'answer.other';

export const RadioButtonsEditor = ({ schema, setSectionSchemaProp, saveSectionSchemaProp }) => {
    const { title, description, options, showOther } = schema;
    const hideRemove = options.length === 1;
    const limitReached = options.length === MAX_OPTIONS;

    const handleTitleChange = (e) => {
        const { value: text } = e.target;
        setSectionSchemaProp('title.text', text);
    };

    const handleTitleTypingEnd = (text) => {
        saveSectionSchemaProp('title.text', text);
    };

    const handleDescriptionToggleChange = () => {
        saveSectionSchemaProp('description.show', !description.show);
    };

    const handleDescriptionChange = (text) => {
        setSectionSchemaProp('description.text', text);
    };

    const handleDescriptionTypingEnd = (text) => {
        saveSectionSchemaProp('description.text', text);
    };

    const handleOptionChange = (label, index) => {
        const updatedOptions = [...options];
        updatedOptions[index] = { id: updatedOptions[index].id, label };
        setSectionSchemaProp('options', updatedOptions);
    };

    const handleOptionTypingEnd = (label, index) => {
        const updatedOptions = [...options];
        updatedOptions[index] = { id: updatedOptions[index].id, label };
        saveSectionSchemaProp('options', updatedOptions);
    };

    const handleDragEnd = (result) => {
        const fromIndex = result.source.index;
        const toIndex = result.destination.index;
        if (fromIndex === toIndex) return;
        // Ignore when the user drags outside of the droppable container
        if (!result.destination || options.length <= 1) return;
        saveSectionSchemaProp('options', reorderOptions(fromIndex, toIndex, options));
    };

    const handleAddOption = () => {
        if (!limitReached) {
            const id = getUniqueId(5);
            const label = `Option ${options.length + 1}`;
            saveSectionSchemaProp('options', addOption(options, { id, label }));
        }
    };

    const handleShowOtherToggleChange = () => {
        saveSectionSchemaProp('showOther', !showOther);
    };

    const handleRemoveOption = (removeIndex) => {
        saveSectionSchemaProp('options', removeOption(options, removeIndex));
    };

    return (
        <div className="space-y-3.5">
            <Label desc="Question" />
            <TextInputDebounce value={title.text} onChange={handleTitleChange} onTypingEnd={handleTitleTypingEnd} />
            <div>
                <ToggleRow label="Description" enabled={description.show} onChange={handleDescriptionToggleChange} />
                <RichTextEditorDebounce
                    value={description.text}
                    onChange={handleDescriptionChange}
                    onTypingEnd={handleDescriptionTypingEnd}
                />
            </div>
            <Label desc="Options" />
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="droppable-radio-buttons-option">
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef} className="space-y-3.5">
                            {options.map((option, index) => (
                                <Draggable
                                    key={`draggable-${option.id}`}
                                    draggableId={`draggable-${option.id}`}
                                    index={index}>
                                    {(providedDraggable) => (
                                        <div ref={providedDraggable.innerRef} {...providedDraggable.draggableProps}>
                                            <div className="relative" key={`draggable-container-${option.id}`}>
                                                <div
                                                    className="absolute left-1.5 z-[1] top-1/2 -translate-y-1/2"
                                                    {...providedDraggable.dragHandleProps}>
                                                    <SwitchVerticalIcon className="h-em w-em cursor-grab text-neutral-200" />
                                                </div>
                                                {!hideRemove && (
                                                    <div className="absolute right-1.5 z-[1] top-1/2 -translate-y-1/2">
                                                        <button type="button" onClick={() => handleRemoveOption(index)}>
                                                            <XIcon className="h-em w-em text-neutral-200" />
                                                        </button>
                                                    </div>
                                                )}
                                                <TextInputDebounce
                                                    value={option.label}
                                                    onChange={(e) => handleOptionChange(e.target.value, index)}
                                                    onTypingEnd={(text) => handleOptionTypingEnd(text, index)}
                                                    inputClassName="px-6"
                                                />
                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

            <ToggleRow label="Allow Other" enabled={showOther} onChange={handleShowOtherToggleChange} />
            <DefaultButton label="Add option" onClick={handleAddOption} disabled={limitReached} />
        </div>
    );
};

export const RadioButtons = ({
    workbookSchema,
    schema,
    mode = WORKBOOK_SECTION_VIEWER_MODES.DEFAULT,
    sectionResponses,
    setSectionResponseProp,
    saveSectionResponseProp,
}) => {
    const { title, description, options, showOther } = schema;

    const handleOptionChange = (value) => {
        if (value !== sectionResponses?.[responsePath]?.id && typeof saveSectionResponseProp === 'function') {
            saveSectionResponseProp(responsePath, { id: value, other: '' });
        }
    };

    const handleOtherChange = (e) => {
        const { value: text } = e.target;
        if (typeof setSectionResponseProp === 'function') {
            setSectionResponseProp(responsePath, { id: OTHER_OPTION_KEY, other: text });
        }
    };

    const handleOtherTypingEnd = (text) => {
        if (typeof saveSectionResponseProp === 'function') saveSectionResponseProp(responsePathOther, text);
    };

    return (
        <>
            <SectionTitle
                text={title.text}
                style={{
                    fontFamily: workbookSchema.textStyling.subtitle.fontFamily,
                    color: workbookSchema.textStyling.subtitle.color,
                }}
            />
            {description.show && (
                <SectionDescription
                    text={description.text}
                    style={{
                        fontFamily: workbookSchema.textStyling.body.fontFamily,
                        color: workbookSchema.textStyling.body.color,
                    }}
                />
            )}
            <RadioGroup
                value={sectionResponses?.[responsePath]?.id}
                options={options}
                onChange={handleOptionChange}
                ariaLabel={title.text}
                showOther={showOther}
                mode={mode}
                otherValue={sectionResponses?.[responsePath]?.other}
                onOtherChange={handleOtherChange}
                onOtherTypingEnd={handleOtherTypingEnd}
                optionLabelStyle={{
                    fontFamily: workbookSchema.textStyling.body.fontFamily,
                    color: workbookSchema.textStyling.body.color,
                }}
                workbookSchema={workbookSchema}
            />
        </>
    );
};

export const RadioButtonsArtifact = ({ workbookSchema, schema, sectionResponses }) => {
    const { title, description, options, showOther } = schema;

    return (
        <>
            <SectionTitle
                text={title.text}
                style={{
                    fontFamily: workbookSchema.textStyling.subtitle.fontFamily,
                    color: workbookSchema.textStyling.subtitle.color,
                }}
            />
            {description.show && (
                <SectionDescription
                    text={description.text}
                    style={{
                        fontFamily: workbookSchema.textStyling.body.fontFamily,
                        color: workbookSchema.textStyling.body.color,
                    }}
                />
            )}
            <RadioGroupArtifact
                value={sectionResponses?.[responsePath]?.id}
                options={options}
                ariaLabel={title.text}
                showOther={showOther}
                otherValue={sectionResponses?.[responsePath]?.other}
                optionLabelStyle={{
                    fontFamily: workbookSchema.textStyling.body.fontFamily,
                    color: workbookSchema.textStyling.body.color,
                }}
            />
        </>
    );
};

function RadioGroup({
    value,
    options,
    onChange,
    ariaLabel,
    optionLabelStyle = {},
    mode,
    showOther,
    otherValue,
    onOtherChange,
    onOtherTypingEnd,
    workbookSchema,
}) {
    const otherChecked = value === OTHER_OPTION_KEY;

    const textArea = () => (
        <TextInputDebounce
            value={otherValue || ''}
            onChange={onOtherChange}
            onTypingEnd={onOtherTypingEnd}
            placeholder="Other..."
            onFocus={mode === WORKBOOK_SECTION_VIEWER_MODES.READ_ONLY ? () => {} : () => onChange(OTHER_OPTION_KEY)}
            readOnly={isSectionReadOnly(mode)}
            className="mb-0 w-full"
            inputClassName="custom-focus-outline"
            showSavingIcon={!isSectionReadOnly(mode)}
            style={{ height: '32px' }}
        />
    );

    return (
        <>
            {mode === WORKBOOK_SECTION_VIEWER_MODES.BUILDER && (
                <style type="text/css">
                    {`
                .custom-focus-outline:focus, .custom-focus-outline:hover {
                    --tw-ring-color: ${workbookSchema.buttonStyling.fillColor};
                    border-color: ${workbookSchema.buttonStyling.fillColor};
                }

                .custom-focus-outline:checked {
                    background-color: ${workbookSchema.buttonStyling.fillColor} !important;
                }
            `}
                </style>
            )}
            <div role="radiogroup" className="w-full flex flex-col" aria-label={ariaLabel || 'radio buttons group'}>
                {options.map((option) => {
                    const checked = value === option.id;
                    return (
                        <div key={option.id}>
                            <label>
                                <div className="paragraph__md inline-flex items-center mt-2">
                                    <input
                                        type="radio"
                                        value={option.id}
                                        checked={checked}
                                        onChange={
                                            mode === WORKBOOK_SECTION_VIEWER_MODES.READ_ONLY
                                                ? () => {}
                                                : (e) => onChange(e.target.value)
                                        }
                                        className={classNames('custom-focus-outline', { 'radio-checked': checked })}
                                    />{' '}
                                    <span className="ml-2.5" style={optionLabelStyle}>
                                        {option.label}
                                    </span>
                                </div>
                            </label>
                        </div>
                    );
                })}
                {showOther && (
                    <div className="caption inline-flex items-start w-full sm:max-w-[75%] mt-[2px]">
                        <input
                            type="radio"
                            value={OTHER_OPTION_KEY}
                            checked={otherChecked}
                            onChange={
                                mode === WORKBOOK_SECTION_VIEWER_MODES.READ_ONLY
                                    ? () => {}
                                    : (e) => onChange(e.target.value)
                            }
                            className={classNames('custom-focus-outline mr-2.5 translate-y-2.5', {
                                'radio-checked': otherChecked,
                            })}
                        />{' '}
                        {mode === WORKBOOK_SECTION_VIEWER_MODES.BUILDER ? (
                            <PreviewSuggestionTooltip>{textArea()}</PreviewSuggestionTooltip>
                        ) : (
                            textArea()
                        )}
                    </div>
                )}
            </div>
        </>
    );
}

function RadioGroupArtifact({ value, options, ariaLabel, optionLabelStyle, showOther, otherValue }) {
    const allOptions = [...options];
    if (showOther) {
        allOptions.push({
            id: OTHER_OPTION_KEY,
            label: otherValue || 'Other',
        });
    }
    return (
        <div
            role="radiogroup"
            className="w-full flex flex-col space-y-2"
            aria-label={ariaLabel || 'radio buttons group'}>
            {allOptions.map((option) => {
                const checked = value === option.id;
                return (
                    <div key={option.id}>
                        <label>
                            <div className="paragraph__md inline-flex items-center">
                                <input
                                    type="radio"
                                    value={option.id}
                                    checked={checked}
                                    readOnly
                                    onChange={() => {}}
                                    className={classNames('custom-focus-outline', { 'radio-checked': checked })}
                                />{' '}
                                <span className="ml-2.5" style={optionLabelStyle}>
                                    {option.label}
                                </span>
                            </div>
                        </label>
                    </div>
                );
            })}
        </div>
    );
}
