import ScrollIntoView from 'react-scroll-into-view';

export default function SectionButton({ sectionId, label, icon, onClick, style, selected }) {
    const Icon = icon;

    return (
        <ScrollIntoView
            selector={`#section-${sectionId}`}
            scrollOptions={{ behavior: 'smooth', block: 'center' }}
            onClick={typeof onClick === 'function' ? onClick : () => {}}>
            <div
                className="mb-1.5 rounded py-1.5 px-2.5 text-neutral-500"
                style={{
                    backgroundColor: !selected ? style.fillColor.replace(',1)', ',0.50)') : style.fillColor,
                    color: style.textColor,
                }}>
                <button type="button" className="caption custom-outline-color relative flex items-center p-1">
                    {Icon && <Icon className="mr-1.5 inline-block h-4 w-4 -translate-y-px" />}
                    <div className="inline-block truncate text-left w-[70vw] lg:w-[250px]">{label}</div>
                </button>
            </div>
        </ScrollIntoView>
    );
}
