import { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import TextInput from '@/system/TextInput';
import { TYPING_DEBOUNCE_TIMEOUT } from '@/util/constants';
import SavingIcon from '@/system/SavingIcon';

export default function TextInputDebounce({
    type,
    value,
    onChange,
    label,
    onBlur,
    onFocus,
    placeholder,
    icon,
    id,
    required,
    error,
    errorMsg,
    style,
    className,
    inputClassName,
    onTypingEnd,
    readOnly,
    showSavingIcon,
    maxLength,
    'data-test': dataTest,
}) {
    const isMounted = useRef(true);

    const [initialized, setInitialized] = useState(false);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        if (initialized && typeof onTypingEnd === 'function') {
            if (showSavingIcon) {
                let secondaryTimeoutId;
                const timeOutId = setTimeout(() => {
                    if (isMounted.current) {
                        onTypingEnd(value);
                        setSaving(true);
                        secondaryTimeoutId = setTimeout(() => {
                            if (isMounted.current) setSaving(false);
                        }, 50);
                    }
                }, TYPING_DEBOUNCE_TIMEOUT);
                return () => {
                    clearTimeout(timeOutId);
                    clearTimeout(secondaryTimeoutId);
                };
            }

            const timeOutId = setTimeout(() => onTypingEnd(value), TYPING_DEBOUNCE_TIMEOUT);
            return () => clearTimeout(timeOutId);
        }
        setInitialized(true);
        return () => {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    if (showSavingIcon) {
        return (
            <div className="relative w-full">
                <div className="relative w-full">
                    <TextInput
                        type={type}
                        value={value}
                        onChange={onChange}
                        label={label}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        icon={icon}
                        id={id}
                        placeholder={placeholder}
                        required={required}
                        style={style}
                        className={className}
                        inputClassName={classNames(inputClassName, { '!pr-10': !error, '!pl-8 !pr-9': error })}
                        readOnly={readOnly}
                        maxLength={maxLength}
                        data-test={dataTest}
                    />
                    {error && (
                        <div className="absolute top-1/2 -translate-y-1/2 w-4 h-4 left-2.5 text-error-300">
                            <ExclamationCircleIcon className="w-full h-full" />
                        </div>
                    )}
                    <div className="absolute top-1/2 -translate-y-1/2 w-5 h-5 right-2.5">
                        <SavingIcon saving={saving} showCheckIcon={false} />
                    </div>
                </div>
                {error && errorMsg && <div className="caption mt-1 text-error-200">{errorMsg}</div>}
            </div>
        );
    }

    return (
        <TextInput
            type={type}
            value={value}
            onChange={onChange}
            label={label}
            onBlur={onBlur}
            onFocus={onFocus}
            icon={icon}
            id={id}
            placeholder={placeholder}
            required={required}
            error={error}
            errorMsg={errorMsg}
            style={style}
            className={className}
            inputClassName={inputClassName}
            readOnly={readOnly}
            maxLength={maxLength}
        />
    );
}
