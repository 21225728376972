import { Portal } from 'react-portal';
import classNames from 'classnames';
import NonVerifiedEmailNotification from '@/system/notifications/NonVerifiedEmailNotification';
import {
    NOTIFICATION_VARIANTS,
    InfoNotification,
    WarningNotification,
    ErrorNotification,
} from '@/system/notifications/Notification';

const NOTIFICATION_MESSAGES = {
    NON_VERIFIED_EMAIL: NonVerifiedEmailNotification,
};

export default function NotificationsContainer({ notifications, onNotificationRemove }) {
    const handleRemoveClick = (index) => {
        if (typeof onNotificationRemove === 'function') onNotificationRemove(index);
    };

    if (!Array.isArray(notifications) || notifications.length === 0) return null;

    return (
        <Portal>
            <div
                className={classNames(
                    'heading__xs--medium transparent fixed bottom-5 right-5 w-4/5 space-y-3 sm:w-[385px]',
                    'z-70',
                )}>
                {notifications.map((notification, index) => {
                    if (!notification?.key) return null;

                    const notificationComponent = NOTIFICATION_MESSAGES[notification.key];
                    if (!notificationComponent) return null;

                    let Notification;
                    switch (notification?.variant) {
                        case NOTIFICATION_VARIANTS.ERROR:
                            Notification = ErrorNotification;
                            break;
                        case NOTIFICATION_VARIANTS.WARNING:
                            Notification = WarningNotification;
                            break;
                        default:
                            Notification = InfoNotification;
                    }

                    return (
                        <Notification
                            component={notificationComponent}
                            onRemove={() => handleRemoveClick(index)}
                            key={notification.key}
                        />
                    );
                })}
            </div>
        </Portal>
    );
}
