import { useEffect, useState } from 'react';
import { useAuth } from '@/auth/AuthProvider';

// Why these numbers are not incrementing by 1?
// Because we are using bitwise operators to set the flags.
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Bitwise_Operators
// This allows us to set multiple flags at once.
const USER_FLAGS = {
    NONE: 0,
    EMAIL_NOT_VERIFIED: 1,
    PASSWORD_NOT_SET: 2,
    FIRST_NAME_NOT_SET: 4,
    LAST_NAME_NOT_SET: 8,
};

// Why not using bitwise operators here? and instead using modulo?
// Because our prettier config is not allowing bitwise operators.
// https://prettier.io/docs/en/options.html#bitwise-operators
// I didn't want to change the prettier config because it's a global config.
// So I'm using modulo here to get the flags.
export function userFlagsUtil(flags) {
    return {
        flags,
        isEmailNotVerified: flags % (2 * USER_FLAGS.EMAIL_NOT_VERIFIED) >= USER_FLAGS.EMAIL_NOT_VERIFIED,
        isPasswordNotSet: flags % (2 * USER_FLAGS.PASSWORD_NOT_SET) >= USER_FLAGS.PASSWORD_NOT_SET,
        isFirstNameNotSet: flags % (2 * USER_FLAGS.FIRST_NAME_NOT_SET) >= USER_FLAGS.FIRST_NAME_NOT_SET,
        isLastNameNotSet: flags % (2 * USER_FLAGS.LAST_NAME_NOT_SET) >= USER_FLAGS.LAST_NAME_NOT_SET,
    };
}

export default function useUserFlags() {
    const { user } = useAuth();
    const [userFlagUtil, setUserFlagUtil] = useState(userFlagsUtil(user.flags));

    useEffect(() => {
        if (user.flags !== userFlagsUtil.flags) {
            setUserFlagUtil(userFlagsUtil(user.flags));
        }
    }, [user.flags, userFlagUtil.flags]);

    return userFlagUtil;
}
