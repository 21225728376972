import { DocumentIcon } from '@heroicons/react/outline';
import classNames from 'classnames';

export default function WorkbookCoverPageButton({ label, selected, onClick }) {
    return (
        <div className="w-full">
            <button
                type="button"
                className={classNames(
                    'heading__xs--medium custom-outline-color flex w-full items-center justify-between rounded-md bg-neutral-25 px-2.5 py-3.5 text-left',
                    { 'bg-neutral-100': selected },
                )}
                onClick={typeof onClick === 'function' ? onClick : () => {}}>
                <div>{label}</div>

                <DocumentIcon className="inline-block h-em w-em -translate-y-px transform" />
            </button>
        </div>
    );
}
