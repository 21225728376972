import { getApiKeyRequest, postApiKeyRequest } from '@/api/helpers';

export default function usePublicApi() {
    const getWorkbookVersion = async (creatorId, workbookId, workbookVersionId) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/public/creators/${creatorId}/workbooks/${workbookId}/versions/${workbookVersionId}`;
        return getApiKeyRequest(url);
    };

    const getWorkbookVersionModules = async (creatorId, workbookId, workbookVersionId) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/public/creators/${creatorId}/workbooks/${workbookId}/versions/${workbookVersionId}/modules`;
        return getApiKeyRequest(url);
    };

    const getCreator = async (creatorId) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/public/creators/${creatorId}`;
        return getApiKeyRequest(url);
    };

    const createCreatorWorkbookArtifactSignedUrl = async (creatorId, workbookId, optionalInfo) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/public/creators/${creatorId}/workbooks/${workbookId}/artifact`;
        const payload = {};
        if (optionalInfo) {
            if (optionalInfo.headerHtml) {
                payload.header_html = optionalInfo.headerHtml;
            }
            if (optionalInfo.footerHtml) {
                payload.footer_html = optionalInfo.footerHtml;
            }
        }
        return postApiKeyRequest(url, payload);
    };

    const createCreatorWorkbookModuleArtifactSignedUrl = async (creatorId, workbookId, moduleId, optionalInfo) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/public/creators/${creatorId}/workbooks/${workbookId}/modules/${moduleId}/artifact`;
        const payload = {};
        if (optionalInfo) {
            if (optionalInfo.headerHtml) {
                payload.header_html = optionalInfo.headerHtml;
            }
            if (optionalInfo.footerHtml) {
                payload.footer_html = optionalInfo.footerHtml;
            }
        }
        return postApiKeyRequest(url, payload);
    };

    return {
        getWorkbookVersion,
        getWorkbookVersionModules,
        getCreator,
        createCreatorWorkbookArtifactSignedUrl,
        createCreatorWorkbookModuleArtifactSignedUrl,
    };
}
