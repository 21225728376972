export default function AccessibilityIcon({ className = '', style = {} }) {
    return (
        <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            style={style}>
            <circle cx="8.05949" cy="7.83683" r="7.01549" stroke="currentColor" strokeWidth="1.00988" />
            <path
                d="M9.13777 11.8677C8.89471 10.9678 8.45928 9.4696 8.3947 9.31091C8.31409 9.11287 8.18658 9.0133 8.05929 9.01262C7.932 9.0133 7.80449 9.11287 7.72387 9.31091C7.65929 9.4696 7.22386 10.9678 6.98081 11.8677C6.92321 12.081 6.85936 12.2942 6.83898 12.3415C6.72277 12.6111 6.23089 12.6228 6.08272 12.3595C6.05073 12.3026 6.0341 12.2167 6.04226 12.1506C6.04985 12.0888 6.218 11.4102 6.41587 10.6425C6.91076 8.72269 6.91335 8.70724 6.93637 7.5392C6.95388 6.65061 6.95057 6.7338 6.89392 6.62403C6.79987 6.4418 6.20037 6.54412 5.44494 6.46555C4.68951 6.38697 3.83788 5.99205 4.05078 5.38081C4.26368 4.76958 4.88865 5.24346 5.44494 5.38081C6.00123 5.51817 6.70861 5.78017 8.05929 5.78017V4.95093C6.82089 4.95093 6.80073 3.125 8.05929 3.125C9.31784 3.125 9.29768 4.95093 8.05929 4.95093V5.78017C9.40996 5.78017 10.1173 5.51817 10.6736 5.38081C11.2299 5.24346 11.8549 4.76958 12.0678 5.38081C12.2807 5.99205 11.4291 6.38697 10.6736 6.46555C9.91821 6.54412 9.3187 6.4418 9.22466 6.62403C9.168 6.7338 9.16469 6.65061 9.18221 7.5392C9.20522 8.70724 9.20782 8.72269 9.7027 10.6425C9.90058 11.4102 10.0687 12.0888 10.0763 12.1506C10.0845 12.2167 10.0678 12.3026 10.0359 12.3595C9.88768 12.6228 9.3958 12.6111 9.27959 12.3415C9.25921 12.2942 9.19537 12.081 9.13777 11.8677Z"
                fill="currentColor"
            />
        </svg>
    );
}
