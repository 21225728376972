/* eslint-disable camelcase */
const gtm = {
    pushVariable: (variable, value) => {
        window.dataLayer.push({ [variable]: value });
    },
    pushEvent: (event, eventData) => {
        window.dataLayer.push({ event, ...eventData });
    },
    pushSignup: (user_type, method, user_id) => {
        gtm.pushVariable('user_id', user_id);
        window.dataLayer.push({
            event: 'signup',
            user_type,
            method,
            user_id,
        });
    },
    pushSignin: (user_type, method, user_id) => {
        gtm.pushVariable('user_id', user_id);
        window.dataLayer.push({
            event: 'signin',
            user_type,
            method,
            user_id,
        });
    },
    pushPurchase: (user_id) => {
        gtm.pushVariable('user_id', user_id);
        window.dataLayer.push({
            event: 'purchase',
            user_id,
        });
    },
};

export default function useGTM() {
    if (!window.dataLayer) {
        window.dataLayer = [];
    }

    return gtm;
}
