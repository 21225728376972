import { ROLES } from './constants';
import hsp from './hubspot';

export default function useHubspot() {
    if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
        return {
            submitSignin: () => {},
            updateContactLifecycleStage: () => {},
            lifecycleStages: {},
        };
    }
    const currentRoleToString = (currentRole) => {
        if (!Object.values(ROLES).includes(currentRole)) {
            console.log('Invalid role: ', currentRole);
            return '';
        }
        const rolesMap = {
            [ROLES.NOT_SELECTED]: 'Not Selected',
            [ROLES.CREATOR]: 'Creator',
            [ROLES.STUDENT]: 'Student',
            [ROLES.MEMBER]: 'Member',
            [ROLES.ADMIN]: 'Admin',
        };
        return rolesMap[currentRole];
    };
    const submitSignin = async ({ user, companyName, signupOrigin, currentRole }) => {
        try {
            if (user.id) {
                hsp.submitSignin({
                    email: user.email,
                    firstname: user.firstName,
                    lastname: user.lastName,
                    companyName,
                    signupOrigin,
                    role: currentRoleToString(currentRole),
                });
            }
        } catch (err) {
            console.error('Hubspot event (submitSignin):', err);
        }
    };
    const updateContactLifecycleStage = async (email, lifecycleStage) => {
        try {
            if (!lifecycleStage || !Object.keys(hsp.LIFECYCLE_STAGES).includes(lifecycleStage)) {
                console.warn(`Hubspot event (Lifecycle): the lifecycle stage is not valid (${lifecycleStage})`);
                return;
            }
            if (!email) {
                console.warn(`Hubspot event (Lifecycle): the email is not valid (${email})`);
                return;
            }
            hsp.submitLifecycle(email, lifecycleStage);
        } catch (err) {
            console.error('Hubspot event (Lifecycle): ', err);
        }
    };
    return {
        submitSignin,
        updateContactLifecycleStage,
        lifecycleStages: hsp.LIFECYCLE_STAGES,
    };
}
