import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import get from 'lodash/get';
import useApi from '@/api/useApi';
import { ANALYTICS_EVENTS, useAuth } from '@/auth/AuthProvider';
import useWorkbookReducer, { ACTIONS } from '@/workbook/useWorkbookReducer';
import { moduleFromDto, sectionFromDto, workbookFromDto } from '@/api/dtos';
import { TOAST_POSITION, useToast } from '@/system/ToastProvider';
import { getDuplicatedSection, storageFilenameExistsInWorkbook } from '@/workbook/builder/sections/helpers';
import {
    deepCopy,
    getStorageObjectBasePath,
    appendUniqueIdToStr,
    getUniqueId,
    getArtifactOptionalInfo,
} from '@/util/helpers';
import { ARTIFACT_SAMPLE_STUDENT, UPDATE_THUMBNAIL_DEBOUNCE_TIMEOUT, WORKBOOK_ID_QUERY_PARAM } from '@/util/constants';
import { useRoles } from '@/navigation/RolesProvider';

const WorkbookContext = createContext();

export function useWorkbook() {
    return useContext(WorkbookContext);
}

export default function WorkbookProvider({ children }) {
    const isMounted = useRef(true);
    const { state, pathname, search } = useLocation();
    const routerState = (state && state.workbook) || {};
    const navigate = useNavigate();
    const { [WORKBOOK_ID_QUERY_PARAM]: workbookId } = useParams();
    const [workbook, setWorkbook] = useWorkbookReducer();
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [loadingError, setLoadingError] = useState('');
    const [publishing, setPublishing] = useState(false);
    const [editingSection, setEditingSection] = useState(false);
    const [loadingModule, setLoadingModule] = useState(false);
    // The workbookArtifactDownloadUrl state is stored at the provider level because we
    // don't want to regenerate an artifact unnecessarily. Once an artifact is generated
    // we don't need to regenerate a new one until a new response is made.
    const [workbookArtifactDownloadUrl, setWorkbookArtifactDownloadUrl] = useState(null);
    const {
        selectedCreator: creator,
        currentRole,
        makeWorkbookPublic: makeWorkbookPublicApi,
        makeWorkbookPrivate: makeWorkbookPrivateApi,
        addRecentlyUsedSection,
    } = useRoles();
    const { trackEvent } = useAuth();
    const {
        getCreatorWorkbook,
        updateWorkbookName,
        updateWorkbookCommenting,
        upsertWorkbookSchema,
        updateWorkbookDraft,
        getCreatorWorkbookModules,
        createModule,
        deleteModule: deleteModuleApi,
        duplicateModule: duplicateModuleApi,
        updateModuleTitle,
        moveModule: moveModuleApi,
        appendSection,
        upsertSectionSchema,
        duplicateSection: duplicateSectionApi,
        moveSection: moveSectionApi,
        deleteSection: deleteSectionApi,
        publishWorkbook: publishWorkbookApi,
        createWorkbookVersion,
        saveStorageObject,
        deleteStorageObject,
        updateWorkbookThumbnail: updateWorkbookThumbnailApi,
        createCreatorWorkbookArtifactSignedUrl,
        createCreatorWorkbookModuleArtifactSignedUrl,
    } = useApi();
    const { pushError, pushDefault } = useToast(TOAST_POSITION.BOTTOM);

    const updateThumbnailControl = useRef({
        pendingPromise: null,
        timeout: null,
    });

    const saveWorkbookDraft = () => {
        if (!workbook.draft) {
            setSaving(true);
            setWorkbook({ type: ACTIONS.UPDATE_WORKBOOK_DRAFT, payload: true });
            updateWorkbookDraft(creator.id, workbook.id)
                .then(() => {
                    if (isMounted.current) {
                        setSaving(false);
                    }
                })
                .catch((error) => {
                    if (isMounted.current) {
                        setWorkbook({ type: ACTIONS.UPDATE_WORKBOOK_DRAFT, payload: false });
                        pushError(`Error updating workbook draft: ${error.message}`, null);
                        setSaving(false);
                    }
                });
        } else {
            setSaving(false);
        }
    };

    const setWorkbookName = (name) => {
        setWorkbook({ type: ACTIONS.UPDATE_WORKBOOK_NAME, payload: name });
    };

    const saveWorkbookName = (name) => {
        setSaving(true);
        const previousName = workbook.name;
        setWorkbook({ type: ACTIONS.UPDATE_WORKBOOK_NAME, payload: name });
        updateWorkbookName(creator.id, workbook.id, name)
            .then(() => {
                if (isMounted.current) {
                    // No need to update draft to true in the backend cause updateWorkbookName does it for us
                    setWorkbook({ type: ACTIONS.UPDATE_WORKBOOK_DRAFT, payload: true });
                }
            })
            .catch((error) => {
                if (isMounted.current) {
                    setWorkbook({ type: ACTIONS.UPDATE_WORKBOOK_NAME, payload: previousName });
                    pushError(`Error updating workbook name: ${error.message}`, null);
                }
            })
            .finally(() => {
                if (isMounted.current) {
                    setSaving(false);
                }
            });
    };

    const saveWorkbookCommenting = async (commentingEnabled, notificationsEnabled, courseName, courseURL) => {
        setSaving(true);
        return updateWorkbookCommenting(
            creator.id,
            workbook.id,
            commentingEnabled,
            notificationsEnabled,
            courseName,
            courseURL,
        )
            .then(() => {
                if (isMounted.current) {
                    setWorkbook({
                        type: ACTIONS.UPDATE_WORKBOOK_COMMENTING,
                        payload: { commentingEnabled, notificationsEnabled, courseName, courseURL },
                    });
                    setSaving(false);
                }
            })
            .catch((error) => {
                if (isMounted.current) {
                    pushError(`Error updating workbook commenting: ${error.message}`, null);
                    setSaving(false);
                }
            })
            .finally(() => {
                if (isMounted.current) {
                    setSaving(false);
                }
            });
    };

    const setWorkbookSchemaProp = useCallback(
        (path, value) => {
            setWorkbook({ type: ACTIONS.UPDATE_WORKBOOK_SCHEMA_PROP, payload: { path, value } });
        },
        [setWorkbook],
    );

    const saveWorkbookSchemaProp = (path, value) => {
        setSaving(true);
        const previousValue = deepCopy(get(workbook.schema, path));
        setWorkbook({ type: ACTIONS.UPDATE_WORKBOOK_SCHEMA_PROP, payload: { path, value } });
        upsertWorkbookSchema(creator.id, workbook.id, path, value)
            .then(() => {
                if (isMounted.current) {
                    saveWorkbookDraft();
                }
            })
            .catch((error) => {
                if (isMounted.current) {
                    setWorkbook({ type: ACTIONS.UPDATE_WORKBOOK_SCHEMA_PROP, payload: { path, value: previousValue } });
                    pushError(`Error updating workbook schema: ${error.message}`, null);
                    setSaving(false);
                }
            });
    };

    const setWorkbookFile = (path, filename, imageFilename) => {
        setSaving(true);
        const previousValue = deepCopy(get(workbook.schema, path));
        const value = {
            ...previousValue,
            filename,
            storage_filename: imageFilename,
        };
        const filesCleanUpId = getUniqueId();
        setWorkbook({ type: ACTIONS.UPDATE_WORKBOOK_SCHEMA_FILE_PROP, payload: { path, value, filesCleanUpId } });
        upsertWorkbookSchema(creator.id, workbook.id, path, value)
            .then(() => {
                if (isMounted.current) {
                    setWorkbook({ type: ACTIONS.SET_FILES_CLEANUP_ID, payload: filesCleanUpId });
                    saveWorkbookDraft();
                }
            })
            .catch((error) => {
                if (isMounted.current) {
                    setWorkbook({
                        type: ACTIONS.UPDATE_WORKBOOK_SCHEMA_PROP,
                        payload: { path, value: previousValue },
                    });
                    pushError(`Error setting workbook file: ${error.message}`, null);
                    setSaving(false);
                }
            });
    };

    const saveWorkbookFile = (path, fileData) => {
        setSaving(true);
        const imageFilePrefix = 'workbook';
        const imageFilename = appendUniqueIdToStr(imageFilePrefix);
        const imageObjectKey = `${workbook.schema.storageObjectBasePath}${imageFilename}`;
        saveStorageObject(imageObjectKey, fileData)
            .then(() => setWorkbookFile(path, fileData.name, imageFilename))
            .catch((error) => {
                pushError(`Error saving workbook storage object : ${error.message}`, null);
                setSaving(false);
            });
    };

    const resetWorkbookFile = (path) => {
        const file = get(workbook.schema, path);
        if (!file.storage_filename) return;
        setWorkbookFile(path, '', '');
    };

    const performWorkbookThumbnailUpdate = () => {
        const control = updateThumbnailControl.current;

        if (control.pendingPromise) {
            // avoid concurrent calls
            return control.pendingPromise;
        }

        clearTimeout(control.timeout);

        const promise = updateWorkbookThumbnailApi(creator.id, workbook.id);

        updateThumbnailControl.current = {
            ...control,
            pendingPromise: promise,
            timeout: null,
        };

        promise.then(
            () => {
                updateThumbnailControl.current.pendingPromise = null;
            },
            () => {
                updateThumbnailControl.current.pendingPromise = null;
            },
        );

        return promise;
    };

    const updateWorkbookThumbnail = async () => {
        if (updateThumbnailControl.current.timeout) {
            clearTimeout(updateThumbnailControl.current.timeout);
            updateThumbnailControl.current.timeout = null;
        }
        /*
            This function takes around UPDATE_THUMBNAIL_DEBOUNCE_TIMEOUT + 10 secs. 
            If the user publishes before the call actually ends, the thumbnail is 
            not updated for the students.
        */
        updateThumbnailControl.current.timeout = setTimeout(
            performWorkbookThumbnailUpdate,
            UPDATE_THUMBNAIL_DEBOUNCE_TIMEOUT,
        );
    };

    const generateWorkbookArtifact = async () => {
        const optionalInfo = getArtifactOptionalInfo(creator.schema, ARTIFACT_SAMPLE_STUDENT);

        return createCreatorWorkbookArtifactSignedUrl(creator.id, workbook.id, optionalInfo)
            .then((dto) => {
                if (dto.url) {
                    return fetch(dto.url)
                        .then((res) => res.blob())
                        .then((blob) => {
                            const objectUrl = URL.createObjectURL(blob);
                            setWorkbookArtifactDownloadUrl(objectUrl);
                            return dto;
                        })
                        .catch((err) => {
                            pushError('Could not access PDF', null);
                            console.error(err);
                            throw err;
                        });
                }
                return dto;
            })
            .catch((err) => {
                pushError('Could not create PDF', null);
                console.error(err);
                throw err;
            });
    };

    const generateWorkbookModuleArtifact = async (moduleId) => {
        const optionalInfo = getArtifactOptionalInfo(creator.schema, ARTIFACT_SAMPLE_STUDENT);

        return createCreatorWorkbookModuleArtifactSignedUrl(creator.id, workbook.id, moduleId, optionalInfo)
            .then((dto) => {
                if (dto.url) {
                    return fetch(dto.url)
                        .then((res) => res.blob())
                        .then((blob) => {
                            const objectUrl = URL.createObjectURL(blob);
                            return { url: objectUrl };
                        })
                        .catch((err) => {
                            pushError('Could not access PDF', null);
                            console.error(err);
                            throw err;
                        });
                }
                return dto;
            })
            .catch((err) => {
                pushError('Could not create PDF', null);
                console.error(err);
                throw err;
            });
    };

    const loadRemainingSections = (sections) => {
        let index = 0;
        const id2 = setInterval(() => {
            if (sections.length === index) {
                setLoadingModule(false);
                clearInterval(id2);
            } else {
                const section = sections[index];
                setWorkbook({
                    type: ACTIONS.LAZY_APPEND_SECTION,
                    payload: section,
                });
                index += 1;
            }
        }, 10);
    };

    const selectModule = (moduleId, lazyLoad = false) => {
        if (moduleId !== workbook.selectedModule?.id) {
            const initialLoad = 10;
            const moduleSections = workbook.modules[workbook.moduleIdsDictionary[moduleId]].sections;
            if (lazyLoad && moduleSections.length > 30) {
                setLoadingModule(true);
                setWorkbook({
                    type: ACTIONS.SELECT_MODULE,
                    payload: { moduleId, sectionCount: initialLoad },
                });
                loadRemainingSections(moduleSections.slice(initialLoad));
            } else {
                setWorkbook({ type: ACTIONS.SELECT_MODULE, payload: { moduleId } });
            }
        }
    };

    const selectPrevModule = () => {
        setWorkbook({ type: ACTIONS.SELECT_PREV_MODULE });
    };

    const selectNextModule = () => {
        setWorkbook({ type: ACTIONS.SELECT_NEXT_MODULE });
    };

    const addModule = () => {
        setSaving(true);
        createModule(creator.id, workbook.id, `Module ${workbook.modules.length}`)
            .then((dto) => {
                trackEvent(ANALYTICS_EVENTS.ADD_WORKBOOK_MODULE);
                if (isMounted.current) {
                    setWorkbook({ type: ACTIONS.APPEND_MODULE, payload: moduleFromDto(dto) });
                    saveWorkbookDraft();
                }
            })
            .catch((error) => {
                if (isMounted.current) {
                    pushError(`Error adding module: ${error.message}`, null);
                    setSaving(false);
                }
            });
    };

    const duplicateModule = (moduleId) => {
        setSaving(true);
        const index = workbook.moduleIdsDictionary[moduleId] + 1;
        duplicateModuleApi(creator.id, workbook.id, moduleId)
            .then((dto) => {
                if (isMounted.current) {
                    setWorkbook({ type: ACTIONS.INSERT_MODULE, payload: { module: moduleFromDto(dto), index } });
                    saveWorkbookDraft();
                }
            })
            .catch((error) => {
                if (isMounted.current) {
                    pushError(`Error duplicating module: ${error.message}`, null);
                    setSaving(false);
                }
            });
    };

    const deleteModule = (moduleId) => {
        setSaving(true);
        const filesCleanUpId = getUniqueId();
        setWorkbook({ type: ACTIONS.DELETE_MODULE, payload: { moduleId, filesCleanUpId } });
        deleteModuleApi(creator.id, workbook.id, moduleId)
            .then(() => {
                if (isMounted.current) {
                    setWorkbook({ type: ACTIONS.SET_FILES_CLEANUP_ID, payload: filesCleanUpId });
                    saveWorkbookDraft();
                }
            })
            .catch((error) => {
                if (isMounted.current) {
                    pushError(`Error deleting module: ${error.message}`, null);
                    setSaving(false);
                }
            });
    };

    const moveModule = (fromIndex, toIndex) => {
        if (fromIndex !== toIndex) {
            setSaving(true);
            setWorkbook({ type: ACTIONS.MOVE_MODULE, payload: { fromIndex, toIndex } });
            moveModuleApi(creator.id, workbook.id, fromIndex, toIndex)
                .then(() => {
                    if (isMounted.current) {
                        saveWorkbookDraft();
                    }
                })
                .catch((error) => {
                    if (isMounted.current) {
                        setWorkbook({ type: ACTIONS.MOVE_MODULE, payload: { fromIndex: toIndex, toIndex: fromIndex } });
                        pushError(`Error moving module: ${error.message}`, null);
                        setSaving(false);
                    }
                });
        }
    };

    const saveModuleTitle = (title, showTitle) => {
        setSaving(true);
        const previousTitle = workbook.editingModule.title;
        setWorkbook({ type: ACTIONS.UPDATE_MODULE_TITLE, payload: [title, showTitle] });
        updateModuleTitle(creator.id, workbook.id, workbook.editingModule.id, title, showTitle)
            .then(() => {
                if (isMounted.current) {
                    saveWorkbookDraft();
                }
            })
            .catch((error) => {
                if (isMounted.current) {
                    setWorkbook({ type: ACTIONS.UPDATE_MODULE_TITLE, payload: previousTitle });
                    pushError(`Error updating module title: ${error.message}`, null);
                    setSaving(false);
                }
            });
    };

    const addSection = async (section) => {
        setSaving(true);
        setEditingSection(true);
        return appendSection(creator.id, workbook.id, workbook.selectedModule.id, section)
            .then((dto) => {
                trackEvent(ANALYTICS_EVENTS.ADD_WORKBOOK_SECTION);
                trackEvent(section.EVENT_NAME);
                addRecentlyUsedSection(section.EVENT_NAME);
                if (isMounted.current) {
                    setWorkbook({ type: ACTIONS.APPEND_SECTION, payload: sectionFromDto(dto) });
                    saveWorkbookDraft();
                }
            })
            .catch((error) => {
                if (isMounted.current) {
                    pushError(`Error adding section: ${error.message}`, null);
                    setSaving(false);
                }
            });
    };

    const selectSection = (sectionId) => {
        setEditingSection(true);
        setWorkbook({ type: ACTIONS.SELECT_SECTION, payload: sectionId });
    };

    const setSectionSchemaProp = useCallback(
        (path, value) => {
            setWorkbook({ type: ACTIONS.UPDATE_SECTION_SCHEMA_PROP, payload: { path, value } });
        },
        [setWorkbook],
    );

    const saveSectionSchemaProp = async (path, value) => {
        setSaving(true);
        const previousValue = deepCopy(get(workbook.selectedSection.schema, path));
        setWorkbook({ type: ACTIONS.UPDATE_SECTION_SCHEMA_PROP, payload: { path, value } });
        return upsertSectionSchema(
            creator.id,
            workbook.id,
            workbook.selectedModule.id,
            workbook.selectedSection.id,
            path,
            value,
            currentRole,
        )
            .then(() => {
                if (isMounted.current) {
                    saveWorkbookDraft();
                }
            })
            .catch((error) => {
                if (isMounted.current) {
                    setWorkbook({ type: ACTIONS.UPDATE_SECTION_SCHEMA_PROP, payload: { path, value: previousValue } });
                    pushError(`Error updating section schema: ${error.message}`, null);
                    setSaving(false);
                }
            });
    };

    const setSectionFile = (path, filename, storageFilename) => {
        setSaving(true);
        const previousValue = deepCopy(get(workbook.selectedSection.schema, path));
        const value = {
            ...previousValue,
            filename,
            storage_filename: storageFilename,
        };
        const filesCleanUpId = getUniqueId();
        setWorkbook({ type: ACTIONS.UPDATE_SECTION_SCHEMA_FILE_PROP, payload: { path, value, filesCleanUpId } });
        upsertSectionSchema(
            creator.id,
            workbook.id,
            workbook.selectedModule.id,
            workbook.selectedSection.id,
            path,
            value,
            currentRole,
        )
            .then(() => {
                if (isMounted.current) {
                    setWorkbook({ type: ACTIONS.SET_FILES_CLEANUP_ID, payload: filesCleanUpId });
                    saveWorkbookDraft();
                }
            })
            .catch((error) => {
                if (isMounted.current) {
                    setWorkbook({
                        type: ACTIONS.UPDATE_SECTION_SCHEMA_PROP,
                        payload: { path, value: previousValue },
                    });
                    pushError(`Error setting section file: ${error.message}`, null);
                    setSaving(false);
                }
            });
    };

    const saveSectionFile = async (path, fileData) => {
        setSaving(true);
        const filePrefix = 'section';
        const filename = appendUniqueIdToStr(filePrefix);
        const objectKey = `${workbook.schema.storageObjectBasePath}${filename}`;
        return saveStorageObject(objectKey, fileData)
            .then(() => setSectionFile(path, fileData.name, filename))
            .catch((error) => {
                pushError(`Error saving section storage object : ${error.message}`, null);
                setSaving(false);
            });
    };

    const resetSectionFile = (path) => {
        const file = get(workbook.selectedSection.schema, path);
        if (!file.storage_filename) return;
        setSectionFile(path, '', '');
    };

    const duplicateSection = (sectionId) => {
        setSaving(true);
        const sourceIndex = workbook.sectionIdsDictionary[sectionId];
        const sourceSection = workbook.selectedModule.sections[sourceIndex];
        const section = getDuplicatedSection(sourceSection);
        const index = sourceIndex + 1;
        duplicateSectionApi(creator.id, workbook.id, workbook.selectedModule.id, section, index)
            .then((dto) => {
                if (isMounted.current) {
                    setWorkbook({
                        type: ACTIONS.INSERT_SECTION,
                        payload: { section: sectionFromDto(dto), index },
                    });
                    saveWorkbookDraft();
                }
            })
            .catch((error) => {
                if (isMounted.current) {
                    pushError(`Error duplicating section: ${error.message}`, null);
                    setSaving(false);
                }
            });
    };

    const moveSection = (fromIndex, toIndex) => {
        if (fromIndex !== toIndex) {
            setSaving(true);
            setWorkbook({ type: ACTIONS.MOVE_SECTION, payload: { fromIndex, toIndex } });
            moveSectionApi(creator.id, workbook.id, workbook.selectedModule.id, fromIndex, toIndex)
                .then(() => {
                    if (isMounted.current) {
                        saveWorkbookDraft();
                    }
                })
                .catch((error) => {
                    if (isMounted.current) {
                        setWorkbook({
                            type: ACTIONS.MOVE_SECTION,
                            payload: { fromIndex: toIndex, toIndex: fromIndex },
                        });
                        pushError(`Error moving section: ${error.message}`, null);
                        setSaving(false);
                    }
                });
        }
    };

    const deleteSection = (sectionId) => {
        setSaving(true);
        const filesCleanUpId = getUniqueId();
        setWorkbook({ type: ACTIONS.DELETE_SECTION, payload: { sectionId, filesCleanUpId } });
        deleteSectionApi(creator.id, workbook.id, workbook.selectedModule.id, sectionId)
            .then(() => {
                if (isMounted.current) {
                    setWorkbook({ type: ACTIONS.SET_FILES_CLEANUP_ID, payload: filesCleanUpId });
                    saveWorkbookDraft();
                }
            })
            .catch((error) => {
                if (isMounted.current) {
                    pushError(`Error deleting section: ${error.message}`, null);
                    setSaving(false);
                }
            });
    };

    const publishWorkbook = async () => {
        setPublishing(true);
        if (!workbook.versionId) {
            return createWorkbookVersion(creator.id, workbook.id)
                .then((dto) => {
                    if (isMounted.current) {
                        setWorkbook({ type: ACTIONS.UPDATE_VERSION_ID, payload: dto.id });
                        // There's no need to update the draft field on the backend because
                        // publish API does it for us
                        setWorkbook({ type: ACTIONS.UPDATE_WORKBOOK_DRAFT, payload: false });
                        pushDefault('Workbook published successfully', 3000);
                        setPublishing(false);
                    }
                    return true;
                })
                .catch((error) => {
                    if (isMounted.current) {
                        pushError(`Error publishing workbook: ${error.message}`, null);
                        setPublishing(false);
                    }
                    return false;
                });
        }

        return new Promise((resolve, reject) => {
            if (updateThumbnailControl.current.timeout) {
                // if there's a pending updateThumbnail call, clear it and call the thumbnail update
                // API before publishing the workbook, so the updated thumbnail is published
                performWorkbookThumbnailUpdate().then(resolve, reject);
            } else {
                // if there's no pending updateThumbnail call, wait any pending promise to resolve
                resolve(updateThumbnailControl.current.pendingPromise);
            }
        })
            .then(() => publishWorkbookApi(creator.id, workbook.id, workbook.versionId))
            .then(() => {
                trackEvent(ANALYTICS_EVENTS.PUBLISH_WORKBOOK);
                if (isMounted.current) {
                    // There's no need to update the draft field on the backend because
                    // publish API does it for us
                    setWorkbook({ type: ACTIONS.UPDATE_WORKBOOK_DRAFT, payload: false });
                    pushDefault('Workbook published successfully', 3000);
                    setPublishing(false);
                }
                return true;
            })
            .catch((error) => {
                if (isMounted.current) {
                    pushError(`Error publishing workbook: ${error.message}`, null);
                    setPublishing(false);
                }
                return false;
            });
    };

    const setCohortId = async (cohortId) => {
        if (cohortId) {
            setWorkbook({ type: ACTIONS.UPDATE_COHORT_ID, payload: cohortId });
            return true;
        }
        return false;
    };

    const makeWorkbookPublic = () => {
        setSaving(true);
        makeWorkbookPublicApi(workbook.id)
            .then(() => {
                if (isMounted.current) {
                    setWorkbook({ type: ACTIONS.SET_PUBLIC, payload: true });
                }
            })
            .catch((error) => {
                if (isMounted.current) {
                    pushError(`Error making workbook public: ${error.message}`, null);
                }
            })
            .finally(() => {
                if (isMounted.current) {
                    setSaving(false);
                }
            });
    };

    const makeWorkbookPrivate = () => {
        setSaving(true);
        makeWorkbookPrivateApi(workbook.id)
            .then(() => {
                if (isMounted.current) {
                    setWorkbook({ type: ACTIONS.SET_PUBLIC, payload: false });
                }
            })
            .catch((error) => {
                if (isMounted.current) {
                    pushError(`Error making workbook private: ${error.message}`, null);
                }
            })
            .finally(() => {
                if (isMounted.current) {
                    setSaving(false);
                }
            });
    };

    const loadWorkbook = async () => {
        const payload = {
            workbook: {},
            modules: [],
        };
        if (routerState.id === workbookId) {
            payload.workbook = routerState;
        } else {
            const workbookDto = await getCreatorWorkbook(creator.id, workbookId);
            payload.workbook = workbookFromDto(workbookDto);
        }
        if (payload.workbook.moduleIds.length > 0) {
            const moduleDtos = await getCreatorWorkbookModules(creator.id, workbookId).catch((error) => {
                if (isMounted.current) {
                    const fullError = `Error getting modules: ${error.message}`;
                    pushError(fullError, null);
                    throw new Error(fullError);
                }
            });
            if (moduleDtos) {
                payload.modules = moduleDtos.map((dto) => moduleFromDto(dto));
            }
        }
        return payload;
    };

    const stopEditingSection = () => {
        setEditingSection(false);
    };

    const startEditingModule = (moduleId) => {
        setWorkbook({ type: ACTIONS.SELECT_EDITING_MODULE, payload: moduleId });
    };

    const stopEditingModule = () => {
        setWorkbook({ type: ACTIONS.CLEAR_EDITING_MODULE });
    };

    useEffect(() => {
        if (
            isMounted.current &&
            workbook.filesCleanUpId &&
            Object.prototype.hasOwnProperty.call(workbook.filesToCleanUp, workbook.filesCleanUpId)
        ) {
            workbook.filesToCleanUp[workbook.filesCleanUpId].forEach((filename) => {
                if (filename && !storageFilenameExistsInWorkbook(workbook, filename)) {
                    const previousImageObjectKey = `${workbook.schema.storageObjectBasePath}${filename}`;
                    deleteStorageObject(previousImageObjectKey);
                }
            });
            setWorkbook({ type: ACTIONS.RESET_FILES_CLEANUP_ID });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workbook.filesCleanUpId]);

    useEffect(() => {
        const revokeUrl = workbookArtifactDownloadUrl;
        return () => {
            if (revokeUrl) URL.revokeObjectURL(revokeUrl);
        };
    }, [workbookArtifactDownloadUrl]);

    useEffect(() => {
        isMounted.current = true;
        if (isMounted.current) {
            setLoading(true);
            loadWorkbook()
                .then((response) => {
                    const versionId =
                        response.workbook.versionIds && response.workbook.versionIds.length > 0
                            ? response.workbook.versionIds[0]
                            : '';
                    const cohortId =
                        response.workbook.cohortIds && response.workbook.cohortIds.length > 0
                            ? response.workbook.cohortIds[0]
                            : '';
                    const isPublic = creator.publicWorkbookIds.includes(response.workbook.id);
                    const payload = {
                        ...response,
                        workbook: {
                            ...response.workbook,
                            schema: {
                                ...response.workbook.schema,
                                storageObjectBasePath: getStorageObjectBasePath(creator.id, response.workbook.id),
                                workbookUploadsStorageObjectBasePath: '',
                            },
                        },
                        versionId,
                        cohortId,
                        isPublic,
                    };
                    setWorkbook({ type: ACTIONS.SET, payload });
                    setLoading(false);
                })
                .catch((error) => {
                    if (isMounted.current) {
                        setLoadingError(error.message);
                        setLoading(false);
                    }
                });
        }
        // Reset router state by leaving the second parameter's state property undefined to ensure
        // the latest workbook data is used when rendering
        navigate(`${pathname}${search}`, { replace: true });
        return () => {
            isMounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(
        function savePendingThumbnailChanges() {
            return () => {
                if (updateThumbnailControl.current.timeout != null) {
                    updateWorkbookThumbnailApi(creator.id, workbook.id);
                    updateThumbnailControl.current.timeout = undefined;
                }
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [updateThumbnailControl.current.timeout, creator.id, workbook.id],
    );

    const context = {
        workbook,
        loading,
        loadingError,
        loadingModule,
        publishing,
        saving,
        editingSection,
        setWorkbookName,
        saveWorkbookName,
        saveWorkbookCommenting,
        setWorkbookSchemaProp,
        saveWorkbookSchemaProp,
        saveWorkbookFile,
        resetWorkbookFile,
        updateWorkbookThumbnail,
        generateWorkbookArtifact,
        generateWorkbookModuleArtifact,
        selectModule,
        selectPrevModule,
        selectNextModule,
        addModule,
        duplicateModule,
        deleteModule,
        moveModule,
        saveModuleTitle,
        addSection,
        selectSection,
        setSectionSchemaProp,
        saveSectionSchemaProp,
        saveSectionFile,
        resetSectionFile,
        duplicateSection,
        moveSection,
        deleteSection,
        publishWorkbook,
        workbookArtifactDownloadUrl,
        setCohortId,
        makeWorkbookPublic,
        makeWorkbookPrivate,
        stopEditingSection,
        startEditingModule,
        stopEditingModule,
    };

    return <WorkbookContext.Provider value={context}>{children}</WorkbookContext.Provider>;
}
