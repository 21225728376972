/* eslint-disable react/jsx-boolean-value */
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '@/system/LoadingSpinner';
import TechnicalError from '@/system/TechnicalError';
import { useAuth } from '@/auth/AuthProvider';
import { useEmbed } from '@/embed/EmbedProvider';
import useQueryParameter from '@/util/useQueryParameter';
import {
    ROLES,
    ROLE_QUERY_PARAM,
    EMBED_QUERY_PARAMS,
    ROUTES,
    ERROR_QUERY_PARAM,
    FROM_URL_QUERY_PARAM,
} from '@/util/constants';
import { SSO_ERROR_ACTION } from '@/embed/ThinkificSso';
import { appendQueryParams, getSsoErrorState } from '@/util/helpers';
import useGTM from '@/util/useGTM';

export default function ThinkificAuthComplete() {
    const isMounted = useRef(true);
    const navigate = useNavigate();
    const { completeEmbedLogin, completeEmbedLoginV2, user, setOriginalSignin } = useAuth();
    const { embedded } = useEmbed();
    const ssoError = useQueryParameter(ERROR_QUERY_PARAM);
    const fromUrl = useQueryParameter(FROM_URL_QUERY_PARAM);
    const isCreator = useQueryParameter('is_creator'); // Backwards compatibility
    let role = useQueryParameter(ROLE_QUERY_PARAM);
    if (isCreator) role = ROLES.CREATOR; // Backwards compatibility
    const thinkificV2 = useQueryParameter(EMBED_QUERY_PARAMS.THINKIFIC_V2);
    const errorState = getSsoErrorState(ssoError, role);
    const fromUrlWithRole = appendQueryParams(fromUrl, { role });
    const gtm = useGTM();

    const handleSsoErrorClick = () => {
        navigate(errorState.navigateUrl);
    };

    const reportError = (errorMessage) => {
        if (!thinkificV2) {
            window.opener.postMessage({ action: SSO_ERROR_ACTION, payload: errorMessage }, '*');
            window.opener.console.error(errorMessage);
            window.close();
        } else {
            console.error(errorMessage);
        }
    };

    useEffect(() => {
        isMounted.current = true;
        if (embedded) {
            if (!ssoError) {
                const defaultUrl = `${ROUTES.COMMON.BASE}?embed=true`;

                if (!thinkificV2) {
                    completeEmbedLogin()
                        .then(() => {
                            gtm.pushSignin('creator', 'thinkific', user.id);
                            setOriginalSignin('thinkific');
                            window.opener.location.href = fromUrlWithRole || appendQueryParams(defaultUrl, { role });
                            window.close();
                        })
                        .catch((error) => {
                            if (isMounted.current) {
                                reportError(error.message);
                            }
                        });
                } else {
                    completeEmbedLoginV2()
                        .then(() => {
                            gtm.pushSignin('creator', 'thinkific', user.id);
                            setOriginalSignin('thinkific');
                            navigate(fromUrlWithRole || appendQueryParams(defaultUrl, { role }));
                        })
                        .catch((error) => {
                            if (isMounted.current) {
                                reportError(error.message);
                            }
                        });
                }
            } else {
                reportError(ssoError);
            }
        } else if (!ssoError) {
            gtm.pushSignin('creator', 'thinkific', user.id);
            setOriginalSignin('thinkific');
            navigate(fromUrlWithRole || ROUTES.COMMON.BASE);
        }
        return () => {
            isMounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (ssoError)
        return (
            <TechnicalError
                title="SSO error"
                desc={`Error completing SSO: ${ssoError}`}
                buttonLabel={embedded ? null : errorState.buttonLabel}
                onButtonClick={handleSsoErrorClick}
            />
        );
    return <LoadingSpinner />;
}
