import { useState } from 'react';
import { Disclosure } from '@headlessui/react';
import { Link } from 'react-router-dom';
import { HandIcon, XIcon } from '@heroicons/react/outline';
import { ROUTES } from '@/util/constants';
import { DefaultButton } from '@/system/Buttons';
import { NoActionModal } from '@/system/Modals';
import StudentLogin from '@/auth/distribution/StudentLogin';
import { useWorkbook } from '@/workbook/artifact/WorkbookJsonLoaderProvider';
import StudentEmailSignup from '@/auth/distribution/StudentEmailSignup';

const navbarConfig = {
    routes: {
        dashboard: ROUTES.STUDENT.DASHBOARD,
    },
};

export default function PubliclySharedWorkbookAppNavbar({ signupModalVisible, setSignupModalVisible }) {
    const dashboardRoute = navbarConfig.routes.dashboard;
    const [loginModalVisible, setLoginModalVisible] = useState(false);

    const onLoginClick = () => {
        setSignupModalVisible(false);
        setLoginModalVisible(true);
    };

    return (
        <Disclosure as="nav" className="relative bg-white">
            <div className="relative z-20 flex h-12 items-center justify-between bg-white px-2 shadow sm:px-4 lg:h-16 lg:px-16">
                <Link className="flex flex-shrink-0 items-center ml-2 sm:ml-0" to={dashboardRoute}>
                    <img src="/images/logo.svg" alt="Wobo" className="block w-20" />
                </Link>
                <ReadonlyModeHeader />
                <SignInSignupButtons onLoginClick={onLoginClick} onSignupClick={() => setSignupModalVisible(true)} />
            </div>
            {loginModalVisible && <SignInModal onClose={() => setLoginModalVisible(false)} />}
            {signupModalVisible && (
                <SignupModal onLoginClick={onLoginClick} onClose={() => setSignupModalVisible(false)} />
            )}
        </Disclosure>
    );
}

function ReadonlyModeHeader() {
    return (
        <div className="paragraph__sm flex items-center">
            <HandIcon className="w-9 m-2" />
            <span>Read-Only Mode</span>
        </div>
    );
}

function SignInSignupButtons({ onLoginClick, onSignupClick }) {
    return (
        <div className="flex items-center">
            <DefaultButton
                label="Log in"
                onClick={onLoginClick}
                className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-primary-500 hover:bg-primary-600"
            />
            <button
                type="button"
                onClick={onSignupClick}
                className="ml-3 inline-flex items-center px-4 py-2 border border-primary text-base font-medium rounded-md text-primary-500 bg-white hover:bg-primary-50">
                Sign up
            </button>
        </div>
    );
}

function SignInModal({ onClose }) {
    const { shareId } = useWorkbook();
    return (
        <NoActionModal
            padding="p-10"
            show
            form={
                <div className="flex flex-col max-w-[400px]">
                    <div className="h-[28px] mb-4 flex flex-row-reverse">
                        <XIcon className="h-full text-neutral-300 cursor-pointer" onClick={onClose} />
                    </div>
                    <StudentLogin
                        showThinkific={false}
                        showGoogle={false}
                        autoEnrollPubliclySharedWorkbookId={shareId}
                    />
                </div>
            }
        />
    );
}

function SignupModal({ onLoginClick, onClose }) {
    const { shareId } = useWorkbook();

    return (
        <NoActionModal
            padding="p-10"
            show
            form={
                <div className="flex flex-col max-w-[400px]">
                    <div className="h-[28px] mb-4 flex flex-row-reverse">
                        <XIcon className="h-full text-neutral-300 cursor-pointer" onClick={onClose} />
                    </div>
                    <StudentEmailSignup onLoginClick={onLoginClick} publiclySharedWorkbookId={shareId} />
                </div>
            }
        />
    );
}
