export default function DashboardBackground() {
    return (
        <div className="absolute w-full">
            <div className="relative h-[150px] w-full bg-neutral-50 md:h-[250px]" />
            <svg width="100%" height="250px" viewBox="0 0 500 80" preserveAspectRatio="none">
                <path d="M0,0 L0,40 Q250,80 500,40 L500,0 Z" fill="#F3F3F3" />
            </svg>
        </div>
    );
}
