import superagent from 'superagent';

export class ApiError extends Error {
    constructor(message, statusCode) {
        const subMessages = message.split(':');
        super(subMessages.length === 1 ? subMessages[0].trim() : subMessages[1].trim());
        this.statusCode = statusCode;
    }
}

export const getSearch = (queryParamsObject, omitEncoding = false) => {
    const queryParamArray = [];
    Object.keys(queryParamsObject).forEach((query) => {
        if (omitEncoding) {
            queryParamArray.push(`${query}=${queryParamsObject[query]}`);
        } else {
            queryParamArray.push(`${encodeURIComponent(query)}=${encodeURIComponent(queryParamsObject[query])}`);
        }
    });
    return queryParamArray.length > 0 ? `?${queryParamArray.join('&')}` : '';
};

export const throwApiError = (response) => {
    const { message } = response.response.body;
    const statusCode = response.status;
    throw new ApiError(message, statusCode);
};

export const dispatchRequest = async (baseRequest) => {
    return baseRequest
        .set('Accept', 'application/json')
        .withCredentials()
        .then((res) => res.body)
        .catch((res) => throwApiError(res));
};

export const buildSimpleRequest = async (baseRequest, headers) => {
    if (headers && Object.keys(headers).length > 0) {
        Object.keys(headers).forEach((header) => {
            baseRequest.set(header, headers[header]);
        });
    }
    return dispatchRequest(baseRequest);
};

export const buildApiKeyRequest = async (baseRequest) => {
    return dispatchRequest(baseRequest.set('api-key', process.env.REACT_APP_API_KEY));
};

export const buildXRequestedWithRequest = async (baseRequest) => {
    return dispatchRequest(baseRequest.set('X-Requested-With', process.env.REACT_APP_API_X_REQUESTED_WITH));
};

export const buildAccessTokenRequest = async (baseRequest, accessToken) => {
    return dispatchRequest(baseRequest.set('Authorization', `Bearer ${accessToken}`));
};

export const postXRequestedWithRequest = async (url, payload, queryParamsObject) => {
    let urlWithQueryParams = url;
    if (queryParamsObject) {
        urlWithQueryParams += getSearch(queryParamsObject);
    }
    const baseRequest = superagent.post(urlWithQueryParams).send(payload);
    return buildXRequestedWithRequest(baseRequest);
};

export const postAccessTokenRequest = async (url, payload, accessToken, queryParamsObject) => {
    let urlWithQueryParams = url;
    if (queryParamsObject) {
        urlWithQueryParams += getSearch(queryParamsObject);
    }
    const baseRequest = superagent.post(urlWithQueryParams).send(payload);
    return buildAccessTokenRequest(baseRequest, accessToken);
};

export const postSimpleRequest = async (url, payload, queryParamsObject, headers) => {
    let urlWithQueryParams = url;
    if (queryParamsObject) {
        urlWithQueryParams += getSearch(queryParamsObject);
    }
    const baseRequest = superagent.post(urlWithQueryParams).send(payload);
    return buildSimpleRequest(baseRequest, headers);
};

export const putSimpleRequest = async (url, payload, queryParamsObject, headers) => {
    let urlWithQueryParams = url;
    if (queryParamsObject) {
        urlWithQueryParams += getSearch(queryParamsObject);
    }
    const baseRequest = superagent.put(urlWithQueryParams).send(payload);
    return buildSimpleRequest(baseRequest, headers);
};

export const postApiKeyRequest = async (url, payload, queryParamsObject) => {
    let urlWithQueryParams = url;
    if (queryParamsObject) {
        urlWithQueryParams += getSearch(queryParamsObject);
    }
    const baseRequest = superagent.post(urlWithQueryParams).send(payload);
    return buildApiKeyRequest(baseRequest);
};

export const getApiKeyRequest = async (url, queryParamsObject) => {
    let urlWithQueryParams = url;
    if (queryParamsObject) {
        urlWithQueryParams += getSearch(queryParamsObject);
    }
    const baseRequest = superagent.get(urlWithQueryParams).send();
    return buildApiKeyRequest(baseRequest);
};

export const getXRequestedWithRequest = async (url, queryParamsObject, omitEncoding) => {
    let urlWithQueryParams = url;
    if (queryParamsObject) {
        urlWithQueryParams += getSearch(queryParamsObject, omitEncoding);
    }
    const baseRequest = superagent.get(urlWithQueryParams);
    return buildXRequestedWithRequest(baseRequest);
};

export const getAccessTokenRequest = async (url, accessToken, queryParamsObject, omitEncoding) => {
    let urlWithQueryParams = url;
    if (queryParamsObject) {
        urlWithQueryParams += getSearch(queryParamsObject, omitEncoding);
    }
    const baseRequest = superagent.get(urlWithQueryParams);
    return buildAccessTokenRequest(baseRequest, accessToken);
};

export const patchXRequestedWithRequest = async (url, payload, queryParamsObject) => {
    let urlWithQueryParams = url;
    if (queryParamsObject) {
        urlWithQueryParams += getSearch(queryParamsObject);
    }
    const baseRequest = superagent.patch(urlWithQueryParams).send(payload);
    return buildXRequestedWithRequest(baseRequest);
};

export const patchAccessTokenRequest = async (url, payload, accessToken, queryParamsObject) => {
    let urlWithQueryParams = url;
    if (queryParamsObject) {
        urlWithQueryParams += getSearch(queryParamsObject);
    }
    const baseRequest = superagent.patch(urlWithQueryParams).send(payload);
    return buildAccessTokenRequest(baseRequest, accessToken);
};

export const putXRequestedWithRequest = async (url, payload, queryParamsObject) => {
    let urlWithQueryParams = url;
    if (queryParamsObject) {
        urlWithQueryParams += getSearch(queryParamsObject);
    }
    const baseRequest = superagent.put(urlWithQueryParams).send(payload);
    return buildXRequestedWithRequest(baseRequest);
};

export const putAccessTokenRequest = async (url, payload, accessToken, queryParamsObject) => {
    let urlWithQueryParams = url;
    if (queryParamsObject) {
        urlWithQueryParams += getSearch(queryParamsObject);
    }
    const baseRequest = superagent.put(urlWithQueryParams).send(payload);
    return buildAccessTokenRequest(baseRequest, accessToken);
};

export const deleteXRequestedWithRequest = async (url, queryParamsObject, payload) => {
    let urlWithQueryParams = url;
    if (queryParamsObject) {
        urlWithQueryParams += getSearch(queryParamsObject);
    }
    const baseRequest = superagent.delete(urlWithQueryParams).send(payload);
    return buildXRequestedWithRequest(baseRequest);
};

export const deleteAccessTokenRequest = async (url, accessToken, queryParamsObject, payload) => {
    let urlWithQueryParams = url;
    if (queryParamsObject) {
        urlWithQueryParams += getSearch(queryParamsObject);
    }
    const baseRequest = superagent.delete(urlWithQueryParams).send(payload);
    return buildAccessTokenRequest(baseRequest, accessToken);
};
