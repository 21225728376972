import { useReducer } from 'react';
import set from 'lodash/set';
import { PLANS, ARTIFACT_PAGE_FORMATS } from '@/util/constants';
import { deepCopy } from '@/util/helpers';
import { SECTIONS_EVENTS_DICTIONARY } from '@/workbook/builder/sections/sections';
import { getDefaultSchema as getDefaultWorkbookSchema } from '@/workbook/useWorkbookReducer';

const maxRecentlyUsedSections = 3;

export const ACTIONS = {
    INIT: 'init',
    SET_SELECTED_ROLE: 'set_selected_role',
    SET_CREATOR: 'set_creator',
    SET_SELECTED_CREATOR: 'set_selected_creator',
    UPDATE_CREATOR_SCHEMA_PROP: 'update_creator_schema_prop',
    ADD_CREATOR_PUBLIC_WORKBOOK: 'add_creator_public_workbook',
    REMOVE_CREATOR_PUBLIC_WORKBOOK: 'remove_creator_public_workbook',
    SET_ANALYTICS: 'set_analytics',
    ADD_RECENTLY_USED_SECTION: 'add_recently_used_section',
};

export const getDefaultCreatorSchema = () => ({
    protectingIp: {
        disableTextCopying: false,
        watermark: {
            includeStudentDetails: false,
            includeCustomText: false,
            customText: '',
        },
    },
    artifact: {
        pageFormat: ARTIFACT_PAGE_FORMATS.LETTER,
        showPageNumbers: false,
    },
    companyLogo: {
        storage_filename: '',
        filename: '',
    },
    defaultWorkbookSettings: getDefaultWorkbookSchema(),
});

export const getDefaultCreatorSsoConfig = () => ({
    authorizationRoute: '',
    tokenRoute: '',
    userRoute: '',
    clientId: '',
    clientSecret: '',
    scope: '',
    userMapping: {
        email: '',
        givenName: '',
        familyName: '',
    },
    active: false,
});

export const getDefaultAnalytics = () => ({ recentlyUsedSections: new Set() });

export const getDefaultState = () => {
    return {
        selectedRole: null,
        student: {
            id: '',
            creators: {},
            totalCreators: 0,
        },
        creator: {
            id: '',
            workbookIds: [],
            memberIds: [],
            companyName: '',
            plan: PLANS.NO_PAID,
            thinkificDomain: '',
            thinkificSubdomain: '',
            teachableDomain: '',
            teachableSubdomain: '',
            teachableClientId: '',
            teachableClientSecret: '',
            teachableSchoolId: '',
            paymentCustomerId: '',
            paymentCheckoutSessionId: '',
            paymentSubscriptionId: '',
            paymentCancelEndDate: null,
            paymentHistoricalPeriods: null,
            trialEndDate: null,
            schema: getDefaultCreatorSchema(),
            publicWorkbookIds: [],
            addOns: {
                studentImageUpload: false,
            },
        },
        member: {
            id: '',
            creators: {},
            totalCreators: 0,
        },
        admin: {
            id: '',
        },
        selectedCreator: null,
        analytics: getDefaultAnalytics(),
    };
};

export const rolesReducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.INIT: {
            return {
                ...state,
                selectedRole: action.payload.selectedRole,
                creator: action.payload.creator,
                student: action.payload.student,
                member: action.payload.member,
                admin: action.payload.admin,
                selectedCreator: action.payload.selectedCreator,
            };
        }
        case ACTIONS.SET_SELECTED_ROLE: {
            return {
                ...state,
                selectedRole: action.payload.selectedRole,
                selectedCreator: action.payload.selectedCreator,
            };
        }
        case ACTIONS.SET_CREATOR: {
            return {
                ...state,
                creator: {
                    ...state.creator,
                    ...action.payload,
                },
                selectedCreator: {
                    ...state.creator,
                    ...action.payload,
                },
            };
        }
        case ACTIONS.SET_SELECTED_CREATOR: {
            return {
                ...state,
                selectedCreator: action.payload,
            };
        }
        case ACTIONS.UPDATE_CREATOR_SCHEMA_PROP: {
            const { path, value } = action.payload;
            const schema = deepCopy(state.creator.schema);
            set(schema, path, value);
            return {
                ...state,
                creator: {
                    ...state.creator,
                    schema,
                },
                selectedCreator: {
                    ...state.creator,
                    schema,
                },
            };
        }
        case ACTIONS.ADD_CREATOR_PUBLIC_WORKBOOK: {
            const publicWorkbookIds = [...state.creator.publicWorkbookIds, action.payload];
            return {
                ...state,
                creator: {
                    ...state.creator,
                    publicWorkbookIds,
                },
                selectedCreator: {
                    ...state.creator,
                    publicWorkbookIds,
                },
            };
        }
        case ACTIONS.REMOVE_CREATOR_PUBLIC_WORKBOOK: {
            const publicWorkbookIds = state.creator.publicWorkbookIds.filter((id) => id !== action.payload);
            return {
                ...state,
                creator: {
                    ...state.creator,
                    publicWorkbookIds,
                },
                selectedCreator: {
                    ...state.creator,
                    publicWorkbookIds,
                },
            };
        }
        case ACTIONS.SET_ANALYTICS: {
            const results = action.payload;
            const analytics = getDefaultAnalytics();
            if (results) {
                for (
                    let i = results.events.length - 1;
                    i >= 0 && analytics.recentlyUsedSections.size < maxRecentlyUsedSections;
                    i -= 1
                ) {
                    const eventName = results.events[i].event;
                    const sectionKey = SECTIONS_EVENTS_DICTIONARY[eventName];
                    if (sectionKey) {
                        analytics.recentlyUsedSections.add(sectionKey);
                    }
                }
            }
            return {
                ...state,
                analytics,
            };
        }
        case ACTIONS.ADD_RECENTLY_USED_SECTION: {
            const eventName = action.payload;
            const recentlyUsedSections = new Set([SECTIONS_EVENTS_DICTIONARY[eventName]]);
            state.analytics.recentlyUsedSections.forEach((sectionKey) => {
                if (recentlyUsedSections.size < maxRecentlyUsedSections) {
                    recentlyUsedSections.add(sectionKey);
                }
            });
            return {
                ...state,
                analytics: {
                    ...state.analytics,
                    recentlyUsedSections,
                },
            };
        }
        default:
            throw new Error('Action not supported ');
    }
};

const useRolesReducer = () => {
    return useReducer(rolesReducer, getDefaultState());
};

export default useRolesReducer;
