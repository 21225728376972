export default function BoldIcon({ className }) {
    return (
        <svg
            width="8"
            height="10"
            viewBox="0 0 8 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}>
            <path
                d="M0.661577 9.47852V0.751243H4.00249C4.63317 0.751243 5.15732 0.850674 5.57493 1.04954C5.99538 1.24556 6.3093 1.51403 6.51669 1.85494C6.72692 2.19584 6.83203 2.58221 6.83203 3.01403C6.83203 3.36914 6.76385 3.67312 6.62749 3.92596C6.49112 4.17596 6.30788 4.37908 6.07777 4.53533C5.84766 4.69158 5.59055 4.8038 5.30646 4.87198V4.95721C5.61612 4.97425 5.913 5.06942 6.19709 5.24272C6.48402 5.41317 6.71839 5.65465 6.90021 5.96715C7.08203 6.27965 7.17294 6.65749 7.17294 7.10067C7.17294 7.55238 7.06357 7.95863 6.84482 8.31942C6.62607 8.67738 6.29652 8.96005 5.85618 9.16744C5.41584 9.37482 4.86186 9.47852 4.19425 9.47852H0.661577ZM2.24254 8.15749H3.94283C4.51669 8.15749 4.93004 8.04812 5.18288 7.82937C5.43857 7.60778 5.56641 7.32369 5.56641 6.9771C5.56641 6.71857 5.50249 6.48562 5.37465 6.27823C5.2468 6.068 5.06499 5.90323 4.82919 5.78391C4.59339 5.66175 4.31214 5.60067 3.98544 5.60067H2.24254V8.15749ZM2.24254 4.46289H3.80646C4.07919 4.46289 4.32493 4.41317 4.54368 4.31374C4.76243 4.21147 4.9343 4.068 5.0593 3.88335C5.18714 3.69585 5.25107 3.47425 5.25107 3.21857C5.25107 2.8805 5.13175 2.60209 4.89311 2.38334C4.65732 2.16459 4.30646 2.05522 3.84055 2.05522H2.24254V4.46289Z"
                fill="currentColor"
            />
        </svg>
    );
}
