/* eslint-disable react/jsx-props-no-spreading */
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { CheckIcon, ExclamationIcon } from '@heroicons/react/outline';
import { DefaultButton, WhiteButton, CancelButton, BUTTON_SIZE } from '@/system/Buttons';
import FocusableHiddenElement from '@/system/FocusableHiddenElement';

const MODAL_VARIANT = {
    SINGLE_ACTION: 'singleAction',
    TWO_ACTION: 'twoAction',
    FORM: 'form',
    SIMPLE: 'simple',
    NO_ACTION: 'noAction',
};

function BaseModal({
    variant,
    show,
    header,
    title,
    showTitle = true,
    padding = '',
    desc,
    form = null,
    error = false,
    showIcon = true,
    icon = null,
    iconClassName = '',
    iconStyle = {},
    onClose,
    cancelButtonLabel = 'Cancel',
    onCancel,
    actionButtonComponent = null,
    actionButtonLabel = 'Continue',
    onActionClick,
    hideCancel = false,
    className = '',
    modalClassName = '',
    'data-test': dataTest,
}) {
    const Icon = icon;

    let variantIconClasses;

    switch (variant) {
        case MODAL_VARIANT.SINGLE_ACTION:
            variantIconClasses = error ? 'w-5 text-error-400' : 'w-5 text-success-500';
            break;
        default:
            variantIconClasses = error ? 'w-5 text-error-400' : 'w-5 text-primary-500';
    }

    const iconClasses = classNames(variantIconClasses, iconClassName);
    const dialogClasses = classNames('fixed inset-0 overflow-y-auto z-90', className);
    const Button = actionButtonComponent || DefaultButton;

    return (
        <Transition show={show} as={Fragment} appear>
            <Dialog onClose={typeof onClose === 'function' ? onClose : () => {}} className={dialogClasses}>
                <FocusableHiddenElement />
                <div className="min-h-screen px-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <Dialog.Overlay className="fixed inset-0 bg-black/30" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="inline-block h-screen align-middle" aria-hidden="true">
                        &#8203;
                    </span>

                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <div
                            className={classNames(
                                'inline-block transform overflow-hidden rounded-lg bg-white p-0 text-left align-middle shadow-xl transition-all',
                                { 'w-full max-w-lg': variant !== MODAL_VARIANT.NO_ACTION },
                                modalClassName,
                            )}
                            data-test={dataTest}>
                            {variant === MODAL_VARIANT.SINGLE_ACTION && (
                                <>
                                    <div className="px-8 pt-6 pb-9 text-center">
                                        {showIcon && (
                                            <div className="mb-5 flex justify-center">
                                                <div
                                                    className={classNames(
                                                        'flex h-12 w-12 items-center justify-center rounded-full',
                                                        {
                                                            'bg-success-50': !error,
                                                            'bg-error-100': error,
                                                        },
                                                    )}>
                                                    {Icon ? (
                                                        <Icon className={iconClasses} style={iconStyle} />
                                                    ) : (
                                                        <CheckIcon className={iconClasses} style={iconStyle} />
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        {showTitle && (
                                            <Dialog.Title className="heading__sm--medium mb-2">{title}</Dialog.Title>
                                        )}
                                        <Dialog.Description as="div" className="paragraph__sm">
                                            {desc}
                                        </Dialog.Description>
                                    </div>

                                    <div className="bg-neutral-50 p-3 text-center">
                                        <Button
                                            label={actionButtonLabel}
                                            size={BUTTON_SIZE.LG}
                                            className="px-11"
                                            onClick={onActionClick}
                                            data-test="action-button"
                                        />
                                    </div>
                                </>
                            )}

                            {variant === MODAL_VARIANT.TWO_ACTION && (
                                <>
                                    {header && <div className="bg-primary-500 p-5 text-left text-white">{header}</div>}
                                    <div className="flex flex-row items-start">
                                        <div className="w-20 flex-shrink-0 flex-grow-0 pt-6 text-center">
                                            {showIcon && (
                                                <div className="mb-5 flex translate-x-1.5 transform justify-center">
                                                    <div
                                                        className={classNames(
                                                            'align-center flex h-10 w-10 justify-center rounded-full',
                                                            {
                                                                'bg-primary-50': !error,
                                                                'bg-error-100': error,
                                                            },
                                                        )}>
                                                        {Icon ? (
                                                            <Icon className={iconClasses} />
                                                        ) : (
                                                            <ExclamationIcon className={iconClasses} />
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="w-auto flex-shrink flex-grow pb-7 pt-6 pr-7">
                                            {showTitle && (
                                                <Dialog.Title className="heading__sm--medium mb-2">
                                                    {title}
                                                </Dialog.Title>
                                            )}
                                            <Dialog.Description as="div" className="paragraph__sm">
                                                {desc}
                                            </Dialog.Description>
                                        </div>
                                    </div>
                                    <div className="flex justify-end space-x-3 bg-neutral-50 py-3 px-6">
                                        <WhiteButton
                                            label={cancelButtonLabel}
                                            size={BUTTON_SIZE.MD}
                                            data-test="cancel-button"
                                            onClick={typeof onCancel === 'function' ? onCancel : () => {}}
                                        />
                                        {error ? (
                                            <CancelButton
                                                label={actionButtonLabel}
                                                size={BUTTON_SIZE.MD}
                                                data-test="action-button"
                                                onClick={typeof onActionClick === 'function' ? onActionClick : () => {}}
                                            />
                                        ) : (
                                            <Button
                                                label={actionButtonLabel}
                                                size={BUTTON_SIZE.MD}
                                                data-test="action-button"
                                                onClick={typeof onActionClick === 'function' ? onActionClick : () => {}}
                                            />
                                        )}
                                    </div>
                                </>
                            )}

                            {variant === MODAL_VARIANT.FORM && (
                                <>
                                    <div className="bg-primary-500 p-5 text-left text-white">
                                        {showTitle && (
                                            <Dialog.Title
                                                className={classNames('heading__sm--semibold', { 'mb-2': desc })}>
                                                {title}
                                            </Dialog.Title>
                                        )}
                                        <Dialog.Description as="div" className="paragraph__sm">
                                            {desc}
                                        </Dialog.Description>
                                    </div>
                                    <div className={padding !== '' ? padding : 'p-6'}>{form}</div>
                                    <div className="flex justify-end space-x-3 border-t border-neutral-100 py-3 px-6">
                                        {!hideCancel && (
                                            <WhiteButton
                                                label={cancelButtonLabel}
                                                size={BUTTON_SIZE.MD}
                                                data-test="cancel-button"
                                                onClick={typeof onCancel === 'function' ? onCancel : () => {}}
                                            />
                                        )}
                                        <Button
                                            label={actionButtonLabel}
                                            size={BUTTON_SIZE.MD}
                                            data-test="action-button"
                                            onClick={typeof onActionClick === 'function' ? onActionClick : () => {}}
                                        />
                                    </div>
                                </>
                            )}

                            {variant === MODAL_VARIANT.SIMPLE && (
                                <>
                                    {showTitle && (
                                        <div className="p-5 text-center">
                                            <Dialog.Title className="heading__xs--semibold">{title}</Dialog.Title>
                                        </div>
                                    )}
                                    <div className="px-5 pb-5 text-center">
                                        <Dialog.Description as="div" className="paragraph__sm">
                                            {desc}
                                        </Dialog.Description>
                                    </div>
                                    <div className="flex justify-between px-5 pb-5">
                                        <button
                                            type="button"
                                            className="paragraph__sm border-0 bg-transparent"
                                            onClick={typeof onCancel === 'function' ? onCancel : () => {}}>
                                            {cancelButtonLabel}
                                        </button>
                                        <button
                                            type="button"
                                            className="paragraph__sm border-0 bg-transparent text-error-400"
                                            onClick={typeof onActionClick === 'function' ? onActionClick : () => {}}>
                                            {actionButtonLabel}
                                        </button>
                                    </div>
                                </>
                            )}

                            {variant === MODAL_VARIANT.NO_ACTION && (
                                <div className={padding !== '' ? padding : 'p-6'}>{form}</div>
                            )}
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    );
}

export function SingleActionModal({
    padding,
    show,
    showTitle,
    title,
    desc,
    error,
    icon,
    iconClassName,
    iconStyle,
    showIcon,
    onClose,
    actionButtonComponent,
    actionButtonLabel,
    onActionClick,
    className,
    modalClassName,
    'data-test': dataTest,
}) {
    return (
        <BaseModal
            padding={padding}
            variant={MODAL_VARIANT.SINGLE_ACTION}
            show={show}
            showTitle={showTitle}
            error={error}
            icon={icon}
            iconClassName={iconClassName}
            iconStyle={iconStyle}
            title={title}
            desc={desc}
            showIcon={showIcon}
            onClose={onClose}
            actionButtonComponent={actionButtonComponent}
            actionButtonLabel={actionButtonLabel}
            onActionClick={onActionClick}
            className={className}
            modalClassName={modalClassName}
            data-test={dataTest}
        />
    );
}

export function TwoActionModal({
    padding,
    show,
    showTitle,
    header,
    title,
    desc,
    error,
    icon,
    iconClassName,
    iconStyle,
    onClose,
    cancelButtonLabel,
    onCancel,
    actionButtonComponent,
    actionButtonLabel,
    onActionClick,
    className,
    modalClassName,
    'data-test': dataTest,
}) {
    return (
        <BaseModal
            padding={padding}
            variant={MODAL_VARIANT.TWO_ACTION}
            show={show}
            showTitle={showTitle}
            error={error}
            icon={icon}
            iconClassName={iconClassName}
            iconStyle={iconStyle}
            header={header}
            title={title}
            desc={desc}
            onClose={onClose}
            cancelButtonLabel={cancelButtonLabel}
            onCancel={onCancel}
            actionButtonComponent={actionButtonComponent}
            actionButtonLabel={actionButtonLabel}
            onActionClick={onActionClick}
            className={className}
            modalClassName={modalClassName}
            data-test={dataTest}
        />
    );
}

export function FormModal({
    padding,
    show,
    showTitle,
    title,
    desc,
    form,
    onCancel,
    actionButtonComponent,
    actionButtonLabel,
    onActionClick,
    hideCancel,
    className,
    modalClassName,
    'data-test': dataTest,
}) {
    return (
        <BaseModal
            padding={padding}
            variant={MODAL_VARIANT.FORM}
            show={show}
            showTitle={showTitle}
            title={title}
            desc={desc}
            form={form}
            showIcon={false}
            onClose={null}
            onCancel={onCancel}
            actionButtonLabel={actionButtonLabel}
            actionButtonComponent={actionButtonComponent}
            onActionClick={onActionClick}
            hideCancel={hideCancel}
            className={className}
            modalClassName={modalClassName}
            data-test={dataTest}
        />
    );
}

export function SimpleModal({
    padding,
    show,
    showTitle,
    title,
    desc,
    onClose,
    onCancel,
    actionButtonComponent,
    actionButtonLabel,
    onActionClick,
    className,
    modalClassName,
}) {
    return (
        <BaseModal
            padding={padding}
            variant={MODAL_VARIANT.SIMPLE}
            show={show}
            showTitle={showTitle}
            title={title}
            desc={desc}
            showIcon={false}
            onClose={onClose}
            onCancel={onCancel}
            actionButtonComponent={actionButtonComponent}
            actionButtonLabel={actionButtonLabel}
            onActionClick={onActionClick}
            className={className}
            modalClassName={modalClassName}
        />
    );
}

export function NoActionModal({ show, form, onClose, padding, className, modalClassName }) {
    return (
        <BaseModal
            variant={MODAL_VARIANT.NO_ACTION}
            show={show}
            form={form}
            onClose={onClose}
            padding={padding}
            className={className}
            modalClassName={modalClassName}
        />
    );
}
