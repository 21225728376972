/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useAuth } from '@/auth/AuthProvider';
import { isEmailValid, isPasswordValid } from '@/auth/helpers';
import { CardWithFooter } from '@/auth/Cards';
import PasswordValidator from '@/auth/PasswordValidator';
import ConfirmationCodeInput, { verifyCode } from '@/auth/ConfirmationCodeInput';
import TextInput, {
    TEXT_INPUT_TYPE,
    TEXT_INPUT_AUTO_CAPITALIZE,
    TEXT_INPUT_AUTO_CORRECT,
    TEXT_INPUT_SPELL_CHECK,
} from '@/system/TextInput';
import { AUTHENTICATION_TYPES, ROUTES } from '@/util/constants';

export default function ConfirmForgotPassword() {
    const isMounted = useRef(true);
    const { confirmPasswordReset } = useAuth();
    const [loading, setLoading] = useState(false);
    const [otp, setOtp] = useState('');
    const [otpErrorMessage, setOtpErrorMessage] = useState('');
    const [password, setPassword] = useState('');
    const [checkPassword, setCheckPassword] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [checkConfirmPassword, setCheckConfirmPassword] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] = useState('');
    const [apiErrorMessage, setApiErrorMessage] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const email = location.state?.email;

    const validatePassword = (value) => {
        let passed = true;
        if (apiErrorMessage) setApiErrorMessage('');
        if (!checkPassword) setCheckPassword(true);
        if (passwordError) passed = false;
        if (!value || !isPasswordValid(value)) {
            setPasswordError(true);
            passed = false;
        } else {
            setPasswordError(false);
        }
        if (checkConfirmPassword) {
            if (value !== confirmPassword) {
                setConfirmPasswordError(true);
                setConfirmPasswordErrorMessage('passwords do not match');
                if (confirmPassword) passed = false;
            } else {
                setConfirmPasswordError(false);
                setConfirmPasswordErrorMessage('');
            }
        }
        return passed;
    };

    const handlePasswordChange = (e) => {
        const { value: newPassword } = e.target;
        setPassword(newPassword);
        validatePassword(newPassword);
    };

    const validateConfirmPassword = (value) => {
        let passed = true;
        if (apiErrorMessage) setApiErrorMessage('');
        if (!checkConfirmPassword) setCheckConfirmPassword(true);
        if (confirmPasswordError && confirmPasswordErrorMessage) passed = false;
        if (!value) {
            setConfirmPasswordError(true);
            passed = false;
        } else {
            setConfirmPasswordError(false);
        }
        if (value !== password) {
            setConfirmPasswordError(true);
            setConfirmPasswordErrorMessage('passwords do not match');
            passed = false;
        } else {
            setConfirmPasswordErrorMessage('');
        }
        return passed;
    };

    const validateOtp = (value) => {
        const error = verifyCode(value);
        setOtpErrorMessage(error);
        if (error) {
            return false;
        }
        return true;
    };

    const handleConfirmPasswordChange = (e) => {
        const { value: newConfirmPassword } = e.target;
        setConfirmPassword(newConfirmPassword);
        validateConfirmPassword(newConfirmPassword);
    };

    const checkFields = () => {
        let passed = true;
        if (!validatePassword(password)) passed = false;
        if (!validateConfirmPassword(confirmPassword)) passed = false;
        if (!validateOtp(otp)) passed = false;
        return passed;
    };

    const handleResetPasswordClick = (e) => {
        e.preventDefault();
        if (!checkFields()) return;
        setLoading(true);
        confirmPasswordReset(email, password, otp)
            .then(() => {
                navigate(`${ROUTES.STUDENT.LOGIN}?login_type=${AUTHENTICATION_TYPES.EMAIL}`);
            })
            .catch((error) => {
                if (isMounted.current) {
                    setApiErrorMessage(error.message);
                    setLoading(false);
                }
            });
    };

    useEffect(() => {
        isMounted.current = true;
        if (!email) setApiErrorMessage('email is required');
        if (!isEmailValid(email)) setApiErrorMessage('email is not valid');
        return () => {
            isMounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <CardWithFooter
            loading={loading}
            onSubmit={handleResetPasswordClick}
            buttonLabel="Reset password"
            apiErrorMessage={apiErrorMessage}
            footer={
                <div className="caption">
                    Entered the wrong email address? Click{' '}
                    <Link className="caption--semibold" to={ROUTES.COMMON.FORGOT_PASSWORD} state={{ email }} replace>
                        here
                    </Link>{' '}
                    to change it.
                </div>
            }
            className="max-w-sm">
            <div className="heading__md--medium mb-6">Reset Password</div>
            <div className="paragraph__sm mb-6">
                Almost done! Please confirm your account by entering the 6-digit number we just sent to your email then
                enter a new password.
            </div>
            <div className="mb-3.5">
                <ConfirmationCodeInput onKeyUp={(value) => setOtp(value)} errorMessage={otpErrorMessage} />
            </div>
            <TextInput
                value={password}
                placeholder="password"
                type={TEXT_INPUT_TYPE.PASSWORD}
                onChange={handlePasswordChange}
                required
                error={checkPassword && passwordError}
                autoCorrect={TEXT_INPUT_AUTO_CORRECT.OFF}
                autoCapitalize={TEXT_INPUT_AUTO_CAPITALIZE.NONE}
                spellCheck={TEXT_INPUT_SPELL_CHECK.FALSE}
            />
            <TextInput
                value={confirmPassword}
                placeholder="confirm password"
                type={TEXT_INPUT_TYPE.PASSWORD}
                onChange={handleConfirmPasswordChange}
                required
                error={checkConfirmPassword && confirmPasswordError}
                errorMsg={confirmPasswordErrorMessage}
                autoCorrect={TEXT_INPUT_AUTO_CORRECT.OFF}
                autoCapitalize={TEXT_INPUT_AUTO_CAPITALIZE.NONE}
                spellCheck={TEXT_INPUT_SPELL_CHECK.FALSE}
            />
            <div className="paragraph__sm mb-4 pt-2">* indicates a required field</div>
            <PasswordValidator validate={checkPassword} password={password} />
        </CardWithFooter>
    );
}
