/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-boolean-value */
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Transition } from '@headlessui/react';
import { SecondaryButton, BUTTON_SIZE } from '@/system/Buttons';
import { ThinkificButton, GoogleButton, TeachableButton } from '@/system/BrandedButtons';
import EmailLogin from '@/auth/EmailLogin';
import ThinkificLogin from '@/auth/ThinkificLogin';
import {
    getAnimationProps,
    getThinkificLoginSsoParams,
    getGoogleLoginSsoParams,
    getTeachableLoginUrl,
} from '@/util/helpers';
import useQueryParameter from '@/util/useQueryParameter';
import LoadingSpinner from '@/system/LoadingSpinner';
import {
    AUTHENTICATION_TYPES,
    ROLES,
    ROUTES,
    SSO_ID_QUERY_PARAM,
    SSO_TYPES,
    SSO_TYPE_QUERY_PARAM,
    GOOGLE_AUTHORIZE_URL,
} from '@/util/constants';
import { getSearch } from '@/api/helpers';
import TeachableLogin from '@/auth/TeachableLogin';

export default function Login() {
    const isMounted = useRef(true);
    const [loading, setLoading] = useState(true);
    const [redirecting, setRedirecting] = useState(false);
    const [signinType, setSigninType] = useState(null);
    const loginType = useQueryParameter('login_type');

    const handleEmailLoginClick = () => {
        setSigninType(AUTHENTICATION_TYPES.EMAIL);
    };

    const handleThinkificLoginClick = () => {
        setSigninType(AUTHENTICATION_TYPES.THINKIFIC);
    };

    const handleTeachableLoginClick = () => {
        setSigninType(AUTHENTICATION_TYPES.TEACHABLE);
    };

    const handleGoogleLoginClick = async () => {
        setRedirecting(true);
        const search = getSearch({
            [SSO_TYPE_QUERY_PARAM]: SSO_TYPES.GOOGLE,
        });
        window.location.href = `${GOOGLE_AUTHORIZE_URL}${getSearch(
            getGoogleLoginSsoParams(`${ROUTES.CREATOR.WORKBOOKS_DASHBOARD}${search}`, ROLES.CREATOR),
        )}`;
    };

    const getSsoParams = (domain, ssoPlatform = 'thinkific') => {
        const search = getSearch({
            [SSO_TYPE_QUERY_PARAM]: SSO_TYPES.THINKIFIC,
            [SSO_ID_QUERY_PARAM]: domain,
        });
        switch (ssoPlatform) {
            case 'thinkific':
                return getThinkificLoginSsoParams(`${ROUTES.CREATOR.WORKBOOKS_DASHBOARD}${search}`, ROLES.CREATOR);
            case 'teachable':
                return getTeachableLoginUrl(domain);
            default:
                return null;
        }
    };

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (isMounted.current) {
            setSigninType(loginType);
            setLoading(false);
        }
    }, [loginType]);

    if (loading) return <LoadingSpinner />;

    return (
        <>
            {redirecting && <LoadingSpinner />}
            {!signinType && (
                <div className="absolute top-1/2 left-1/2 flex min-w-[80%] -translate-x-1/2 -translate-y-1/2 flex-col items-center justify-center lg:min-w-full">
                    <img src="/images/logo.svg" className="mb-10 w-32" alt="Logo" />
                    <div className="flex flex-col items-center justify-center">
                        <p className="heading__md--medium mb-8.5">Log in to your account</p>
                        <div className="grid grid-cols-1 gap-4">
                            <GoogleButton
                                label="Google"
                                onClick={handleGoogleLoginClick}
                                size={BUTTON_SIZE.LG}
                                className="w-[360px]"
                            />
                            <SecondaryButton
                                label="Sign in with Email"
                                onClick={handleEmailLoginClick}
                                size={BUTTON_SIZE.LG}
                                className="w-[360px]"
                                data-test="email-sign-in-button"
                            />
                        </div>

                        <p className="caption my-6">or</p>

                        <div className="mb-8.5 flex flex-col items-center justify-center space-y-3">
                            <ThinkificButton
                                label="Continue with Thinkific"
                                onClick={handleThinkificLoginClick}
                                size={BUTTON_SIZE.LG}
                                className="w-[360px]"
                            />
                            <TeachableButton
                                label="Continue with Teachable"
                                onClick={handleTeachableLoginClick}
                                size={BUTTON_SIZE.LG}
                                className="w-[360px]"
                            />
                        </div>

                        <div className="paragraph__sm text-center">
                            <span className="mr-1">Don&apos;t have an account?</span>
                            <Link className="paragraph__sm--semibold" to={ROUTES.CREATOR.SIGNUP}>
                                Sign up!
                            </Link>
                        </div>
                    </div>
                </div>
            )}

            <Transition
                show={signinType === AUTHENTICATION_TYPES.EMAIL}
                {...getAnimationProps(
                    { transition: 'duration-300 ease-in', from: 'opacity-0', to: 'opacity-100' },
                    { transition: 'duration-300 ease-out', from: 'opacity-100', to: 'opacity-0' },
                )}>
                <EmailLogin fromUrl={ROUTES.CREATOR.WORKBOOKS_DASHBOARD} creator />
            </Transition>
            <Transition
                show={signinType === AUTHENTICATION_TYPES.THINKIFIC}
                {...getAnimationProps(
                    { transition: 'duration-300 ease-in', from: 'opacity-0', to: 'opacity-100' },
                    { transition: 'duration-300 ease-out', from: 'opacity-100', to: 'opacity-0' },
                )}>
                <ThinkificLogin getSsoParams={getSsoParams} />
            </Transition>
            <Transition
                show={signinType === AUTHENTICATION_TYPES.TEACHABLE}
                {...getAnimationProps(
                    { transition: 'duration-300 ease-in', from: 'opacity-0', to: 'opacity-100' },
                    { transition: 'duration-300 ease-out', from: 'opacity-100', to: 'opacity-0' },
                )}>
                <TeachableLogin getLoginUrl={getSsoParams} />
            </Transition>
        </>
    );
}
