export default function ModuleTitle({ title, workbookSchema }) {
    return (
        <h1
            className="mb-10 text-4xl"
            style={{
                fontFamily: workbookSchema.textStyling.title.fontFamily,
                color: workbookSchema.textStyling.title.color,
            }}>
            {title}
        </h1>
    );
}
