import { createContext, useContext, useEffect, useRef, useState } from 'react';
import merge from 'lodash/merge';
import get from 'lodash/get';
import { useParams } from 'react-router-dom';
import usePublicApi from '@/api/usePublicApi';
import useWorkbookVersionReducer, { getModuleVersionAndResponse, ACTIONS } from '@/workbook/useWorkbookVersionReducer';
import { workbookVersionFromDto, moduleVersionFromDto, addOnsFromDto } from '@/api/dtos';
import { TOAST_POSITION, useToast } from '@/system/ToastProvider';
import { getFakeModuleResponse, getStorageObjectBasePath, getArtifactOptionalInfo, deepCopy } from '@/util/helpers';
import {
    ARTIFACT_SAMPLE_STUDENT,
    CREATOR_ID_QUERY_PARAM,
    WORKBOOK_ID_QUERY_PARAM,
    WORKBOOK_VERSION_ID_QUERY_PARAM,
} from '@/util/constants';
import { getDefaultCreatorSchema } from '@/navigation/useRolesReducer';

const WorkbookVersionContext = createContext();

export function useWorkbookVersion() {
    return useContext(WorkbookVersionContext);
}

export default function WorkbookVersionProvider({ children }) {
    const isMounted = useRef(true);
    const {
        [CREATOR_ID_QUERY_PARAM]: creatorId,
        [WORKBOOK_ID_QUERY_PARAM]: workbookId,
        [WORKBOOK_VERSION_ID_QUERY_PARAM]: workbookVersionId,
    } = useParams();
    const [workbookVersion, setWorkbookVersion] = useWorkbookVersionReducer();
    const [loading, setLoading] = useState(true);
    const [loadingError, setLoadingError] = useState('');
    const [creator, setCreator] = useState({});
    // The workbookArtifactDownloadUrl state is stored at the provider level because we
    // don't want to regenerate an artifact unnecessarily. Once an artifact is generated
    // we don't need to regenerate a new one until a new response is made.
    const [workbookArtifactDownloadUrl, setWorkbookArtifactDownloadUrl] = useState(null);
    const {
        getWorkbookVersion,
        getWorkbookVersionModules,
        getCreator,
        createCreatorWorkbookArtifactSignedUrl,
        createCreatorWorkbookModuleArtifactSignedUrl,
    } = usePublicApi();
    const { pushError } = useToast(TOAST_POSITION.BOTTOM);

    const selectModule = (moduleVersionId) => {
        setWorkbookVersion({ type: ACTIONS.SELECT_MODULE, payload: moduleVersionId });
    };

    const selectPrevModule = () => {
        setWorkbookVersion({ type: ACTIONS.SELECT_PREV_MODULE });
    };

    const selectNextModule = () => {
        setWorkbookVersion({ type: ACTIONS.SELECT_NEXT_MODULE });
    };

    const selectSection = (sectionId) => {
        setWorkbookVersion({ type: ACTIONS.SELECT_SECTION, payload: sectionId });
    };

    const setSectionResponseProp = (sectionId, path, value) => {
        setWorkbookVersion({ type: ACTIONS.UPDATE_SECTION_RESPONSE_PROP, payload: { sectionId, path, value } });
    };

    const updateResponseFile = (sectionId, path, fileData) => {
        const previousValue = deepCopy(
            get(workbookVersion.selectedModuleResponse.sectionResponses, `${sectionId}.${path}`) || {},
        );
        if (previousValue.storage_filename) URL.revokeObjectURL(previousValue.storage_filename);
        const value = {
            filename: fileData ? fileData.name : '',
            storage_filename: fileData ? fileData.url : '',
        };
        setWorkbookVersion({
            type: ACTIONS.UPDATE_SECTION_RESPONSE_PROP,
            payload: { sectionId, path, value },
        });
    };

    const saveResponseFile = (sectionId, path, fileData) => updateResponseFile(sectionId, path, fileData);

    const resetResponseFile = (sectionId, path) => updateResponseFile(sectionId, path);

    const generateWorkbookArtifact = async () => {
        const optionalInfo = getArtifactOptionalInfo(creator?.schema, ARTIFACT_SAMPLE_STUDENT);

        return createCreatorWorkbookArtifactSignedUrl(creatorId, workbookId, optionalInfo)
            .then((dto) => {
                if (dto.url) {
                    return fetch(dto.url)
                        .then((res) => res.blob())
                        .then((blob) => {
                            const objectUrl = URL.createObjectURL(blob);
                            setWorkbookArtifactDownloadUrl(objectUrl);
                            return dto;
                        })
                        .catch((err) => {
                            pushError('Could not access PDF', null);
                            console.error(err);
                            throw err;
                        });
                }
                return dto;
            })
            .catch((err) => {
                pushError('Could not create PDF', null);
                console.error(err);
                throw err;
            });
    };

    const generateWorkbookModuleArtifact = async (moduleVersionId) => {
        const optionalInfo = getArtifactOptionalInfo(creator?.schema, ARTIFACT_SAMPLE_STUDENT);
        const { module } = getModuleVersionAndResponse(workbookVersion, moduleVersionId);
        return createCreatorWorkbookModuleArtifactSignedUrl(creatorId, workbookId, module.moduleId, optionalInfo)
            .then((dto) => {
                if (dto.url) {
                    return fetch(dto.url)
                        .then((res) => res.blob())
                        .then((blob) => {
                            const objectUrl = URL.createObjectURL(blob);
                            return { url: objectUrl };
                        })
                        .catch((err) => {
                            pushError('Could not access PDF', null);
                            console.error(err);
                            throw err;
                        });
                }
                return dto;
            })
            .catch((err) => {
                pushError('Could not create PDF', null);
                console.error(err);
                throw err;
            });
    };

    const loadData = async () => {
        const payload = {
            workbookVersion: {},
            modules: [],
            moduleResponses: [],
        };
        const workbookVersionDto = await getWorkbookVersion(creatorId, workbookId, workbookVersionId);
        payload.workbookVersion = workbookVersionFromDto(workbookVersionDto);
        payload.creator = await getCreator(creatorId).then((dto) => {
            const schema = getDefaultCreatorSchema();
            merge(schema, dto.schema);
            return {
                schema,
                addOns: addOnsFromDto(dto.add_ons),
            };
        });
        payload.moduleResponses = [];
        if (payload.workbookVersion.moduleVersionIds.length > 0) {
            const moduleVersionDtos = await getWorkbookVersionModules(creatorId, workbookId, workbookVersionId).catch(
                (error) => {
                    if (isMounted.current) {
                        const fullError = `Error getting modules: ${error.message}`;
                        pushError(fullError, null);
                        throw new Error(fullError);
                    }
                },
            );
            if (moduleVersionDtos && moduleVersionDtos.length > 0) {
                payload.modules = moduleVersionDtos.map((dto) => {
                    const moduleVersion = moduleVersionFromDto(dto);
                    payload.moduleResponses.push(getFakeModuleResponse(moduleVersion.moduleId));
                    return moduleVersion;
                });
            }
        }
        return payload;
    };

    useEffect(() => {
        const revokeUrl = workbookArtifactDownloadUrl;
        return () => {
            if (revokeUrl) URL.revokeObjectURL(revokeUrl);
        };
    }, [workbookArtifactDownloadUrl]);

    useEffect(() => {
        isMounted.current = true;
        if (isMounted.current) {
            setLoading(true);
            loadData()
                .then((response) => {
                    const cohortId =
                        response.workbookVersion.cohortIds && response.workbookVersion.cohortIds.length > 0
                            ? response.workbookVersion.cohortIds[0]
                            : '';
                    const payload = {
                        ...response,
                        workbookVersion: {
                            ...response.workbookVersion,
                            schema: {
                                ...response.workbookVersion.schema,
                                storageObjectBasePath: getStorageObjectBasePath(
                                    response.workbookVersion.creatorId,
                                    response.workbookVersion.workbookId,
                                    response.workbookVersion.id,
                                ),
                                workbookUploadsStorageObjectBasePath: '',
                            },
                        },
                        cohortId,
                    };
                    if (isMounted.current) {
                        setCreator(response.creator);
                        setWorkbookVersion({ type: ACTIONS.SET, payload });
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    if (isMounted.current) {
                        setLoadingError(error.message);
                        setLoading(false);
                    }
                });
        }
        return () => {
            isMounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const context = {
        workbookVersion,
        loading,
        loadingError,
        saving: false,
        creator,
        selectModule,
        selectPrevModule,
        selectNextModule,
        selectSection,
        setSectionResponseProp,
        generateWorkbookArtifact,
        generateWorkbookModuleArtifact,
        workbookArtifactDownloadUrl,
        saveResponseFile,
        resetResponseFile,
    };

    return <WorkbookVersionContext.Provider value={context}>{children}</WorkbookVersionContext.Provider>;
}
