import { CheckCircleIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';

const SPINNER_TIMEOUT = 1500;

const SavingIcon = ({ saving, showCheckIcon = true }) => {
    const [spinning, setSpinning] = useState(false);
    const [saveCount, setSaveCount] = useState(0);

    useEffect(() => {
        if (saving) setSaveCount(saveCount + 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saving]);

    useEffect(() => {
        if (saveCount > 0) {
            setSpinning(true);
            const timeOutId = setTimeout(() => setSpinning(false), SPINNER_TIMEOUT);
            return () => clearTimeout(timeOutId);
        }
        return () => {};
    }, [saveCount]);

    return (
        <div className="flex h-full w-full justify-center">
            {spinning ? (
                <div className="h-[80%] w-[80%]">
                    <div className="h-full w-full animate-spin rounded-full border-[1px] border-t-neutral-500 border-l-neutral-500 border-b-neutral-500 border-r-transparent" />
                </div>
            ) : (
                showCheckIcon && (
                    <div className="h-full w-full">
                        <CheckCircleIcon className="h-full w-full -translate-y-0.5" />
                    </div>
                )
            )}
        </div>
    );
};

export default SavingIcon;
