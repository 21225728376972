/* eslint-disable react/jsx-boolean-value */
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '@/system/LoadingSpinner';
import TechnicalError from '@/system/TechnicalError';
import { useAuth } from '@/auth/AuthProvider';
import { useEmbed } from '@/embed/EmbedProvider';
import useQueryParameter from '@/util/useQueryParameter';
import { ROLE_QUERY_PARAM, ROUTES, ERROR_QUERY_PARAM, FROM_URL_QUERY_PARAM } from '@/util/constants';
import { appendQueryParams } from '@/util/helpers';

export default function CustomSSOAuthComplete() {
    const isMounted = useRef(true);
    const navigate = useNavigate();
    const { completeEmbedLoginV2 } = useAuth();
    const { embedded } = useEmbed();
    const ssoError = useQueryParameter(ERROR_QUERY_PARAM);
    const fromUrl = useQueryParameter(FROM_URL_QUERY_PARAM);
    const role = useQueryParameter(ROLE_QUERY_PARAM);
    const fromUrlWithRole = appendQueryParams(fromUrl, { role });

    useEffect(() => {
        isMounted.current = true;
        if (embedded) {
            if (!ssoError) {
                const defaultUrl = `${ROUTES.COMMON.BASE}?embed=true`;
                completeEmbedLoginV2()
                    .then(() => {
                        navigate(fromUrlWithRole || appendQueryParams(defaultUrl, { role }));
                    })
                    .catch((error) => {
                        if (isMounted.current) {
                            console.error(error.message);
                        }
                    });
            } else {
                console.error(ssoError);
            }
        }
        return () => {
            isMounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!embedded)
        return (
            <TechnicalError
                title="Native mode not supported"
                desc="URL must specify embed mode; embed query param must be true"
                buttonLabel={null}
            />
        );

    if (ssoError)
        return <TechnicalError title="SSO error" desc={`Error completing SSO: ${ssoError}`} buttonLabel={null} />;
    return <LoadingSpinner />;
}
