import { useState, useEffect } from 'react';
import classNames from 'classnames';
import { TYPES, useEmbed } from '@/embed/EmbedProvider';
import ThinkificSso from '@/embed/ThinkificSso';
import TechnicalError from '@/system/TechnicalError';
import { getSearch } from '@/api/helpers';
import LoadingSpinner from '@/system/LoadingSpinner';
import { getGoogleStudentEmbedSsoParams } from '@/util/helpers';
import {
    BACKEND_ERRORS,
    ROUTES,
    EMBED_CONTEXTS,
    GOOGLE_AUTHORIZE_URL,
    CREATOR_ROUTE_BASE_PREFIX,
} from '@/util/constants';
import { GoogleButton } from '@/system/BrandedButtons';
import { SecondaryButton } from '@/system/Buttons';
import EmailLogin from '@/auth/EmailLogin';
import Divider from '@/system/Divider';

export const SSO_ERROR_ACTION = 'sso_error_action';

const popupWidth = 450;
const popupHeight = 575;
const maxWaitingTime = 45000;

function OtherSso() {
    const { originalLocation, context: embedContext } = useEmbed();
    const [popupWindow, setPopupWindow] = useState(null);
    const [popupWindowTimer, setPopupWindowTimer] = useState(null);
    const [retry, setRetry] = useState(false);
    const [error, setError] = useState('');
    const searchParams = new URLSearchParams(originalLocation.search);
    const workbookId = searchParams.get('workbook_id') || '';
    const originalUrl = `${originalLocation.pathname}${originalLocation.search}`;
    const isCreatorSso = originalLocation.pathname.startsWith(CREATOR_ROUTE_BASE_PREFIX);

    const handleClick = () => {
        const params = getSearch(getGoogleStudentEmbedSsoParams(originalUrl || ROUTES.COMMON.BASE, workbookId));
        const oauthUrl = `${GOOGLE_AUTHORIZE_URL}${params}`;
        const newPopupWindow = window.open(
            oauthUrl,
            'Google SSO',
            `toolbar=no, resizable=no, width=${popupWidth}, height=${popupHeight}`,
        );
        setPopupWindow(newPopupWindow);
    };

    const handleRetryClick = () => {
        setRetry(false);
        handleClick();
    };

    const enableRetry = () => {
        clearTimeout(popupWindowTimer);
        if (popupWindow) popupWindow.close();
        setRetry(true);
        setPopupWindow(null);
        setPopupWindowTimer(null);
    };

    useEffect(() => {
        window.addEventListener('message', (event) => {
            const { action, payload } = event.data;
            if (action === SSO_ERROR_ACTION) {
                if (payload.includes(BACKEND_ERRORS.THINKIFIC_CALLBACK.CREATOR_HAS_NO_ACCESS_TO_PLAN)) {
                    setError("We couldn't find this workbook. Contact your instructor for more information.");
                } else {
                    enableRetry();
                }
            }
        });

        switch (embedContext) {
            case EMBED_CONTEXTS.WORKBOOK_MODULE:
            default:
                if (!workbookId) setError('Workbook id not provided');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (popupWindow) {
            const timer = setTimeout(() => {
                enableRetry();
            }, maxWaitingTime);
            setPopupWindowTimer(timer);
            return () => clearTimeout(timer);
        }
        return () => {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [popupWindow]);

    if (error) return <TechnicalError title="Oops, something went wrong!" desc={error} />;
    if (popupWindow)
        return (
            <LoadingSpinner
                text={
                    <div className="text-center">
                        <div>Please complete your Google SSO in the pop-up window</div>
                    </div>
                }
            />
        );

    return (
        <div className="relative h-full w-full">
            <div
                className={classNames(
                    'absolute left-1/2 my-0 mx-auto block w-11/12 max-w-sm -translate-x-1/2 transform',
                    { 'top-0': !retry, 'top-1/2 -translate-y-1/2': retry },
                )}>
                <div className="flex flex-col items-center top-">
                    {retry ? (
                        <>
                            <div className="paragraph__sm pb-6">Oops! Something went wrong.</div>
                            <SecondaryButton label="Try again" onClick={handleRetryClick} />
                        </>
                    ) : (
                        <>
                            <EmailLogin
                                fromUrl={originalUrl}
                                creator={isCreatorSso}
                                cardProps={{
                                    header: (
                                        <div className="caption mb-6">
                                            Sign in to start filling out the digital workbook for this course.
                                        </div>
                                    ),
                                    hideLogo: true,
                                    secondarySubmitButton: true,
                                    postFormSection: (
                                        <div className="flex flex-col mt-3">
                                            <div className="flex justify-between items-center mb-4">
                                                <Divider className="border-neutral-100" />
                                                <div className=" paragraph__sm text-neutral-400 mx-6">Or</div>
                                                <Divider className="border-neutral-100" />
                                            </div>
                                            <GoogleButton label="Sign in with Google" onClick={handleClick} fullWidth />
                                        </div>
                                    ),
                                }}
                            />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default function EmbedLogin() {
    const { type } = useEmbed();

    switch (type) {
        case TYPES.DEFAULT:
        case TYPES.KAJABI:
        case TYPES.TEACHABLE:
        case TYPES.ZENLER:
            return <OtherSso />;
        case TYPES.THINKIFIC:
            // The ThinkificSso component is only here for backwards compability to support the old
            // method of embedding in the Thinkific course player. It facilitates SSO through
            // a popup window and completes the login using the post message API. The new embed
            // link directly accesses the authorize endpoint of a Thinkific site and doesn't need
            // to be kicked-off in the same manner.
            return <ThinkificSso />;
        default:
            return <TechnicalError title="Error" desc="Embed type not supported" />;
    }
}
