import { useNavigate } from 'react-router-dom';
import { useEmbed } from '@/embed/EmbedProvider';
import { ROUTES } from '@/util/constants';
import TechnicalError from '@/system/TechnicalError';

export default function EmbedTechnicalError({ title, desc, onButtonClick }) {
    const { removeTokens, originalLocation } = useEmbed();
    const navigate = useNavigate();

    const handleButtonClick = () => {
        // Remove the access tokens in parent window context since this should be rendered within an iframe
        removeTokens();
        if (typeof onButtonClick === 'function') {
            onButtonClick();
        }
        navigate(ROUTES.COMMON.EMBED_LOGIN, {
            state: {
                from: originalLocation,
            },
        });
    };

    return <TechnicalError title={title} desc={desc} buttonLabel="Back to login" onButtonClick={handleButtonClick} />;
}
