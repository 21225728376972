import { createContext, useContext, useEffect, useRef, useState } from 'react';
import get from 'lodash/get';
import useWorkbookVersionReducer, { ACTIONS } from '@/workbook/useWorkbookVersionReducer';
import { workbookVersionFromDto, moduleVersionFromDto } from '@/api/dtos';
import { deepCopy, fetchJsonFile, getFakeModuleResponse, getStorageObjectBasePath } from '@/util/helpers';
import useQueryParameter from '@/util/useQueryParameter';

const defaultTemplate = 'default';

const WorkbookVersionContext = createContext();

export function useWorkbookVersion() {
    return useContext(WorkbookVersionContext);
}

export default function WorkbookVersionProvider({ children }) {
    const isMounted = useRef(true);
    const template = useQueryParameter('template');
    const [workbookVersion, setWorkbookVersion] = useWorkbookVersionReducer();
    const [loading, setLoading] = useState(true);
    const [loadingError, setLoadingError] = useState('');

    const selectModule = (moduleVersionId) => {
        setWorkbookVersion({ type: ACTIONS.SELECT_MODULE, payload: moduleVersionId });
    };

    const selectPrevModule = () => {
        setWorkbookVersion({ type: ACTIONS.SELECT_PREV_MODULE });
    };

    const selectNextModule = () => {
        setWorkbookVersion({ type: ACTIONS.SELECT_NEXT_MODULE });
    };

    const selectSection = (sectionId) => {
        setWorkbookVersion({ type: ACTIONS.SELECT_SECTION, payload: sectionId });
    };

    const setSectionResponseProp = async (sectionId, path, value) => {
        return setWorkbookVersion({ type: ACTIONS.UPDATE_SECTION_RESPONSE_PROP, payload: { sectionId, path, value } });
    };

    const updateResponseFile = (sectionId, path, fileData) => {
        const previousValue = deepCopy(
            get(workbookVersion.selectedModuleResponse.sectionResponses, `${sectionId}.${path}`) || {},
        );
        if (previousValue.storage_filename) URL.revokeObjectURL(previousValue.storage_filename);
        const value = {
            filename: fileData ? fileData.name : '',
            storage_filename: fileData ? fileData.url : '',
        };
        setWorkbookVersion({
            type: ACTIONS.UPDATE_SECTION_RESPONSE_PROP,
            payload: { sectionId, path, value },
        });
    };

    const saveResponseFile = (sectionId, path, fileData) => updateResponseFile(sectionId, path, fileData);

    const resetResponseFile = (sectionId, path) => updateResponseFile(sectionId, path);

    const loadWorkbookVersion = async () => {
        const payload = {
            workbookVersion: {},
            modules: [],
            moduleResponses: [],
        };
        const templateToLoad = template || defaultTemplate;
        try {
            const templateJson = await fetchJsonFile(`templates/demo/${templateToLoad}.json`);
            const workbookVersionDto = templateJson.workbookVersion;
            payload.workbookVersion = workbookVersionFromDto(workbookVersionDto);
            payload.moduleResponses = [];
            const moduleVersionDtos = templateJson.moduleVersions;
            payload.modules = moduleVersionDtos.map((dto) => {
                const moduleVersion = moduleVersionFromDto(dto);
                payload.moduleResponses.push(getFakeModuleResponse(moduleVersion.moduleId));
                return moduleVersion;
            });
            return payload;
        } catch (err) {
            throw new Error('Could not load demo');
        }
    };

    useEffect(() => {
        isMounted.current = true;
        if (isMounted.current) {
            setLoading(true);
            loadWorkbookVersion()
                .then((response) => {
                    const cohortId =
                        response.workbookVersion.cohortIds && response.workbookVersion.cohortIds.length > 0
                            ? response.workbookVersion.cohortIds[0]
                            : '';
                    const payload = {
                        ...response,
                        workbookVersion: {
                            ...response.workbookVersion,
                            schema: {
                                ...response.workbookVersion.schema,
                                storageObjectBasePath: getStorageObjectBasePath(
                                    response.workbookVersion.creatorId,
                                    response.workbookVersion.workbookId,
                                    response.workbookVersion.id,
                                ),
                                workbookUploadsStorageObjectBasePath: '',
                            },
                        },
                        cohortId,
                    };
                    if (isMounted.current) {
                        setWorkbookVersion({ type: ACTIONS.SET, payload });
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    if (isMounted.current) {
                        setLoadingError(error.message);
                        setLoading(false);
                    }
                });
        }

        return () => {
            isMounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const context = {
        workbookVersion,
        loading,
        loadingError,
        saving: false,
        selectModule,
        selectPrevModule,
        selectNextModule,
        selectSection,
        setSectionResponseProp,
        saveResponseFile,
        resetResponseFile,
    };

    return <WorkbookVersionContext.Provider value={context}>{children}</WorkbookVersionContext.Provider>;
}
