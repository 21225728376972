/* eslint-disable jsx-a11y/interactive-supports-focus */
import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { TrashIcon, PhotographIcon, ExclamationIcon } from '@heroicons/react/outline';
import TextInputDebounce from '@/system/TextInputDebounce';
import ToggleRow from '@/workbook/builder/sections/ToggleRow';
import RichTextEditorDebounce from '@/system/RichTextEditorDebounce';
import SectionTitle from '@/system/SectionTitle';
import SectionDescription from '@/system/SectionDescription';
import PreviewSuggestionTooltip from '@/workbook/builder/sections/PreviewSuggestionTooltip';
import {
    IMAGE_SIZES,
    FILE_MAX_SIZES_DESC,
    FILE_DESC_TO_SIZE,
    WORKBOOK_SECTION_VIEWER_MODES,
    VALID_IMAGE_MIME_TYPES,
    MAX_IMAGE_WIDTH,
    MAX_IMAGE_HEIGHT,
    ROUTES,
    SETTINGS_SECTIONS,
} from '@/util/constants';
import Divider from '@/system/Divider';
import RadioGroup from '@/system/RadioGroup';
import Label from '@/system/Label';
import ImageUploaderModal from '@/system/imageUploader/ImageUploaderModal';
import {
    getImageWidthBySize,
    getSizeDescription,
    getStorageUrl,
    getUniqueComponentClassName,
    getStudentSectionStorageObjectBasePath,
} from '@/util/helpers';
import { useToast } from '@/system/ToastProvider';
import { DefaultButton, ICON_POSITION } from '@/system/Buttons';
import { APP_STYLES } from '@/system/styles';
import FileLoader from '@/system/imageUploader/FileLoader';
import LoadingSpinner from '@/system/LoadingSpinner';

const radioGroupImageSizes = [
    { id: IMAGE_SIZES.SMALL, label: IMAGE_SIZES.SMALL },
    { id: IMAGE_SIZES.MEDIUM, label: IMAGE_SIZES.MEDIUM },
    { id: IMAGE_SIZES.LARGE, label: IMAGE_SIZES.LARGE },
    { id: IMAGE_SIZES.FULL, label: IMAGE_SIZES.FULL },
];

const radioGroupMaxFileSize = [
    { id: FILE_MAX_SIZES_DESC['1MB'], label: FILE_MAX_SIZES_DESC['1MB'] },
    { id: FILE_MAX_SIZES_DESC['5MB'], label: FILE_MAX_SIZES_DESC['5MB'] },
    { id: FILE_MAX_SIZES_DESC['10MB'], label: FILE_MAX_SIZES_DESC['10MB'] },
];

export const StudentImageUploadEditor = ({ schema, setSectionSchemaProp, saveSectionSchemaProp }) => {
    const { title, description, image, file } = schema;

    const handleTitleToggleChange = () => {
        saveSectionSchemaProp('title.show', !title.show);
    };

    const handleTitleChange = (e) => {
        const { value: text } = e.target;
        setSectionSchemaProp('title.text', text);
    };

    const handleTitleTypingEnd = (text) => {
        saveSectionSchemaProp('title.text', text);
    };

    const handleDescriptionToggleChange = () => {
        saveSectionSchemaProp('description.show', !description.show);
    };

    const handleDescriptionChange = (text) => {
        setSectionSchemaProp('description.text', text);
    };

    const handleDescriptionTypingEnd = (text) => {
        saveSectionSchemaProp('description.text', text);
    };

    const handleImageSizeChange = (value) => {
        saveSectionSchemaProp('image.size', value);
    };

    const handleMaxSizeChange = (value) => {
        saveSectionSchemaProp('file.max_size', value);
    };

    return (
        <>
            <Label desc="Sizing" />
            <RadioGroup
                value={image.size}
                onChange={handleImageSizeChange}
                options={radioGroupImageSizes}
                className="mb-2"
            />
            <Divider />
            <ToggleRow
                label="Title"
                enabled={title.show}
                onChange={handleTitleToggleChange}
                data-test="section-editor-qa-title-toggle"
            />
            <TextInputDebounce
                value={title.text}
                onChange={handleTitleChange}
                onTypingEnd={handleTitleTypingEnd}
                data-test="section-editor-qa-title"
            />
            <ToggleRow
                label="Description"
                enabled={description.show}
                onChange={handleDescriptionToggleChange}
                data-test="section-editor-qa-desc-toggle"
            />

            <RichTextEditorDebounce
                value={description.text}
                onChange={handleDescriptionChange}
                onTypingEnd={handleDescriptionTypingEnd}
                data-test="section-editor-qa-desc"
            />
            <Divider />
            <Label desc="Maximum file upload size" />
            <RadioGroup
                value={file.max_size}
                onChange={handleMaxSizeChange}
                options={radioGroupMaxFileSize}
                className="mb-2"
            />
        </>
    );
};

const initialState = () => ({ show: false, mode: 'upload', data: null });

export const StudentImageUpload = ({
    id,
    student,
    workbookSchema,
    schema,
    mode = WORKBOOK_SECTION_VIEWER_MODES.DEFAULT,
    sectionResponses,
    saveResponseFile,
    resetResponseFile,
    disabled,
}) => {
    const { title, description } = schema;

    if (disabled) {
        if (mode === WORKBOOK_SECTION_VIEWER_MODES.BUILDER) return <DisabledSectionPlaceHolder />;
        return null;
    }

    return (
        <>
            {title.show && (
                <SectionTitle
                    text={title.text}
                    style={{
                        fontFamily: workbookSchema.textStyling.subtitle.fontFamily,
                        color: workbookSchema.textStyling.subtitle.color,
                    }}
                />
            )}
            {description.show && (
                <SectionDescription
                    text={description.text}
                    style={{
                        fontFamily: workbookSchema.textStyling.body.fontFamily,
                        color: workbookSchema.textStyling.body.color,
                    }}
                    data-test="section-qa-desc"
                />
            )}
            <Uploader
                id={id}
                student={student}
                workbookSchema={workbookSchema}
                schema={schema}
                mode={mode}
                sectionResponses={sectionResponses}
                saveResponseFile={saveResponseFile}
                resetResponseFile={resetResponseFile}
            />
        </>
    );
};

export const Uploader = ({
    id,
    student,
    workbookSchema,
    schema,
    mode,
    sectionResponses,
    saveResponseFile,
    resetResponseFile,
}) => {
    const isMounted = useRef(true);
    const { file } = schema;
    const { pushError } = useToast();
    const componentClassName = useRef(getUniqueComponentClassName());
    const [imageUploader, setImageUploader] = useState(initialState);
    const [loadingDirectImage, setLoadingDirectImage] = useState(false);

    const imageSizeStyles = {
        width: getImageWidthBySize(schema.image.size),
    };

    const maxFileSize = file.max_size && FILE_DESC_TO_SIZE[file.max_size];

    let src = '';
    if (
        mode === WORKBOOK_SECTION_VIEWER_MODES.DEFAULT ||
        mode === WORKBOOK_SECTION_VIEWER_MODES.READ_ONLY ||
        mode === WORKBOOK_SECTION_VIEWER_MODES.STUDENT
    ) {
        src = sectionResponses?.file?.storage_filename
            ? getStorageUrl(
                  `${getStudentSectionStorageObjectBasePath(
                      workbookSchema.workbookUploadsStorageObjectBasePath,
                      id,
                      student?.id,
                  )}${sectionResponses?.file?.storage_filename}`,
              )
            : '';
    } else {
        src = sectionResponses?.file?.storage_filename ? sectionResponses.file.storage_filename : '';
    }

    const handleButtonClick = () => {
        setImageUploader({ mode: 'upload', data: null, show: true });
    };

    const handleImageUse = (data) => {
        setImageUploader({ mode: 'uploading', data, show: true });
        saveResponseFile('file', data, 'image').then(() => {
            if (isMounted.current) setImageUploader({ mode: 'uploading', data: null, show: false });
        });
    };

    const handleImageReset = () => {
        setImageUploader(initialState);
        resetResponseFile('file');
    };

    const handleFileLoaded = (data) => {
        setImageUploader({ ...imageUploader, mode: 'edit', data });
    };

    const handleModalClose = () => {
        setImageUploader(initialState);
    };

    const handleDirectImageLoaded = (fileData) => {
        const img = new Image();
        const url = URL.createObjectURL(fileData);
        setLoadingDirectImage(true);
        img.onload = () => {
            if (fileData.size > maxFileSize) {
                pushError(`File size should be smaller than ${getSizeDescription(maxFileSize)}.`, null);
                setLoadingDirectImage(false);
            } else if (img.width > MAX_IMAGE_WIDTH || img.height > MAX_IMAGE_HEIGHT) {
                pushError(
                    `Image dimensions are too large (${img.width} x ${img.height} pixels). Please upload an image under ${MAX_IMAGE_WIDTH} x ${MAX_IMAGE_HEIGHT} pixels.`,
                    null,
                );
                setLoadingDirectImage(false);
            } else {
                const loadedImageData = {
                    name: fileData.name,
                    type: fileData.type,
                    size: fileData.size,
                    url,
                };
                saveResponseFile('file', loadedImageData, 'image').finally(() => {
                    setLoadingDirectImage(false);
                });
            }
        };
        img.src = url;
    };

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);

    return (
        <div className={`${componentClassName.current}`}>
            <style type="text/css">{`
                .${componentClassName.current} .replace-button {
                    font-family: ${workbookSchema.textStyling.body.fontFamily};
                    background-color: ${workbookSchema.buttonStyling.fillColor};
                    color: ${workbookSchema.buttonStyling.textColor};
                }

                .${componentClassName.current} .replace-button:hover {
                    background-color: ${workbookSchema.buttonStyling.hoverColor};
                    color: ${workbookSchema.buttonStyling.hoverTextColor};
                }

                .${componentClassName.current} .replace-button:disabled {
                    background-color: ${APP_STYLES.COLORS.NEUTRAL[300]};
                    color: ${APP_STYLES.COLORS.NEUTRAL[100]};
                }
                .${componentClassName.current} .delete-button {
                    font-family: ${workbookSchema.textStyling.body.fontFamily};
                    background-color: ${workbookSchema.buttonStyling.hoverColor};
                    color: ${workbookSchema.buttonStyling.hoverTextColor};
                }

                .${componentClassName.current} .delete-button:hover {
                    background-color: ${workbookSchema.buttonStyling.fillColor};
                    color: ${workbookSchema.buttonStyling.textColor};
                }

                .fileloader:focus + label {
                    --tw-ring-color: ${workbookSchema.buttonStyling.fillColor};
                    border-color: ${workbookSchema.buttonStyling.fillColor};
                }
            `}</style>

            <>
                <div className="hidden w-full md:block">
                    <div className="flex items-center justify-center w-full">
                        {src && (
                            <div
                                className="flex flex-col rounded-lg border border-dashed border-neutral-100 p-4 min-w-[280px]"
                                style={imageSizeStyles}>
                                <img src={src} alt={schema.title.text} />
                                {mode !== WORKBOOK_SECTION_VIEWER_MODES.READ_ONLY && (
                                    <div className="flex justify-end pt-4">
                                        <div className="caption">
                                            <DefaultButton
                                                label="Replace File"
                                                icon={PhotographIcon}
                                                iconPosition={ICON_POSITION.LEFT}
                                                className="replace-button custom-focus-outline"
                                                onClick={handleButtonClick}
                                                fullWidth
                                            />
                                        </div>
                                        <div className="caption ml-2.5">
                                            <DefaultButton
                                                label="Delete"
                                                onClick={handleImageReset}
                                                icon={TrashIcon}
                                                iconPosition={ICON_POSITION.LEFT}
                                                className="delete-button custom-focus-outline"
                                                data-test="cancel-plan-button"
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                        {!src && mode === WORKBOOK_SECTION_VIEWER_MODES.READ_ONLY && (
                            <PdfImagePlaceHolder style={imageSizeStyles} />
                        )}
                        {!src && mode !== WORKBOOK_SECTION_VIEWER_MODES.READ_ONLY && (
                            <UploadImagePlaceHolder onClick={handleButtonClick} style={imageSizeStyles} mode={mode} />
                        )}
                    </div>
                    <ImageUploaderModal
                        show={imageUploader.show}
                        mode={imageUploader.mode}
                        maxFileSize={file.max_size && FILE_DESC_TO_SIZE[file.max_size]}
                        imageData={imageUploader.data}
                        onUse={handleImageUse}
                        onFileLoaded={handleFileLoaded}
                        onClose={handleModalClose}
                        onFileLoaderError={(error) => pushError(error, null)}
                    />
                </div>
                <div className="w-full md:hidden">
                    <div className="flex items-center justify-center w-full">
                        {src && (
                            <div
                                className="flex flex-col rounded-lg border border-dashed border-neutral-100 p-4 min-w-[280px]"
                                style={imageSizeStyles}>
                                {loadingDirectImage && (
                                    <div className="relative w-full h-full">
                                        <LoadingSpinner />
                                    </div>
                                )}
                                <img src={src} alt={schema.title.text} />
                                {mode !== WORKBOOK_SECTION_VIEWER_MODES.READ_ONLY && (
                                    <div className="flex justify-end pt-4">
                                        <div className="caption">
                                            <FileLoader
                                                typeButton
                                                buttonText="Replace File"
                                                buttonClassName="replace-button flex text-center shadow-sm text-sm py-2 px-4 rounded-md download-button cursor-pointer"
                                                onFileLoaded={handleDirectImageLoaded}
                                                supportedFileTypes={VALID_IMAGE_MIME_TYPES}
                                                onFileLoaderError={(error) => pushError(error, null)}
                                            />
                                        </div>
                                        <div className="caption ml-2.5">
                                            <DefaultButton
                                                label="Delete"
                                                onClick={handleImageReset}
                                                icon={TrashIcon}
                                                iconPosition={ICON_POSITION.LEFT}
                                                className="delete-button custom-focus-outline"
                                                data-test="cancel-plan-button"
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                        {!src && mode === WORKBOOK_SECTION_VIEWER_MODES.READ_ONLY && (
                            <PdfImagePlaceHolder style={imageSizeStyles} />
                        )}
                        {!src && mode !== WORKBOOK_SECTION_VIEWER_MODES.READ_ONLY && (
                            <div className="w-full h-full">
                                {loadingDirectImage && (
                                    <div className="relative w-full h-full">
                                        <LoadingSpinner />
                                    </div>
                                )}
                                <FileLoader
                                    title="Upload a file"
                                    buttonText="Upload"
                                    buttonClassName="replace-button text-center shadow-sm focus:outline-none focus:ring-offset-2 focus:ring-2 text-sm py-2 px-4 rounded-md download-button custom-focus-outline cursor-pointer mt-3.5"
                                    onFileLoaded={handleDirectImageLoaded}
                                    constraintText="Files must be in .png or .jpg format"
                                    supportedFileTypes={VALID_IMAGE_MIME_TYPES}
                                    onFileLoaderError={(error) => pushError(error, null)}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </>
        </div>
    );
};

export const StudentImageUploadArtifact = ({ id, student, workbookSchema, schema, sectionResponses }) => {
    const { title, description } = schema;

    const imageSizeStyles = {
        width: getImageWidthBySize(schema.image.size),
    };

    const src = sectionResponses?.file?.storage_filename
        ? getStorageUrl(
              `${getStudentSectionStorageObjectBasePath(
                  workbookSchema.workbookUploadsStorageObjectBasePath,
                  id,
                  student.id,
              )}${sectionResponses?.file?.storage_filename}`,
          )
        : '';

    return (
        <>
            {title.show && (
                <SectionTitle
                    text={title.text}
                    style={{
                        fontFamily: workbookSchema.textStyling.subtitle.fontFamily,
                        color: workbookSchema.textStyling.subtitle.color,
                    }}
                />
            )}
            {description.show && (
                <SectionDescription
                    text={description.text}
                    style={{
                        fontFamily: workbookSchema.textStyling.body.fontFamily,
                        color: workbookSchema.textStyling.body.color,
                    }}
                    data-test="section-qa-desc"
                />
            )}
            <div className="w-full break-inside-avoid">
                <div className="flex items-center justify-center w-full">
                    {src ? (
                        <div className="flex flex-col" style={imageSizeStyles}>
                            <img src={src} alt={schema.title.text} />
                        </div>
                    ) : (
                        <PdfImagePlaceHolder style={imageSizeStyles} />
                    )}
                </div>
            </div>
        </>
    );
};

const UploadImagePlaceHolder = ({ style, onClick, mode }) => {
    return (
        <div className="flex items-center justify-center w-full ">
            <div
                className="flex flex-col items-center justify-center w-full h-64 bg-white border border-dashed rounded-lg border-neutral-400"
                style={style}
                role="button">
                <PhotographIcon className="mb-6 w-9 text-neutral-300" />
                <div className="mb-2 heading__sm--semibold">Upload a File</div>
                <p className="text-center caption w-96 text-neutral-400">Files must be in .jpg .svg. or .png format</p>
                {mode === WORKBOOK_SECTION_VIEWER_MODES.BUILDER && (
                    <div>
                        <PreviewSuggestionTooltip>
                            <DefaultButton
                                label="Upload"
                                icon={PhotographIcon}
                                iconPosition={ICON_POSITION.LEFT}
                                className="mt-3 replace-button custom-focus-outline"
                            />
                        </PreviewSuggestionTooltip>
                    </div>
                )}
                {mode !== WORKBOOK_SECTION_VIEWER_MODES.BUILDER && (
                    <DefaultButton
                        label="Upload"
                        icon={PhotographIcon}
                        iconPosition={ICON_POSITION.LEFT}
                        className="mt-3 replace-button custom-focus-outline"
                        onClick={onClick}
                    />
                )}
            </div>
        </div>
    );
};

const PdfImagePlaceHolder = ({ style }) => {
    return (
        <div className="flex items-center justify-center w-full ">
            <div
                className="flex items-center justify-center w-full h-64 bg-white border border-dashed rounded-lg border-neutral-400"
                style={style}>
                <img
                    key="placeholder-image"
                    src="/images/image-section-placeholder.svg"
                    className="break-inside-avoid h-9 w-9"
                    alt="Upload"
                />
            </div>
        </div>
    );
};

const DisabledSectionPlaceHolder = ({ style }) => {
    return (
        <div className="flex items-center justify-center w-full ">
            <div
                className="flex flex-col items-center justify-center w-full h-64 bg-white border border-dashed rounded-lg border-neutral-400"
                style={style}>
                <ExclamationIcon className="mb-3 w-9 text-neutral-300" />

                <div className="heading__sm--semibold">Upgrade plan to enable Student Image Uploads</div>
                <Link
                    to={`${ROUTES.CREATOR.SETTINGS}?section=${SETTINGS_SECTIONS.BILLING_AND_PLANS}`}
                    className="caption--underline">
                    Update in the Billings and Plans Page.
                </Link>
            </div>
        </div>
    );
};
