/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Editor, EditorState, convertFromRaw, convertToRaw, RichUtils } from 'draft-js';
import { markdownToDraft, draftToMarkdown } from 'markdown-draft-js';
import { TYPING_DEBOUNCE_TIMEOUT } from '@/util/constants';
import BoldIcon from '@/system/icons/BoldIcon';
import ItalicIcon from '@/system/icons/ItalicIcon';
import UnorderedListIcon from '@/system/icons/UnorderedListIcon';
import OrderedListIcon from '@/system/icons/OrderedListIcon';

import 'draft-js/dist/Draft.css';

const inlineStyles = [
    { label: 'Bold', style: 'BOLD', icon: BoldIcon, iconClassName: 'w-[0.7em] h-[1.3em]' },
    { label: 'Italic', style: 'ITALIC', icon: ItalicIcon, iconClassName: 'w-[0.7em] h-[1em]' },
];

const blockTypes = [
    { label: 'OL', style: 'ordered-list-item', icon: OrderedListIcon, iconClassName: 'w-[1em] h-[1.2em]' },
    { label: 'UL', style: 'unordered-list-item', icon: UnorderedListIcon, iconClassName: 'w-[1em] h-[1.2em]' },
];

export default function RichTextEditorDebounce({ value, onChange, onTypingEnd, 'data-test': dataTest }) {
    const editor = useRef(null);
    const [initialized, setInitialized] = useState(false);
    const [editorState, setEditorState] = useState(
        EditorState.createWithContent(convertFromRaw(markdownToDraft(value))),
    );

    const currentInlineStyles = editorState ? editorState.getCurrentInlineStyle() : [];
    const blockTypeStyle = editorState
        ? editorState.getCurrentContent().getBlockForKey(editorState.getSelection().getStartKey()).getType()
        : null;

    const focus = () => {
        editor.current.focus();
    };

    const handleEditorChange = (es) => {
        setEditorState(es);
        if (typeof onChange === 'function') {
            onChange(draftToMarkdown(convertToRaw(es.getCurrentContent())));
        }
    };

    const handleToggleBlockType = (blockType) => {
        handleEditorChange(RichUtils.toggleBlockType(editorState, blockType));
    };

    const handleToggleInlineStyle = (inlineStyle) => {
        handleEditorChange(RichUtils.toggleInlineStyle(editorState, inlineStyle));
    };

    useEffect(() => {
        if (initialized && typeof onTypingEnd === 'function') {
            const timeoutId = setTimeout(() => {
                onTypingEnd(value);
            }, TYPING_DEBOUNCE_TIMEOUT);
            return () => clearTimeout(timeoutId);
        }
        setInitialized(true);
        return () => {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
        <>
            <div className="flex items-center space-x-3 text-neutral-200 px-3 py-1.5 bg-neutral-50 rounded-tl-md rounded-tr-md border-neutral-100 border">
                {inlineStyles.map((inlineStyle) => (
                    <StyleButton
                        key={inlineStyle.label}
                        label={inlineStyle.label}
                        style={inlineStyle.style}
                        active={currentInlineStyles.includes(inlineStyle.style)}
                        icon={inlineStyle.icon}
                        iconClassName={inlineStyle.iconClassName}
                        onToggle={handleToggleInlineStyle}
                    />
                ))}
                <span className="px">|</span>
                {blockTypes.map((blockType) => (
                    <StyleButton
                        key={blockType.label}
                        label={blockType.label}
                        style={blockType.style}
                        active={blockTypeStyle === blockType.style}
                        icon={blockType.icon}
                        iconClassName={blockType.iconClassName}
                        onToggle={handleToggleBlockType}
                    />
                ))}
            </div>
            <div
                className="border-b border-l border-r border-neutral-100 rounded-bl-md rounded-br-md px-3 py-2 paragraph_md min-h-[125px] rmd"
                role="button"
                tabIndex="-1"
                onClick={focus}
                data-test={dataTest}>
                <Editor editorState={editorState} onChange={handleEditorChange} ref={editor} />
            </div>
        </>
    );
}

function StyleButton({ label, style, active, onToggle, icon, iconClassName }) {
    const Icon = icon;
    const handleClick = (e) => {
        // we need to prevent default on mouse down so the button doesn't take focus!
        e.preventDefault();
        onToggle(style);
    };
    return (
        <button
            type="button"
            className={classNames({ 'text-neutral-400': active })}
            onMouseDown={handleClick}
            tabIndex="0">
            {Icon ? <Icon className={iconClassName} /> : label}
        </button>
    );
}
