import { formatISO } from 'date-fns';
import useRequest from '@/api/useRequest';
import { MIXPANEL_USER_FEEDBACK_URL } from '@/util/constants';

export default function useRolesApi() {
    const { getRequest, patchRequest, putRequest, postRequest, deleteRequest } = useRequest();

    const getCreator = async (creatorId) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/creators/${creatorId}`;
        return getRequest(url);
    };

    const getStudent = async (studentId) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/students/${studentId}`;
        return getRequest(url);
    };

    const getMember = async (memberId) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/members/${memberId}`;
        return getRequest(url);
    };

    const getMemberCreator = async (memberId, creatorId) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/members/${memberId}/creators/${creatorId}`;
        return getRequest(url);
    };

    const getStudentCreator = async (studentId, creatorId) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/students/${studentId}/creators/${creatorId}`;
        return getRequest(url);
    };

    const updateCreatorTrial = async (creatorId, plan, trialEndDate = null) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/creators/${creatorId}`;
        const payload = { plan };
        if (trialEndDate) payload.trial_end_date = formatISO(trialEndDate);
        return patchRequest(url, payload);
    };

    const updateCreatorCompany = async (creatorId, companyName) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/creators/${creatorId}`;
        const payload = { company_name: companyName };
        return patchRequest(url, payload);
    };

    const upsertCreatorSchema = async (creatorId, path, value) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/creators/${creatorId}/schema_property`;
        const payload = { path, value };
        return putRequest(url, payload);
    };

    const addPublicWorkbook = async (creatorId, workbookId) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/creators/${creatorId}/public-workbooks`;
        const queryParams = { workbook_id: workbookId };
        return postRequest(url, null, queryParams);
    };

    const removePublicWorkbook = async (creatorId, workbookId) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/creators/${creatorId}/public-workbooks/${workbookId}`;
        return deleteRequest(url);
    };

    const getAdmin = async (adminId) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/admins/${adminId}`;
        return getRequest(url);
    };

    const getAnalyticsPayload = (creatorId, fromDate, toDate, event, limit) => {
        let analyticsUrl = `${MIXPANEL_USER_FEEDBACK_URL}?project_id=${process.env.REACT_APP_MIXPANEL_PROJECT_ID}`;
        if (event) {
            analyticsUrl = `${analyticsUrl}&event=${encodeURIComponent(`${event}`)}`;
        }
        analyticsUrl = `${analyticsUrl}&distinct_ids=${encodeURIComponent(`["${creatorId}"]`)}`;
        analyticsUrl = `${analyticsUrl}&from_date=${fromDate.toISOString().split('T')[0]}`;
        analyticsUrl = `${analyticsUrl}&to_date=${toDate.toISOString().split('T')[0]}`;
        analyticsUrl = `${analyticsUrl}&limit=${limit}`;
        return {
            url: analyticsUrl,
            http_method: `GET`,
        };
    };

    const getCreatorAnalyticsFeedback = async (creatorId, fromDate, toDate, event = null, limit = 100) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/creators/${creatorId}/analytics-query`;
        return postRequest(url, getAnalyticsPayload(creatorId, fromDate, toDate, event, limit));
    };

    const getMemberAnalyticsFeedback = async (memberId, fromDate, toDate, event = null, limit = 100) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/members/${memberId}/analytics-query`;
        return postRequest(url, getAnalyticsPayload(memberId, fromDate, toDate, event, limit));
    };

    const becomeCreator = async (companyName) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/users/add-creator-role`;
        const payload = { company_name: companyName };
        return postRequest(url, payload);
    };

    return {
        getCreator,
        updateCreatorTrial,
        updateCreatorCompany,
        getStudent,
        getStudentCreator,
        getMember,
        getMemberCreator,
        upsertCreatorSchema,
        addPublicWorkbook,
        removePublicWorkbook,
        getAdmin,
        getCreatorAnalyticsFeedback,
        getMemberAnalyticsFeedback,
        becomeCreator,
    };
}
