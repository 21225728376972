import TextareaDebounce from '@/system/TextareaDebounce';
import { WORKBOOK_SECTION_VIEWER_MODES } from '@/util/constants';
import PreviewSuggestionTooltip from '@/workbook/builder/sections/PreviewSuggestionTooltip';
import { isSectionReadOnly } from '@/util/helpers';

const responsePath = 'note';

export const NotesAreaEditor = () => {
    return null;
};

export const NotesArea = ({
    workbookSchema,
    mode = WORKBOOK_SECTION_VIEWER_MODES.DEFAULT,
    sectionResponses,
    setSectionResponseProp,
    saveSectionResponseProp,
}) => {
    const handleNoteChange = (e) => {
        const { value: text } = e.target;
        if (typeof setSectionResponseProp === 'function') setSectionResponseProp(responsePath, text);
    };

    const handleNoteTypingEnd = async (text) => {
        if (typeof saveSectionResponseProp === 'function') return saveSectionResponseProp(responsePath, text);
        return null;
    };

    const textArea = () => (
        <TextareaDebounce
            value={sectionResponses?.[responsePath]}
            onChange={handleNoteChange}
            onTypingEnd={handleNoteTypingEnd}
            className="custom-focus-outline min-h-[125px]"
            readOnly={isSectionReadOnly(mode)}
            style={{ fontFamily: workbookSchema.textStyling.body.fontFamily }}
            showStatusMessage
            resizeToFit
        />
    );

    return mode === WORKBOOK_SECTION_VIEWER_MODES.BUILDER ? (
        <PreviewSuggestionTooltip>{textArea()}</PreviewSuggestionTooltip>
    ) : (
        textArea()
    );
};

export const NotesAreaArtifact = ({ workbookSchema, sectionResponses }) => {
    return (
        <pre
            className="paragraph__sm w-full block rounded-md border px-3 pt-2 pb-2 border-neutral-200 whitespace-pre-wrap break-words min-h-[125px] bg-white"
            style={{ fontFamily: workbookSchema.textStyling.body.fontFamily }}>
            {sectionResponses?.[responsePath]}
        </pre>
    );
};
