/* eslint-disable import/prefer-default-export */
import { IMAGE_SIZES } from '@/util/constants';
import { getImageWidthBySize, getStorageUrl } from '@/util/helpers';
import AccessibilityIcon from '@/system/icons/AccessibilityIcon';
import ImageUploader from '@/system/ImageUploader';
import TextareaDebounce from '@/system/TextareaDebounce';
import Divider from '@/system/Divider';
import RadioGroup from '@/system/RadioGroup';
import TextInputDebounce from '@/system/TextInputDebounce';
import { useToast } from '@/system/ToastProvider';
import Label from '@/system/Label';

const radioGroupImageSizes = [
    { id: IMAGE_SIZES.SMALL, label: IMAGE_SIZES.SMALL },
    { id: IMAGE_SIZES.MEDIUM, label: IMAGE_SIZES.MEDIUM },
    { id: IMAGE_SIZES.LARGE, label: IMAGE_SIZES.LARGE },
    { id: IMAGE_SIZES.FULL, label: IMAGE_SIZES.FULL },
];

export const ImageEditor = ({
    schema,
    setSectionSchemaProp,
    saveSectionSchemaProp,
    saveSectionFile,
    resetSectionFile,
}) => {
    const { title, image } = schema;
    const { pushError } = useToast();

    const handleTitleChange = (e) => {
        const { value: text } = e.target;
        setSectionSchemaProp('title.text', text);
    };

    const handleTitleTypingEnd = (text) => {
        saveSectionSchemaProp('title.text', text);
    };

    const handleImageSelect = async (fileData) => {
        return saveSectionFile('image', fileData);
    };

    const handleImageRemove = () => {
        resetSectionFile('image');
    };

    const handleImageAltChange = (e) => {
        const { value: text } = e.target;
        setSectionSchemaProp('image.alt', text);
    };

    const handleImageAltTypingEnd = (text) => {
        saveSectionSchemaProp('image.alt', text);
    };

    const handleFileLoaderError = (error) => {
        pushError(error, null);
    };

    return (
        <>
            <ImageUploader
                imageName={image.filename}
                buttonLabel="Upload image"
                onSelect={handleImageSelect}
                onRemove={handleImageRemove}
                onFileLoaderError={handleFileLoaderError}
                filenameMaxWidth="179px"
            />
            <Divider />
            <Label desc="Sizing" />
            <RadioGroup
                value={image.size}
                onChange={(value) => saveSectionSchemaProp('image.size', value)}
                options={radioGroupImageSizes}
                className="mb-2"
            />

            <Label desc="Navigation Title" />
            <TextInputDebounce
                value={title.text}
                onChange={handleTitleChange}
                onTypingEnd={handleTitleTypingEnd}
                resizeToFit
            />
            <Divider />
            <div className="text-base font-medium flex items-center mb-6">
                <AccessibilityIcon className="mr-3 inline-block h-4" />
                <span>Accessibility</span>
            </div>
            <Label desc="Alternative Text" className="mb-1.5" />
            <p className="caption">
                Add a description of this image that can be read by a screen reader for the visually impaired.
            </p>
            <div className="pt-3">
                <TextareaDebounce
                    value={image.alt}
                    onChange={handleImageAltChange}
                    onTypingEnd={handleImageAltTypingEnd}
                    resizeToFit
                />
            </div>
        </>
    );
};

export function Image({ schema, workbookSchema }) {
    const imageSizeStyles = {
        width: getImageWidthBySize(schema.image.size),
    };

    return (
        <div className="w-full">
            {schema.image.storage_filename ? (
                <div className="flex w-full items-center justify-center">
                    <img
                        src={getStorageUrl(`${workbookSchema.storageObjectBasePath}${schema.image.storage_filename}`)}
                        alt={schema.image.alt || schema.title.text}
                        style={imageSizeStyles}
                    />
                </div>
            ) : (
                <div className="flex w-full items-center justify-center">
                    <div
                        className="flex h-64 w-full items-center justify-center rounded-lg border border-dashed border-neutral-400 bg-white"
                        style={imageSizeStyles}>
                        <img
                            key="placeholder-image"
                            src="/images/image-section-placeholder.svg"
                            className="h-9 w-9"
                            alt="Upload"
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

export function ImageArtifact({ schema, workbookSchema }) {
    if (!schema.image.storage_filename) return null;

    const imageSizeStyles = {
        width: getImageWidthBySize(schema.image.size),
    };

    return (
        <div className="w-full break-inside-avoid-page">
            <div className="flex w-full items-center justify-center">
                <img
                    src={getStorageUrl(`${workbookSchema.storageObjectBasePath}${schema.image.storage_filename}`)}
                    alt={schema.title.text}
                    style={imageSizeStyles}
                />
            </div>
        </div>
    );
}
