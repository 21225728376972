import { LinkIcon } from '@heroicons/react/outline';
import TextInputDebounce from '@/system/TextInputDebounce';
import GroupHeading from '@/system/GroupHeading';
import { ensureHttpOrHttpsUrl } from '@/util/helpers';
import Label from '@/system/Label';

const handleClick = (url) => {
    window.open(ensureHttpOrHttpsUrl(url), '_blank');
};

export const LinkEditor = ({ schema, setSectionSchemaProp, saveSectionSchemaProp }) => {
    const { text, url } = schema;

    const handleTextChange = (e) => {
        const { value: newText } = e.target;
        setSectionSchemaProp('text', newText);
    };

    const handleTextTypingEnd = (newText) => {
        saveSectionSchemaProp('text', newText);
    };

    const handleUrlChange = (e) => {
        const { value: newText } = e.target;
        setSectionSchemaProp('url', newText);
    };

    const handleUrlTypingEnd = (newText) => {
        saveSectionSchemaProp('url', newText);
    };

    return (
        <div className="flex flex-col">
            <GroupHeading heading="Content" />
            <Label desc="Text" />
            <TextInputDebounce value={text} onChange={handleTextChange} onTypingEnd={handleTextTypingEnd} />
            <Label desc="URL" />
            <TextInputDebounce value={url} onChange={handleUrlChange} onTypingEnd={handleUrlTypingEnd} />
            <div
                role="button"
                onClick={() => handleClick(url)}
                onKeyPress={() => handleClick(url)}
                className="text-sm text-primary-500 underline"
                tabIndex={0}>
                Test this link
            </div>
        </div>
    );
};

export const Link = ({ workbookSchema, schema }) => {
    const { text, url } = schema;

    return (
        <div
            role="button"
            onClick={() => handleClick(url)}
            onKeyPress={() => handleClick(url)}
            className="paragraph__md custom-outline-color"
            style={{
                fontFamily: workbookSchema.textStyling.body.fontFamily,
                color: workbookSchema.linkStyling.textColor,
            }}
            tabIndex={0}>
            <LinkIcon className="mr-1 inline-block h-4 w-4 break-words" />
            <span className="font-semibold underline">{text}</span>
        </div>
    );
};

export const LinkArtifact = ({ workbookSchema, schema }) => {
    const { text, url } = schema;

    return (
        <a
            href={ensureHttpOrHttpsUrl(url)}
            className="paragraph__md block print:break-inside-avoid-page"
            style={{
                fontFamily: workbookSchema.textStyling.body.fontFamily,
                color: workbookSchema.linkStyling.textColor,
            }}
            rel="noreferrer"
            target="_blank">
            <LinkIcon className="mr-1 inline-block h-4 w-4 break-words" />
            <span className="font-semibold underline">{text}</span>
        </a>
    );
};
