import ModuleExplorer from '@/workbook/student/sidebar/ModuleExplorer';

export default function DesktopNav({ workbook, onModuleSelect, onSectionSelect, addOns, showAddOns }) {
    return (
        <div className="hidden lg:block lg:h-full lg:overflow-y-auto lg:px-6 lg:py-6">
            <div className="mb-6 break-words heading__sm--medium">{workbook.name}</div>
            <ModuleExplorer
                workbook={workbook}
                onModuleSelect={onModuleSelect}
                onSectionSelect={onSectionSelect}
                addOns={addOns}
                showAddOns={showAddOns}
            />
        </div>
    );
}
