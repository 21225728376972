import { useReducer } from 'react';

export const ACTIONS = {
    SET: 'set',
    RESET: 'reset',
    PUSH_NOTIFICATION: 'push_notification',
    REMOVE_NOTIFICATION: 'remove_notification',
    REMOVE_NOTIFICATION_FROM_HISTORY: 'remove_notification_from_history',
};

export const getDefaultState = () => ({
    initialized: false,
    history: {},
    notifications: [],
});

export const notificationsReducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.SET: {
            const history = action?.payload?.history || {};
            const notifications = action?.payload?.notifications || [];
            return {
                initialized: true,
                history,
                notifications,
            };
        }
        case ACTIONS.RESET:
            return getDefaultState();
        case ACTIONS.PUSH_NOTIFICATION: {
            const { key, variant } = action.payload;
            if (Object.prototype.hasOwnProperty.call(state.history, key)) return state;

            const newHistory = { ...state.history };
            newHistory[key] = true;

            return {
                initialized: state.initialized,
                history: newHistory,
                notifications: [{ key, variant }, ...state.notifications],
            };
        }
        case ACTIONS.REMOVE_NOTIFICATION: {
            const { index } = action.payload;

            const newNotifications = state.notifications.filter((element, elementIndex) => {
                if (elementIndex === index) return false;
                return true;
            });

            return {
                ...state,
                notifications: newNotifications,
            };
        }
        case ACTIONS.REMOVE_NOTIFICATION_FROM_HISTORY: {
            const { key } = action.payload;

            const newHistory = { ...state.history };
            delete newHistory[key];

            const newNotifications = state.notifications.filter((element) => {
                if (element?.key === key) return false;
                return true;
            });

            return {
                ...state,
                history: newHistory,
                notifications: newNotifications,
            };
        }
        default:
            throw new Error('Action not supported ');
    }
};

const useNotificationsReducer = () => {
    return useReducer(notificationsReducer, getDefaultState());
};

export default useNotificationsReducer;
