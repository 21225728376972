import { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import Textarea from '@/system/Textarea';
import { TYPING_DEBOUNCE_TIMEOUT, HIDE_RESPONSE_SAVED_TIMEOUT } from '@/util/constants';
import { useToast } from '@/system/ToastProvider';

export default function TextareaDebounce({
    value,
    onChange,
    label,
    onBlur,
    placeholder,
    required,
    error,
    errorMsg,
    onTypingEnd,
    readOnly,
    style,
    resizeToFit,
    focus,
    className,
    showStatusMessage,
    'data-test': dataTest,
}) {
    const isMounted = useRef(true);
    const showResponseSavedTimeout = useRef(null);
    const [initialized, setInitialized] = useState(false);
    const [showResponseSaved, setShowResponseSaved] = useState(false);
    const { pushError } = useToast();

    useEffect(() => {
        if (initialized && typeof onTypingEnd === 'function') {
            const timeOutId = setTimeout(() => {
                if (showStatusMessage) {
                    onTypingEnd(value)
                        .then(() => {
                            if (showResponseSavedTimeout.current) clearTimeout(showResponseSavedTimeout.current);
                            if (isMounted.current) setShowResponseSaved(true);
                            showResponseSavedTimeout.current = setTimeout(() => {
                                showResponseSavedTimeout.current = null;
                                if (isMounted.current) setShowResponseSaved(false);
                            }, HIDE_RESPONSE_SAVED_TIMEOUT);
                        })
                        .catch(() => {
                            pushError('Could not save response', null);
                        });
                } else {
                    onTypingEnd(value);
                }
            }, TYPING_DEBOUNCE_TIMEOUT);
            return () => clearTimeout(timeOutId);
        }
        setInitialized(true);
        return () => {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    });

    return (
        <div className="relative w-full">
            {showResponseSaved && (
                <div className="caption absolute bottom-2.5 right-4 text-neutral-400">response saved</div>
            )}
            <Textarea
                value={value}
                onChange={onChange}
                label={label}
                onBlur={onBlur}
                placeholder={placeholder}
                required={required}
                error={error}
                errorMsg={errorMsg}
                readOnly={readOnly}
                style={style}
                resizeToFit={resizeToFit}
                focus={focus}
                className={classNames(className, { '!pb-6': showStatusMessage })}
                data-test={dataTest}
            />
        </div>
    );
}
