export default function ItalicIcon({ className }) {
    return (
        <svg
            width="3"
            height="10"
            viewBox="0 0 3 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}>
            <path
                d="M0.0259233 9.47852L1.11683 2.93306H2.39098L1.30007 9.47852H0.0259233ZM2.02024 1.91033C1.79865 1.91033 1.61115 1.83647 1.45774 1.68874C1.30717 1.53817 1.23757 1.3592 1.24893 1.15181C1.2603 0.941583 1.34837 0.762606 1.51314 0.614879C1.67791 0.464311 1.86967 0.389026 2.08842 0.389026C2.31001 0.389026 2.49609 0.464311 2.64666 0.614879C2.79723 0.762606 2.86825 0.941583 2.85973 1.15181C2.84837 1.3592 2.7603 1.53817 2.59553 1.68874C2.43359 1.83647 2.24183 1.91033 2.02024 1.91033Z"
                fill="currentColor"
            />
        </svg>
    );
}
