import { useEffect, useState, useRef } from 'react';
import { useAuth } from '@/auth/AuthProvider';

export const SESSION_CACHE_KEYS = {
    NOTIFICATIONS: 'wobo-n',
    ACTIVE_STUDENTS_WORKBOOKS: 'wobo-asw',
    SELECTED_ROLE: 'wobo-sr',
    SELECTED_CREATOR_ID: 'wobo-sc',
};

export default function useSessionCache(key) {
    const { user, authenticated } = useAuth();

    const keyWithUserId = useRef('');
    const [ready, setReady] = useState(false);
    const [entry, setEntry] = useState({ value: null, exp: Number.MAX_SAFE_INTEGER });

    const getItemValue = () => {
        try {
            const now = new Date().getTime();
            if (now <= entry.exp) return entry.value;
            return null;
        } catch (error) {
            return null;
        }
    };

    const setItemValue = (value, exp = Number.MAX_SAFE_INTEGER) => {
        if (!Number.isFinite(exp)) throw new Error('Expiration must be expressed in milliseconds');
        const now = new Date().getTime();
        try {
            const newEntry = { value, exp: exp + now };
            setEntry(newEntry);
            window.sessionStorage.setItem(keyWithUserId.current, JSON.stringify(newEntry));
        } catch (error) {
            console.error(`Unable to store new entry for ${keyWithUserId.current} in sessionStorage.`);
        }
    };

    const evictItem = () => {
        try {
            const newEntry = { value: null, exp: 0 };
            setEntry(newEntry);
            window.sessionStorage.removeItem(keyWithUserId.current);
        } catch (error) {
            console.error(`Unable to store new entry for ${keyWithUserId.current} in sessionStorage.`);
        }
    };

    useEffect(() => {
        if (!key) throw new Error('A key must be provided');
    }, [key]);

    useEffect(() => {
        if (user.id && authenticated) {
            keyWithUserId.current = `${key}-${user.id}`;
            setEntry(() => {
                try {
                    const item = window.sessionStorage.getItem(keyWithUserId.current);
                    return item ? JSON.parse(item) : { value: null, exp: Number.MAX_SAFE_INTEGER };
                } catch (error) {
                    return { value: null, exp: Number.MAX_SAFE_INTEGER };
                }
            });
            setReady(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.id, authenticated]);

    return { ready, getItemValue, setItemValue, evictItem };
}
