import { SECTIONS, SECTIONS_DICTIONARY } from '@/workbook/builder/sections/sections';
import { deepCopy } from '@/util/helpers';

export const getSectionLabel = (section) => {
    let label = '';
    switch (section.type) {
        case SECTIONS.MODULE_COVER_PAGE.TYPE_ID:
        case SECTIONS.QUESTION_AND_ANSWER.TYPE_ID:
        case SECTIONS.TEXT_FIELD.TYPE_ID:
        case SECTIONS.WORKBOOK_DOWNLOAD_LINK.TYPE_ID:
        case SECTIONS.RADIO_BUTTONS.TYPE_ID:
        case SECTIONS.CHECKBOXES.TYPE_ID:
        case SECTIONS.DROP_DOWN_LIST.TYPE_ID:
        case SECTIONS.FILL_IN_THE_BLANKS.TYPE_ID:
        case SECTIONS.SLIDER.TYPE_ID:
        case SECTIONS.MODULE_DOWNLOAD_LINK.TYPE_ID:
        case SECTIONS.STUDENT_IMAGE_UPLOAD.TYPE_ID:
        case SECTIONS?.FILE_UPLOAD?.TYPE_ID:
        case SECTIONS?.PAGE_BREAK?.TYPE_ID:
            label = section.schema?.title?.text;
            break;
        case SECTIONS?.VIDEO?.TYPE_ID:
            label = section.schema?.title?.text;
            break;
        case SECTIONS.LINK.TYPE_ID:
            label = section.schema?.text;
            break;
        case SECTIONS.IMAGE.TYPE_ID:
            label = section.schema?.title?.text || 'Image';
            break;
        case SECTIONS.NOTES_AREA.TYPE_ID:
            label = section.schema?.text || 'Notes Area';
            break;
        case SECTIONS.TABLE.TYPE_ID:
            label = section.schema?.title?.text || SECTIONS[SECTIONS_DICTIONARY[section.type]].NEW_SCHEMA.title.text; // Backwards compatibility
            break;
        default:
            break;
    }
    return label;
};

export const getSectionIcon = (section) => {
    if (section && Object.prototype.hasOwnProperty.call(SECTIONS_DICTIONARY, section.type)) {
        return SECTIONS[SECTIONS_DICTIONARY[section.type]].ICON;
    }
    return null;
};

export const getSectionTitle = (section) => {
    if (section && Object.prototype.hasOwnProperty.call(SECTIONS_DICTIONARY, section.type)) {
        return SECTIONS[SECTIONS_DICTIONARY[section.type]].TITLE;
    }
    return null;
};

export const getSectionEditor = (section) => {
    if (section && Object.prototype.hasOwnProperty.call(SECTIONS_DICTIONARY, section.type)) {
        return SECTIONS[SECTIONS_DICTIONARY[section.type]].EDITOR;
    }
    return null;
};

export const getSectionViewer = (section) => {
    if (section && Object.prototype.hasOwnProperty.call(SECTIONS_DICTIONARY, section.type)) {
        return SECTIONS[SECTIONS_DICTIONARY[section.type]].VIEWER;
    }
    return null;
};

export const getSectionArtifact = (section) => {
    if (section && Object.prototype.hasOwnProperty.call(SECTIONS_DICTIONARY, section.type)) {
        return SECTIONS[SECTIONS_DICTIONARY[section.type]].ARTIFACT;
    }
    return null;
};

export const getDuplicatedSection = (section) => {
    const newSection = deepCopy(section);
    switch (section.type) {
        case SECTIONS.LINK.TYPE_ID:
            newSection.schema.text = `Copy of ${section.schema.text}`;
            break;
        case SECTIONS.NOTES_AREA.TYPE_ID:
            newSection.schema.text = 'Notes Area';
            break;
        default:
            newSection.schema.title.text = `Copy of ${section.schema.title.text}`;
            break;
    }
    return newSection;
};

export const getSectionStorageFilenames = (section) => {
    const images = [];
    switch (section.type) {
        case SECTIONS.WORKBOOK_COVER_PAGE.TYPE_ID:
        case SECTIONS.MODULE_COVER_PAGE.TYPE_ID:
        case SECTIONS.IMAGE.TYPE_ID:
            if (section.schema.image.storage_filename) images.push(section.schema.image.storage_filename);
            break;
        default:
    }
    return images;
};

export const storageFilenameExistsInWorkbook = (workbook, storageFilename) => {
    for (let i = 0; i < workbook.modules.length; i += 1) {
        for (let j = 0; j < workbook.modules[i].sections.length; j += 1) {
            const section = workbook.modules[i].sections[j];
            const sectionStorageFilenames = getSectionStorageFilenames(section);
            for (let k = 0; k < sectionStorageFilenames.length; k += 1) {
                if (storageFilename === sectionStorageFilenames[k]) return true;
            }
        }
    }
    return false;
};

export const isSectionDisabledAddOn = (sectionType, addOns) => {
    return (
        (sectionType === SECTIONS.STUDENT_IMAGE_UPLOAD.TYPE_ID || sectionType === SECTIONS.FILE_UPLOAD.TYPE_ID) &&
        !addOns?.studentImageUpload
    );
};
