import classNames from 'classnames';

export default function TableLayoutIcon2({ className = '', style = {} }) {
    return (
        <svg
            width="39"
            height="39"
            viewBox="0 0 39 39"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={classNames('w-em', className)}
            style={style}>
            <g filter="url(#filter0_dd_302_2929)">
                <g clipPath="url(#clip0_302_2929)">
                    <rect x="3.72607" y="18.126" width="15.9985" height="4.90711" fill="currentColor" />
                    <rect x="19.7251" y="18.126" width="15.9985" height="4.90711" fill="currentColor" />
                    <rect x="3.72607" y="2.12598" width="15.9985" height="4.9071" fill="currentColor" />
                    <rect x="3.72607" y="7.0332" width="15.9985" height="11.0929" fill="white" />
                    <rect x="3.72607" y="23.0332" width="15.9985" height="11.0929" fill="white" />
                    <rect x="19.7251" y="2.12598" width="15.9985" height="4.9071" fill="currentColor" />
                    <rect x="19.7251" y="7.0332" width="15.9985" height="11.0929" fill="white" />
                    <rect x="19.7251" y="23.0332" width="15.9985" height="11.0929" fill="white" />
                    <path d="M19.7246 2.12598L19.7247 34.126" stroke="#272727" />
                    <path d="M3.72363 7.0332L35.7215 7.0332" stroke="#272727" />
                    <path d="M3.72363 23.0332L35.7215 23.0332" stroke="#272727" />
                </g>
            </g>
            <defs>
                <filter
                    id="filter0_dd_302_2929"
                    x="0.723633"
                    y="0.125977"
                    width="38"
                    height="38"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_302_2929" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="1.5" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="effect1_dropShadow_302_2929" result="effect2_dropShadow_302_2929" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_302_2929" result="shape" />
                </filter>
                <clipPath id="clip0_302_2929">
                    <path
                        d="M3.72363 4.12598C3.72363 3.02141 4.61906 2.12598 5.72363 2.12598H33.7236C34.8282 2.12598 35.7236 3.02141 35.7236 4.12598V32.126C35.7236 33.2305 34.8282 34.126 33.7236 34.126H5.72363C4.61906 34.126 3.72363 33.2305 3.72363 32.126V4.12598Z"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}
