/* eslint-disable import/prefer-default-export */
import classNames from 'classnames';
import { BUTTON_SIZE, BUTTON_TYPE } from '@/system/Buttons';
import ThinkificIcon from '@/system/icons/brands/ThinkificIcon';
import GoogleIcon from '@/system/icons/brands/GoogleIcon';
import TeachableIcon from '@/system/icons/brands/TeachableIcon';

const BRANDED_BUTTON_VARIANT = {
    THINKIFIC: 'thinkific',
    GOOGLE: 'google',
    TEACHABLE: 'teachable',
};

function BaseBrandedButton({
    label,
    onClick,
    type = BUTTON_TYPE.BUTTON,
    size = BUTTON_SIZE.MD,
    fullWidth = false,
    variant = BRANDED_BUTTON_VARIANT.THINKIFIC,
    style = {},
    className = '',
    disabled = false,
}) {
    let variantClasses;
    let variantIconOuterContainerClasses;
    let variantIconInnerContainerClasses;
    let variantIconClasses;
    let sizeClasses;
    let sizeIconOuterContainerClasses;
    let sizeLabelClasses;
    let Icon;

    switch (variant) {
        case BRANDED_BUTTON_VARIANT.GOOGLE:
            variantClasses =
                'bg-[rgba(66,133,244,1)] text-white hover:bg-[rgba(66,133,244,0.8)] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-neutral-300 disabled:border-0 disabled:bg-neutral-100 disabled:text-neutral-300 border border-[rgba(66,133,244,1)] overflow-hidden';
            variantIconOuterContainerClasses = 'border-r border-[rgba(66,133,244,1)] bg-white group-hover:opacity-80';
            variantIconInnerContainerClasses = 'h-[1.3em] w-[1.3em]';
            variantIconClasses = 'h-full w-full';

            Icon = GoogleIcon;
            break;
        case BRANDED_BUTTON_VARIANT.TEACHABLE:
            variantClasses =
                'bg-[rgba(21,207,157,1)] text-white hover:bg-[rgba(21,207,157,0.8)] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-neutral-300 disabled:border-0 disabled:bg-neutral-100 disabled:text-neutral-300 border border-[rgba(21,207,157,1)] overflow-hidden';
            variantIconOuterContainerClasses = 'border-r border-white bg-[rgba(21,207,157,1)] group-hover:opacity-80';
            variantIconInnerContainerClasses = 'h-[1.3em] w-[1.3em]';
            variantIconClasses = 'h-full w-full';

            Icon = TeachableIcon;
            break;
        default:
            variantClasses =
                'bg-[rgba(37,36,74,1)] text-neutral-25 hover:bg-[rgba(63,61,115,1)] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[rgba(63,61,115,1)] disabled:border-0 disabled:bg-neutral-100 disabled:text-neutral-300';
            variantIconOuterContainerClasses = 'border-r border-white';
            variantIconInnerContainerClasses = 'border border-white h-[1.3em] w-[1.2em]';
            variantIconClasses = 'h-full w-full';

            Icon = ThinkificIcon;
    }

    switch (size) {
        case BUTTON_SIZE.SM:
            sizeClasses = 'caption rounded';
            sizeLabelClasses = 'pl-2.5 pr-3';
            sizeIconOuterContainerClasses = 'py-[0.775em] px-3';
            break;
        case BUTTON_SIZE.LG:
            sizeClasses = 'paragraph__md--semibold rounded-md';
            sizeLabelClasses = 'pl-3 pr-4';
            sizeIconOuterContainerClasses = 'py-[0.6em] px-4';
            break;
        case BUTTON_SIZE.XL:
            sizeClasses = 'paragraph__lg--semibold rounded-md';
            sizeLabelClasses = 'pl-3 pr-4';
            sizeIconOuterContainerClasses = 'py-[0.8em] px-4';
            break;
        default:
            sizeClasses = 'paragraph__sm--semibold rounded-md';
            sizeLabelClasses = 'pl-3 pr-4';
            sizeIconOuterContainerClasses = 'py-[0.65em] px-4';
    }

    const buttonClasses = classNames(
        'inline-flex items-center justify-center shadow-sm p-0 group',
        variantClasses,
        sizeClasses,
        className,
        {
            'w-full': fullWidth,
        },
    );

    const iconJsx = Icon ? (
        <span className={classNames('flex items-center justify-center rounded-sm', variantIconInnerContainerClasses)}>
            <Icon className={variantIconClasses} />
        </span>
    ) : null;

    return (
        <button
            // eslint-disable-next-line react/button-has-type
            type={type || BUTTON_TYPE.BUTTON}
            className={buttonClasses}
            style={style}
            onClick={typeof onClick === 'function' ? onClick : () => {}}
            disabled={disabled}>
            <span
                className={classNames(
                    'flex items-center justify-center',
                    variantIconOuterContainerClasses,
                    sizeIconOuterContainerClasses,
                )}>
                {iconJsx}
            </span>
            <span className={classNames('inline-block grow text-center', sizeLabelClasses)}>{label}</span>
        </button>
    );
}

export function ThinkificButton({
    label,
    type,
    onClick,
    size,
    style,
    fullWidth,
    className,
    disabled,
    disableUppercaseFirstLetter,
}) {
    return (
        <BaseBrandedButton
            label={label}
            type={type}
            onClick={onClick}
            size={size}
            style={style}
            fullWidth={fullWidth}
            variant={BRANDED_BUTTON_VARIANT.THINKIFIC}
            className={className}
            disabled={disabled}
            disableUppercaseFirstLetter={disableUppercaseFirstLetter}
        />
    );
}

export function GoogleButton({ label, type, onClick, size, style, fullWidth, className, disabled }) {
    return (
        <BaseBrandedButton
            label={label}
            type={type}
            onClick={onClick}
            size={size}
            style={style}
            fullWidth={fullWidth}
            variant={BRANDED_BUTTON_VARIANT.GOOGLE}
            className={className}
            disabled={disabled}
        />
    );
}

export function TeachableButton({
    label,
    type,
    onClick,
    size,
    style,
    fullWidth,
    className,
    disabled,
    disableUppercaseFirstLetter,
}) {
    return (
        <BaseBrandedButton
            label={label}
            type={type}
            onClick={onClick}
            size={size}
            style={style}
            fullWidth={fullWidth}
            variant={BRANDED_BUTTON_VARIANT.TEACHABLE}
            className={className}
            disabled={disabled}
            disableUppercaseFirstLetter={disableUppercaseFirstLetter}
        />
    );
}
