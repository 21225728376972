/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@/auth/AuthProvider';
import { isEmailValid, isPasswordValid } from '@/auth/helpers';
import useQueryParameter from '@/util/useQueryParameter';
import { Card } from '@/auth/Cards';
import PasswordValidator from '@/auth/PasswordValidator';
import TextInput, {
    TEXT_INPUT_TYPE,
    TEXT_INPUT_AUTO_CAPITALIZE,
    TEXT_INPUT_AUTO_CORRECT,
    TEXT_INPUT_SPELL_CHECK,
} from '@/system/TextInput';
import { AUTHENTICATION_TYPES, ROUTES, ROLE_QUERY_PARAM, ROLES } from '@/util/constants';

export default function ConfirmForgotPassword() {
    const isMounted = useRef(true);
    const email = useQueryParameter('email');
    const otp = useQueryParameter('otp');
    const roleQueryParam = useQueryParameter(ROLE_QUERY_PARAM);
    const { confirmPasswordReset } = useAuth();
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [checkPassword, setCheckPassword] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [checkConfirmPassword, setCheckConfirmPassword] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] = useState('');
    const [apiErrorMessage, setApiErrorMessage] = useState('');
    const [passwordReset, setPasswordReset] = useState(false);
    const navigate = useNavigate();

    const validatePassword = (value) => {
        let passed = true;
        if (apiErrorMessage) setApiErrorMessage('');
        if (!checkPassword) setCheckPassword(true);
        if (passwordError) passed = false;
        if (!value || !isPasswordValid(value)) {
            setPasswordError(true);
            passed = false;
        } else {
            setPasswordError(false);
        }
        if (checkConfirmPassword) {
            if (value !== confirmPassword) {
                setConfirmPasswordError(true);
                setConfirmPasswordErrorMessage('passwords do not match');
                if (confirmPassword) passed = false;
            } else {
                setConfirmPasswordError(false);
                setConfirmPasswordErrorMessage('');
            }
        }
        return passed;
    };

    const handlePasswordChange = (e) => {
        const { value: newPassword } = e.target;
        setPassword(newPassword);
        validatePassword(newPassword);
    };

    const validateConfirmPassword = (value) => {
        let passed = true;
        if (apiErrorMessage) setApiErrorMessage('');
        if (!checkConfirmPassword) setCheckConfirmPassword(true);
        if (confirmPasswordError && confirmPasswordErrorMessage) passed = false;
        if (!value) {
            setConfirmPasswordError(true);
            passed = false;
        } else {
            setConfirmPasswordError(false);
        }
        if (value !== password) {
            setConfirmPasswordError(true);
            setConfirmPasswordErrorMessage('passwords do not match');
            passed = false;
        } else {
            setConfirmPasswordErrorMessage('');
        }
        return passed;
    };

    const handleConfirmPasswordChange = (e) => {
        const { value: newConfirmPassword } = e.target;
        setConfirmPassword(newConfirmPassword);
        validateConfirmPassword(newConfirmPassword);
    };

    const checkFields = () => {
        let passed = true;
        if (!validatePassword(password)) passed = false;
        if (!validateConfirmPassword(confirmPassword)) passed = false;
        return passed;
    };

    const handleResetPasswordClick = (e) => {
        e.preventDefault();

        if (!checkFields()) return;
        setLoading(true);
        confirmPasswordReset(email, password, otp)
            .then(() => {
                setPasswordReset(true);
                setLoading(false);
            })
            .catch((error) => {
                if (isMounted.current) {
                    setApiErrorMessage(error.message);
                    setLoading(false);
                }
            });
    };

    const handleReturnToLoginClick = (e) => {
        e.preventDefault();
        navigate(
            `${roleQueryParam === ROLES.CREATOR ? ROUTES.CREATOR.LOGIN : ROUTES.STUDENT.LOGIN}?login_type=${
                AUTHENTICATION_TYPES.EMAIL
            }`,
        );
    };

    useEffect(() => {
        isMounted.current = true;
        if (!otp) setApiErrorMessage('confirmation code is required');
        if (!email) setApiErrorMessage('email is required');
        if (!isEmailValid(email)) setApiErrorMessage('email is not valid');
        return () => {
            isMounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Card
            loading={loading}
            onSubmit={passwordReset ? handleReturnToLoginClick : handleResetPasswordClick}
            buttonLabel={passwordReset ? 'Return to log in page' : 'Reset password'}
            apiErrorMessage={apiErrorMessage}>
            <div className="heading__md--medium mb-6">Reset Password</div>
            {passwordReset ? (
                <div className="paragraph__sm mb-6">Password changed successfully.</div>
            ) : (
                <>
                    <TextInput
                        value={password}
                        placeholder="password"
                        type={TEXT_INPUT_TYPE.PASSWORD}
                        onChange={handlePasswordChange}
                        required
                        error={checkPassword && passwordError}
                        autoCorrect={TEXT_INPUT_AUTO_CORRECT.OFF}
                        autoCapitalize={TEXT_INPUT_AUTO_CAPITALIZE.NONE}
                        spellCheck={TEXT_INPUT_SPELL_CHECK.FALSE}
                    />
                    <TextInput
                        value={confirmPassword}
                        placeholder="confirm password"
                        type={TEXT_INPUT_TYPE.PASSWORD}
                        onChange={handleConfirmPasswordChange}
                        required
                        error={checkConfirmPassword && confirmPasswordError}
                        errorMsg={confirmPasswordErrorMessage}
                        autoCorrect={TEXT_INPUT_AUTO_CORRECT.OFF}
                        autoCapitalize={TEXT_INPUT_AUTO_CAPITALIZE.NONE}
                        spellCheck={TEXT_INPUT_SPELL_CHECK.FALSE}
                    />
                    <div className="paragraph__sm mb-4 pt-2">* indicates a required field</div>
                    <PasswordValidator validate={checkPassword} password={password} />
                </>
            )}
        </Card>
    );
}
