/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Disclosure, Transition } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import { getAnimationProps } from '@/util/helpers';

export default function ModuleButton({ label, selected, onClick, children, hideSections = false }) {
    const moduleLabelRef = useRef();
    const handleLabelClick = () => {
        if (typeof onClick === 'function') onClick();
    };

    useEffect(() => {
        if (selected) {
            moduleLabelRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected]);

    if (hideSections) {
        return (
            <div className="flex flex-row">
                <div ref={moduleLabelRef} className="w-full" onClick={handleLabelClick} role="button" tabIndex="-1">
                    <div
                        className={classNames(
                            'heading__xs--medium flex w-full items-center justify-between rounded-md bg-neutral-25 px-2.5 py-3.5',
                            { 'bg-neutral-100': selected },
                        )}>
                        <div className="flex-grow">{label}</div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="flex flex-row">
            <div ref={moduleLabelRef} className="w-full" onClick={handleLabelClick} role="button" tabIndex="-1">
                <Disclosure defaultOpen={selected}>
                    {({ open }) => (
                        <div className="flex w-full flex-col">
                            <Disclosure.Button
                                as="div"
                                className={classNames(
                                    'heading__xs--medium flex w-full items-center justify-between rounded-md bg-neutral-25 px-2.5 py-3.5',
                                    { 'bg-neutral-100': selected },
                                )}>
                                <div className="flex-grow">{label}</div>
                                {open ? (
                                    <ChevronUpIcon className="ml-2 inline-block h-4 w-4" />
                                ) : (
                                    <ChevronDownIcon className="ml-2 h-4 w-4" />
                                )}
                            </Disclosure.Button>
                            {/* TODO: this animation can be improved if we use javascript instead of CSS; the problem is that 
                                scaleY doesn't resize the height of the panel element as the animation is transitioning so you 
                                end up getting a jarring switch of height once the panel is no longer a part of the DOM. 
                                See this for more info: https://css-tricks.com/using-css-transitions-auto-dimensions/ 
                                and this: https://eliteionic.com/tutorials/high-performance-animated-accordion-list-ionic/ 
                                The second link may not be suitable for the builder ModuleLabel because of its border */}
                            <Transition
                                appear
                                {...getAnimationProps(
                                    {
                                        transition: 'duration-150 origin-top ease-out',
                                        from: 'scale-y-0 opacity-0',
                                        to: 'scale-y-100 opacity-100',
                                    },
                                    {
                                        transition: 'duration-150 ease-out',
                                        from: 'h-4 opacity-100',
                                        to: 'h-0 opacity-0',
                                    },
                                )}>
                                <Disclosure.Panel className="bg-white py-2.5 pl-6 pr-0">
                                    {({ close }) => {
                                        if (open && !selected) {
                                            // This timeout is needed to avoid rendering errors in the Disclosure panel
                                            setTimeout(() => close(), 0);
                                            return null;
                                        }
                                        return <>{children}</>;
                                    }}
                                </Disclosure.Panel>
                            </Transition>
                        </div>
                    )}
                </Disclosure>
            </div>
        </div>
    );
}
