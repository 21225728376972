/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-bitwise */
import { createContext, useContext, useState, useEffect, useRef, Fragment } from 'react';
import classNames from 'classnames';
import { Portal } from 'react-portal';
import { Transition } from '@headlessui/react';
import { XIcon, CheckCircleIcon, ExclamationIcon, BanIcon } from '@heroicons/react/outline';
import { getAnimationProps } from '@/util/helpers';
import useTimeout from '@/util/useTimeout';
import { useAuth } from '@/auth/AuthProvider';

export const TOAST_POSITION = {
    TOP: 'top',
    BOTTOM: 'bottom',
};

export const TOAST_MESSAGE_VARIANT = {
    DEFAULT: 'default',
    SECONDARY: 'secondary',
    WARNING: 'warning',
    ERROR: 'error',
};

function ToastContainer({ position = TOAST_POSITION.TOP, toasts, remove }) {
    let positionClasses;
    switch (position) {
        case TOAST_POSITION.TOP:
            positionClasses = 'top-0 left-1/2 transform -translate-x-1/2';
            break;
        default:
            positionClasses = 'bottom-0 left-1/2 transform -translate-x-1/2';
    }

    const containerClasses = classNames(
        'fixed w-full pointer-events-none max-h-screen overflow-hidden z-80',
        positionClasses,
    );

    if (!Array.isArray(toasts) || toasts.length === 0) return '';
    return (
        <Portal>
            <div className={containerClasses}>
                {toasts.map((toast) => {
                    return (
                        <div className="pointer-events-auto flex w-full" key={toast.id}>
                            <ToastMessage
                                id={toast.id}
                                message={toast.message}
                                variant={toast.variant}
                                onRemove={(id) => remove(id)}
                                timeout={toast.timeout}
                            />
                        </div>
                    );
                })}
            </div>
        </Portal>
    );
}

// This value should be a little higher than the `leave` prop's transition property, which is generated
// by the getAnimationProps(), in the Transition component below.
const ON_REMOVE_TIMEOUT = 125;

function ToastMessage({ id, message, variant = TOAST_MESSAGE_VARIANT.DEFAULT, timeout = null, onRemove }) {
    const [visible, setVisible] = useState(true);
    const isMounted = useRef(true);

    useTimeout(() => {
        if (isMounted.current && timeout >= 0) setVisible(false);
    }, timeout);

    let variantClasses;
    let Icon;
    switch (variant) {
        case TOAST_MESSAGE_VARIANT.SECONDARY:
            variantClasses = 'bg-primary-500 text-white text-center py-3 px-8';
            break;
        case TOAST_MESSAGE_VARIANT.WARNING:
            Icon = ExclamationIcon;
            variantClasses = 'my-2 mx-3 rounded-md bg-warning-50 text-warning-500 shadow-md text-left py-5 px-4';
            break;
        case TOAST_MESSAGE_VARIANT.ERROR:
            Icon = BanIcon;
            variantClasses = 'my-2 mx-3 rounded-md bg-error-50 text-error-500 shadow-md text-left py-5 px-4';
            break;
        default:
            Icon = CheckCircleIcon;
            variantClasses = 'my-2 mx-3 rounded-md bg-success-50 text-success-500 shadow-md text-left py-5 px-4';
    }

    const toastClasses = classNames('relative paragraph__sm--semibold w-full', variantClasses);

    useEffect(() => {
        if (!visible) {
            setTimeout(() => onRemove(id), ON_REMOVE_TIMEOUT);
        }
    }, [visible]);

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);

    return (
        <Transition show={visible} appear {...getAnimationProps()} as={Fragment}>
            <div className={toastClasses}>
                {Icon && <Icon className="mr-3 inline-block w-em-2 -translate-y-px" />}
                {typeof message === 'string' ? <span>{message}</span> : message}
                <button
                    type="button"
                    className="absolute right-3 top-1/2 -translate-y-1/2 transform"
                    onClick={() => {
                        setVisible(false);
                    }}>
                    <XIcon className="h-em-2 w-em-2" />
                </button>
            </div>
        </Transition>
    );
}

const ToastContext = createContext();

export const useToast = (position = TOAST_POSITION.TOP) => {
    const toastContext = useContext(ToastContext);

    useEffect(() => {
        toastContext.setPosition(position);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [position]);

    return {
        toasts: toastContext.toast,
        pushDefault: toastContext.pushDefault,
        pushSecondary: toastContext.pushSecondary,
        pushWarning: toastContext.pushWarning,
        pushError: toastContext.pushError,
        remove: toastContext.remove,
        clearAll: toastContext.clearAll,
    };
};

const DEFAULT_TIMEOUT = 2500;

export default function ToastProvider({ children }) {
    const [toasts, setToasts] = useState([]);
    const [containerPosition, setContainerPosition] = useState(TOAST_POSITION.TOP);
    const isMounted = useRef(true);
    const { authenticated } = useAuth();

    const push = (message, variant, timeout = DEFAULT_TIMEOUT) => {
        const newToast = {
            id: Math.random(),
            message,
            variant,
            timeout,
        };
        if (isMounted.current) {
            setToasts((prevState) => [...prevState, newToast]);
        }
    };

    const pushDefault = (message, timeout) => push(message, TOAST_MESSAGE_VARIANT.DEFAULT, timeout);
    const pushSecondary = (message, timeout) => push(message, TOAST_MESSAGE_VARIANT.SECONDARY, timeout);
    const pushWarning = (message, timeout) => push(message, TOAST_MESSAGE_VARIANT.WARNING, timeout);
    const pushError = (message, timeout) => push(message, TOAST_MESSAGE_VARIANT.ERROR, timeout);

    const clearAll = () => setToasts([]);

    const remove = (id) => {
        if (isMounted.current) setToasts((prevState) => prevState.filter((e) => e.id !== id));
    };

    useEffect(() => {
        if (!authenticated) clearAll();
    }, [authenticated]);

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);

    return (
        <ToastContext.Provider
            value={{
                toasts,
                pushDefault,
                pushSecondary,
                pushWarning,
                pushError,
                remove,
                setPosition: setContainerPosition,
                clearAll,
            }}>
            <ToastContainer toasts={toasts} position={containerPosition} remove={remove} />
            {children}
        </ToastContext.Provider>
    );
}
