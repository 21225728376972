import { ChevronRightIcon } from '@heroicons/react/outline';

export default function CreatorSelectorButton({ label, onClick }) {
    return (
        <button
            type="button"
            className="w-full rounded-lg border-0.5 border-neutral-100 bg-white p-5"
            onClick={typeof onClick === 'function' ? onClick : () => {}}>
            <div className="paragraph__sm--semibold flex justify-between">
                <div>{label}</div>
                <ChevronRightIcon className="h-em-2" />
            </div>
        </button>
    );
}
