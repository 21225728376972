import { useReducer } from 'react';
import set from 'lodash/set';
import get from 'lodash/get';
import { deepCopy, getIdsDictionary } from '@/util/helpers';
import { DEFAULT_FONT_FAMILY } from '@/system/FontLoader';
import { getSectionStorageFilenames } from '@/workbook/builder/sections/helpers';
import { DEFAULT_SCHEMA_STYLES } from '@/system/styles';

export const ACTIONS = {
    SET: 'set',
    UPDATE_WORKBOOK_NAME: 'update_workbook_name',
    UPDATE_WORKBOOK_DRAFT: 'update_workbook_draft',
    UPDATE_WORKBOOK_SCHEMA_PROP: 'update_workbook_schema_prop',
    UPDATE_WORKBOOK_SCHEMA_FILE_PROP: 'update_workbook_schema_file_prop',
    UPDATE_WORKBOOK_COMMENTING: 'update_workbook_commenting',
    APPEND_MODULE: 'append_module',
    INSERT_MODULE: 'insert_module',
    SELECT_MODULE: 'select_module',
    SELECT_PREV_MODULE: 'select_prev_module',
    SELECT_NEXT_MODULE: 'select_next_module',
    UPDATE_MODULE_TITLE: 'update_module_title',
    DELETE_MODULE: 'delete_module',
    MOVE_MODULE: 'move_module',
    APPEND_SECTION: 'append_section',
    SELECT_SECTION: 'select_section',
    INSERT_SECTION: 'insert_section',
    UPDATE_SECTION_SCHEMA_PROP: 'update_section_schema_prop',
    UPDATE_SECTION_SCHEMA_FILE_PROP: 'update_section_schema_file_prop',
    MOVE_SECTION: 'move_section',
    DELETE_SECTION: 'delete_section',
    UPDATE_VERSION_ID: 'update_version_id',
    UPDATE_COHORT_ID: 'update_cohort_id',
    SET_FILES_CLEANUP_ID: 'set_files_cleanup_id',
    RESET_FILES_CLEANUP_ID: 'reset_files_cleanup_id',
    SET_PUBLIC: 'set_public',
    LAZY_APPEND_SECTION: 'lazy_append_section',
    SELECT_EDITING_MODULE: 'select_editing_module',
    CLEAR_EDITING_MODULE: 'clear_editing_module',
};

export const getDefaultSchema = () => ({
    logo: {
        storage_filename: '',
        filename: '',
    },
    thumbnail: {
        storage_filename: '',
        filename: '',
    },
    pageColor: DEFAULT_SCHEMA_STYLES.PAGE_COLOR,
    textStyling: {
        title: {
            fontFamily: DEFAULT_FONT_FAMILY,
            color: DEFAULT_SCHEMA_STYLES.TEXT_STYLING.TITLE.COLOR,
        },
        subtitle: {
            fontFamily: DEFAULT_FONT_FAMILY,
            color: DEFAULT_SCHEMA_STYLES.TEXT_STYLING.SUBTITLE.COLOR,
        },
        body: {
            fontFamily: DEFAULT_FONT_FAMILY,
            color: DEFAULT_SCHEMA_STYLES.TEXT_STYLING.BODY.COLOR,
        },
    },
    buttonStyling: {
        fillColor: DEFAULT_SCHEMA_STYLES.BUTTON_STYLING.FILL_COLOR,
        textColor: DEFAULT_SCHEMA_STYLES.BUTTON_STYLING.TEXT_COLOR,
        hoverColor: DEFAULT_SCHEMA_STYLES.BUTTON_STYLING.HOVER_COLOR,
        hoverTextColor: DEFAULT_SCHEMA_STYLES.BUTTON_STYLING.HOVER_TEXT_COLOR,
    },
    linkStyling: {
        textColor: DEFAULT_SCHEMA_STYLES.LINK_STYLING.TEXT_COLOR,
    },
    tableStyling: {
        headerBackgroundColor: DEFAULT_SCHEMA_STYLES.TABLE_STYLING.HEADER_BACKGROUND_COLOR,
        lineColor: DEFAULT_SCHEMA_STYLES.TABLE_STYLING.LINE_COLOR,
    },
});

export const getDefaultState = () => {
    return {
        initialized: false,
        id: '',
        name: '',
        description: '',
        schema: getDefaultSchema(),
        draft: false,
        moduleIdsDictionary: {},
        modules: [],
        selectedModule: null,
        editingModule: null,
        sectionIdsDictionary: {},
        selectedSection: null,
        versionId: '',
        cohortId: '',
        filesCleanUpId: '',
        filesToCleanUp: {},
        isPublic: false,
    };
};

export const workbookReducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.SET: {
            const { workbook, modules, versionId, cohortId, isPublic } = action.payload;
            const coverPageSchema = modules[0]?.sections[0]?.schema;
            const hideCoverPageModule = coverPageSchema?.hidden ?? false;
            let selectedModule = modules.length ? modules[0] : null;

            // Return next module if cover page is hidden
            if (hideCoverPageModule) {
                selectedModule = modules.length ? modules[1] : selectedModule;
            }

            return {
                initialized: true,
                id: workbook.id,
                name: workbook.name,
                description: workbook.description,
                schema: workbook.schema,
                draft: !!workbook.draft,
                courseName: workbook.course_name,
                courseURL: workbook.course_url,
                commentingEnabled: !!workbook.commenting_enabled,
                notificationEnabled: !!workbook.notification_enabled,
                moduleIdsDictionary: getIdsDictionary(modules),
                modules,
                selectedModule,
                editingModule: null,
                sectionIdsDictionary: getIdsDictionary(selectedModule ? selectedModule.sections : []),
                selectedSection: selectedModule && selectedModule.sections.length ? selectedModule.sections[0] : null,
                versionId: versionId || '',
                cohortId: cohortId || '',
                filesCleanUpId: '',
                filesToCleanUp: {},
                isPublic: !!isPublic,
            };
        }
        case ACTIONS.UPDATE_VERSION_ID: {
            const versionId = action.payload;
            return { ...state, versionId };
        }
        case ACTIONS.UPDATE_COHORT_ID: {
            const cohortId = action.payload;
            return { ...state, cohortId };
        }
        case ACTIONS.UPDATE_WORKBOOK_NAME: {
            const name = action.payload;
            return { ...state, name };
        }
        case ACTIONS.UPDATE_WORKBOOK_COMMENTING: {
            const commenting = action.payload;
            return { ...state, commenting };
        }
        case ACTIONS.UPDATE_WORKBOOK_DRAFT: {
            const draft = action.payload;
            return { ...state, draft };
        }
        case ACTIONS.UPDATE_WORKBOOK_SCHEMA_PROP: {
            const { path, value } = action.payload;
            const schema = deepCopy(state.schema);
            set(schema, path, value);
            return { ...state, schema };
        }
        case ACTIONS.UPDATE_WORKBOOK_SCHEMA_FILE_PROP: {
            const { path, value, filesCleanUpId } = action.payload;
            const schema = deepCopy(state.schema);
            set(schema, path, value);
            const filesToCleanUp = deepCopy(state.filesToCleanUp);
            const file = get(state.schema, path);
            if (file.storage_filename) filesToCleanUp[filesCleanUpId] = [file.storage_filename];
            return { ...state, schema, filesToCleanUp };
        }
        case ACTIONS.APPEND_MODULE: {
            const modules = deepCopy(state.modules);
            const module = action.payload;
            const moduleIndex = modules.length;
            modules.push(module);
            const moduleIdsDictionary = {
                ...state.moduleIdsDictionary,
                [module.id]: moduleIndex,
            };
            return { ...state, modules, moduleIdsDictionary };
        }
        case ACTIONS.INSERT_MODULE: {
            const { module: newModule, index: newModuleIndex } = action.payload;
            // If the index is at the end of the array, just run the append action
            if (state.modules.length === newModuleIndex)
                return workbookReducer(state, { type: ACTIONS.APPEND_MODULE, payload: newModule });
            const moduleIdsDictionary = {};
            const modules = [];
            let index = 0;
            state.modules.forEach((module, i) => {
                if (i === newModuleIndex) {
                    modules.push(newModule);
                    moduleIdsDictionary[newModule.id] = index;
                    index += 1;
                }
                modules.push(module);
                moduleIdsDictionary[module.id] = index;
                index += 1;
            });
            return {
                ...state,
                modules,
                moduleIdsDictionary,
            };
        }
        case ACTIONS.SELECT_MODULE: {
            const { moduleId, sectionCount } = action.payload;
            const moduleIndex = state.moduleIdsDictionary[moduleId];
            const selectedModule = state.modules[moduleIndex];
            const sections = sectionCount ? selectedModule.sections.slice(0, sectionCount) : selectedModule.sections;
            return {
                ...state,
                selectedModule: {
                    ...selectedModule,
                    sections,
                },
                sectionIdsDictionary: getIdsDictionary(sections),
                selectedSection: selectedModule.sections.length ? selectedModule.sections[0] : null,
            };
        }
        case ACTIONS.SELECT_EDITING_MODULE: {
            const moduleId = action.payload;
            const editingModule = state.modules[state.moduleIdsDictionary[moduleId]];
            return {
                ...state,
                editingModule,
            };
        }
        case ACTIONS.CLEAR_EDITING_MODULE: {
            return {
                ...state,
                editingModule: null,
            };
        }
        case ACTIONS.SELECT_PREV_MODULE: {
            const moduleIndex = state.moduleIdsDictionary[state.selectedModule.id];
            const prevModuleIndex = moduleIndex - 1 >= 0 ? moduleIndex - 1 : null;
            if (prevModuleIndex === null) return state;
            const selectedModule = state.modules[prevModuleIndex];
            return {
                ...state,
                selectedModule,
                sectionIdsDictionary: getIdsDictionary(selectedModule.sections),
                selectedSection: selectedModule.sections.length ? selectedModule.sections[0] : null,
            };
        }
        case ACTIONS.SELECT_NEXT_MODULE: {
            const moduleIndex = state.moduleIdsDictionary[state.selectedModule.id];
            const nextModuleIndex = state.modules.length > moduleIndex + 1 ? moduleIndex + 1 : null;
            if (nextModuleIndex === null) return state;
            const selectedModule = state.modules[nextModuleIndex];
            return {
                ...state,
                selectedModule,
                sectionIdsDictionary: getIdsDictionary(selectedModule.sections),
                selectedSection: selectedModule.sections.length ? selectedModule.sections[0] : null,
            };
        }
        case ACTIONS.UPDATE_MODULE_TITLE: {
            const title = action.payload[0];
            const showTitle = action.payload[1];
            const modules = deepCopy(state.modules);
            modules[state.moduleIdsDictionary[state.editingModule.id]].title = title;
            modules[state.moduleIdsDictionary[state.editingModule.id]].show_title = showTitle;
            const newState = {
                ...state,
                modules,
                editingModule: null,
            };
            if (state.editingModule.id === state.selectedModule.id) {
                const selectedModule = deepCopy(state.selectedModule);
                selectedModule.title = title;
                selectedModule.show_title = showTitle;
                newState.selectedModule = selectedModule;
            }
            return newState;
        }
        case ACTIONS.DELETE_MODULE: {
            const {
                modules: oldModules,
                selectedModule: oldSelectedModule,
                moduleIdsDictionary: oldModuleIdsDictionary,
            } = state;
            if (oldModules.length === 1) return state;
            const { moduleId, filesCleanUpId } = action.payload;
            const moduleIdsDictionary = {};
            const modules = [];
            const filesToCleanUp = deepCopy(state.filesToCleanUp);
            let index = 0;
            oldModules.forEach((module) => {
                if (module.id !== moduleId) {
                    modules.push(module);
                    moduleIdsDictionary[module.id] = index;
                    index += 1;
                } else {
                    let files = [];
                    module.sections.forEach((section) => {
                        files = files.concat(getSectionStorageFilenames(section));
                    });
                    filesToCleanUp[filesCleanUpId] = files;
                }
            });
            let selectedModule = oldSelectedModule;
            if (oldSelectedModule.id === moduleId) {
                selectedModule =
                    oldModuleIdsDictionary[moduleId] === oldModules.length - 1
                        ? modules[modules.length - 1]
                        : modules[oldModuleIdsDictionary[moduleId]];
            }
            return {
                ...state,
                modules,
                moduleIdsDictionary,
                selectedModule,
                sectionIdsDictionary: getIdsDictionary(selectedModule.sections),
                selectedSection: selectedModule.sections.length ? selectedModule.sections[0] : null,
                filesToCleanUp,
            };
        }
        case ACTIONS.MOVE_MODULE: {
            const { fromIndex, toIndex } = action.payload;
            if (fromIndex === toIndex) return state;
            const fromModule = state.modules[fromIndex];
            const toModule = state.modules[toIndex];
            const moduleIdsDictionary = {};
            const modules = [];
            let index = 0;
            state.modules.forEach((module) => {
                if (module.id !== fromModule.id) {
                    if (module.id === toModule.id) {
                        if (toIndex > fromIndex) {
                            modules[index] = module;
                            moduleIdsDictionary[module.id] = index;
                            index += 1;
                            modules[index] = fromModule;
                            moduleIdsDictionary[fromModule.id] = index;
                            index += 1;
                        } else {
                            modules[index] = fromModule;
                            moduleIdsDictionary[fromModule.id] = index;
                            index += 1;
                            modules[index] = module;
                            moduleIdsDictionary[module.id] = index;
                            index += 1;
                        }
                    } else {
                        modules[index] = module;
                        moduleIdsDictionary[module.id] = index;
                        index += 1;
                    }
                }
            });
            return {
                ...state,
                modules,
                moduleIdsDictionary,
            };
        }
        case ACTIONS.APPEND_SECTION: {
            const section = action.payload;
            const modules = deepCopy(state.modules);
            const selectedModule = deepCopy(state.selectedModule);
            const selectedModuleIndex = state.moduleIdsDictionary[state.selectedModule.id];
            const sectionIdsDictionary = {
                ...state.sectionIdsDictionary,
                [section.id]: selectedModule.sections.length,
            };
            modules[selectedModuleIndex].sections.push(section);
            selectedModule.sections.push(section);
            return {
                ...state,
                modules,
                selectedModule,
                sectionIdsDictionary,
                selectedSection: section,
            };
        }
        case ACTIONS.LAZY_APPEND_SECTION: {
            const section = action.payload;
            const selectedModule = deepCopy(state.selectedModule);
            const sectionIdsDictionary = {
                ...state.sectionIdsDictionary,
                [section.id]: selectedModule.sections.length,
            };
            selectedModule.sections.push(section);
            return {
                ...state,
                selectedModule,
                sectionIdsDictionary,
            };
        }
        case ACTIONS.SELECT_SECTION: {
            const sectionId = action.payload;
            const selectedSection = state.selectedModule.sections[state.sectionIdsDictionary[sectionId]];
            return {
                ...state,
                selectedSection,
            };
        }
        case ACTIONS.UPDATE_SECTION_SCHEMA_PROP: {
            const { path, value } = action.payload;
            const schema = deepCopy(state.selectedSection.schema);
            const modules = deepCopy(state.modules);
            const selectedModule = deepCopy(state.selectedModule);
            set(schema, path, value);
            const selectedSection = {
                ...state.selectedSection,
                schema,
            };
            const selectedModuleIndex = state.moduleIdsDictionary[state.selectedModule.id];
            const selectedSectionIndex = state.sectionIdsDictionary[state.selectedSection.id];
            modules[selectedModuleIndex].sections[selectedSectionIndex] = selectedSection;
            selectedModule.sections[selectedSectionIndex] = selectedSection;
            return {
                ...state,
                modules,
                selectedModule,
                selectedSection,
            };
        }
        case ACTIONS.INSERT_SECTION: {
            const { section: newSection, index: newSectionIndex } = action.payload;
            // If the index is at the end of the array, just run the append action
            if (state.selectedModule.sections.length === newSectionIndex)
                return workbookReducer(state, { type: ACTIONS.APPEND_SECTION, payload: newSection });
            const sectionIdsDictionary = {};
            const sections = [];
            let index = 0;
            state.selectedModule.sections.forEach((section, i) => {
                if (i === newSectionIndex) {
                    sections.push(newSection);
                    sectionIdsDictionary[newSection.id] = index;
                    index += 1;
                }
                sections.push(section);
                sectionIdsDictionary[section.id] = index;
                index += 1;
            });
            const modules = deepCopy(state.modules);
            const selectedModuleIndex = state.moduleIdsDictionary[state.selectedModule.id];
            modules[selectedModuleIndex].sections = sections;
            const selectedModule = deepCopy(state.selectedModule);
            selectedModule.sections = sections;
            return {
                ...state,
                modules,
                selectedModule,
                sectionIdsDictionary,
            };
        }
        case ACTIONS.MOVE_SECTION: {
            const { fromIndex, toIndex } = action.payload;
            if (fromIndex === toIndex) return state;
            const fromSection = state.selectedModule.sections[fromIndex];
            const toSection = state.selectedModule.sections[toIndex];
            const sectionIdsDictionary = {};
            const sections = [];
            let index = 0;
            state.selectedModule.sections.forEach((section) => {
                if (section.id !== fromSection.id) {
                    if (section.id === toSection.id) {
                        if (toIndex > fromIndex) {
                            sections[index] = section;
                            sectionIdsDictionary[section.id] = index;
                            index += 1;
                            sections[index] = fromSection;
                            sectionIdsDictionary[fromSection.id] = index;
                            index += 1;
                        } else {
                            sections[index] = fromSection;
                            sectionIdsDictionary[fromSection.id] = index;
                            index += 1;
                            sections[index] = section;
                            sectionIdsDictionary[section.id] = index;
                            index += 1;
                        }
                    } else {
                        sections[index] = section;
                        sectionIdsDictionary[section.id] = index;
                        index += 1;
                    }
                }
            });
            const modules = deepCopy(state.modules);
            const selectedModuleIndex = state.moduleIdsDictionary[state.selectedModule.id];
            modules[selectedModuleIndex].sections = sections;
            const selectedModule = deepCopy(state.selectedModule);
            selectedModule.sections = sections;
            return {
                ...state,
                modules,
                selectedModule,
                sectionIdsDictionary,
            };
        }
        case ACTIONS.DELETE_SECTION: {
            const { sections: oldSections } = state.selectedModule;
            const { sectionId, filesCleanUpId } = action.payload;
            const sectionIdsDictionary = {};
            const sections = [];
            let selectedSection = deepCopy(state.selectedSection);
            const filesToCleanUp = deepCopy(state.filesToCleanUp);
            let index = 0;
            oldSections.forEach((section, i) => {
                if (section.id !== sectionId) {
                    sections.push(section);
                    sectionIdsDictionary[section.id] = index;
                    index += 1;
                } else if (selectedSection && selectedSection.id === section.id && oldSections.length > 1) {
                    selectedSection = i === 0 ? oldSections[1] : oldSections[i - 1];
                }
                if (section.id === sectionId) {
                    filesToCleanUp[filesCleanUpId] = getSectionStorageFilenames(section);
                }
            });
            selectedSection = sections.length === 0 ? null : selectedSection;
            const modules = deepCopy(state.modules);
            const selectedModuleIndex = state.moduleIdsDictionary[state.selectedModule.id];
            modules[selectedModuleIndex].sections = sections;
            const selectedModule = deepCopy(state.selectedModule);
            selectedModule.sections = sections;
            return {
                ...state,
                modules,
                selectedModule,
                sectionIdsDictionary,
                selectedSection,
                filesToCleanUp,
            };
        }
        case ACTIONS.UPDATE_SECTION_SCHEMA_FILE_PROP: {
            const schema = deepCopy(state.selectedSection.schema);
            const { path, value, filesCleanUpId } = action.payload;
            const modules = deepCopy(state.modules);
            const selectedModule = deepCopy(state.selectedModule);
            set(schema, path, value);
            const selectedSection = {
                ...state.selectedSection,
                schema,
            };
            const selectedModuleIndex = state.moduleIdsDictionary[state.selectedModule.id];
            const selectedSectionIndex = state.sectionIdsDictionary[state.selectedSection.id];
            modules[selectedModuleIndex].sections[selectedSectionIndex] = selectedSection;
            selectedModule.sections[selectedSectionIndex] = selectedSection;
            const filesToCleanUp = deepCopy(state.filesToCleanUp);
            const file = get(state.selectedSection.schema, path);
            if (file.storage_filename) filesToCleanUp[filesCleanUpId] = [file.storage_filename];

            return {
                ...state,
                modules,
                selectedModule,
                selectedSection,
                filesToCleanUp,
            };
        }
        case ACTIONS.SET_FILES_CLEANUP_ID: {
            const filesCleanUpId = action.payload;
            return { ...state, filesCleanUpId };
        }
        case ACTIONS.RESET_FILES_CLEANUP_ID: {
            const filesToCleanUp = deepCopy(state.filesToCleanUp);
            delete filesToCleanUp[state.filesCleanUpId];
            return {
                ...state,
                filesCleanUpId: '',
                filesToCleanUp,
            };
        }
        case ACTIONS.SET_PUBLIC: {
            const isPublic = action.payload;
            return { ...state, isPublic };
        }
        default:
            throw new Error('Action not supported ');
    }
};

const useWorkbookReducer = () => {
    return useReducer(workbookReducer, getDefaultState());
};

export default useWorkbookReducer;
