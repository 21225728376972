import { Route, Routes } from 'react-router-dom';
import CreatorLogin from '@/auth/CreatorLogin';
import StudentLogin from '@/auth/StudentLogin';
import CreatorSignup from '@/auth/CreatorSignup';
import ConfirmCreatorSignup from '@/auth/ConfirmCreatorSignup';
import ConfirmSignup from '@/auth/ConfirmSignup';
import ForgotPassword from '@/auth/ForgotPassword';
import ConfirmForgotPassword from '@/auth/ConfirmForgotPassword';
import ConfirmForgotPasswordEmbed from '@/auth/ConfirmForgotPasswordEmbed';
import ThinkificAuthComplete from '@/auth/ThinkificAuthComplete';
import TeachableAuthComplete from '@/auth/TeachableAuthComplete';
import ThinkificAuthCompleteFromModal from '@/auth/ThinkificAuthCompleteFromModal';
import TeachableAuthCompleteFromModal from '@/auth/TeachableAuthCompleteFromModal';
import GoogleAuthComplete from '@/auth/GoogleAuthComplete';
import CustomSsoAuthComplete from '@/auth/CustomSsoAuthComplete';
import EmbedLogin from '@/embed/EmbedLogin';
import { REACT_ROUTER_PATHS } from '@/util/constants';
import WorkbookDemo from '@/workbook/demo/Workbook';
import DemoProvider from '@/workbook/demo/WorkbookVersionProvider';
import PublicWorkbook from '@/workbook/public/Workbook';
import PublicWorkbookProvider from '@/workbook/public/WorkbookVersionProvider';
import WorkbookArtifact from '@/workbook/artifact/Workbook';
import StudentWorkbookArtifact from '@/workbook/studentArtifact/Workbook';
import PubliclySharedWorkbook from '@/workbook/publiclyShared/Workbook';
import WorkbookThumbnail from '@/workbook/thumbnail/Workbook';
import WorkbookJsonLoaderProvider from '@/workbook/artifact/WorkbookJsonLoaderProvider';
import WorkbookVersionJsonLoaderProvider from '@/workbook/studentArtifact/WorkbookVersionJsonLoaderProvider';
import ThinkificInstall from '@/navigation/ThinkificInstall';
import RolesProvider from '@/navigation/RolesProvider';
import RoleRoutes from '@/navigation/RoleRoutes';
import AppNavbar from '@/navigation/AppNavbar';
import TeachableAuthStart from '@/auth/TeachableAuthStart';

export default function AppRoutes() {
    return (
        <Routes>
            {/* Auth */}
            <Route path={REACT_ROUTER_PATHS.CREATOR.LOGIN} element={<CreatorLogin />} />
            <Route path={REACT_ROUTER_PATHS.CREATOR.SIGNUP} element={<CreatorSignup />} />
            <Route path={REACT_ROUTER_PATHS.CREATOR.CONFIRM_SIGNUP} element={<ConfirmCreatorSignup />} />
            <Route path={REACT_ROUTER_PATHS.STUDENT.LOGIN} element={<StudentLogin />} />
            <Route path={REACT_ROUTER_PATHS.STUDENT.CONFIRM_SIGNUP} element={<ConfirmSignup />} />
            <Route path={REACT_ROUTER_PATHS.MEMBER.CONFIRM_SIGNUP} element={<ConfirmSignup />} />
            <Route path={REACT_ROUTER_PATHS.COMMON.EMBED_LOGIN} element={<EmbedLogin />} />
            <Route path={REACT_ROUTER_PATHS.COMMON.FORGOT_PASSWORD} element={<ForgotPassword />} />
            <Route path={REACT_ROUTER_PATHS.COMMON.CONFIRM_FORGOT_PASSWORD} element={<ConfirmForgotPassword />} />
            <Route
                path={REACT_ROUTER_PATHS.COMMON.CONFIRM_FORGOT_PASSWORD_EMBED}
                element={<ConfirmForgotPasswordEmbed />}
            />

            {/* Thinkific */}
            <Route path={REACT_ROUTER_PATHS.COMMON.THINKIFIC_AUTH_COMPLETE} element={<ThinkificAuthComplete />} />
            <Route
                path={REACT_ROUTER_PATHS.COMMON.THINKIFIC_AUTH_COMPLETE_FROM_MODAL}
                element={<ThinkificAuthCompleteFromModal />}
            />
            <Route path={REACT_ROUTER_PATHS.CREATOR.INSTALL_THINKIFIC} element={<ThinkificInstall />} />
            {/* Teachable */}
            <Route path={REACT_ROUTER_PATHS.COMMON.TEACHABLE_AUTH_COMPLETE} element={<TeachableAuthComplete />} />
            <Route path={REACT_ROUTER_PATHS.COMMON.TEACHABLE_AUTH_START} element={<TeachableAuthStart />} />
            <Route
                path={REACT_ROUTER_PATHS.COMMON.TEACHABLE_AUTH_COMPLETE_FROM_MODAL}
                element={<TeachableAuthCompleteFromModal />}
            />
            {/* Google */}
            <Route path={REACT_ROUTER_PATHS.COMMON.GOOGLE_AUTH_COMPLETE} element={<GoogleAuthComplete />} />

            {/* Custom SSO */}
            <Route path={REACT_ROUTER_PATHS.COMMON.CUSTOM_SSO_AUTH_COMPLETE} element={<CustomSsoAuthComplete />} />

            {/* Demo */}
            <Route
                path={REACT_ROUTER_PATHS.COMMON.DEMO}
                element={
                    <DemoProvider>
                        <WorkbookDemo />
                    </DemoProvider>
                }
            />

            {/*  Workbook Thumbnail */}
            <Route
                path={REACT_ROUTER_PATHS.COMMON.THUMBNAIL}
                element={
                    <WorkbookJsonLoaderProvider>
                        <WorkbookThumbnail />
                    </WorkbookJsonLoaderProvider>
                }
            />

            {/*  Workbook Artifact (PDF) */}
            <Route
                path={REACT_ROUTER_PATHS.COMMON.ARTIFACT}
                element={
                    <WorkbookJsonLoaderProvider>
                        <WorkbookArtifact />
                    </WorkbookJsonLoaderProvider>
                }
            />
            <Route
                path={REACT_ROUTER_PATHS.STUDENT.ARTIFACT}
                element={
                    <WorkbookVersionJsonLoaderProvider>
                        <StudentWorkbookArtifact />
                    </WorkbookVersionJsonLoaderProvider>
                }
            />

            {/* Public workbook */}
            <Route
                path={REACT_ROUTER_PATHS.COMMON.PUBLIC_WORKBOOK}
                element={
                    <PublicWorkbookProvider>
                        <PublicWorkbook />
                    </PublicWorkbookProvider>
                }
            />

            {/* Publicly shared workbook */}
            <Route
                path={REACT_ROUTER_PATHS.COMMON.PUBLIC}
                element={
                    <WorkbookJsonLoaderProvider>
                        <PubliclySharedWorkbook full />
                    </WorkbookJsonLoaderProvider>
                }
            />

            {/*  Role routes */}
            <Route
                path="*"
                element={
                    <RolesProvider>
                        <AppNavbar />
                        <RoleRoutes />
                    </RolesProvider>
                }
            />
        </Routes>
    );
}
