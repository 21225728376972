import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import useUserFlags from '@/util/useUserFlags';
import { useAuth } from '@/auth/AuthProvider';
import { useToast } from '@/system/ToastProvider';
import LoadingSpinner from '@/system/LoadingSpinner';
import { SingleActionModal } from '@/system/Modals';
import { useRoles } from '@/navigation/RolesProvider';
import { UNVERIFIED_USER_PLACEHOLDER } from '@/util/constants';

export default function UserFlagBar({ settingsRoute }) {
    const flags = useUserFlags();

    if (flags.isEmailNotVerified) {
        return <EmailVerifyFlagBar />;
    }
    if (flags.isFirstNameNotSet || flags.isLastNameNotSet) {
        return <SetFirstNameLastNameBar settingsRoute={settingsRoute} />;
    }

    return null;
}

function EmailVerifyFlagBar() {
    const isMounted = useRef(true);
    const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
    const { user, sendPasswordResetEmail } = useAuth();
    const [emailSent, setEmailSent] = useState(false);
    const { pushError } = useToast();
    const { currentRole } = useRoles();

    const handleResetPasswordClick = async () => {
        sendPasswordResetEmail(user.email, currentRole)
            .then(() => {
                if (isMounted.current) {
                    setEmailSent(true);
                    setShowLoadingSpinner(false);
                }
            })
            .catch((error) => {
                if (isMounted.current) {
                    pushError(error.message, null);
                    setShowLoadingSpinner(false);
                }
            });
    };

    const handleCloseModal = () => {
        setEmailSent(false);
    };

    return (
        <>
            {showLoadingSpinner && <LoadingSpinner />}
            <div className="top-0 left-0 w-full bg-primary text-white text-center py-2 font-bold flex justify-center items-center gap-1">
                <span className="select-none">Secure your progress!</span>
                <button
                    type="button"
                    onClick={handleResetPasswordClick}
                    className="font-bold underline transition-[color] hover:text-[rgba(256,256,256,0.75)]">
                    Set your password
                </button>
            </div>
            <SingleActionModal
                show={emailSent}
                title="Email sent"
                desc=" An email with instructions has been sent to you. If you don’t see it make sure to check your spam or junk folders."
                actionButtonLabel="Close"
                onActionClick={handleCloseModal}
            />
        </>
    );
}

function SetFirstNameLastNameBar({ settingsRoute }) {
    const { user } = useAuth();

    if (user.firstName !== UNVERIFIED_USER_PLACEHOLDER && user.lastName !== UNVERIFIED_USER_PLACEHOLDER) {
        return null;
    }

    return (
        <div className="top-0 left-0 w-full bg-primary text-white text-center py-2 font-bold flex justify-center items-center gap-1">
            <span className="select-none">Make it official!</span>
            <Link
                to={`${settingsRoute}?completing=true`}
                className="font-bold underline transition-[color] hover:text-[rgba(256,256,256,0.75)] select-none">
                Update your profile
            </Link>
        </div>
    );
}
