import { Link } from 'react-router-dom';
import { ROUTES, SETTINGS_SECTIONS } from '@/util/constants';

export default function NonVerifiedEmailNotification() {
    return (
        <div>
            <p className="mb-1">Please verify your email to avoid getting locked out of your Wobo account!</p>
            Go to{' '}
            <Link to={`${ROUTES.CREATOR.SETTINGS}?section=${SETTINGS_SECTIONS.PROFILE}`} className="underline">
                Profile
            </Link>
        </div>
    );
}
