import classNames from 'classnames';

export default function TableLayoutIcon4({ className = '', style = {} }) {
    return (
        <svg
            width="39"
            height="39"
            viewBox="0 0 39 39"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={classNames('w-em', className)}
            style={style}>
            <g filter="url(#filter0_dd_302_2955)">
                <g clipPath="url(#clip0_302_2955)">
                    <rect x="3.396" y="2.12598" width="15.9897" height="9.8142" fill="currentColor" />
                    <rect x="3.396" y="11.9404" width="15.9897" height="22.1858" fill="currentColor" />
                    <rect x="19.3862" y="2.12598" width="15.9897" height="9.8142" fill="currentColor" />
                    <rect x="19.3862" y="11.9404" width="15.9897" height="22.1858" fill="currentColor" />
                    <path d="M3.37598 11.9404L35.376 11.9404" stroke="#272727" />
                    <path d="M19.3862 2.12598L19.3859 34.1258" stroke="#272727" />
                </g>
            </g>
            <defs>
                <filter
                    id="filter0_dd_302_2955"
                    x="0.375977"
                    y="0.125977"
                    width="38"
                    height="38"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_302_2955" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="1.5" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="effect1_dropShadow_302_2955" result="effect2_dropShadow_302_2955" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_302_2955" result="shape" />
                </filter>
                <clipPath id="clip0_302_2955">
                    <path
                        d="M3.37598 4.12598C3.37598 3.02141 4.27141 2.12598 5.37598 2.12598H33.376C34.4805 2.12598 35.376 3.02141 35.376 4.12598V32.126C35.376 33.2305 34.4805 34.126 33.376 34.126H5.37598C4.27141 34.126 3.37598 33.2305 3.37598 32.126V4.12598Z"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}
