import classNames from 'classnames';

export const OUTLINE_SIZES = {
    DEFAULT: 'default',
    LG: 'lg',
};

export default function HeadingWithOutline({
    label,
    size = OUTLINE_SIZES.DEFAULT,
    className = '',
    outlineClassName = '',
    outlineStyle = {},
}) {
    const large = size === OUTLINE_SIZES.LG;
    return (
        <div
            className={classNames(
                'relative',
                {
                    'heading__md--semibold': !large,
                    'heading__lg--bold': large,
                },
                className,
            )}>
            <div className={classNames('absolute', outlineClassName)} style={outlineStyle}>
                <img
                    src={large ? '/images/heading-outline-large.png' : '/images/heading-outline.png'}
                    alt=""
                    className="h-full w-full"
                />
            </div>
            {label}
        </div>
    );
}
