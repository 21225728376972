import { Suspense, lazy } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '@/auth/AuthProvider';
import PaidRoute from '@/navigation/PaidRoute';
import MinimumWidthRoute from '@/navigation/MinimumWidthRoute';
import WorkbookProvider from '@/workbook/builder/WorkbookProvider';
import ProcessPayment from '@/payment/ProcessPayment';
import CohortProvider from '@/cohorts/StudentsProvider';
import { ROLES, REACT_ROUTER_PATHS, ROUTES } from '@/util/constants';
import WorkbookResponsesProvider from '@/workbook/studentReadOnly/WorkbookVersionProvider';
import LoadingSpinner from '@/system/LoadingSpinner';

const Dashboard = lazy(() => import('@/dashboard/Dashboard'));
const ThinkificSignupSuccess = lazy(() => import('@/auth/ThinkificSignupSuccess'));
const GoogleSignupSuccess = lazy(() => import('@/auth/GoogleSignupSuccess'));
const Settings = lazy(() => import('@/settings/creator/Settings'));
const Builder = lazy(() => import('@/workbook/builder/Builder'));
const Preview = lazy(() => import('@/workbook/builder/preview/Preview'));
const PreviewContent = lazy(() => import('@/workbook/builder/preview/PreviewContent'));
const Cohorts = lazy(() => import('@/cohorts/Dashboard'));
const WorkbookResponses = lazy(() => import('@/workbook/studentReadOnly/Workbook'));
const Payment = lazy(() => import('@/payment/Payment'));
const Trial = lazy(() => import('@/payment/Trial'));
const TemplateSelectorV2 = lazy(() => import('@/workbook/templateSelector/TemplateSelectorV2'));
const Admin = lazy(() => import('@/admin/Admin'));

export default function CreatorRoutes() {
    const { user } = useAuth();
    const location = useLocation();

    if (user.roles.includes(ROLES.CREATOR))
        return (
            <Suspense fallback={<LoadingSpinner />}>
                <Routes>
                    <Route
                        index
                        element={
                            <PaidRoute>
                                <Dashboard />
                            </PaidRoute>
                        }
                    />
                    <Route
                        path={REACT_ROUTER_PATHS.CREATOR.THINKIFIC_SIGNUP_SUCCESS}
                        element={<ThinkificSignupSuccess />}
                    />
                    <Route path={REACT_ROUTER_PATHS.CREATOR.GOOGLE_SIGNUP_SUCCESS} element={<GoogleSignupSuccess />} />
                    <Route path={REACT_ROUTER_PATHS.CREATOR.PAYMENT} element={<Payment />} />
                    <Route path={REACT_ROUTER_PATHS.CREATOR.TRIAL} element={<Trial />} />
                    <Route path={REACT_ROUTER_PATHS.CREATOR.PROCESS_PAYMENT} element={<ProcessPayment />} />
                    <Route
                        path={REACT_ROUTER_PATHS.CREATOR.WORKBOOKS_DASHBOARD}
                        element={
                            <PaidRoute>
                                <Dashboard />
                            </PaidRoute>
                        }
                    />
                    <Route
                        path={REACT_ROUTER_PATHS.CREATOR.SETTINGS}
                        element={
                            <PaidRoute>
                                <Settings key={location.key} />
                            </PaidRoute>
                        }
                    />
                    <Route
                        path={REACT_ROUTER_PATHS.CREATOR.ADMIN}
                        element={
                            <PaidRoute>
                                <Admin key={location.key} />
                            </PaidRoute>
                        }
                    />
                    <Route
                        path={REACT_ROUTER_PATHS.CREATOR.BUILDER}
                        element={
                            <PaidRoute>
                                <MinimumWidthRoute>
                                    <WorkbookProvider>
                                        <Builder />
                                    </WorkbookProvider>
                                </MinimumWidthRoute>
                            </PaidRoute>
                        }
                    />
                    <Route
                        path={REACT_ROUTER_PATHS.CREATOR.PREVIEW}
                        element={
                            <PaidRoute>
                                <Preview />
                            </PaidRoute>
                        }
                    />
                    <Route
                        path={REACT_ROUTER_PATHS.CREATOR.PREVIEW_CONTENT}
                        element={
                            <PaidRoute>
                                <WorkbookProvider>
                                    <PreviewContent />
                                </WorkbookProvider>
                            </PaidRoute>
                        }
                    />
                    <Route
                        path={REACT_ROUTER_PATHS.CREATOR.COHORTS_DASHBOARD}
                        element={
                            <PaidRoute>
                                <MinimumWidthRoute>
                                    <CohortProvider>
                                        <Cohorts />
                                    </CohortProvider>
                                </MinimumWidthRoute>
                            </PaidRoute>
                        }
                    />
                    <Route
                        path={REACT_ROUTER_PATHS.CREATOR.STUDENT_RESPONSES}
                        element={
                            <PaidRoute>
                                <WorkbookResponsesProvider>
                                    <WorkbookResponses />
                                </WorkbookResponsesProvider>
                            </PaidRoute>
                        }
                    />
                    <Route
                        path={REACT_ROUTER_PATHS.CREATOR.TEMPLATE_SELECTOR}
                        element={
                            <PaidRoute>
                                <TemplateSelectorV2 />
                            </PaidRoute>
                        }
                    />
                    <Route path="*" element={<Navigate to={ROUTES.CREATOR.WORKBOOKS_DASHBOARD} replace />} />
                </Routes>
            </Suspense>
        );
    return <Navigate to="/" state={{ from: location }} />;
}
