import { getSectionViewer } from '@/workbook/builder/sections/helpers';

export default function Viewer({ workbook }) {
    const { schema, modules } = workbook;
    const section = modules[0].sections[0];
    const WorkbookCoverPage = getSectionViewer(section);

    return (
        <div id="thumbnail-container" className="h-[1088px] w-[816px] origin-top-left scale-[0.25]">
            {WorkbookCoverPage && <WorkbookCoverPage schema={section.schema} workbookSchema={schema} />}
        </div>
    );
}
