import classNames from 'classnames';
import LoadingSpinner from '@/util/LoadingSpinner';

const BUTTON_VARIANT = {
    DEFAULT: 'default',
    SECONDARY: 'secondary',
    WHITE: 'white',
    CANCEL: 'cancel',
    MOBILE: 'mobile',
    EMBED_NAVBAR: 'embed_navbar',
};

export const BUTTON_SIZE = {
    SM: 'sm',
    MD: 'md',
    LG: 'lg',
    XL: 'xl',
};

export const BUTTON_TYPE = {
    BUTTON: 'button',
    SUBMIT: 'submit',
};

export const ICON_POSITION = {
    RIGHT: 'right',
    LEFT: 'left',
};

function BaseButton({
    label,
    onClick,
    type = BUTTON_TYPE.BUTTON,
    icon = null,
    iconStyle = {},
    iconPosition = ICON_POSITION.RIGHT,
    size = BUTTON_SIZE.MD,
    fullWidth = false,
    variant = BUTTON_VARIANT.DEFAULT,
    style = {},
    className = '',
    disabled = false,
    noWrap = false,
    showLabelOnHover = false,
    selected = false,
    'data-test': dataTest,
    loading = false,
}) {
    const iconPositions = [ICON_POSITION.RIGHT, ICON_POSITION.LEFT];
    const Icon = icon;
    const labelStyle = {};
    let variantClasses;
    let sizeClasses;
    let labelClasses;
    let currentIconPosition = iconPosition;

    if (Icon && !iconPositions.includes(currentIconPosition)) {
        currentIconPosition = ICON_POSITION.RIGHT;
    }

    switch (variant) {
        case BUTTON_VARIANT.SECONDARY:
            variantClasses = classNames(
                'bg-white text-primary-500 border-solid border border-primary-600 hover:bg-primary-50 hover:border-primary-400 focus:outline-none focus:border-primary-200 focus:ring-2 focus:ring-primary-500 disabled:border-0 disabled:bg-neutral-100 disabled:text-neutral-300',
                { 'outline-none border-primary-200 ring-2 ring-primary-500': selected },
            );
            break;
        case BUTTON_VARIANT.WHITE:
            variantClasses = classNames(
                'bg-white text-neutral-400 border-solid border border-neutral-100 hover:bg-neutral-50 hover:border-neutral-100 focus:outline-none focus:border-primary-200 focus:ring-2 focus:ring-primary-500 disabled:border-0 disabled:bg-neutral-100 disabled:text-neutral-300',
                { 'outline-none border-primary-200 ring-2 ring-primary-500': selected },
            );
            break;
        case BUTTON_VARIANT.CANCEL:
            variantClasses = classNames(
                'bg-error-50 text-error-500 border-none hover:bg-error-100 focus:outline-none focus:ring-offset-2 focus:ring-2 focus:ring-error-400 disabled:bg-neutral-100 disabled:text-neutral-300',
                { 'outline-none ring-offset-2 ring-2 ring-error-400': selected },
            );
            break;
        case BUTTON_VARIANT.MOBILE:
            variantClasses = classNames(
                'py-2 px-3 caption border-none disabled:bg-neutral-100 disabled:text-neutral-300 focus:outline-none focus:ring-2 focus:ring-offset-2',
                { 'outline-none ring-2 ring-offset-2': selected },
            );
            break;
        case BUTTON_VARIANT.EMBED_NAVBAR:
            variantClasses = classNames(
                'py-1.5 px-2 caption border-none disabled:bg-neutral-100 disabled:text-neutral-300 focus:outline-none focus:ring-2 focus:ring-offset-2',
                { 'outline-none ring-2 ring-offset-2': selected },
            );
            break;
        default:
            variantClasses = classNames(
                'bg-primary-500 text-white hover:bg-primary-400 focus:bg-primary-600 focus:outline-none focus:ring-offset-2 focus:ring-2 focus:ring-primary-500 disabled:bg-neutral-100 disabled:text-neutral-300',
                { 'outline-none ring-offset-2 ring-2 ring-primary-500': selected },
            );
    }

    switch (size) {
        case BUTTON_SIZE.SM:
            sizeClasses = 'text-xs py-2 px-3 rounded';
            if (Icon) {
                if (showLabelOnHover) {
                    labelClasses =
                        currentIconPosition === ICON_POSITION.RIGHT ? 'group-hover:mr-[11px]' : 'group-hover:ml-[11px]';
                } else {
                    labelClasses = currentIconPosition === ICON_POSITION.RIGHT ? 'mr-[11px]' : 'ml-[11px]';
                }
            }
            break;
        case BUTTON_SIZE.LG:
            sizeClasses = 'text-base py-2 px-3 font-semibold rounded-md';
            if (Icon) {
                if (showLabelOnHover) {
                    labelClasses =
                        currentIconPosition === ICON_POSITION.RIGHT ? 'group-hover:mr-[13px]' : 'group-hover:ml-[13px]';
                } else {
                    labelClasses = currentIconPosition === ICON_POSITION.RIGHT ? 'mr-[13px]' : 'ml-[13px]';
                }
            }
            break;
        case BUTTON_SIZE.XL:
            sizeClasses = 'text-lg py-3 px-6 font-semibold rounded-md';
            if (Icon) {
                if (showLabelOnHover) {
                    labelClasses =
                        currentIconPosition === ICON_POSITION.RIGHT ? 'group-hover:mr-4' : 'group-hover:ml-4';
                } else {
                    labelClasses = currentIconPosition === ICON_POSITION.RIGHT ? 'mr-4' : 'ml-4';
                }
            }
            break;
        default:
            sizeClasses = 'text-sm py-2 px-4 rounded-md';
            if (Icon) {
                if (showLabelOnHover) {
                    labelClasses =
                        currentIconPosition === ICON_POSITION.RIGHT ? 'group-hover:mr-[11px]' : 'group-hover:ml-[11px]';
                } else {
                    labelClasses = currentIconPosition === ICON_POSITION.RIGHT ? 'mr-[11px]' : 'ml-[11px]';
                }
            }
    }

    const buttonClasses = classNames('text-center shadow-sm', variantClasses, sizeClasses, className, {
        'w-full': fullWidth,
        group: showLabelOnHover,
        'whitespace-nowrap': noWrap,
    });

    const iconJsx = Icon ? (
        <>
            <Icon className="inline-block transform -translate-y-px h-em w-em" style={iconStyle} />
        </>
    ) : null;

    return (
        <button
            // eslint-disable-next-line react/button-has-type
            type={type || BUTTON_TYPE.BUTTON}
            className={buttonClasses}
            style={style}
            data-test={dataTest}
            onClick={typeof onClick === 'function' ? onClick : () => {}}
            disabled={disabled}>
            {Icon && currentIconPosition === ICON_POSITION.LEFT && iconJsx}
            {label && (
                <span
                    className={classNames(labelClasses, {
                        'inline-block max-w-0 overflow-hidden whitespace-nowrap align-top opacity-0 transition-all duration-150 ease-in-out-quad group-hover:max-w-xl group-hover:opacity-100':
                            showLabelOnHover,
                    })}
                    style={labelStyle}>
                    {label}
                </span>
            )}
            {Icon && currentIconPosition !== ICON_POSITION.LEFT && iconJsx}
            {loading && (
                <span className="ml-3">
                    <LoadingSpinner />
                </span>
            )}
        </button>
    );
}

export function DefaultButton({
    label,
    type,
    onClick,
    icon,
    iconStyle,
    iconPosition,
    size,
    style,
    fullWidth,
    className,
    disabled,
    showLabelOnHover,
    noWrap,
    selected,
    loading,
    'data-test': dataTest,
}) {
    return (
        <BaseButton
            label={label}
            type={type}
            onClick={onClick}
            icon={icon}
            iconStyle={iconStyle}
            iconPosition={iconPosition}
            size={size}
            style={style}
            fullWidth={fullWidth}
            variant={BUTTON_VARIANT.DEFAULT}
            className={className}
            disabled={disabled}
            showLabelOnHover={showLabelOnHover}
            noWrap={noWrap}
            selected={selected}
            data-test={dataTest}
            loading={loading}
        />
    );
}

export function SecondaryButton({
    label,
    type,
    onClick,
    icon,
    iconStyle,
    iconPosition,
    size,
    style,
    fullWidth,
    className,
    disabled,
    showLabelOnHover,
    noWrap,
    selected,
    'data-test': dataTest,
}) {
    return (
        <BaseButton
            label={label}
            type={type}
            onClick={onClick}
            icon={icon}
            iconStyle={iconStyle}
            iconPosition={iconPosition}
            size={size}
            style={style}
            fullWidth={fullWidth}
            variant={BUTTON_VARIANT.SECONDARY}
            className={className}
            disabled={disabled}
            showLabelOnHover={showLabelOnHover}
            noWrap={noWrap}
            selected={selected}
            data-test={dataTest}
        />
    );
}

export function WhiteButton({
    label,
    type,
    onClick,
    icon,
    iconStyle,
    iconPosition,
    size,
    style,
    fullWidth,
    className,
    disabled,
    showLabelOnHover,
    noWrap,
    selected,
    'data-test': dataTest,
}) {
    return (
        <BaseButton
            label={label}
            type={type}
            onClick={onClick}
            icon={icon}
            iconStyle={iconStyle}
            iconPosition={iconPosition}
            size={size}
            style={style}
            fullWidth={fullWidth}
            variant={BUTTON_VARIANT.WHITE}
            className={className}
            disabled={disabled}
            showLabelOnHover={showLabelOnHover}
            noWrap={noWrap}
            selected={selected}
            data-test={dataTest}
        />
    );
}

export function CancelButton({
    label,
    type,
    onClick,
    icon,
    iconStyle,
    iconPosition,
    size,
    style,
    fullWidth,
    className,
    disabled,
    showLabelOnHover,
    noWrap,
    selected,
    'data-test': dataTest,
}) {
    return (
        <BaseButton
            label={label}
            type={type}
            onClick={onClick}
            icon={icon}
            iconStyle={iconStyle}
            iconPosition={iconPosition}
            size={size}
            style={style}
            fullWidth={fullWidth}
            variant={BUTTON_VARIANT.CANCEL}
            className={className}
            disabled={disabled}
            showLabelOnHover={showLabelOnHover}
            noWrap={noWrap}
            selected={selected}
            data-test={dataTest}
        />
    );
}

export function ViewerButton({
    label,
    type,
    onClick,
    icon,
    iconStyle,
    iconPosition,
    size,
    style,
    fullWidth,
    className,
    disabled,
    showLabelOnHover,
    noWrap,
    selected,
    'data-test': dataTest,
}) {
    return (
        <BaseButton
            label={label}
            type={type}
            onClick={onClick}
            icon={icon}
            iconStyle={iconStyle}
            iconPosition={iconPosition}
            size={size}
            style={style}
            fullWidth={fullWidth}
            variant={BUTTON_VARIANT.MOBILE}
            className={className}
            disabled={disabled}
            showLabelOnHover={showLabelOnHover}
            noWrap={noWrap}
            selected={selected}
            data-test={dataTest}
        />
    );
}

export function EmbedNavbarButton({
    label,
    type,
    onClick,
    icon,
    iconStyle,
    iconPosition,
    size,
    style,
    fullWidth,
    className,
    disabled,
    showLabelOnHover,
    noWrap,
    selected,
    'data-test': dataTest,
}) {
    return (
        <BaseButton
            label={label}
            type={type}
            onClick={onClick}
            icon={icon}
            iconStyle={iconStyle}
            iconPosition={iconPosition}
            size={size}
            style={style}
            fullWidth={fullWidth}
            variant={BUTTON_VARIANT.EMBED_NAVBAR}
            className={className}
            disabled={disabled}
            showLabelOnHover={showLabelOnHover}
            noWrap={noWrap}
            selected={selected}
            data-test={dataTest}
        />
    );
}
