export default function UnorderedListIcon({ className }) {
    return (
        <svg
            width="12"
            height="11"
            viewBox="0 0 12 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}>
            <path d="M4.75488 1.39648H11.1134" stroke="currentColor" strokeWidth="0.687081" />
            <path d="M4.75488 5.46094H11.1134" stroke="currentColor" strokeWidth="0.687081" />
            <path d="M4.75488 9.69824H11.1134" stroke="currentColor" strokeWidth="0.687081" />
            <ellipse cx="2.0268" cy="1.39644" rx="1.02876" ry="1.03218" fill="currentColor" />
            <ellipse cx="2.0268" cy="5.46089" rx="1.02876" ry="1.03218" fill="currentColor" />
            <ellipse cx="2.0268" cy="9.69819" rx="1.02876" ry="1.03218" fill="currentColor" />
        </svg>
    );
}
