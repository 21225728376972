import { useRef } from 'react';
import { isMobile } from 'react-device-detect';
import TextInputDebounce from '@/system/TextInputDebounce';
import ToggleRow from '@/workbook/builder/sections/ToggleRow';
import RichTextEditorDebounce from '@/system/RichTextEditorDebounce';
import SectionTitle from '@/system/SectionTitle';
import SectionDescription from '@/system/SectionDescription';
import Label from '@/system/Label';
import GroupHeading from '@/system/GroupHeading';
import RangeSlider from '@/system/RangeSlider';
import { TEXT_INPUT_TYPE } from '@/system/TextInput';
import { WORKBOOK_SECTION_VIEWER_MODES } from '@/util/constants';

const responsePath = 'answer';

export const SliderEditor = ({ schema, setSectionSchemaProp, saveSectionSchemaProp }) => {
    const { title, description, label, range } = schema;

    const handleTitleToggleChange = () => {
        saveSectionSchemaProp('title.show', !title.show);
    };

    const handleTitleChange = (e) => {
        const { value: text } = e.target;
        setSectionSchemaProp('title.text', text);
    };

    const handleTitleTypingEnd = (text) => {
        saveSectionSchemaProp('title.text', text);
    };

    const handleDescriptionToggleChange = () => {
        saveSectionSchemaProp('description.show', !description.show);
    };

    const handleDescriptionChange = (text) => {
        setSectionSchemaProp('description.text', text);
    };

    const handleDescriptionTypingEnd = (text) => {
        saveSectionSchemaProp('description.text', text);
    };

    const handleLabelChange = (e) => {
        const { value: text } = e.target;
        setSectionSchemaProp('label', text);
    };

    const handleLabelTypingEnd = (text) => {
        saveSectionSchemaProp('label', text);
    };

    const handleMinRangeChange = (e) => {
        const { value: minRange } = e.target;
        let min = parseInt(minRange, 10);
        if (Number.isNaN(min)) min = 0;
        setSectionSchemaProp('range.min', min);
        if (range.max <= min) setSectionSchemaProp('range.max', min + 2);
    };

    const handleMinRangeTypingEnd = (value) => {
        let min = parseInt(value, 10);
        if (Number.isNaN(min)) min = 0;
        saveSectionSchemaProp('range.min', min);
        if (range.max <= min) saveSectionSchemaProp('range.max', min + 2);
    };

    const handleMaxRangeChange = (e) => {
        const { value: maxRange } = e.target;
        let max = parseInt(maxRange, 10);
        if (Number.isNaN(max)) max = 0;
        setSectionSchemaProp('range.max', max);
        if (range.min >= max) setSectionSchemaProp('range.min', max - 2);
    };

    const handleMaxRangeTypingEnd = (value) => {
        let max = parseInt(value, 10);
        if (Number.isNaN(max)) max = 0;
        saveSectionSchemaProp('range.max', max);
        if (range.min >= max) saveSectionSchemaProp('range.min', max - 2);
    };

    return (
        <>
            <div className="space-y-3.5">
                <ToggleRow label="Title" enabled={title.show} onChange={handleTitleToggleChange} />
                <TextInputDebounce value={title.text} onChange={handleTitleChange} onTypingEnd={handleTitleTypingEnd} />
                <div>
                    <ToggleRow
                        label="Description"
                        enabled={description.show}
                        onChange={handleDescriptionToggleChange}
                    />
                    <RichTextEditorDebounce
                        value={description.text}
                        onChange={handleDescriptionChange}
                        onTypingEnd={handleDescriptionTypingEnd}
                    />
                </div>
                <Label desc="Label" />
                <TextInputDebounce value={label} onChange={handleLabelChange} onTypingEnd={handleLabelTypingEnd} />
                <Label desc="Range" />
                <div className="grid grid-cols-4 gap-1/2">
                    <div>
                        <GroupHeading className="text-sm" heading="Min." />
                        <div className="grid-cols-2 gap-2">
                            <TextInputDebounce
                                className="flex w-30 text-sm"
                                type={TEXT_INPUT_TYPE.NUMBER}
                                value={range.min}
                                onChange={handleMinRangeChange}
                                onTypingEnd={handleMinRangeTypingEnd}
                            />
                        </div>
                    </div>
                    <span className="py-10 px-8">to</span>
                    <div>
                        <GroupHeading className="text-sm" heading="Max." />
                        <TextInputDebounce
                            className="w-30 text-sm"
                            type={TEXT_INPUT_TYPE.NUMBER}
                            value={range.max}
                            onChange={handleMaxRangeChange}
                            onTypingEnd={handleMaxRangeTypingEnd}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export const Slider = ({
    workbookSchema,
    schema,
    mode = WORKBOOK_SECTION_VIEWER_MODES.DEFAULT,
    sectionResponses,
    setSectionResponseProp,
    saveSectionResponseProp,
}) => {
    const { title, description, range, label } = schema;
    const hasPendingChanges = useRef(false);

    const handleRangeAnswerChange = (e) => {
        if (mode === WORKBOOK_SECTION_VIEWER_MODES.READ_ONLY) return;
        const { value: text } = e.target;
        if (typeof setSectionResponseProp === 'function') {
            setSectionResponseProp(responsePath, text);
            hasPendingChanges.current = true;
        }
        if (isMobile && typeof saveSectionResponseProp === 'function') {
            saveSectionResponseProp(responsePath, text);
            hasPendingChanges.current = false;
        }
    };

    const handleRangeAnswerBlur = (e) => {
        if (mode === WORKBOOK_SECTION_VIEWER_MODES.READ_ONLY) return;
        const { value: text } = e.target;
        if (typeof saveSectionResponseProp === 'function' && hasPendingChanges.current) {
            saveSectionResponseProp(responsePath, text);
            hasPendingChanges.current = false;
        }
    };

    const handleRangeMouseOut = (e) => {
        if (mode === WORKBOOK_SECTION_VIEWER_MODES.READ_ONLY) {
            return;
        }
        if (
            typeof saveSectionResponseProp === 'function' &&
            e.target.tagName === 'INPUT' &&
            hasPendingChanges.current
        ) {
            const { value: text } = e.target;
            if (typeof saveSectionResponseProp === 'function') {
                saveSectionResponseProp(responsePath, text);
                hasPendingChanges.current = false;
            }
        }
    };

    const getValue = () => {
        if (mode === WORKBOOK_SECTION_VIEWER_MODES.BUILDER) {
            if (sectionResponses && sectionResponses[responsePath]) {
                return sectionResponses[responsePath] < range.max ? sectionResponses[responsePath] : range.max;
            }
            return Math.trunc((range.max - range.min) / 2) + range.min;
        }
        return sectionResponses && sectionResponses[responsePath]
            ? sectionResponses?.[responsePath]
            : Math.trunc((range.max - range.min) / 2) + range.min;
    };

    const value = getValue();

    return (
        <>
            {title.show && (
                <SectionTitle
                    text={title.text}
                    style={{
                        fontFamily: workbookSchema.textStyling.subtitle.fontFamily,
                        color: workbookSchema.textStyling.subtitle.color,
                    }}
                />
            )}
            {description.show && (
                <SectionDescription
                    text={description.text}
                    style={{
                        fontFamily: workbookSchema.textStyling.body.fontFamily,
                        color: workbookSchema.textStyling.body.color,
                    }}
                />
            )}
            <div
                className="flex space-x-4"
                style={{
                    fontFamily: workbookSchema.textStyling.body.fontFamily,
                    color: workbookSchema.textStyling.body.color,
                }}>
                <div className="text-xs font-semibold opacity-0">{range.min}</div>
                {label && <Label className="font-semibold" desc={`${label}: ${value}`} />}
            </div>
            <div className="flex space-x-4">
                <div
                    className="text-xs font-semibold"
                    style={{
                        fontFamily: workbookSchema.textStyling.body.fontFamily,
                        color: workbookSchema.textStyling.body.color,
                    }}>
                    {range.min}
                </div>
                <RangeSlider
                    colors={{
                        valueBg: 'bg-current',
                        thumbBgMaterial: 'range-thumb:bg-current',
                    }}
                    style={{
                        color: workbookSchema.buttonStyling.fillColor,
                    }}
                    className="w-full"
                    value={value}
                    step={1}
                    min={range.min}
                    max={range.max}
                    onMouseOut={handleRangeMouseOut}
                    onChange={handleRangeAnswerChange}
                    onBlur={handleRangeAnswerBlur}
                />
                <div
                    className="text-xs font-semibold"
                    style={{
                        fontFamily: workbookSchema.textStyling.body.fontFamily,
                        color: workbookSchema.textStyling.body.color,
                    }}>
                    {range.max}
                </div>
            </div>
        </>
    );
};

export const SliderArtifact = ({ workbookSchema, schema, sectionResponses }) => {
    const { title, description, range, label } = schema;

    const value = sectionResponses
        ? sectionResponses?.[responsePath]
        : Math.trunc((range.max - range.min) / 2) + range.min;

    return (
        <>
            {title.show && (
                <SectionTitle
                    text={title.text}
                    style={{
                        fontFamily: workbookSchema.textStyling.subtitle.fontFamily,
                        color: workbookSchema.textStyling.subtitle.color,
                    }}
                />
            )}
            {description.show && (
                <SectionDescription
                    text={description.text}
                    style={{
                        fontFamily: workbookSchema.textStyling.body.fontFamily,
                        color: workbookSchema.textStyling.body.color,
                    }}
                />
            )}
            <div
                className="flex space-x-4"
                style={{
                    fontFamily: workbookSchema.textStyling.body.fontFamily,
                    color: workbookSchema.textStyling.body.color,
                }}>
                <div className="text-xs font-semibold opacity-0">{range.min}</div>
                {label && <Label className="font-semibold" desc={`${label}: ${value}`} />}
            </div>
            <div className="flex space-x-4">
                <div
                    className="text-xs font-semibold"
                    style={{
                        fontFamily: workbookSchema.textStyling.body.fontFamily,
                        color: workbookSchema.textStyling.body.color,
                    }}>
                    {range.min}
                </div>
                <RangeSlider
                    colors={{
                        valueBg: 'bg-current',
                        thumbBgMaterial: 'range-thumb:bg-current',
                    }}
                    style={{
                        color: workbookSchema.buttonStyling.fillColor,
                    }}
                    className="w-full"
                    value={value}
                    step="1"
                    min={range.min}
                    max={range.max}
                />
                <div
                    className="text-xs font-semibold"
                    style={{
                        fontFamily: workbookSchema.textStyling.body.fontFamily,
                        color: workbookSchema.textStyling.body.color,
                    }}>
                    {range.max}
                </div>
            </div>
        </>
    );
};
