import { Suspense, lazy } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '@/auth/AuthProvider';
import { ROLES, REACT_ROUTER_PATHS, ROUTES } from '@/util/constants';
import { useEmbed } from '@/embed/EmbedProvider';
import TechnicalError from '@/system/TechnicalError';
import LoadingSpinner from '@/system/LoadingSpinner';
import WorkbookVersionProvider from '@/workbook/student/WorkbookVersionProvider';
import Enroll from '@/workbook/publiclyShared/Enroll';

const Dashboard = lazy(() => import('@/dashboard/StudentDashboard'));
const Workbook = lazy(() => import('@/workbook/student/Workbook'));
const Settings = lazy(() => import('@/settings/student/Settings'));

export default function StudentRoutes() {
    const { user } = useAuth();
    const { embedded, originalLocation, removeTokens } = useEmbed();
    const location = useLocation();

    if (user.roles.includes(ROLES.STUDENT) || location.pathname.includes('/student/public-enroll/')) {
        return (
            <Suspense fallback={<LoadingSpinner />}>
                <Routes>
                    <Route path={REACT_ROUTER_PATHS.STUDENT.PUBLIC_ENROLL} element={<Enroll />} />
                    <Route path={REACT_ROUTER_PATHS.STUDENT.DASHBOARD} element={<Dashboard />} />
                    <Route path={REACT_ROUTER_PATHS.STUDENT.SETTINGS} element={<Settings />} />
                    <Route
                        path={REACT_ROUTER_PATHS.STUDENT.WORKBOOK}
                        element={
                            <WorkbookVersionProvider>
                                <Workbook />
                            </WorkbookVersionProvider>
                        }
                    />
                    <Route
                        path="*"
                        element={
                            embedded ? (
                                <TechnicalError
                                    title="Invalid route"
                                    desc="Please ask your course creator to check this lesson's embed URL."
                                />
                            ) : (
                                <Navigate to={ROUTES.STUDENT.DASHBOARD} replace />
                            )
                        }
                    />
                </Routes>
            </Suspense>
        );
    }
    if (embedded) {
        removeTokens();
        return <Navigate to={ROUTES.COMMON.EMBED_LOGIN} state={{ from: originalLocation }} />;
    }

    return <Navigate to="/" state={{ from: location }} />;
}
