import { useEffect } from 'react';
import WebFontLoader from 'webfontloader';

export const FONTS = [
    'Abhaya Libre',
    'Abril Fatface',
    'Aleo',
    'Antic Didone',
    'Archivo',
    'Arvo',
    'Bebas Neue',
    'Bellota',
    'Bodoni Moda',
    'Cardo',
    'Carter One',
    'Concert One',
    'Courier Prime',
    'Crimson Pro',
    'Crimson Text',
    'DM Mono',
    'DM Sans',
    'EB Garamond',
    'Figtree',
    'Francois One',
    'Frank Ruhl Libre',
    'Gowun Batang',
    'Gowun Dodum',
    'IBM Plex Mono',
    'IBM Plex Sans Thai Looped',
    'IBM Plex Serif',
    'Inconsolata',
    'Inter',
    'Italiana',
    'Josefin Sans',
    'Jost',
    'Lato',
    'Lexend',
    'Libre Baskerville',
    'Libre Caslon Display',
    'Lora',
    'Maven Pro',
    'Merriweather',
    'Montserrat',
    'Mulish',
    'Noto Sans',
    'Noto Serif Display',
    'Nunito',
    'Old Standard TT',
    'Open Sans',
    'Oswald',
    'Oxygen',
    'Patrick Hand',
    'Permanent Marker',
    'Playfair Display',
    'Plus Jakarta Sans',
    'Poppins',
    'PT Serif',
    'Quicksand',
    'Raleway',
    'Rampart One',
    'Roboto',
    'Roboto Mono',
    'Roboto Slab',
    'Rubik',
    'Saira',
    'Shrikhand',
    'Space Grotesk',
    'Space Mono',
    'Source Sans Pro',
    'Titillium Web',
    'Ubuntu',
    'Urbanist',
    'Work Sans',
    'Zen Antique',
    'Zen Kaku Gothic Antique',
    'Zen Maru Gothic',
];

export const WEB_FONT_LOADER = {
    'Abhaya Libre': 'Abhaya Libre:400,700',
    'Abril Fatface': 'Abril Fatface:400',
    Aleo: 'Aleo:400,700',
    'Antic Didone': 'Antic Didone:400',
    Archivo: 'Archivo:300,400,500,600,700',
    Arvo: 'Arvo:400,700',
    'Bebas Neue': 'Bebas Neue',
    Bellota: 'Bellota:300,400,700',
    'Bodoni Moda': 'Bodoni Moda:400,500,600,700',
    Cardo: 'Cardo:400,700',
    'Carter One': 'Carter One',
    'Concert One': 'Concert One',
    'Courier Prime': 'Courier Prime:400,700',
    'Crimson Pro': 'Crimson Pro:300,400,500,600,700',
    'Crimson Text': 'Crimson Text:400,700',
    'DM Mono': 'DM Mono:300,400,500',
    'DM Sans': 'DM Sans:400,500,700',
    'EB Garamond': 'EB Garamond:400,500,600,700',
    Figtree: 'Figtree:300,400,500,600,700',
    'Francois One': 'Francois One',
    'Frank Ruhl Libre': 'Frank Ruhl Libre:400,700',
    'Gowun Batang': 'Gowun Batang:400,700',
    'Gowun Dodum': 'Gowun Dodum',
    'IBM Plex Mono': 'IBM Plex Mono:100,200,300,400,500,600,700',
    'IBM Plex Sans Thai Looped': 'IBM Plex Sans Thai Looped:300,400,500,600,700',
    'IBM Plex Serif': 'IBM Plex Serif:300,400,500,600,700',
    Inconsolata: 'Inconsolata:300,400,500,600,700',
    Inter: 'Inter:300,400,500,600,700',
    Italiana: 'Italiana',
    'Josefin Sans': 'Josefin Sans:300,400,500,600,700',
    Jost: 'Jost:300,400,500,600,700',
    Lato: 'Lato:400,700',
    Lexend: 'Lexend:300,400,500,600,700',
    'Libre Baskerville': 'Libre Baskerville:400,700',
    'Libre Caslon Display': 'Libre Caslon Display',
    Lora: 'Lora:400,500,600,700',
    'Maven Pro': 'Maven Pro:400,500,600,700',
    Merriweather: 'Merriweather:400,700',
    Montserrat: 'Montserrat:100,200,300,400,500,600,700,800,900',
    Mulish: 'Mulish:600,800',
    'Noto Sans': 'Noto Sans:400,700',
    'Noto Serif Display': 'Noto Serif Display:300,400,500,600,700',
    Nunito: 'Nunito:200,300,400,500,600,700,800,900',
    'Old Standard TT': 'Old Standard TT:400,700',
    'Open Sans': 'Open Sans:300,400,500,600,700',
    Oswald: 'Oswald:300,400,500,600,700',
    Oxygen: 'Oxygen:400,700',
    'Patrick Hand': 'Patrick Hand:400',
    'Permanent Marker': 'Permanent Marker',
    'Playfair Display': 'Playfair Display:400,500,600,700',
    'Plus Jakarta Sans': 'Plus Jakarta Sans:300,400,500,600,700',
    Poppins: 'Poppins:300,400,600,700',
    'PT Serif': 'PT Serif:400,700',
    Quicksand: 'Quicksand:300,400,500,600,700',
    Raleway: 'Raleway:100,200,300,400,500,600,700,800,900',
    'Rampart One': 'Rampart One',
    Roboto: 'Roboto:300,400,500,600,700',
    'Roboto Mono': 'Roboto Mono:300,400,500,600,700',
    'Roboto Slab': 'Roboto Slab:300,400,500,600,700',
    Rubik: 'Rubik:300,400,500,600,700',
    Saira: 'Saira:300,400,500,600,700',
    Shrikhand: 'Shrikhand',
    'Source Sans Pro': 'Source Sans Pro:400,700',
    'Space Grotesk': 'Space Grotesk:300,400,500,600,700',
    'Space Mono': 'Space Mono:400,700',
    'Titillium Web': 'Titillium Web:400,700',
    Ubuntu: 'Ubuntu:400,700',
    Urbanist: 'Urbanist:300,400,500,600,700',
    'Work Sans': 'Work Sans:400,600',
    'Zen Antique': 'Zen Antique',
    'Zen Kaku Gothic Antique': 'Zen Kaku Gothic Antique:300,400,500,700',
    'Zen Maru Gothic': 'Zen Maru Gothic:300,400,500,700',
};

export const DEFAULT_FONT_FAMILY = 'Open Sans';

export default function FontLoader({ children }) {
    useEffect(() => {
        const googleFonts = [];
        FONTS.forEach((font) => {
            googleFonts.push(WEB_FONT_LOADER[font]);
        });

        WebFontLoader.load({
            google: {
                families: googleFonts,
            },
        });
    }, []);

    return <>{children}</>;
}
