import classNames from 'classnames';

export default function SettingsNavButton({
    label,
    onClick,
    icon = null,
    iconStyle = {},
    style = {},
    className = '',
    disabled = false,
    current = false,
}) {
    const Icon = icon;
    const buttonClasses = classNames('heading__xs--medium w-full text-left px-3 py-2 rounded-md', className, {
        'bg-neutral-100 text-neutral-500': current,
        'bg-transparent text-neutral-300': !current,
    });

    return (
        <button
            type="button"
            className={buttonClasses}
            style={style}
            onClick={typeof onClick === 'function' ? onClick : () => {}}
            disabled={disabled}>
            {Icon && <Icon className="inline-block h-em-2 w-em-2 translate-y-[-1px] transform" style={iconStyle} />}
            <span className={classNames({ 'ml-3': Icon })}>{label}</span>
        </button>
    );
}
