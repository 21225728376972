import classNames from 'classnames';
import { getSectionArtifact, isSectionDisabledAddOn } from '@/workbook/builder/sections/helpers';
import ModuleTitle from '@/workbook/builder/viewer/ModuleTitle';
import { SECTIONS } from '@/workbook/builder/sections/sections';
import { ARTIFACT_PAGE_FORMATS } from '@/util/constants';
import PoweredByWobo from '@/workbook/builder/sections/PoweredByWobo';

export default function Viewer({ workbook, creatorSchema, student, addOns }) {
    const { schema, modules, moduleResponses, moduleResponsesByModuleIdDictionary } = workbook;
    const {
        artifact: { pageFormat },
        poweredByWobo,
    } = creatorSchema;
    const isLetterPageFormat = pageFormat !== ARTIFACT_PAGE_FORMATS.A4;
    const isA4PageFormat = pageFormat === ARTIFACT_PAGE_FORMATS.A4;
    const coverPageSchema = modules[0]?.sections[0]?.schema;
    const hideCoverPageModule = coverPageSchema?.hidden ?? false;

    return (
        <div
            className={classNames({
                'w-[8in]': isLetterPageFormat,
                'w-[197.3mm]': isA4PageFormat,
            })}
            style={{ backgroundColor: schema.pageColor }}>
            {modules.map((module, moduleIndex) => {
                const { id: moduleVersionId, moduleId } = module;
                const isCoverPage = module?.sections?.[0]?.type === SECTIONS.WORKBOOK_COVER_PAGE.TYPE_ID;
                const isLastModule = moduleIndex === modules.length - 1;
                const moduleResponse = moduleResponses[moduleResponsesByModuleIdDictionary[moduleId]];
                const isFirstSectionModuleCoverPage =
                    module?.sections?.[0]?.type === SECTIONS.MODULE_COVER_PAGE.TYPE_ID;
                const isLastSectionModuleCoverPage =
                    module?.sections?.[module.sections.length - 1]?.type === SECTIONS.MODULE_COVER_PAGE.TYPE_ID;

                if (hideCoverPageModule && moduleIndex === 0) {
                    return null;
                }

                return (
                    <div
                        key={`module_${moduleVersionId}`}
                        className={classNames('w-full space-y-8', {
                            'min-h-[10in]': isLetterPageFormat,
                            'min-h-[271.4mm]': isA4PageFormat,
                            'break-after-page': !isLastModule,
                            'p-6': !isCoverPage && !isFirstSectionModuleCoverPage,
                            'pt-0 pr-6 pb-6 pl-6':
                                !isCoverPage && isFirstSectionModuleCoverPage && !isLastSectionModuleCoverPage,
                            'pt-0 pr-6 pb-0 pl-6':
                                !isCoverPage && isFirstSectionModuleCoverPage && isLastSectionModuleCoverPage,
                            'p-0': isCoverPage,
                        })}>
                        <PoweredByWobo enable={poweredByWobo && !isFirstSectionModuleCoverPage && !isCoverPage} />
                        {!isCoverPage && !isFirstSectionModuleCoverPage && !module.show_title && (
                            <ModuleTitle title={module.title} workbookSchema={schema} />
                        )}
                        {module?.sections?.map((section, index) => {
                            // Hide the sections which are dependent on creator's paid add-ons or being on trial
                            if (isSectionDisabledAddOn(section.type, addOns)) {
                                return null;
                            }

                            const Section = getSectionArtifact(section);
                            let responses = null;
                            if (
                                moduleResponse &&
                                Object.prototype.hasOwnProperty.call(moduleResponse.sectionResponses, section.id)
                            ) {
                                responses = moduleResponse.sectionResponses[section.id];
                            }

                            return (
                                <div
                                    key={section.id}
                                    id={`section-${section.id}`}
                                    className={classNames('w-full', {
                                        'h-[10in]':
                                            section.type === SECTIONS.WORKBOOK_COVER_PAGE.TYPE_ID && isLetterPageFormat,
                                        'h-[271.4mm]':
                                            section.type === SECTIONS.WORKBOOK_COVER_PAGE.TYPE_ID && isA4PageFormat,
                                    })}>
                                    {Section && (
                                        <>
                                            <Section
                                                id={section.id}
                                                schema={section.schema}
                                                workbookSchema={schema}
                                                sectionResponses={responses}
                                                creatorSchema={creatorSchema}
                                                student={student}
                                                showPoweredByWobo={
                                                    poweredByWobo &&
                                                    (section.type === 1 || section.type === 2) &&
                                                    ((isFirstSectionModuleCoverPage && index === 0) || isCoverPage)
                                                }
                                            />
                                        </>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                );
            })}
        </div>
    );
}
