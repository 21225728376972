import { useEffect, useState } from 'react';
import ValidationListItem from '@/system/ValidationListItem';
import {
    testNoWhitespace,
    testPasswordLength,
    testPasswordLowercase,
    testPasswordNumbers,
    testPasswordSpecialCharacters,
    testPasswordUppercase,
} from '@/auth/helpers';

export default function PasswordValidator({ validate, password }) {
    const [length, setLength] = useState(testPasswordLength(password));
    const [lowercase, setLowercase] = useState(testPasswordLowercase(password));
    const [uppercase, setUppercase] = useState(testPasswordUppercase(password));
    const [numbers, setNumbers] = useState(testPasswordNumbers(password));
    const [specialCharacters, setSpecialCharacters] = useState(testPasswordSpecialCharacters(password));
    const [noWhitespace, setNoWhitespace] = useState(testNoWhitespace(password));
    const [passed, setPassed] = useState(
        length && lowercase && uppercase && numbers && specialCharacters && noWhitespace,
    );

    useEffect(() => {
        setLength(testPasswordLength(password));
        setLowercase(testPasswordLowercase(password));
        setUppercase(testPasswordUppercase(password));
        setNumbers(testPasswordNumbers(password));
        setSpecialCharacters(testPasswordSpecialCharacters(password));
        setNoWhitespace(testNoWhitespace(password));
    }, [validate, password]);

    useEffect(() => {
        setPassed(length && lowercase && uppercase && numbers && specialCharacters);
    }, [length, lowercase, uppercase, numbers, specialCharacters]);

    return (
        <div className="paragraph__sm">
            <div className={!!validate && !passed ? 'text-error-200' : ''}>Password must contain:</div>
            <ValidationListItem validate={validate} passed={length} content="At least 8 characters" />
            <ValidationListItem validate={validate} passed={lowercase} content="At least 1 lowercase character" />
            <ValidationListItem validate={validate} passed={uppercase} content="At least 1 uppercase character" />
            <ValidationListItem validate={validate} passed={numbers} content="At least 1 number" />
            <ValidationListItem validate={validate} passed={specialCharacters} content="At least 1 special character" />
            <ValidationListItem validate={validate} passed={noWhitespace} content="No whitespaces" />
        </div>
    );
}
