export default function useHumanDate() {
    return {
        timeAgo: (dateString) => {
            const now = new Date();
            const date = new Date(dateString);
            const seconds = Math.floor((now - date) / 1000);
            const minutes = Math.floor(seconds / 60);
            const hours = Math.floor(minutes / 60);
            const days = Math.floor(hours / 24);
            const weeks = Math.floor(days / 7);
            const months = Math.floor(days / 30);
            const years = Math.floor(days / 365);

            if (seconds < 0) {
                // Only occurs if user manually sets their computer time before the timezone they're in
                return 'Recently';
            }
            if (seconds < 60) {
                return seconds === 1 ? 'Just now' : `${seconds} seconds ago`;
            }
            if (minutes < 60) {
                return minutes === 1 ? 'A minute ago' : `${minutes} minutes ago`;
            }
            if (hours < 24) {
                return hours === 1 ? 'An hour ago' : `${hours} hours ago`;
            }
            if (days < 7) {
                return days === 1 ? 'Yesterday' : `${days} days ago`;
            }
            if (weeks < 4) {
                return weeks === 1 ? 'A week ago' : `${weeks} weeks ago`;
            }
            if (months < 12) {
                return months === 1 ? 'A month ago' : `${months} months ago`;
            }

            return years === 1 ? 'A year ago' : `${years} years ago`;
        },
    };
}
