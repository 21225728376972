import React, { useState } from 'react';
import { AnnotationIcon } from '@heroicons/react/outline';
import CommentEditor from './CommentEditor';

export default function AddComment({ onSave, workbookSchema }) {
    const [addingComment, setAddingComment] = useState(false);

    return (
        <div>
            <button
                type="button"
                className="flex flex-row w-full mt-4 cursor-pointer"
                style={{ fontFamily: workbookSchema.textStyling.body.fontFamily }}
                onClick={() => setAddingComment((v) => !v)}>
                <AnnotationIcon className="h-6 w-5 text-neutral-300" />
                <span className="ml-2 underline text-neutral-300">Add a comment</span>
            </button>
            {addingComment && (
                <div className="">
                    <CommentEditor
                        workbookSchema={workbookSchema}
                        initialComment=""
                        setAddingComment={setAddingComment}
                        onAdd={onSave}
                        isEditing={false}
                    />
                </div>
            )}
        </div>
    );
}
