import { useRef } from 'react';

export class Tokens {
    constructor(tokensDto) {
        this.accessToken = tokensDto.access_token;
    }
}

const getQueryParamTokens = () => {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    const accessToken = searchParams.get('access_token');
    return { accessToken };
};

export const useQueryParamTokens = () => {
    const tokens = useRef(getQueryParamTokens());
    return tokens.current;
};
