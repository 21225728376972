import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { XIcon } from '@heroicons/react/outline';
import useQueryParameter from '@/util/useQueryParameter';
import useApi from '@/api/useApi';
import LoadingSpinner from '@/system/LoadingSpinner';
import { SingleActionModal } from '@/system/Modals';
import { ROUTES } from '@/util/constants';
import { paymentInfoFromCheckoutSessionDto } from '@/api/dtos';
import { useRoles } from '@/navigation/RolesProvider';
import useCache, { CACHE_KEYS } from '@/util/useCache';
import useGTM from '@/util/useGTM';
import { useAuth } from '@/auth/AuthProvider';
import useHubspot from '@/util/useHubspot';

export default function ProcessPayment() {
    const isMounted = useRef(true);
    const [error, setError] = useState(false);
    const sessionId = useQueryParameter('session_id');
    const { processCheckout } = useApi();
    const { selectedCreator: creator, loading: loadingCreator, setPaymentInfo } = useRoles();
    const navigate = useNavigate();
    const { ready: upcomingInvoiceCacheReady, evictItem: evictCachedUpcomingInvoice } = useCache(
        CACHE_KEYS.CREATOR_UPCOMING_INVOICE,
    );
    const { user } = useAuth();
    const [paidEventSent, setPaidEventSent] = useState(false);
    const { updateContactLifecycleStage, lifecycleStages } = useHubspot();
    const gtm = useGTM();

    useEffect(() => {
        isMounted.current = true;
        if (!loadingCreator && upcomingInvoiceCacheReady) {
            if (sessionId) {
                evictCachedUpcomingInvoice();
                processCheckout(creator.id, sessionId)
                    .then((dto) => {
                        if (isMounted.current) {
                            setPaymentInfo(paymentInfoFromCheckoutSessionDto(dto));
                        }
                    })
                    .catch(() => {
                        if (isMounted.current) setError(true);
                    });
            } else if (isMounted.current) {
                setError(true);
            }
        }

        return () => {
            isMounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingCreator, upcomingInvoiceCacheReady]);

    useEffect(() => {
        if (user && !error && !loadingCreator && !paidEventSent) {
            setPaidEventSent(true);
            updateContactLifecycleStage(user?.email, lifecycleStages.PAID);
        }
    }, [user, error, updateContactLifecycleStage, lifecycleStages, loadingCreator, paidEventSent, setPaidEventSent]);

    if (loadingCreator || !upcomingInvoiceCacheReady) return <LoadingSpinner />;
    if (error)
        return (
            <div>
                <SingleActionModal
                    show
                    title="Payment Failed"
                    desc="Seems like something went wrong while processing your payment."
                    icon={XIcon}
                    error
                    actionButtonLabel="Return to plans"
                    onActionClick={() => navigate(ROUTES.CREATOR.PAYMENT)}
                    data-test="payment-failed-modal"
                />
            </div>
        );

    gtm.pushPurchase(user.id);
    return (
        <div>
            <SingleActionModal
                show
                title="Payment Success!"
                actionButtonLabel="Go to your dashboard"
                onActionClick={() => navigate(ROUTES.CREATOR.WORKBOOKS_DASHBOARD)}
                data-test="payment-successful-modal"
            />
        </div>
    );
}
