import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { getSearch } from '@/api/helpers';
import { checkDomainUrlAvailability } from '@/util/helpers';
import { CardWithFooter } from '@/auth/Cards';
import TextInput, {
    TEXT_INPUT_AUTO_CAPITALIZE,
    TEXT_INPUT_AUTO_CORRECT,
    TEXT_INPUT_SPELL_CHECK,
} from '@/system/TextInput';
import { THINKIFIC_AUTHORIZE_ROUTE } from '@/util/constants';
import useQueryParameter from '@/util/useQueryParameter';
import { CACHE_KEYS } from '@/util/useCache';

export default function ThinkificLogin({ getSsoParams, signup, student }) {
    const [loading, setLoading] = useState(true);
    const [domain, setDomain] = useState('');
    const [domainErrorMessage, setDomainErrorMessage] = useState('');
    const [checked, setChecked] = useState(false);
    const subdomain = useQueryParameter('subdomain');

    const validateDomain = (value) => {
        let passed = true;
        if (domainErrorMessage) passed = false;
        if (!value) {
            setDomainErrorMessage('domain is required');
            passed = false;
        } else {
            setDomainErrorMessage('');
        }
        return passed;
    };

    const handleDomainChange = (e) => {
        const { value: newDomain } = e.target;
        setDomain(newDomain);
        validateDomain(newDomain);
    };

    const redirectToThinkific = (thinkificUrl) => {
        const params = getSearch(getSsoParams(`https://${domain}`));
        const oAuthUrl = `${thinkificUrl}${params}`;
        window.location.href = oAuthUrl;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateDomain(domain)) return;
        setLoading(true);
        const thinkificUrl = `https://${domain}${THINKIFIC_AUTHORIZE_ROUTE}`;
        if (await checkDomainUrlAvailability(thinkificUrl)) {
            // We use local storage directly because the user is not logged in at this point
            if (!signup && checked) {
                window.localStorage.setItem(CACHE_KEYS.THINKIFIC_DOMAIN, domain);
            } else {
                window.localStorage.removeItem(CACHE_KEYS.THINKIFIC_DOMAIN);
            }
            redirectToThinkific(thinkificUrl);
        } else {
            setLoading(false);
            setDomainErrorMessage('the domain cannot be accessed, please check your domain');
        }
    };

    useEffect(() => {
        if (subdomain && !subdomain.match(/\./g)) {
            const thinkificUrl = `https://${subdomain}.thinkific.com${THINKIFIC_AUTHORIZE_ROUTE}`;
            redirectToThinkific(thinkificUrl);
        } else {
            if (!signup) {
                // We use local storage directly because the user is not logged in at this point
                const cachedDomain = window.localStorage.getItem(CACHE_KEYS.THINKIFIC_DOMAIN);
                if (cachedDomain) {
                    setDomain(cachedDomain);
                    setChecked(true);
                }
            }
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subdomain]);

    return (
        <CardWithFooter loading={loading} onSubmit={handleSubmit} buttonLabel="Next" className="md:w-96">
            <p className="paragraph__sm mb-6">
                {signup ? (
                    <>
                        Your Wobo account will be created using your Thinkific account information. The next screen will
                        prompt you to install the Wobo Thinkific app that enables simpler student sign-in.
                    </>
                ) : (
                    <>
                        {student ? (
                            <>
                                Copy and paste the URL of your online course.
                                <br />
                                e.g. example.domain.com
                            </>
                        ) : (
                            <>Enter your Thinkific site&apos;s domain.</>
                        )}
                    </>
                )}
            </p>

            <TextInput
                value={domain}
                placeholder="Thinkific domain"
                onChange={handleDomainChange}
                required
                error={!!domainErrorMessage}
                errorMsg={domainErrorMessage}
                prefixLabel="https://"
                autoCorrect={TEXT_INPUT_AUTO_CORRECT.OFF}
                autoCapitalize={TEXT_INPUT_AUTO_CAPITALIZE.NONE}
                spellCheck={TEXT_INPUT_SPELL_CHECK.FALSE}
            />
            {!signup && (
                <div className="paragraph__sm inline-flex items-center">
                    <label>
                        <input
                            type="checkbox"
                            checked={checked || false}
                            onChange={() => setChecked(!checked)}
                            className={classNames('custom-focus-outline rounded', {
                                'radio-checked': checked,
                            })}
                        />{' '}
                        <span className="ml-1.5">Remember me</span>
                    </label>
                </div>
            )}
        </CardWithFooter>
    );
}
