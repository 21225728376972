import { Suspense, lazy } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '@/auth/AuthProvider';
import { ROLES, DEFAULT_MEMBER_PERMISSIONS, REACT_ROUTER_PATHS, ROUTES, PERMISSIONS } from '@/util/constants';
import { useEmbed } from '@/embed/EmbedProvider';
import LoadingSpinner from '@/system/LoadingSpinner';
import MinimumWidthRoute from '@/navigation/MinimumWidthRoute';
import WorkbookProvider from '@/workbook/builder/WorkbookProvider';
import CohortProvider from '@/cohorts/StudentsProvider';
import WorkbookResponsesProvider from '@/workbook/studentReadOnly/WorkbookVersionProvider';
import { useRoles } from '@/navigation/RolesProvider';

const Settings = lazy(() => import('@/settings/member/Settings'));
const Dashboard = lazy(() => import('@/dashboard/Dashboard'));
const Builder = lazy(() => import('@/workbook/builder/Builder'));
const Preview = lazy(() => import('@/workbook/builder/preview/Preview'));
const PreviewContent = lazy(() => import('@/workbook/builder/preview/PreviewContent'));
const Cohorts = lazy(() => import('@/cohorts/Dashboard'));
const WorkbookResponses = lazy(() => import('@/workbook/studentReadOnly/Workbook'));
const TemplateSelectorV2 = lazy(() => import('@/workbook/templateSelector/TemplateSelectorV2'));

export default function MemberRoutes() {
    const { user } = useAuth();
    const { embedded, originalLocation, removeTokens } = useEmbed();
    const { selectedCreator, member } = useRoles();
    const location = useLocation();

    if (user.roles.includes(ROLES.MEMBER)) {
        const permissions =
            member.creators && member.creators[selectedCreator.id]
                ? member.creators[selectedCreator.id].permissions
                : DEFAULT_MEMBER_PERMISSIONS;
        return (
            <Suspense fallback={<LoadingSpinner />}>
                <Routes>
                    <Route
                        path={REACT_ROUTER_PATHS.MEMBER.DASHBOARD}
                        element={<Dashboard permissions={permissions} />}
                    />
                    <Route path={REACT_ROUTER_PATHS.MEMBER.SETTINGS} element={<Settings key={location.key} />} />
                    {permissions[PERMISSIONS.WORKBOOK_UPDATE] && (
                        <Route
                            path={REACT_ROUTER_PATHS.MEMBER.BUILDER}
                            element={
                                <MinimumWidthRoute>
                                    <WorkbookProvider>
                                        <Builder permissions={permissions} />
                                    </WorkbookProvider>
                                </MinimumWidthRoute>
                            }
                        />
                    )}
                    <Route path={REACT_ROUTER_PATHS.MEMBER.PREVIEW} element={<Preview />} />
                    <Route
                        path={REACT_ROUTER_PATHS.MEMBER.PREVIEW_CONTENT}
                        element={
                            <WorkbookProvider>
                                <PreviewContent />
                            </WorkbookProvider>
                        }
                    />
                    <Route
                        path={REACT_ROUTER_PATHS.MEMBER.COHORTS_DASHBOARD}
                        element={
                            <MinimumWidthRoute>
                                <CohortProvider>
                                    <Cohorts permissions={permissions} />
                                </CohortProvider>
                            </MinimumWidthRoute>
                        }
                    />
                    <Route
                        path={REACT_ROUTER_PATHS.MEMBER.STUDENT_RESPONSES}
                        element={
                            <WorkbookResponsesProvider>
                                <WorkbookResponses permissions={permissions} />
                            </WorkbookResponsesProvider>
                        }
                    />
                    {permissions[PERMISSIONS.WORKBOOK_CREATE] && (
                        <Route
                            path={REACT_ROUTER_PATHS.MEMBER.TEMPLATE_SELECTOR}
                            element={<TemplateSelectorV2 permissions={permissions} />}
                        />
                    )}
                    <Route path="*" element={<Navigate to={ROUTES.MEMBER.DASHBOARD} replace />} />
                </Routes>
            </Suspense>
        );
    }
    if (embedded) {
        removeTokens();
        return <Navigate to={ROUTES.COMMON.EMBED_LOGIN} state={{ from: originalLocation }} />;
    }

    return <Navigate to="/" state={{ from: location }} />;
}
