import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { getAccessTokenRequest } from '@/api/helpers';
import { fromDto } from '@/auth/useUserReducer';
import { EMBED_QUERY_PARAMS, PREVIEW_POST_MESSAGE_ACTIONS } from '@/util/constants';

const supportedActions = [
    process.env.REACT_APP_MESSAGE_ACTION_UPDATE_TOKENS,
    process.env.REACT_APP_MESSAGE_ACTION_UPDATE_METADATA,
    PREVIEW_POST_MESSAGE_ACTIONS.UPDATE_PREVIEW_TOKENS,
];

export const TYPES = {
    DEFAULT: 'default',
    THINKIFIC: 'thinkific',
    KAJABI: 'kajabi',
    TEACHABLE: 'teachable',
    ZENLER: 'zenler',
    CUSTOM: 'custom',
    PUBLIC_LINK: 'public_link',
    INVITE_BY_EMAIL: 'invite_by_email',
    LMS_INTEGRATION: 'lms_integration',
    EMBED: 'embed',
};

// Note that this is the only API call performed outside the useApi hook.
// This is done to avoid dependency cycle errors
const getUser = async (accessToken) => {
    const url = `${process.env.REACT_APP_API_BASE_URL}/users`;
    const userDto = await getAccessTokenRequest(url, accessToken);
    return fromDto(userDto);
};

const getQueryParam = (key) => {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    return searchParams.get(key);
};

const isEmbedded = () => {
    return getQueryParam(EMBED_QUERY_PARAMS.EMBED) === 'true';
};

const getEmbedType = () => {
    return getQueryParam(EMBED_QUERY_PARAMS.TYPE) || TYPES.DEFAULT;
};

const getEmbedContext = () => {
    return getQueryParam(EMBED_QUERY_PARAMS.CONTEXT);
};

const getThinkificV2 = () => {
    return getQueryParam(EMBED_QUERY_PARAMS.THINKIFIC_V2) === 'true';
};

const EmbedContext = createContext();

export function useEmbed() {
    return useContext(EmbedContext);
}

export default function EmbedProvider({ children }) {
    const isMounted = useRef(true);
    const embedded = useRef(isEmbedded());
    const type = useRef(getEmbedType());
    const context = useRef(getEmbedContext());
    const thinkificV2 = useRef(getThinkificV2());
    const [loadingTokens, setLoadingTokens] = useState(embedded.current);
    const [tokens, setTokens] = useState({});
    const [loadingMetadata, setLoadingMetadata] = useState(true);
    const [originalLocation, setOriginalLocation] = useState(null);
    const [metadata, setMetadata] = useState({});

    const removeTokens = () => {
        setTokens({});
        window.parent.postMessage({ action: process.env.REACT_APP_MESSAGE_ACTION_REMOVE_TOKENS }, '*');
    };

    const updateTokens = (updatedTokens) => {
        setTokens(updatedTokens);
        setLoadingTokens(false);
        window.parent.postMessage(
            { action: process.env.REACT_APP_MESSAGE_ACTION_UPDATE_TOKENS, payload: updatedTokens },
            '*',
        );
    };

    // This is used for embed links where we directly kick-off the SSO process
    // We're going to generate a new access token for each embed request and
    // store the access token in memory.
    const updateTokensV2 = (updatedTokens) => {
        setTokens(updatedTokens);
        setLoadingTokens(false);
    };

    const updateOpenerTokens = (updatedTokens) => {
        setTokens(updatedTokens);
        window.opener.parent.postMessage(
            { action: process.env.REACT_APP_MESSAGE_ACTION_UPDATE_TOKENS, payload: updatedTokens },
            '*',
        );
    };

    useEffect(() => {
        if (!thinkificV2.current) {
            window.addEventListener('message', (event) => {
                const { action, payload } = event.data;
                if (isMounted.current) {
                    if (
                        !action ||
                        !payload ||
                        !(payload.accessToken || payload.hostname) ||
                        !supportedActions.includes(action)
                    ) {
                        setLoadingTokens(false);
                        setLoadingMetadata(false);
                        return;
                    }
                    if (
                        action === process.env.REACT_APP_MESSAGE_ACTION_UPDATE_TOKENS ||
                        action === PREVIEW_POST_MESSAGE_ACTIONS.UPDATE_PREVIEW_TOKENS
                    ) {
                        getUser(payload.accessToken)
                            .then(() => {
                                if (isMounted.current) {
                                    setTokens(payload);
                                    setLoadingTokens(false);
                                }
                            })
                            .catch(() => {
                                if (isMounted.current) {
                                    setLoadingTokens(false);
                                }
                            });
                    } else if (action === process.env.REACT_APP_MESSAGE_ACTION_UPDATE_METADATA) {
                        if (isMounted.current) {
                            setMetadata(payload);
                            setLoadingMetadata(false);
                        }
                    }
                }
            });
        }
    }, []);

    useEffect(() => {
        isMounted.current = true;

        if (embedded && isMounted.current) {
            setOriginalLocation({ pathname: window.location.pathname, search: window.location.search });
            if (!thinkificV2.current) {
                window.parent.postMessage({ action: process.env.REACT_APP_MESSAGE_ACTION_REQUEST_METADATA }, '*');
                window.parent.postMessage({ action: process.env.REACT_APP_MESSAGE_ACTION_REQUEST_TOKENS }, '*');
            }
        }

        return () => {
            isMounted.current = false;
        };
    }, []);

    const embed = {
        embedded: embedded.current,
        type: type.current,
        context: context.current,
        tokens,
        originalLocation,
        metadata,
        loadingTokens,
        loadingMetadata,
        removeTokens,
        updateTokens,
        updateTokensV2,
        updateOpenerTokens,
    };

    return <EmbedContext.Provider value={embed}>{children}</EmbedContext.Provider>;
}
