import classNames from 'classnames';
import { PlusIcon, MinusIcon } from '@heroicons/react/solid';

export default function NumberMinusPlus({ number, onChange, min = 1, max = 20 }) {
    const disableMinus = number === min;
    const disablePlus = number === max;

    const handleNumberDecrease = () => {
        if (number - 1 >= min) onChange(number - 1);
    };

    const handleNumberIncrease = () => {
        if (number + 1 <= max) onChange(number + 1);
    };

    return (
        <div className="flex w-full flex-nowrap items-center rounded-md border border-neutral-100 py-2.5 px-3.5">
            <div className="w-1/2">{number}</div>
            <div className="flex w-1/2 text-[12px]">
                <button
                    type="button"
                    className="flex h-6.5 w-8 items-center justify-center rounded-l-md border border-neutral-100"
                    onClick={handleNumberDecrease}
                    disabled={disableMinus}>
                    <MinusIcon className={classNames('w-em', { 'opacity-25': disableMinus })} />
                </button>
                <button
                    type="button"
                    className="flex h-6.5 w-8 items-center justify-center rounded-r-md border-t border-r border-b border-neutral-100"
                    onClick={handleNumberIncrease}
                    disabled={disablePlus}>
                    <PlusIcon className={classNames('w-em', { 'opacity-25': disablePlus })} />
                </button>
            </div>
        </div>
    );
}
