import { useState } from 'react';
import TextInput, {
    TEXT_INPUT_AUTO_CAPITALIZE,
    TEXT_INPUT_AUTO_CORRECT,
    TEXT_INPUT_SPELL_CHECK,
} from '@/system/TextInput';
import { CardWithFooter } from '@/auth/Cards';

export default function TeachableLogin({ getLoginUrl }) {
    const [loading, setLoading] = useState(false);
    const [domain, setDomain] = useState('');
    const [subdomain, setSubdomain] = useState('');
    const [domainErrorMessage, setDomainErrorMessage] = useState('');
    const [subdomainErrorMessage, setSubdomainErrorMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setDomainErrorMessage('');
        setSubdomainErrorMessage('');

        if (domain !== '' || subdomain !== '') {
            setLoading(true);
            const loginUrl = await getLoginUrl(domain || subdomain, 'teachable');
            if (loginUrl) {
                window.location.href = loginUrl;
            } else {
                setLoading(false);
                if (domain !== '') {
                    setDomainErrorMessage("We couldn't find a Teachable site with that domain.");
                } else {
                    setSubdomainErrorMessage("We couldn't find a Teachable site with that subdomain.");
                }
                return false;
            }
        } else {
            setDomainErrorMessage('At least one of the fields is required');
            setSubdomainErrorMessage('At least one of the fields is required');
        }
        return false;
    };

    return (
        <CardWithFooter loading={loading} onSubmit={handleSubmit} buttonLabel="Next" className="md:w-fit">
            <p className="paragraph__sm mb-6">Enter your Teachable domain or sub-domain to sign in.</p>
            <TextInput
                value={domain}
                placeholder="Teachable domain"
                onChange={(e) => setDomain(e.target.value)}
                error={!!domainErrorMessage}
                errorMsg={domainErrorMessage}
                prefixLabel="https://"
                autoCorrect={TEXT_INPUT_AUTO_CORRECT.OFF}
                autoCapitalize={TEXT_INPUT_AUTO_CAPITALIZE.NONE}
                spellCheck={TEXT_INPUT_SPELL_CHECK.FALSE}
            />
            <p className="mb-3 text-center">or</p>
            <TextInput
                value={subdomain}
                placeholder="Teachable sub domain"
                onChange={(e) => setSubdomain(e.target.value)}
                error={!!subdomainErrorMessage}
                errorMsg={subdomainErrorMessage}
                prefixLabel="https://"
                suffixLabel=".teachable.com"
                autoCorrect={TEXT_INPUT_AUTO_CORRECT.OFF}
                autoCapitalize={TEXT_INPUT_AUTO_CAPITALIZE.NONE}
                spellCheck={TEXT_INPUT_SPELL_CHECK.FALSE}
            />
        </CardWithFooter>
    );
}
