import { useWorkbook } from '@/workbook/artifact/WorkbookJsonLoaderProvider';
import LoadingSpinner from '@/system/LoadingSpinner';
import TechnicalError from '@/system/TechnicalError';
import Viewer from '@/workbook/thumbnail/Viewer';
import usePrintable from '@/workbook/usePrintable';

export default function Workbook() {
    const { workbook, loading, loadingError } = useWorkbook();
    usePrintable();

    if (loading) return <LoadingSpinner />;
    if (loadingError) return <TechnicalError title="Error loading workbook" desc={`Details: ${loadingError}`} />;
    return <Viewer workbook={workbook} />;
}
