import { Routes, Route } from 'react-router-dom';
import CreatorRoutes from '@/navigation/CreatorRoutes';
import StudentRoutes from '@/navigation/StudentRoutes';
import MemberRoutes from '@/navigation/MemberRoutes';
import { STUDENT_ROUTE_BASE_PREFIX, CREATOR_ROUTE_BASE_PREFIX, MEMBER_ROUTE_BASE_PREFIX } from '@/util/constants';

export default function RoleRoutes() {
    return (
        <Routes>
            <Route path={`${STUDENT_ROUTE_BASE_PREFIX}*`} element={<StudentRoutes />} />
            <Route path={`${CREATOR_ROUTE_BASE_PREFIX}*`} element={<CreatorRoutes />} />
            <Route path={`${MEMBER_ROUTE_BASE_PREFIX}*`} element={<MemberRoutes />} />
        </Routes>
    );
}
