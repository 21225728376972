import classNames from 'classnames';

export default function TableLayoutIcon1({ className = '', style = {} }) {
    return (
        <svg
            width="39"
            height="39"
            viewBox="0 0 39 39"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={classNames('w-em', className)}
            style={style}>
            <g filter="url(#filter0_dd_302_2920)">
                <g clipPath="url(#clip0_302_2920)">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M19.9258 2.12598H3.92627V11.9402H19.9258V2.12598ZM35.9262 2.12598H19.9266V11.9402H35.9262V2.12598Z"
                        fill="currentColor"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M19.9258 11.9404H3.92627V34.1262H19.9258V11.9404ZM35.9262 11.9404H19.9266V34.1262H35.9262V11.9404Z"
                        fill="white"
                    />
                    <path d="M3.92627 11.9404H35.9262" stroke="#272727" />
                    <path d="M19.9263 2.12598L19.9263 34.1259" stroke="#272727" />
                </g>
            </g>
            <defs>
                <filter
                    id="filter0_dd_302_2920"
                    x="0.92627"
                    y="0.125977"
                    width="38"
                    height="38"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_302_2920" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="1.5" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="effect1_dropShadow_302_2920" result="effect2_dropShadow_302_2920" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_302_2920" result="shape" />
                </filter>
                <clipPath id="clip0_302_2920">
                    <path
                        d="M3.92627 4.12598C3.92627 3.02141 4.8217 2.12598 5.92627 2.12598H33.9263C35.0308 2.12598 35.9263 3.02141 35.9263 4.12598V32.126C35.9263 33.2305 35.0308 34.126 33.9263 34.126H5.92627C4.8217 34.126 3.92627 33.2305 3.92627 32.126V4.12598Z"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}
