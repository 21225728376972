import { memo } from 'react';
import { WOBO_APP_URL } from '@/util/constants';

const referralUrl = `${WOBO_APP_URL}/?utm_source=poweredbywobo&utm_medium=referral`;

const PoweredByWobo = ({ enable, overlay }) => {
    if (!enable) return null;
    return overlay ? (
        <div className="w-44 absolute top-6 right-6" data-test="powered-by-wobo">
            <a href={referralUrl} target="blank">
                <img src="/images/powered-by-wobo.png" alt="Logo" />
            </a>
        </div>
    ) : (
        <div className="flex flex-row-reverse" data-test="powered-by-wobo">
            <div className="w-44">
                <a href={referralUrl} target="blank">
                    <img src="/images/powered-by-wobo.png" alt="Logo" />
                </a>
            </div>
        </div>
    );
};

export default memo(PoweredByWobo);
