import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import get from 'lodash/get';
import useStudentApi from '@/api/useStudentApi';
import useApi from '@/api/useApi';
import useWorkbookVersionReducer, {
    ACTIONS,
    getModuleVersionAndResponse,
    getPrevModule,
    getNextModule,
} from '@/workbook/useWorkbookVersionReducer';
import { workbookVersionFromDto, moduleVersionFromDto, moduleResponseFromDto } from '@/api/dtos';
import { TOAST_POSITION, useToast } from '@/system/ToastProvider';
import {
    getStorageObjectBasePath,
    getWorkbookUploadsStorageObjectBasePath,
    getStudentSectionStorageObjectBasePath,
    getArtifactOptionalInfo,
    appendUniqueIdToStr,
    deepCopy,
} from '@/util/helpers';
import useQueryParameter from '@/util/useQueryParameter';
import { WORKBOOK_VERSION_ID_QUERY_PARAM } from '@/util/constants';
import { useRoles } from '@/navigation/RolesProvider';
import { useAuth } from '@/auth/AuthProvider';

const WorkbookVersionContext = createContext();

export function useWorkbookVersion() {
    return useContext(WorkbookVersionContext);
}

export default function WorkbookVersionProvider({ children }) {
    const isMounted = useRef(true);
    const { state, pathname, search } = useLocation();
    const routerState = (state && state.workbookVersion) || {};
    const navigate = useNavigate();
    const { [WORKBOOK_VERSION_ID_QUERY_PARAM]: workbookVersionId } = useParams();
    const [workbookVersion, setWorkbookVersion] = useWorkbookVersionReducer();
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    // The workbookArtifactDownloadUrl state is stored at the provider level because we
    // don't want to regenerate an artifact unnecessarily. Once an artifact is generated
    // we don't need to regenerate a new one until a new response is made.
    const [workbookArtifactDownloadUrl, setWorkbookArtifactDownloadUrl] = useState(null);
    const [totalResponsesChanged, setTotalResponsesChanged] = useState(0);
    const [loadingError, setLoadingError] = useState('');
    const { student, selectedCreator } = useRoles();
    const { user } = useAuth();
    const queryParamModuleId = useQueryParameter('module_id');
    const {
        getStudentWorkbookVersion,
        getStudentWorkbookVersionModules,
        getStudentWorkbookVersionModuleResponses,
        updateStudentModuleResponseToOpened,
        upsertStudentSectionResponseProp,
        createStudentWorkbookArtifactSignedUrl,
        createStudentWorkbookModuleArtifactSignedUrl,
        logStudentAccess,
    } = useStudentApi();
    const { saveStorageObject, deleteStorageObject, getCommentsByWorkbookVersionId } = useApi();
    const { pushError } = useToast(TOAST_POSITION.BOTTOM);

    const updateModuleResponseToOpened = (moduleVersionId) => {
        const { moduleResponse } = getModuleVersionAndResponse(workbookVersion, moduleVersionId);
        if (!moduleResponse.opened) {
            updateStudentModuleResponseToOpened(
                student.id,
                workbookVersion.id,
                workbookVersion.cohortId,
                moduleResponse.id,
            );
        }
    };

    const generateWorkbookArtifact = async () => {
        const optionalInfo = getArtifactOptionalInfo(selectedCreator.schema, user);

        return createStudentWorkbookArtifactSignedUrl(
            student.id,
            workbookVersion.id,
            workbookVersion.cohortId,
            optionalInfo,
        )
            .then((dto) => {
                if (dto.url) {
                    return fetch(dto.url)
                        .then((res) => res.blob())
                        .then((blob) => {
                            const objectUrl = URL.createObjectURL(blob);
                            setWorkbookArtifactDownloadUrl(objectUrl);
                            return dto;
                        })
                        .catch((err) => {
                            pushError('Could not access PDF', null);
                            console.error(err);
                            throw err;
                        });
                }
                return dto;
            })
            .catch((err) => {
                pushError('Could not create PDF', null);
                console.error(err);
                throw err;
            });
    };

    const generateWorkbookModuleArtifact = async (moduleId) => {
        const optionalInfo = getArtifactOptionalInfo(selectedCreator.schema, user);

        return createStudentWorkbookModuleArtifactSignedUrl(
            student.id,
            workbookVersion.id,
            moduleId,
            workbookVersion.cohortId,
            optionalInfo,
        )
            .then((dto) => {
                if (dto.url) {
                    return fetch(dto.url)
                        .then((res) => res.blob())
                        .then((blob) => {
                            const objectUrl = URL.createObjectURL(blob);
                            return { url: objectUrl };
                        })
                        .catch((err) => {
                            pushError('Could not access PDF', null);
                            console.error(err);
                            throw err;
                        });
                }
                return dto;
            })
            .catch((err) => {
                pushError('Could not create PDF', null);
                console.error(err);
                throw err;
            });
    };

    const selectModule = (moduleVersionId) => {
        updateModuleResponseToOpened(moduleVersionId);
        setWorkbookVersion({ type: ACTIONS.SELECT_MODULE, payload: moduleVersionId });
    };

    const selectPrevModule = () => {
        const prevModule = getPrevModule(workbookVersion);
        if (prevModule) {
            updateModuleResponseToOpened(prevModule.id);
        }
        setWorkbookVersion({ type: ACTIONS.SELECT_PREV_MODULE });
    };

    const selectNextModule = () => {
        const nextModule = getNextModule(workbookVersion);
        if (nextModule) {
            updateModuleResponseToOpened(nextModule.id);
        }
        setWorkbookVersion({ type: ACTIONS.SELECT_NEXT_MODULE });
    };

    const selectSection = (sectionId) => {
        setWorkbookVersion({ type: ACTIONS.SELECT_SECTION, payload: sectionId });
    };

    const setSectionResponseProp = (sectionId, path, value) => {
        setWorkbookVersion({ type: ACTIONS.UPDATE_SECTION_RESPONSE_PROP, payload: { sectionId, path, value } });
    };

    const saveSectionResponseProp = async (sectionId, path, value) => {
        setSaving(true);
        const { selectedModuleResponse } = workbookVersion;
        setWorkbookVersion({ type: ACTIONS.UPDATE_SECTION_RESPONSE_PROP, payload: { sectionId, path, value } });
        return upsertStudentSectionResponseProp(
            student.id,
            workbookVersion.id,
            workbookVersion.cohortId,
            selectedModuleResponse.id,
            sectionId,
            path,
            value,
        )
            .then(() => {
                if (isMounted.current) {
                    setSaving(false);
                    setWorkbookArtifactDownloadUrl(null);
                    setTotalResponsesChanged(totalResponsesChanged + 1);
                }
            })
            .catch((error) => {
                if (isMounted.current) {
                    pushError(`Error updating section schema: ${error.message}`, null);
                    setSaving(false);
                }
            });
    };

    const updateResponseFile = (sectionId, path, filename, storageFilename, size) => {
        setSaving(true);
        const { selectedModuleResponse } = workbookVersion;
        const previousValue = deepCopy(get(selectedModuleResponse.sectionResponses, `${sectionId}.${path}`) || {});
        const value = {
            filename,
            storage_filename: storageFilename,
            size,
        };
        setWorkbookVersion({
            type: ACTIONS.UPDATE_SECTION_RESPONSE_PROP,
            payload: { sectionId, path, value },
        });
        upsertStudentSectionResponseProp(
            student.id,
            workbookVersion.id,
            workbookVersion.cohortId,
            selectedModuleResponse.id,
            sectionId,
            path,
            value,
        )
            .then(() => {
                if (isMounted.current && previousValue.storage_filename) {
                    deleteStorageObject(
                        `${getStudentSectionStorageObjectBasePath(
                            workbookVersion.schema.workbookUploadsStorageObjectBasePath,
                            sectionId,
                            student.id,
                        )}${previousValue.storage_filename}`,
                    );
                }
            })
            .catch((error) => {
                if (isMounted.current) {
                    setWorkbookVersion({
                        type: ACTIONS.UPDATE_SECTION_RESPONSE_PROP,
                        payload: { sectionId, path, value: previousValue },
                    });
                    pushError(`Error updating section file: ${error.message}`, null);
                    setSaving(false);
                }
            });
    };

    const saveResponseFile = async (sectionId, path, fileData, filePrefix) => {
        setSaving(true);
        const filename = appendUniqueIdToStr(filePrefix);
        const objectKey = `${getStudentSectionStorageObjectBasePath(
            workbookVersion.schema.workbookUploadsStorageObjectBasePath,
            sectionId,
            student.id,
        )}${filename}`;
        return saveStorageObject(objectKey, fileData)
            .then(() => updateResponseFile(sectionId, path, fileData.name, filename, fileData.size))
            .catch((error) => {
                pushError(`Error saving response storage object : ${error.message}`, null);
                setSaving(false);
            });
    };

    const resetResponseFile = (sectionId, path) => {
        const file = get(workbookVersion.selectedModuleResponse.sectionResponses, `${sectionId}.${path}`);
        if (!file || !file.storage_filename) return;
        updateResponseFile(sectionId, path, '', '');
    };

    const loadWorkbookVersion = async () => {
        const payload = {
            workbookVersion: {},
            modules: [],
            moduleResponses: [],
            comments: [],
            commentingUsers: [],
        };
        if (routerState.id === workbookVersionId) {
            payload.workbookVersion = routerState;
        } else {
            const workbookVersionDto = await getStudentWorkbookVersion(student.id, workbookVersionId);
            payload.workbookVersion = workbookVersionFromDto(workbookVersionDto);
        }

        if (payload.workbookVersion.cohortIds?.[0]) {
            const moduleResponseDtos = await getStudentWorkbookVersionModuleResponses(
                student.id,
                workbookVersionId,
                payload.workbookVersion.cohortIds[0],
            ).catch((error) => {
                if (isMounted.current) {
                    const fullError = `Error getting module responses: ${error.message}`;
                    pushError(fullError, null);
                    throw new Error(fullError);
                }
            });
            logStudentAccess(student.id, workbookVersionId);
            if (moduleResponseDtos && moduleResponseDtos.length > 0) {
                payload.moduleResponses = moduleResponseDtos.map((dto) => moduleResponseFromDto(dto));
            }
        }

        if (payload.workbookVersion.moduleVersionIds.length > 0) {
            const moduleVersionDtos = await getStudentWorkbookVersionModules(student.id, workbookVersionId).catch(
                (error) => {
                    if (isMounted.current) {
                        const fullError = `Error getting modules: ${error.message}`;
                        pushError(fullError, null);
                        throw new Error(fullError);
                    }
                },
            );
            if (moduleVersionDtos && moduleVersionDtos.length > 0) {
                payload.modules = moduleVersionDtos.map((dto) => moduleVersionFromDto(dto));
            }
        }
        const { comments, users } = await getCommentsByWorkbookVersionId(workbookVersionId, student.id);
        payload.comments = comments;
        payload.commentingUsers = users;
        return payload;
    };

    const setSectionCommentAdded = (comment) => {
        setWorkbookVersion({ type: ACTIONS.ADD_COMMENT, payload: comment });
    };

    const setSectionCommentUpdated = (comment) => {
        setWorkbookVersion({ type: ACTIONS.UPDATE_COMMENT, payload: comment });
    };

    const setSectionCommentDeleted = (comment) => {
        setWorkbookVersion({ type: ACTIONS.DELETE_COMMENT, payload: comment });
    };

    useEffect(() => {
        const revokeUrl = workbookArtifactDownloadUrl;
        return () => {
            if (revokeUrl) URL.revokeObjectURL(revokeUrl);
        };
    }, [workbookArtifactDownloadUrl]);

    useEffect(() => {
        isMounted.current = true;
        if (isMounted.current) {
            setLoading(true);
            loadWorkbookVersion()
                .then((response) => {
                    const cohortId =
                        response.workbookVersion.cohortIds && response.workbookVersion.cohortIds.length > 0
                            ? response.workbookVersion.cohortIds[0]
                            : '';
                    const payload = {
                        ...response,
                        workbookVersion: {
                            ...response.workbookVersion,
                            schema: {
                                ...response.workbookVersion.schema,
                                storageObjectBasePath: getStorageObjectBasePath(
                                    response.workbookVersion.creatorId,
                                    response.workbookVersion.workbookId,
                                    response.workbookVersion.id,
                                ),
                                workbookUploadsStorageObjectBasePath: getWorkbookUploadsStorageObjectBasePath(
                                    response.workbookVersion.creatorId,
                                    response.workbookVersion.workbookId,
                                ),
                            },
                        },
                        cohortId,
                        moduleId: queryParamModuleId,
                    };
                    if (isMounted.current) {
                        setWorkbookVersion({ type: ACTIONS.SET, payload });
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    if (isMounted.current) {
                        setLoadingError(error.message);
                        setLoading(false);
                    }
                });
        }
        // Reset router state by leaving the second parameter's state property undefined to ensure
        // the latest workbook data is used when rendering
        navigate(`${pathname}${search}`, { replace: true });
        return () => {
            isMounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (workbookVersion.initialized && workbookVersion.selectedModule?.id) {
            updateModuleResponseToOpened(workbookVersion.selectedModule.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workbookVersion.initialized]);

    const context = {
        workbookVersion,
        loading,
        loadingError,
        saving,
        selectModule,
        selectPrevModule,
        selectNextModule,
        selectSection,
        setSectionResponseProp,
        saveSectionResponseProp,
        generateWorkbookArtifact,
        generateWorkbookModuleArtifact,
        saveResponseFile,
        resetResponseFile,
        workbookArtifactDownloadUrl,
        totalResponsesChanged,
        setSectionCommentAdded,
        setSectionCommentUpdated,
        setSectionCommentDeleted,
    };

    return <WorkbookVersionContext.Provider value={context}>{children}</WorkbookVersionContext.Provider>;
}
