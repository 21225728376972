import { formatISO } from 'date-fns';
import { putSimpleRequest } from '@/api/helpers';
import {
    newWorkbookDto,
    updateWorkbookNameDto,
    updateWorkbookSchemaDto,
    updateWorkbookDraftDto,
    newModuleDto,
    newSectionDto,
    newSectionsDtoFromTemplate,
    updateModuleTitleDto,
    updateSectionSchemaDto,
    duplicatedSectionDto,
    newCohortDto,
    updateCohortDto,
    studentEnrollmentDto,
    newEvergreenCohortDto,
    updateWorkbookCommentingDto,
} from '@/api/dtos';
import { dateWithoutTime } from '@/util/helpers';
import { ROLES } from '@/util/constants';
import { useRoles } from '@/navigation/RolesProvider';
import useRequest from '@/api/useRequest';

export default function useApi() {
    const { getRequest, postRequest, patchRequest, deleteRequest, putRequest } = useRequest();
    const { currentRole, member } = useRoles();
    const baseUrl = `${process.env.REACT_APP_API_BASE_URL}${
        currentRole === ROLES.MEMBER ? `/members/${member.id}/creators/` : '/creators/'
    }`;

    const throwErrorIfRoleIsMember = () => {
        if (currentRole === ROLES.MEMBER) throw new Error('operation not supported for member');
    };

    const getCreatorWorkbook = async (creatorId, workbookId) => {
        const url = `${baseUrl}${creatorId}/workbooks/${workbookId}`;
        return getRequest(url);
    };

    const getCreatorWorkbooks = async (creatorId) => {
        const url = `${baseUrl}${creatorId}/workbooks`;
        return getRequest(url);
    };

    const getCreatorCohort = async (creatorId, workbookId, cohortId) => {
        const url = `${baseUrl}${creatorId}/workbooks/${workbookId}/cohorts/${cohortId}`;
        return getRequest(url);
    };

    const getCreatorCohorts = async (creatorId) => {
        const url = `${baseUrl}${creatorId}/cohorts`;
        return getRequest(url);
    };

    const getCohortStudents = async (creatorId, workbookId, cohortId, dataQueryOptions) => {
        const url = `${baseUrl}${creatorId}/workbooks/${workbookId}/cohorts/${cohortId}/students`;
        return getRequest(url, dataQueryOptions, true);
    };

    const publishWorkbook = async (creatorId, workbookId, workbookVersionId) => {
        const url = `${baseUrl}${creatorId}/workbooks/${workbookId}/versions/${workbookVersionId}`;
        const queryParams = { action: 'publish' };
        return patchRequest(url, null, queryParams);
    };

    const createWorkbook = async (creatorId, name, description, schema) => {
        const url = `${baseUrl}${creatorId}/workbooks`;
        const payload = newWorkbookDto(name, description, schema);
        return postRequest(url, payload);
    };

    const duplicateWorkbook = async (creatorId, sourceWorkbookId) => {
        const url = `${baseUrl}${creatorId}/workbooks`;
        const queryParams = { source_id: sourceWorkbookId };
        return postRequest(url, null, queryParams);
    };

    const deleteWorkbook = async (creatorId, workbookId) => {
        const url = `${baseUrl}${creatorId}/workbooks/${workbookId}`;
        return deleteRequest(url);
    };

    const updateWorkbookName = async (creatorId, workbookId, workbookName) => {
        const url = `${baseUrl}${creatorId}/workbooks/${workbookId}`;
        const payload = updateWorkbookNameDto(workbookName);
        return patchRequest(url, payload);
    };

    const updateWorkbookCommenting = async (
        creatorId,
        workbookId,
        commentingEnabled,
        notificationsEnabled,
        courseName,
        courseUrl,
    ) => {
        const url = `${baseUrl}${creatorId}/workbooks/${workbookId}/commenting`;
        const payload = updateWorkbookCommentingDto(commentingEnabled, notificationsEnabled, courseName, courseUrl);
        return patchRequest(url, payload);
    };

    const upsertWorkbookSchema = async (creatorId, workbookId, path, value) => {
        const url = `${baseUrl}${creatorId}/workbooks/${workbookId}/schema_property`;
        const payload = updateWorkbookSchemaDto(path, value);
        return putRequest(url, payload);
    };

    const updateWorkbookDraft = async (creatorId, workbookId) => {
        const url = `${baseUrl}${creatorId}/workbooks/${workbookId}`;
        const payload = updateWorkbookDraftDto();
        return patchRequest(url, payload);
    };

    const getCreatorWorkbookModules = async (creatorId, workbookId) => {
        const url = `${baseUrl}${creatorId}/workbooks/${workbookId}/modules`;
        return getRequest(url);
    };

    const createModule = async (creatorId, workbookId, title) => {
        const url = `${baseUrl}${creatorId}/workbooks/${workbookId}/modules`;
        const payload = newModuleDto(title);
        return postRequest(url, payload);
    };

    const duplicateModule = async (creatorId, workbookId, sourceModuleId) => {
        const url = `${baseUrl}${creatorId}/workbooks/${workbookId}/modules`;
        const queryParams = { source_id: sourceModuleId };
        return postRequest(url, null, queryParams);
    };

    const deleteModule = async (creatorId, workbookId, moduleId) => {
        const url = `${baseUrl}${creatorId}/workbooks/${workbookId}/modules/${moduleId}`;
        return deleteRequest(url);
    };

    const updateModuleTitle = async (creatorId, workbookId, moduleId, moduleTitle, moduleShowTitle) => {
        const url = `${baseUrl}${creatorId}/workbooks/${workbookId}/modules/${moduleId}`;
        const payload = updateModuleTitleDto(moduleTitle, moduleShowTitle);
        return patchRequest(url, payload);
    };

    const moveModule = async (creatorId, workbookId, fromIndex, toIndex) => {
        const url = `${baseUrl}${creatorId}/workbooks/${workbookId}/modules`;
        const queryParams = { from: fromIndex, to: toIndex };
        return patchRequest(url, null, queryParams);
    };

    const appendSection = async (creatorId, workbookId, moduleId, sectionType) => {
        const url = `${baseUrl}${creatorId}/workbooks/${workbookId}/modules/${moduleId}/sections`;
        const payload = newSectionDto(sectionType);
        const queryParams = { action: 'append' };
        return postRequest(url, payload, queryParams);
    };

    const appendSectionsFromTemplate = async (creatorId, workbookId, moduleId, sectionTemplates) => {
        const url = `${baseUrl}${creatorId}/workbooks/${workbookId}/modules/${moduleId}/sections`;
        const payload = newSectionsDtoFromTemplate(sectionTemplates);
        const queryParams = { action: 'append_many' };
        return postRequest(url, payload, queryParams);
    };

    const duplicateSection = async (creatorId, workbookId, moduleId, section, index) => {
        const url = `${baseUrl}${creatorId}/workbooks/${workbookId}/modules/${moduleId}/sections`;
        const payload = duplicatedSectionDto(section);
        const queryParams = { action: 'insert', at: index };
        return postRequest(url, payload, queryParams);
    };

    const postCheckoutSession = async (creatorId, plan, monthlyPricing, addOns) => {
        throwErrorIfRoleIsMember();
        const url = `${process.env.REACT_APP_API_BASE_URL}/creators/${creatorId}/payment/billing-checkout`;
        const payload = { plan };
        payload.monthlyPricing = monthlyPricing;
        if (Array.isArray(addOns)) payload.add_ons = addOns;
        return postRequest(url, payload);
    };

    const processCheckout = async (creatorId, sessionId) => {
        throwErrorIfRoleIsMember();
        const url = `${process.env.REACT_APP_API_BASE_URL}/creators/${creatorId}/payment/subscription`;
        const payload = { session_id: sessionId };
        return postRequest(url, payload);
    };

    const getBillingPortal = async (creatorId) => {
        throwErrorIfRoleIsMember();
        const url = `${process.env.REACT_APP_API_BASE_URL}/creators/${creatorId}/payment/billing-portal`;
        return getRequest(url);
    };

    const cancelSubscription = async (creatorId) => {
        throwErrorIfRoleIsMember();
        const url = `${process.env.REACT_APP_API_BASE_URL}/creators/${creatorId}/payment/subscription`;
        const queryParams = { action: 'cancel' };
        return patchRequest(url, null, queryParams);
    };

    const updateSubscription = async (plan) => {
        throwErrorIfRoleIsMember();
        const url = `${process.env.REACT_APP_API_BASE_URL}/users/stripe-subscription`;
        const queryParams = { action: 'update' };
        const payload = { plan };
        return patchRequest(url, payload, queryParams);
    };

    const updateSubscriptionChangePlan = async (creatorId, planId) => {
        throwErrorIfRoleIsMember();
        const url = `${process.env.REACT_APP_API_BASE_URL}/creators/${creatorId}/payment/subscription`;
        const queryParams = { action: 'change_plan' };
        const payload = { plan: planId };
        return patchRequest(url, payload, queryParams);
    };

    const updateSubscriptionWithAddOn = async (creatorId, addOn) => {
        throwErrorIfRoleIsMember();
        const url = `${process.env.REACT_APP_API_BASE_URL}/creators/${creatorId}/payment/subscription`;
        const queryParams = { action: 'create_add_on' };
        const payload = { type: addOn };
        return patchRequest(url, payload, queryParams);
    };

    const removeSubscriptionAddOn = async (creatorId, addOn) => {
        throwErrorIfRoleIsMember();
        const url = `${process.env.REACT_APP_API_BASE_URL}/creators/${creatorId}/payment/subscription`;
        const queryParams = { action: 'remove_add_on' };
        const payload = { type: addOn };
        return patchRequest(url, payload, queryParams);
    };

    const updateStudentImageUploadStorageAddOnUsage = async (creatorId) => {
        throwErrorIfRoleIsMember();
        const url = `${process.env.REACT_APP_API_BASE_URL}/creators/${creatorId}/student-image-upload-add-on-usage`;
        return postRequest(url);
    };

    const getSubscriptionUpcomingInvoice = async (creatorId, subscriptionId) => {
        throwErrorIfRoleIsMember();
        const url = `${process.env.REACT_APP_API_BASE_URL}/creators/${creatorId}/payment/subscription/${subscriptionId}/upcoming-invoice`;
        return getRequest(url);
    };

    const upsertSectionSchema = async (creatorId, workbookId, moduleId, sectionId, path, value) => {
        const url = `${baseUrl}${creatorId}/workbooks/${workbookId}/modules/${moduleId}/sections/${sectionId}/schema_property`;
        const payload = updateSectionSchemaDto(path, value);
        return putRequest(url, payload);
    };

    const moveSection = async (creatorId, workbookId, moduleId, fromIndex, toIndex) => {
        const url = `${baseUrl}${creatorId}/workbooks/${workbookId}/modules/${moduleId}`;
        const queryParams = { action: 'move', from: fromIndex, to: toIndex };
        return patchRequest(url, null, queryParams);
    };

    const deleteSection = async (creatorId, workbookId, moduleId, sectionId) => {
        const url = `${baseUrl}${creatorId}/workbooks/${workbookId}/modules/${moduleId}/sections/${sectionId}`;
        return deleteRequest(url);
    };

    const getSignedUrl = async (objectKey, contentType) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/storage/signed-url`;
        const queryParams = { object_key: objectKey, content_type: contentType };
        return getRequest(url, queryParams);
    };

    const saveStorageObject = async (objectKey, fileData) => {
        const getSignedUrlPromise = getSignedUrl(objectKey, fileData.type);
        const blobPromise = fetch(fileData.url).then((res) => Promise.resolve(res.blob()));

        return Promise.all([getSignedUrlPromise, blobPromise])
            .then((values) => {
                const { url: signedUrl, headers } = values[0];
                const blob = values[1];
                return putSimpleRequest(signedUrl, blob, null, headers).catch(() => {
                    throw new Error('could not upload file');
                });
            })
            .catch(() => {
                throw new Error('could not upload file');
            });
    };

    const deleteStorageObject = async (objectKey) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/storage/object`;
        const queryParams = { object_key: objectKey };
        return deleteRequest(url, queryParams);
    };

    const copyStorageObject = async (pathFrom, pathTo) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/storage/object`;
        const queryParams = { path_from: pathFrom, path_to: pathTo };
        return postRequest(url, null, queryParams);
    };

    const getActiveCohorts = async (creatorId) => {
        const url = `${baseUrl}${creatorId}/active_cohorts`;
        // We use date without time in tandem with dates in NewCohortModal to sync the searches
        const queryParams = { reference_date: formatISO(dateWithoutTime(new Date())) };
        return getRequest(url, queryParams);
    };

    const createWorkbookVersion = async (creatorId, workbookId) => {
        const url = `${baseUrl}${creatorId}/workbooks/${workbookId}/versions`;
        return postRequest(url);
    };

    const createCohort = async (creatorId, workbookId, name, startDate, endDate) => {
        const url = `${baseUrl}${creatorId}/workbooks/${workbookId}/cohorts`;
        const payload = newCohortDto(name, startDate, endDate);
        return postRequest(url, payload);
    };

    const createEvergreenCohort = async (creatorId, workbookId, name, startDate) => {
        const url = `${baseUrl}${creatorId}/workbooks/${workbookId}/cohorts`;
        const payload = newEvergreenCohortDto(name, startDate);
        return postRequest(url, payload);
    };

    const updateCohort = async (creatorId, workbookId, cohortId, name, startDate, endDate) => {
        const url = `${baseUrl}${creatorId}/workbooks/${workbookId}/cohorts/${cohortId}`;
        const payload = updateCohortDto(name, startDate, endDate);
        return patchRequest(url, payload);
    };

    const enrollStudent = async (creatorId, workbookId, cohortId, firsName, lastName, email) => {
        const url = `${baseUrl}${creatorId}/workbooks/${workbookId}/cohorts/${cohortId}/students`;
        const payload = studentEnrollmentDto(firsName, lastName, email);
        return postRequest(url, payload);
    };

    const disenrollStudent = async (creatorId, workbookId, cohortId, studentId) => {
        const url = `${baseUrl}${creatorId}/workbooks/${workbookId}/cohorts/${cohortId}/students/${studentId}`;
        return deleteRequest(url);
    };

    const getCreatorWorkbookVersion = async (creatorId, workbookId, workbookVersionId) => {
        const url = `${baseUrl}${creatorId}/workbooks/${workbookId}/versions/${workbookVersionId}`;
        return getRequest(url);
    };

    const getCreatorWorkbookVersionModules = async (creatorId, workbookId, workbookVersionId) => {
        const url = `${baseUrl}${creatorId}/workbooks/${workbookId}/versions/${workbookVersionId}/modules`;
        return getRequest(url);
    };

    const getCreatorWorkbookVersionModuleResponses = async (creatorId, workbookId, cohortId, studentId) => {
        const url = `${baseUrl}${creatorId}/workbooks/${workbookId}/cohorts/${cohortId}/students/${studentId}/module-responses`;
        return getRequest(url);
    };

    const updateWorkbookThumbnail = async (creatorId, workbookId) => {
        const url = `${baseUrl}${creatorId}/workbooks/${workbookId}/thumbnail`;
        return putRequest(url);
    };

    const finalizeWorkbook = async (creatorId, workbookId) => {
        const url = `${baseUrl}${creatorId}/workbooks/${workbookId}/finalize`;
        return postRequest(url);
    };

    const createCreatorWorkbookArtifactSignedUrl = async (creatorId, workbookId, optionalInfo) => {
        const url = `${baseUrl}${creatorId}/workbooks/${workbookId}/artifact`;
        const payload = {};
        if (optionalInfo) {
            if (optionalInfo.pageFormat) {
                payload.page_format = optionalInfo.pageFormat;
            }
            if (optionalInfo.headerHtml) {
                payload.header_html = optionalInfo.headerHtml;
            }
            if (optionalInfo.footerHtml) {
                payload.footer_html = optionalInfo.footerHtml;
            }
        }
        return postRequest(url, payload);
    };

    const createCreatorWorkbookModuleArtifactSignedUrl = async (creatorId, workbookId, moduleId, optionalInfo) => {
        const url = `${baseUrl}${creatorId}/workbooks/${workbookId}/modules/${moduleId}/artifact`;
        const payload = {};
        if (optionalInfo) {
            if (optionalInfo.pageFormat) {
                payload.page_format = optionalInfo.pageFormat;
            }
            if (optionalInfo.headerHtml) {
                payload.header_html = optionalInfo.headerHtml;
            }
            if (optionalInfo.footerHtml) {
                payload.footer_html = optionalInfo.footerHtml;
            }
        }
        return postRequest(url, payload);
    };

    const getMonthlyActiveStudents = async (creatorId, periodEnd, dataQueryOptions) => {
        throwErrorIfRoleIsMember();
        const url = `${process.env.REACT_APP_API_BASE_URL}/creators/${creatorId}/payment/monthly-active-students`;
        const queryParams = { period_end: encodeURIComponent(periodEnd), ...dataQueryOptions };
        return getRequest(url, queryParams, true);
    };

    const getMonthlyActiveStudentWorkbooks = async (creatorId, studentId, periodEnd) => {
        throwErrorIfRoleIsMember();
        const url = `${process.env.REACT_APP_API_BASE_URL}/creators/${creatorId}/payment/monthly-active-students/${studentId}/workbooks`;
        const queryParams = { period_end: periodEnd };
        return getRequest(url, queryParams);
    };

    const getCreatorMembers = async (creatorId) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/creators/${creatorId}/members`;
        return getRequest(url);
    };

    const addMember = async (creatorId, newMember) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/creators/${creatorId}/members`;
        const payload = {
            email: newMember.email,
            first_name: newMember.firstName,
            last_name: newMember.lastName,
            subrole: parseInt(newMember.subrole, 10),
        };
        return postRequest(url, payload);
    };

    const deleteMember = async (creatorId, memberId) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/creators/${creatorId}/members/${memberId}`;
        return deleteRequest(url);
    };

    const addComment = async (sectionId, studentId, comment) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/comments`;
        const payload = {
            section_id: sectionId,
            student_id: studentId,
            comment,
        };
        return postRequest(url, payload);
    };

    const getCommentsByWorkbookVersionId = async (workbookVersionId, studentId) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/comments?student_id=${studentId}&workbook_version_id=${workbookVersionId}`;
        return getRequest(url);
    };

    const getCommentsByWorkbookId = async (workbookId, studentId) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/comments/student_id=${studentId}&workbook_id=${workbookId}`;
        return getRequest(url);
    };

    const deleteComment = async (commentId) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/comments`;
        return deleteRequest(url, null, { id: commentId });
    };

    const updateComment = async (commentId, comment) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/comments`;
        const payload = {
            id: commentId,
            comment,
        };
        return patchRequest(url, payload);
    };

    const sendCommentNotificationTestEmail = async (workbookId) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/comments/test`;
        const payload = {
            workbook_id: workbookId,
        };
        return postRequest(url, payload);
    };

    const shareWorkbookPublicly = async (workbookId) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/share/workbook`;
        const payload = {
            workbook_id: workbookId,
        };
        return postRequest(url, payload);
    };

    const getPubliclySharedWorkbook = async (workbookId) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/share/check?id=${workbookId}`;
        return getRequest(url);
    };

    const deletePubliclySharedWorkbook = async (shareLinkId) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/share/workbook`;
        const payload = {
            share_id: shareLinkId,
        };
        return deleteRequest(url, null, payload);
    };

    const enrollStudentInPubliclySharedWorkbook = async (shareLinkId) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/share/${shareLinkId}/enroll`;
        return postRequest(url);
    };

    return {
        getCreatorWorkbook,
        getCreatorWorkbooks,
        getCreatorCohort,
        getCreatorCohorts,
        getCreatorWorkbookModules,
        getCreatorWorkbookVersion,
        getCreatorWorkbookVersionModules,
        getCreatorWorkbookVersionModuleResponses,
        createCreatorWorkbookArtifactSignedUrl,
        createCreatorWorkbookModuleArtifactSignedUrl,
        getCohortStudents,
        createWorkbook,
        duplicateWorkbook,
        deleteWorkbook,
        publishWorkbook,
        createWorkbookVersion,
        updateWorkbookName,
        updateWorkbookCommenting,
        upsertWorkbookSchema,
        updateWorkbookThumbnail,
        updateWorkbookDraft,
        createModule,
        deleteModule,
        appendSection,
        appendSectionsFromTemplate,
        duplicateModule,
        postCheckoutSession,
        processCheckout,
        getBillingPortal,
        cancelSubscription,
        updateSubscription,
        updateSubscriptionChangePlan,
        updateSubscriptionWithAddOn,
        removeSubscriptionAddOn,
        updateStudentImageUploadStorageAddOnUsage,
        getSubscriptionUpcomingInvoice,
        updateModuleTitle,
        moveModule,
        upsertSectionSchema,
        duplicateSection,
        moveSection,
        deleteSection,
        saveStorageObject,
        deleteStorageObject,
        copyStorageObject,
        getActiveCohorts,
        createCohort,
        createEvergreenCohort,
        updateCohort,
        enrollStudent,
        disenrollStudent,
        getMonthlyActiveStudents,
        getMonthlyActiveStudentWorkbooks,
        getCreatorMembers,
        addMember,
        deleteMember,
        addComment,
        getCommentsByWorkbookVersionId,
        getCommentsByWorkbookId,
        deleteComment,
        updateComment,
        sendCommentNotificationTestEmail,
        shareWorkbookPublicly,
        deletePubliclySharedWorkbook,
        getPubliclySharedWorkbook,
        enrollStudentInPubliclySharedWorkbook,
        finalizeWorkbook,
    };
}
