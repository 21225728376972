import { dateWithoutTime } from '@/util/helpers';
import { PLANS } from '@/util/constants';

export const isPaidPlan = (plan) => {
    if (!plan) return false;
    return plan !== PLANS.NO_PAID && plan !== PLANS.TRIAL;
};

export const creatorIsInTrial = (creator) => {
    if (!creator || !creator.plan) return false;
    const today = dateWithoutTime(new Date());
    return creator.plan === PLANS.TRIAL && today < creator.trialEndDate;
};
