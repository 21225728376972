export default function OrderedListIcon({ className }) {
    return (
        <svg
            width="11"
            height="15"
            viewBox="0 0 11 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}>
            <path
                d="M1.69995 1.9957V5.31934H1.09786V2.58156H1.07839L0.301033 3.07815V2.52638L1.12707 1.9957H1.69995Z"
                fill="currentColor"
            />
            <path
                d="M0.348115 12.7305V12.2955L1.50204 11.1643C1.61241 11.0529 1.70437 10.9539 1.77795 10.8673C1.85152 10.7807 1.9067 10.6969 1.94349 10.6157C1.98028 10.5346 1.99867 10.448 1.99867 10.3561C1.99867 10.2511 1.97487 10.1613 1.92726 10.0867C1.87966 10.0109 1.8142 9.95249 1.73088 9.91137C1.64757 9.87026 1.5529 9.8497 1.44686 9.8497C1.33758 9.8497 1.24183 9.87242 1.1596 9.91787C1.07737 9.96223 1.01353 10.0255 0.968088 10.1078C0.923727 10.19 0.901547 10.2879 0.901547 10.4015H0.328639C0.328639 10.1905 0.376787 10.0071 0.473083 9.85132C0.569379 9.69552 0.701921 9.57488 0.87071 9.4894C1.04058 9.40393 1.23534 9.36119 1.45498 9.36119C1.67787 9.36119 1.8737 9.40284 2.04249 9.48616C2.21128 9.56947 2.3422 9.68362 2.43525 9.8286C2.52938 9.97359 2.57645 10.1391 2.57645 10.3252C2.57645 10.4497 2.55265 10.5719 2.50504 10.692C2.45743 10.8121 2.37358 10.9452 2.25348 11.0913C2.13446 11.2373 1.9673 11.4142 1.75198 11.622L1.17907 12.2046V12.2273H2.62676V12.7305H0.348115Z"
                fill="currentColor"
            />
            <path d="M4.4873 3.39648H10.8458" stroke="currentColor" strokeWidth="0.687081" />
            <path d="M4.4873 7.46094H10.8458" stroke="currentColor" strokeWidth="0.687081" />
            <path d="M4.4873 11.6982H10.8458" stroke="currentColor" strokeWidth="0.687081" />
        </svg>
    );
}
