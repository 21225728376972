/* eslint-disable import/prefer-default-export */
import { customAlphabet } from 'nanoid';
import { alphanumeric } from 'nanoid-dictionary';
import { generatePath } from 'react-router-dom';
import { format } from 'date-fns';
import sanitize from 'sanitize-filename';
import { TYPES } from '@/embed/EmbedProvider';
import {
    COHORT_ID_QUERY_PARAM,
    COHORT_STATUS,
    CREATOR_ID_QUERY_PARAM,
    CREATOR_ROUTE,
    CREATOR_ROUTE_BASE_PREFIX,
    EMBED_QUERY_PARAMS,
    IMAGE_SIZES,
    MEMBER_ROUTE,
    MEMBER_ROUTE_BASE_PREFIX,
    PLANS,
    PUBLICLY_SHARED_WORKBOOK_ID_QUERY_PARAM,
    ROLES,
    ROUTES,
    STUDENT_ID_QUERY_PARAM,
    STUDENT_ROUTE,
    STUDENT_ROUTE_BASE_PREFIX,
    WORKBOOK_ID_QUERY_PARAM,
    WORKBOOK_SECTION_VIEWER_MODES,
    WORKBOOK_VERSION_ID_QUERY_PARAM,
} from '@/util/constants';
import { APP_STYLES } from '@/system/styles';

/* Routes */

export const isCreatorRoute = (path) => path.startsWith(CREATOR_ROUTE);
export const isStudentRoute = (path) => path.startsWith(STUDENT_ROUTE);
export const isMemberRoute = (path) => path.startsWith(MEMBER_ROUTE);
export const isCreatorPreviewRoute = (path) => path.startsWith(`${CREATOR_ROUTE_BASE_PREFIX}preview`);
export const isMemberPreviewRoute = (path) => path.startsWith(`${MEMBER_ROUTE_BASE_PREFIX}preview`);
export const isStudentWorkbookRoute = (path) => path.startsWith(`${STUDENT_ROUTE_BASE_PREFIX}workbook-version`);
export const getWorkbookBuilderRoute = (workbookId, currentRole) =>
    generatePath(currentRole === ROLES.MEMBER ? ROUTES.MEMBER.BUILDER : ROUTES.CREATOR.BUILDER, {
        [WORKBOOK_ID_QUERY_PARAM]: workbookId,
    });
export const getCohortRoute = (cohortId, workbookId, currentRole) =>
    generatePath(currentRole === ROLES.MEMBER ? ROUTES.MEMBER.COHORTS_DASHBOARD : ROUTES.CREATOR.COHORTS_DASHBOARD, {
        [COHORT_ID_QUERY_PARAM]: cohortId,
        [WORKBOOK_ID_QUERY_PARAM]: workbookId,
    });
export const getStudentResponsesRoute = (cohortId, workbookId, workbookVersionId, studentId, currentRole) =>
    generatePath(currentRole === ROLES.MEMBER ? ROUTES.MEMBER.STUDENT_RESPONSES : ROUTES.CREATOR.STUDENT_RESPONSES, {
        [COHORT_ID_QUERY_PARAM]: cohortId,
        [WORKBOOK_ID_QUERY_PARAM]: workbookId,
        [WORKBOOK_VERSION_ID_QUERY_PARAM]: workbookVersionId,
        [STUDENT_ID_QUERY_PARAM]: studentId,
    });
export const getWorkbookPreviewRoute = (workbookId, currentRole) =>
    generatePath(currentRole === ROLES.MEMBER ? ROUTES.MEMBER.PREVIEW : ROUTES.CREATOR.PREVIEW, {
        [WORKBOOK_ID_QUERY_PARAM]: workbookId,
    });
export const getWorkbookPreviewContentRoute = (workbookId, currentRole) =>
    generatePath(currentRole === ROLES.MEMBER ? ROUTES.MEMBER.PREVIEW_CONTENT : ROUTES.CREATOR.PREVIEW_CONTENT, {
        [WORKBOOK_ID_QUERY_PARAM]: workbookId,
    });
export const getStudentWorkbookRoute = (workbookVersionId) =>
    generatePath(ROUTES.STUDENT.WORKBOOK, { [WORKBOOK_VERSION_ID_QUERY_PARAM]: workbookVersionId });

export const getStudentEnrollInPubliclySharedWorkbook = (publiclySharedWorkbookId) =>
    generatePath(ROUTES.STUDENT.PUBLIC_ENROLL, { [PUBLICLY_SHARED_WORKBOOK_ID_QUERY_PARAM]: publiclySharedWorkbookId });

export const getPublicWorkbookRoute = (creatorId, workbookId, workbookVersionId) =>
    process.env.REACT_APP_BASE_URL +
    generatePath(ROUTES.COMMON.PUBLIC_WORKBOOK, {
        [CREATOR_ID_QUERY_PARAM]: creatorId,
        [WORKBOOK_ID_QUERY_PARAM]: workbookId,
        [WORKBOOK_VERSION_ID_QUERY_PARAM]: workbookVersionId,
    });

/* General */

export const getUniqueId = (length) => {
    const idGenerator = customAlphabet(alphanumeric, !length || !Number.isFinite(length) ? 10 : length);
    return idGenerator();
};

export const copyToClipboard = (inputRef) => {
    if (!inputRef?.current) {
        console.error('Could not copy to clipboard');
        return;
    }

    if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard.writeText(inputRef.current.value);
    } else {
        // navigator.clipboard is only available on localhost and https origin domains,
        // we need this fallback code to copy values to the clipboard when we're not
        // in either of those situations (i.e. wobolocal.com)
        inputRef.current.focus();
        inputRef.current.select();
        inputRef.current.setSelectionRange(0, 99999);
        document.execCommand('copy');
    }
};

/* Dates */

export const dateWithoutTime = (dateTime) => {
    const date = new Date(dateTime.getTime());
    date.setHours(0, 0, 0, 0);
    return date;
};

export const getTrialDays = (expiryDate) => {
    // eslint-disable-next-line no-restricted-globals
    if (!(expiryDate instanceof Date) || isNaN(expiryDate)) return 0;
    const today = new Date();
    const msInDay = 86400000;
    const timeDiff = expiryDate.getTime() - today.getTime();
    if (timeDiff < 0) return 0;
    return Math.ceil(timeDiff / msInDay);
};

export const getBillingPeriodLabel = (startDate, endDate) => {
    if (!startDate || !endDate) return '';
    const start = format(startDate, 'LLL d');
    const end = format(endDate, 'LLL d');
    return `${start} to ${end}`;
};

export const getBillingPeriodLabelWithYear = (startDate, endDate) => {
    if (!startDate || !endDate) return '';

    let start;
    let end;
    if (startDate.getFullYear() === endDate.getFullYear()) {
        start = format(startDate, 'LLL d');
        end = format(endDate, 'LLL d, yyyy');
    } else {
        start = format(startDate, 'LLL d, yyyy');
        end = format(endDate, 'LLL d, yyyy');
    }

    return `${start} - ${end}`;
};

/* Objects */

export const deepCopy = (obj) => {
    return JSON.parse(JSON.stringify(obj));
};

export const fetchJsonFile = async (filename) => {
    return fetch(filename).then((response) => response.json());
};

/* Strings */

export const uppercaseFirstLetter = (str) => {
    if (!str || typeof str !== 'string') return '';
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const replaceAt = (str, index, replacement) => {
    if (!str || typeof str !== 'string') return '';
    const parsedIndex = typeof index === 'string' ? parseInt(index, 10) : index;
    if (!Number.isFinite(parsedIndex)) return '';
    return str.substring(0, parsedIndex) + replacement + str.substring(parsedIndex + replacement.length);
};

export const removeSpaces = (str) => {
    if (!str || typeof str !== 'string') return '';
    return str.replace(/\s/g, '');
};

export const sanitizeFilenameUrl = (filename) => {
    return sanitize(removeSpaces(filename), {
        replacement: '',
    });
};

/* URL */

export const ensureHttpOrHttpsUrl = (url) => {
    let finalUrl = url;
    if (!url.startsWith('http')) finalUrl = `https://${url}`;
    return finalUrl;
};

export const removeQueryParam = (search, queryParam) => {
    if (!search || typeof search !== 'string') return '';
    const queryParamArray = [];
    const searchParams = new URLSearchParams(search);
    searchParams.forEach((value, key) => {
        if (key !== queryParam) {
            queryParamArray.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
        }
    });
    return queryParamArray.length > 0 ? `?${queryParamArray.join('&')}` : '';
};

export const appendQueryParams = (url, queryParamsObject, omitEncoding = false) => {
    if (!queryParamsObject) return url;
    const queryParamArray = [];
    Object.keys(queryParamsObject).forEach((query) => {
        if (omitEncoding) {
            queryParamArray.push(`${query}=${queryParamsObject[query]}`);
        } else {
            queryParamArray.push(`${encodeURIComponent(query)}=${encodeURIComponent(queryParamsObject[query])}`);
        }
    });
    return queryParamArray.length > 0 ? `${url.includes('?') ? `${url}&` : `${url}?`}${queryParamArray.join('&')}` : '';
};

/* Tailwind */

const DEFAULT_ANIMATION = {
    TRANSITION: 'duration-100 ease-out',
    ENTER_FROM: 'scale-95 opacity-0',
    ENTER_TO: 'scale-100 opacity-100',
    LEAVE_FROM: 'scale-100 opacity-100',
    LEAVE_TO: 'scale-95 opacity-0',
};

export const getAnimationProps = (
    enter = {
        transition: DEFAULT_ANIMATION.TRANSITION,
        from: DEFAULT_ANIMATION.ENTER_FROM,
        to: DEFAULT_ANIMATION.ENTER_TO,
    },
    leave = {
        transition: DEFAULT_ANIMATION.TRANSITION,
        from: DEFAULT_ANIMATION.LEAVE_FROM,
        to: DEFAULT_ANIMATION.LEAVE_TO,
    },
) => {
    if (
        typeof enter !== 'object' ||
        enter === null ||
        Object.keys(enter).length === 0 ||
        typeof leave !== 'object' ||
        leave === null ||
        Object.keys(leave).length === 0
    )
        return {
            enter: `transition-all ${DEFAULT_ANIMATION.TRANSITION}`,
            enterFrom: `transform ${DEFAULT_ANIMATION.ENTER_FROM}`,
            enterTo: `transform ${DEFAULT_ANIMATION.ENTER_TO}`,
            leave: `transition-all ${DEFAULT_ANIMATION.TRANSITION}`,
            leaveFrom: `transform ${DEFAULT_ANIMATION.LEAVE_FROM}`,
            leaveTo: `transform ${DEFAULT_ANIMATION.LEAVE_TO}`,
        };

    return {
        enter: `transition-all ${enter.transition}`,
        enterFrom: `transform ${enter.from}`,
        enterTo: `transform ${enter.to}`,
        leave: `transition-all ${leave.transition}`,
        leaveFrom: `transform ${leave.from}`,
        leaveTo: `transform ${leave.to}`,
    };
};

/* React-color picker */

// Convert a react-color rgba object into rgb string
export const rgbaObjToStr = (obj) => {
    if (
        !obj ||
        typeof obj !== 'object' ||
        !Object.prototype.hasOwnProperty.call(obj, 'rgb') ||
        !Object.prototype.hasOwnProperty.call(obj.rgb, 'r') ||
        !Object.prototype.hasOwnProperty.call(obj.rgb, 'g') ||
        !Object.prototype.hasOwnProperty.call(obj.rgb, 'b') ||
        !Object.prototype.hasOwnProperty.call(obj.rgb, 'a')
    ) {
        return APP_STYLES.COLORS.STANDARD.BLACK;
    }

    return `rgba(${obj.rgb.r},${obj.rgb.g},${obj.rgb.b},${obj.rgb.a})`;
};

// Convert a string into a react-color rgba object
export const rgbaStrToObj = (str) => {
    const defaultRgb = {
        r: 0,
        g: 0,
        b: 0,
        a: 1,
    };

    if (!str) {
        return defaultRgb;
    }

    const rgbMatch = str.match(/^rgba\((.*?)\)$/);

    if (!rgbMatch) {
        return defaultRgb;
    }

    const rgba = rgbMatch[1].split(',');

    return {
        r: rgba[0] ? parseInt(rgba[0], 10) : 0,
        g: rgba[1] ? parseInt(rgba[1], 10) : 0,
        b: rgba[2] ? parseInt(rgba[2], 10) : 0,
        a: rgba[3] ? parseFloat(rgba[3], 10) : 1,
    };
};

/* S3 */

export const getStorageUrl = (objectKey) => {
    if (!objectKey) return '';
    if (objectKey.startsWith('blob:http') || objectKey.startsWith('data:image')) return objectKey;
    return `https://${process.env.REACT_APP_S3_BUCKET_NAME}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${objectKey}`;
};

export const appendUniqueIdToStr = (str) => {
    if (!str || typeof str !== 'string') return '';
    return `${str}_${getUniqueId()}`;
};

export const getStorageObjectBasePath = (creatorId, workbookId, workbookVersionId) => {
    return `${creatorId}/workbooks/${workbookId}/${workbookVersionId || 'current'}/`;
};

export const getWorkbookUploadsStorageObjectBasePath = (creatorId, workbookId) => {
    return `${creatorId}/workbook_uploads/${workbookId}/`;
};

export const getStudentSectionStorageObjectBasePath = (basePath, sectionId, studentId) => {
    return `${basePath}${sectionId}/${studentId}/`;
};

export const getCreatorStorageObjectBasePath = (creatorId) => `${creatorId}/`;

/* ImageUploader */

export const getSizeDescription = (sizeInBytes) => {
    if (sizeInBytes < 1024) {
        return `${sizeInBytes} ${sizeInBytes === 1 ? 'byte' : 'bytes'}`;
    }
    if (sizeInBytes >= 1024 && sizeInBytes < 1048576) {
        return `${(sizeInBytes / 1024).toFixed(1)} KB`;
    }
    if (sizeInBytes >= 1048576 && sizeInBytes < 1073741824) {
        return `${(sizeInBytes / 1048576).toFixed(1)} MB`;
    }
    return `${(sizeInBytes / 1073741824).toFixed(1)} GB`;
};

/* Thinkific SSO */

export const checkDomainUrlAvailability = async (url) => {
    try {
        await fetch(url, { method: 'GET', mode: 'no-cors' });
        return true;
    } catch (error) {
        return false;
    }
};

/* Teachable SSO
This code could be merged with the above thnc implementation
*/

export const checkTeachableUrl = async (url) => {
    try {
        await fetch(url, { method: 'GET', mode: 'no-cors' });
        return true;
    } catch (error) {
        return false;
    }
};

export const getThinkificCreatorSignupSsoParams = (
    fromUrl,
    email = null,
    referralId = null,
    referredFromThinkific = false,
    callbackRoute = ROUTES.COMMON.THINKIFIC_AUTH_COMPLETE,
) => {
    const state = {
        callback_route: callbackRoute,
        redirect_query_params: {
            from_url: fromUrl || ROUTES.CREATOR.WORKBOOKS_DASHBOARD,
            role: ROLES.CREATOR,
        },
        referred_from_thinkific: typeof referredFromThinkific === 'boolean' ? referredFromThinkific : false,
    };
    if (email) state.current_email = email;
    if (referralId) state.referral_id = referralId;
    return {
        response_type: 'code id_token token',
        scope: 'openid profile email site write:site_scripts',
        client_id: process.env.REACT_APP_THINKIFIC_CLIENT_ID,
        redirect_uri: `${process.env.REACT_APP_API_BASE_URL}/thinkific/auth-callback`,
        state: JSON.stringify(state),
    };
};

export const getTeachableCreatorSignupSsoParams = (teachableSsoParams) => {
    const {
        fromUrl,
        email = null,
        referralId = null,
        referredFromTeachable = false,
        callbackRoute = ROUTES.COMMON.TEACHABLE_AUTH_COMPLETE,
        subdomain,
        schoolID,
        clientID,
        clientSecret,
    } = teachableSsoParams;

    const state = {
        callback_route: callbackRoute,
        redirect_query_params: {
            from_url: fromUrl || ROUTES.CREATOR.WORKBOOKS_DASHBOARD,
            role: ROLES.CREATOR,
        },
        subdomain,
        school_id: schoolID,
        client_id: clientID,
        client_secret: clientSecret,
        referred_from_teachable: typeof referredFromTeachable === 'boolean' ? referredFromTeachable : false,
    };

    if (email) state.current_email = email;
    if (referralId) state.referral_id = referralId;
    return {
        client_id: clientID,
        response_type: 'code',
        required_scopes: 'name:read email:read',
        optional_scopes: 'courses:read',
        redirect_uri: `${process.env.REACT_APP_API_BASE_URL}/teachable/auth-callback`,
        state: JSON.stringify(state),
    };
};

export const getThinkificStudentEmbedSsoParams = (fromUrl, workbookId, embedContext, v2) => {
    const state = {
        workbook_id: workbookId,
        callback_route: ROUTES.COMMON.THINKIFIC_AUTH_COMPLETE,
        redirect_query_params: {
            from_url: fromUrl,
            [EMBED_QUERY_PARAMS.EMBED]: 'true',
            [EMBED_QUERY_PARAMS.TYPE]: TYPES.THINKIFIC,
            role: ROLES.STUDENT,
        },
    };
    if (embedContext) state.redirect_query_params[EMBED_QUERY_PARAMS.CONTEXT] = embedContext;
    if (v2) state.redirect_query_params[EMBED_QUERY_PARAMS.THINKIFIC_V2] = 'true';
    return {
        response_type: 'code id_token token',
        scope: 'openid profile email site',
        client_id: process.env.REACT_APP_THINKIFIC_CLIENT_ID,
        redirect_uri: `${process.env.REACT_APP_API_BASE_URL}/thinkific/embed-auth-callback`,
        state: JSON.stringify(state),
    };
};

export const getTeachableStudentEmbedSsoParams = (fromUrl, workbookId, embedContext, v2, creator) => {
    const state = {
        workbook_id: workbookId,
        callback_route: ROUTES.COMMON.TEACHABLE_AUTH_COMPLETE,
        redirect_query_params: {
            from_url: fromUrl,
            [EMBED_QUERY_PARAMS.EMBED]: 'true',
            [EMBED_QUERY_PARAMS.TYPE]: TYPES.TEACHABLE,
            role: ROLES.STUDENT,
        },
        subdomain: creator.teachableClientId,
        school_id: creator.teachableSchoolId,
        client_id: creator.teachableClientId,
        client_secret: creator.teachableClientSecret,
        referred_from_teachable: false,
    };
    if (embedContext) state.redirect_query_params[EMBED_QUERY_PARAMS.CONTEXT] = embedContext;
    if (v2) state.redirect_query_params[EMBED_QUERY_PARAMS.TEACHABLE_V2] = 'true';
    return {
        response_type: 'code',
        required_scopes: 'name:read email:read',
        optional_scopes: 'courses:read',
        client_id: creator.teachableClientId,
        redirect_uri: `${process.env.REACT_APP_API_BASE_URL}/teachable/auth-callback`,
        state: JSON.stringify(state),
    };
};

export const getThinkificLoginSsoParams = (
    fromUrl,
    role,
    callbackRoute = ROUTES.COMMON.THINKIFIC_AUTH_COMPLETE,
    loggedInUserEmail = null,
) => {
    const state = {
        role: parseInt(role, 10),
        callback_route: callbackRoute,
        redirect_query_params: {
            from_url: fromUrl,
            role,
        },
    };
    if (loggedInUserEmail) {
        state.logged_in_user_email = loggedInUserEmail;
    }
    return {
        response_type: 'code id_token token',
        scope: 'openid profile email site',
        client_id: process.env.REACT_APP_THINKIFIC_CLIENT_ID,
        redirect_uri: `${process.env.REACT_APP_API_BASE_URL}/thinkific/login-callback`,
        state: JSON.stringify(state),
    };
};

export const getTeachableLoginUrl = async (domain, redirectUrl, embed, workbookId) => {
    try {
        let url = `${process.env.REACT_APP_API_BASE_URL}/teachable/sign-in?domain=${domain}`;
        if (redirectUrl) url += `&redirect_url=${redirectUrl}`;
        if (embed) url += `&embed=${embed}`;
        if (workbookId) url += `&workbook_id=${workbookId}`;
        const response = await fetch(url);
        const data = await response.json();
        return data.loginUrl;
    } catch (error) {
        return null;
    }
};

/* Google SSO */

export const getGoogleCreatorSignupSsoParams = (
    fromUrl,
    referralId = null,
    callbackRoute = ROUTES.COMMON.GOOGLE_AUTH_COMPLETE,
) => {
    const state = {
        callback_route: callbackRoute,
        redirect_query_params: {
            from_url: fromUrl || ROUTES.CREATOR.WORKBOOKS_DASHBOARD,
            role: ROLES.CREATOR,
        },
    };
    if (referralId) state.referral_id = referralId;
    return {
        response_type: 'code',
        scope: 'openid profile email',
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        redirect_uri: `${process.env.REACT_APP_API_BASE_URL}/google/signup-callback`,
        state: JSON.stringify(state),
    };
};

export const getGoogleStudentEmbedSsoParams = (fromUrl, workbookId, embedContext) => {
    const state = {
        workbook_id: workbookId,
        callback_route: ROUTES.COMMON.GOOGLE_AUTH_COMPLETE,
        redirect_query_params: {
            from_url: fromUrl,
            [EMBED_QUERY_PARAMS.EMBED]: 'true',
            role: ROLES.STUDENT,
        },
    };
    if (embedContext) state.redirect_query_params[EMBED_QUERY_PARAMS.CONTEXT] = embedContext;
    return {
        response_type: 'code',
        scope: 'openid profile email',
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        redirect_uri: `${process.env.REACT_APP_API_BASE_URL}/google/embed-auth-callback`,
        state: JSON.stringify(state),
    };
};

export const getGoogleLoginSsoParams = (fromUrl, role, callbackRoute = ROUTES.COMMON.GOOGLE_AUTH_COMPLETE) => {
    const state = {
        role: parseInt(role, 10),
        callback_route: callbackRoute,
        redirect_query_params: {
            from_url: fromUrl,
            role,
        },
    };
    return {
        response_type: 'code',
        scope: 'openid profile email',
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        redirect_uri: `${process.env.REACT_APP_API_BASE_URL}/google/login-callback`,
        state: JSON.stringify(state),
    };
};

/* Custom SSO */

export const getCustomStudentEmbedSsoParams = (fromUrl, ssoConfig, creatorId, workbookId, embedContext) => {
    const state = {
        creator_id: creatorId,
        workbook_id: workbookId,
        callback_route: ROUTES.COMMON.CUSTOM_SSO_AUTH_COMPLETE,
        redirect_query_params: {
            from_url: fromUrl,
            [EMBED_QUERY_PARAMS.EMBED]: 'true',
            role: ROLES.STUDENT,
        },
    };
    if (embedContext) state.redirect_query_params[EMBED_QUERY_PARAMS.CONTEXT] = embedContext;
    return {
        response_type: 'code',
        scope: ssoConfig.scope,
        client_id: ssoConfig.clientId,
        redirect_uri: `${process.env.REACT_APP_API_BASE_URL}/custom-sso/embed-auth-callback`,
        state: JSON.stringify(state),
    };
};

/* SSO Errors */

export const getSsoErrorState = (error, role) => {
    if (
        error === 'error with sso login: NotAuthorizedException: Incorrect username or password.' &&
        role === ROLES.CREATOR
    ) {
        return {
            buttonLabel: 'Go to signup',
            navigateUrl: ROUTES.CREATOR.SIGNUP,
        };
    }
    return {
        buttonLabel: 'Go to login',
        navigateUrl: role === ROLES.CREATOR || role === ROLES.MEMBER ? ROUTES.CREATOR.LOGIN : ROUTES.STUDENT.LOGIN,
    };
};

/* Cohorts */

export const getCohortStatus = (cohort) => {
    const todayMillisecs = dateWithoutTime(new Date()).getTime();
    if (todayMillisecs < cohort.startDate.getTime()) return COHORT_STATUS.UPCOMING;
    if (todayMillisecs <= cohort.endDate.getTime()) return COHORT_STATUS.ACTIVE;
    return COHORT_STATUS.COMPLETED;
};

/* Workbook reducers */

export const getIdsDictionary = (arr) => {
    const dictionary = {};
    arr.forEach((el, i) => {
        dictionary[el.id] = i;
    });
    return dictionary;
};

export const getModuleIdsDictionary = (moduleResponses) => {
    const dictionary = {};
    moduleResponses.forEach((el, i) => {
        dictionary[el.moduleId] = i;
    });
    return dictionary;
};

export const getFakeModuleResponse = (moduleId) => {
    return {
        id: getUniqueId(),
        workbookId: '',
        moduleId,
        cohortId: '',
        sectionResponses: {},
        opened: false,
    };
};

/* Workbook */

export const getStudentWorkbookProgress = (moduleVersionsProgress) => {
    const moduleVersionsKeys = Object.keys(moduleVersionsProgress);
    const totalModules = moduleVersionsKeys.length;
    let openedModules = 0;

    if (totalModules === 0) return 0;
    moduleVersionsKeys.forEach((versionId) => {
        if (moduleVersionsProgress[versionId].opened) openedModules += 1;
    });

    return openedModules / totalModules;
};

export const getImageWidthBySize = (size) => {
    switch (size) {
        case IMAGE_SIZES.SMALL:
            return '40%';
        case IMAGE_SIZES.MEDIUM:
            return '60%';
        case IMAGE_SIZES.LARGE:
            return '80%';
        default:
            return '100%';
    }
};

/* Cover page sections */

export const getRelativeFontSize = (fontSize, fromRootFontSize, toRootFontSize) => {
    if (!Number.isFinite(fontSize)) return 0;
    if (!Number.isFinite(fromRootFontSize) || !Number.isFinite(toRootFontSize)) return fontSize;
    return (fontSize / fromRootFontSize) * toRootFontSize;
};

/* Styling (CSS) */

export const getUniqueComponentClassName = () => {
    return `wobo-${getUniqueId(5)}`;
};

/* Radio Buttons, Checkboxes and Drop-down List sections */

export const reorderOptions = (fromIndex, toIndex, options) => {
    const numberOfOptions = options?.length;
    if (
        !Number.isFinite(fromIndex) ||
        !Number.isFinite(toIndex) ||
        !Array.isArray(options) ||
        fromIndex >= numberOfOptions ||
        toIndex >= numberOfOptions
    )
        return [];

    if (fromIndex === toIndex || options.length === 1) return options;

    const updatedOptions = [];
    if (options.length === 2) {
        if (toIndex > fromIndex) {
            updatedOptions.push(options[toIndex], options[fromIndex]);
        } else {
            updatedOptions.push(options[fromIndex], options[toIndex]);
        }
    } else if (fromIndex > toIndex) {
        options.forEach((option, index) => {
            if (index === toIndex) {
                updatedOptions.push(options[fromIndex], option);
            } else if (index !== fromIndex) {
                updatedOptions.push(option);
            }
        });
    } else {
        options.forEach((option, index) => {
            if (index === toIndex) {
                updatedOptions.push(option, options[fromIndex]);
            } else if (index !== fromIndex) {
                updatedOptions.push(option);
            }
        });
    }
    return updatedOptions;
};

export const addOption = (options, newOption) => {
    if (!Array.isArray(options)) return [];
    return [...options, newOption];
};

export const removeOption = (options, removeIndex) => {
    if (!Array.isArray(options) || !Number.isFinite(removeIndex)) return [];
    const updatedOptions = options.filter((element, index) => {
        if (index === removeIndex) return false;
        return true;
    });
    return updatedOptions;
};

/* Billing and Plans */

export const getPlanName = (plan) => {
    switch (plan) {
        case PLANS.NO_PAID:
            return 'Not Paid';
        case PLANS.TRIAL:
            return 'Free Trial';
        case PLANS.LAUNCH_MONTHLY:
            return 'Monthly';
        case PLANS.ENTERPRISE:
            return 'Enterprise';
        case PLANS.BASIC:
            return 'Basic';
        case PLANS.PRO:
            return 'Pro';
        default:
            return 'Invalid';
    }
};

export const getPlanConfirmationMessage = (fromId, toId) => {
    const fromName = getPlanName(fromId);
    const toName = getPlanName(toId);
    return `Please confirm you'd like to change your subscription from ${fromName} to ${toName}.`;
};

/* Artifact (PDF) */

export const getArtifactOptionalInfo = (config, user) => {
    const optionalInfo = {};
    const watermarkConfig = config?.protectingIp?.watermark || {};
    const artifactConfig = config?.artifact || {};
    if (watermarkConfig.includeStudentDetails && user) {
        optionalInfo.headerHtml = `<div style="display: flex; justify-content: flex-end; width: 100%; color: rgba(184,184,184,1); font-size: 8px; margin-left: 0.2in; margin-right: 0.2in;"><div>${user.firstName} ${user.lastName} (${user.email})</div></div>`;
    }
    if (watermarkConfig.includeCustomText || artifactConfig.showPageNumbers) {
        const customText = watermarkConfig.includeCustomText ? watermarkConfig.customText : null;
        optionalInfo.footerHtml = `<div style="display: flex; justify-content: space-between; width: 100%; color: rgba(184,184,184,1); font-size: 8px; margin-left: 0.2in; margin-right: 0.2in;"><div>${
            customText || ''
        }</div><div>${artifactConfig.showPageNumbers ? '<span class="pageNumber"></span>' : ''}</div></div>`;
    }
    if (artifactConfig.pageFormat) {
        optionalInfo.pageFormat = artifactConfig.pageFormat;
    }
    return optionalInfo;
};

/* Currency */

export const convertStripeCurrencyToDollars = (amount = 0) => {
    if (!amount) return 0;
    return amount / 100;
};

export const getDollarAmount = (amount = 0, currency, decimals = 2) =>
    new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency || 'USD',
        minimumFractionDigits: decimals,
    }).format(amount);

/* Viewer Mode */

export const isSectionReadOnly = (mode) => {
    return ![
        WORKBOOK_SECTION_VIEWER_MODES.DEFAULT,
        WORKBOOK_SECTION_VIEWER_MODES.PREVIEW,
        WORKBOOK_SECTION_VIEWER_MODES.STUDENT,
    ].includes(mode);
};

export const getCookieValue = (cname) => {
    const name = `${cname}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    // If there is no cookie, we get an array of [''] from the previous line
    if (ca.length <= 1 && ca[0] === '') return 'Cookie not found';
    for (let i = 0; i < ca.length; i += 1) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return 'Cookie not found';
};
