import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { PhotographIcon, XIcon } from '@heroicons/react/outline';
import { NoActionModal } from '@/system/Modals';
import { getSizeDescription } from '@/util/helpers';
import FileLoader from '@/system/imageUploader/FileLoader';
import ImageEditor from '@/system/imageUploader/ImageEditor';
import { DefaultButton, BUTTON_SIZE } from '@/system/Buttons';
import { MAX_FILE_SIZE, VALID_IMAGE_MIME_TYPES, MAX_IMAGE_HEIGHT, MAX_IMAGE_WIDTH } from '@/util/constants';
import LoadingSpinner from '@/system/LoadingSpinner';

export default function ImageUploaderModal({
    mode,
    show,
    title,
    imageData,
    editorActionButtonText,
    maxFileSize = MAX_FILE_SIZE,
    validMimeTypes = VALID_IMAGE_MIME_TYPES,
    constraintText = 'Files must be in .png or .jpg format',
    onUse,
    onFileLoaded,
    onClose,
    onFileLoaderError,
}) {
    const [originalImageData, setOriginalImageData] = useState(mode === 'edit' ? imageData : null);
    const [currentImageData, setCurrentImageData] = useState(mode === 'edit' ? imageData : null);

    const handleFileLoaded = (file) => {
        const img = new Image();
        const url = URL.createObjectURL(file);
        img.onload = () => {
            if (img.width > MAX_IMAGE_WIDTH || img.height > MAX_IMAGE_HEIGHT) {
                onFileLoaderError(
                    `Image dimensions are too large (${img.width} x ${img.height} pixels). Please upload an image under ${MAX_IMAGE_WIDTH} x ${MAX_IMAGE_HEIGHT} pixels.`,
                );
            } else {
                const loadedImageData = {
                    name: file.name,
                    type: file.type,
                    size: file.size,
                    url,
                };
                onFileLoaded(loadedImageData);
            }
        };
        img.src = url;
    };

    const handleUploadAndUseClick = () => {
        onUse(currentImageData);
    };

    const handleImageChange = (canvas) => {
        canvas.toBlob((blob) => {
            if (blob) {
                const croppedImageData = {
                    name: originalImageData.name,
                    size: blob.size,
                    type: originalImageData.type,
                    url: canvas.toDataURL(originalImageData.type),
                };
                setCurrentImageData(croppedImageData);
            }
        }, originalImageData.type);
    };

    useEffect(() => {
        setOriginalImageData(mode === 'edit' ? imageData : null);
        setCurrentImageData(mode === 'edit' ? imageData : null);
    }, [mode, imageData]);

    const maxImageSizeExceeded = currentImageData && currentImageData.size > maxFileSize;

    const form = (
        <>
            <div className="flex flex-row items-start">
                <div className="w-16 flex-shrink-0 flex-grow-0">
                    <div className="mb-5 flex translate-x-1.5 transform justify-start">
                        <div className="flex h-10 w-10 items-center justify-center rounded-full bg-primary-50">
                            <PhotographIcon className="h-em-2 w-em-2 text-primary-500" />
                        </div>
                    </div>
                </div>
                <div className="relative flex w-auto flex-shrink flex-grow flex-col">
                    <div className="absolute top-0 right-1">
                        <button type="button" onClick={onClose}>
                            <XIcon className="h-4 w-4" />
                        </button>
                    </div>
                    {mode === 'edit' && (
                        <div className="absolute right-1 top-6">
                            <DefaultButton
                                label={editorActionButtonText || 'Finish'}
                                size={BUTTON_SIZE.MD}
                                onClick={handleUploadAndUseClick}
                                disabled={maxImageSizeExceeded}
                            />
                        </div>
                    )}
                    <div
                        className={classNames('heading__sm--semibold', {
                            'mb-1': mode === 'edit',
                            'mb-6': mode === 'upload',
                        })}>
                        {mode === 'upload' ? 'Upload Image' : title || 'Image Editor'}
                    </div>
                    {mode === 'edit' && (
                        <p className="caption mb-6">
                            Crop or Zoom your image below
                            <br />
                            Image size:{' '}
                            <span className={classNames({ 'text-error-200': maxImageSizeExceeded })}>
                                {currentImageData ? getSizeDescription(currentImageData.size) : null}
                            </span>{' '}
                            | Max size: {getSizeDescription(maxFileSize)}
                        </p>
                    )}
                    <div className="h-[425px] w-[581px]">
                        {mode === 'upload' && (
                            <>
                                <div className="h-full w-full">
                                    <FileLoader
                                        onFileLoaded={handleFileLoaded}
                                        constraintText={constraintText}
                                        supportedFileTypes={validMimeTypes}
                                        onFileLoaderError={onFileLoaderError}
                                    />
                                </div>
                            </>
                        )}
                        {mode === 'edit' && (
                            <>
                                <div className="h-full w-full">
                                    {originalImageData ? (
                                        <ImageEditor imageData={originalImageData} onImageChange={handleImageChange} />
                                    ) : null}
                                </div>
                            </>
                        )}
                        {mode === 'uploading' && (
                            <>
                                <div className="relative h-full w-full">
                                    <LoadingSpinner />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );

    return <NoActionModal show={show} form={form} onClose={null} />;
}
