import { useState } from 'react';
import AddComment from '@/workbook/builder/sections/comments/AddComment';
import useApi from '@/api/useApi';
import CommentsList from '@/workbook/builder/sections/comments/CommentsList';
import { useRoles } from '@/navigation/RolesProvider';

export default function SectionComments({
    sectionId,
    studentId,
    comments,
    setSectionCommentAdded,
    setSectionCommentDeleted,
    setSectionCommentUpdated,
    commentingUsers,
    workbookSchema,
}) {
    const { addComment: addCommentApi, deleteComment: deleteCommentApi, updateComment: updateCommentApi } = useApi();
    const [expandDefault, setExpandDefault] = useState(false);
    const role = useRoles();

    // Grab first property of the member object (this is always the unique ID), then find the subrole
    const roleMember = role.member?.creators;
    const subrole = typeof roleMember === 'object' && (Object.values(roleMember)?.[0]?.subrole ?? null);
    const isViewer = role.member && subrole === 2;

    const addComment = async (text) => {
        if (!comments || comments.length === 0) {
            setExpandDefault(true);
        }
        const newComment = await addCommentApi(sectionId, studentId, text);
        setSectionCommentAdded(newComment);
    };

    const deleteComment = async (comment) => {
        await deleteCommentApi(comment.id);
        setSectionCommentDeleted(comment);
    };

    const updateComment = async (comment) => {
        await updateCommentApi(comment.id, comment.comment);
        setSectionCommentUpdated(comment);
    };

    if (comments?.length > 0) {
        return (
            <div className="">
                <CommentsList
                    comments={comments}
                    onDelete={deleteComment}
                    onUpdate={updateComment}
                    onAdd={addComment}
                    commentingUsers={commentingUsers}
                    workbookSchema={workbookSchema}
                    expandDefault={expandDefault}
                />
            </div>
        );
    }

    if (isViewer) {
        return null;
    }

    return (
        <div className="">
            <AddComment onSave={addComment} workbookSchema={workbookSchema} />
        </div>
    );
}
