import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWorkbook } from '@/workbook/artifact/WorkbookJsonLoaderProvider';
import LoadingSpinner from '@/system/LoadingSpinner';
import TechnicalError from '@/system/TechnicalError';
import Viewer from '@/workbook/publiclyShared/Viewer';
import WorkbookStyles from '@/workbook/WorkbookStyles';
import Sidebar from '@/workbook/student/sidebar/Sidebar';
import PubliclySharedWorkbookAppNavbar from '@/navigation/PubliclySharedWorkbookAppNavbar';
import { WORKBOOK_SECTION_VIEWER_MODES } from '@/util/constants';
import useAuthApi from '@/api/useAuthApi';
import { getStudentEnrollInPubliclySharedWorkbook } from '@/util/helpers';

export default function Workbook({ full }) {
    const {
        shareId,
        workbook,
        student,
        loading,
        loadingError,
        creatorSchema,
        selectPrevModule,
        selectNextModule,
        selectModule,
        selectSection,
    } = useWorkbook();
    const [signupModalVisible, setSignupModalVisible] = useState(false);
    const { currentAuthenticatedUser } = useAuthApi();
    const viewerContainerRef = useRef(null);
    const { schema } = workbook;
    const navigate = useNavigate();

    useEffect(() => {
        if (workbook) {
            currentAuthenticatedUser()
                .then((user) => {
                    if (user && shareId) {
                        const path = getStudentEnrollInPubliclySharedWorkbook(shareId);
                        navigate(path, { replace: true });
                    }
                })
                .catch(() => {
                    // silently fail, we don't care if the user is not logged in
                });
        }
    }, [currentAuthenticatedUser, workbook, navigate, shareId]);

    if (loading) return <LoadingSpinner />;
    if (loadingError) return <TechnicalError title="Error loading workbook" desc={`Details: ${loadingError}`} />;
    return (
        <div className="h-screen">
            <PubliclySharedWorkbookAppNavbar
                signupModalVisible={signupModalVisible}
                setSignupModalVisible={setSignupModalVisible}
            />
            <WorkbookStyles schema={schema} />
            <div className="h-[calc(100%-4rem)] lg:flex lg:overflow-hidden">
                <div className="lg:h-full lg:w-96 lg:flex-shrink-0 lg:flex-grow-0 lg:bg-white">
                    <Sidebar workbook={workbook} selectModule={selectModule} selectSection={selectSection} />
                </div>
                <div
                    ref={viewerContainerRef}
                    className="viewer-container-background-color h-screen-minus-mobile-navbar overflow-auto lg:h-full lg:w-[calc(100vw-24rem)] lg:flex-shrink lg:flex-grow lg:bg-neutral-50 lg:pt-6">
                    <Viewer
                        setSignupModalVisible={setSignupModalVisible}
                        workbook={workbook}
                        creatorSchema={creatorSchema}
                        student={student}
                        full={full}
                        mode={WORKBOOK_SECTION_VIEWER_MODES.READ_ONLY}
                        selectPrevModule={selectPrevModule}
                        selectNextModule={selectNextModule}
                    />
                </div>
            </div>
        </div>
    );
}
