import classNames from 'classnames';
import LoadingSpinner from '@/system/LoadingSpinner';
import { DefaultButton, SecondaryButton, BUTTON_TYPE } from '@/system/Buttons';

function BaseCard({
    children,
    loading,
    onSubmit,
    buttonLabel,
    className,
    apiErrorMessage = null,
    footer = null,
    hideLogo = false,
    postFormSection = null,
    secondarySubmitButton = false,
}) {
    const containerClasses = classNames('pt-14 md:pt-18 w-11/12 md:w-1/4 md:min-w-[350px] mx-auto', className);
    return (
        <>
            {loading && <LoadingSpinner />}
            <div className={containerClasses}>
                {!hideLogo && (
                    <div className="mb-8 flex flex-col items-center">
                        <img src="/images/logo.svg" alt="logo" className="w-32" />
                    </div>
                )}

                <div className="flex flex-col items-center">
                    <div
                        className={classNames(
                            'flex w-full flex-col justify-center rounded-t-lg border-neutral-300 bg-white p-8',
                            { 'rounded-b-lg': !footer },
                        )}
                        style={{ borderWidth: '0.5px' }}>
                        <form onSubmit={onSubmit} noValidate>
                            {children}
                            {secondarySubmitButton ? (
                                <SecondaryButton
                                    type={BUTTON_TYPE.SUBMIT}
                                    className="mt-6"
                                    label={buttonLabel}
                                    size="lg"
                                    fullWidth
                                />
                            ) : (
                                <DefaultButton
                                    type={BUTTON_TYPE.SUBMIT}
                                    className="mt-6"
                                    label={buttonLabel}
                                    size="lg"
                                    fullWidth
                                />
                            )}
                        </form>
                        <div className="caption mt-2 text-error-200">{apiErrorMessage}</div>
                        {postFormSection}
                    </div>
                    {!!footer && (
                        <div className="flex w-full flex-col justify-center rounded-b-lg border border-t-0 border-neutral-300 bg-neutral-25 p-6">
                            {footer}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export function Card({ children, loading, onSubmit, buttonLabel, apiErrorMessage }) {
    return (
        <BaseCard loading={loading} onSubmit={onSubmit} buttonLabel={buttonLabel} apiErrorMessage={apiErrorMessage}>
            {children}
        </BaseCard>
    );
}

export function CardWithFooter({
    children,
    loading,
    onSubmit,
    buttonLabel,
    apiErrorMessage,
    footer,
    className,
    hideLogo,
    postFormSection,
    secondarySubmitButton,
}) {
    return (
        <BaseCard
            loading={loading}
            onSubmit={onSubmit}
            buttonLabel={buttonLabel}
            className={className}
            apiErrorMessage={apiErrorMessage}
            footer={footer}
            hideLogo={hideLogo}
            postFormSection={postFormSection}
            secondarySubmitButton={secondarySubmitButton}>
            {children}
        </BaseCard>
    );
}
