import classNames from 'classnames';
import Toggle from '@/system/Toggle';

export default function ToggleRow({
    label,
    enabled,
    onChange,
    className = 'text-sm',
    'data-test': dataTest,
    description,
}) {
    return (
        <div className="w-full mb-2">
            <div className="flex w-full justify-between" data-test={dataTest}>
                <div className={classNames('flex flex-col content-center justify-center', className)}>{label}</div>
                <Toggle enabled={enabled} onChange={onChange} />
            </div>
            {description && <p className="mt-2 heading__xs--medium font-normal">{description}</p>}
        </div>
    );
}
