import { useRef } from 'react';
import { PhotographIcon } from '@heroicons/react/outline';
import ArrowUpOnSquareStackIcon from '@/system/icons/ArrowUpOnSquareStackIcon';
import { getUniqueId } from '@/util/helpers';
import { WORKBOOK_SECTION_VIEWER_MODES } from '@/util/constants';
import PreviewSuggestionTooltip from '@/workbook/builder/sections/PreviewSuggestionTooltip';
import { DefaultButton, ICON_POSITION } from '@/system/Buttons';

export default function FileLoader({
    title = 'drag & drop files here',
    mode = 'default',
    constraintText,
    buttonText = 'Browse and upload instead',
    typeButton = false,
    buttonClassName,
    onFileLoaded,
    supportedFileTypes,
    onFileLoaderError,
}) {
    const idRef = useRef(getUniqueId(5));
    const id = `inputfile-${idRef.current}`;
    const inputFileRef = useRef(null);
    const labelClasses = buttonClassName || 'caption--underline mt-3.5 cursor-pointer';

    const read = async (files) => {
        if (!files || files.length === 0) return;

        const file = files[0];
        if (supportedFileTypes.includes(file.type)) {
            if (window.URL || window.webkitURL) {
                // eslint-disable-next-line consistent-return
                return file;
            }
            throw new Error('Your browser is not supported.');
        } else {
            throw new Error(`File type not supported: ${file.type}`);
        }
    };

    const handleChange = ({ target }) => {
        read(target.files)
            .then((data) => {
                onFileLoaded(data);
            })
            .catch((err) => {
                onFileLoaderError(err.message);
            });
    };

    const handleDragOver = (e) => e.preventDefault();

    const handleDrop = (e) => {
        e.preventDefault();
        read(e.dataTransfer.files)
            .then((data) => {
                onFileLoaded(data);
            })
            .catch((err) => {
                onFileLoaderError(err.message);
            });
    };

    const handleInputFileClick = () => {
        inputFileRef.current?.click();
    };

    return (
        <>
            <style type="text/css">
                {`

                .fileloader {
                    width: 0.1px;
                    height: 0.1px;
                    opacity: 0;
                    overflow: hidden;
                    position: absolute;
                    z-index: -1;
                }

                .fileloader:focus + label {
                    outline: 2px solid transparent;
                    outline-offset: 2px;
                    --tw-ring-offset-width: 2px;
                    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
                    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
                    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
                }

            `}
            </style>
            {!typeButton ? (
                <div
                    className="flex flex-col items-center justify-center w-full h-full py-16 bg-white border border-black border-dashed rounded-lg"
                    onChange={handleChange}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}>
                    <ArrowUpOnSquareStackIcon className="mb-6 w-9 text-neutral-300" style={{ height: 35 }} />
                    <div className="mb-2 heading__sm--semibold">{title}</div>
                    <p className="w-full pl-2 pr-2 text-center caption text-neutral-400">{constraintText}</p>
                    <input
                        id={id}
                        className="fileloader"
                        type="file"
                        accept={supportedFileTypes.join(', ')}
                        ref={inputFileRef}
                    />
                    {mode === WORKBOOK_SECTION_VIEWER_MODES.BUILDER ? (
                        <div>
                            <PreviewSuggestionTooltip>
                                <DefaultButton
                                    label="Upload"
                                    iconPosition={ICON_POSITION.LEFT}
                                    className="mt-3 replace-button custom-focus-outline"
                                />
                            </PreviewSuggestionTooltip>
                        </div>
                    ) : (
                        <DefaultButton
                            label="Upload"
                            iconPosition={ICON_POSITION.LEFT}
                            onClick={() => {
                                if (mode !== WORKBOOK_SECTION_VIEWER_MODES.BUILDER) handleInputFileClick();
                            }}
                            className="mt-3 replace-button custom-focus-outline"
                        />
                    )}
                </div>
            ) : (
                <>
                    <input
                        id={id}
                        className="fileloader"
                        type="file"
                        accept={supportedFileTypes.join(', ')}
                        onChange={handleChange}
                        ref={inputFileRef}
                    />
                    {mode !== 'default' ? (
                        <div>
                            <DefaultButton
                                label="Upload"
                                icon={PhotographIcon}
                                iconPosition={ICON_POSITION.LEFT}
                                onClick={() => {
                                    if (mode !== WORKBOOK_SECTION_VIEWER_MODES.BUILDER) handleInputFileClick();
                                }}
                                className="mt-3 replace-button custom-focus-outline"
                            />
                        </div>
                    ) : (
                        <button type="button" onClick={handleInputFileClick} className={labelClasses}>
                            {buttonText}
                        </button>
                    )}
                </>
            )}
        </>
    );
}
