export default function PageBreakIcon({ className = '', style = {} }) {
    return (
        <svg
            width="22"
            height="22"
            viewBox="0 0 12 15"
            fill="none"
            stroke="currentColor"
            strokeWidth="1.4"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            className={className}
            style={style}>
            <path
                d="M3.15039 7.125H6.90039M8.15039 12.125H1.90039C1.21003 12.125 0.650391 11.5654 0.650391 10.875V2.125C0.650391 1.43464 1.21003 0.875 1.90039 0.875H5.39151C5.55727 0.875 5.71624 0.940848 5.83345 1.05806L9.21733 4.44194C9.33454 4.55915 9.40039 4.71812 9.40039 4.88388V10.875C9.40039 11.5654 8.84075 12.125 8.15039 12.125Z"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
