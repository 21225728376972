import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import useApi from '@/api/useApi';
import useWorkbookVersionReducer, { ACTIONS } from '@/workbook/useWorkbookVersionReducer';
import { workbookVersionFromDto, moduleVersionFromDto, moduleResponseFromDto } from '@/api/dtos';
import { TOAST_POSITION, useToast } from '@/system/ToastProvider';
import {
    getStorageObjectBasePath,
    getArtifactOptionalInfo,
    getWorkbookUploadsStorageObjectBasePath,
} from '@/util/helpers';
import {
    COHORT_ID_QUERY_PARAM,
    STUDENT_ID_QUERY_PARAM,
    WORKBOOK_ID_QUERY_PARAM,
    WORKBOOK_VERSION_ID_QUERY_PARAM,
    FIRST_NAME_QUERY_PARAM,
    LAST_NAME_QUERY_PARAM,
    EMAIL_QUERY_PARAM,
} from '@/util/constants';
import { useRoles } from '@/navigation/RolesProvider';
import useStudentApi from '@/api/useStudentApi';
import useQueryParameter from '@/util/useQueryParameter';

const WorkbookVersionContext = createContext();

export function useWorkbookVersion() {
    return useContext(WorkbookVersionContext);
}

export default function WorkbookVersionProvider({ children }) {
    const isMounted = useRef(true);
    const {
        [WORKBOOK_ID_QUERY_PARAM]: workbookId,
        [WORKBOOK_VERSION_ID_QUERY_PARAM]: workbookVersionId,
        [COHORT_ID_QUERY_PARAM]: cohortId,
        [STUDENT_ID_QUERY_PARAM]: studentId,
    } = useParams();
    const studentFirstName = useQueryParameter(FIRST_NAME_QUERY_PARAM);
    const studentLastName = useQueryParameter(LAST_NAME_QUERY_PARAM);
    const studentEmail = useQueryParameter(EMAIL_QUERY_PARAM);
    const student = {
        id: studentId,
        firstName: studentFirstName,
        lastName: studentLastName,
        email: studentEmail,
    };
    const [workbookVersion, setWorkbookVersion] = useWorkbookVersionReducer();
    const [loading, setLoading] = useState(true);
    const [loadingError, setLoadingError] = useState('');
    const [workbookArtifactDownloadUrl, setWorkbookArtifactDownloadUrl] = useState(null);
    const { selectedCreator: creator, currentRole } = useRoles();
    const { createStudentWorkbookArtifactSignedUrl, createStudentWorkbookModuleArtifactSignedUrl } = useStudentApi();

    const {
        getCreatorWorkbookVersion,
        getCreatorWorkbookVersionModules,
        getCreatorWorkbookVersionModuleResponses,
        getCommentsByWorkbookVersionId,
    } = useApi();
    const { pushError } = useToast(TOAST_POSITION.BOTTOM);

    const selectModule = (moduleVersionId) => {
        setWorkbookVersion({ type: ACTIONS.SELECT_MODULE, payload: moduleVersionId });
    };

    const selectPrevModule = () => {
        setWorkbookVersion({ type: ACTIONS.SELECT_PREV_MODULE });
    };

    const selectNextModule = () => {
        setWorkbookVersion({ type: ACTIONS.SELECT_NEXT_MODULE });
    };

    const selectSection = (sectionId) => {
        setWorkbookVersion({ type: ACTIONS.SELECT_SECTION, payload: sectionId });
    };

    const setSectionResponseProp = (sectionId, path, value) => {
        setWorkbookVersion({ type: ACTIONS.UPDATE_SECTION_RESPONSE_PROP, payload: { sectionId, path, value } });
    };

    const setSectionCommentAdded = (comment) => {
        setWorkbookVersion({ type: ACTIONS.ADD_COMMENT, payload: comment });
    };

    const setSectionCommentUpdated = (comment) => {
        setWorkbookVersion({ type: ACTIONS.UPDATE_COMMENT, payload: comment });
    };

    const setSectionCommentDeleted = (comment) => {
        setWorkbookVersion({ type: ACTIONS.DELETE_COMMENT, payload: comment });
    };

    const generateWorkbookArtifact = async () => {
        const optionalInfo = getArtifactOptionalInfo(creator.schema, student);

        return createStudentWorkbookArtifactSignedUrl(studentId, workbookVersionId, cohortId, optionalInfo)
            .then((dto) => {
                if (dto.url) {
                    return fetch(dto.url)
                        .then((res) => res.blob())
                        .then((blob) => {
                            const objectUrl = URL.createObjectURL(blob);
                            setWorkbookArtifactDownloadUrl(objectUrl);
                            return dto;
                        })
                        .catch((err) => {
                            pushError('Could not access PDF', null);
                            console.error(err);
                            throw err;
                        });
                }
                return dto;
            })
            .catch((err) => {
                pushError('Could not create PDF', null);
                console.error(err);
                throw err;
            });
    };

    const generateWorkbookModuleArtifact = async (moduleId) => {
        const optionalInfo = getArtifactOptionalInfo(creator.schema, student);

        return createStudentWorkbookModuleArtifactSignedUrl(
            studentId,
            workbookVersionId,
            moduleId,
            cohortId,
            optionalInfo,
        )
            .then((dto) => {
                if (dto.url) {
                    return fetch(dto.url)
                        .then((res) => res.blob())
                        .then((blob) => {
                            const objectUrl = URL.createObjectURL(blob);
                            return { url: objectUrl };
                        })
                        .catch((err) => {
                            pushError('Could not access PDF', null);
                            console.error(err);
                            throw err;
                        });
                }
                return dto;
            })
            .catch((err) => {
                pushError('Could not create PDF', null);
                console.error(err);
                throw err;
            });
    };

    const loadWorkbookVersion = async () => {
        const payload = {
            workbookVersion: {},
            modules: [],
            moduleResponses: [],
            comments: [],
            creator: {},
            commentingUsers: [],
        };
        const workbookVersionDto = await getCreatorWorkbookVersion(
            creator.id,
            workbookId,
            workbookVersionId,
            currentRole,
        );
        payload.workbookVersion = workbookVersionFromDto(workbookVersionDto);

        const moduleResponseDtos = await getCreatorWorkbookVersionModuleResponses(
            creator.id,
            workbookId,
            cohortId,
            studentId,
            currentRole,
        ).catch((error) => {
            if (isMounted.current) {
                const fullError = `Error getting module responses: ${error.message}`;
                pushError(fullError, null);
                throw new Error(fullError);
            }
        });
        if (moduleResponseDtos && moduleResponseDtos.length > 0) {
            payload.moduleResponses = moduleResponseDtos.map((dto) => moduleResponseFromDto(dto));
        }
        if (payload.workbookVersion.moduleVersionIds.length > 0) {
            const moduleVersionDtos = await getCreatorWorkbookVersionModules(
                creator.id,
                workbookId,
                workbookVersionId,
                currentRole,
            ).catch((error) => {
                if (isMounted.current) {
                    const fullError = `Error getting modules: ${error.message}`;
                    pushError(fullError, null);
                    throw new Error(fullError);
                }
            });
            if (moduleVersionDtos && moduleVersionDtos.length > 0) {
                payload.modules = moduleVersionDtos.map((dto) => moduleVersionFromDto(dto));
            }
        }
        const { comments, users } = await getCommentsByWorkbookVersionId(workbookVersionId, studentId);
        payload.comments = comments;
        payload.commentingUsers = users;
        return payload;
    };

    useEffect(() => {
        const revokeUrl = workbookArtifactDownloadUrl;
        return () => {
            if (revokeUrl) URL.revokeObjectURL(revokeUrl);
        };
    }, [workbookArtifactDownloadUrl]);

    useEffect(() => {
        isMounted.current = true;
        if (isMounted.current) {
            setLoading(true);
            loadWorkbookVersion()
                .then((response) => {
                    const payload = {
                        ...response,
                        workbookVersion: {
                            ...response.workbookVersion,
                            schema: {
                                ...response.workbookVersion.schema,
                                storageObjectBasePath: getStorageObjectBasePath(
                                    response.workbookVersion.creatorId,
                                    response.workbookVersion.workbookId,
                                    response.workbookVersion.id,
                                ),
                                workbookUploadsStorageObjectBasePath: getWorkbookUploadsStorageObjectBasePath(
                                    response.workbookVersion.creatorId,
                                    response.workbookVersion.workbookId,
                                ),
                            },
                        },
                        cohortId,
                    };
                    if (isMounted.current) {
                        setWorkbookVersion({ type: ACTIONS.SET, payload });
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    if (isMounted.current) {
                        setLoadingError(error.message);
                        setLoading(false);
                    }
                });
        }
        return () => {
            isMounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const context = {
        workbookVersion,
        loading,
        loadingError,
        saving: false,
        selectModule,
        selectPrevModule,
        selectNextModule,
        selectSection,
        setSectionResponseProp,
        generateWorkbookArtifact,
        generateWorkbookModuleArtifact,
        workbookArtifactDownloadUrl,
        student,
        setSectionCommentAdded,
        setSectionCommentUpdated,
        setSectionCommentDeleted,
    };

    return <WorkbookVersionContext.Provider value={context}>{children}</WorkbookVersionContext.Provider>;
}
