import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import useApi from '@/api/useApi';
import useCohortReducer, { ACTIONS, defaultDataQueryOptions } from '@/cohorts/useStudentsReducer';
import { workbookFromDto, cohortFromDto, allCohortStudentsFromDto } from '@/api/dtos';
import { TOAST_POSITION, useToast } from '@/system/ToastProvider';
import { COHORT_ID_QUERY_PARAM, WORKBOOK_ID_QUERY_PARAM } from '@/util/constants';
import { useRoles } from '@/navigation/RolesProvider';

const CohortContext = createContext();

export function useCohort() {
    return useContext(CohortContext);
}

export default function CohortProvider({ children, defaultCohort = undefined }) {
    const isMounted = useRef(true);
    const { state, pathname, search } = useLocation();
    const routerState = {
        cohort: (state && state.cohort) || { workbook: {}, students: [] },
    };
    const navigate = useNavigate();
    const { [WORKBOOK_ID_QUERY_PARAM]: workbookId, [COHORT_ID_QUERY_PARAM]: cohortId = defaultCohort } = useParams();
    const [cohort, setCohort] = useCohortReducer();
    const [loading, setLoading] = useState(true);
    const [updatingStudents, setUpdatingStudents] = useState(false);
    const [error, setError] = useState('');
    const { selectedCreator: creator } = useRoles();
    const {
        getCreatorWorkbook,
        getCreatorCohort,
        getCohortStudents,
        enrollStudent,
        disenrollStudent,
        updateCohort: updateCohortApi,
    } = useApi();
    const { pushError } = useToast(TOAST_POSITION.BOTTOM);

    const updateCohort = (name, startDate, endDate) => {
        setLoading(true);
        const previousValue = { name: cohort.name, startDate: cohort.startDate, endDate: cohort.endDate };
        setCohort({ type: ACTIONS.UPDATE_COHORT, payload: { name, startDate, endDate } });
        updateCohortApi(creator.id, workbookId, cohortId, name, startDate, endDate)
            .then(() => {
                if (isMounted.current) {
                    setLoading(false);
                }
            })
            .catch((err) => {
                if (isMounted.current) {
                    setCohort({ type: ACTIONS.UPDATE_COHORT, payload: previousValue });
                    pushError(`Error updating cohort: ${err.message}`, null);
                    setLoading(false);
                }
            });
    };

    const loadStudents = async (dataQueryOptions) => {
        let studentResponses = [];
        if (routerState.students && routerState.students.length > 0) {
            studentResponses = routerState.students;
        } else {
            studentResponses = await getCohortStudents(creator.id, workbookId, cohortId, dataQueryOptions).then((dto) =>
                allCohortStudentsFromDto(dto),
            );
        }
        return studentResponses;
    };

    const applyQuery = async (dataQueryOptions) => {
        setUpdatingStudents(true);
        loadStudents(dataQueryOptions)
            .then((students) => {
                setCohort({ type: ACTIONS.UPDATE_STUDENTS, payload: { students, dataQueryOptions } });
                setUpdatingStudents(false);
            })
            .catch((err) => {
                if (isMounted.current) {
                    setError(err.message);
                    setUpdatingStudents(false);
                }
            });
    };

    const addStudent = async (firstName, lastName, email) => {
        setUpdatingStudents(true);
        enrollStudent(creator.id, workbookId, cohortId, firstName, lastName, email)
            .then(() => applyQuery(cohort.dataQueryOptions))
            .catch((err) => {
                if (isMounted.current) {
                    pushError(`Error adding student: ${err.message}`, null);
                    setUpdatingStudents(false);
                }
            });
    };

    const deleteStudent = async (studentId) => {
        setUpdatingStudents(true);
        disenrollStudent(creator.id, workbookId, cohortId, studentId)
            .then(() => applyQuery(cohort.dataQueryOptions))
            .catch((err) => {
                if (isMounted.current) {
                    pushError(`Error deleting student: ${err.message}`, null);
                    setUpdatingStudents(false);
                }
            });
    };

    const loadWorkbook = async () => {
        let workbookResponse = {};
        if (routerState.cohort && routerState.cohort.workbook.id === workbookId) {
            workbookResponse = routerState.cohort.workbook;
        } else {
            const workbookDto = await getCreatorWorkbook(creator.id, workbookId);
            workbookResponse = workbookFromDto(workbookDto);
        }
        return workbookResponse;
    };

    const loadCohort = async () => {
        let cohortResponse = {};
        if (routerState.cohort && routerState.cohort.id === cohortId) {
            cohortResponse = routerState.cohort;
        } else {
            const cohortDto = await getCreatorCohort(creator.id, workbookId, cohortId);
            cohortResponse = cohortFromDto(cohortDto);
        }
        return cohortResponse;
    };

    useEffect(() => {
        isMounted.current = true;
        if (isMounted.current) {
            setLoading(true);
            const loadCohortPromise = loadCohort();
            const loadWorkbookPromise = loadWorkbook();
            const loadStudentsPromise = loadStudents(defaultDataQueryOptions());
            Promise.all([loadCohortPromise, loadWorkbookPromise, loadStudentsPromise])
                .then((values) => {
                    const payload = {
                        ...values[0],
                        workbook: {
                            id: values[1].id,
                            name: values[1].name,
                            description: values[1].description,
                            moduleIds: values[1].moduleIds,
                            versionId: values[1].versionIds[0],
                        },
                        students: values[2],
                    };
                    setCohort({ type: ACTIONS.SET, payload });
                    setLoading(false);
                })
                .catch((err) => {
                    if (isMounted.current) {
                        setError(err.message);
                        setLoading(false);
                    }
                });
        }
        // Reset router state by leaving the second parameter's state property undefined to ensure
        // the latest cohort/workbook data is used when rendering
        navigate(`${pathname}${search}`, { replace: true });

        return () => {
            isMounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const context = {
        cohort,
        loading,
        error,
        updateCohort,
        addStudent,
        deleteStudent,
        applyQuery,
        updatingStudents,
    };

    return <CohortContext.Provider value={context}>{children}</CohortContext.Provider>;
}
