import { useRef, useEffect } from 'react';
import classNames from 'classnames';
import { ArrowNarrowLeftIcon, ArrowNarrowRightIcon } from '@heroicons/react/outline';
import { getSectionViewer, isSectionDisabledAddOn } from '@/workbook/builder/sections/helpers';
import { ViewerButton, ICON_POSITION } from '@/system/Buttons';
import ModuleTitle from '@/workbook/builder/viewer/ModuleTitle';
import { SECTIONS } from '@/workbook/builder/sections/sections';
import { EMBED_CONTEXTS, WORKBOOK_SECTION_VIEWER_MODES } from '@/util/constants';
import PoweredByWobo from '@/workbook/builder/sections/PoweredByWobo';
import { APP_STYLES } from '@/system/styles';
import SectionComments from '@/workbook/builder/sections/comments/SectionComments';

export default function Viewer({
    workbookVersion,
    workbook,
    selectPrevModule,
    selectNextModule,
    setSectionResponseProp,
    saveSectionResponseProp,
    saveResponseFile,
    resetResponseFile,
    generateWorkbookArtifact,
    generateWorkbookModuleArtifact,
    workbookArtifactDownloadUrl,
    student,
    totalResponsesChanged,
    previewResponses,
    embedded,
    embedContext,
    mode,
    viewerContainerRef,
    creatorSchema,
    addOns,
    setSectionCommentAdded,
    setSectionCommentDeleted,
    setSectionCommentUpdated,
    showAddOns,
}) {
    const moduleContainerRef = useRef(null);
    const { selectedModule, selectedModuleResponse, schema } = workbookVersion;
    const poweredByWobo = creatorSchema?.poweredByWobo || false;
    const currentModuleIndex = workbookVersion.moduleIdsDictionary[selectedModule.id];
    let showPrevModuleButton = currentModuleIndex > 0;
    const showNextModuleButton = currentModuleIndex < workbookVersion.modules.length - 1;
    const selectedIsCoverPage =
        workbookVersion?.selectedModule?.id &&
        workbookVersion.moduleIdsDictionary[workbookVersion.selectedModule.id] === 0;
    const isFirstSectionModuleCoverPage = selectedModule?.sections?.[0]?.type === SECTIONS.MODULE_COVER_PAGE.TYPE_ID;
    const sectionCount = selectedModule?.sections?.length || 0;
    const canComment =
        workbookVersion.commentingEnabled &&
        (mode === WORKBOOK_SECTION_VIEWER_MODES.READ_ONLY || mode === WORKBOOK_SECTION_VIEWER_MODES.STUDENT);
    const coverPageSchema = workbookVersion?.modules[0]?.sections[0]?.schema;
    const hideCoverPageModule = coverPageSchema?.hidden ?? false;

    // Valid sections that can use comments
    const CommentingSectionTypeIds = [
        SECTIONS.QUESTION_AND_ANSWER.TYPE_ID,
        SECTIONS.TABLE.TYPE_ID,
        SECTIONS.FILL_IN_THE_BLANKS.TYPE_ID,
        SECTIONS.NOTES_AREA.TYPE_ID,
    ];

    const scrollToTopOfModule = () => {
        const maxInnerWidth = embedded
            ? APP_STYLES.SCREENS.WORKBOOK_VIEWER_EMBED_SHOW_PADDED_CONTENT_MIN_WIDTH
            : APP_STYLES.SCREENS.LG_MIN_WIDTH;
        // The module container div on mobile width doesn't have a fixed height
        // so we need to scroll to the top of the window.
        if (window.innerWidth < maxInnerWidth) {
            viewerContainerRef?.current?.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        } else {
            moduleContainerRef.current.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }
    };

    const sectionSupportsCommenting = (typeId) => CommentingSectionTypeIds.indexOf(typeId) > -1;

    useEffect(() => {
        scrollToTopOfModule();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedModule]);

    // If cover page is set to hidden, don't show the back button on the first module
    if (hideCoverPageModule) {
        showPrevModuleButton = currentModuleIndex > 1;
    }

    return (
        <>
            <div
                key={selectedModule.id}
                ref={moduleContainerRef}
                className={classNames('relative mx-auto w-full space-y-8 overflow-y-auto', {
                    'min-h-full-minus-nav-button-container p-4 lg:p-10': !selectedIsCoverPage,
                    'pt-0 pr-4 pl-4 pb-4 lg:pt-0 lg:pr-10 lg:pl-10': isFirstSectionModuleCoverPage,
                    'lg:pb-10': isFirstSectionModuleCoverPage && sectionCount > 1,
                    'lg:pb-0': isFirstSectionModuleCoverPage && sectionCount === 1,
                    'h-full-minus-nav-button-container p-4 pb-0': selectedIsCoverPage,
                    'lg:p-0': selectedIsCoverPage && !embedded,
                    'emw:p-0': selectedIsCoverPage && embedded,
                    'emw:w-workbook-viewer-embed-desktop emw:shadow-md': embedded,
                    'emw:h-workbook-viewer-embed-desktop emw:min-h-workbook-viewer-embed-desktop':
                        embedded && embedContext !== EMBED_CONTEXTS.WORKBOOK_MODULE,
                    'emw:h-module-viewer-embed-desktop emw:min-h-module-viewer-embed-desktop':
                        embedded && embedContext === EMBED_CONTEXTS.WORKBOOK_MODULE,
                    'lg:h-full-minus-nav-button-container lg:min-h-full-minus-nav-button-container lg:w-workbook-viewer-desktop lg:shadow-md':
                        !embedded,
                })}
                style={{ backgroundColor: selectedIsCoverPage ? 'transparent' : schema.pageColor }}>
                <PoweredByWobo
                    enable={embedded && !selectedIsCoverPage && poweredByWobo && !isFirstSectionModuleCoverPage}
                />
                {!selectedIsCoverPage && !isFirstSectionModuleCoverPage && !selectedModule.show_title && (
                    <ModuleTitle title={selectedModule.title} workbookSchema={schema} />
                )}
                {selectedModule?.sections?.map((section, index) => {
                    // Hide the sections which are dependent on creator's paid add-ons or being on trial
                    if (showAddOns && isSectionDisabledAddOn(section.type, addOns)) {
                        return null;
                    }

                    // Hide page break sections from student view
                    if (section.type === SECTIONS.PAGE_BREAK.TYPE_ID) {
                        return null;
                    }

                    const sectionId = section.id;
                    const Section = getSectionViewer(section);
                    let responses = previewResponses?.[sectionId] || null;
                    const sectionType = section.type;

                    if (
                        !previewResponses &&
                        selectedModuleResponse &&
                        Object.prototype.hasOwnProperty.call(selectedModuleResponse.sectionResponses, sectionId)
                    ) {
                        responses = selectedModuleResponse.sectionResponses[sectionId];
                    }

                    const handleSetSectionResponseProps = (path, value) => {
                        setSectionResponseProp(sectionId, path, value);
                    };

                    const handleSaveSectionResponseProps = async (path, value) => {
                        return saveSectionResponseProp(sectionId, path, value);
                    };

                    const handleSaveResponseFile = async (path, fileData, filePrefix) => {
                        return saveResponseFile(sectionId, path, fileData, filePrefix);
                    };

                    const handleResetResponseFile = (path) => {
                        return resetResponseFile(sectionId, path);
                    };

                    return (
                        <div
                            key={sectionId}
                            id={`section-${sectionId}`}
                            className={classNames('relative', {
                                'h-full w-full': section.type === SECTIONS.WORKBOOK_COVER_PAGE.TYPE_ID,
                            })}>
                            {Section && (
                                <div>
                                    <Section
                                        id={sectionId}
                                        schema={section.schema}
                                        workbookVersion={workbookVersion}
                                        workbook={workbook}
                                        module={selectedModule}
                                        workbookSchema={schema}
                                        sectionResponses={responses}
                                        setSectionResponseProp={handleSetSectionResponseProps}
                                        saveSectionResponseProp={handleSaveSectionResponseProps}
                                        saveResponseFile={handleSaveResponseFile}
                                        resetResponseFile={handleResetResponseFile}
                                        generateWorkbookArtifact={generateWorkbookArtifact}
                                        generateWorkbookModuleArtifact={
                                            typeof generateWorkbookModuleArtifact === 'function'
                                                ? () => generateWorkbookModuleArtifact(selectedModule.id)
                                                : () => {}
                                        }
                                        workbookArtifactDownloadUrl={workbookArtifactDownloadUrl}
                                        student={student}
                                        totalResponsesChanged={totalResponsesChanged}
                                        mode={mode}
                                        showPoweredByWobo={
                                            embedded &&
                                            poweredByWobo &&
                                            (section.type === SECTIONS.WORKBOOK_COVER_PAGE.TYPE_ID ||
                                                section.type === SECTIONS.MODULE_COVER_PAGE.TYPE_ID) &&
                                            ((isFirstSectionModuleCoverPage && index === 0) || selectedIsCoverPage)
                                        }
                                    />
                                    {canComment && sectionSupportsCommenting(sectionType) && (
                                        <SectionComments
                                            studentId={student.id}
                                            sectionId={sectionId}
                                            workbookSchema={schema}
                                            comments={workbookVersion.commentsBySectionIdDictionary[sectionId]}
                                            setSectionCommentAdded={setSectionCommentAdded}
                                            setSectionCommentDeleted={setSectionCommentDeleted}
                                            setSectionCommentUpdated={setSectionCommentUpdated}
                                            commentingUsers={workbookVersion.commentingUsers}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
            {embedContext !== EMBED_CONTEXTS.WORKBOOK_MODULE && (
                <div
                    className={classNames(
                        'nav-button-container mx-auto flex w-full justify-between p-4 emw:py-4 emw:px-0',
                        { 'emw:w-workbook-viewer-desktop': !embedded, 'emw:w-workbook-viewer-embed-desktop': embedded },
                    )}
                    data-test="viewer-nav-container">
                    {showPrevModuleButton && (
                        <ViewerButton
                            label="Back"
                            icon={ArrowNarrowLeftIcon}
                            iconPosition={ICON_POSITION.LEFT}
                            onClick={selectPrevModule}
                            className="nav-button custom-focus-outline"
                        />
                    )}

                    {showNextModuleButton && (
                        <ViewerButton
                            label="Next"
                            icon={ArrowNarrowRightIcon}
                            onClick={selectNextModule}
                            className="ml-auto nav-button custom-focus-outline"
                        />
                    )}
                </div>
            )}
        </>
    );
}
