import { useState } from 'react';

export default function usePDFDownloader() {
    const [downloading, setDownloading] = useState(false);
    const download = async (downloadUrl, fileName) => {
        setDownloading(true);
        const response = await fetch(downloadUrl);
        const data = await response.blob();

        const url = window.URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        setDownloading(false);
    };

    return { downloading, download };
}
