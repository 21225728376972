/* eslint-disable react/jsx-boolean-value */
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '@/system/LoadingSpinner';
import TechnicalError from '@/system/TechnicalError';
import { useAuth } from '@/auth/AuthProvider';
import { useEmbed } from '@/embed/EmbedProvider';
import useQueryParameter from '@/util/useQueryParameter';
import {
    ROLES,
    ROLE_QUERY_PARAM,
    EMBED_QUERY_PARAMS,
    ROUTES,
    ERROR_QUERY_PARAM,
    FROM_URL_QUERY_PARAM,
} from '@/util/constants';
import { appendQueryParams, getSsoErrorState } from '@/util/helpers';
import useGTM from '@/util/useGTM';

export default function TeachableAuthComplete() {
    const isMounted = useRef(true);
    const navigate = useNavigate();
    const { completeEmbedLogin, completeEmbedLoginV2, user, setOriginalSignin } = useAuth();
    const { embedded } = useEmbed();
    const ssoError = useQueryParameter(ERROR_QUERY_PARAM);
    const fromUrl = useQueryParameter(FROM_URL_QUERY_PARAM);
    const isCreator = useQueryParameter('is_creator'); // Backwards compatibility
    let role = useQueryParameter(ROLE_QUERY_PARAM);
    const forcedRole = useQueryParameter('forced_role');
    if (isCreator) role = ROLES.CREATOR; // Backwards compatibility
    const teachableV2 = useQueryParameter(EMBED_QUERY_PARAMS.TEACHABLE_V2);
    const errorState = getSsoErrorState(ssoError, role);
    const creatorID = useQueryParameter('creator_id');
    const workbookID = useQueryParameter('workbook_id');
    const moduleID = useQueryParameter('module_id');
    let fromUrlWithRole = appendQueryParams(fromUrl, { role, forced_role: forcedRole });
    fromUrlWithRole += `&creator_id=${creatorID}&workbook_id=${workbookID}&module_id=${moduleID}`;
    const gtm = useGTM();

    const handleSsoErrorClick = () => {
        navigate(errorState.navigateUrl);
    };

    const checkMountError = (error) => {
        if (isMounted.current) {
            console.error(error.message);
        }
    };

    useEffect(() => {
        isMounted.current = true;
        if (embedded) {
            if (!ssoError) {
                const defaultUrl = `${ROUTES.COMMON.BASE}?embed=true`;

                if (!teachableV2) {
                    completeEmbedLogin()
                        .then(() => {
                            gtm.pushSignin('creator', 'teachable', user.id);
                            setOriginalSignin('teachable');
                            window.opener.location.href =
                                fromUrlWithRole || appendQueryParams(defaultUrl, { role, forced_role: forcedRole });
                            window.close();
                        })
                        .catch(checkMountError);
                } else {
                    completeEmbedLoginV2()
                        .then(() => {
                            gtm.pushSignin('creator', 'teachable', user.id);
                            setOriginalSignin('teachable');
                            navigate(
                                fromUrlWithRole || appendQueryParams(defaultUrl, { role, forced_role: forcedRole }),
                            );
                        })
                        .catch(checkMountError);
                }
            } else {
                console.error(ssoError);
            }
        } else if (!ssoError) {
            gtm.pushSignin('creator', 'teachable', user.id);
            setOriginalSignin('teachable');
            navigate(fromUrlWithRole || ROUTES.COMMON.BASE);
        }
        return () => {
            isMounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (ssoError)
        return (
            <TechnicalError
                title="SSO error"
                desc={`Error completing SSO: ${ssoError}`}
                buttonLabel={embedded ? null : errorState.buttonLabel}
                onButtonClick={handleSsoErrorClick}
            />
        );
    return <LoadingSpinner />;
}
