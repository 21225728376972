export default function TeachableIcon({ className = '', style = {} }) {
    return (
        <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAaCAYAAABGiCfwAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHbSURBVHgBvZa7TgJBFIb/RVoVqCkwPIB2IFrwBlpoLZ1WYEFoxV4TqTQ26gNQWKmVKA2JGomiLXgPIV4SoOJyPLMLUZKFHZaFL/mzs5uz++/MnJkzCgwgIgdfQqxpVpDlaEmQZRVYx6yUoigFmEGYsDZY3yTPAcvTj48wCrLyZI48KyRrFCFr2DAyCpG1rHcz8lB/8yNLUM/svNcbtUaDGs0mmeAWOgnRE39glqLRKJlE7Z2t5beC4aImi43+Fu0wESPnsHNjplfU6dkZcrkcqpUqHh4fsLW9rT6PRCJ4enlGMpnEXCCA+cAcDFi0GZnd393h4vIS9XoNH+8faluoVq/j9fUN+7t7uLm6hgQekRxxmRnWS5DPry/KZDJULBZlPhG3YwBcTid8Pp90vBjGAkbEKM2ywiwrE2kfG0OpVMIAZG1c8H64kTKKXF5aRjqdxuraGmKxmJqNKb73er1IJBJGr6uFtZ0gm9CqcFfC4TCcLhdOT05QKZfVZ5MT4/D7/XC73TDgqOOODDbiAci3PZR/Zh6+iB3aAWuZ0j2bkPXFM97zNzhgnawhLtVvDlwk8wceUe1D6AfSjgk7fZockFaydFFkTKEtiwWInbuzShSgbQoXrMPWmu3KL3MDb8r1/i+wAAAAAElFTkSuQmCC"
            className={`w-full h-full ${className}`}
            style={style}
            alt="Teachable"
        />
    );
}
