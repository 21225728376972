import React, { useState } from 'react';
import { AnnotationIcon, ChevronDownIcon } from '@heroicons/react/outline';
import Comment from './Comment';
import CommentEditor from '@/workbook/builder/sections/comments/CommentEditor';
import { useRoles } from '@/navigation/RolesProvider';

export default function CommentsList({
    comments,
    onDelete,
    onUpdate,
    onAdd,
    commentingUsers,
    workbookSchema,
    expandDefault,
}) {
    const [expanded, setExpanded] = useState(expandDefault);

    const role = useRoles();

    // Grab first property of the member object (this is always the unique ID), then find the subrole
    const roleMember = role.member?.creators;
    const subrole = typeof roleMember === 'object' && (Object.values(roleMember)?.[0]?.subrole ?? null);
    const isViewer = role.member && subrole === 2;

    return (
        <div>
            <div className="flex flex-row justify-around bg-transparent">
                <button
                    type="button"
                    className="flex flex-row w-full mt-4 cursor-pointer justify-between"
                    style={{ fontFamily: workbookSchema.textStyling.body.fontFamily }}
                    onClick={() => {
                        setExpanded((v) => !v);
                    }}>
                    <div className="flex flex-row">
                        <AnnotationIcon className="h-6 w-5 text-neutral-300" />
                        <span className="ml-2 underline text-neutral-300">Comments ({comments.length})</span>
                    </div>

                    {expanded ? (
                        <ChevronDownIcon className="h-6 w-5 transition-transform transform rotate-180 text-neutral-300" />
                    ) : (
                        <ChevronDownIcon className="h-6 w-5 transition-transform text-neutral-300" />
                    )}
                </button>
            </div>

            {expanded && (
                <div className="flex flex-col w-full h-full mt-2 bg-slate-50 border rounded-md overflow-hidden">
                    <div className="flex flex-col w-full h-full">
                        {comments.map((comment) => (
                            <Comment
                                workbookSchema={workbookSchema}
                                comment={comment}
                                key={comment.id}
                                onDelete={onDelete}
                                onUpdate={onUpdate}
                                commentingUsers={commentingUsers}
                            />
                        ))}
                    </div>
                    {!isViewer && (
                        <div className="flex flex-row w-full h-full p-5">
                            <CommentEditor
                                workbookSchema={workbookSchema}
                                initialComment=""
                                onAdd={onAdd}
                                isEditing={false}
                                setAddingComment={setExpanded}
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}
