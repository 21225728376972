/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState, useEffect } from 'react';
import { getSearch } from '@/api/helpers';
import TechnicalError from '@/system/TechnicalError';
import LoadingSpinner from '@/system/LoadingSpinner';
import { useEmbed } from '@/embed/EmbedProvider';
import { getThinkificStudentEmbedSsoParams } from '@/util/helpers';
import { BACKEND_ERRORS, ROUTES, EMBED_CONTEXTS } from '@/util/constants';

export const SSO_ERROR_ACTION = 'sso_error_action';

const popupHeight = 250;
const popupWidth = 250;
const maxWaitingTime = 30000;

const getHost = (hostname) => {
    const domain = hostname.split('.thinkific.com')[0];
    if (domain === hostname) return domain;
    return hostname;
};

const CustomButton = ({ label, handler, metadata }) => (
    <button
        type="button"
        className="thinkific-embed-login-button h-10 w-full rounded-md text-base font-medium"
        style={{ backgroundColor: metadata.backgroundColor }}
        onClick={handler}>
        <div className="flex h-full flex-col items-center justify-center rounded-md">
            <span style={{ color: metadata.textColor }}>{label}</span>
        </div>
    </button>
);

export default function ThinkificSso() {
    const { metadata, loadingMetadata, originalLocation, context: embedContext } = useEmbed();
    const [popupWindow, setPopupWindow] = useState(null);
    const [popupWindowTimer, setPopupWindowTimer] = useState(null);
    const [retry, setRetry] = useState(false);
    const [error, setError] = useState('');
    const searchParams = new URLSearchParams(originalLocation.search);
    const workbookId = searchParams.get('workbook_id') || '';
    const originalUrl = `${originalLocation.pathname}${originalLocation.search}`;

    const handleClick = () => {
        const params = getSearch(getThinkificStudentEmbedSsoParams(originalUrl || ROUTES.COMMON.BASE, workbookId));
        const host = getHost(metadata.hostname);
        const oauthUrl = `https://${host}/oauth2/authorize${params}`;
        const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
        const newPopupWindow = window.open(
            oauthUrl,
            'Thinkific SSO',
            `toolbar=no, resizable=no, width=${popupWidth}, height=${popupHeight}, top=${vh}, left=${0}`,
        );
        setPopupWindow(newPopupWindow);
    };

    const handleRetryClick = () => {
        setRetry(false);
        handleClick();
    };

    const enableRetry = () => {
        clearTimeout(popupWindowTimer);
        if (popupWindow) popupWindow.close();
        setRetry(true);
        setPopupWindow(null);
        setPopupWindowTimer(null);
    };

    useEffect(() => {
        window.addEventListener('message', (event) => {
            const { action, payload } = event.data;
            if (action === SSO_ERROR_ACTION) {
                if (payload.includes(BACKEND_ERRORS.THINKIFIC_CALLBACK.CREATOR_HAS_NO_ACCESS_TO_PLAN)) {
                    setError("We couldn't find this workbook. Contact your instructor for more information.");
                } else {
                    enableRetry();
                }
            }
        });

        switch (embedContext) {
            case EMBED_CONTEXTS.WORKBOOK_MODULE:
            default:
                if (!workbookId) setError('Workbook id not provided');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setError(!metadata.hostname ? 'No hostname' : '');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingMetadata]);

    useEffect(() => {
        if (popupWindow) {
            const timer = setTimeout(() => {
                enableRetry();
            }, maxWaitingTime);
            setPopupWindowTimer(timer);
            return () => clearTimeout(timer);
        }
        return () => {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [popupWindow]);

    if (loadingMetadata) return <LoadingSpinner />;
    if (error) return <TechnicalError title="Oops, something went wrong!" desc={error} />;
    if (popupWindow)
        return (
            <LoadingSpinner
                text={
                    <div className="text-center">
                        <div>Accessing your workbook. Please do</div>
                        <div>not close the pop-up window.</div>
                    </div>
                }
            />
        );

    const accessInfo = {};
    switch (embedContext) {
        case EMBED_CONTEXTS.WORKBOOK_MODULE:
        default:
            accessInfo.title = 'Access your workbook';
            accessInfo.desc =
                'Your instructor has created an interactive workbook for this course. Continue below to access it securely using the same login details you use for this course.';
            accessInfo.buttonLabel = 'Access workbook';
    }

    return (
        <div className="relative h-full w-full">
            <div className="absolute top-1/2 left-1/2 my-0 mx-auto block w-11/12 max-w-sm -translate-y-1/2 -translate-x-1/2 transform ">
                <div className="flex flex-col items-center">
                    <div
                        className="flex w-full flex-col justify-center rounded-lg border-neutral-300 bg-white p-8"
                        style={{ borderWidth: '0.5px' }}>
                        {retry ? (
                            <>
                                <div className="paragraph__sm pb-6">Oops! Something went wrong.</div>
                                <CustomButton label="Try again" handler={handleRetryClick} metadata={metadata} />
                            </>
                        ) : (
                            <>
                                <label className="heading__md--medium pb-6">{accessInfo.title}</label>
                                <div className="paragraph__sm pb-6">{accessInfo.desc}</div>
                                <CustomButton
                                    label={accessInfo.buttonLabel}
                                    handler={handleClick}
                                    metadata={metadata}
                                />
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
