import classNames from 'classnames';
import { useRef, useEffect } from 'react';
import { uppercaseFirstLetter } from '@/util/helpers';

export default function Textarea({
    value,
    onChange,
    label = null,
    onBlur = null,
    placeholder = '',
    required = false,
    error = false,
    errorMsg = null,
    readOnly = false,
    style = {},
    resizeToFit = false,
    focus = false,
    className = '',
    containerRef = null,
    'data-test': dataTest,
}) {
    const inputRef = useRef();
    const requiredText = !placeholder ? '* Required' : '* ';

    const resizeTextarea = () => {
        const input = inputRef.current;
        if (containerRef?.current) {
            const container = containerRef.current;
            input.style.height = '';
            if (container.offsetHeight > input.scrollHeight) {
                input.style.height = `${container.offsetHeight}px`;
            } else {
                input.style.height = `${input.scrollHeight + 2}px`;
            }
        } else {
            input.style.height = '';
            input.style.height = `${input.scrollHeight + 2}px`;
        }
    };

    useEffect(() => {
        if (resizeToFit) resizeTextarea();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, resizeToFit]);

    useEffect(() => {
        if (focus && inputRef.current) inputRef.current.focus();
    }, [focus]);

    return (
        <>
            {label && <label className="heading__xs--medium mb-1.5">{label}</label>}
            <textarea
                className={classNames('paragraph_md w-full rounded-md border px-3 pt-2 pb-2', className, {
                    'border-error-200 text-error-500': error,
                    'border-neutral-200': !error,
                    'resize-none': resizeToFit,
                })}
                placeholder={`${required ? requiredText : ''}${uppercaseFirstLetter(placeholder)}`}
                onChange={typeof onChange === 'function' ? onChange : () => {}}
                onBlur={typeof onBlur === 'function' ? onBlur : () => {}}
                value={value}
                readOnly={readOnly}
                style={style}
                ref={inputRef}
                data-test={dataTest}
            />
            {error && errorMsg && <div className="caption mt-1 text-error-200">{uppercaseFirstLetter(errorMsg)}</div>}
        </>
    );
}
