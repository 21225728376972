/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import { SwitchVerticalIcon, XIcon } from '@heroicons/react/outline';
import { Droppable, Draggable, DragDropContext } from 'react-beautiful-dnd';
import { getUniqueId, reorderOptions, addOption, removeOption, isSectionReadOnly } from '@/util/helpers';
import TextInputDebounce from '@/system/TextInputDebounce';
import RichTextEditorDebounce from '@/system/RichTextEditorDebounce';
import ToggleRow from '@/workbook/builder/sections/ToggleRow';
import SectionTitle from '@/system/SectionTitle';
import SectionDescription from '@/system/SectionDescription';
import { DefaultButton, WhiteButton } from '@/system/Buttons';
import { FILL_IN_THE_BLANKS_SECTION, WORKBOOK_SECTION_VIEWER_MODES } from '@/util/constants';
import Label from '@/system/Label';
import PreviewSuggestionTooltip from '@/workbook/builder/sections/PreviewSuggestionTooltip';

const responsePath = 'answer';

const getTotalTextsAndInputs = (options) => {
    let totalTexts = 0;
    let totalInputs = 0;
    options.forEach((answer) => {
        if (answer.type === FILL_IN_THE_BLANKS_SECTION.TEXT_TYPE) {
            totalTexts += 1;
        } else {
            totalInputs += 1;
        }
    });
    return [totalTexts, totalInputs];
};

export const FillInTheBlanksEditor = ({ schema, setSectionSchemaProp, saveSectionSchemaProp }) => {
    const { title, description, options } = schema;
    const [totalTexts, totalInputs] = getTotalTextsAndInputs(options);
    const [showRemoveText, showRemoveInput] = [totalTexts > 1, totalInputs > 1];
    const [textlimitReached, inputLimitReached] = [
        totalTexts === FILL_IN_THE_BLANKS_SECTION.MAX_TEXTS,
        totalInputs === FILL_IN_THE_BLANKS_SECTION.MAX_INPUTS,
    ];

    const handleTitleChange = (e) => {
        const { value: text } = e.target;
        setSectionSchemaProp('title.text', text);
    };

    const handleTitleTypingEnd = (text) => {
        saveSectionSchemaProp('title.text', text);
    };

    const handleDescriptionToggleChange = () => {
        saveSectionSchemaProp('description.show', !description.show);
    };

    const handleDescriptionChange = (text) => {
        setSectionSchemaProp('description.text', text);
    };

    const handleDescriptionTypingEnd = (text) => {
        saveSectionSchemaProp('description.text', text);
    };

    const handleAnswerChange = (label, index) => {
        const updatedAnswers = [...options];
        updatedAnswers[index] = { id: updatedAnswers[index].id, label, type: updatedAnswers[index].type };
        setSectionSchemaProp('options', updatedAnswers);
    };

    const handleAnswerTypingEnd = (label, index) => {
        const updatedAnswers = [...options];
        updatedAnswers[index] = { id: updatedAnswers[index].id, label, type: updatedAnswers[index].type };
        saveSectionSchemaProp('options', updatedAnswers);
    };

    const handleDragEnd = (result) => {
        const fromIndex = result.source.index;
        const toIndex = result.destination.index;
        if (fromIndex === toIndex) return;
        // Ignore when the user drags outside of the droppable container
        if (!result.destination || options.length <= 1) return;
        saveSectionSchemaProp('options', reorderOptions(fromIndex, toIndex, options));
    };

    const handleAddText = () => {
        if (!textlimitReached) {
            const id = getUniqueId(5);
            const label = `Text ${totalTexts + 1}`;
            const type = FILL_IN_THE_BLANKS_SECTION.TEXT_TYPE;
            saveSectionSchemaProp('options', addOption(options, { id, label, type }));
        }
    };

    const handleAddInput = () => {
        if (!inputLimitReached) {
            const id = getUniqueId(5);
            const label = `Input Field ${totalInputs + 1}`;
            const type = FILL_IN_THE_BLANKS_SECTION.INPUT_TYPE;
            saveSectionSchemaProp('options', addOption(options, { id, label, type }));
        }
    };

    const handleRemoveOption = (removeIndex) => {
        saveSectionSchemaProp('options', removeOption(options, removeIndex));
    };

    return (
        <div className="space-y-3.5">
            <Label desc="Question" />
            <TextInputDebounce value={title.text} onChange={handleTitleChange} onTypingEnd={handleTitleTypingEnd} />
            <div>
                <ToggleRow label="Description" enabled={description.show} onChange={handleDescriptionToggleChange} />
                <RichTextEditorDebounce
                    value={description.text}
                    onChange={handleDescriptionChange}
                    onTypingEnd={handleDescriptionTypingEnd}
                />
            </div>
            <Label desc="Answers" />
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="droppable-options">
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef} className="space-y-3.5">
                            {options.map((answer, index) => (
                                <Draggable
                                    key={`draggable-${answer.id}`}
                                    draggableId={`draggable-${answer.id}`}
                                    index={index}>
                                    {(providedDraggable) => (
                                        <div ref={providedDraggable.innerRef} {...providedDraggable.draggableProps}>
                                            <div className="relative" key={`draggable-container-${answer.id}`}>
                                                <div
                                                    className="absolute left-1.5 z-[1] top-1/2 -translate-y-1/2"
                                                    {...providedDraggable.dragHandleProps}>
                                                    <SwitchVerticalIcon className="h-em w-em cursor-grab text-neutral-200" />
                                                </div>
                                                {((answer.type === FILL_IN_THE_BLANKS_SECTION.TEXT_TYPE &&
                                                    showRemoveText) ||
                                                    (answer.type === FILL_IN_THE_BLANKS_SECTION.INPUT_TYPE &&
                                                        showRemoveInput)) && (
                                                    <div className="absolute right-1.5 z-[1] top-1/2 -translate-y-1/2">
                                                        <button type="button" onClick={() => handleRemoveOption(index)}>
                                                            <XIcon className="h-em w-em text-neutral-200" />
                                                        </button>
                                                    </div>
                                                )}
                                                <TextInputDebounce
                                                    value={answer.label}
                                                    onChange={(e) => handleAnswerChange(e.target.value, index)}
                                                    onTypingEnd={(text) => handleAnswerTypingEnd(text, index)}
                                                    inputClassName={classNames('px-6', {
                                                        'text-neutral-200 bg-neutral-50 border-neutral-100 border':
                                                            answer.type === FILL_IN_THE_BLANKS_SECTION.INPUT_TYPE,
                                                    })}
                                                    readOnly={answer.type === FILL_IN_THE_BLANKS_SECTION.INPUT_TYPE}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

            <DefaultButton label="Add Text" className="w-full" onClick={handleAddText} disabled={textlimitReached} />
            <WhiteButton
                label="Add Input Field"
                className="w-full"
                onClick={handleAddInput}
                disabled={inputLimitReached}
            />
        </div>
    );
};

export const FillInTheBlanks = ({
    workbookSchema,
    schema,
    mode = WORKBOOK_SECTION_VIEWER_MODES.DEFAULT,
    sectionResponses,
    setSectionResponseProp,
    saveSectionResponseProp,
}) => {
    const { title, description, options } = schema;

    const handleAnswerChange = (id, value) => {
        if (typeof setSectionResponseProp === 'function') {
            setSectionResponseProp(`${responsePath}.${id}`, value);
        }
    };

    const handleAnswerTypingEnd = (id, value) => {
        if (typeof saveSectionResponseProp === 'function') {
            saveSectionResponseProp(`${responsePath}.${id}`, value);
        }
    };

    return (
        <>
            <SectionTitle
                text={title.text}
                style={{
                    fontFamily: workbookSchema.textStyling.subtitle.fontFamily,
                    color: workbookSchema.textStyling.subtitle.color,
                }}
            />
            {description.show && (
                <SectionDescription
                    text={description.text}
                    style={{
                        fontFamily: workbookSchema.textStyling.body.fontFamily,
                        color: workbookSchema.textStyling.body.color,
                    }}
                />
            )}
            <TextsAndAnswers
                options={options}
                responses={sectionResponses}
                onChange={handleAnswerChange}
                onTypingEnd={handleAnswerTypingEnd}
                ariaLabel={title.text}
                mode={mode}
                style={{
                    fontFamily: workbookSchema.textStyling.body.fontFamily,
                    color: workbookSchema.textStyling.body.color,
                }}
            />
        </>
    );
};

export const FillInTheBlanksArtifact = ({ workbookSchema, schema, sectionResponses }) => {
    const { title, description, options } = schema;

    return (
        <>
            <SectionTitle
                text={title.text}
                style={{
                    fontFamily: workbookSchema.textStyling.subtitle.fontFamily,
                    color: workbookSchema.textStyling.subtitle.color,
                }}
            />
            {description.show && (
                <SectionDescription
                    text={description.text}
                    style={{
                        fontFamily: workbookSchema.textStyling.body.fontFamily,
                        color: workbookSchema.textStyling.body.color,
                    }}
                />
            )}
            <TextsAndAnswersArtifact
                options={options}
                responses={sectionResponses}
                ariaLabel={title.text}
                style={{
                    fontFamily: workbookSchema.textStyling.body.fontFamily,
                    color: workbookSchema.textStyling.body.color,
                }}
            />
        </>
    );
};

function TextsAndAnswers({ options, responses, ariaLabel, style, mode, onChange, onTypingEnd }) {
    const textInput = (response, option) => (
        <TextInputDebounce
            value={response || ''}
            onChange={
                mode === WORKBOOK_SECTION_VIEWER_MODES.READ_ONLY ? () => {} : (e) => onChange(option.id, e.target.value)
            }
            onTypingEnd={
                mode === WORKBOOK_SECTION_VIEWER_MODES.READ_ONLY ? () => {} : (text) => onTypingEnd(option.id, text)
            }
            readOnly={isSectionReadOnly(mode)}
            className="mb-0 w-full"
            inputClassName="custom-focus-outline"
            showSavingIcon={!isSectionReadOnly(mode)}
            style={{ height: '32px' }}
        />
    );

    return (
        <div
            className="w-full paragraph__md break-words leading-[38px] space-y-3"
            style={style}
            aria-label={ariaLabel || 'options'}>
            {options.map((option) => {
                if (option.type === FILL_IN_THE_BLANKS_SECTION.INPUT_TYPE) {
                    const response = responses?.[responsePath]?.[option.id];
                    return (
                        <div key={option.id} className="inline-block mx-1">
                            <div className="w-[200px] ">
                                {mode === WORKBOOK_SECTION_VIEWER_MODES.BUILDER ? (
                                    <PreviewSuggestionTooltip>{textInput(response, option)}</PreviewSuggestionTooltip>
                                ) : (
                                    textInput(response, option)
                                )}
                            </div>
                        </div>
                    );
                }
                return option.label;
            })}
        </div>
    );
}

function TextsAndAnswersArtifact({ options, responses, ariaLabel, style }) {
    return (
        <div
            className="w-full paragraph__md break-words leading-[38px] space-y-3"
            style={style}
            aria-label={ariaLabel || 'options'}>
            {options.map((option) => {
                if (option.type === FILL_IN_THE_BLANKS_SECTION.INPUT_TYPE) {
                    const response = responses?.[responsePath]?.[option.id];
                    return (
                        <div key={option.id} className="inline-block mx-1">
                            <span className="underline whitespace-pre">
                                {response || (
                                    <>
                                        {/* Empty answer */}
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </>
                                )}
                            </span>
                        </div>
                    );
                }
                return option.label;
            })}
        </div>
    );
}
