import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingSpinner from '@/system/LoadingSpinner';
import useQueryParameter from '@/util/useQueryParameter';
import { FROM_URL_QUERY_PARAM } from '@/util/constants';
import useGTM from '@/util/useGTM';
import { useAuth } from '@/auth/AuthProvider';

export default function ThinkificAuthCompleteFromModal() {
    const navigate = useNavigate();
    const location = useLocation();
    const fromUrl = useQueryParameter(FROM_URL_QUERY_PARAM);
    const gtm = useGTM();
    const { user, setOriginalSignin } = useAuth();

    useEffect(() => {
        gtm.pushSignin('creator', 'thinkific', user.id);
        setOriginalSignin('thinkific');
        navigate(`${fromUrl}${location.search}`, { replace: true });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <LoadingSpinner />;
}
