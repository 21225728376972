/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { removeSpaces, replaceAt, uppercaseFirstLetter } from '@/util/helpers';

const validKeys = ['Backspace', 'Delete', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

export const verifyCode = (code) => {
    let error = '';
    const valueWithoutSpaces = removeSpaces(code);
    if (!valueWithoutSpaces) {
        error = 'Code not provided';
    } else if (valueWithoutSpaces.length < 6) {
        error = 'Code is incomplete';
    }
    return error;
};

export default function ConfirmationCodeInput({ onKeyUp, errorMessage, setFocusOnInitialMount = true }) {
    const [digit0, setDigit0] = useState(' ');
    const digit0Ref = useRef(null);
    const [digit1, setDigit1] = useState(' ');
    const digit1Ref = useRef(null);
    const [digit2, setDigit2] = useState(' ');
    const digit2Ref = useRef(null);
    const [digit3, setDigit3] = useState(' ');
    const digit3Ref = useRef(null);
    const [digit4, setDigit4] = useState(' ');
    const digit4Ref = useRef(null);
    const [digit5, setDigit5] = useState(' ');
    const digit5Ref = useRef(null);
    const [allDigits, setAllDigits] = useState('      ');
    const inputClasses = classNames(
        'heading__md--medium caret-transparent text-center text-neutral-500 placeholder-neutral-300 border border-neutral-100 rounded-md px-2 py-2 w-full',
        { 'text-error-500 border-error-200': !!errorMessage },
    );
    const style = { width: '38px', height: '38px' };

    const updateAllDigits = (current, value) => {
        const position = current.name.split('_')[1];
        const newValue = replaceAt(allDigits, position, value);
        const newValueWithoutSpaces = removeSpaces(newValue);
        setAllDigits(newValue);
        if (typeof onKeyUp === 'function') onKeyUp(newValueWithoutSpaces);
    };

    // Needed to suppress React warnings and keep inputs as controlled inputs using only the onKeyUp event handler
    const handleChangeDigit = () => {};

    const handleKeyUp = (key, currentValue, setFunc, current, prev, next) => {
        if (!validKeys.includes(key)) {
            return;
        }
        if ((key === 'Backspace' || key === 'Delete') && (currentValue !== ' ' || !prev)) {
            updateAllDigits(current, ' ');
            setFunc(' ');
        } else if (/^[0-9\b]+$/.test(key)) {
            updateAllDigits(current, key);
            setFunc(key);
            if (next) next.focus();
        } else if (prev) {
            if (key === 'Backspace') prev.focus();
        }
    };

    const highlightInput = (event) => {
        // eslint-disable-next-line no-param-reassign
        event.target.selectionStart = 0;
        // eslint-disable-next-line no-param-reassign
        event.target.selectionEnd = 1;
    };

    useEffect(() => {
        if (setFocusOnInitialMount) digit0Ref.current.focus();
    }, []);

    return (
        <>
            <label className="heading__xs--medium mb-1.5">Enter verification code</label>
            <div className="flex justify-between">
                <input
                    type="text"
                    className={inputClasses}
                    style={style}
                    onFocus={highlightInput}
                    onClick={highlightInput}
                    maxLength={1}
                    value={digit0}
                    name="digit_0"
                    ref={digit0Ref}
                    onChange={handleChangeDigit}
                    onKeyUp={(e) => handleKeyUp(e.key, digit0, setDigit0, digit0Ref.current, null, digit1Ref.current)}
                />
                <input
                    type="text"
                    className={inputClasses}
                    style={style}
                    onFocus={highlightInput}
                    onClick={highlightInput}
                    maxLength={1}
                    value={digit1}
                    name="digit_1"
                    ref={digit1Ref}
                    onChange={handleChangeDigit}
                    onKeyUp={(e) =>
                        handleKeyUp(e.key, digit1, setDigit1, digit1Ref.current, digit0Ref.current, digit2Ref.current)
                    }
                />
                <input
                    type="text"
                    className={inputClasses}
                    style={style}
                    onFocus={highlightInput}
                    onClick={highlightInput}
                    maxLength={1}
                    value={digit2}
                    name="digit_2"
                    ref={digit2Ref}
                    onChange={handleChangeDigit}
                    onKeyUp={(e) =>
                        handleKeyUp(e.key, digit2, setDigit2, digit2Ref.current, digit1Ref.current, digit3Ref.current)
                    }
                />
                <input
                    type="text"
                    className={inputClasses}
                    style={style}
                    onFocus={highlightInput}
                    onClick={highlightInput}
                    maxLength={1}
                    value={digit3}
                    name="digit_3"
                    ref={digit3Ref}
                    onChange={handleChangeDigit}
                    onKeyUp={(e) =>
                        handleKeyUp(e.key, digit3, setDigit3, digit3Ref.current, digit2Ref.current, digit4Ref.current)
                    }
                />
                <input
                    type="text"
                    className={inputClasses}
                    style={style}
                    onFocus={highlightInput}
                    onClick={highlightInput}
                    maxLength={1}
                    value={digit4}
                    name="digit_4"
                    ref={digit4Ref}
                    onChange={handleChangeDigit}
                    onKeyUp={(e) =>
                        handleKeyUp(e.key, digit4, setDigit4, digit4Ref.current, digit3Ref.current, digit5Ref.current)
                    }
                />
                <input
                    type="text"
                    className={inputClasses}
                    style={style}
                    onFocus={highlightInput}
                    onClick={highlightInput}
                    maxLength={1}
                    value={digit5}
                    name="digit_5"
                    ref={digit5Ref}
                    onChange={handleChangeDigit}
                    onKeyUp={(e) => handleKeyUp(e.key, digit5, setDigit5, digit5Ref.current, digit4Ref.current)}
                />
            </div>
            {!!errorMessage && <div className="caption mt-1 text-error-200">{uppercaseFirstLetter(errorMessage)}</div>}
        </>
    );
}
