import { createContext, useContext, useEffect, useRef, useState } from 'react';
import merge from 'lodash/merge';
import useWorkbookVersionReducer, { ACTIONS } from '@/workbook/useWorkbookVersionReducer';
import { getDefaultCreatorSchema } from '@/navigation/useRolesReducer';
import { workbookVersionFromDto, moduleVersionFromDto, moduleResponseFromDto, addOnsFromDto } from '@/api/dtos';
import { fetchJsonFile, getStorageObjectBasePath, getWorkbookUploadsStorageObjectBasePath } from '@/util/helpers';
import useQueryParameter from '@/util/useQueryParameter';

const WorkbookVersionContext = createContext();

export function useWorkbookVersion() {
    return useContext(WorkbookVersionContext);
}

export default function WorkbookVersionJsonLoaderProvider({ children }) {
    const isMounted = useRef(true);
    const [workbookVersion, setWorkbookVersion] = useWorkbookVersionReducer();
    const [student, setStudent] = useState({});
    const [loading, setLoading] = useState(true);
    const [loadingError, setLoadingError] = useState('');
    const [creatorSchema, setCreatorSchema] = useState(getDefaultCreatorSchema());
    const [addOns, setAddOns] = useState({});
    const jsonUrl = useQueryParameter('json_url');

    const loadWorkbookVersion = async () => {
        const payload = {
            studentId: '',
            workbookVersion: {},
            modules: [],
            moduleResponses: [],
        };
        try {
            const workbookVersionJson = await fetchJsonFile(jsonUrl);
            const workbookVersionDto = workbookVersionJson.workbookVersion;
            payload.workbookVersion = workbookVersionFromDto(workbookVersionDto);

            const moduleResponseDtos = workbookVersionJson.moduleResponses;
            if (moduleResponseDtos) {
                payload.moduleResponses = moduleResponseDtos.map((dto) => moduleResponseFromDto(dto));
            }
            const moduleVersionDtos = workbookVersionJson.moduleVersions;
            payload.modules = moduleVersionDtos.map((dto) => moduleVersionFromDto(dto));
            payload.creatorSchema = merge(creatorSchema, workbookVersionJson.creatorSchema);
            payload.studentId = workbookVersionJson.studentId;
            payload.addOns = addOnsFromDto(workbookVersionJson.addOns);
            return payload;
        } catch (err) {
            console.error(err);
            throw new Error('Could not load workbook');
        }
    };

    useEffect(() => {
        isMounted.current = true;
        if (isMounted.current) {
            setLoading(true);
            loadWorkbookVersion()
                .then((response) => {
                    const cohortId =
                        response.workbookVersion.cohortIds && response.workbookVersion.cohortIds.length > 0
                            ? response.workbookVersion.cohortIds[0]
                            : '';
                    const payload = {
                        ...response,
                        workbookVersion: {
                            ...response.workbookVersion,
                            schema: {
                                ...response.workbookVersion.schema,
                                storageObjectBasePath: getStorageObjectBasePath(
                                    response.workbookVersion.creatorId,
                                    response.workbookVersion.workbookId,
                                    response.workbookVersion.id,
                                ),
                                workbookUploadsStorageObjectBasePath: getWorkbookUploadsStorageObjectBasePath(
                                    response.workbookVersion.creatorId,
                                    response.workbookVersion.workbookId,
                                ),
                            },
                        },
                        cohortId,
                        creatorId: response.workbookVersion.creatorId,
                    };
                    if (isMounted.current) {
                        setWorkbookVersion({ type: ACTIONS.SET, payload });
                        setStudent({ id: response.studentId });
                        setCreatorSchema(response.creatorSchema);
                        setAddOns(response.addOns);
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    if (isMounted.current) {
                        setLoadingError(error.message);
                        setLoading(false);
                    }
                });
        }

        return () => {
            isMounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const context = {
        workbookVersion,
        loading,
        loadingError,
        creatorSchema,
        addOns,
        student,
    };

    return <WorkbookVersionContext.Provider value={context}>{children}</WorkbookVersionContext.Provider>;
}
