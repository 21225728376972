import { Fragment, useState, useEffect } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import classNames from 'classnames';

export default function SelectList({
    value,
    options,
    onChange,
    placeholder,
    className = '',
    relativeOptionsPositioning = false,
    readOnly = false,
}) {
    const [optionsDictionary, setOptionsDictionary] = useState({});

    useEffect(() => {
        const dictionary = {};
        if (Array.isArray(options)) {
            options.forEach((option) => {
                dictionary[option.value] = option.label;
            });
        }
        setOptionsDictionary(dictionary);
    }, [options]);

    if (!Array.isArray(options) || options.length === 0) return 'At least 1 option required for SelectList';

    return (
        <div className={classNames('relative', className)}>
            <Listbox value={value} onChange={typeof onChange === 'function' ? onChange : () => {}} disabled={readOnly}>
                <Listbox.Button className="paragraph__md relative w-full cursor-default rounded-md border border-neutral-100 bg-white py-2 pl-3 pr-10 text-left text-neutral-500 focus:outline-none focus-visible:border-2 focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300">
                    {({ open }) => {
                        return (
                            <>
                                <span className="block truncate">
                                    {Object.keys(optionsDictionary) === 0
                                        ? ''
                                        : optionsDictionary[value] || placeholder || 'Choose an option'}
                                </span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 text-neutral-500">
                                    {open ? (
                                        <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
                                    ) : (
                                        <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                                    )}
                                </span>
                            </>
                        );
                    }}
                </Listbox.Button>
                <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <Listbox.Options
                        className={classNames(
                            'select-list-show-scrollbars z-10 mt-2.25 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
                            { absolute: !relativeOptionsPositioning, relative: relativeOptionsPositioning },
                        )}>
                        {options.map((option) => (
                            <Listbox.Option
                                key={option.id}
                                value={option.value}
                                disabled={option.disabled}
                                className={({ active }) =>
                                    classNames(
                                        'paragraph__md relative cursor-default select-none p-3.25 text-neutral-500',
                                        { 'bg-neutral-50': active },
                                    )
                                }>
                                {({ selected }) => {
                                    return (
                                        <div className={classNames({ 'font-semibold text-primary-500': selected })}>
                                            {option.label}
                                        </div>
                                    );
                                }}
                            </Listbox.Option>
                        ))}
                    </Listbox.Options>
                </Transition>
            </Listbox>
        </div>
    );
}
