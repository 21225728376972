import { createContext, useContext, useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { addDays } from 'date-fns';
import get from 'lodash/get';
import { useAuth } from '@/auth/AuthProvider';
import { useEmbed } from '@/embed/EmbedProvider';
import LoadingSpinner from '@/system/LoadingSpinner';
import TechnicalError from '@/system/TechnicalError';
import EmbedTechnicalError from '@/embed/EmbedTechnicalError';
import { isStudentRoute, isCreatorRoute, isMemberRoute, dateWithoutTime, deepCopy } from '@/util/helpers';
import {
    ROLES,
    ROUTES,
    ROLE_QUERY_PARAM,
    EMBED_CONTEXTS,
    TRIAL_DAYS,
    PLANS,
    CREATOR_ID_QUERY_PARAM,
} from '@/util/constants';
import useQueryParameter from '@/util/useQueryParameter';
import useRolesReducer, { ACTIONS, getDefaultCreatorSchema } from '@/navigation/useRolesReducer';
import useRolesApi from '@/api/useRolesApi';
import {
    creatorFromDto,
    studentFromDto,
    studentCreatorFromDto,
    memberFromDto,
    memberCreatorFromDto,
    adminFromDto,
} from '@/api/dtos';
import CreatorSelector from '@/navigation/CreatorSelector';
import useSessionCache, { SESSION_CACHE_KEYS } from '@/util/useSessionCache';
import useGetSelectedCreatorIdFromSso from '@/navigation/useGetSelectedCreatorIdFromSso';
import { useToast } from '@/system/ToastProvider';

const RoleSelectorContext = createContext();

export function useRoles() {
    return useContext(RoleSelectorContext);
}

const isWrongRoute = (selectedRole, route, forcedRoute) => {
    if (route.includes('/student/public-enroll/')) {
        return false;
    }

    return (
        ((selectedRole === ROLES.STUDENT || forcedRoute === ROLES.STUDENT) && !isStudentRoute(route)) ||
        ((selectedRole === ROLES.CREATOR || forcedRoute === ROLES.CREATOR) && !isCreatorRoute(route)) ||
        ((selectedRole === ROLES.MEMBER || forcedRoute === ROLES.MEMBER) && !isMemberRoute(route))
    );
};

const getDefaultRoute = (selectedRole) => {
    switch (selectedRole) {
        case ROLES.STUDENT:
            return ROUTES.STUDENT.DASHBOARD;
        case ROLES.MEMBER:
            return ROUTES.MEMBER.DASHBOARD;
        default:
            return ROUTES.CREATOR.WORKBOOKS_DASHBOARD;
    }
};

const studentOnlyEmbedContexts = [EMBED_CONTEXTS.WORKBOOK, EMBED_CONTEXTS.WORKBOOK_MODULE];

export default function RoleSelectorProvider({ children }) {
    const [authCheck, setAuthCheck] = useState(false);
    const [error, setError] = useState(null);
    const { user, authenticated, checkAuthentication } = useAuth();
    const { embedded, loadingTokens, context: embedContext } = useEmbed();
    const queryParamRole = useQueryParameter(ROLE_QUERY_PARAM);
    const queryParamCreatorId = useQueryParameter(CREATOR_ID_QUERY_PARAM);
    const forcedRole = useQueryParameter('forced_role');
    const location = useLocation();
    const [state, setState] = useRolesReducer();
    const [loading, setLoading] = useState(true);
    const {
        getCreator,
        updateCreatorTrial,
        updateCreatorCompany,
        upsertCreatorSchema,
        getStudent,
        getStudentCreator,
        getMember,
        getMemberCreator,
        addPublicWorkbook,
        removePublicWorkbook,
        getAdmin,
        getCreatorAnalyticsFeedback,
        getMemberAnalyticsFeedback,
    } = useRolesApi();
    const {
        ready: selectedRoleCacheReady,
        getItemValue: getCachedSelectedRole,
        setItemValue: setCachedSelectedRole,
    } = useSessionCache(SESSION_CACHE_KEYS.SELECTED_ROLE);
    const {
        ready: selectedCreatorIdCacheReady,
        getItemValue: getCachedSelectedCreatorId,
        setItemValue: setCachedSelectedCreatorId,
    } = useSessionCache(SESSION_CACHE_KEYS.SELECTED_CREATOR_ID);
    const navigate = useNavigate();
    const getSelectedCreatorIdFromSso = useGetSelectedCreatorIdFromSso();
    const { pushError } = useToast();
    const isSelectedRoleCreator = state.selectedRole === ROLES.CREATOR;

    const triggerRoleSelect = () => {
        setState({
            type: ACTIONS.SET_SELECTED_ROLE,
            payload: {
                selectedRole: ROLES.NOT_SELECTED,
                selectedCreator: null,
            },
        });
    };

    const setPaymentInfo = (paymentInfo) => {
        setState({
            type: ACTIONS.SET_CREATOR,
            payload: {
                ...paymentInfo,
            },
        });
    };

    const setCancelDate = (paymentCancelEndDate) => {
        setState({
            type: ACTIONS.SET_CREATOR,
            payload: {
                paymentCancelEndDate,
            },
        });
    };

    const setPaymentStudentImageUploadAddOn = (enabled) => {
        setState({
            type: ACTIONS.SET_CREATOR,
            payload: {
                addOns: {
                    ...state.creator.addOns,
                    studentImageUpload: enabled,
                },
            },
        });
    };

    const updateTrial = () => {
        if (!isSelectedRoleCreator) {
            pushError('You must be a creator to update trial info');
            return;
        }
        const today = dateWithoutTime(new Date());
        const trialEndDate = state.creator.trialEndDate || addDays(today, TRIAL_DAYS);
        updateCreatorTrial(state.creator.id, PLANS.TRIAL, trialEndDate)
            .then(() => {
                setState({
                    type: ACTIONS.SET_CREATOR,
                    payload: {
                        plan: PLANS.TRIAL,
                        trialEndDate,
                        addOns: {
                            studentImageUpload: today.getTime() < trialEndDate.getTime(),
                        },
                    },
                });
            })
            .catch(() => {});
    };

    const updateCompany = async (companyName) => {
        return updateCreatorCompany(state.creator.id, companyName).then(() => {
            setState({
                type: ACTIONS.SET_CREATOR,
                payload: { companyName },
            });
        });
    };

    const makeWorkbookPublic = async (workbookId) => {
        if (!isSelectedRoleCreator) {
            return Promise.reject(new Error('You must be a creator to make a workbook public'));
        }
        return addPublicWorkbook(state.selectedCreator.id, workbookId).then(() => {
            setState({
                type: ACTIONS.ADD_CREATOR_PUBLIC_WORKBOOK,
                payload: workbookId,
            });
        });
    };

    const makeWorkbookPrivate = async (workbookId) => {
        if (!isSelectedRoleCreator) {
            return Promise.reject(new Error('You must be a creator to make a workbook private'));
        }
        return removePublicWorkbook(state.selectedCreator.id, workbookId).then(() => {
            setState({
                type: ACTIONS.REMOVE_CREATOR_PUBLIC_WORKBOOK,
                payload: workbookId,
            });
        });
    };

    const triggerStudentCreatorSelect = () => {
        if (state.selectedRole === ROLES.STUDENT) {
            setState({
                type: ACTIONS.SET_SELECTED_CREATOR,
                payload: null,
            });
        }
    };

    const loadStudentCreator = async (creatorId) => {
        return getStudentCreator(user.id, creatorId).then((dto) => {
            const creator = {
                id: creatorId,
                ...studentCreatorFromDto(dto),
            };

            return creator;
        });
    };

    const loadMemberCreator = async (creatorId) => {
        return getMemberCreator(user.id, creatorId).then((dto) => ({
            id: creatorId,
            ...memberCreatorFromDto(dto),
        }));
    };

    const handleStudentCreatorSelect = (creatorId) => {
        setLoading(true);
        loadStudentCreator(creatorId)
            .then((creator) => {
                setState({
                    type: ACTIONS.SET_SELECTED_CREATOR,
                    payload: creator,
                });
                setCachedSelectedCreatorId(creator.id);
            })
            .finally(() => setLoading(false));
    };

    const loadRoles = async (isCreator, isStudent, isMember, isAdmin) => {
        const rolePromises = [Promise.resolve(), Promise.resolve(), Promise.resolve()];
        if (isCreator) rolePromises[0] = getCreator(user.id).then((dto) => creatorFromDto(dto));
        if (isStudent) rolePromises[1] = getStudent(user.id).then((dto) => studentFromDto(dto));
        if (isMember) rolePromises[2] = getMember(user.id).then((dto) => memberFromDto(dto));
        if (isAdmin) rolePromises[3] = getAdmin(user.id).then((dto) => adminFromDto(dto));
        const roles = await Promise.all(rolePromises)
            .then((res) => {
                const rolesResponse = { creator: null, student: null, member: null, admin: null };
                /* eslint-disable prefer-destructuring */
                if (isCreator) rolesResponse.creator = res[0];
                if (isStudent) rolesResponse.student = res[1];
                if (isMember) rolesResponse.member = res[2];
                if (isAdmin) rolesResponse.admin = res[3];
                /* eslint-enable prefer-destructuring */
                return rolesResponse;
            })
            .catch((err) => {
                const fullError = `error getting roles: ${err.message}`;
                throw new Error(fullError);
            });
        if (!isCreator && !isMember && isStudent && roles.student.totalCreators === 0) {
            return roles;
        }
        if (!isCreator && !isStudent && isMember && roles.member.totalCreators === 0) {
            // TODO implement member changes: define what to do in this case
            throw new Error('You have no memberships associated. Please contact Wobo team.');
        }
        return roles;
    };

    const getSelectedRole = (roles, isCreator, isStudent, isMember) => {
        let selectedRole;
        if (forcedRole) {
            selectedRole = forcedRole;
        } else if (embedded && (!embedContext || studentOnlyEmbedContexts.includes(embedContext)) && isStudent) {
            selectedRole = ROLES.STUDENT;
        } else if (queryParamRole && queryParamRole === ROLES.CREATOR && isCreator) {
            selectedRole = ROLES.CREATOR;
        } else if (queryParamRole && queryParamRole === ROLES.MEMBER && isMember) {
            selectedRole = ROLES.MEMBER;
        } else if (queryParamRole && queryParamRole === ROLES.STUDENT && isStudent) {
            selectedRole = ROLES.STUDENT;
        } else {
            selectedRole = getCachedSelectedRole();
            if (selectedRole === null) {
                if (isCreator) {
                    selectedRole = ROLES.CREATOR;
                } else if (isMember) {
                    selectedRole = ROLES.MEMBER;
                } else {
                    selectedRole = ROLES.STUDENT;
                }
            }
        }
        if (selectedRole === ROLES.MEMBER && roles.member.totalCreators === 0) {
            if (isCreator) {
                selectedRole = ROLES.CREATOR;
            } else {
                selectedRole = ROLES.STUDENT;
            }
        }
        return selectedRole;
    };

    const getSelectedCreator = async (roles, selectedRole) => {
        let selectedCreator = null;
        switch (selectedRole) {
            case ROLES.CREATOR:
                selectedCreator = roles.creator;
                break;
            case ROLES.MEMBER: {
                let selectedCreatorId;
                if (roles.member.totalCreators === 1) {
                    const memberCreatorIds = Object.keys(roles.member.creators);
                    [selectedCreatorId] = memberCreatorIds;
                } else {
                    selectedCreatorId = getSelectedCreatorIdFromSso(roles.member);
                    if (!selectedCreatorId) {
                        const memberCreatorIds = Object.keys(roles.member.creators);
                        if (queryParamCreatorId && memberCreatorIds.includes(queryParamCreatorId)) {
                            selectedCreatorId = queryParamCreatorId;
                        } else {
                            selectedCreatorId = getCachedSelectedCreatorId();
                            selectedCreatorId = selectedCreatorId || memberCreatorIds[0];
                        }
                    }
                }
                selectedCreator = await loadMemberCreator(selectedCreatorId);
                break;
            }
            case ROLES.STUDENT:
            default: {
                const studentCreatorIds = Object.keys(roles.student.creators);
                let selectedCreatorId;
                if (studentCreatorIds.length === 1) {
                    [selectedCreatorId] = studentCreatorIds;
                } else if (queryParamCreatorId && studentCreatorIds.includes(queryParamCreatorId)) {
                    selectedCreatorId = queryParamCreatorId;
                } else {
                    selectedCreatorId = getCachedSelectedCreatorId();
                    selectedCreatorId = selectedCreatorId || studentCreatorIds[0];
                }
                if (selectedCreatorId !== null && selectedCreatorId !== undefined) {
                    selectedCreator = await loadStudentCreator(selectedCreatorId);
                } else {
                    selectedCreator = {
                        companyName: '',
                        schema: getDefaultCreatorSchema(),
                        enrollments: [],
                        addOns: { studentImageUpload: false },
                    };
                }
                break;
            }
        }
        return selectedCreator;
    };

    // eslint-disable-next-line
    const loadAnalytics = async (isMember) => {
        let results = null;
        try {
            const sixMonthsAgo = dateWithoutTime(new Date());
            sixMonthsAgo.setDate(sixMonthsAgo.getDate() - 180);
            const today = dateWithoutTime(new Date());
            const res = isMember
                ? await getMemberAnalyticsFeedback(user.id, sixMonthsAgo, today)
                : await getCreatorAnalyticsFeedback(user.id, sixMonthsAgo, today);
            if (res.status === 'ok' && res.results.events.length > 0) {
                results = res.results;
            }
        } catch (err) {
            console.error(err);
        }
        setState({
            type: ACTIONS.SET_ANALYTICS,
            payload: results,
        });
    };

    const setInitialState = async () => {
        if (user.roles.length === 0) {
            throw new Error('Please contact the Wobo team. Error: role not set');
        } else {
            const isCreator = user.roles.includes(ROLES.CREATOR);
            const isStudent = user.roles.includes(ROLES.STUDENT);
            const isMember = user.roles.includes(ROLES.MEMBER);
            const isAdmin = user.roles.includes(ROLES.ADMIN);
            const roles = await loadRoles(isCreator, isStudent, isMember, isAdmin);
            const selectedRole = getSelectedRole(roles, isCreator, isStudent, isMember);
            const selectedCreator = await getSelectedCreator(roles, selectedRole);

            // disabled analytics to workaround https://linear.app/course-studio/issue/WO-1806
            // TODO: Find a way to load MixPanel data without being rate-limited by their API
            // loadAnalytics(!isCreator && isMember);
            setState({
                type: ACTIONS.INIT,
                payload: {
                    ...roles,
                    selectedRole,
                    selectedCreator,
                },
            });
        }
    };

    const setCurrentRole = async (role, creatorId) => {
        if (
            ((role === ROLES.CREATOR || role === ROLES.STUDENT) && role !== state.selectedRole) ||
            (role === ROLES.MEMBER && role + creatorId !== state.selectedRole + state.selectedCreator.id) // Make sure to switch when the student's creator is the same as the member's creator
        ) {
            setLoading(true);
            let selectedCreator = null;
            if (role === ROLES.CREATOR) {
                selectedCreator = state.creator;
            } else if (role === ROLES.STUDENT) {
                selectedCreator = await loadStudentCreator(Object.keys(state.student.creators)[0]);
            } else if (role === ROLES.MEMBER) {
                selectedCreator = await loadMemberCreator(creatorId || Object.keys(state.member.creators)[0]);
            }
            setCachedSelectedRole(role);
            setCachedSelectedCreatorId(selectedCreator.id);
            setState({
                type: ACTIONS.SET_SELECTED_ROLE,
                payload: {
                    selectedRole: role,
                    selectedCreator,
                },
            });
            navigate(getDefaultRoute(role), { from: location });
            setLoading(false);
        }
    };

    const setCreatorSchemaProp = (path, value) => {
        if (!isSelectedRoleCreator) {
            pushError('You must be a creator to set a creator schema prop');
            return;
        }
        setState({ type: ACTIONS.UPDATE_CREATOR_SCHEMA_PROP, payload: { path, value } });
    };

    const saveCreatorSchemaProp = async (path, value) => {
        if (!isSelectedRoleCreator) {
            return Promise.reject(new Error('You must be a creator to save a creator schema prop'));
        }
        const previousValue = deepCopy(get(state.creator.schema, path));
        setState({ type: ACTIONS.UPDATE_CREATOR_SCHEMA_PROP, payload: { path, value } });
        return upsertCreatorSchema(state.creator.id, path, value).catch(() => {
            setState({ type: ACTIONS.UPDATE_CREATOR_SCHEMA_PROP, payload: { path, previousValue } });
        });
    };

    const addRecentlyUsedSection = (eventName) => {
        setState({ type: ACTIONS.ADD_RECENTLY_USED_SECTION, payload: eventName });
    };

    useEffect(() => {
        if (authenticated && selectedRoleCacheReady && selectedCreatorIdCacheReady) {
            setInitialState()
                .catch((err) => setError(err))
                .finally(() => setLoading(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authenticated, selectedRoleCacheReady, selectedCreatorIdCacheReady]);

    useEffect(() => {
        if (!embedded || (embedded && !loadingTokens)) {
            checkAuthentication().then(() => {
                setAuthCheck(true);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [embedded, loadingTokens]);

    if (error)
        return embedded ? (
            <EmbedTechnicalError title="Oops, something went wrong!" desc={error.message} />
        ) : (
            <TechnicalError title="Oops, something went wrong!" desc={error.message} />
        );
    if (authCheck && !authenticated) {
        return (
            <Navigate to={!embedded ? ROUTES.CREATOR.LOGIN : ROUTES.COMMON.EMBED_LOGIN} state={{ from: location }} />
        );
    }
    if (
        !authCheck ||
        loading ||
        state.selectedRole === null ||
        (authenticated && (!selectedRoleCacheReady || !selectedCreatorIdCacheReady))
    )
        return <LoadingSpinner />;
    if (isWrongRoute(state.selectedRole, location.pathname, forcedRole)) {
        if (embedded) return <EmbedTechnicalError title="Your user role doesn't match the embedded route" />;
        return <Navigate to={getDefaultRoute(state.selectedRole)} state={{ from: location }} />;
    }
    if (state.selectedRole === ROLES.STUDENT && !state.selectedCreator && state.student.totalCreators > 1)
        return (
            <CreatorSelector
                creatorOptions={Object.keys(state.student.creators).map((creatorId) => ({
                    id: creatorId,
                    label: state.student.creators[creatorId].companyName || creatorId,
                }))}
                onSelect={handleStudentCreatorSelect}
            />
        );

    // We protect against ourselves: admin options should only be shown to the creator role.
    const isAdmin = state.selectedRole === ROLES.CREATOR && user.roles.includes(ROLES.ADMIN);

    const context = {
        roles: user.roles,
        currentRole: state.selectedRole,
        creator: state.creator,
        student: state.student,
        member: state.member,
        admin: state.admin,
        selectedCreator: state.selectedCreator,
        isAdmin,
        analytics: state.analytics,
        setCurrentRole,
        triggerStudentCreatorSelect,
        triggerRoleSelect,
        setPaymentInfo,
        setCancelDate,
        setPaymentStudentImageUploadAddOn,
        updateTrial,
        updateCompany,
        setCreatorSchemaProp,
        saveCreatorSchemaProp,
        makeWorkbookPublic,
        makeWorkbookPrivate,
        addRecentlyUsedSection,
    };

    return <RoleSelectorContext.Provider value={context}>{children}</RoleSelectorContext.Provider>;
}
