import { useReducer } from 'react';
import set from 'lodash/set';
import { deepCopy, getIdsDictionary, getModuleIdsDictionary } from '@/util/helpers';
import { getDefaultSchema } from '@/workbook/useWorkbookReducer';

export const ACTIONS = {
    SET: 'set',
    SELECT_MODULE: 'select_module',
    SELECT_PREV_MODULE: 'select_prev_module',
    SELECT_NEXT_MODULE: 'select_next_module',
    SELECT_SECTION: 'select_section',
    UPDATE_SECTION_RESPONSE_PROP: 'update_section_response_prop',
    ADD_COMMENT: 'add_comment',
    UPDATE_COMMENT: 'update_comment',
    DELETE_COMMENT: 'delete_comment',
};

export const getModuleVersionAndResponse = (workbookVersion, moduleVersionId) => {
    const moduleVersionIndex = workbookVersion.moduleIdsDictionary[moduleVersionId];
    const moduleVersion = { ...workbookVersion.modules[moduleVersionIndex] };
    const moduleResponseIndex = workbookVersion.moduleResponsesByModuleIdDictionary[moduleVersion.moduleId];
    const moduleResponse = { ...workbookVersion.moduleResponses[moduleResponseIndex] };

    return {
        module: moduleVersion,
        moduleIndex: moduleVersionIndex,
        moduleResponse,
        moduleResponseIndex,
    };
};

const getSelectedModuleVersion = (modules, moduleId) => {
    const coverPageSchema = modules[0]?.sections[0]?.schema;
    const hideCoverPageModule = coverPageSchema?.hidden ?? false;

    // Return next module if cover page is hidden and is not a module embed link
    if (hideCoverPageModule && !moduleId?.length) {
        return modules.length ? modules[1] : null;
    }

    const moduleIdsDictionary = getModuleIdsDictionary(modules);
    const moduleIndex = moduleIdsDictionary[moduleId];
    return modules.length ? modules[moduleIndex] || modules[0] : null;
};

export const getPrevModule = (workbookVersion) => {
    const moduleIndex = workbookVersion.moduleIdsDictionary[workbookVersion.selectedModule.id];
    const prevModuleIndex = moduleIndex - 1 >= 0 ? moduleIndex - 1 : null;
    if (prevModuleIndex !== null) {
        return { ...workbookVersion.modules[prevModuleIndex] };
    }
    return null;
};

export const getNextModule = (workbookVersion) => {
    const moduleIndex = workbookVersion.moduleIdsDictionary[workbookVersion.selectedModule.id];
    const nextModuleIndex = workbookVersion.modules.length > moduleIndex + 1 ? moduleIndex + 1 : null;
    if (nextModuleIndex !== null) {
        return { ...workbookVersion.modules[nextModuleIndex] };
    }
    return null;
};

export const getDefaultState = () => {
    return {
        initialized: false,
        id: '',
        name: '',
        description: '',
        schema: getDefaultSchema(),
        moduleIdsDictionary: {},
        modules: [],
        selectedModule: null,
        sectionIdsDictionary: {},
        selectedSection: null,
        workbookId: '',
        cohortId: '',
        moduleResponses: [],
        selectedModuleResponse: null,
        moduleResponseIdsDictionary: {},
        moduleResponsesByModuleIdDictionary: {},
    };
};

export const workbookVersionReducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.SET: {
            const {
                workbookVersion,
                modules: moduleVersions,
                cohortId,
                moduleResponses,
                moduleId,
                comments,
                commentingUsers,
            } = action.payload;
            const moduleResponsesByModuleIdDictionary = getModuleIdsDictionary(moduleResponses);
            const selectedModuleVersion = getSelectedModuleVersion(moduleVersions, moduleId);
            const moduleVersionIdsDictionary = getIdsDictionary(moduleVersions);
            const newState = {
                initialized: true,
                id: workbookVersion.id,
                name: workbookVersion.name,
                description: workbookVersion.description,
                schema: workbookVersion.schema,
                moduleIdsDictionary: moduleVersionIdsDictionary, // The property doesn't match the variable name because we need our components to work for both creator and student
                modules: moduleVersions, // The property doesn't match the variable name because we need our components to work for both creator and student
                selectedModule: selectedModuleVersion, // The property doesn't match the variable name because we need our components to work for both creator and student
                sectionIdsDictionary: getIdsDictionary(selectedModuleVersion ? selectedModuleVersion.sections : []),
                selectedSection:
                    selectedModuleVersion && selectedModuleVersion.sections.length
                        ? selectedModuleVersion.sections[0]
                        : null,
                workbookId: workbookVersion.workbookId || '',
                cohortId: cohortId || '',
                moduleResponses,
                moduleResponseIdsDictionary: getIdsDictionary(moduleResponses),
                moduleResponsesByModuleIdDictionary,
                commentingEnabled: workbookVersion.commentingEnabled,
                commentsBySectionIdDictionary: (comments || []).reduce((result, comment) => {
                    const temp = { ...result };
                    (temp[comment.section_id] || (temp[comment.section_id] = [])).push(comment);
                    return temp;
                }, {}),
                commentingUsers,
            };
            if (selectedModuleVersion !== null) {
                const { moduleResponse: selectedModuleResponse } = getModuleVersionAndResponse(
                    newState,
                    selectedModuleVersion.id,
                );
                newState.selectedModuleResponse = selectedModuleResponse;
            } else {
                newState.selectedModuleResponse = null;
            }
            return newState;
        }
        case ACTIONS.SELECT_MODULE: {
            const moduleVersionId = action.payload;
            const {
                module: selectedModule,
                moduleResponse: selectedModuleResponse,
                moduleResponseIndex,
            } = getModuleVersionAndResponse(state, moduleVersionId);
            const moduleResponses = deepCopy(state.moduleResponses);
            if (selectedModuleResponse) {
                selectedModuleResponse.opened = true;
                moduleResponses[moduleResponseIndex].opened = true;
            }

            return {
                ...state,
                selectedModule,
                sectionIdsDictionary: getIdsDictionary(selectedModule.sections),
                selectedSection: selectedModule.sections.length ? selectedModule.sections[0] : null,
                selectedModuleResponse: selectedModuleResponse || null,
                moduleResponses,
            };
        }
        case ACTIONS.SELECT_PREV_MODULE: {
            const prevModule = getPrevModule(state);
            if (prevModule === null) return state;
            const { moduleResponse: selectedModuleResponse, moduleResponseIndex } = getModuleVersionAndResponse(
                state,
                prevModule.id,
            );
            const moduleResponses = deepCopy(state.moduleResponses);
            if (selectedModuleResponse) {
                selectedModuleResponse.opened = true;
                moduleResponses[moduleResponseIndex].opened = true;
            }
            return {
                ...state,
                selectedModule: prevModule,
                sectionIdsDictionary: getIdsDictionary(prevModule.sections),
                selectedSection: prevModule.sections.length ? prevModule.sections[0] : null,
                selectedModuleResponse: selectedModuleResponse || null,
                moduleResponses,
            };
        }
        case ACTIONS.SELECT_NEXT_MODULE: {
            const nextModule = getNextModule(state);
            if (nextModule === null) return state;
            const { moduleResponse: selectedModuleResponse, moduleResponseIndex } = getModuleVersionAndResponse(
                state,
                nextModule.id,
            );
            const moduleResponses = deepCopy(state.moduleResponses);
            if (selectedModuleResponse) {
                selectedModuleResponse.opened = true;
                moduleResponses[moduleResponseIndex].opened = true;
            }
            return {
                ...state,
                selectedModule: nextModule,
                sectionIdsDictionary: getIdsDictionary(nextModule.sections),
                selectedSection: nextModule.sections.length ? nextModule.sections[0] : null,
                selectedModuleResponse: selectedModuleResponse || null,
                moduleResponses,
            };
        }
        case ACTIONS.SELECT_SECTION: {
            const sectionId = action.payload;
            const selectedSection = state.selectedModule.sections[state.sectionIdsDictionary[sectionId]];
            return {
                ...state,
                selectedSection,
            };
        }
        case ACTIONS.UPDATE_SECTION_RESPONSE_PROP: {
            const { sectionId, path, value } = action.payload;
            const sectionResponses = deepCopy(state.selectedModuleResponse.sectionResponses);
            set(sectionResponses, `${sectionId}.${path}`, value);
            const moduleResponses = deepCopy(state.moduleResponses);
            const moduleResponsesIndex = state.moduleResponseIdsDictionary[state.selectedModuleResponse.id];
            moduleResponses[moduleResponsesIndex].sectionResponses = sectionResponses;
            const selectedModuleResponse = deepCopy(state.selectedModuleResponse);
            selectedModuleResponse.sectionResponses = sectionResponses;

            return {
                ...state,
                moduleResponses,
                selectedModuleResponse,
            };
        }
        case ACTIONS.ADD_COMMENT: {
            const { section_id: sectionId } = action.payload;
            const commentsBySectionIdDictionary = deepCopy(state.commentsBySectionIdDictionary);
            (commentsBySectionIdDictionary[sectionId] || (commentsBySectionIdDictionary[sectionId] = [])).push(
                action.payload,
            );
            return {
                ...state,
                commentsBySectionIdDictionary,
            };
        }
        case ACTIONS.UPDATE_COMMENT: {
            const { section_id: sectionId, id: commentId } = action.payload;
            const commentsBySectionIdDictionary = deepCopy(state.commentsBySectionIdDictionary);
            const commentIndex = commentsBySectionIdDictionary[sectionId].findIndex(
                (comment) => comment.id === commentId,
            );
            commentsBySectionIdDictionary[sectionId][commentIndex] = action.payload;
            return {
                ...state,
                commentsBySectionIdDictionary,
            };
        }
        case ACTIONS.DELETE_COMMENT: {
            const { section_id: sectionId, id: commentId } = action.payload;
            const commentsBySectionIdDictionary = deepCopy(state.commentsBySectionIdDictionary);
            const commentIndex = commentsBySectionIdDictionary[sectionId].findIndex(
                (comment) => comment.id === commentId,
            );
            commentsBySectionIdDictionary[sectionId].splice(commentIndex, 1);
            return {
                ...state,
                commentsBySectionIdDictionary,
            };
        }
        default:
            throw new Error('Action not supported ');
    }
};

const useWorkbookVersionReducer = () => {
    return useReducer(workbookVersionReducer, getDefaultState());
};

export default useWorkbookVersionReducer;
