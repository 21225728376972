import { createContext, useContext, useEffect, useRef, useState } from 'react';
import merge from 'lodash/merge';
import useWorkbookReducer, { ACTIONS } from '@/workbook/useWorkbookReducer';
import { getDefaultCreatorSchema } from '@/navigation/useRolesReducer';
import { workbookFromDto, moduleFromDto, addOnsFromDto } from '@/api/dtos';
import { fetchJsonFile, getStorageObjectBasePath, getWorkbookUploadsStorageObjectBasePath } from '@/util/helpers';
import useQueryParameter from '@/util/useQueryParameter';

const WorkbookContext = createContext();

export function useWorkbook() {
    return useContext(WorkbookContext);
}

export default function WorkbookJsonLoaderProvider({ children }) {
    const isMounted = useRef(true);
    const [workbook, setWorkbook] = useWorkbookReducer();
    const [student, setStudent] = useState({});
    const [loading, setLoading] = useState(true);
    const [loadingError, setLoadingError] = useState('');
    const [creatorSchema, setCreatorSchema] = useState(getDefaultCreatorSchema());
    const [addOns, setAddOns] = useState({});
    const jsonUrl = useQueryParameter('json_url');
    const shareId = jsonUrl.split('/').pop();

    const loadWorkbook = async () => {
        const payload = {
            studentId: '',
            workbookVersion: {},
            modules: [],
            moduleResponses: [],
        };
        try {
            const workbookJson = await fetchJsonFile(jsonUrl);
            const workbookDto = workbookJson.workbook;
            payload.workbook = workbookFromDto(workbookDto);
            payload.workbook.creatorId = workbookDto.creator_id;

            const moduleDtos = workbookJson.modules;
            payload.modules = moduleDtos.map((dto) => moduleFromDto(dto));
            payload.creatorSchema = merge(creatorSchema, workbookJson.creatorSchema);
            payload.studentId = workbookJson.studentId;
            payload.addOns = addOnsFromDto(workbookJson.addOns);
            return payload;
        } catch (err) {
            console.error(err);
            throw new Error('Could not load workbook');
        }
    };

    useEffect(() => {
        isMounted.current = true;
        if (isMounted.current) {
            setLoading(true);
            loadWorkbook()
                .then((response) => {
                    const payload = {
                        ...response,
                        workbook: {
                            ...response.workbook,
                            schema: {
                                ...response.workbook.schema,
                                storageObjectBasePath: getStorageObjectBasePath(
                                    response.workbook.creatorId,
                                    response.workbook.id,
                                ),
                                workbookUploadsStorageObjectBasePath: getWorkbookUploadsStorageObjectBasePath(
                                    response.workbookVersion.creatorId,
                                    response.workbookVersion.workbookId,
                                ),
                            },
                        },
                    };
                    if (isMounted.current) {
                        setWorkbook({ type: ACTIONS.SET, payload });
                        setStudent({ id: response.studentId });
                        setCreatorSchema(response.creatorSchema);
                        setAddOns(response.addOns);
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    if (isMounted.current) {
                        setLoadingError(error.message);
                        setLoading(false);
                    }
                });
        }

        return () => {
            isMounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const selectModule = (moduleId) => {
        if (moduleId !== workbook.selectedModule?.id) {
            setWorkbook({ type: ACTIONS.SELECT_MODULE, payload: { moduleId } });
        }
    };

    const selectPrevModule = () => {
        setWorkbook({ type: ACTIONS.SELECT_PREV_MODULE });
    };

    const selectNextModule = () => {
        setWorkbook({ type: ACTIONS.SELECT_NEXT_MODULE });
    };

    const selectSection = (sectionId) => {
        setWorkbook({ type: ACTIONS.SELECT_SECTION, payload: sectionId });
    };

    const context = {
        shareId,
        workbook,
        loading,
        loadingError,
        creatorSchema,
        addOns,
        student,
        selectModule,
        selectPrevModule,
        selectNextModule,
        selectSection,
    };

    return <WorkbookContext.Provider value={context}>{children}</WorkbookContext.Provider>;
}
