import classNames from 'classnames';
import Label from '@/system/Label';

export const PageBreakEditor = () => {
    return (
        <>
            <div className="space-y-3.5">
                <Label desc="This section adds a page break into the PDF version of the workbook. The blue line shows where the page will break in the PDF and will not be visible to your students while they're using the workbook." />
            </div>
        </>
    );
};

export const PageBreak = ({ className }) => {
    return (
        <>
            <hr
                className={classNames(
                    'bt-0.5 mb-0 mt-2 w-full border-dashed border-blue-700 border-2 opacity-50',
                    className,
                )}
            />
        </>
    );
};

export const PageBreakArtifact = () => {
    return (
        <>
            <div className="h-0 break-after-page" />
        </>
    );
};
