/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '@/auth/AuthProvider';
import { CardWithFooter } from '@/auth/Cards';
import TextInput, {
    TEXT_INPUT_AUTO_CAPITALIZE,
    TEXT_INPUT_AUTO_CORRECT,
    TEXT_INPUT_SPELL_CHECK,
} from '@/system/TextInput';
import { isEmailValid } from '@/auth/helpers';
import { useEmbed } from '@/embed/EmbedProvider';
import { ROUTES, ROLE_QUERY_PARAM } from '@/util/constants';
import ValidationListItem from '@/system/ValidationListItem';
import useQueryParameter from '@/util/useQueryParameter';

export default function ForgotPassword({ emailSentProp = false, title = 'Forgot Password', className }) {
    const isMounted = useRef(true);
    const { sendPasswordResetEmail } = useAuth();
    const [loading, setLoading] = useState(false);
    const [emailSent, setEmailSent] = useState(emailSentProp);
    const [email, setEmail] = useState('');
    const [checkEmail, setCheckEmail] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState('');
    const [apiErrorMessage, setApiErrorMessage] = useState('');
    const { embedded } = useEmbed();
    const role = useQueryParameter(ROLE_QUERY_PARAM);
    const navigate = useNavigate();
    const location = useLocation();

    const validateEmail = (value, checkFormat = true) => {
        let passed = true;
        if (apiErrorMessage) setApiErrorMessage('');
        if (!checkEmail) setCheckEmail(true);
        if (emailErrorMessage) passed = false;
        if (!value) {
            setEmailErrorMessage('email is required');
            passed = false;
        } else {
            setEmailErrorMessage('');
        }
        if (checkFormat && value && !isEmailValid(value)) {
            setEmailErrorMessage('email is not valid');
            passed = false;
        }
        return passed;
    };

    const handleEmailChange = (value) => {
        setEmail(value);
        validateEmail(value, false);
    };

    const handleEmailBlur = (value) => {
        setEmail(value);
        validateEmail(value);
    };

    const checkFields = () => {
        let passed = true;
        if (!validateEmail(email)) passed = false;
        return passed;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!checkFields()) return;
        setLoading(true);
        sendPasswordResetEmail(email, role, embedded)
            .then(() => {
                if (isMounted.current) {
                    if (embedded) {
                        navigate(ROUTES.COMMON.CONFIRM_FORGOT_PASSWORD_EMBED, {
                            replace: true,
                            state: { embed: embedded, email },
                        });
                        return;
                    }
                    setEmailSent(true);
                    setLoading(false);
                }
            })
            .catch((error) => {
                if (isMounted.current) {
                    setApiErrorMessage(error.message);
                    setLoading(false);
                }
            });
    };

    useEffect(() => {
        isMounted.current = true;
        if (location.state?.email) setEmail(location.state.email);
        return () => {
            isMounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <CardWithFooter
            className={className}
            loading={loading}
            onSubmit={handleSubmit}
            buttonLabel={emailSent ? 'Resend Email' : 'Send reset email'}
            apiErrorMessage={apiErrorMessage}>
            <div className="heading__md--medium mb-6">{title}</div>
            {emailSent && (
                <div className="paragraph__sm mb-6">
                    We&apos;ve sent you an email with instructions on how to choose a new password and log in. If you
                    don&apos;t see it:
                    <ValidationListItem content="Check your spam or junk folders" />
                    <ValidationListItem content="You may need to verify your new email if you've recently changed it" />
                    <ValidationListItem content="Try again in a few minutes" />
                </div>
            )}
            <TextInput
                value={email}
                placeholder="email"
                onChange={(e) => handleEmailChange(e.target.value)}
                onBlur={(e) => handleEmailBlur(e.target.value.trim())}
                required
                error={checkEmail && !!emailErrorMessage}
                errorMsg={emailErrorMessage}
                autoCorrect={TEXT_INPUT_AUTO_CORRECT.OFF}
                autoCapitalize={TEXT_INPUT_AUTO_CAPITALIZE.NONE}
                spellCheck={TEXT_INPUT_SPELL_CHECK.FALSE}
            />
        </CardWithFooter>
    );
}
