export default function CheckboxIcon({ className }) {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6 3C4.34315 3 3 4.34315 3 6V18C3 19.6569 4.34315 21 6 21H18C19.6569 21 21 19.6569 21 18V6C21 4.34315 19.6569 3 18 3H6ZM5 6C5 5.44772 5.44772 5 6 5H18C18.5523 5 19 5.44772 19 6V18C19 18.5523 18.5523 19 18 19H6C5.44772 19 5 18.5523 5 18V6ZM16.1793 10.2269C16.5699 9.83641 16.5699 9.20324 16.1793 8.81272C15.7888 8.4222 15.1557 8.4222 14.7651 8.81272L10.5119 13.066L9.23486 11.7889C8.84434 11.3984 8.21117 11.3984 7.82065 11.7889C7.43013 12.1795 7.43013 12.8126 7.82065 13.2031L9.80479 15.1873C9.99233 15.3748 10.2467 15.4802 10.5119 15.4802C10.7771 15.4802 11.0315 15.3748 11.219 15.1873L16.1793 10.2269Z"
                fill="currentColor"
            />
        </svg>
    );
}
