import { useEffect, useRef } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { isPaidPlan, creatorIsInTrial } from '@/payment/helpers';
import { ROLES, ROUTES, PLANS } from '@/util/constants';
import { useRoles } from '@/navigation/RolesProvider';
import LoadingSpinner from '@/system/LoadingSpinner';

export default function PaidRoute({ children }) {
    const isMounted = useRef(true);
    const { currentRole, selectedCreator: creator, loading: loadingCreator } = useRoles();
    const location = useLocation();

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);

    if (loadingCreator) return <LoadingSpinner />;

    if (
        currentRole === ROLES.STUDENT ||
        currentRole === ROLES.MEMBER ||
        isPaidPlan(creator.plan) ||
        creatorIsInTrial(creator)
    )
        return children;
    if (creator.plan === PLANS.NO_PAID) return <Navigate to={ROUTES.CREATOR.TRIAL} state={{ from: location }} />;
    return <Navigate to={ROUTES.CREATOR.PAYMENT} state={{ from: location }} />;
}
