import classNames from 'classnames';

export default function TableLayoutIcon3({ className = '', style = {} }) {
    return (
        <svg
            width="39"
            height="39"
            viewBox="0 0 39 39"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={classNames('w-em', className)}
            style={style}>
            <g filter="url(#filter0_dd_302_3260)">
                <g clipPath="url(#clip0_302_3260)">
                    <rect x="3.52295" y="2.12598" width="4.89817" height="4.92718" fill="currentColor" />
                    <rect x="3.52295" y="7.05469" width="4.89817" height="13.5333" fill="currentColor" />
                    <rect x="3.52295" y="20.5928" width="4.89817" height="13.5333" fill="currentColor" />
                    <path d="M8.4209 2.12598H21.9718V7.05315H8.4209V2.12598Z" fill="currentColor" />
                    <rect x="8.4209" y="7.05469" width="13.5509" height="13.5333" fill="white" />
                    <rect x="8.4209" y="20.5928" width="13.5509" height="13.5333" fill="white" />
                    <rect x="21.9722" y="2.12598" width="13.5509" height="4.92718" fill="currentColor" />
                    <rect x="21.9722" y="7.05469" width="13.5509" height="13.5333" fill="white" />
                    <rect x="21.9722" y="20.5928" width="13.5509" height="13.5333" fill="white" />
                    <path d="M3.52295 7.05469L35.5229 7.05277" stroke="#272727" />
                    <path d="M3.52295 20.5947L35.5229 20.5928" stroke="#272727" />
                    <path d="M8.4209 2.22168L8.42091 34.2217" stroke="#272727" />
                    <path d="M21.9722 2.22168L21.9722 34.2217" stroke="#272727" />
                </g>
            </g>
            <defs>
                <filter
                    id="filter0_dd_302_3260"
                    x="0.522949"
                    y="0.125977"
                    width="38"
                    height="38"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_302_3260" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="1.5" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="effect1_dropShadow_302_3260" result="effect2_dropShadow_302_3260" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_302_3260" result="shape" />
                </filter>
                <clipPath id="clip0_302_3260">
                    <path
                        d="M3.52295 4.12598C3.52295 3.02141 4.41838 2.12598 5.52295 2.12598H33.5229C34.6275 2.12598 35.5229 3.02141 35.5229 4.12598V32.126C35.5229 33.2305 34.6275 34.126 33.5229 34.126H5.52295C4.41838 34.126 3.52295 33.2305 3.52295 32.126V4.12598Z"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}
