/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@/auth/AuthProvider';
import { isEmailValid, isPasswordValid } from '@/auth/helpers';
import useQueryParameter from '@/util/useQueryParameter';
import { Card } from '@/auth/Cards';
import PasswordValidator from '@/auth/PasswordValidator';
import TextInput, { TEXT_INPUT_TYPE } from '@/system/TextInput';
import { ROUTES, ROLE_QUERY_PARAM, ROLES, AUTHENTICATION_TYPES } from '@/util/constants';

export default function ConfirmSignup() {
    const isMounted = useRef(true);
    const email = useQueryParameter('email');
    const tmpPassword = useQueryParameter('tmp_password');
    const roleQueryParam = useQueryParameter(ROLE_QUERY_PARAM);
    const { confirmSignup } = useAuth();
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [checkPassword, setCheckPassword] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [checkConfirmPassword, setCheckConfirmPassword] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] = useState('');
    const [apiErrorMessage, setApiErrorMessage] = useState('');
    const [passwordChanged, setPasswordChanged] = useState(false);
    const navigate = useNavigate();

    const validatePassword = (value) => {
        let passed = true;
        if (apiErrorMessage) setApiErrorMessage('');
        if (!checkPassword) setCheckPassword(true);
        if (passwordError) passed = false;
        if (!value || !isPasswordValid(value)) {
            setPasswordError(true);
            passed = false;
        } else {
            setPasswordError(false);
        }
        if (checkConfirmPassword) {
            if (value !== confirmPassword) {
                setConfirmPasswordError(true);
                setConfirmPasswordErrorMessage('passwords do not match');
                if (confirmPassword) passed = false;
            } else {
                setConfirmPasswordError(false);
                setConfirmPasswordErrorMessage('');
            }
        }
        return passed;
    };

    const handlePasswordChange = (e) => {
        const { value: newPassword } = e.target;
        setPassword(newPassword);
        validatePassword(newPassword);
    };

    const validateConfirmPassword = (value) => {
        let passed = true;
        if (apiErrorMessage) setApiErrorMessage('');
        if (!checkConfirmPassword) setCheckConfirmPassword(true);
        if (confirmPasswordError && confirmPasswordErrorMessage) passed = false;
        if (!value) {
            setConfirmPasswordError(true);
            passed = false;
        } else {
            setConfirmPasswordError(false);
        }
        if (value !== password) {
            setConfirmPasswordError(true);
            setConfirmPasswordErrorMessage('passwords do not match');
            passed = false;
        } else {
            setConfirmPasswordErrorMessage('');
        }
        return passed;
    };

    const handleConfirmPasswordChange = (e) => {
        const { value: newConfirmPassword } = e.target;
        setConfirmPassword(newConfirmPassword);
        validateConfirmPassword(newConfirmPassword);
    };

    const checkFields = () => {
        let passed = true;
        if (!validatePassword(password)) passed = false;
        if (!validateConfirmPassword(confirmPassword)) passed = false;
        return passed;
    };

    const handleChoosePasswordClick = (e) => {
        e.preventDefault();

        if (!checkFields()) return;
        setLoading(true);
        confirmSignup(email, password, tmpPassword)
            .then(() => {
                setPasswordChanged(true);
                setLoading(false);
            })
            .catch((error) => {
                if (isMounted.current) {
                    setApiErrorMessage(error.message);
                    setLoading(false);
                }
            });
    };

    const handleReturnToLoginClick = (e) => {
        e.preventDefault();

        navigate(
            `${roleQueryParam === ROLES.MEMBER ? ROUTES.CREATOR.LOGIN : ROUTES.STUDENT.LOGIN}?login_type=${
                AUTHENTICATION_TYPES.EMAIL
            }`,
        );
    };

    useEffect(() => {
        isMounted.current = true;
        if (!tmpPassword) setApiErrorMessage('confirmation code is required');
        if (!email) setApiErrorMessage('email is required');
        if (!isEmailValid(email)) setApiErrorMessage('email is not valid');
        return () => {
            isMounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Card
            loading={loading}
            onSubmit={passwordChanged ? handleReturnToLoginClick : handleChoosePasswordClick}
            buttonLabel={passwordChanged ? 'Return to log in page' : 'Choose password'}
            apiErrorMessage={apiErrorMessage}>
            <div className="heading__md--medium mb-6">Choose Password</div>
            {passwordChanged ? (
                <div className="paragraph__sm mb-6">Password changed successfully.</div>
            ) : (
                <>
                    <TextInput
                        value={password}
                        placeholder="password"
                        type={TEXT_INPUT_TYPE.PASSWORD}
                        onChange={handlePasswordChange}
                        required
                        error={checkPassword && passwordError}
                    />
                    <TextInput
                        value={confirmPassword}
                        placeholder="confirm password"
                        type={TEXT_INPUT_TYPE.PASSWORD}
                        onChange={handleConfirmPasswordChange}
                        required
                        error={checkConfirmPassword && confirmPasswordError}
                        errorMsg={confirmPasswordErrorMessage}
                    />
                    <div className="paragraph__sm mb-4 pt-2">* indicates a required field</div>
                    <PasswordValidator validate={checkPassword} password={password} />
                </>
            )}
        </Card>
    );
}
