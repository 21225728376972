/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-boolean-value */
import { useEffect, useRef, useState } from 'react';
import EmailLogin from '@/auth/distribution/EmailLogin';
import useQueryParameter from '@/util/useQueryParameter';
import LoadingSpinner from '@/system/LoadingSpinner';
import { ROUTES } from '@/util/constants';

export default function StudentLogin({ autoEnrollPubliclySharedWorkbookId = undefined }) {
    const isMounted = useRef(true);
    const [loading, setLoading] = useState(true);
    const loginType = useQueryParameter('login_type');
    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (isMounted.current) {
            setLoading(false);
        }
    }, [loginType]);

    if (loading) return <LoadingSpinner />;
    return (
        <EmailLogin
            fromUrl={ROUTES.STUDENT.DASHBOARD}
            autoEnrollPubliclySharedWorkbookId={autoEnrollPubliclySharedWorkbookId}
        />
    );
}
