/* eslint-disable import/prefer-default-export */

/* Libraries */

export const LIBRARY_LOADING_TIMEOUT = 3000;

/* Auth */

export const AUTHENTICATION_TYPES = {
    EMAIL: 'email',
    THINKIFIC: 'thinkific',
    TEACHABLE: 'teachable',
};

/* Routes */

export const CREATOR_ROUTE = '/creator';
export const STUDENT_ROUTE = '/student';
export const MEMBER_ROUTE = '/member';

export const CREATOR_ROUTE_BASE_PREFIX = `${CREATOR_ROUTE}/`;
export const STUDENT_ROUTE_BASE_PREFIX = `${STUDENT_ROUTE}/`;
export const MEMBER_ROUTE_BASE_PREFIX = `${MEMBER_ROUTE}/`;

export const REACT_ROUTER_PATHS = {
    COMMON: {
        BASE: '/',
        FORGOT_PASSWORD: '/forgot-password',
        CONFIRM_FORGOT_PASSWORD: '/confirm-forgot-password',
        CONFIRM_FORGOT_PASSWORD_EMBED: '/confirm-forgot-password-embed',
        THINKIFIC_AUTH_COMPLETE: '/thinkific-auth-complete',
        THINKIFIC_AUTH_COMPLETE_FROM_MODAL: '/thinkific-auth-complete-from-modal',
        TEACHABLE_AUTH_COMPLETE: '/teachable-auth-complete',
        TEACHABLE_AUTH_START: '/teachable-auth-start',
        TEACHABLE_AUTH_COMPLETE_FROM_MODAL: '/teachable-auth-complete-from-modal',
        GOOGLE_AUTH_COMPLETE: '/google-auth-complete',
        CUSTOM_SSO_AUTH_COMPLETE: '/custom-sso-auth-complete',
        EMBED_LOGIN: '/embed-login',
        THUMBNAIL: '/thumbnail',
        ARTIFACT: '/artifact',
        DEMO: '/demo',
        PUBLIC_WORKBOOK:
            '/public-workbook/creator/:creator_id/workbook/:workbook_id/workbook-version/:workbook_version_id',
        PUBLIC: '/public',
    },
    CREATOR: {
        SIGNUP: '/creator-signup',
        CONFIRM_SIGNUP: '/confirm-creator-signup',
        LOGIN: '/creator-login',
        THINKIFIC_SIGNUP_SUCCESS: 'thinkific-signup-success',
        TEACHABLE_SIGNUP_SUCCESS: 'teachable-signup-success',
        GOOGLE_SIGNUP_SUCCESS: 'google-signup-success',
        PAYMENT: 'payment',
        TRIAL: 'trial',
        PROCESS_PAYMENT: 'process-payment',
        WORKBOOKS_DASHBOARD: 'dashboard',
        SETTINGS: 'settings',
        BUILDER: 'builder/:workbook_id',
        PREVIEW: 'preview/:workbook_id',
        PREVIEW_CONTENT: 'preview-content/:workbook_id',
        COHORTS_DASHBOARD: 'cohort/:cohort_id/workbook/:workbook_id',
        STUDENT_RESPONSES: 'cohort/:cohort_id/workbook/:workbook_id/version/:workbook_version_id/student/:student_id',
        TEMPLATE_SELECTOR: 'template-selector',
        INSTALL_THINKIFIC: '/install-thinkific',
        INSTALL_TEACHABLE: '/install-teachable',
        ADMIN: 'admin',
    },
    STUDENT: {
        SIGNUP: '/student-signup',
        CONFIRM_SIGNUP: '/confirm-student-signup',
        LOGIN: '/student-login',
        DASHBOARD: 'dashboard',
        SETTINGS: 'settings',
        WORKBOOK: 'workbook-version/:workbook_version_id',
        ARTIFACT: '/student-artifact',
        PUBLIC_ENROLL: 'public-enroll/:publicly_shared_workbook_id',
    },
    MEMBER: {
        CONFIRM_SIGNUP: '/confirm-member-signup',
        DASHBOARD: 'dashboard',
        SETTINGS: 'settings',
        BUILDER: 'builder/:workbook_id',
        PREVIEW: 'preview/:workbook_id',
        PREVIEW_CONTENT: 'preview-content/:workbook_id',
        COHORTS_DASHBOARD: 'cohort/:cohort_id/workbook/:workbook_id',
        STUDENT_RESPONSES: 'cohort/:cohort_id/workbook/:workbook_id/version/:workbook_version_id/student/:student_id',
        TEMPLATE_SELECTOR: 'template-selector',
    },
};

export const ROUTES = {
    COMMON: {
        BASE: REACT_ROUTER_PATHS.COMMON.BASE,
        FORGOT_PASSWORD: REACT_ROUTER_PATHS.COMMON.FORGOT_PASSWORD,
        CONFIRM_FORGOT_PASSWORD: REACT_ROUTER_PATHS.COMMON.CONFIRM_FORGOT_PASSWORD,
        CONFIRM_FORGOT_PASSWORD_EMBED: REACT_ROUTER_PATHS.COMMON.CONFIRM_FORGOT_PASSWORD_EMBED,
        THINKIFIC_AUTH_COMPLETE: REACT_ROUTER_PATHS.COMMON.THINKIFIC_AUTH_COMPLETE,
        THINKIFIC_AUTH_COMPLETE_FROM_MODAL: REACT_ROUTER_PATHS.COMMON.THINKIFIC_AUTH_COMPLETE_FROM_MODAL,
        TEACHABLE_AUTH_COMPLETE: REACT_ROUTER_PATHS.COMMON.TEACHABLE_AUTH_COMPLETE,
        TEACHABLE_AUTH_COMPLETE_FROM_MODAL: REACT_ROUTER_PATHS.COMMON.TEACHABLE_AUTH_COMPLETE_FROM_MODAL,
        GOOGLE_AUTH_COMPLETE: REACT_ROUTER_PATHS.COMMON.GOOGLE_AUTH_COMPLETE,
        CUSTOM_SSO_AUTH_COMPLETE: REACT_ROUTER_PATHS.COMMON.CUSTOM_SSO_AUTH_COMPLETE,
        EMBED_LOGIN: REACT_ROUTER_PATHS.COMMON.EMBED_LOGIN,
        THUMBNAIL: REACT_ROUTER_PATHS.COMMON.THUMBNAIL,
        ARTIFACT: REACT_ROUTER_PATHS.COMMON.ARTIFACT,
        DEMO: REACT_ROUTER_PATHS.COMMON.DEMO,
        PUBLIC_WORKBOOK: REACT_ROUTER_PATHS.COMMON.PUBLIC_WORKBOOK,
    },
    CREATOR: {
        SIGNUP: REACT_ROUTER_PATHS.CREATOR.SIGNUP,
        CONFIRM_SIGNUP: REACT_ROUTER_PATHS.CREATOR.CONFIRM_SIGNUP,
        LOGIN: REACT_ROUTER_PATHS.CREATOR.LOGIN,
        THINKIFIC_SIGNUP_SUCCESS: `${CREATOR_ROUTE_BASE_PREFIX}${REACT_ROUTER_PATHS.CREATOR.THINKIFIC_SIGNUP_SUCCESS}`,
        TEACHABLE_SIGNUP_SUCCESS: `${CREATOR_ROUTE_BASE_PREFIX}${REACT_ROUTER_PATHS.CREATOR.TEACHABLE_SIGNUP_SUCCESS}`,
        GOOGLE_SIGNUP_SUCCESS: `${CREATOR_ROUTE_BASE_PREFIX}${REACT_ROUTER_PATHS.CREATOR.GOOGLE_SIGNUP_SUCCESS}`,
        PAYMENT: `${CREATOR_ROUTE_BASE_PREFIX}${REACT_ROUTER_PATHS.CREATOR.PAYMENT}`,
        TRIAL: `${CREATOR_ROUTE_BASE_PREFIX}${REACT_ROUTER_PATHS.CREATOR.TRIAL}`,
        PROCESS_PAYMENT: `${CREATOR_ROUTE_BASE_PREFIX}${REACT_ROUTER_PATHS.CREATOR.PROCESS_PAYMENT}`,
        WORKBOOKS_DASHBOARD: `${CREATOR_ROUTE_BASE_PREFIX}${REACT_ROUTER_PATHS.CREATOR.WORKBOOKS_DASHBOARD}`,
        SETTINGS: `${CREATOR_ROUTE_BASE_PREFIX}${REACT_ROUTER_PATHS.CREATOR.SETTINGS}`,
        BUILDER: `${CREATOR_ROUTE_BASE_PREFIX}${REACT_ROUTER_PATHS.CREATOR.BUILDER}`,
        PREVIEW: `${CREATOR_ROUTE_BASE_PREFIX}${REACT_ROUTER_PATHS.CREATOR.PREVIEW}`,
        PREVIEW_CONTENT: `${CREATOR_ROUTE_BASE_PREFIX}${REACT_ROUTER_PATHS.CREATOR.PREVIEW_CONTENT}`,
        COHORTS_DASHBOARD: `${CREATOR_ROUTE_BASE_PREFIX}${REACT_ROUTER_PATHS.CREATOR.COHORTS_DASHBOARD}`,
        STUDENT_RESPONSES: `${CREATOR_ROUTE_BASE_PREFIX}${REACT_ROUTER_PATHS.CREATOR.STUDENT_RESPONSES}`,
        TEMPLATE_SELECTOR: `${CREATOR_ROUTE_BASE_PREFIX}${REACT_ROUTER_PATHS.CREATOR.TEMPLATE_SELECTOR}`,
        INSTALL_THINKIFIC: REACT_ROUTER_PATHS.CREATOR.INSTALL_THINKIFIC,
        ADMIN: `${CREATOR_ROUTE_BASE_PREFIX}${REACT_ROUTER_PATHS.CREATOR.ADMIN}`,
    },
    STUDENT: {
        SIGNUP: REACT_ROUTER_PATHS.STUDENT.SIGNUP,
        CONFIRM_SIGNUP: REACT_ROUTER_PATHS.STUDENT.CONFIRM_SIGNUP,
        LOGIN: REACT_ROUTER_PATHS.STUDENT.LOGIN,
        DASHBOARD: `${STUDENT_ROUTE_BASE_PREFIX}${REACT_ROUTER_PATHS.STUDENT.DASHBOARD}`,
        SETTINGS: `${STUDENT_ROUTE_BASE_PREFIX}${REACT_ROUTER_PATHS.STUDENT.SETTINGS}`,
        WORKBOOK: `${STUDENT_ROUTE_BASE_PREFIX}${REACT_ROUTER_PATHS.STUDENT.WORKBOOK}`,
        ARTIFACT: REACT_ROUTER_PATHS.STUDENT.ARTIFACT,
        PUBLIC_ENROLL: `${STUDENT_ROUTE_BASE_PREFIX}${REACT_ROUTER_PATHS.STUDENT.PUBLIC_ENROLL}`,
    },
    MEMBER: {
        CONFIRM_SIGNUP: REACT_ROUTER_PATHS.MEMBER.CONFIRM_SIGNUP,
        DASHBOARD: `${MEMBER_ROUTE_BASE_PREFIX}${REACT_ROUTER_PATHS.MEMBER.DASHBOARD}`,
        SETTINGS: `${MEMBER_ROUTE_BASE_PREFIX}${REACT_ROUTER_PATHS.MEMBER.SETTINGS}`,
        BUILDER: `${MEMBER_ROUTE_BASE_PREFIX}${REACT_ROUTER_PATHS.MEMBER.BUILDER}`,
        PREVIEW: `${MEMBER_ROUTE_BASE_PREFIX}${REACT_ROUTER_PATHS.MEMBER.PREVIEW}`,
        PREVIEW_CONTENT: `${MEMBER_ROUTE_BASE_PREFIX}${REACT_ROUTER_PATHS.MEMBER.PREVIEW_CONTENT}`,
        COHORTS_DASHBOARD: `${MEMBER_ROUTE_BASE_PREFIX}${REACT_ROUTER_PATHS.MEMBER.COHORTS_DASHBOARD}`,
        STUDENT_RESPONSES: `${MEMBER_ROUTE_BASE_PREFIX}${REACT_ROUTER_PATHS.MEMBER.STUDENT_RESPONSES}`,
        TEMPLATE_SELECTOR: `${MEMBER_ROUTE_BASE_PREFIX}${REACT_ROUTER_PATHS.MEMBER.TEMPLATE_SELECTOR}`,
    },
};

export const ROLE_QUERY_PARAM = 'role';

/* Embed mode */

export const EMBED_QUERY_PARAMS = {
    EMBED: 'embed',
    TYPE: 'embed_type',
    CONTEXT: 'embed_context',
    THINKIFIC_V2: 'thinkific_v2',
    TEACHABLE_V2: 'teachable_v2',
};

export const EMBED_CONTEXTS = {
    WORKBOOK_MODULE: 'workbook_module',
    WORKBOOK: 'workbook',
};

/* Builder */

export const SECTION_PREVIEW_PREFIX_PATH = '/images/section-preview-';

export const WORKBOOK_MAX_MODULES = 301; // 1+ for the cover page
export const WORKBOOK_MODULE_MAX_SECTIONS = 100;

export const LOGO_SIZES = {
    SMALL: {
        ID: 'small',
        LABEL: 'Small',
    },
    MEDIUM: {
        ID: 'medium',
        LABEL: 'Medium',
    },
    LARGE: {
        ID: 'large',
        LABEL: 'Large',
    },
};

export const TYPING_DEBOUNCE_TIMEOUT = 500;
export const UPDATE_THUMBNAIL_DEBOUNCE_TIMEOUT = 30000;

export const DROPPABLE_TYPES = {
    MODULE: 'module',
    SECTION: 'section',
};

export const POSITIONS_X = {
    LEFT: 'left',
    MIDDLE: 'middle',
    RIGHT: 'right',
};

export const POSITIONS_Y = {
    TOP: 'top',
    ONE_THIRD: '1/3',
    MIDDLE: 'middle',
    TWO_THIRDS: '2/3',
    BOTTOM: 'bottom',
};

export const TEXT_ALIGNMENTS = {
    LEFT: 'left',
    CENTER: 'center',
    RIGHT: 'right',
};

export const FILE_SIZE_1MB = 1048576;
export const FILE_SIZE_5MB = 5242880;
export const FILE_SIZE_10MB = 10485760;
export const MAX_COMPANY_LOGO_SIZE = FILE_SIZE_1MB;
export const MAX_FILE_SIZE = FILE_SIZE_5MB;
export const MAX_IMAGE_HEIGHT = 4500;
export const MAX_IMAGE_WIDTH = 4500;

export const VALID_LOGO_MIME_TYPES = ['image/jpeg', 'image/png', 'image/svg+xml', 'image/gif'];
export const VALID_IMAGE_MIME_TYPES = [
    'image/jpeg',
    'image/png',
    'image/jpg',
    'video/mp4',
    'application/pdf',
    'audio/mpeg',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'video/mpeg',
    'audio/mpeg',
    'audio/x-m4a',
    'application/vnd.ms-excel',
    'text/csv',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'text/plain',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/zip',
    'application/x-zip-compressed', // Windows 11 ZIP mime type
];
export const VALID_COVER_PAGE_IMAGE_MIME_TYPES = ['image/jpeg', 'image/gif'];

export const SNIPPET_CODE = `<script src="${process.env.REACT_APP_BASE_URL}/client_scripts/default.js"></script>`;

export const SHARE_QUERY_PARAM = 'share';
export const EMBED_LINK_MODAL_QUERY_PARAM = 'embed_link_modal';
export const CREATOR_ID_QUERY_PARAM = 'creator_id';
export const STUDENT_ID_QUERY_PARAM = 'student_id';
export const WORKBOOK_ID_QUERY_PARAM = 'workbook_id';
export const WORKBOOK_VERSION_ID_QUERY_PARAM = 'workbook_version_id';
export const PUBLICLY_SHARED_WORKBOOK_ID_QUERY_PARAM = 'publicly_shared_workbook_id';
export const COHORT_ID_QUERY_PARAM = 'cohort_id';
export const MODULE_ID_QUERY_PARAM = 'module_id';
export const FIRST_NAME_QUERY_PARAM = 'first_name';
export const LAST_NAME_QUERY_PARAM = 'last_name';
export const EMAIL_QUERY_PARAM = 'email';

/* Sections */

export const WORKBOOK_SECTION_VIEWER_MODES = {
    BUILDER: 'builder',
    READ_ONLY: 'read_only',
    DEFAULT: 'default',
    PREVIEW: 'preview',
    STUDENT: 'student',
};

// Image
export const IMAGE_SIZES = {
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large',
    FULL: 'full width',
};

// Student Image Upload
export const FILE_MAX_SIZES_DESC = {
    '1MB': '1 MB',
    '5MB': '5 MB',
    '10MB': '10 MB',
};

export const FILE_DESC_TO_SIZE = {
    '1 MB': FILE_SIZE_1MB,
    '5 MB': FILE_SIZE_5MB,
    '10 MB': FILE_SIZE_10MB,
};

// Workbook cover page and module cover page
export const COVER_PAGE_SECTION_ROOT_FONT_SIZE = 16;
export const COVER_PAGE_SECTION_MOBILE_ROOT_FONT_SIZE = 10;
export const COVER_PAGE_SECTION_EDITOR = {
    TITLE: {
        FONT_SIZE: {
            STEP: 1,
            MIN: 30,
            MAX: 100,
        },
        LETTER_SPACING: {
            STEP: 0.01,
            MIN: 0,
            MAX: 0.4,
        },
        LINE_HEIGHT: {
            STEP: 1,
            MIN: 32,
            MAX: 110,
        },
    },
    SUBTITLE: {
        FONT_SIZE: {
            STEP: 1,
            MIN: 12,
            MAX: 40,
        },
        LETTER_SPACING: {
            STEP: 0.01,
            MIN: 0,
            MAX: 0.4,
        },
        LINE_HEIGHT: {
            STEP: 1,
            MIN: 14,
            MAX: 48,
        },
    },
};

// Table
export const TABLE_LAYOUTS = {
    TOP_ROW: 'top_row',
    ALTERNATING_ROWS: 'alternating_rows',
    TOP_ROW_FIRST_COL: 'top_row_first_col',
    ALL_ROWS: 'all_rows',
};

// Radio Buttons and Checkboxes
export const OTHER_OPTION_KEY = 'other';
export const MAX_OPTIONS = 15;

// Fill in the blanks
export const FILL_IN_THE_BLANKS_SECTION = {
    MAX_TEXTS: 15,
    MAX_INPUTS: 15,
    INPUT_TYPE: 'i',
    TEXT_TYPE: 't',
};

// Artifacts
export const ARTIFACT_FILE_NAME = 'workbook.pdf';

export const ARTIFACT_SAMPLE_STUDENT = {
    firstName: 'Student First Name',
    lastName: 'Last Name',
    email: 'email@email.com',
};

export const ARTIFACT_PAGE_FORMATS = {
    A4: 'a4',
    LETTER: 'letter',
};

/* Workbook Preview */

export const PREVIEW_POST_MESSAGE_ACTIONS = {
    LOADING_ERROR: 'loadingError',
    UPDATE_PREVIEW_TOKENS: 'updatePreviewTokens',
};

/* Cohorts */

export const COHORT_STATUS = {
    COMPLETED: 'Completed',
    UPCOMING: 'Upcoming',
    ACTIVE: 'Active',
    UNASSIGNED: 'Unassigned',
    UNPUBLISHED: 'Unpublished',
    DRAFT: 'Draft',
};

/* Settings */

export const SETTINGS_SECTIONS = {
    PROFILE: 'profile',
    WORKBOOK_SETTINGS: 'workbook_settings',
    APPS: 'apps',
    BILLING_AND_PLANS: 'billing_and_plans',
    MEMBERS: 'members',
    SUPPORT: 'support',
};

/* Admin */

export const ADMIN_SECTIONS = {
    CREATORS: 'creators',
    IMPERSONATE: 'impersonate',
};

/* Billing and Plans */

export const PLANS = {
    NO_PAID: 1,
    TRIAL: 2,
    LAUNCH_MONTHLY: 3,
    ENTERPRISE: 4,
    BASIC: 5,
    PRO: 6,
};

export const PRICING_PLANS = [
    {
        title: 'Basic',
        price: 39.99,
        targettedFor: 'For Individuals',
        settings: [
            {
                title: '250 Monthly Active Users',
            },
            {
                title: '10 Worksheets',
            },
        ],
        features: [
            {
                isAvailable: true,
                title: 'Link Sharing',
            },
            {
                isAvailable: true,
                title: 'Wobo Embedding',
            },
            {
                isAvailable: true,
                title: 'SSO Integrations',
            },
            {
                isAvailable: false,
                title: 'Commenting',
            },
            {
                isAvailable: false,
                title: 'Student File Upload',
            },
            {
                isAvailable: false,
                title: 'IP Protection',
            },
        ],
        planType: PLANS.BASIC,
        freeTrial: false,
    },
    {
        title: 'Pro',
        price: 79.99,
        targettedFor: 'Small Teams',
        settings: [
            {
                title: '500 Monthly Active Users',
            },
            {
                title: 'Unlimited Worksheets',
            },
        ],
        features: [
            {
                isAvailable: true,
                title: 'Link Sharing',
            },
            {
                isAvailable: true,
                title: 'Wobo Embedding',
            },
            {
                isAvailable: true,
                title: 'SSO Integrations',
            },
            {
                isAvailable: true,
                title: 'Commenting',
            },
            {
                isAvailable: true,
                title: 'Student File Upload (20 GB)',
            },
            {
                isAvailable: true,
                title: 'IP Protection',
            },
        ],
        planType: PLANS.PRO,
        freeTrial: true,
    },
];

export const LINE_ITEM_TYPES = {
    MONTHLY_PLAN: 'monthly_plan',
    MONTHLY_ACTIVE_STUDENTS: 'monthly_active_users',
    STUDENT_IMAGE_UPLOAD: 'student_image_upload',
};

export const BILLING_PLAN_HIGHLIGHTS = {
    MONTHLY: [
        'Unlimited workbooks',
        'Unlimited students',
        'Teachable and Thinkific SSO integrations',
        'Embed into most LMSs',
    ],
};

export const BILLING_ADD_ONS = {
    STUDENT_IMAGE_UPLOAD: 'student_image_upload',
};

export const STUDENT_IMAGE_UPLOAD_TIP =
    'Your account starts with 10GB of storage for student-uploaded files. Files that you upload while creating the workbook do not count towards this storage limit. Each 10GB tier is an additional $10 USD. You can check your storage usage at any time from within your account';

export const MONTHLY_ACTIVE_STUDENT_TIP =
    'A monthly active student is one that accesses a new or existing workbook within the billing period. If a student does not access their workbook within your billing period, you will not be charged for them as a monthly active student. Billing periods are monthly (30 days) and will renew 30 days from when your subscription started. If the same student accesses more than one workbook from you, they will only be counted as one active student.';

/* Support */

export const WOBO_APP_URL = 'https://woboapp.com';
export const WOBO_CONTACT_URL = 'https://woboapp.com/contact';
export const WOBO_SUPPORT_DOCS_BASE_URL = 'https://docs.woboapp.com';
export const WOBO_SUPPORT_DOCS_EMBEDDING_IN_THINKIFIC = `${WOBO_SUPPORT_DOCS_BASE_URL}/embedding-in-thinkific`;
export const WOBO_SUPPORT_DOCS_EMBEDDING_IN_TEACHABLE =
    'https://support.teachable.com/hc/en-us/articles/12190933519501';
export const WOBO_SUPPORT_DOCS_EMBEDDING_IN_OTHER_LMS = `${WOBO_SUPPORT_DOCS_BASE_URL}/embedding-in-other-lms-platforms`;
export const WOBO_SUPPORT_DOCS_TERMS = `${WOBO_SUPPORT_DOCS_BASE_URL}/licence-agreement`;
export const WOBO_SUPPORT_DOCS_PRIVACY_POLICY = `${WOBO_SUPPORT_DOCS_BASE_URL}/privacy-policy`;
export const WOBO_SUPPORT_DOCS_COOKIE_POLICY = `${WOBO_SUPPORT_DOCS_BASE_URL}/cookie-policy`;
export const WOBO_SUPPORT_EMAIL = 'hi@woboapp.com';

export const TRIAL_DAYS = 14;

/* Textarea  */

export const HIDE_RESPONSE_SAVED_TIMEOUT = 2000;

/* Backend */

export const BACKEND_ERRORS = {
    JWT_EXPIRED: 'missing or malformed jwt',
    THINKIFIC_CALLBACK: {
        USER_NOT_ADMIN: 'thinkific user is not an admin for the given domain',
        EMAILS_DO_NOT_MATCH: 'user email and thinkific email must match',
        CREATOR_HAS_NO_ACCESS_TO_PLAN: 'creator has no access to plan',
    },
    TEACHABLE_CALLBACK: {
        USER_NOT_ADMIN: 'teachable user is not an admin for the given domain',
        EMAILS_DO_NOT_MATCH: 'user email and teachable email must match',
        CREATOR_HAS_NO_ACCESS_TO_PLAN: 'creator has no access to plan',
    },
    UPDATE_USER: {
        EXISTING_EMAIL: 'an account with the given email already exists',
    },
};

/* Data tables */

export const DATA_TABLE_DATE_FORMAT = 'MM/dd/yyyy';

/* SSO */

export const SSO_ID_QUERY_PARAM = 'sso_id';
export const SSO_TYPES = {
    THINKIFIC: 'thinkific',
    GOOGLE: 'google',
};
export const ERROR_QUERY_PARAM = 'error';
export const FROM_URL_QUERY_PARAM = 'from_url';
export const SSO_TYPE_QUERY_PARAM = 'sso_type';
export const THINKIFIC_AUTHORIZE_ROUTE = '/oauth2/authorize';
export const TEACHABLE_AUTHORIZE_ROUTE = '/identity/oauth_provider/authorize';
export const THINKIFIC_CALLBACK_ERROR_QUERY_PARAM = 'error';
export const TEACHABLE_CALLBACK_ERROR_QUERY_PARAM = 'error';
export const THINKIFIC_CALLBACK_AUTH_COMPLETED_QUERY_PARAM = 'thinkific_auth_completed';
export const TEACHABLE_CALLBACK_AUTH_COMPLETED_QUERY_PARAM = 'teachable_auth_completed';
export const GOOGLE_AUTHORIZE_URL = 'https://accounts.google.com/o/oauth2/v2/auth';

/* Roles, Permissions and Members */

export const ROLES = {
    NOT_SELECTED: '0',
    STUDENT: '1',
    CREATOR: '2',
    MEMBER: '3',
    ADMIN: '4',
};

export const STATUSES = {
    MISSING: 0,
    OTHER: 1,
    UNCONFIRMED: 2,
    CONFIRMED: 3,
    CHANGE_PASSWORD: 4,
};

export const STATUS_LABELS = {
    [STATUSES.MISSING]: 'Missing',
    [STATUSES.OTHER]: 'Other',
    [STATUSES.UNCONFIRMED]: 'Unconfirmed',
    [STATUSES.CONFIRMED]: 'Confirmed',
    [STATUSES.CHANGE_PASSWORD]: 'Invited',
};

export const SUBROLES = {
    PARTNER: 1,
    VIEWER: 2,
};

export const SUBROLE_LABELS = {
    [SUBROLES.PARTNER]: 'Partner',
    [SUBROLES.VIEWER]: 'Viewer',
};

export const PERMISSIONS = {
    WORKBOOKS_GET: 1,
    WORKBOOK_GET: 2,
    WORKBOOK_CREATE: 3,
    WORKBOOK_UPDATE: 4,
    WORKBOOK_DELETE: 5,
    WORKBOOK_THUMBNAIL_UPDATE: 6,
    WORKBOOK_ARTIFACT_SIGNED_URL_GET: 7,
    WORKBOOK_SCHEMA_PROP_UPDATE: 8,
    MODULES_GET: 9,
    MODULE_GET: 10,
    MODULE_CREATE: 11,
    MODULE_UPDATE: 12,
    MODULE_DELETE: 13,
    MODULE_MOVE: 14,
    SECTION_CREATE: 15,
    SECTION_SCHEMA_PROP_UPDATE: 16,
    SECTION_DELETE: 17,
    WORKBOOK_VERSIONS_GET: 18,
    WORKBOOK_VERSION_GET: 19,
    WORKBOOK_VERSION_CREATE: 20,
    WORKBOOK_VERSION_UPDATE: 21,
    WORKBOOK_VERSION_MODULES_GET: 22,
    CREATOR_COHORTS_GET: 23,
    COHORTS_GET: 24,
    COHORT_GET: 25,
    COHORT_CREATE: 26,
    COHORT_UPDATE: 27,
    COHORT_DELETE: 28,
    COHORT_STUDENTS_GET: 29,
    COHORT_STUDENT_ENROLL: 30,
    COHORT_STUDENT_DISENROLL: 31,
    COHORT_STUDENT_RESPONSES_GET: 32,
    BILLING_GET: 33,
    BILLING_EDIT: 34,
    BILLING_DELETE: 35,
};

export const DEFAULT_CREATOR_PERMISSIONS = {
    [PERMISSIONS.WORKBOOKS_GET]: true,
    [PERMISSIONS.WORKBOOK_GET]: true,
    [PERMISSIONS.WORKBOOK_CREATE]: true,
    [PERMISSIONS.WORKBOOK_UPDATE]: true,
    [PERMISSIONS.WORKBOOK_DELETE]: true,
    [PERMISSIONS.WORKBOOK_THUMBNAIL_UPDATE]: true,
    [PERMISSIONS.WORKBOOK_ARTIFACT_SIGNED_URL_GET]: true,
    [PERMISSIONS.WORKBOOK_SCHEMA_PROP_UPDATE]: true,
    [PERMISSIONS.MODULES_GET]: true,
    [PERMISSIONS.MODULE_GET]: true,
    [PERMISSIONS.MODULE_CREATE]: true,
    [PERMISSIONS.MODULE_UPDATE]: true,
    [PERMISSIONS.MODULE_DELETE]: true,
    [PERMISSIONS.MODULE_MOVE]: true,
    [PERMISSIONS.SECTION_CREATE]: true,
    [PERMISSIONS.SECTION_SCHEMA_PROP_UPDATE]: true,
    [PERMISSIONS.SECTION_DELETE]: true,
    [PERMISSIONS.WORKBOOK_VERSIONS_GET]: true,
    [PERMISSIONS.WORKBOOK_VERSION_GET]: true,
    [PERMISSIONS.WORKBOOK_VERSION_CREATE]: true,
    [PERMISSIONS.WORKBOOK_VERSION_UPDATE]: true,
    [PERMISSIONS.WORKBOOK_VERSION_MODULES_GET]: true,
    [PERMISSIONS.CREATOR_COHORTS_GET]: true,
    [PERMISSIONS.COHORTS_GET]: true,
    [PERMISSIONS.COHORT_GET]: true,
    [PERMISSIONS.COHORT_CREATE]: true,
    [PERMISSIONS.COHORT_UPDATE]: true,
    [PERMISSIONS.COHORT_DELETE]: true,
    [PERMISSIONS.COHORT_STUDENTS_GET]: true,
    [PERMISSIONS.COHORT_STUDENT_ENROLL]: true,
    [PERMISSIONS.COHORT_STUDENT_DISENROLL]: true,
    [PERMISSIONS.COHORT_STUDENT_RESPONSES_GET]: true,
    [PERMISSIONS.BILLING_GET]: true,
    [PERMISSIONS.BILLING_EDIT]: true,
    [PERMISSIONS.BILLING_DELETE]: true,
};

export const DEFAULT_MEMBER_PERMISSIONS = {
    [PERMISSIONS.WORKBOOKS_GET]: false,
    [PERMISSIONS.WORKBOOK_GET]: false,
    [PERMISSIONS.WORKBOOK_CREATE]: false,
    [PERMISSIONS.WORKBOOK_UPDATE]: false,
    [PERMISSIONS.WORKBOOK_DELETE]: false,
    [PERMISSIONS.WORKBOOK_THUMBNAIL_UPDATE]: false,
    [PERMISSIONS.WORKBOOK_ARTIFACT_SIGNED_URL_GET]: false,
    [PERMISSIONS.WORKBOOK_SCHEMA_PROP_UPDATE]: false,
    [PERMISSIONS.MODULES_GET]: false,
    [PERMISSIONS.MODULE_GET]: false,
    [PERMISSIONS.MODULE_CREATE]: false,
    [PERMISSIONS.MODULE_UPDATE]: false,
    [PERMISSIONS.MODULE_DELETE]: false,
    [PERMISSIONS.MODULE_MOVE]: false,
    [PERMISSIONS.SECTION_CREATE]: false,
    [PERMISSIONS.SECTION_SCHEMA_PROP_UPDATE]: false,
    [PERMISSIONS.SECTION_DELETE]: false,
    [PERMISSIONS.WORKBOOK_VERSIONS_GET]: false,
    [PERMISSIONS.WORKBOOK_VERSION_GET]: false,
    [PERMISSIONS.WORKBOOK_VERSION_CREATE]: false,
    [PERMISSIONS.WORKBOOK_VERSION_UPDATE]: false,
    [PERMISSIONS.WORKBOOK_VERSION_MODULES_GET]: false,
    [PERMISSIONS.CREATOR_COHORTS_GET]: false,
    [PERMISSIONS.COHORTS_GET]: false,
    [PERMISSIONS.COHORT_GET]: false,
    [PERMISSIONS.COHORT_CREATE]: false,
    [PERMISSIONS.COHORT_UPDATE]: false,
    [PERMISSIONS.COHORT_DELETE]: false,
    [PERMISSIONS.COHORT_STUDENTS_GET]: false,
    [PERMISSIONS.COHORT_STUDENT_ENROLL]: false,
    [PERMISSIONS.COHORT_STUDENT_DISENROLL]: false,
    [PERMISSIONS.COHORT_STUDENT_RESPONSES_GET]: false,
    [PERMISSIONS.BILLING_GET]: false,
    [PERMISSIONS.BILLING_EDIT]: false,
    [PERMISSIONS.BILLING_DELETE]: false,
};

/* Analytics */

export const MIXPANEL_USER_FEEDBACK_URL = 'https://mixpanel.com/api/2.0/stream/query';
export const FEATURES = {
    DISTROBUTION: 'Distribution',
    COMMENTING: 'Commenting',
};
export const FEATURE_BUTTONS = {
    [FEATURES.DISTROBUTION]: {
        SHARE_LINK: 'Share Link',
        EMBED: 'Embed',
    },
};

/* Distribution Status */

export const STATUS_IDLE = 'idle';
export const STATUS_GENERATING = 'generating';
export const STATUS_COPIED = 'copied';
export const STATUS_DELETING = 'deleting';
export const STATUS_DELETED = 'deleted';

/* Distribution Placeholder */

export const UNVERIFIED_USER_PLACEHOLDER = 'Not Set';
