/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@/auth/AuthProvider';
import useQueryParameter from '@/util/useQueryParameter';
import ConfirmationCodeInput, { verifyCode } from '@/auth/ConfirmationCodeInput';
import { CardWithFooter } from '@/auth/Cards';
import { isEmailValid } from '@/auth/helpers';
import { AUTHENTICATION_TYPES, ROUTES } from '@/util/constants';

export default function ConfirmCreatorSignup() {
    const isMounted = useRef(true);
    const navigate = useNavigate();
    const { confirmCreatorSignup } = useAuth();
    const [loading, setLoading] = useState(false);
    const [email] = useState(useQueryParameter('email'));
    const [otp, setOtp] = useState(useQueryParameter('otp'));
    const [otpErrorMessage, setOtpErrorMessage] = useState('');
    const [apiErrorMessage, setApiErrorMessage] = useState('');

    const validateEmail = (value) => {
        let passed = true;
        if (apiErrorMessage) setApiErrorMessage('');
        if (!value) {
            setApiErrorMessage('email is required');
            passed = false;
        } else {
            setApiErrorMessage('');
        }
        if (value && !isEmailValid(value)) {
            setApiErrorMessage('email is not valid');
            passed = false;
        }
        return passed;
    };

    const validateOtp = (value, noErrorMessage = false) => {
        const error = verifyCode(value);
        if (error) {
            if (!noErrorMessage) setOtpErrorMessage(error);
            return false;
        }
        setOtpErrorMessage(error);
        return true;
    };

    const submitConfirmation = () => {
        setLoading(true);
        confirmCreatorSignup(email, otp)
            .then(() => {
                navigate(`${ROUTES.CREATOR.LOGIN}?login_type=${AUTHENTICATION_TYPES.EMAIL}`);
            })
            .catch((error) => {
                if (isMounted.current) {
                    setApiErrorMessage(error.message);
                    setLoading(false);
                }
            });
    };

    const checkFields = (noOtpErrorMessage = false) => {
        let passed = true;
        if (!validateEmail(email)) passed = false;
        if (!validateOtp(otp, noOtpErrorMessage)) passed = false;
        return passed;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!checkFields()) return;
        setLoading(true);
        submitConfirmation();
    };

    useEffect(() => {
        isMounted.current = true;
        if (isMounted.current) {
            if (checkFields(true)) submitConfirmation();
        }
        return () => {
            isMounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <CardWithFooter
            loading={loading}
            onSubmit={handleSubmit}
            buttonLabel="Verify"
            apiErrorMessage={apiErrorMessage}
            className="max-w-sm">
            <div className="heading__md--medium mb-6">Verification</div>
            <div className="paragraph__sm mb-6">
                Almost done! Please confirm your account by entering the 6-digit number we just sent to your email.
            </div>
            <ConfirmationCodeInput onKeyUp={(value) => setOtp(value)} errorMessage={otpErrorMessage} />
        </CardWithFooter>
    );
}
