import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@/auth/AuthProvider';
import { isEmailValid, isPasswordValid } from '@/auth/helpers';
import TextInput, { TEXT_INPUT_TYPE } from '@/system/TextInput';
import { CardWithFooter } from '@/auth/Cards';
import PasswordValidator from '@/auth/PasswordValidator';
import {
    ROUTES,
    WOBO_SUPPORT_DOCS_TERMS,
    WOBO_SUPPORT_DOCS_PRIVACY_POLICY,
    WOBO_SUPPORT_DOCS_COOKIE_POLICY,
} from '@/util/constants';
import useReferral from '@/referral/useReferral';
import useGTM from '@/util/useGTM';

export default function CreatorEmailSignup({ showCompany = true }) {
    const isMounted = useRef(true);
    const [loading, setLoading] = useState(false);
    const [companyName, setCompanyName] = useState('');
    const [checkCompanyName, setCheckCompanyName] = useState(false);
    const [companyNameErrorMessage, setCompanyNameErrorMessage] = useState('');
    const [firstName, setFirstName] = useState('');
    const [checkFirstName, setCheckFirstName] = useState(false);
    const [firstNameErrorMessage, setFirstNameErrorMessage] = useState('');
    const [lastName, setLastName] = useState('');
    const [checkLastName, setCheckLastName] = useState(false);
    const [lastNameErrorMessage, setLastNameErrorMessage] = useState('');
    const [email, setEmail] = useState('');
    const [checkEmail, setCheckEmail] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState('');
    const [password, setPassword] = useState('');
    const [checkPassword, setCheckPassword] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [checkConfirmPassword, setCheckConfirmPassword] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] = useState('');
    const [apiErrorMessage, setApiErrorMessage] = useState('');
    const navigate = useNavigate();
    const { creatorSignup } = useAuth();
    const { loading: loadingReferral, referralId } = useReferral();
    const gtm = useGTM();

    const validateCompanyName = (value) => {
        let passed = true;
        if (apiErrorMessage) setApiErrorMessage('');
        if (!checkCompanyName) setCheckCompanyName(true);
        if (companyNameErrorMessage) passed = false;
        if (!value) {
            setCompanyNameErrorMessage('company name is required');
            passed = false;
        } else {
            setCompanyNameErrorMessage('');
        }
        return passed;
    };

    const handleCompanyNameChange = (value) => {
        setCompanyName(value);
        validateCompanyName(value);
    };

    const validateFirstName = (value) => {
        let passed = true;
        if (apiErrorMessage) setApiErrorMessage('');
        if (!checkFirstName) setCheckFirstName(true);
        if (firstNameErrorMessage) passed = false;
        if (!value) {
            setFirstNameErrorMessage('first name is required');
            passed = false;
        } else {
            setFirstNameErrorMessage('');
        }
        return passed;
    };

    const handleFirstNameChange = (value) => {
        setFirstName(value);
        validateFirstName(value);
    };

    const validateLastName = (value) => {
        let passed = true;
        if (apiErrorMessage) setApiErrorMessage('');
        if (!checkLastName) setCheckLastName(true);
        if (lastNameErrorMessage) passed = false;
        if (!value) {
            setLastNameErrorMessage('last name is required');
            passed = false;
        } else {
            setLastNameErrorMessage('');
        }
        return passed;
    };

    const handleLastNameChange = (value) => {
        setLastName(value);
        validateLastName(value);
    };

    const validateEmail = (value, checkFormat = true) => {
        let passed = true;
        if (apiErrorMessage) setApiErrorMessage('');
        if (!checkEmail) setCheckEmail(true);
        if (emailErrorMessage) passed = false;
        if (!value) {
            setEmailErrorMessage('email is required');
            passed = false;
        } else {
            setEmailErrorMessage('');
        }
        if (checkFormat && value && !isEmailValid(value)) {
            setEmailErrorMessage('email is not valid');
            passed = false;
        }
        return passed;
    };

    const handleEmailChange = (value) => {
        setEmail(value);
        validateEmail(value, false);
    };

    const handleEmailBlur = (value) => {
        setEmail(value);
        validateEmail(value);
    };

    const validatePassword = (value) => {
        let passed = true;
        if (apiErrorMessage) setApiErrorMessage('');
        if (!checkPassword) setCheckPassword(true);
        if (passwordError) passed = false;
        if (!value || !isPasswordValid(value)) {
            setPasswordError(true);
            passed = false;
        } else {
            setPasswordError(false);
        }
        if (checkConfirmPassword) {
            if (value !== confirmPassword) {
                setConfirmPasswordError(true);
                setConfirmPasswordErrorMessage('passwords do not match');
                if (confirmPassword) passed = false;
            } else {
                setConfirmPasswordError(false);
                setConfirmPasswordErrorMessage('');
            }
        }
        return passed;
    };

    const handlePasswordChange = (e) => {
        const { value: newPassword } = e.target;
        setPassword(newPassword);
        validatePassword(newPassword);
    };

    const validateConfirmPassword = (value) => {
        let passed = true;
        if (apiErrorMessage) setApiErrorMessage('');
        if (!checkConfirmPassword) setCheckConfirmPassword(true);
        if (confirmPasswordError && confirmPasswordErrorMessage) passed = false;
        if (!value) {
            setConfirmPasswordError(true);
            passed = false;
        } else {
            setConfirmPasswordError(false);
        }
        if (value !== password) {
            setConfirmPasswordError(true);
            setConfirmPasswordErrorMessage('passwords do not match');
            passed = false;
        } else {
            setConfirmPasswordErrorMessage('');
        }
        return passed;
    };

    const handleConfirmPasswordChange = (e) => {
        const { value: newConfirmPassword } = e.target;
        setConfirmPassword(newConfirmPassword);
        validateConfirmPassword(newConfirmPassword);
    };

    const checkFields = () => {
        let passed = true;
        if (!validateCompanyName(companyName)) passed = false;
        if (!validateFirstName(firstName)) passed = false;
        if (!validateLastName(lastName)) passed = false;
        if (!validateEmail(email)) passed = false;
        if (!validatePassword(password)) passed = false;
        if (!validateConfirmPassword(confirmPassword)) passed = false;
        return passed;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!checkFields()) return;
        setLoading(true);
        creatorSignup(companyName, firstName, lastName, email, password, referralId)
            .then((data) => {
                gtm.pushSignup('creator', 'email', data.id);
                navigate(`${ROUTES.CREATOR.CONFIRM_SIGNUP}?email=${encodeURIComponent(email)}`);
            })
            .catch((error) => {
                if (isMounted.current) {
                    setApiErrorMessage(error.message);
                    setLoading(false);
                }
            });
    };

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);

    return (
        <CardWithFooter
            loading={loading || loadingReferral}
            onSubmit={handleSubmit}
            buttonLabel="Sign up"
            apiErrorMessage={apiErrorMessage}
            footer={
                <div className="caption">
                    By signing up, you agree to our{' '}
                    <span className="caption--semibold">
                        <a href={WOBO_SUPPORT_DOCS_TERMS} target="_blank" rel="noreferrer">
                            Terms
                        </a>
                        ,{' '}
                        <a href={WOBO_SUPPORT_DOCS_PRIVACY_POLICY} target="_blank" rel="noreferrer">
                            Data Policy
                        </a>{' '}
                    </span>
                    and{' '}
                    <span className="caption--semibold">
                        <a href={WOBO_SUPPORT_DOCS_COOKIE_POLICY} target="_blank" rel="noreferrer">
                            Cookies Policy
                        </a>
                        .
                    </span>
                </div>
            }>
            {showCompany && (
                <TextInput
                    value={companyName}
                    placeholder="company name"
                    onChange={(e) => handleCompanyNameChange(e.target.value)}
                    onBlur={(e) => handleCompanyNameChange(e.target.value.trim())}
                    required
                    error={checkCompanyName && !!companyNameErrorMessage}
                    errorMsg={companyNameErrorMessage}
                />
            )}
            <TextInput
                value={firstName}
                placeholder="first name"
                onChange={(e) => handleFirstNameChange(e.target.value)}
                onBlur={(e) => handleFirstNameChange(e.target.value.trim())}
                required
                error={checkFirstName && !!firstNameErrorMessage}
                errorMsg={firstNameErrorMessage}
            />
            <TextInput
                value={lastName}
                placeholder="last name"
                onChange={(e) => handleLastNameChange(e.target.value)}
                onBlur={(e) => handleLastNameChange(e.target.value.trim())}
                required
                error={checkLastName && !!lastNameErrorMessage}
                errorMsg={lastNameErrorMessage}
            />
            <TextInput
                value={email}
                placeholder="email"
                onChange={(e) => handleEmailChange(e.target.value)}
                onBlur={(e) => handleEmailBlur(e.target.value.trim())}
                required
                error={checkEmail && !!emailErrorMessage}
                errorMsg={emailErrorMessage}
            />
            <TextInput
                value={password}
                placeholder="password"
                type={TEXT_INPUT_TYPE.PASSWORD}
                onChange={handlePasswordChange}
                required
                error={checkPassword && passwordError}
            />
            <TextInput
                value={confirmPassword}
                placeholder="confirm password"
                type={TEXT_INPUT_TYPE.PASSWORD}
                onChange={handleConfirmPasswordChange}
                required
                error={checkConfirmPassword && confirmPasswordError}
                errorMsg={confirmPasswordErrorMessage}
            />
            <div className="paragraph__sm mb-4 pt-2">* indicates a required field</div>
            {showCompany && <PasswordValidator validate={checkPassword} password={password} />}
        </CardWithFooter>
    );
}
