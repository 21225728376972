import { createRef } from 'react';
import TextInputDebounce from '@/system/TextInputDebounce';
import RichTextEditorDebounce from '@/system/RichTextEditorDebounce';
import GroupHeading from '@/system/GroupHeading';
import SectionTitle from '@/system/SectionTitle';
import SectionDescription from '@/system/SectionDescription';
import ToggleRow from '@/workbook/builder/sections/ToggleRow';
import { ensureHttpOrHttpsUrl } from '@/util/helpers';
import Label from '@/system/Label';

const handleClick = (url) => {
    window.open(ensureHttpOrHttpsUrl(url), '_blank');
};

const checkUrlPattern = (url) => {
    const youtubePattern =
        /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    const vimeoPattern = /^(http:\/\/|https:\/\/)?(?:vimeo\.com\/|player\.vimeo\.com\/video\/)([0-9]+)$/;
    const loomPattern = /^(http:\/\/|https:\/\/)?(www\.)?(loom\.com\/)(?:embed\/)((\w|-){11})(?:\S+)?$/;

    const valid = url.match(youtubePattern) || url.match(vimeoPattern) || url.match(loomPattern);
    return !!valid;
};

const checkDomainUrl = (url) => {
    return /(youtube|youtu|vimeo|loom)/.test(url);
};

export const VideoEditor = ({ schema, setSectionSchemaProp, saveSectionSchemaProp }) => {
    const { title, description, url } = schema;
    const isVideoUrlAllowed = createRef();
    isVideoUrlAllowed.current = checkDomainUrl(url);

    const handleTitleToggleChange = () => {
        saveSectionSchemaProp('title.show', !title.show);
    };

    const handleTitleChange = (e) => {
        const { value: text } = e.target;
        setSectionSchemaProp('title.text', text);
    };

    const handleTitleTypingEnd = (text) => {
        saveSectionSchemaProp('title.text', text);
    };

    const handleDescriptionToggleChange = () => {
        saveSectionSchemaProp('description.show', !description.show);
    };

    const handleDescriptionChange = (descriptionText) => {
        setSectionSchemaProp('description.text', descriptionText);
    };

    const handleDescriptionTypingEnd = (descriptionText) => {
        saveSectionSchemaProp('description.text', descriptionText);
    };

    const handleUrlChange = (e) => {
        const { value: newText } = e.target;
        setSectionSchemaProp('url', newText);
    };

    const handleUrlTypingEnd = (newText) => {
        let embedVideo = newText.replace('watch?v=', 'embed/');
        isVideoUrlAllowed.current = false;

        if (newText.includes('youtu.be')) {
            embedVideo = newText.replace('youtu.be', 'www.youtube.com/embed');
            isVideoUrlAllowed.current = true;
        }
        if (newText.includes('loom.com')) {
            embedVideo = newText.replace('loom.com/share', 'loom.com/embed');
            isVideoUrlAllowed.current = true;
        }
        if (newText.includes('vimeo.com')) {
            embedVideo = newText.replace('https://vimeo.com', 'https://player.vimeo.com/video');
            isVideoUrlAllowed.current = true;
        }

        saveSectionSchemaProp('url', embedVideo);
    };

    return (
        <div className="flex flex-col">
            <GroupHeading heading="Embed a video from YouTube, Vimeo or Loom by pasting in the video's share link." />
            <ToggleRow label="Title" enabled={title.show} onChange={handleTitleToggleChange} />
            <TextInputDebounce value={title.text} onChange={handleTitleChange} onTypingEnd={handleTitleTypingEnd} />
            <ToggleRow label="Description" enabled={description.show} onChange={handleDescriptionToggleChange} />
            <RichTextEditorDebounce
                value={description.text}
                onChange={handleDescriptionChange}
                onTypingEnd={handleDescriptionTypingEnd}
            />
            <br />
            <Label desc="Video URL" />
            <TextInputDebounce
                value={url}
                placeholder="https://"
                onChange={handleUrlChange}
                onTypingEnd={handleUrlTypingEnd}
            />
            <div>
                {!isVideoUrlAllowed.current && url.length > 12 && (
                    <Label className="text-red-500" desc="Please use a share link from YouTube, Vimeo or Loom." />
                )}
            </div>
            <div
                role="button"
                onClick={() => handleClick(url)}
                onKeyPress={() => handleClick(url)}
                className="text-sm text-primary-500 underline"
                tabIndex={0}>
                Test this link
            </div>
        </div>
    );
};

const Iframe = ({ title, url }) => {
    return (
        <span className="font-semibold underline">
            <iframe
                title={title}
                width="800"
                height="450"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                className="w-full"
                src={url}
            />
        </span>
    );
};

export const Video = ({ workbookSchema, schema }) => {
    const { title, description, url } = schema;

    return (
        <div
            role="button"
            onClick={() => handleClick(url)}
            onKeyPress={() => handleClick(url)}
            className="paragraph__md custom-outline-color"
            style={{
                fontFamily: workbookSchema.textStyling.body.fontFamily,
                color: workbookSchema.linkStyling.textColor,
            }}
            tabIndex={0}>
            {title?.show && (
                <SectionTitle
                    text={title.text}
                    style={{
                        fontFamily: workbookSchema.textStyling.subtitle.fontFamily,
                        color: workbookSchema.textStyling.subtitle.color,
                    }}
                />
            )}
            {description?.show && (
                <SectionDescription
                    text={description.text}
                    style={{
                        fontFamily: workbookSchema.textStyling.body.fontFamily,
                        color: workbookSchema.textStyling.body.color,
                    }}
                />
            )}
            {checkUrlPattern(url) && <Iframe url={url} />}
        </div>
    );
};

export const VideoArtifact = ({ workbookSchema, schema }) => {
    const { title, description, url } = schema;

    return (
        <a
            href={ensureHttpOrHttpsUrl(url)}
            className="paragraph__md block print:break-inside-avoid-page"
            style={{
                fontFamily: workbookSchema.textStyling.body.fontFamily,
                color: workbookSchema.linkStyling.textColor,
            }}
            rel="noreferrer"
            target="_blank">
            {title?.show && (
                <SectionTitle
                    text={title.text}
                    style={{
                        fontFamily: workbookSchema.textStyling.subtitle.fontFamily,
                        color: workbookSchema.textStyling.subtitle.color,
                    }}
                />
            )}
            {description?.show && (
                <SectionDescription
                    text={description.text}
                    style={{
                        fontFamily: workbookSchema.textStyling.body.fontFamily,
                        color: workbookSchema.textStyling.body.color,
                    }}
                />
            )}
            {checkUrlPattern(url) && <Iframe url={url} />}
        </a>
    );
};
