import { useState } from 'react';
import classNames from 'classnames';
import TextareaDebounce from '@/system/TextareaDebounce';
import CellTextareaDebounce from '@/system/CellTextareaDebounce';
import GroupHeading from '@/system/GroupHeading';
import Divider from '@/system/Divider';
import TableLayoutIconTopRow from '@/system/icons/TableLayoutIconTopRow';
import TableLayoutIconAlternatingRows from '@/system/icons/TableLayoutIconAlternatingRows';
import TableLayoutIconTopRowFirstCol from '@/system/icons/TableLayoutIconTopRowFirstCol';
import TableLayoutIconAllRows from '@/system/icons/TableLayoutIconAllRows';
import NumberMinusPlus from '@/system/NumberMinusPlus';
import { TwoActionModal } from '@/system/Modals';
import { TABLE_LAYOUTS, WORKBOOK_SECTION_VIEWER_MODES } from '@/util/constants';
import PreviewSuggestionTooltip from '@/workbook/builder/sections/PreviewSuggestionTooltip';
import ToggleRow from '@/workbook/builder/sections/ToggleRow';
import TextInputDebounce from '@/system/TextInputDebounce';
import RichTextEditorDebounce from '@/system/RichTextEditorDebounce';
import SectionTitle from '@/system/SectionTitle';
import SectionDescription from '@/system/SectionDescription';
import { isSectionReadOnly } from '@/util/helpers';

const maxRows = 20;
const maxCols = 7;

const defaultSelectedCell = { cell: null, row: -1, col: -1 };

const isCreatorCell = (layout, row, col) => {
    switch (layout) {
        case TABLE_LAYOUTS.TOP_ROW:
            return row === 0;
        case TABLE_LAYOUTS.ALTERNATING_ROWS:
            return row % 2 === 0;
        case TABLE_LAYOUTS.TOP_ROW_FIRST_COL:
            return row === 0 || col === 0;
        case TABLE_LAYOUTS.ALL_ROWS:
            return true;
        default:
            return false;
    }
};

const isMinHeightRow = (layout, row) => {
    switch (layout) {
        case TABLE_LAYOUTS.TOP_ROW:
            return row !== 0;
        case TABLE_LAYOUTS.ALTERNATING_ROWS:
            return row % 2 === 1;
        case TABLE_LAYOUTS.TOP_ROW_FIRST_COL:
            return row !== 0;
        case TABLE_LAYOUTS.ALL_ROWS:
        default:
            return false;
    }
};

export const TableEditor = ({ schema, setSectionSchemaProp, saveSectionSchemaProp }) => {
    const [saving, setSaving] = useState(false);
    const [selectedCell, setSelectedCell] = useState(defaultSelectedCell);
    const [showLayoutChangeConfirmationModal, setShowLayoutChangeConfirmationModal] = useState(false);
    const [selectedLayout, setSelectedLayout] = useState(null);
    const { title, description, layout, rows, columns, cellText } = schema;

    const noCellText = Object.keys(cellText).length === 0;

    const handleTitleToggleChange = () => {
        saveSectionSchemaProp('title.show', !title.show);
    };

    const handleTitleChange = (e) => {
        const { value: text } = e.target;
        setSectionSchemaProp('title.text', text);
    };

    const handleTitleTypingEnd = (text) => {
        saveSectionSchemaProp('title.text', text);
    };

    const handleDescriptionToggleChange = () => {
        saveSectionSchemaProp('description.show', !description.show);
    };

    const handleDescriptionChange = (text) => {
        setSectionSchemaProp('description.text', text);
    };

    const handleDescriptionTypingEnd = (text) => {
        saveSectionSchemaProp('description.text', text);
    };

    const resetSelectedCell = () => {
        setSelectedCell(defaultSelectedCell);
    };

    const handleShowLayoutChangeConfirmationModal = (layoutId) => {
        setSelectedLayout(layoutId);
        setShowLayoutChangeConfirmationModal(true);
    };

    const handleCloseLayoutChangeConfirmationModal = () => {
        setShowLayoutChangeConfirmationModal(false);
    };

    const handleLayoutChange = (layoutId) => {
        saveSectionSchemaProp('layout', layoutId).then(() => {
            resetSelectedCell();
        });
        setShowLayoutChangeConfirmationModal(false);
    };

    const handleLayoutChangeActionClick = () => {
        handleLayoutChange(selectedLayout);
    };

    const handleColumnsChange = (number) => {
        saveSectionSchemaProp('columns', number);
    };

    const handleRowsChange = (number) => {
        saveSectionSchemaProp('rows', number);
    };

    const handleCellTextChange = (e) => {
        const { value: text } = e.target;
        if ((selectedCell.row ?? -1) >= 0 && (selectedCell.col ?? -1) >= 0) {
            setSectionSchemaProp(`cellText.row${selectedCell.row}_col${selectedCell.col}`, text);
        }
    };

    const handleCellTextTypingEnd = (text) => {
        if ((selectedCell.row ?? -1) >= 0 && (selectedCell.col ?? -1) >= 0) {
            setSaving(true);
            saveSectionSchemaProp(`cellText.row${selectedCell.row}_col${selectedCell.col}`, text).finally(() => {
                setSaving(false);
            });
        }
    };

    const tableRows = () => {
        const rowsArray = [];
        let cellCount = 1;
        for (let r = 0; r < rows; r += 1) {
            const columnsArray = [];
            for (let c = 0; c < columns; c += 1) {
                const currentCount = cellCount;
                const creatorCell = isCreatorCell(layout, r, c);
                columnsArray.push(
                    <td
                        key={`row${r}_col${c}`}
                        className={classNames('border border-neutral-100 text-center', {
                            'cursor-not-allowed bg-neutral-50': !creatorCell,
                        })}>
                        {creatorCell ? (
                            <button
                                type="button"
                                className="h-full w-full"
                                onClick={() => {
                                    if (!saving)
                                        setSelectedCell({
                                            cell: currentCount,
                                            row: r,
                                            col: c,
                                        });
                                }}>
                                {currentCount}
                            </button>
                        ) : (
                            <>&nbsp;</>
                        )}
                    </td>,
                );
                if (creatorCell) cellCount += 1;
            }
            rowsArray.push(<tr key={`row${r}`}>{columnsArray}</tr>);
        }
        return rowsArray;
    };

    return (
        <>
            <ToggleRow label="Title" enabled={title.show} onChange={handleTitleToggleChange} />
            <TextInputDebounce value={title.text} onChange={handleTitleChange} onTypingEnd={handleTitleTypingEnd} />
            <ToggleRow label="Description" enabled={description.show} onChange={handleDescriptionToggleChange} />
            <RichTextEditorDebounce
                value={description.text}
                onChange={handleDescriptionChange}
                onTypingEnd={handleDescriptionTypingEnd}
            />
            <br />
            <GroupHeading heading="Choose Layout" />
            <div className="flex items-center space-x-1 text-5xl">
                <button
                    type="button"
                    onClick={() =>
                        noCellText
                            ? handleLayoutChange(TABLE_LAYOUTS.TOP_ROW)
                            : handleShowLayoutChangeConfirmationModal(TABLE_LAYOUTS.TOP_ROW)
                    }
                    className="inline-block"
                    disabled={layout === TABLE_LAYOUTS.TOP_ROW}>
                    <TableLayoutIconTopRow
                        className={classNames({
                            'text-primary-500': layout === TABLE_LAYOUTS.TOP_ROW,
                            'text-neutral-200': layout !== TABLE_LAYOUTS.TOP_ROW,
                        })}
                    />
                </button>
                <button
                    type="button"
                    onClick={() =>
                        noCellText
                            ? handleLayoutChange(TABLE_LAYOUTS.ALTERNATING_ROWS)
                            : handleShowLayoutChangeConfirmationModal(TABLE_LAYOUTS.ALTERNATING_ROWS)
                    }
                    className="inline-block"
                    disabled={layout === TABLE_LAYOUTS.ALTERNATING_ROWS}>
                    <TableLayoutIconAlternatingRows
                        className={classNames({
                            'text-primary-500': layout === TABLE_LAYOUTS.ALTERNATING_ROWS,
                            'text-neutral-200': layout !== TABLE_LAYOUTS.ALTERNATING_ROWS,
                        })}
                    />
                </button>
                <button
                    type="button"
                    onClick={() =>
                        noCellText
                            ? handleLayoutChange(TABLE_LAYOUTS.TOP_ROW_FIRST_COL)
                            : handleShowLayoutChangeConfirmationModal(TABLE_LAYOUTS.TOP_ROW_FIRST_COL)
                    }
                    className="inline-block"
                    disabled={layout === TABLE_LAYOUTS.TOP_ROW_FIRST_COL}>
                    <TableLayoutIconTopRowFirstCol
                        className={classNames({
                            'text-primary-500': layout === TABLE_LAYOUTS.TOP_ROW_FIRST_COL,
                            'text-neutral-200': layout !== TABLE_LAYOUTS.TOP_ROW_FIRST_COL,
                        })}
                    />
                </button>
                <button
                    type="button"
                    onClick={() =>
                        noCellText
                            ? handleLayoutChange(TABLE_LAYOUTS.ALL_ROWS)
                            : handleShowLayoutChangeConfirmationModal(TABLE_LAYOUTS.ALL_ROWS)
                    }
                    className="inline-block"
                    disabled={layout === TABLE_LAYOUTS.ALL_ROWS}>
                    <TableLayoutIconAllRows
                        className={classNames({
                            'text-primary-500': layout === TABLE_LAYOUTS.ALL_ROWS,
                            'text-neutral-200': layout !== TABLE_LAYOUTS.ALL_ROWS,
                        })}
                    />
                </button>
            </div>
            <Divider />
            <div className="grid grid-cols-2 gap-2">
                <div>
                    <GroupHeading heading="Columns" />
                    <NumberMinusPlus number={columns} onChange={handleColumnsChange} max={maxCols} />
                </div>
                <div>
                    <GroupHeading heading="Rows" />
                    <NumberMinusPlus number={rows} onChange={handleRowsChange} max={maxRows} />
                </div>
            </div>
            <Divider />
            <GroupHeading heading="Questions / Prompts" />
            <p className="caption mb-3.5">
                Select a cell to edit its content. Cells that require student responses are grayed-out.
            </p>

            <div className="overflow-hidden rounded-md border border-neutral-100">
                <table className="w-full border-collapse table-fixed" style={{ borderStyle: 'hidden' }}>
                    <tbody>{tableRows()}</tbody>
                </table>
            </div>

            {selectedCell.cell && (
                <div className="mt-6">
                    <GroupHeading heading={`Cell Text ${selectedCell.cell}`} />
                    <TextareaDebounce
                        value={cellText?.[`row${selectedCell.row}_col${selectedCell.col}`] || ''}
                        onChange={handleCellTextChange}
                        onTypingEnd={handleCellTextTypingEnd}
                        resizeToFit
                        focus
                    />
                </div>
            )}
            <TwoActionModal
                show={showLayoutChangeConfirmationModal}
                title="Switch layout?"
                desc={
                    <>
                        Switching layouts will change some cells from prompts to student response areas and may hide
                        data that was previously entered by you or your students. Click continue to change the layout.
                    </>
                }
                onClose={handleCloseLayoutChangeConfirmationModal}
                onCancel={handleCloseLayoutChangeConfirmationModal}
                onActionClick={handleLayoutChangeActionClick}
            />
        </>
    );
};

const BuilderTable = ({ layout, columns, rows, cellText, color, fontFamily, headerBackgroundColor, lineColor }) => {
    const tableRows = () => {
        const rowsArray = [];
        for (let r = 0; r < rows; r += 1) {
            const columnsArray = [];
            for (let c = 0; c < columns; c += 1) {
                const creatorCell = isCreatorCell(layout, r, c);
                columnsArray.push(
                    <td
                        key={`row${r}_col${c}`}
                        className={classNames('border text-left align-top', {
                            'h-full bg-neutral-50 p-0': !creatorCell,
                            'text-base font-bold p-2.5': creatorCell,
                            'w-1/3': layout === TABLE_LAYOUTS.TOP_ROW_FIRST_COL && c === 0,
                        })}
                        style={{
                            backgroundColor: !creatorCell ? '#ffffff' : headerBackgroundColor,
                            borderColor: lineColor,
                        }}>
                        {creatorCell ? (
                            <pre
                                className="whitespace-pre-wrap break-words"
                                style={{
                                    fontFamily,
                                    color,
                                }}>
                                {cellText?.[`row${r}_col${c}`] || ' '}
                            </pre>
                        ) : (
                            <PreviewSuggestionTooltip>
                                <textarea
                                    className="paragraph_md w-full h-full custom-focus-outline rounded-none border-none resize-none"
                                    readOnly
                                />
                            </PreviewSuggestionTooltip>
                        )}
                    </td>,
                );
            }
            rowsArray.push(
                <tr key={`row${r}`} className={classNames({ 'h-[100px]': isMinHeightRow(layout, r) })}>
                    {columnsArray}
                </tr>,
            );
        }
        return rowsArray;
    };

    return (
        <div
            className="min-w-[640px] overflow-hidden rounded-md border md:w-full md:min-w-0"
            style={{ borderColor: lineColor }}>
            <table className="w-full table-fixed border-collapse" style={{ borderStyle: 'hidden' }}>
                <tbody>{tableRows()}</tbody>
            </table>
        </div>
    );
};

const DefaultTable = ({
    mode,
    sectionResponses,
    setSectionResponseProp,
    saveSectionResponseProp,
    layout,
    columns,
    rows,
    cellText,
    color,
    fontFamily,
    headerBackgroundColor,
    lineColor,
}) => {
    const handleStudentResponseChange = (row, col, text) => {
        if (typeof setSectionResponseProp === 'function') setSectionResponseProp(`row${row}_col${col}`, text);
    };

    const handleStudentResponseTypingEnd = async (row, col, text) => {
        if (typeof saveSectionResponseProp === 'function') return saveSectionResponseProp(`row${row}_col${col}`, text);
        return null;
    };

    const textArea = (row, column) => (
        <CellTextareaDebounce
            value={sectionResponses?.[`row${row}_col${column}`]}
            onChange={
                mode === WORKBOOK_SECTION_VIEWER_MODES.READ_ONLY
                    ? () => {}
                    : (e) => {
                          const { value: text } = e.target;
                          handleStudentResponseChange(row, column, text);
                      }
            }
            onTypingEnd={
                mode === WORKBOOK_SECTION_VIEWER_MODES.READ_ONLY
                    ? () => {}
                    : async (text) => {
                          return handleStudentResponseTypingEnd(row, column, text);
                      }
            }
            readOnly={isSectionReadOnly(mode)}
            className="custom-focus-outline rounded-none border-none align-bottom"
            style={{ fontFamily }}
            resizeToFit
        />
    );

    const tableRows = () => {
        const rowsArray = [];
        for (let r = 0; r < rows; r += 1) {
            const columnsArray = [];
            for (let c = 0; c < columns; c += 1) {
                const creatorCell = isCreatorCell(layout, r, c);
                columnsArray.push(
                    <td
                        key={`row${r}_col${c}`}
                        className={classNames('border text-left align-top', {
                            'h-full bg-neutral-50 p-0': !creatorCell,
                            'text-base font-bold p-2.5': creatorCell,
                            'w-1/3': layout === TABLE_LAYOUTS.TOP_ROW_FIRST_COL && c === 0,
                        })}
                        style={{
                            backgroundColor: !creatorCell ? '#ffffff' : headerBackgroundColor,
                            borderColor: lineColor,
                        }}>
                        {creatorCell ? (
                            <pre
                                className="whitespace-pre-wrap break-words"
                                style={{
                                    fontFamily,
                                    color,
                                }}>
                                {cellText?.[`row${r}_col${c}`] || ' '}
                            </pre>
                        ) : (
                            textArea(r, c)
                        )}
                    </td>,
                );
            }
            rowsArray.push(
                <tr key={`row${r}`} className={classNames({ 'h-[100px]': isMinHeightRow(layout, r) })}>
                    {columnsArray}
                </tr>,
            );
        }
        return rowsArray;
    };

    return (
        <div
            className={classNames('overflow-hidden rounded-md border md:w-full md:min-w-0', {
                'min-w-[640px]': columns > 1,
            })}
            style={{ borderColor: lineColor }}>
            <table className="w-full table-fixed border-collapse" style={{ borderStyle: 'hidden' }}>
                <tbody>{tableRows()}</tbody>
            </table>
        </div>
    );
};

export const Table = ({
    workbookSchema,
    schema,
    mode = WORKBOOK_SECTION_VIEWER_MODES.DEFAULT,
    sectionResponses,
    setSectionResponseProp,
    saveSectionResponseProp,
}) => {
    const { title, description, layout, columns, rows, cellText } = schema;
    const { headerBackgroundColor, lineColor } = workbookSchema.tableStyling;
    const { color, fontFamily } = workbookSchema.textStyling.body;

    return (
        <div className="w-full overflow-x-auto">
            {title?.show && (
                <SectionTitle
                    text={title.text}
                    style={{
                        fontFamily: workbookSchema.textStyling.subtitle.fontFamily,
                        color: workbookSchema.textStyling.subtitle.color,
                    }}
                />
            )}
            {description?.show && (
                <SectionDescription
                    text={description.text}
                    style={{
                        fontFamily: workbookSchema.textStyling.body.fontFamily,
                        color: workbookSchema.textStyling.body.color,
                    }}
                />
            )}
            {mode === WORKBOOK_SECTION_VIEWER_MODES.BUILDER ? (
                <BuilderTable
                    layout={layout}
                    columns={columns}
                    rows={rows}
                    cellText={cellText}
                    color={color}
                    fontFamily={fontFamily}
                    headerBackgroundColor={headerBackgroundColor}
                    lineColor={lineColor}
                />
            ) : (
                <DefaultTable
                    mode={mode}
                    sectionResponses={sectionResponses}
                    setSectionResponseProp={setSectionResponseProp}
                    saveSectionResponseProp={saveSectionResponseProp}
                    layout={layout}
                    columns={columns}
                    rows={rows}
                    cellText={cellText}
                    color={color}
                    fontFamily={fontFamily}
                    headerBackgroundColor={headerBackgroundColor}
                    lineColor={lineColor}
                />
            )}
        </div>
    );
};

export const TableArtifact = ({ workbookSchema, schema, sectionResponses }) => {
    const { title, description, layout, columns, rows, cellText } = schema;
    const { headerBackgroundColor, lineColor } = workbookSchema.tableStyling;

    const tableRows = () => {
        const rowsArray = [];
        for (let r = 0; r < rows; r += 1) {
            const columnsArray = [];
            for (let c = 0; c < columns; c += 1) {
                const creatorCell = isCreatorCell(layout, r, c);
                columnsArray.push(
                    <td
                        key={`row${r}_col${c}`}
                        className={classNames('border border-black text-left align-top', {
                            'paragraph__md h-[100px] px-3 py-2': !creatorCell,
                            'text-base font-bold p-3': creatorCell,
                            'w-1/3': layout === TABLE_LAYOUTS.TOP_ROW_FIRST_COL && c === 0,
                        })}
                        style={{
                            backgroundColor: !creatorCell ? '#ffffff' : headerBackgroundColor,
                            borderColor: lineColor,
                        }}>
                        <pre
                            className="whitespace-pre-wrap break-words"
                            style={{
                                fontFamily: workbookSchema.textStyling.body.fontFamily,
                                color: creatorCell && workbookSchema.textStyling.body.color,
                            }}>
                            {creatorCell
                                ? cellText?.[`row${r}_col${c}`] || ' '
                                : sectionResponses?.[`row${r}_col${c}`] || ' '}
                        </pre>
                    </td>,
                );
            }
            rowsArray.push(
                <tr key={`row${r}`} className="print:break-inside-avoid-page">
                    {columnsArray}
                </tr>,
            );
        }
        return rowsArray;
    };

    return (
        <div className="w-full">
            {title?.show && (
                <SectionTitle
                    text={title.text}
                    style={{
                        fontFamily: workbookSchema.textStyling.subtitle.fontFamily,
                        color: workbookSchema.textStyling.subtitle.color,
                    }}
                />
            )}
            {description?.show && (
                <SectionDescription
                    text={description.text}
                    style={{
                        fontFamily: workbookSchema.textStyling.body.fontFamily,
                        color: workbookSchema.textStyling.body.color,
                    }}
                />
            )}
            <div className="w-full overflow-hidden rounded-md border" style={{ borderColor: lineColor }}>
                <table className="w-full table-fixed border-collapse" style={{ borderStyle: 'hidden' }}>
                    <tbody>{tableRows()}</tbody>
                </table>
            </div>
        </div>
    );
};
