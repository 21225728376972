import merge from 'lodash/merge';
import { parseISO } from 'date-fns';
import { getDefaultSchema as getDefaultWorkbookSchema } from '@/workbook/useWorkbookReducer';
import { getDefaultCreatorSchema, getDefaultCreatorSsoConfig } from '@/navigation/useRolesReducer';
import { deepCopy } from '@/util/helpers';
import { SECTIONS } from '@/workbook/builder/sections/sections';
import { DEFAULT_MEMBER_PERMISSIONS } from '@/util/constants';
import { getDefaultAdminLogSchema, getDefaultAdminLogEvent } from '@/admin/creators/useCreatorsReducer';

const coverPageTitle = 'Workbook Cover Page';

export const newWorkbookDto = (name, description, schema = null) => {
    return {
        name,
        description,
        schema: schema || getDefaultWorkbookSchema(),
    };
};

export const updateWorkbookNameDto = (name) => {
    return { name, draft: true };
};

export const updateWorkbookSchemaDto = (path, value) => {
    return { path, value };
};

export const updateWorkbookCommentingDto = (commentingEnabled, notificationEnabled, courseName, courseUrl) => {
    return {
        commenting_enabled: commentingEnabled,
        notification_enabled: notificationEnabled,
        course_name: courseName,
        course_url: courseUrl,
    };
};

export const updateWorkbookDraftDto = () => {
    return { draft: true };
};

export const workbookFromDto = (dto) => {
    const schema = getDefaultWorkbookSchema();
    merge(schema, dto.schema);
    return {
        id: dto.id,
        name: dto.name,
        description: dto.description,
        schema,
        draft: dto.draft,
        moduleIds: dto.module_ids,
        cohortIds: dto.cohort_ids,
        versionIds: dto.workbook_version_ids,
        commenting_enabled: dto.commenting_enabled,
        notification_enabled: dto.notification_enabled,
        course_name: dto.course_name,
        course_url: dto.course_url,
    };
};

export const newModuleDto = (title) => {
    return {
        title: title || coverPageTitle,
        show_title: false,
    };
};

const getSection = (type) => {
    const sectionNames = Object.keys(SECTIONS);
    for (let i = 0; i < sectionNames.length; i += 1) {
        const SECTION = SECTIONS[sectionNames[i]];
        if (SECTION.TYPE_ID === type) return deepCopy(SECTION);
    }
    throw new Error('Section type not defined');
};

export const sectionFromDto = (dto) => {
    const schema = getSection(dto.type).DEFAULT_SCHEMA;
    merge(schema, dto.schema);
    return {
        id: dto.id,
        type: dto.type,
        schema,
    };
};

export const moduleFromDto = (dto) => {
    return {
        id: dto.id,
        title: dto.title,
        show_title: dto.show_title,
        sections: dto.sections.map((sectionDto) => sectionFromDto(sectionDto)),
    };
};

export const updateModuleTitleDto = (title, showTitle = false) => {
    return { title, show_title: showTitle };
};

export const updateSectionSchemaDto = (path, value) => {
    return { path, value };
};

export const newSectionDto = (sectionType = SECTIONS.WORKBOOK_COVER_PAGE) => {
    return {
        type: sectionType.TYPE_ID,
        schema: sectionType.NEW_SCHEMA || sectionType.DEFAULT_SCHEMA,
    };
};

export const newSectionsDtoFromTemplate = (sectionTemplates) => {
    const dtos = [];
    for (let i = 0; i < sectionTemplates.length; i += 1) {
        const sectionTemplate = sectionTemplates[i];
        dtos.push({
            type: sectionTemplate.type,
            schema: sectionTemplate.schema,
        });
    }
    return dtos;
};

export const duplicatedSectionDto = (section) => {
    return {
        type: section.type,
        schema: section.schema,
    };
};

export const newCohortDto = (name, startDate, endDate) => {
    return {
        name,
        start_date: startDate,
        end_date: endDate,
    };
};

export const newEvergreenCohortDto = (name, startDate) => {
    return {
        name,
        start_date: startDate,
        evergreen: true,
    };
};

export const updateCohortDto = (name, startDate, endDate) => {
    return {
        name,
        start_date: startDate,
        end_date: endDate,
    };
};

export const cohortFromDto = (dto) => {
    return {
        id: dto.id,
        name: dto.name,
        startDate: dto.start_date ? parseISO(dto.start_date) : null,
        endDate: dto.end_date ? parseISO(dto.end_date) : null,
        studentIds: dto.student_ids,
        evergreen: dto.evergreen,
    };
};

export const studentEnrollmentDto = (firstName, lastName, email) => {
    return {
        first_name: firstName,
        last_name: lastName,
        email,
    };
};

const cohortStudentFromDto = (dto) => {
    return {
        id: dto.id,
        firstName: dto.first_name,
        lastName: dto.last_name,
        email: dto.email,
        status: dto.status,
        addedDate: dto.creation_date ? parseISO(dto.creation_date) : null,
        modulesProgress: dto.modules_progress,
        openedModules: dto.opened_modules,
    };
};

export const allCohortStudentsFromDto = (dto) => {
    return {
        total: dto.total,
        data: dto.data.map((creatorDto) => cohortStudentFromDto(creatorDto)),
    };
};

export const historicalPeriodFromDto = (dto) => {
    return {
        startDate: dto.start_date ? parseISO(dto.start_date) : null,
        endDate: dto.end_date ? parseISO(dto.end_date) : null,
    };
};

export const paymentInfoFromCheckoutSessionDto = (dto) => {
    return {
        plan: dto.plan,
        paymentCustomerId: dto.customer_id ? dto.customer_id : '',
        paymentSubscriptionId: dto.subscription_id ? dto.subscription_id : '',
        paymentHistoricalPeriods: [
            {
                startDate: dto.current_period_start_date ? parseISO(dto.current_period_start_date) : null,
                endDate: dto.current_period_end_date ? parseISO(dto.current_period_end_date) : null,
            },
        ],
    };
};

export const ssoConfigFromDto = (dto) => {
    return {
        authorizationRoute: dto.authorization_route,
        tokenRoute: dto.token_route,
        userRoute: dto.user_route,
        clientId: dto.client_id,
        clientSecret: dto.client_secret,
        scope: dto.scope,
        userMapping: {
            email: dto.user_mapping?.email || '',
            givenName: dto.user_mapping?.given_name || '',
            familyName: dto.user_mapping?.family_name || '',
        },
        active: dto.active,
    };
};

export const addOnsFromDto = (dto) => {
    return {
        studentImageUpload: !!dto?.student_image_upload,
    };
};

export const creatorFromDto = (dto) => {
    const schema = getDefaultCreatorSchema();
    merge(schema, dto.schema);
    const ssoConfig = getDefaultCreatorSsoConfig();
    merge(ssoConfig, dto.sso_config ? ssoConfigFromDto(dto.sso_config) : {});
    return {
        id: dto.id,
        workbookIds: dto.workbook_ids,
        memberIds: dto.member_ids,
        companyName: dto.company_name,
        plan: dto.plan,
        trialEndDate: dto.trial_end_date ? parseISO(dto.trial_end_date) : null,
        thinkificDomain: dto.thinkific ? dto.thinkific.domain : '',
        thinkificSubdomain: dto.thinkific ? dto.thinkific.subdomain : '',
        teachableDomain: dto.teachable ? dto.teachable.domain : '',
        teachableSchoolId: dto.teachable ? dto.teachable.school_id : '',
        teachableSubdomain: dto.teachable ? dto.teachable.subdomain : '',
        teachableClientId: dto.teachable ? dto.teachable.client_id : '',
        teachableClientSecret: dto.teachable ? dto.teachable.client_secret : '',
        paymentCustomerId: dto.payment && dto.payment.customer_id ? dto.payment.customer_id : '',
        paymentSubscriptionId: dto.payment && dto.payment.subscription_id ? dto.payment.subscription_id : '',
        paymentCheckoutSessionId: dto.payment && dto.payment.checkout_session_id ? dto.payment.checkout_session_id : '',
        paymentCancelEndDate: dto.payment && dto.payment.cancel_end_date ? parseISO(dto.payment.cancel_end_date) : null,
        paymentHistoricalPeriods:
            dto.payment && dto.payment.historical_periods
                ? dto.payment.historical_periods.map((period) => historicalPeriodFromDto(period))
                : null,
        addOns: addOnsFromDto(dto.add_ons),
        schema,
        publicWorkbookIds: dto.public_workbook_ids ? dto.public_workbook_ids : [],
        ssoConfig,
    };
};

export const adminFromDto = (dto) => {
    return {
        id: dto.id,
    };
};

export const workbookVersionFromDto = (dto) => {
    const schema = getDefaultWorkbookSchema();
    merge(schema, dto.schema);
    return {
        id: dto.id,
        name: dto.name,
        description: dto.description,
        schema,
        cohortIds: dto.cohort_ids,
        workbookId: dto.workbook_id,
        moduleVersionIds: dto.module_version_ids,
        creatorId: dto.creator_id,
        commentingEnabled: dto.commenting_enabled,
    };
};

export const studentCreatorEnrollmentFromDto = (dto) => {
    return {
        workbookVersion: workbookVersionFromDto(dto.workbook_version),
        startDate: dto.start_date,
        endDate: dto.end_date,
        moduleVersionsProgress: dto.module_versions_progress,
    };
};

export const studentFromDto = (dto) => {
    const student = {
        id: dto.id,
        creators: {},
        totalCreators: 0,
    };
    const keys = Object.keys(dto.creators);
    if (keys.length > 0) {
        keys.forEach((creatorId) => {
            const creator = dto.creators[creatorId];
            student.creators[creatorId] = {
                companyName: creator.company_name,
            };
        });
        student.totalCreators = keys.length;
    }
    return student;
};

export const studentCreatorFromDto = (dto) => {
    const schema = getDefaultCreatorSchema();
    merge(schema, dto.schema);
    return {
        companyName: dto.company_name,
        schema,
        enrollments: dto.enrollments.map((enrollmentDto) => studentCreatorEnrollmentFromDto(enrollmentDto)),
        addOns: addOnsFromDto(dto.add_ons),
    };
};

export const moduleVersionFromDto = (dto) => {
    return {
        id: dto.id,
        title: dto.title,
        show_title: dto.show_title,
        moduleId: dto.module_id,
        sections: dto.sections,
    };
};

export const moduleResponseFromDto = (dto) => {
    return {
        id: dto.id,
        workbookId: dto.workbook_id,
        moduleId: dto.module_id,
        cohortId: dto.cohort_id,
        sectionResponses: dto.section_responses,
        opened: dto.opened,
    };
};

export const updateSectionResponsePropDto = (path, value) => {
    return { path, value };
};

const monthlyActiveStudentFromDto = (dto) => {
    return {
        studentId: dto.student_id,
        firstName: dto.first_name,
        lastName: dto.last_name,
        email: dto.email,
        workbooksAccessed: dto.workbooks_accessed,
        accessDate: dto.access_date ? parseISO(dto.access_date) : null,
    };
};

export const monthlyActiveStudentsFromDto = (dto) => {
    return {
        total: dto.total,
        data: dto.data.map((studentDto) => monthlyActiveStudentFromDto(studentDto)),
    };
};

export const monthlyActiveStudentWorkbooksFromDto = (dto) => {
    return dto.map((w) => ({
        workbookId: w.workbook_id,
        workbookName: w.workbook_name,
        cohortId: w.cohort_id,
        workbookVersionId: w.workbook_version_id,
        accessDate: w.access_date ? parseISO(w.access_date) : null,
    }));
};

const membershipFromDto = (dto) => {
    return {
        subrole: dto.subrole,
        creationDate: dto.creation_date ? parseISO(dto.creation_date) : null,
        permissions: {
            ...DEFAULT_MEMBER_PERMISSIONS,
            ...dto.permissions,
        },
        creatorCompanyName: dto.creator_company_name,
        creatorThinkificDomain: dto.creator_thinkific_domain,
        creatorCompanyLogoStorageFilename: dto.creator_schema?.companyLogo?.storage_filename || '',
    };
};

export const memberFromDto = (dto) => {
    const member = {
        id: dto.id,
        creators: {},
        totalCreators: 0,
    };
    if (dto.memberships.length > 0) {
        dto.memberships.forEach((membership) => {
            member.creators[membership.creator_id] = membershipFromDto(membership);
        });
        member.totalCreators = dto.memberships.length;
    }
    return member;
};

export const memberCreatorFromDto = (dto) => {
    const schema = getDefaultCreatorSchema();
    merge(schema, dto.schema);
    const ssoConfig = getDefaultCreatorSsoConfig();
    merge(ssoConfig, dto.sso_config ? ssoConfigFromDto(dto.sso_config) : {});
    return {
        plan: dto.plan,
        companyName: dto.company_name,
        thinkificDomain: dto.thinkific ? dto.thinkific.domain : '',
        thinkificSubdomain: dto.thinkific ? dto.thinkific.subdomain : '',
        schema,
        publicWorkbookIds: dto.public_workbook_ids ? dto.public_workbook_ids : [],
        ssoConfig,
        addOns: addOnsFromDto(dto.add_ons),
    };
};

export const membersFromDto = (dto) => {
    if (dto.length > 0) {
        return dto.map((member) => ({
            id: member.id,
            email: member.email,
            firstName: member.first_name,
            lastName: member.last_name,
            status: member.status,
            subrole: member.subrole,
            permissions: {
                ...DEFAULT_MEMBER_PERMISSIONS,
                ...member.permissions,
            },
            creationDate: member.creation_date ? parseISO(member.creation_date) : null,
        }));
    }
    return dto;
};

const extendedCreatorFromDto = (dto) => {
    const schema = getDefaultCreatorSchema();
    merge(schema, dto.schema);
    const adminLogSchema = getDefaultAdminLogSchema();
    merge(adminLogSchema, dto.admin_log_schema);
    let adminLogEvents = dto.admin_log_events || [];
    adminLogEvents = adminLogEvents.map((eventDto) => {
        const event = getDefaultAdminLogEvent();
        merge(event, eventDto);
        return event;
    });
    return {
        id: dto.id,
        firstName: dto.first_name,
        lastName: dto.last_name,
        email: dto.email,
        workbookIds: dto.workbook_ids,
        memberIds: dto.member_ids,
        companyName: dto.company_name,
        plan: dto.plan,
        trialEndDate: dto.trial_end_date ? parseISO(dto.trial_end_date) : null,
        thinkificDomain: dto.thinkific_domain || '',
        thinkificSubdomain: dto.thinkific_subdomain || '',
        teachableDomain: dto.teachable_domain || '',
        teachableSubdomain: dto.teachable_subdomain || '',
        paymentCustomerId: dto.payment && dto.payment.customer_id ? dto.payment.customer_id : '',
        paymentSubscriptionId: dto.payment && dto.payment.subscription_id ? dto.payment.subscription_id : '',
        paymentCheckoutSessionId: dto.payment && dto.payment.checkout_session_id ? dto.payment.checkout_session_id : '',
        paymentCancelEndDate: dto.payment && dto.payment.cancel_end_date ? parseISO(dto.payment.cancel_end_date) : null,
        paymentHistoricalPeriods:
            dto.payment && dto.payment.historical_periods
                ? dto.payment.historical_periods.map((period) => historicalPeriodFromDto(period))
                : null,
        schema,
        adminLogSchema,
        adminLogEvents,
    };
};

export const allCreatorsFromDto = (dto) => {
    return {
        total: dto.total,
        data: dto.data.map((creatorDto) => extendedCreatorFromDto(creatorDto)),
    };
};

export const upcomingInvoiceFromDto = (dto) => {
    return {
        lineItems: Array.isArray(dto.line_items)
            ? dto.line_items.map((lineItem) => ({
                  price: lineItem.price,
                  priceId: lineItem.price_id,
                  qty: lineItem.qty,
                  type: lineItem.type,
              }))
            : [],
        subTotal: dto.sub_total,
        discount: dto.discount,
        tax: dto.tax,
        total: dto.total,
    };
};
