import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import classNames from 'classnames';
import { debounce } from 'lodash';
import { useEmbed } from '@/embed/EmbedProvider';
import { useAuth } from '@/auth/AuthProvider';
import { useNotifications, NOTIFICATION_MESSAGE_KEYS } from '@/system/notifications/NotificationProvider';
import { NOTIFICATION_VARIANTS } from '@/system/notifications/Notification';

const debounceTimeout = 100;

export default function Main({ children }) {
    const { embedded } = useEmbed();
    const { initialized: notificationsIntialized, pushNotification } = useNotifications();
    const { user } = useAuth();

    // Returns whether scrollbars show up on scrollable elements.
    // This is false on Macs when the "General > Show scroll bars" setting is
    // not set to "Always". The approach taken here is to create an element
    // that will scroll and then compare its outer width (including scrollbars)
    // to its inner width (excluding scrollbars).
    const isScrollbarVisible = () => {
        const scrollableElem = document.createElement('div');
        const innerElem = document.createElement('div');
        scrollableElem.style.width = '30px';
        scrollableElem.style.height = '30px';
        scrollableElem.style.overflow = 'scroll';
        scrollableElem.style.borderWidth = '0';
        innerElem.style.width = '30px';
        innerElem.style.height = '60px';
        scrollableElem.appendChild(innerElem);
        document.body.appendChild(scrollableElem);
        const diff = scrollableElem.offsetWidth - scrollableElem.clientWidth;
        document.body.removeChild(scrollableElem);
        return diff > 0;
    };

    const setVhCssVariable = () => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    useEffect(() => {
        if (notificationsIntialized && !user.emailVerified && user.id) {
            pushNotification(NOTIFICATION_MESSAGE_KEYS.NON_VERIFIED_EMAIL, NOTIFICATION_VARIANTS.ERROR);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notificationsIntialized, user.emailVerified, user.id]);

    useEffect(() => {
        // The default setting in macOS is to hide scrollbars when only a trackpad is present. To ensure
        // that all scrollable elements show the scrollbar in Mac webkit-based browsers (i.e. Chrome, Safari, Opera)
        // we need to apply the force-show-scrollbars class when scrollbars are being hidden.
        // More details: https://gist.github.com/IceCreamYou/cd517596e5847a88e2bb0a091da43fb4
        if (!isScrollbarVisible()) {
            document.body.classList.add('force-show-scrollbars');
        }

        if (isMobile) {
            // Ensure mobile vh is accurate since default vh unit includes the address and nav bars.
            setVhCssVariable();
            window.addEventListener('resize', debounce(setVhCssVariable, debounceTimeout));
            window.addEventListener('orientationchange', debounce(setVhCssVariable, debounceTimeout));
        }
    }, []);

    return (
        <div
            className={classNames('relative px-0', {
                'h-screen': embedded,
                'min-h-screen-minus-mobile-navbar lg:min-h-screen-minus-desktop-navbar': !embedded,
            })}>
            {children}
        </div>
    );
}
