import { useEffect, useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import { getUniqueId } from '@/util/helpers';

export const PLACEMENT = {
    BOTTOM: 'bottom',
    TOP: 'top',
    LEFT: 'left',
    RIGHT: 'right',
};

export default function Tooltip({
    tip,
    children,
    place = PLACEMENT.TOP,
    offset = [0, 0],
    className = '',
    event = null,
    eventOff = null,
    isCapture = false,
    rebuild = true,
    inlineBlock = false,
}) {
    const idRef = useRef(getUniqueId(5));
    const id = idRef.current;

    useEffect(() => {
        if (rebuild) ReactTooltip.rebuild();
    });

    return (
        <>
            <ReactTooltip
                id={id}
                place={place}
                backgroundColor="white"
                textColor="black"
                effect="solid"
                className={classNames(
                    '!caption !break-words !bg-white !rounded-lg !drop-shadow-md !filter !opacity-100',
                    className,
                )}
                multiline
                overridePosition={({ left, top }) => {
                    const [x, y] = offset;
                    return { left: left + x, top: top + y };
                }}
                event={event}
                eventOff={eventOff}
                isCapture={isCapture}
            />
            <div
                data-for={id}
                data-tip={tip}
                className={classNames('static w-auto h-auto', {
                    'inline-block': inlineBlock,
                })}>
                {children}
            </div>
        </>
    );
}
