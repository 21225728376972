import React, { useEffect } from 'react';
import { TrashIcon } from '@heroicons/react/outline';
import useHumanDate from '@/util/useHumanDate';
import CommentEditor from '@/workbook/builder/sections/comments/CommentEditor';
import { useAuth } from '@/auth/AuthProvider';
import { TwoActionModal } from '@/system/Modals';

export default function Comment({ workbookSchema, comment, commentingUsers, onDelete, onUpdate }) {
    const [editing, setEditing] = React.useState(false);
    const { user } = useAuth();
    const onEdit = () => {
        setEditing(true);
    };

    const onDeleteCompleted = () => {
        onDelete(comment);
    };

    const onEditCompleted = (text) => {
        onUpdate({ ...comment, comment: text });
        setEditing(false);
    };

    return (
        <div
            style={{ fontFamily: workbookSchema.textStyling.body.fontFamily }}
            className="flex flex-row justify-between w-full p-5 border-b bg-white">
            <div className="flex-grow">
                <CommentBody
                    workbookSchema={workbookSchema}
                    comment={comment}
                    editing={editing}
                    setEditing={setEditing}
                    onEditingEnded={onEditCompleted}
                />
                <CommentInformation comment={comment} commentingUsers={commentingUsers} />
            </div>
            {!editing && comment.user_id === user.id && (
                <div>
                    <CommentOptionsMenu onEditClick={onEdit} onDeleteClick={onDeleteCompleted} />
                </div>
            )}
        </div>
    );
}

function CommentBody({ workbookSchema, comment, editing, setEditing, onEditingEnded }) {
    return editing ? (
        <CommentBodyEditing
            workbookSchema={workbookSchema}
            comment={comment}
            onEditingEnded={onEditingEnded}
            setEditing={setEditing}
        />
    ) : (
        <CommentBodyReadonly comment={comment} />
    );
}

function CommentBodyReadonly({ comment }) {
    return <div className="text-neutral-500">{comment.comment}</div>;
}

function CommentBodyEditing({ workbookSchema, comment, onEditingEnded, setEditing }) {
    return (
        <CommentEditor
            workbookSchema={workbookSchema}
            initialComment={comment.comment}
            setAddingComment={setEditing}
            onAdd={onEditingEnded}
            isEditing
        />
    );
}

function CommentInformation({ comment, commentingUsers }) {
    const { timeAgo } = useHumanDate();

    const getUserById = (id) => {
        const filter = commentingUsers?.filter((user) => user.id === id);
        return filter && filter.length > 0 ? filter[0] : null;
    };

    const nameAndRole = getUserById(comment.user_id);
    let name = '';
    let role = '';

    if (nameAndRole === null) {
        name = 'Unknown';
        role = 'Previous Member';
    } else {
        name = `${nameAndRole.first_name} ${nameAndRole.last_name}`;
        role = nameAndRole.role;
    }

    return (
        <div className="flex items-baseline space-x-2 text-sm">
            <span className="font-light text-neutral-400">{name}</span>
            <span className="min-w-badge max-w-badge rounded-full py-0.5 px-3 text-center inline-block bg-primary-100 text-primary caption--semibold capitalize">
                {role}
            </span>
            <span className="font-thin text-neutral-300">•</span>
            <span className="font-thin text-neutral-300">{timeAgo(comment.update_date)}</span>
        </div>
    );
}

function CommentOptionsMenuToggleButton({ onClick }) {
    return (
        <button
            type="button"
            className="flex flex-row items-center justify-center w-6 h-6 text-gray-500 rounded-full hover:bg-gray-200 hover:text-gray-600"
            onClick={onClick}>
            <svg className="w-4 h-4 fill-current" viewBox="0 0 16 16">
                <path d="M8 13.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm0-11a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm0 5.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
            </svg>
        </button>
    );
}

function CommentOptionsMenuBody({ onEditClick, onDeleteClick }) {
    const [showDeleteConfirmation, setShowDeleteConfirmation] = React.useState(false);

    return (
        <div className="absolute right-0 w-48 py-1 mt-2 bg-white border border-gray-200 rounded-md shadow-xl">
            <button
                type="button"
                onClick={onEditClick}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left">
                Edit
            </button>
            <button
                type="button"
                onClick={() => setShowDeleteConfirmation(true)}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left">
                Delete
            </button>
            <TwoActionModal
                title="Delete Comment"
                desc="Are you sure you want to delete your comment?"
                icon={TrashIcon}
                actionButtonLabel="Delete"
                iconClassName="text-red-500"
                onActionClick={onDeleteClick}
                show={showDeleteConfirmation}
                onClose={() => setShowDeleteConfirmation(false)}
                onCancel={() => setShowDeleteConfirmation(false)}
            />
        </div>
    );
}

function CommentOptionsMenu({ onEditClick, onDeleteClick }) {
    const [isOpen, setIsOpen] = React.useState(false);
    const menuRef = React.useRef(null);

    useEffect(() => {
        const onClickOutside = (e) => {
            if (isOpen && menuRef.current && !menuRef.current.contains(e.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('click', onClickOutside);

        return () => {
            document.removeEventListener('click', onClickOutside);
        };
    }, [isOpen]);

    return (
        <div ref={menuRef}>
            <CommentOptionsMenuToggleButton onClick={() => setIsOpen((v) => !v)} />
            {isOpen && <CommentOptionsMenuBody onDeleteClick={onDeleteClick} onEditClick={onEditClick} />}
        </div>
    );
}
