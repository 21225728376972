/* eslint-disable jsx-a11y/interactive-supports-focus */
import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { TrashIcon, ExclamationIcon } from '@heroicons/react/outline';
import TextInputDebounce from '@/system/TextInputDebounce';
import ToggleRow from '@/workbook/builder/sections/ToggleRow';
import RichTextEditorDebounce from '@/system/RichTextEditorDebounce';
import SectionTitle from '@/system/SectionTitle';
import { TwoActionModal } from '@/system/Modals';
import SectionDescription from '@/system/SectionDescription';
import {
    FILE_MAX_SIZES_DESC,
    FILE_DESC_TO_SIZE,
    WORKBOOK_SECTION_VIEWER_MODES,
    VALID_IMAGE_MIME_TYPES,
    ROUTES,
    SETTINGS_SECTIONS,
} from '@/util/constants';
import Divider from '@/system/Divider';
import RadioGroup from '@/system/RadioGroup';
import Label from '@/system/Label';
import {
    getImageWidthBySize,
    getSizeDescription,
    getStorageUrl,
    getUniqueComponentClassName,
    getStudentSectionStorageObjectBasePath,
} from '@/util/helpers';
import { useToast } from '@/system/ToastProvider';
import { DefaultButton, ICON_POSITION } from '@/system/Buttons';
import { APP_STYLES } from '@/system/styles';
import FileLoader from '@/system/imageUploader/FileLoader';

const radioGroupMaxFileSize = [
    { id: FILE_MAX_SIZES_DESC['1MB'], label: FILE_MAX_SIZES_DESC['1MB'] },
    { id: FILE_MAX_SIZES_DESC['5MB'], label: FILE_MAX_SIZES_DESC['5MB'] },
    { id: FILE_MAX_SIZES_DESC['10MB'], label: FILE_MAX_SIZES_DESC['10MB'] },
];

export const FileUploadEditor = ({ schema, setSectionSchemaProp, saveSectionSchemaProp }) => {
    const { title, description, file } = schema;

    const handleTitleToggleChange = () => {
        saveSectionSchemaProp('title.show', !title.show);
    };

    const handleTitleChange = (e) => {
        const { value: text } = e.target;
        setSectionSchemaProp('title.text', text);
    };

    const handleTitleTypingEnd = (text) => {
        saveSectionSchemaProp('title.text', text);
    };

    const handleDescriptionToggleChange = () => {
        saveSectionSchemaProp('description.show', !description.show);
    };

    const handleDescriptionChange = (text) => {
        setSectionSchemaProp('description.text', text);
    };

    const handleDescriptionTypingEnd = (text) => {
        saveSectionSchemaProp('description.text', text);
    };

    const handleMaxSizeChange = (value) => {
        saveSectionSchemaProp('file.max_size', value);
    };

    return (
        <>
            <ToggleRow
                label="Title"
                enabled={title.show}
                onChange={handleTitleToggleChange}
                data-test="section-editor-qa-title-toggle"
            />
            <TextInputDebounce
                value={title.text}
                onChange={handleTitleChange}
                onTypingEnd={handleTitleTypingEnd}
                data-test="section-editor-qa-title"
            />
            <ToggleRow
                label="Description"
                enabled={description.show}
                onChange={handleDescriptionToggleChange}
                data-test="section-editor-qa-desc-toggle"
            />

            <RichTextEditorDebounce
                value={description.text}
                onChange={handleDescriptionChange}
                onTypingEnd={handleDescriptionTypingEnd}
                data-test="section-editor-qa-desc"
            />
            <Divider />
            <Label desc="Maximum file upload size" />
            <RadioGroup
                value={file.max_size}
                onChange={handleMaxSizeChange}
                options={radioGroupMaxFileSize}
                className="mb-2"
            />
        </>
    );
};

export const FileUpload = ({
    id,
    student,
    workbookSchema,
    schema,
    mode = WORKBOOK_SECTION_VIEWER_MODES.DEFAULT,
    sectionResponses,
    saveResponseFile,
    resetResponseFile,
    disabled,
}) => {
    const { title, description } = schema;

    if (disabled) {
        if (mode === WORKBOOK_SECTION_VIEWER_MODES.BUILDER) return <DisabledSectionPlaceHolder />;
        return null;
    }

    return (
        <>
            {title.show && (
                <SectionTitle
                    text={title.text}
                    style={{
                        fontFamily: workbookSchema.textStyling.subtitle.fontFamily,
                        color: workbookSchema.textStyling.subtitle.color,
                    }}
                />
            )}
            {description.show && (
                <SectionDescription
                    text={description.text}
                    style={{
                        fontFamily: workbookSchema.textStyling.body.fontFamily,
                        color: workbookSchema.textStyling.body.color,
                    }}
                    data-test="section-qa-desc"
                />
            )}
            <Uploader
                id={id}
                student={student}
                workbookSchema={workbookSchema}
                schema={schema}
                mode={mode}
                sectionResponses={sectionResponses}
                saveResponseFile={saveResponseFile}
                resetResponseFile={resetResponseFile}
            />
        </>
    );
};

const getFormattedSize = (size) => {
    const formattedSize = parseInt(size / 1000, 10);
    return formattedSize > 1000 ? `${(formattedSize / 1000).toFixed(1)} MB` : `${formattedSize} KB`;
};

export const Uploader = ({
    id,
    student,
    workbookSchema,
    schema,
    mode,
    sectionResponses,
    saveResponseFile,
    resetResponseFile,
}) => {
    const isMounted = useRef(true);
    const [size, setSize] = useState(getFormattedSize(sectionResponses?.file?.size));
    const { file } = schema;
    const { pushError } = useToast();
    const componentClassName = useRef(getUniqueComponentClassName());
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    let src = '';

    const imageSizeStyles = {
        width: getImageWidthBySize(schema?.image?.size),
    };

    const maxFileSize = file.max_size && FILE_DESC_TO_SIZE[file.max_size];
    const constraintText = 'Permitted file formats: pdf, doc, docx, xls, xlsx, csv, txt, ppt, pptx, mp3, mp4, zip.';
    const storageUrl = getStorageUrl(
        `${getStudentSectionStorageObjectBasePath(
            workbookSchema.workbookUploadsStorageObjectBasePath,
            id,
            student?.id,
        )}${sectionResponses?.file?.storage_filename}`,
    );

    if (mode === WORKBOOK_SECTION_VIEWER_MODES.DEFAULT || mode === WORKBOOK_SECTION_VIEWER_MODES.READ_ONLY) {
        src = sectionResponses?.file?.storage_filename
            ? getStorageUrl(
                  `${getStudentSectionStorageObjectBasePath(
                      workbookSchema.workbookUploadsStorageObjectBasePath,
                      id,
                      student?.id,
                  )}${sectionResponses?.file?.storage_filename}`,
              )
            : '';
    } else {
        src = sectionResponses?.file?.storage_filename ? sectionResponses.file.storage_filename : '';
    }

    const handleImageReset = () => {
        setShowConfirmDeleteModal(false);
        resetResponseFile('file');
    };

    const handleFileLoaded = (data) => {
        saveResponseFile('file', data, 'image');
    };

    const handleOutFileLoaded = (files) => {
        const url = URL.createObjectURL(files);
        if (files.size > maxFileSize) {
            pushError(`File size should be smaller than ${getSizeDescription(maxFileSize)}.`);
        } else {
            const loadedImageData = {
                name: files.name,
                type: files.type,
                size: files.size,
                url,
            };
            setSize(getFormattedSize(files.size));
            handleFileLoaded(loadedImageData);
        }
    };

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);

    return (
        <div className={`${componentClassName.current}`}>
            <style type="text/css">{`
                .${componentClassName.current} .replace-button {
                    font-family: ${workbookSchema.textStyling.body.fontFamily};
                    background-color: ${workbookSchema.buttonStyling.fillColor};
                    color: ${workbookSchema.buttonStyling.textColor};
                }

                .${componentClassName.current} .replace-button:hover {
                    background-color: ${workbookSchema.buttonStyling.hoverColor};
                    color: ${workbookSchema.buttonStyling.hoverTextColor};
                }

                .${componentClassName.current} .replace-button:disabled {
                    background-color: ${APP_STYLES.COLORS.NEUTRAL[300]};
                    color: ${APP_STYLES.COLORS.NEUTRAL[100]};
                }
                .${componentClassName.current} .delete-button {
                    font-family: ${workbookSchema.textStyling.body.fontFamily};
                    background-color: ${workbookSchema.buttonStyling.hoverColor};
                    color: ${workbookSchema.buttonStyling.hoverTextColor};
                }

                .${componentClassName.current} .delete-button:hover {
                    background-color: ${workbookSchema.buttonStyling.fillColor};
                    color: ${workbookSchema.buttonStyling.textColor};
                }

                .fileloader:focus + label {
                    --tw-ring-color: ${workbookSchema.buttonStyling.fillColor};
                    border-color: ${workbookSchema.buttonStyling.fillColor};
                }
            `}</style>

            <>
                <div className="w-full md:block">
                    <div
                        className={`${
                            src ? 'bg-neutral-25 p-2' : ''
                        } flex w-full items-center justify-center rounded-lg`}>
                        {src && (
                            <>
                                <div
                                    className="flex flex-col border-neutral-100 p-4 min-w-[280px]"
                                    style={imageSizeStyles}>
                                    <a className="pb-1 font-bold" href={storageUrl} target="_blank" rel="noreferrer">
                                        {`${sectionResponses?.file.filename}`}
                                    </a>
                                    {sectionResponses?.file?.size && <h1 className="text-xs">{size}</h1>}
                                </div>
                                <div>
                                    {mode !== WORKBOOK_SECTION_VIEWER_MODES.READ_ONLY && (
                                        <div className="flex justify-end">
                                            <div className="caption ml-2.5">
                                                <DefaultButton
                                                    onClick={() => setShowConfirmDeleteModal(true)}
                                                    icon={TrashIcon}
                                                    iconPosition={ICON_POSITION.LEFT}
                                                    className="mr-2 bg-transparent delete-button custom-focus-outline"
                                                    data-test="cancel-plan-button"
                                                />
                                            </div>
                                            <TwoActionModal
                                                show={showConfirmDeleteModal}
                                                showTitle
                                                title="Delete File"
                                                desc="Are you sure you want to delete this file? This cannot be undone."
                                                onCancel={() => setShowConfirmDeleteModal(false)}
                                                actionButtonLabel="Delete"
                                                data-test="delete-confirmation-modal"
                                                onActionClick={handleImageReset}
                                            />
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                        {!src && mode === WORKBOOK_SECTION_VIEWER_MODES.READ_ONLY && (
                            <PdfImagePlaceHolder style={imageSizeStyles} />
                        )}
                    </div>
                    {!src && mode !== WORKBOOK_SECTION_VIEWER_MODES.READ_ONLY && (
                        <div className="w-full h-full">
                            <FileLoader
                                title="Upload a File"
                                mode={mode}
                                onFileLoaded={handleOutFileLoaded}
                                constraintText={constraintText}
                                supportedFileTypes={VALID_IMAGE_MIME_TYPES}
                                onFileLoaderError={(error) => pushError(error)}
                            />
                        </div>
                    )}
                </div>
            </>
        </div>
    );
};

export const FileUploadArtifact = ({ id, student, workbookSchema, schema, sectionResponses }) => {
    const { title, description } = schema;
    const storageUrl = getStorageUrl(
        `${getStudentSectionStorageObjectBasePath(
            workbookSchema.workbookUploadsStorageObjectBasePath,
            id,
            student?.id,
        )}${sectionResponses?.file?.storage_filename}`,
    );

    const imageSizeStyles = {
        width: getImageWidthBySize(schema?.image?.size),
    };

    const src = sectionResponses?.file?.storage_filename
        ? getStorageUrl(
              `${getStudentSectionStorageObjectBasePath(
                  workbookSchema.workbookUploadsStorageObjectBasePath,
                  id,
                  student.id,
              )}${sectionResponses?.file?.storage_filename}`,
          )
        : '';

    const formattedSize = getFormattedSize(sectionResponses?.file?.size);

    return (
        <>
            {title.show && (
                <SectionTitle
                    text={title.text}
                    style={{
                        fontFamily: workbookSchema.textStyling.subtitle.fontFamily,
                        color: workbookSchema.textStyling.subtitle.color,
                    }}
                />
            )}
            {description.show && (
                <SectionDescription
                    text={description.text}
                    style={{
                        fontFamily: workbookSchema.textStyling.body.fontFamily,
                        color: workbookSchema.textStyling.body.color,
                    }}
                    data-test="section-qa-desc"
                />
            )}
            <div className="w-full break-inside-avoid">
                <div className="flex items-center justify-center w-full">
                    {src && (
                        <div className="flex flex-col" style={imageSizeStyles}>
                            <a href={storageUrl} target="_blank" rel="noreferrer">
                                {`${sectionResponses?.file?.filename}`}
                            </a>
                            {sectionResponses?.file?.size && <h1 className="text-xs">{formattedSize}</h1>}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

const PdfImagePlaceHolder = ({ style }) => {
    return (
        <div className="flex items-center justify-center w-full ">
            <div
                className="flex items-center justify-center w-full h-64 bg-white border border-dashed rounded-lg border-neutral-400"
                style={style}>
                <img
                    key="placeholder-image"
                    src="/images/image-section-placeholder.svg"
                    className="break-inside-avoid h-9 w-9"
                    alt="Upload"
                />
            </div>
        </div>
    );
};

const DisabledSectionPlaceHolder = ({ style }) => {
    return (
        <div className="flex items-center justify-center w-full ">
            <div
                className="flex flex-col items-center justify-center w-full h-64 bg-white border border-dashed rounded-lg border-neutral-400"
                style={style}>
                <ExclamationIcon className="mb-3 w-9 text-neutral-300" />

                <div className="heading__sm--semibold">Upgrade plan to enable Student File Uploads</div>
                <Link
                    to={`${ROUTES.CREATOR.SETTINGS}?section=${SETTINGS_SECTIONS.BILLING_AND_PLANS}`}
                    className="caption--underline">
                    Update in the Billings and Plans Page.
                </Link>
            </div>
        </div>
    );
};
