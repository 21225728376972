import { BrowserRouter as Router } from 'react-router-dom';
import AppErrorBoundary from '@/AppErrorBoundary';
import AppRoutes from '@/navigation/AppRoutes';
import AuthProvider from '@/auth/AuthProvider';
import EmbedProvider from '@/embed/EmbedProvider';
import AppContainer from '@/system/AppContainer';
import ToastProvider from '@/system/ToastProvider';
import Main from '@/system/Main';
import FontLoader from '@/system/FontLoader';
import FeatureFlagProvider from '@/featureFlags/FeatureFlagProvider';
import NotificationProvider from '@/system/notifications/NotificationProvider';

import './tailwind.scss';
import '@progress/kendo-theme-default/dist/all.css';
import './kendo-overrides.scss';
import './rich-text-editor.scss';

function App() {
    return (
        <AppErrorBoundary>
            <EmbedProvider>
                <AuthProvider>
                    <Router>
                        <AppContainer>
                            <FeatureFlagProvider>
                                <FontLoader>
                                    <NotificationProvider>
                                        <ToastProvider>
                                            <Main>
                                                <AppRoutes />
                                            </Main>
                                        </ToastProvider>
                                    </NotificationProvider>
                                </FontLoader>
                            </FeatureFlagProvider>
                        </AppContainer>
                    </Router>
                </AuthProvider>
            </EmbedProvider>
        </AppErrorBoundary>
    );
}

export default App;
