/* eslint-disable react/jsx-props-no-spreading */
import { useRef } from 'react';
import { getUniqueComponentClassName, sanitizeFilenameUrl } from '@/util/helpers';
import RichTextEditorDebounce from '@/system/RichTextEditorDebounce';
import TextInputDebounce from '@/system/TextInputDebounce';
import ToggleRow from '@/workbook/builder/sections/ToggleRow';
import SectionTitle from '@/system/SectionTitle';
import SectionDescription from '@/system/SectionDescription';
import { APP_STYLES } from '@/system/styles';
import { WORKBOOK_SECTION_VIEWER_MODES } from '@/util/constants';
import usePDFDownloader from '@/util/usePDFDownloader';
import LoadingSpinner from '@/util/LoadingSpinner';

export const ModuleDownloadLinkEditor = ({ schema, setSectionSchemaProp, saveSectionSchemaProp }) => {
    const { title, description } = schema;

    const handleTitleToggleChange = () => {
        saveSectionSchemaProp('title.show', !title.show);
    };

    const handleTitleChange = (e) => {
        const { value: text } = e.target;
        setSectionSchemaProp('title.text', text);
    };

    const handleTitleTypingEnd = (text) => {
        saveSectionSchemaProp('title.text', text);
    };

    const handleDescriptionToggleChange = () => {
        saveSectionSchemaProp('description.show', !description.show);
    };

    const handleDescriptionChange = (text) => {
        setSectionSchemaProp('description.text', text);
    };

    const handleDescriptionTypingEnd = (text) => {
        saveSectionSchemaProp('description.text', text);
    };

    return (
        <>
            <ToggleRow label="Title" enabled={title.show} onChange={handleTitleToggleChange} />
            <TextInputDebounce value={title.text} onChange={handleTitleChange} onTypingEnd={handleTitleTypingEnd} />
            <ToggleRow label="Description" enabled={description.show} onChange={handleDescriptionToggleChange} />
            <RichTextEditorDebounce
                value={description.text}
                onChange={handleDescriptionChange}
                onTypingEnd={handleDescriptionTypingEnd}
            />
        </>
    );
};

export const ModuleDownloadLink = ({ workbookSchema, schema, student, workbookVersion, module, workbook, mode }) => {
    const { title, description } = schema;
    const componentClassName = useRef(getUniqueComponentClassName());
    const { downloading, download } = usePDFDownloader();

    const getDownloadUrl = () => {
        if (mode === WORKBOOK_SECTION_VIEWER_MODES.PREVIEW) {
            return ``;
        }

        return `${process.env.REACT_APP_API_BASE_URL}/artifacts?workbook_version_id=${
            workbookVersion?.id || ''
        }&student_id=${student?.id || ''}&cohort_id=${workbookVersion?.cohortId || ''}&module_id=${
            module.id
        }&workbook_id=${workbook?.id || ''}`;
    };

    const getFileName = () => {
        if (mode === WORKBOOK_SECTION_VIEWER_MODES.PREVIEW) {
            return '';
        }

        return student && student.firstName && student.lastName
            ? sanitizeFilenameUrl(`module_${student.firstName}_${student.lastName}.pdf`)
            : 'module.pdf';
    };

    const url = getDownloadUrl();
    const fileName = getFileName();

    return (
        <div className={`${componentClassName.current}`}>
            <style type="text/css">{`
                .${componentClassName.current} .download-button {
                    font-family: ${workbookSchema.textStyling.body.fontFamily};
                    background-color: ${workbookSchema.buttonStyling.fillColor};
                    color: ${workbookSchema.buttonStyling.textColor};
                }

                .${componentClassName.current} .download-button:hover {
                    background-color: ${workbookSchema.buttonStyling.hoverColor};
                    color: ${workbookSchema.buttonStyling.hoverTextColor};
                }

                .${componentClassName.current} .download-button:disabled {
                    background-color: ${APP_STYLES.COLORS.NEUTRAL[300]};
                    color: ${APP_STYLES.COLORS.NEUTRAL[100]};
                }
            `}</style>

            {title.show && (
                <SectionTitle
                    text={title.text}
                    style={{
                        fontFamily: workbookSchema.textStyling.subtitle.fontFamily,
                        color: workbookSchema.textStyling.subtitle.color,
                    }}
                />
            )}
            {description.show && (
                <SectionDescription
                    text={description.text}
                    style={{
                        fontFamily: workbookSchema.textStyling.body.fontFamily,
                        color: workbookSchema.textStyling.body.color,
                    }}
                />
            )}
            <div className="w-40">
                <button
                    onClick={() => download(url, fileName)}
                    type="button"
                    disabled={downloading}
                    className="download-button custom-focus-outline inline-block w-full rounded py-2 px-3 text-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2">
                    {downloading && <LoadingSpinner />}
                    Download
                </button>
            </div>
        </div>
    );
};

export const ModuleDownloadLinkArtifact = () => {
    return null;
};
