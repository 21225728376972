import { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useApi from '@/api/useApi';
import { PUBLICLY_SHARED_WORKBOOK_ID_QUERY_PARAM } from '@/util/constants';
import { getStudentWorkbookRoute } from '@/util/helpers';

export default function Enroll() {
    const { [PUBLICLY_SHARED_WORKBOOK_ID_QUERY_PARAM]: idParam } = useParams();
    const { enrollStudentInPubliclySharedWorkbook } = useApi();
    const navigate = useNavigate();

    useEffect(() => {
        if (idParam) {
            enrollStudentInPubliclySharedWorkbook(idParam).then((enrollment) => {
                const { workbook_version_id: workbookVersionId } = enrollment;
                const path = getStudentWorkbookRoute(workbookVersionId);
                navigate(path);
            });
        }
    }, [enrollStudentInPubliclySharedWorkbook, idParam, navigate]);

    return (
        <div>
            Please wait, if your workbook doesn&apos;t load automatically, please <Link to="/student">click here.</Link>
            .
        </div>
    );
}
