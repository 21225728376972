import { useEffect, useState, useRef } from 'react';
import { useAuth } from '@/auth/AuthProvider';

export const CACHE_KEYS = {
    THINKIFIC_DOMAIN: 'wobo-td',
    CREATOR_UPCOMING_INVOICE: 'creator-upcoming-invoice',
};

export default function useCache(key) {
    const { user } = useAuth();

    const keyWithUserId = useRef('');
    const [ready, setReady] = useState(false);
    const [entry, setEntry] = useState({ value: null, exp: Number.MAX_SAFE_INTEGER });

    const evictItem = () => {
        try {
            const newEntry = { value: null, exp: 0 };
            setEntry(newEntry);
            window.localStorage.removeItem(keyWithUserId.current);
        } catch (error) {
            console.error(`Unable to store new entry for ${keyWithUserId.current} in localStorage.`);
        }
    };

    const getItemValue = () => {
        try {
            const now = new Date().getTime();
            if (now <= entry.exp) return entry.value;
            evictItem();
            return null;
        } catch (error) {
            return null;
        }
    };

    const setItemValue = (value, exp = Number.MAX_SAFE_INTEGER) => {
        if (!Number.isFinite(exp)) throw new Error('Expiration must be expressed in milliseconds');
        const now = new Date().getTime();
        try {
            const newEntry = { value, exp: exp + now };
            setEntry(newEntry);
            window.localStorage.setItem(keyWithUserId.current, JSON.stringify(newEntry));
        } catch (error) {
            console.error(`Unable to store new entry for ${keyWithUserId.current} in localStorage.`);
        }
    };

    useEffect(() => {
        if (!key) throw new Error('A key must be provided');
    }, [key]);

    useEffect(() => {
        if (user.id) {
            keyWithUserId.current = `${key}-${user.id}`;
            setEntry(() => {
                try {
                    const item = window.localStorage.getItem(keyWithUserId.current);
                    return item ? JSON.parse(item) : { value: null, exp: Number.MAX_SAFE_INTEGER };
                } catch (error) {
                    return { value: null, exp: Number.MAX_SAFE_INTEGER };
                }
            });
            setReady(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.id]);

    return { ready, getItemValue, setItemValue, evictItem };
}
