import { STATUSES, STATUS_LABELS } from '@/util/constants';
import { getCookieValue } from '@/util/helpers';

export const isEmailValid = (email) => {
    if (!email) return false;
    const regex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase().trim());
};

export const testPasswordLength = (password) => !!password && password.length >= 8;

export const testPasswordLowercase = (password) => !!password && /^(?=.*[a-z])/.test(password);

export const testPasswordUppercase = (password) => !!password && /^(?=.*[A-Z])/.test(password);

export const testPasswordNumbers = (password) => !!password && /^(?=.*[0-9])/.test(password);

export const testPasswordSpecialCharacters = (password) =>
    !!password && /^(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`])/.test(password);

export const testNoWhitespace = (password) => !/\s/.test(password);

export const isPasswordValid = (password) => {
    return (
        testPasswordLength(password) &&
        testPasswordLowercase(password) &&
        testPasswordUppercase(password) &&
        testPasswordNumbers(password) &&
        testPasswordSpecialCharacters(password) &&
        testNoWhitespace(password)
    );
};

export function getUserStatusLabel(status) {
    switch (status) {
        case STATUSES.UNCONFIRMED:
        case STATUSES.CONFIRMED:
        case STATUSES.OTHER:
        case STATUSES.CHANGE_PASSWORD:
            return STATUS_LABELS[status];
        default:
            return 'Error';
    }
}
export const getGoogleAnalyticsId = () => {
    return getCookieValue(`_ga`)?.substring(6);
};
