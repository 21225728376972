import { createContext, useContext, useEffect } from 'react';
import useNotificationsReducer, { ACTIONS } from '@/system/notifications/useNotificationsReducer';
import NotificationsContainer from '@/system/notifications/NotificationsContainer';
import useSessionCache, { SESSION_CACHE_KEYS } from '@/util/useSessionCache';
import { useAuth } from '@/auth/AuthProvider';

export const NOTIFICATION_MESSAGE_KEYS = {
    NON_VERIFIED_EMAIL: 'NON_VERIFIED_EMAIL',
};

const NotificationsContext = createContext();

export const useNotifications = () => {
    return useContext(NotificationsContext);
};

export default function NotificationProvider({ children }) {
    const [notificationsState, setNotificationsState] = useNotificationsReducer();
    const {
        ready: sessionCacheReady,
        getItemValue: getCachedNotifications,
        setItemValue: setCachedNotifications,
        evictItem: evictCacheNotifications,
    } = useSessionCache(SESSION_CACHE_KEYS.NOTIFICATIONS);
    const { authenticated } = useAuth();
    const { initialized, history, notifications } = notificationsState;

    const pushNotification = (key, variant) => {
        setNotificationsState({ type: ACTIONS.PUSH_NOTIFICATION, payload: { key, variant } });
    };

    const handleRemove = (removeIndex) => {
        setNotificationsState({ type: ACTIONS.REMOVE_NOTIFICATION, payload: { index: removeIndex } });
    };

    const removeNotificationFromHistory = (key) => {
        setNotificationsState({ type: ACTIONS.REMOVE_NOTIFICATION_FROM_HISTORY, payload: { key } });
    };

    useEffect(() => {
        if (!authenticated && initialized) {
            setNotificationsState({ type: ACTIONS.RESET });
            evictCacheNotifications();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authenticated]);

    useEffect(() => {
        if (initialized) {
            setCachedNotifications({
                history,
                notifications,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notifications, history, initialized]);

    useEffect(() => {
        if (sessionCacheReady) {
            const notificationsCache = getCachedNotifications();
            setNotificationsState({ type: ACTIONS.SET, payload: notificationsCache });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionCacheReady]);

    return (
        <NotificationsContext.Provider
            value={{
                initialized,
                pushNotification,
                removeNotificationFromHistory,
            }}>
            <NotificationsContainer notifications={notifications} onNotificationRemove={handleRemove} />
            {children}
        </NotificationsContext.Provider>
    );
}
