import { Component } from 'react';
import TechnicalError from '@/system/TechnicalError';

export default class AppErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: '' };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, error: error.message };
    }

    componentDidCatch(error, errorInfo) {
        console.error({ error, errorInfo });
        // Reload the entire page when there is an issue lazy loading a JS chunk,
        // which can happen during deployments
        if (error.message.startsWith('Loading chunk')) {
            window.location.reload();
        }
    }

    render() {
        const { hasError, error } = this.state;
        if (hasError) {
            return <TechnicalError title="Oops, something went wrong!" desc={error} />;
        }
        const { children } = this.props;
        return children;
    }
}
