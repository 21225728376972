import Tooltip from '@/system/Tooltip';

export default function PreviewSuggestionTooltip({ children }) {
    return (
        <Tooltip
            tip="Click Preview above to test this workbook"
            event="click"
            eventOff="mouseleave"
            className="font-wobo"
            isCapture
            rebuild={false}>
            {children}
        </Tooltip>
    );
}
