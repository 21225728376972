import { Portal } from 'react-portal';
import { useEmbed } from '@/embed/EmbedProvider';
import SimpleLoadingSpinnerIcon from '@/system/icons/SimpleLoadingSpinnerIcon';

export default function LoadingSpinner({ text }) {
    const { embedded } = useEmbed();
    return (
        <Portal>
            <div className="absolute z-100 h-full w-full bg-white/60">
                <div className="absolute top-1/2 left-1/2 my-0 mx-auto block -translate-y-1/2 -translate-x-1/2 transform">
                    <div className="flex flex-col items-center justify-center">
                        {embedded ? (
                            <>
                                <div className="mb-7">
                                    <SimpleLoadingSpinnerIcon />
                                </div>
                                <p className="paragraph__sm">Loading...</p>
                            </>
                        ) : (
                            <>
                                <img
                                    className="h-32 w-32 text-primary-500"
                                    alt="Loading..."
                                    src="/images/logo-animated.svg"
                                />

                                {text && <div className="paragraph__sm--semibold mt-8">{text}</div>}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </Portal>
    );
}
