import { useReducer } from 'react';
import { ROLES } from '@/util/constants';

export const ACTIONS = {
    SET: 'set',
    RESET: 'reset',
};

export const fromDto = (dto) => {
    const roles = [];
    if (dto.roles[ROLES.STUDENT] === true) roles.push(ROLES.STUDENT);
    if (dto.roles[ROLES.CREATOR] === true) roles.push(ROLES.CREATOR);
    if (dto.roles[ROLES.MEMBER] === true) roles.push(ROLES.MEMBER);
    if (dto.roles[ROLES.ADMIN] === true) roles.push(ROLES.ADMIN);
    const user = {
        id: dto.id,
        email: dto.email,
        firstName: dto.first_name,
        lastName: dto.last_name,
        roles,
        emailVerified: dto.email_verified,
        flags: dto.flags,
    };
    return user;
};

export const getDefaultState = () => {
    return {
        id: '',
        email: '',
        firstName: '',
        lastName: '',
        roles: [],
        emailVerified: true,
    };
};

export const userReducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.SET:
            return { ...state, ...action.payload };
        case ACTIONS.RESET:
            return getDefaultState();
        default:
            throw new Error('Action not supported');
    }
};

const useUserReducer = () => {
    return useReducer(userReducer, getDefaultState);
};

export default useUserReducer;
